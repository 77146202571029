import React, { Dispatch, FC, useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import {
  Article,
  ArticleMeta,
  ShareSingleArticleInput,
  Type,
  useShareSingleArticleMutation,
  useUserRewardsMutation,
} from "../../../../codeGenFE";
import { customErrorHandler } from "../../../../utils/customErrorHandler";
import ComponentMessagingOverlay, {
  CmoTheme,
} from "../../../Components/ComponentMessagingOverlay/ComponentMessagingOverlay";
import ShareList, {
  ShareListTheme,
} from "../../../Components/ShareList/ShareList";
import { useRewardsContext, useUserContext } from "../../../context/allContexts";
import {
  ArticleCardActionChoices,
  ArticleCardActions,
} from "../ArticleCard/ReaderCard";
import styles from "./ShareModal.module.scss";
import { useUserRewardLogsMutation } from '../../../../codeGenFE/index';

enum ActionChoices {
  SET_SHARE_ALL,
  SET_SELECTED_USERS,
  ERROR,
}

type Actions =
  | { type: ActionChoices.SET_SHARE_ALL; shareAll: boolean }
  | { type: ActionChoices.SET_SELECTED_USERS; selectedUsers: string[] | [] }
  | { type: ActionChoices.ERROR; error: string };

type State = {
  shareAll: boolean;
  selectedUsers: string[] | [];
  error: string;
};

const reducer = (state: State, action: Actions) => {
  switch (action.type) {
    case ActionChoices.SET_SHARE_ALL: {
      return {
        ...state,
        shareAll: action.shareAll,
      };
    }
    case ActionChoices.SET_SELECTED_USERS: {
      return {
        ...state,
        selectedUsers: action.selectedUsers,
      };
    }
    case ActionChoices.ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

const initState: State = {
  shareAll: false,
  selectedUsers: [],
  error: "",
};

interface Props {
  close: () => void;
  article: ArticleMeta;
  // articleId: string;
  // orig_url: string;
  iconModalTrigger: React.Dispatch<ArticleCardActions>;
  // pubStatus: string;
}

const ShareModal: FC<Props> = ({
  close,
  article,
  // articleId,
  // orig_url,
  iconModalTrigger,
  // pubStatus,
}) => {
  const { whichDB, _id } = article;
  const { user } = useUserContext();
  const {refetch} = useRewardsContext()
  const [state, dispatcher] = useReducer(reducer, initState);
  const [noShareUsers, setNoShareUsers] = useState(false)

  // In this function system is adding reward points on the basis of URL upload action 
  // and on success we are refetching updated reward point details  by 
  // userRewardsDetails()
  const {mutate: rewardsMutate} = useUserRewardsMutation({
    onSuccess: () => {
      refetch()
    },
  })
  const { shareAll, selectedUsers, error } = state;

  //In this function system is logging reward actions and on success we are mutating 
  // userRewardsMutation()
  const {mutate: rewardsLogMutate} = useUserRewardLogsMutation({
    onSuccess: () => {
      rewardsMutate({
        options: {
          type: Type.ArticleShared,
          meta: {
            articleId: _id,
            userIds: selectedUsers
          }
        }
      })
    }
  })

  // In this function our system send selected articles id to 
  // useShareSingleArticleMutation() hook that is used to run graphql 
  // query shareSingleArticle() which is responsible for sharing article to 
  // recipients and on success system is mutating userRewardLogsMutation()
  const { mutate } = useShareSingleArticleMutation({
    onSuccess: () => {
      // console.log("data from share article", data);
      // iconModalTrigger({
      //   type: ArticleCardActionChoices.SET_ICON_MODAL,
      //   payload: "share",
      // });
      toast(`Successfully shared article`);
      // rewardsLogMutate({
      //   options: {
      //     type: Type.ArticleShared
      //   }
      // })
      
      setTimeout(() => {
        // iconModalTrigger({
        //   type: ArticleCardActionChoices.SET_ICON_MODAL,
        //   payload: false,
        // });
      }, 950);
      close();
    },
    onError: (err: any) => {
      dispatcher({ type: ActionChoices.ERROR, error: customErrorHandler(err) });
      console.log("err from share article", err);
    },
  });

  return (
    <>
      <div className={styles.backdrop} onClick={() => close()} />
      <div className={`${styles.shareModalWrapper} ${noShareUsers ? styles.noShareUser : ""}`}>
        {error && (
          <ComponentMessagingOverlay
            close={() => {
              if (!whichDB || !_id) {
                // console.log("something is wrong ShareModal mutation");
              } else {
                dispatcher({ type: ActionChoices.ERROR, error: "" });
                mutate({
                  options: {
                    whichDB: whichDB,
                    articleId: _id,
                    shareAll,
                    recipients: selectedUsers,
                    userFullName: `${user.name} ${
                      user.lastName && user.lastName
                    }`,
                  },
                });
              }
            }}
            message={error}
            showX={true}
            theme={CmoTheme.DARK}
            buttonText={"Retry"}
          />
        )}
        <div className={styles.scrollableContent}>
          <ShareList
            shareAll={shareAll}
            setShareAll={(arg: boolean) =>
              dispatcher({ type: ActionChoices.SET_SHARE_ALL, shareAll: arg })
            }
            selectedUsers={selectedUsers}
            setSelectedUsers={(arg: string[] | []) =>
              dispatcher({
                type: ActionChoices.SET_SELECTED_USERS,
                selectedUsers: arg,
              })
            }
            theme={{
              desktop: ShareListTheme.DARK,
              mobile: ShareListTheme.DARK,
            }}
            checkNoUsers={() => setNoShareUsers(true)}
          />
        </div>
        <div className={styles.btnContainer}>
          <button
            onClick={() => {
              if (!whichDB || !_id) {
              } else {
                mutate({
                  options: {
                    whichDB: whichDB,
                    articleId: _id,
                    shareAll,
                    recipients: selectedUsers,
                    userFullName: `${user.name} ${
                      user.lastName && user.lastName
                    }`,
                  },
                });
              }
            }}
            className={styles.shareBtn}
            disabled={selectedUsers.length === 0}
          >
            Share
          </button>
          <button className={styles.cancelBtn} onClick={() => close()}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default ShareModal;
