import Fuse from "fuse.js";
import React, { Dispatch, FC, useEffect, useReducer } from "react";
import { AiOutlineDown, AiOutlinePlus } from "react-icons/ai";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { useUserContext } from "../../../../context/allContexts";
import {
  Maybe,
  useAddTagsToArticleMutation,
  useDeleteTagFromArticleMutation,
  useUpdateArticleTagsMutation,
  useUserTagsQuery,
  WhichDb,
} from "../../../../../codeGenFE";
import styles from "./ACDisplayTags.module.scss";
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
import { useQueryClient } from "react-query";
import EditTags from "./EditTags/EditTags";
import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";
import TagsList from "./TagsList/TagsList";
import TagActionErrorMsg from "./TagActionErrorMsg/TagActionErrorMsg";
import toast from "react-hot-toast";

export enum Mode {
  DISPLAY_SOME,
  DISPLAY_ALL,
  ADD,
  EDIT,
  DELETE,
  DELETE_CONFIRM,
  ERROR,
}

export enum ACDisplayTagsActionChoices {
  SET_MODE,
  SET_FILTERED_TAGS,
  SET_TAG_TO_ADD,
  SHOW_USER_TAG_CHOICES,
  SHOW_CONFIRM_DELETE_TAG,
  CHANGE_EDIT_TAGS_LIST,
  SUCCESSFULLY_ADDED_TAG,
  EDIT_TAGS_SUCCESS,
  TAG_ACTION_FAILURE,
  CANCEL_MUTATION,
  DUPLICATE_TAG,
}

export type ACDisplayTagsActions =
  | { type: ACDisplayTagsActionChoices.SET_MODE; mode: Mode }
  | {
      type: ACDisplayTagsActionChoices.SET_FILTERED_TAGS;
      tags: Maybe<string>[];
    }
  | { type: ACDisplayTagsActionChoices.SET_TAG_TO_ADD; payload: string }
  | { type: ACDisplayTagsActionChoices.SHOW_USER_TAG_CHOICES; payload: boolean }
  | {
      type: ACDisplayTagsActionChoices.SHOW_CONFIRM_DELETE_TAG;
      tag?: string;
      showConfirmation: boolean;
    }
  | {
      type: ACDisplayTagsActionChoices.CHANGE_EDIT_TAGS_LIST;
      tags: string[] | [];
    }
  | { type: ACDisplayTagsActionChoices.SUCCESSFULLY_ADDED_TAG }
  | { type: ACDisplayTagsActionChoices.EDIT_TAGS_SUCCESS }
  | {
      type: ACDisplayTagsActionChoices.TAG_ACTION_FAILURE;
      error: string;
      retry?: () => void;
    }
  | { type: ACDisplayTagsActionChoices.CANCEL_MUTATION; tags: string[] | [] }
  | { type: ACDisplayTagsActionChoices.DUPLICATE_TAG; duplicateTagError: string | null };

export type ACDisplayTagsState = {
  mode: Mode;
  editTagsList: string[];
  tagToAdd: string;
  tagToDelete: string | undefined;
  filteredTags: Maybe<string>[];
  userTagDropdown: boolean;
  error: string;
  duplicateTagError: string | null;
  retry: () => void | undefined;
};

const ACDisplayTagsReducer = (
  state: ACDisplayTagsState,
  action: ACDisplayTagsActions
): ACDisplayTagsState => {
  switch (action.type) {
    case ACDisplayTagsActionChoices.SET_MODE:
      return {
        ...state,
        mode: action.mode,
      };
    case ACDisplayTagsActionChoices.SET_FILTERED_TAGS:
      return {
        ...state,
        filteredTags: action.tags
          ? action.tags.sort((a: Maybe<string>, b: Maybe<string>) =>
              a!.toLowerCase() > b!.toLowerCase() ? 1 : -1
            )
          : [],
      };
    case ACDisplayTagsActionChoices.SHOW_USER_TAG_CHOICES:
      return {
        ...state,
        userTagDropdown: action.payload,
      };
    case ACDisplayTagsActionChoices.SHOW_CONFIRM_DELETE_TAG:
      return {
        ...state,
        mode: action.showConfirmation ? Mode.DELETE_CONFIRM : Mode.DISPLAY_ALL,
        tagToDelete: action?.tag,
      };
    case ACDisplayTagsActionChoices.SET_TAG_TO_ADD:
      return {
        ...state,
        tagToAdd: action.payload,
      };
    case ACDisplayTagsActionChoices.CHANGE_EDIT_TAGS_LIST:
      return {
        ...state,
        editTagsList: action.tags,
      };
    case ACDisplayTagsActionChoices.DUPLICATE_TAG:
      return {
        ...state,
        duplicateTagError: action.duplicateTagError,
      };  
    case ACDisplayTagsActionChoices.SUCCESSFULLY_ADDED_TAG:
      return {
        ...state,
        mode: Mode.DISPLAY_ALL,
        tagToAdd: "",
      };
    case ACDisplayTagsActionChoices.EDIT_TAGS_SUCCESS:
      return {
        ...state,
        mode: Mode.DISPLAY_ALL,
      };
    case ACDisplayTagsActionChoices.TAG_ACTION_FAILURE:
      return {
        ...state,
        mode: Mode.ERROR,
        error: action.error,
        // @ts-ignore
        retry: action?.retry,
      };
    case ACDisplayTagsActionChoices.CANCEL_MUTATION:
      return {
        ...state,
        // tagToAdd: "",
        editTagsList: action.tags,
        mode: Mode.DISPLAY_SOME,
        retry: () => {},
      };
    default:
      return state;
  }
};

const initState: ACDisplayTagsState = {
  mode: Mode.DISPLAY_SOME,
  editTagsList: [],
  tagToAdd: "",
  tagToDelete: undefined,
  filteredTags: [],
  userTagDropdown: false,
  error: "",
  retry: () => {},
  duplicateTagError: null,
};

interface Props {
  tags: string[];
  label: string;
  id: string;
  WhichDb: WhichDb;
  pubStatus: string;
}
const ACDisplayTags: FC<Props> = ({ tags, label, id, WhichDb, pubStatus }) => {
  // ============================== Queries ============================== //
  // user associated tag choices (from user preferences)
  const { data, status, error: userTagsError } = useUserTagsQuery();
  const availableTags = data?.userTags;

  // ============================== Mutations ============================== //
  const qClient = useQueryClient();

  const { error: addTagsError, mutate: addNewTagsMutation } =
    useAddTagsToArticleMutation({
      onSuccess: (data) => {
        qClient.invalidateQueries("articleCard");
        qClient.invalidateQueries("userTags");
        dispatcher({
          type: ACDisplayTagsActionChoices.SET_TAG_TO_ADD,
          payload: "",
        });
        toast.success(`Tag added!`);
      },
      onError: (error) => {
        dispatcher({
          type: ACDisplayTagsActionChoices.TAG_ACTION_FAILURE,
          error: customErrorHandler(error),
        });
      },
    });

  const { mutate: deleteTagMutation } = useDeleteTagFromArticleMutation({
    onSuccess: (data) => {
      qClient.invalidateQueries("articleCard");
      dispatcher({
        type: ACDisplayTagsActionChoices.SHOW_CONFIRM_DELETE_TAG,
        showConfirmation: false,
      });
    },
    onError: (error) => {
      dispatcher({
        type: ACDisplayTagsActionChoices.TAG_ACTION_FAILURE,
        retry: () =>
          deleteTagMutation({
            delOptions: {
              articleId: id,
              tag: tagToDelete as string,
              whichDB: WhichDb,
            },
          }),
        error: customErrorHandler(error),
      });
    },
  });

  const { mutate: updateArticleTagsMutation } = useUpdateArticleTagsMutation({
    onSuccess: () => {
      qClient.invalidateQueries("articleCard");
      dispatcher({
        type: ACDisplayTagsActionChoices.SET_MODE,
        mode: Mode.DISPLAY_ALL,
      });
    },
    onError: (error) => {
      console.log("update article tags error :>> ", error);
      dispatcher({
        type: ACDisplayTagsActionChoices.TAG_ACTION_FAILURE,
        retry: () =>
          updateArticleTagsMutation({
            updateArgs: {
              articleId: id,
              tags: editTagsList,
              whichDB: WhichDb,
            },
          }),
        error: customErrorHandler(error),
      });
    },
  });

  // ============================== Local State ============================== //
  const [state, dispatcher] = useReducer(ACDisplayTagsReducer, initState);
  const {
    mode,
    tagToAdd,
    tagToDelete,
    filteredTags,
    editTagsList,
    userTagDropdown,
    error,
    duplicateTagError,
    retry,
  } = state;

  useEffect(() => {
    if (mode !== Mode.EDIT && mode !== Mode.ERROR) {
      dispatcher({
        type: ACDisplayTagsActionChoices.CHANGE_EDIT_TAGS_LIST,
        tags,
      });
    }
  }, [tags, mode]);

  // ============= LOCAL STATE ============= //

  useEffect(() => {
    if (availableTags && availableTags.length > 0) {
      if (tagToAdd.trim() === "") {
        dispatcher({
          type: ACDisplayTagsActionChoices.SET_FILTERED_TAGS,
          tags: availableTags,
        });
      } else {
        // Fuzzy search here
        const options = {
          shouldSort: true,
          threshold: 0,
          location: 0,
          distance: 500,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: [],
        };
        let fuse = new Fuse(availableTags, options);
        let result = fuse.search(tagToAdd).map((i) => i.item);
        dispatcher({
          type: ACDisplayTagsActionChoices.SET_FILTERED_TAGS,
          tags: result,
        });
      }
    }
  }, [tagToAdd, availableTags]);

  function keyboardAddTag(tag: any) {
    if (tag !== "") {
      if (filteredTags[0]) {
        submitNewTag(filteredTags[0]);
      } else {
        submitNewTag(tag);
      }
    }
  }

  const submitNewTag = async (tag: any) => {
    if (tag === "") {
      return;
    }

    if (tags) {
      const lowerCaseTagsList = tags.map((tag: any) => tag.toLowerCase());
      if (lowerCaseTagsList.includes(tag.toLowerCase())) {
        dispatcher({type: ACDisplayTagsActionChoices.DUPLICATE_TAG, duplicateTagError: "Tag already exists"})
        return;
      }
    }
    addNewTagsMutation({
      tagArgs: { whichDB: WhichDb, articleId: id, tagsList: [tag] },
    });
  };

  const handleEditByKeyPress = (status: boolean) => {
      if(status) {
        let shouldSubmit = false;
        editTagsList.forEach((t, i) => {
          if (t !== tags[i]) shouldSubmit = true;
        });
        if (shouldSubmit) {
          // Run mutation
          updateArticleTagsMutation({
            updateArgs: {
              articleId: id,
              tags: editTagsList,
              whichDB: WhichDb,
            },
          });
      }
    }
  } 
  if(duplicateTagError) {
    setTimeout(() => {
      dispatcher({type: ACDisplayTagsActionChoices.DUPLICATE_TAG, duplicateTagError: null})
    }, 10000)
  }

  return (
    <>
      <div
        className={styles.titleArrowWrapper}
        onClick={() =>
          dispatcher({
            type: ACDisplayTagsActionChoices.SET_MODE,
            mode:
              mode !== Mode.DISPLAY_SOME ? Mode.DISPLAY_SOME : Mode.DISPLAY_ALL,
          })
        }>
        <p style={{ color: `${!!userTagsError ? "tomato" : "white"}` }}>
          {!userTagsError ? label : customErrorHandler(userTagsError)}
        </p>
        {tags && tags.length > 2 && (
          <AiOutlineDown className={styles.arrowIcon} />
        )}
      </div>
      <div className={styles.tagCtrlWrapper}>
        <div
          className={`${styles.tagsNInputWrapper} ${
            mode === Mode.DISPLAY_SOME || mode === Mode.ADD
              ? styles.disableOverflow
              : null
          }`}>
          {(mode === Mode.DISPLAY_SOME ||
            mode === Mode.DISPLAY_ALL ||
            mode === Mode.ADD ||
            mode === Mode.DELETE) && (
            <TagsList
              dispatcher={dispatcher}
              displayed={mode === Mode.DISPLAY_SOME ? tags.slice(0, 2) : tags}
              mode={mode}
            />
          )}

          {mode === Mode.DELETE_CONFIRM && (
            <DeleteConfirmation
              deleteTag={() =>
                deleteTagMutation({
                  delOptions: {
                    articleId: id,
                    tag: tagToDelete as string,
                    whichDB: WhichDb,
                  },
                })
              }
              cancel={() =>
                dispatcher({
                  type: ACDisplayTagsActionChoices.SHOW_CONFIRM_DELETE_TAG,
                  showConfirmation: false,
                })
              }
            />
          )}
          {mode === Mode.EDIT && (
            <EditTags dispatcher={dispatcher} editTagsList={editTagsList} editByKeyPress={(status) => handleEditByKeyPress(status)} />
          )}
          {mode === Mode.ERROR && (
            <TagActionErrorMsg
              cancel={() =>
                dispatcher({
                  type: ACDisplayTagsActionChoices.CANCEL_MUTATION,
                  tags,
                })
              }
              error={error}
              retry={retry}
            />
          )}
          {/* {Func(tags)} */}
          {mode === Mode.ADD || !tags || tags.length === 0 ? (
            <>
              {
                duplicateTagError ? <p style={{ color: `#2196f3` }}>{duplicateTagError}</p> : ""
              }
              <div className={styles.inputNPlusWrapper}>
              <input
                className={styles.tags__input}
                placeholder="Add Tag!"
                // onClick={() => setUserTagDropdown(true)}
                onClick={(e) => {
                  e.preventDefault();
                  dispatcher({
                    type: ACDisplayTagsActionChoices.SHOW_USER_TAG_CHOICES,
                    payload: true,
                  });
                  dispatcher({
                    type: ACDisplayTagsActionChoices.DUPLICATE_TAG, 
                    duplicateTagError: null
                  })
                }}
                value={tagToAdd}
                // onChange={(e) => setTagToAdd(e.target.value)}
                onChange={(e) => {
                  dispatcher({
                    type: ACDisplayTagsActionChoices.SET_TAG_TO_ADD,
                    payload: e.target.value,
                  });
                  dispatcher({
                    type: ACDisplayTagsActionChoices.DUPLICATE_TAG, 
                    duplicateTagError: null
                  })
                }
                }
                onKeyPress={(e) =>
                  // TODO: Add keyboardAddTag func that runs submitNewTag if list is empty
                  e.key === "Enter" ? keyboardAddTag(tagToAdd) : null
                }
              />
              {userTagDropdown && (
                <div
                  className={styles.usersTagsContainer}
                  // onMouseLeave={() => setUserTagDropdown(false)}>
                  onMouseLeave={() =>
                    dispatcher({
                      type: ACDisplayTagsActionChoices.SHOW_USER_TAG_CHOICES,
                      payload: false,
                    })
                  }>
                  <div className={styles.scrollContainer}>
                    {filteredTags &&
                      filteredTags.map((tag: any, idx: number) => {
                        return (
                          <p
                            className={`${styles.userTag__P} ${
                              idx === 0 ? styles.userTag__p__active : null
                            }`}
                            key={idx + tag}
                            onClick={() => submitNewTag(tag)}>
                            {tag}
                          </p>
                        );
                      })}
                  </div>
                </div>
              )}
              <AiOutlinePlus
                className={styles.inputPlusIcon}
                onClick={() => submitNewTag(tagToAdd)}
              />
            </div>
            </>
          ) : null}
        </div>
        {/* ============= Add, Edit and Trash Icon Section ============= */}
        <div className={styles.iconWrapper}>
          {tags && tags.length > 0 ? (
            <>
              <AiOutlinePlus
                className={`${styles.tagIconPlus} ${
                  mode === Mode.ADD
                    ? styles.activeMorphX
                    : styles.activeMorphXOff
                }`}
                onClick={() => {
                  dispatcher({
                    type: ACDisplayTagsActionChoices.SET_MODE,
                    mode: mode === Mode.ADD ? Mode.DISPLAY_ALL : Mode.ADD,
                  });
                }}
              />
              <FaRegEdit
                className={`${styles.tagIcons} ${
                  mode === Mode.EDIT ? styles.editActive : null
                }`}
                onClick={() => {
                  dispatcher({
                    type: ACDisplayTagsActionChoices.DUPLICATE_TAG, 
                    duplicateTagError: null
                  })
                  if (mode !== Mode.EDIT) {
                    // show edit inputs
                    dispatcher({
                      type: ACDisplayTagsActionChoices.SET_MODE,
                      mode: Mode.EDIT,
                    });
                  } else {
                    // submit tags to be changed on article if necessary
                    let shouldSubmit = false;
                    editTagsList.forEach((t, i) => {
                      if (t !== tags[i]) shouldSubmit = true;
                    });
                    if (shouldSubmit) {
                      // Run mutation
                      updateArticleTagsMutation({
                        updateArgs: {
                          articleId: id,
                          tags: editTagsList,
                          whichDB: WhichDb,
                        },
                      });
                    } else {
                      // close the edit inputs
                      dispatcher({
                        type: ACDisplayTagsActionChoices.SET_MODE,
                        mode: Mode.DISPLAY_SOME,
                      });
                    }
                  }
                }}
              />
              <FaRegTrashAlt
                className={`${styles.tagIcons} ${
                  mode === Mode.DELETE && styles.editActive
                }`}
                onClick={() => {
                  dispatcher({
                    type: ACDisplayTagsActionChoices.SET_MODE,
                    mode: mode === Mode.DELETE ? Mode.DISPLAY_ALL : Mode.DELETE,
                  });
                }}
              />
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ACDisplayTags;
