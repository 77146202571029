import React, { useEffect, useLayoutEffect, useReducer } from "react";
import {
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlinePlus,
} from "react-icons/ai";
import {
  ListUsersBy,
  NoteMeta,
  Type,
  useGetUserNotesQuery,
  useMakeNewNoteMutation,
  useUserLookupTableQuery,
  useUserRewardLogsMutation,
  useUserRewardsMutation,
} from "../../../../codeGenFE";
// COMPONENTS
import InfoButton from "../../../../Icons/InfoButton";
import { customErrorHandler } from "../../../../utils/customErrorHandler";
// CONTEXT
import {
  useNotebookContext,
  useUserContext,
  useViewPortContext,
} from "../../../context/allContexts";
// UTIL
import { convertDate } from "../../../util/MMDDYYwithslashtimestampFormater";
import DashErrorMessage from "../../DashErrorMessage/DashErrorMessage";
import LoadingCube, { LoadingCubeTheme } from "../../LoadingCube/LoadingCube";
import NBInfoModal from "./NBInfoModal/NBInfoModal";
import SANSortByDropDown from "./SANSortByDropDown/SANSortByDropDown";
// STYLES
import styles from "./SelectANote.module.scss";
import { useRewardsContext } from '../../../context/allContexts/RewardsContext';
import { axios } from "../../../../utils/axiosConfig";
import { REST_BASE_ENDPOINT } from "../../../../constants";

export enum SortBy {
  DATE = "Date",
  NAME = "Name",
  SHARED_BY = "Shared By",
}

// @ts-ignore
const SANReducer = (state, action) => {
  switch (action.type) {
    case "selectSort":
      return {
        ...state,
        sortBy: action.sortBy,
        showSortDropdown: false,
      };
    case "showSortDropdown":
      return {
        ...state,
        showSortDropdown: state.showSortDropdown === true ? false : true,
      };
    case "reset":
      return {
        // ...initState,
        ...state,
        sortBy: SortBy.DATE,
        showSortDropdown: false,
      };
    case "edit fuzzy":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "toggle nbInfoModal":
      return {
        ...state,
        showNBInfoModal: action.payload === true ? action.payload : false,
      };
    case "toggle resync":
      return {
        ...state,
        showResync: action.boolean,
      };
  }
  return state;
};

const initState = {
  sortBy: SortBy.DATE,
  showSortDropdown: false,
  sortedNotes: [],
  filteredNotes: [],
  searchNoteInput: "",
  searchStr: null,
  showNBInfoModal: false,
  showResync: false,
};

const SelectANote = () => {
  // context
  const { user } = useUserContext();
  // @ts-ignore
  const { width } = useViewPortContext();
  const { notebookState, notebookDispatch } = useNotebookContext();
  const { noNoteSelectedWarning, fullscreen, noteIdsForAddToNotebook } =
    notebookState;
  const [SANState, SANDispatch] = useReducer(SANReducer, initState);
  const { sortBy, showSortDropdown, searchNoteInput, showNBInfoModal } =
    SANState;

  useLayoutEffect(() => {
    const fetchChatGPTApi = async () => {
      try {
        const res = await axios.get(`${REST_BASE_ENDPOINT}/auth/chat-gpt-valid`)
        console.log(res.data)
        if( res.data.data === "API exists!") {
          notebookDispatch({ type: "chat gpt api exist", payload: true })
        }
      } catch(err) {
        notebookDispatch({ type: "chat gpt api exist", payload: false })
      }
    }
    fetchChatGPTApi()
  }, [])
  useEffect(() => {
    if(localStorage.getItem("reloaded") && localStorage.getItem('activeNotebook'))
      notebookDispatch({ type: "change current active note Id", id:  localStorage.getItem('activeNotebook')})
  },[])  
  // ===========  New Data Management =========== //
  const { data: listData, error: userListError } = useUserLookupTableQuery(
    { listBy: ListUsersBy.Id },
    { enabled: !!user }
  );

  const {refetch: rewardReFetch} = useRewardsContext()

  const userList = listData?.userLookupTable;

  // In this function system is adding reward points on the basis of URL upload 
  // action and on success system is refetching updated reward point details  
  // by userRewardsDetails()
  const {mutate: rewardsMutate} = useUserRewardsMutation({
    onSuccess: () => {
      rewardReFetch()
    }
  })

  //In this function system is logging reward actions and on success system 
  // mutating userRewardsMutation()
  const {mutate: rewardsLogMutate} = useUserRewardLogsMutation({
    onSuccess: () => {
      console.log("success")
      rewardsMutate({
        options:{
          type: Type.NotebookCreate,
          meta: {
            notebbook: notebookState.noteIdsForAddToNotebook,
          }
        }
      })
    }
  })

  const { data, status, isFetching, error, refetch } = useGetUserNotesQuery(
    {
      searchStr: searchNoteInput ? searchNoteInput : null,
    },
    {
      placeholderData: {
        getUserNotes: [
          {
            _id: "12345",
            title: "Loading...",
            date: Date.now() / 1000,
            sharedBy: null,
          },
        ],
      },
    }
  );

  // In this function our system adds a new notebook with empty data by 
  // useMakeNewNoteMutation() hook that is used to run graphql query makeNewNote() 
  // which is responsible for creating a new note and on success system 
  // is mutating userRewardLogsMutation()
  const { mutate: makeNewNote, error: makeNewNoteError } =
    useMakeNewNoteMutation({
      onSuccess: (data) => {
        notebookDispatch({
          type: "change current active note Id",
          id: data.makeNewNote,
        });
        // rewardsLogMutate({
        //   options: {
        //     type: Type.NotebookCreate
        //   }
        // })
      },
      onError: (error) => {
        console.log("error :>> ", error);
      },
    });

  const notes = data?.getUserNotes;

  const toggleNoteActivation = (id: string) => {
    notebookDispatch({ type: "toggle id in noteIdsForAddToNotebook", id: id });
  };

  function lookupUser(userId: string) {
    if (userId && userList) {
      const user = userList[userId];
      if (user) {
        return ` - From: ${user.name}${
          user.lastName ? " " + user.lastName.substring(0, 1) + "." : ""
        }`;
      }
      return;
    }
    return;
  }

  function sortNotes(notes: NoteMeta[]) {
    switch (sortBy) {
      case SortBy.NAME:
        return notes.sort((a: NoteMeta, b: NoteMeta) => {
          return b.title.toLowerCase() < a.title.toLowerCase() ? 1 : -1;
        });
      case SortBy.SHARED_BY:
        return notes
          .filter((note: NoteMeta) => !!note?.sharedBy)
          .sort((a: NoteMeta, b: NoteMeta) => {
            if (!a.sharedBy && !b.sharedBy) return 0;
            if (!a.sharedBy) return -1;
            if (!b.sharedBy) return 1;
            return b.sharedBy.toLowerCase() > a.sharedBy.toLowerCase() ? -1 : 1;
          });
      default:
        return notes.sort((a: NoteMeta, b: NoteMeta) => {
          // @ts-ignore
          return a.date < b.date ? 1 : -1;
        });
    }
  }

  if (status === "loading" && !isFetching) {
    return (
      <div className={styles.selectANoteWrapper}>
        <LoadingCube
          theme={LoadingCubeTheme.LIGHT}
          height={100}
          width={100}
          hasBackground={false}
        />
      </div>
    );
  }

  if (error) {
    return (
      <DashErrorMessage
        refetch={refetch}
        error={customErrorHandler(error)}
        isFetching={isFetching}
      />
    );
  }

  return (
    <div className={styles.selectANoteWrapper}>
      {/* CONSIDER REMOVING FOR THIS COMPONENT */}
      {showNBInfoModal && (
        <NBInfoModal
          close={() =>
            SANDispatch({ type: "toggle nbInfoModal", payload: false })
          }
        />
      )}
      {fullscreen ? (
        <AiOutlineMenuUnfold
          onClick={() => {
            notebookDispatch({
              type: "toggle",
              key: "fullscreen",
              value: !fullscreen,
            });
          }}
          className={styles.menuIcon}
        />
      ) : (
        <AiOutlineMenuFold
          onClick={() => {
            notebookDispatch({
              type: "toggle",
              key: "fullscreen",
              value: !fullscreen,
            });
          }}
          className={styles.menuIcon}
        />
      )}
      <div className={styles.notebook__title__wrapper}>
        <h3 className={styles.notebook__title}>{user.name}'s Notebook</h3>
        <InfoButton
          className={styles.infoIcon}
          onClick={() =>
            SANDispatch({
              type: "toggle nbInfoModal",
              payload: !showNBInfoModal,
            })
          }
          light={width < 1211}
        />
      </div>
      <SANSortByDropDown
        showSortDropdown={showSortDropdown}
        SANDispatch={SANDispatch}
        // @ts-ignore
        sortBy={sortBy}
      />
      <div className={styles.sortDropDownSearch__wrapper}>
        <input
          className={styles.sortInput}
          type="text"
          placeholder="Note Name Search..."
          value={searchNoteInput}
          onChange={(e) => {
            SANDispatch({
              type: "edit fuzzy",
              field: "searchNoteInput",
              value: e.target.value,
            });
          }}>
          {/* Search... */}
        </input>
      </div>
      {/* Add a note */}
      <div className={styles.plusBtnWrapper}>
        {!makeNewNoteError && (
          <label className={styles.createLabel}>Create a Note</label>
        )}
        {makeNewNoteError && (
          <label
            className={`${styles.createLabel} ${styles.createNoteFailMsg}`}>
            Couldn't create note. Please try again
          </label>
        )}
        <button
          className={styles.plusIconBtn}
          onClick={() => {
            makeNewNote({});
          }}>
          <AiOutlinePlus className={styles.plusIcon} />
        </button>
      </div>
      <div className={styles.activeNotesLabel__wrapper}>
        <p className={styles.activeNotesLabel__p}>
          Activate your note(s){" "}
          <button
            className={`${styles.clearBtn} ${
              noteIdsForAddToNotebook.length > 0 ? styles.show : null
            }`}
            onClick={() => {
              notebookDispatch({ type: "clear noteIdsForAddToNotebook" });
            }}>
            Deselect All
          </button>
        </p>
        {noNoteSelectedWarning ? (
          <p
            className={`${styles.activeNotesLabel__p__small} ${styles.activeNotesLabel__p__red}`}>
            ** Please select note(s) to send content to
          </p>
        ) : (
          <p className={styles.activeNotesLabel__p__small}>
            Your selections will go to the active note(s)
          </p>
        )}
      </div>
      {/* List of notes */}
      {/* Note name */}
      <div className={styles.notesListWrapper}>
        <div className={styles.notesListWrapperScroll}>
          {notes &&
            notes?.length > 0 &&
            // @ts-ignore
            sortNotes(notes).map((note) => {
              return (
                <div key={note._id} className={styles.singleNoteWrapper}>
                  <div
                    className={styles.titleNDateWrapper}
                    onClick={() => {
                      notebookDispatch({
                        type: "change current active note Id",
                        id: note._id,
                      });
                      localStorage.setItem('activeNotebook', note._id)
                    }}>
                    <button className={styles.singleNoteBtn}>
                      {isFetching ? "Loading..." : note.title}
                    </button>
                    <span className={styles.noteDate}>
                      {convertDate(note.date)}
                      {note.sharedBy ? (
                        <span>{lookupUser(note.sharedBy)}</span>
                      ) : null}
                    </span>
                  </div>
                  <div
                    className={styles.activate__parent}
                    title="Select a note(s) to add dynamic content from pluaris"
                    onClick={() => toggleNoteActivation(note._id)}>
                    <li
                      className={`${styles.activate}
                    ${
                      noteIdsForAddToNotebook.includes(note._id)
                        ? styles.activate__on
                        : null
                    }`}>
                      {noteIdsForAddToNotebook.includes(note._id)
                        ? "Active"
                        : "Activate"}
                    </li>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SelectANote;
