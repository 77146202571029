import React, { useEffect, useState } from "react";
import {
  AiOutlineLogout,
  AiOutlineMenu,
  AiOutlineUpload,
} from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { HiOutlineBookOpen } from "react-icons/hi";
// Components
// Context
import {
  useUserContext,
  useNavContext,
  useSearchContext,
  useNotebookContext,
} from "../../context/allContexts";
import { SearchContextActions } from "../../context/allContexts/SearchContext/SearchContextTypes";

// Styling
import styles from "./MobileControls.module.scss";

const SensaiControls = ({ disableSearch = false }) => {
  const { logout } = useUserContext();
  const {
    whichMenu,
    toggleNav,
    filterAnimationState,
    navDispatch,
    isFiltersDisabled,
  } = useNavContext();
  const { calcFiltersApplied, searchDispatcher } = useSearchContext();
  const { notebookDispatch } = useNotebookContext();

  // show is the current menu that should be open (or none if "")
  const [show, setShow] = useState("");
  // last menu opened if there is one
  const [prevShow, setPrevShow] = useState("");

  function toggleMenu(toggleMenu) {
    if (toggleMenu === show) {
      setPrevShow(show);
      setShow("");
    } else {
      setPrevShow(show);
      setShow(`${toggleMenu}`);
    }
  }

  useEffect(() => {
    setShow("");
    setPrevShow("");
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setPrevShow("");
    }, 500);
  }, [show]);

  function animateMenu(menu) {
    let style = null;
    if (show === menu) {
      style = styles.openMenu;
    } else if (prevShow === menu) {
      style = styles.closeMenu;
    } else {
      return null;
    }
    return style;
  }

  function setIsOpen() {
    return null;
  }

  return (
    <>
      <div className={styles.sensaiControlsWrapper}>
        <nav className={styles.nav}>
          <button
            className={styles.nav__item}
            onClick={() => {
              toggleNav === "open" && navDispatch({ type: "closing" });
              toggleNav === "closed" && navDispatch({ type: "opening mobile" });
            }}
          >
            <span className={styles.nav__icon}>
              <AiOutlineMenu />
            </span>
            Nav
          </button>
          <button
            onClick={() => {
              filterAnimationState === "open" &&
                navDispatch({ type: "toggle filters", filterState: "closing" });
              filterAnimationState === "closed" &&
                navDispatch({ type: "toggle filters", filterState: "opening" });
            }}
            className={`${styles.nav__item} ${
              isFiltersDisabled ? styles.nav__item__disabled : null
            }`}
            disabled={isFiltersDisabled}
          >
            <span className={styles.nav__icon}>
              <span
                // data-filter-badge-state={toggleNav}
                className={styles.filterBadge}
              >
                {calcFiltersApplied()}
              </span>
              <FiFilter />
            </span>
            Filters
          </button>
          <button
            className={styles.nav__item}
            onClick={() => {
              whichMenu === "quickUpload"
                ? navDispatch({ type: "change which menu", menu: "" })
                : navDispatch({
                    type: "change which menu",
                    menu: "quickUpload",
                  });
            }}
          >
            <span className={styles.nav__icon}>
              <AiOutlineUpload />
            </span>
            Upload
          </button>
          <button
            className={styles.nav__item}
            onClick={() => {
              whichMenu === "notebook"
                ? navDispatch({ type: "change which menu", menu: "" })
                : navDispatch({ type: "change which menu", menu: "notebook" });
            }}
          >
            <span className={styles.nav__icon}>
              <HiOutlineBookOpen />
            </span>
            Notebook
          </button>
          <button
            className={styles.nav__item}
            onClick={() => {
              notebookDispatch({ type: "logout" });
              searchDispatcher({ type: SearchContextActions.RESET_FILTERS });
              navDispatch({ type: "logout" });
              logout();
            }}
          >
            <span className={styles.nav__icon}>
              <AiOutlineLogout />
            </span>
            Logout
          </button>
        </nav>
      </div>
      {/* Items that live in page but not in controls wrapper */}
      {/* <div
        className={`${styles.mobileView} ${animateMenu("nav")}`}
        onClick={() => toggleMenu("nav")}
      >
        <div
          className={`${styles.mobileMenu}`}
          onClick={(e) => e.stopPropagation()}
        >
          <TabPhoneNavigation
            showMobileNav={show}
            // showMobileNav={showMobileNav}
            setShowMobileNav={setShow}
            // setShowMobileNav={setShowMobileNav}
          />
        </div>
      </div> */}
      {/* =========== Search =========== */}
      {/* <div
        className={`${styles.mobileView} ${animateMenu("search")}`}
        onClick={() => toggleMenu("search")}
      >
        <div
          className={`${styles.mobileMenu}`}
          onClick={(e) => e.stopPropagation()}
        >
          <V2Search
            absolutePos={false}
            alwaysOpen={true}
            close={() => toggleMenu("")}
          />
        </div>
      </div> */}
      {/* =========== Quick upload =========== */}
      <div
        className={`${styles.mobileView} ${animateMenu("upload")}`}
        onClick={() => toggleMenu("upload")}
      >
        <div
          className={`${styles.mobileMenu}`}
          onClick={(e) => e.stopPropagation()}
        >
          {/*<QuickUpload setIsOpen={setIsOpen} />*/}
        </div>
      </div>
    </>
  );
};

// export default connect(null, { logoutUser })(SensaiControls);
export default SensaiControls;
