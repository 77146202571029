import React, { FC } from "react";

import styles from "./ToolTip.module.scss";

// pass in "string" name of component. style changes in styles
interface Props {
  toolTip: string;
  styled?: string;
}

const ToolTip: FC<Props> = ({ toolTip, styled = "" }) => {
  let specialStyles = null;
  switch (styled) {
    case "RTNAOneNewsArticle":
      specialStyles = styles.toolTipWrapperForRTNAOneNewsArticle;
      break;
  }

  return (
    <div className={`${styles.toolTipWrapper} ${specialStyles}`}>
      <p>{toolTip}</p>
    </div>
  );
};

export default ToolTip;
