import { Dispatch } from "react";
import {
  QuickUploadActions,
  RelationText,
  RelationTextAndType,
  RelationType,
} from "../QuickUploadTypes";

export interface Props {
  quickUploadDispatcher: Dispatch<QuickUploadActions>;
}

export enum ActionChoices {
  CHANGE_TITLE_STRING,
  CHANGE_CONTENT_STRING,
  RESET_RELATIONSHIP,
  CHANGE_RELATIONSHIP,
  TOGGLE_RELATIONSHIP_DROPDOWN,
  SET_TAGS,
  TOGGLE_IS_PRIVATE,
  TOGGLE_SHARE_ALL,
  SET_SELECTED_USERS,
  RESET_SHARE,
  CLOSE,
  SET_INPUT_ERROR,
  CLEAR_INPUT_ERRORS,
  CLOSE_WARNING,
}

export enum InputErrors {
  TITLE_AND_CONTENT_REQUIRED = "* Title and Content fields are required",
  CONTENT_TOO_LONG = "* Only 15000 characters allowed",
  NO_USERS_TO_SHARE = "Please add users to share with or press cancel",
}

export type Actions =
  | { type: ActionChoices.CHANGE_TITLE_STRING; title: string }
  | { type: ActionChoices.CHANGE_CONTENT_STRING; content: string }
  | {
      type: ActionChoices.CHANGE_RELATIONSHIP;
      relText: RelationText;
      relType: RelationType;
    }
  | { type: ActionChoices.RESET_RELATIONSHIP }
  | { type: ActionChoices.TOGGLE_RELATIONSHIP_DROPDOWN }
  | { type: ActionChoices.SET_TAGS; tags: [] | string[] }
  | { type: ActionChoices.TOGGLE_IS_PRIVATE; boolean: boolean }
  | { type: ActionChoices.TOGGLE_SHARE_ALL; boolean: boolean }
  | { type: ActionChoices.SET_SELECTED_USERS; selectedUsers: [] | string[] }
  | { type: ActionChoices.RESET_SHARE }
  | { type: ActionChoices.SET_INPUT_ERROR; error: InputErrors | null }
  | { type: ActionChoices.CLEAR_INPUT_ERRORS }
  | { type: ActionChoices.CLOSE_WARNING; boolean: boolean };

export type State = {
  title: string;
  content: string;
  relationship: RelationTextAndType;
  isRelationshipDropdownOpen: boolean;
  tags: [] | string[];
  isPrivate: boolean;
  shareAll: boolean;
  selectedUsers: [] | string[];
  inputError: InputErrors | null;
  closeWarning: boolean;
};
