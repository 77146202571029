// import React, { useEffect, useState } from "react";

// COMPONENTS
import V2Search from "../V2Search/V2Search";
import logo from "../../../assets/img/light-pluaris.png";
// import QuickUploadBtn from "../QuickUploadBtn/QuickUploadBtn";
import Global from "../../global/Global";

// STYLES
import styles from "./TopMenu.module.scss";
import {
  useSocialLoginContext,
  useUserContext,
} from "../../context/allContexts";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";
import {
  AiFillCheckCircle,
  AiOutlineLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import toast from "react-hot-toast";
import { LINKEDIN_CLIENT_ID } from "../../../constants";
import { useEffect, useLayoutEffect, useState } from "react";
import algolands from "../../../assets/img/algo-update.png";
import AlgoRandModal from "./AlgoRandModal";
import { useTwitterProcess } from "../../../rq-hooks/useTwitterProcess";
import { useReducer } from "react";
import ToolTip from "../ToolTip/ToolTip";
import IntegrationTooltip from "./SocialShareModal/IntegrationTooltip";
import LogoutModal from "./SocialShareModal/LogoutModal";

interface Props {
  showSearch: boolean;
  showRewards?: boolean;
}

enum SocialTypes {
  LINKEDIN = "linkedin",
  TWITTER = "Twiiter",
}

const topMenuReducer = (state: any, action: any) => {
  switch (action.type) {
    case "show popup":
      return {
        ...state,
        showIntegratePopup: true,
      };
    case "transfer token":
      return {
        ...state,
        transferToken: action.payload,
      };
    case "hide popup":
      return {
        ...state,
        showIntegratePopup: false,
      };
    case "social type":
      return {
        ...state,
        type: action.socialType,
      };
    case "remove social type":
      return {
        ...state,
        type: null,
      };
    case "show logout modal":
      return {
        ...state,
        showLogoutModal: true,
        logoutType: action.logoutType,
      };
    case "hide logout modal":
      return {
        ...state,
        showLogoutModal: false,
      };
    default:
      return state;
  }
};

const initState = {
  showIntegratePopup: false,
  type: null,
  showLogoutModal: false,
  logoutType: null,
  transferToken: false,
};


const TopMenu = ({ showSearch = false }) => {
  const [state, dispatcher] = useReducer(topMenuReducer, initState);
  const { showIntegratePopup, type, showLogoutModal, logoutType } = state;
  // CONTEXT
  const { user } = useUserContext();
  const { socialLoginState, socialLoginDispatch } = useSocialLoginContext();
  const { linkedInUserData, twitterToken, twitterVerifier } = socialLoginState;
  const [text, setText] = useState<any>(null);

  // This hook is used to grab twitter oauth_token and this token is used for
  // redirecting user to twitter authorize page from where user can authrize app
  // for accessing user's twitter data.
  const { mutate } = useTwitterProcess({
    onSuccess: (data: any) => {
      const responseArray = data.split("&");
      // 'https://api.twitter.com/oauth/authorize?oauth_token=Z6eEdO8MOmk394WozF5oKyuAv855l4Mlqo7hxxxxxx'
      window.location.href = `https://api.twitter.com/oauth/authorize?${responseArray[0]}`;
    },
  });
  const [callBack, setCallBack] = useState<any>(null);
  const [showPopup, setShowPopup] = useState(false);

  useLayoutEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    setCallBack(`${location.origin}${location.pathname}`);
  }, []);

  useEffect(() => {
    if (showIntegratePopup) {
      setText(
        linkedInUserData && type === "linkedin"
          ? "Click here to logout from Linkedin"
          : !linkedInUserData && type === "linkedin"
          ? "Click here to integrate LinkedIn"
          : twitterToken && twitterVerifier && type === "twitter"
          ? "Click here to logout from Twitter"
          : "Click here to integrate Twitter"
      );
    } else {
      setText(null);
    }
  }, [showIntegratePopup]);

  const handleHandleSocialLogin = (type: SocialTypes) => {
    localStorage.removeItem("shareType");
    localStorage.removeItem("articleShared");
    localStorage.removeItem("activeNotebook");
    if (type === SocialTypes.LINKEDIN) {
      if (linkedInUserData) {
        return dispatcher({
          type: "show logout modal",
          logoutType: type,
        });
      }
      window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${callBack}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
    }
    if (type === SocialTypes.TWITTER) {
      if (twitterToken && twitterVerifier) {
        return dispatcher({
          type: "show logout modal",
          logoutType: type,
        });
      }
      mutate({
        callback: encodeURI(callBack),
      });
    }
  };
  // console.log(showIntegratePopup, "access")
  // useEffect(() => {
  //   if(showIntegratePopup) {
  //     setTimeout(() => {
  //       dispatcher({
  //         type: "hide popup",
  //         showHoverPopup: false
  //       })
  //     }, 2000)
  //   }
  // }, [showIntegratePopup])
  const setHover = (socialType: string) => {
    dispatcher({ type: "show popup" });
    dispatcher({ type: "social type", socialType });
  };
  const setHideHover = () => {
    dispatcher({ type: "hide popup" });
    dispatcher({ type: "remove social type", socialType: null });
  };

  return (
    
    <div className={`${styles.TopMenuContainer}`}>
      <div className={styles.topMenu__logoNNameNButtonContainer}>
        <img
          className={styles.logo}
          src={logo}
          alt="Nowigence Logo"
          style={{ maxHeight: "30px" }}
        />
      </div>
      <div className={`${styles.searchContainer}`}>
        {showSearch && <V2Search absolutePos={true} darkTheme={true} />}
      </div>
      <div className={`${styles.mainWrapper}`}>
        <div className={styles.socialWrapper}>
          {/* <div
            className={styles.iconWrapper}
            onClick={() => {
              user.walletAddress && user.walletAddress.length > 0
                ? setShowPopup(true)
                : toast.error("Please register in Algorand");
            }}
            style={{ cursor: "pointer" }}
          >
            <img src={algolands} alt="AlgoRand" className={styles.algoIcon} />
            {user.walletAddress && user.walletAddress.length > 0 ? (
              <AiFillCheckCircle className={styles.checkAlgoRand} />
            ) : (
              ""
            )}
          </div> */}
          <div
            className={styles.linkedInWrapper}
            onMouseEnter={() => {
              setHover("linkedin");
            }}
            onMouseLeave={() => {
              setHideHover();
            }}
          >
            <AiOutlineLinkedin
              size={35}
              className={`${styles.icon} ${styles.linkedIn}`}
              onClick={() => handleHandleSocialLogin(SocialTypes.LINKEDIN)}
              onMouseEnter={() => {
                setHover("linkedin");
              }}
              onMouseLeave={() => {
                setHideHover();
              }}
            />
            {linkedInUserData ? (
              <AiFillCheckCircle className={styles.checkLinkdedin} />
            ) : (
              ""
            )}
          </div>
          <div
            className={styles.iconWrapper}
            onMouseEnter={() => {
              setHover("twitter");
            }}
            onMouseLeave={() => {
              setHideHover();
            }}
          >
            <AiOutlineTwitter
              size={35}
              className={`${styles.icon} ${styles.twitter}`}
              onClick={() => handleHandleSocialLogin(SocialTypes.TWITTER)}
              onMouseEnter={() => {
                setHover("twitter");
              }}
              onMouseLeave={() => {
                setHideHover();
              }}
            />
            {twitterToken && twitterVerifier ? (
              <AiFillCheckCircle className={styles.checkTwitter} />
            ) : (
              ""
            )}
          </div>
        </div>
        {/* {
        showRewards ? 
        <div className={styles.rewardsProgress}>
          <div className={styles.progress}>
            <div className={styles.barOverflow}>
              <div className={styles.bar} style={{
                transform: "rotate("+ (45+((((user.nearestPoint - user.totalPoints) / user.nearestPoint) * 100) *1.8)) +"deg)"
              }}></div>
            </div>
          </div>
          <p className={styles.nextToken}>{user.nearestPoint - user.totalPoints} points for next token</p>
        </div>
        // <div className={styles.semiCircle}>
        // </div>
        // <div className={styles.circleWrap}>
        //   <div className={styles.circle}>
        //     <div className={`${styles.mask} ${styles.full}`}>
        //       <div className={styles.fill}></div>
        //     </div>
        //     <div className={`${styles.mask} ${styles.half}`}>
        //       <div className={styles.fill}></div>
        //     </div>
        //     <div className={styles.insideCircle}> 75% </div>
        //   </div>
        // </div>
        
        :
        ""
      } */}
        <AlgoRandModal
          close={() => setShowPopup(false)}
          showPopup={showPopup}
          transferToken={state.transferToken}
          setTransferToken={(status: boolean) =>
            dispatcher({ type: "transfer token", payload: status })
          }
        />
        <LogoutModal
          close={() => {
            dispatcher({
              type: "hide logout modal",
            });
          }}
          showPopup={showLogoutModal}
          type={logoutType}
        />
        {/* {showIntegratePopup && <LinkedIn type={type} text/>} */}
        {showIntegratePopup && text && (
          <IntegrationTooltip toolTip={text} type={type} />
        )}
        {/* {
        true && <ToolTip 
          toolTip={text}
        />
      } */}
      </div>
      <Global />
    </div>
  );
};

export default TopMenu;
