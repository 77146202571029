export const getBulletPoints = (data) => {
  if (data.driver[0] !== "This article does not contain a driver.") {
    const post = `Summary: \n${data.summary?.map(
      (sentence) => `\n\t➡ ${sentence}`
    )}\n⁃⁃⁃⁃⁃⁃⁃⁃\nDrivers: \n${data.driver.map(
      (sentence) => `\n\t➡ ${sentence}`
    )}`;
    return post.replace(/,/g, "");
  } else {
    const post = `Summary: \n${data.summary?.map(
      (sentence) => `\n\t➡ ${sentence}`
    )}`;
    return post.replace(/,/g, "");
  }
};
