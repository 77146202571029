import axios from "axios";
import { useMutation } from "react-query";
import { PYTHON_REST_BASE_ENDPOINT } from "../../constants";
// axiosConfig axios is for protected routes to node backend. python has none of
// this implemented so can't use custom axios have to use default axios
// import { axios } from "../utils/axiosConfig";

interface URLUploadInput {
  url: string;
  company_id: string;
  user_id: string;
  share_all?: boolean;
  share_to?: string[];
  tags?: string[];
  user_relationship_type?: string;
  user_relationship_value?: string;
  from?: string;
  signup?: boolean;
}

export function useURLUpload(options?: any) {
  // console.log("running useURLUpload");

  return useMutation(async (urlUploadInput: URLUploadInput) => {
    try {
      const data = urlUploadInput;
      const { data: resData } = await axios.post(
        `${PYTHON_REST_BASE_ENDPOINT}/quick_upload/url`,
        data
      );
      if (resData) {
        // console.log("resData from python url upload: ", resData);
        return resData;
      }
    } catch (err) {
      console.log("err from url upload python: ", err);
      throw new Error(err.response.data.message);
    }
  }, options);
}
