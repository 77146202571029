/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react';

import Select from 'react-select';


export default function CompanySelect({ company, setCompany }) {
  const colourOptions = [
    { value: 't-mobile', label: 'T-Mobile', color: '#FFC400' },
    { value: 'airbus', label: 'Airbus', color: '#00B8D9' },
    { value: 'credit-suisse', label: 'Credit Suisse', color: '#0052CC' },
    // { value: 'Mahindra', label: 'Mahindra', color: '#5243AA', isDisabled: true },
  ];

  useEffect(() => {
    if (!company) setCompany(colourOptions[0].value)
  }, [])

  const companyChange = (data) => {
    setCompany(data.value);
  }


  return (
    <>
      <Select
        className="basic-single w-52 pt-1 z-20 text-base"
        classNamePrefix="select"
        defaultValue={colourOptions[0]}
        isClearable
        isSearchable
        name="color"
        options={colourOptions}
        onChange={companyChange}
      />

      <div
        style={{
          color: 'hsl(0, 0%, 40%)',
          display: 'inline-block',
          fontSize: 19,
          fontStyle: 'italic',
          marginTop: '1em',
        }}
      >
      </div>

    </>
  );
};