import React, { FC, useReducer } from "react";

import AskMeTrending from "./AskMeTrending/AskMeTrending";
import AskMeWorldChloroplethChart from "./AskMeWorldChloroplethChart/AskMeWorldChloroplethChart";
import AMDrivingDecisions from "../AMDrivingDecisions/AMDrivingDecisions";
import AMRisks from "../AMRisks/AMRisks";
import AMOpp from "../AMOpp/AMOpp";

import styles from "./AMTopicStats.module.scss";
// import { AskMeData } from "../../../../rq-hooks/pythonRestApi/useAskMe";

interface State {
  show: string;
}

type Actions = { type: "what to show"; show: string };

const topicStatsReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "what to show":
      return {
        ...state,
        show: action.show === state.show ? "" : action.show,
      };
  }
  return state;
};

const initState = {
  // show: false,
  // TODO: return to false before pushing
  show: "trending",
};

interface Props {
  // answer: AskMeData;
  answer: any;
  topic: string;
}

const AMTopicStats: FC<Props> = ({ answer, topic }) => {
  const [topicStatsState, topicStatsDispatch] = useReducer(
    topicStatsReducer,
    initState
  );
  const { show } = topicStatsState;
  return (
    <div className={styles.MQHWrapper}>
      <div
        className={styles.questionWrapper}
        onClick={() =>
          topicStatsDispatch({ type: "what to show", show: "trending" })
        }>
        <p className={styles.questionText}>What is trending for {topic}?</p>
        {show === "trending" ? <AskMeTrending topic={topic} /> : null}
      </div>
      <div className={styles.questionWrapper}>
        <p
          className={styles.questionText}
          onClick={() =>
            topicStatsDispatch({ type: "what to show", show: "drivers" })
          }>
          What is driving decisions for {topic}?
        </p>
        {show === "drivers" && <AMDrivingDecisions topic={topic} />}
      </div>
      <div className={styles.questionWrapper}>
        <p
          className={`${styles.questionText}`}
          onClick={() =>
            topicStatsDispatch({ type: "what to show", show: "risks" })
          }>
          What are the risks for {topic}?
        </p>
        {show === "risks" && <AMRisks topic={topic} />}
      </div>
      <div className={styles.questionWrapper}>
        <p
          className={styles.questionText}
          onClick={() =>
            topicStatsDispatch({ type: "what to show", show: "opp" })
          }>
          What's the opportunities for {topic}?
        </p>
        {show === "opp" && <AMOpp topic={topic} />}
      </div>
      <div
        className={styles.questionWrapper}
        onClick={() =>
          topicStatsDispatch({ type: "what to show", show: "world" })
        }>
        <p className={styles.questionText}>Where is {topic} active?</p>
        <div>
          {show === "world" ? (
            <AskMeWorldChloroplethChart topic={topic} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AMTopicStats;
