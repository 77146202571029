import React, { useState, useEffect } from "react";
import CloseModalBtn from "../../../Components/CloseModalBtn/CloseModalBtn";

import styles from "./MyAccountInfoModal.module.scss";


const MyAccountInfoModal = ({ close, accountInfoModal }) => {
  const [shouldRender, setShouldRender] = useState(accountInfoModal);

  useEffect(() => {
    if (accountInfoModal) setShouldRender(true);
  }, [accountInfoModal]);

  const onAnimationEnd = () => {
    if (!accountInfoModal) setShouldRender(false);
  };
  return (
    shouldRender && (
      <React.Fragment>
        <div
          onAnimationEnd={onAnimationEnd}
          className={`${styles.backdrop} ${
            accountInfoModal ? styles.backdrop : styles.fadeOut
          }`}
          onClick={() => close()}
        />
        <div
          className={`${styles.myMemoryInfoModalWrapper} ${
            accountInfoModal ? styles.myMemoryInfoModalWrapper : styles.slideUp
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.closeBtn}>
            <CloseModalBtn theme="light" close={close} />
          </div>
          <h2>My Account page shows your profile data.</h2>
          <div>
            <ul>
                <li>Change you password or verify your email to be eligible for transferring tokens to your peers.</li>
                <li>Use 'Generate SDK Cred' button to generate your client id and client secret.</li>
                <li>Once generated use client id and secret to manage your digital assets related tasks.</li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default MyAccountInfoModal;
