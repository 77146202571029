import React, { Dispatch, FC, SetStateAction } from "react";
import { useContentModalQuery, WhichDb } from "../../../../../codeGenFE";
import CloseModalBtn, {
  CloseModalBtnTheme,
} from "../../../../Components/CloseModalBtn/CloseModalBtn";

import styles from "./ContentModal.module.scss";

interface Props {
  articleId: string;
  whichDb: WhichDb;
  isShowingContentModal: boolean;
  setIsShowingContentModal: Dispatch<SetStateAction<boolean>>;
}

const ContentModal: FC<Props> = ({
  articleId,
  whichDb,
  isShowingContentModal,
  setIsShowingContentModal,
}) => {
  const { data, status, error } = useContentModalQuery({
    articleId: articleId,
    whichDB: whichDb,
  });

  const title = data?.article._source.title;
  const content = data?.article._source.content;
  const orig_url = data?.article._source.orig_url;
  const sharedBy = data?.article.sharedBy;

  return (
    <div
      className={styles.backdrop}
      onClick={() => setIsShowingContentModal(false)}
    >
      <div className={styles.contentModalWrapper}>
        <h2>{title}</h2>
        {sharedBy && <p>Shared By: {sharedBy}</p>}
        <pre>{content}</pre>
        <div className={styles.closeBtnLayout}>
          <CloseModalBtn
            close={() => setIsShowingContentModal(false)}
            theme={CloseModalBtnTheme.LIGHT}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentModal;
