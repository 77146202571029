import React, { FC } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import styles from "./ComponentMessagingOverlay.module.scss";

// NOTES:
// - IMPORTANT: for this to work, you will need to put position: relative on the element you are trying to overlay and this component will need to have nothing between it and that component, ie no other parents (or at least none with relative positioning)
// - If using a link with this component, you MUST give a linkMessage and a path.  This is for internal app links only
// - If using an icon, only use one.

export enum CmoTheme {
  DARK = "dark",
  LIGHT = "light",
}

interface Props {
  close: () => void;
  message: string;
  path?: string;
  linkMessage?: string;
  showCheck?: boolean;
  showX?: boolean;
  theme?: CmoTheme;
  buttonText?: string;
}

const ComponentMessagingOverlay: FC<Props> = ({
  close, // function to run to close this overlay (and anything else)
  message, // message to display
  path = null, // path to another page if there is a link
  linkMessage, // message to be inside of link to another page
  showCheck = false, // positive message like a success message
  showX = false, // negative message like a failure message
  theme = CmoTheme.DARK,
  buttonText = "OKAY", // Allows custom button text messages ("Retry", "Cancel", etc.)
}) => {
  const location = useLocation();

  return (
    <div
      className={`${styles.overlayWrapper} ${
        theme !== CmoTheme.DARK ? styles.light : null
      }`}
    >
      {showCheck && <AiOutlineCheck className={styles.iconCheck} />}
      {showX && <AiOutlineClose className={styles.iconX} />}
      <pre className={styles.modal_p}>{message}</pre>
      {path && (
        <Link className={styles.link} to={path}>
          {linkMessage}
        </Link>
      )}
      <button className={styles.overlayBtn} onClick={() => close()}>
        {buttonText}
      </button>
    </div>
  );
};

export default ComponentMessagingOverlay;
