import React, { FC, useEffect, useState } from "react";
// import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { useStateLabel } from "../../../../hooks/stateLabeler";

// context
import {
  useUserContext,
  useNewsContext,
} from "../../../../context/allContexts";

// styles
import styles from "./RTNAOneNewsArticle.module.scss";

// components
import ToolTip from "../../../../Components/ToolTip/ToolTip";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../../Components/LoadingCube/LoadingCube";
import { usePersistTopicsCheck } from "../../../../../rq-hooks/pythonRestApi/usePersistTopicsCheck";
import { useQueryClient } from "react-query";

interface Props {
  dataForOne: any;
}
const RTNAOneNewsArticle: FC<Props> = ({ dataForOne }) => {
  // a normal article from the db will use shortDesc.  Content was added so that
  // if an article is supplemented from python route it will also have some
  // content to display as those don't have a shortDesc.
  const {
    title,
    orig_url,
    shortDesc,
    content,
    sequenceId,
  } = dataForOne._source;
  const { user } = useUserContext();
  // const qClient = useQueryClient();
  // const {} = qClient.getQueryCache;

  const qClient = useQueryClient();

  const { mutate, isLoading } = usePersistTopicsCheck({
    onSuccess: () => {
      qClient.invalidateQueries("fetchTopFiveNewsPreview");
    },
  });
  // state is for the ToolTip
  const [isHovered, setIsHovered] = useState<string | boolean>(false);
  // const [loading, setLoading] = useStateLabel(false, "loading");
  // const [error, setError] = useStateLabel(null, "error");
  const [showSpan, setShowSpan] = useState<string | boolean>(true);

  const handleHoverOn = (string: string | boolean) => {
    if (content && !shortDesc) {
      setIsHovered("informUser");
    }
  };

  const handleHoverOff = (string: string | boolean) => {
    if (content && !shortDesc) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    if (shortDesc) {
      setIsHovered(false);
    }
  }, [shortDesc]);

  // if (error) {
  //   return (
  //     <div className={styles.errorContainer}>
  //       <AiOutlineClose className={styles.iconX} />
  //       <p className={styles.error__p}>{error}</p>
  //     </div>
  //   );
  // }

  return (
    <>
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <LoadingCube height={150} width={150} theme={LoadingCubeTheme.DARK} />
        </div>
      )}
      <a
        className={styles.oneNewsArticle__link}
        href={orig_url && orig_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p className={styles.oneNewsArticle__link__p}>{title && title}</p>
      </a>
      <div className={styles.oneNewsArticle__descNBadgeWrapper}>
        <p
          className={styles.oneNewsArticle__desc}
          onMouseEnter={() => handleHoverOn("informUser")}
          onMouseLeave={() => handleHoverOff(false)}
        >
          {content && !shortDesc && showSpan && (
            <span
              className={styles.oneNewsArticle__badge}
              onClick={() =>
                mutate({
                  userId: user._id,
                  comp: user.company,
                  sequenceIds: [sequenceId],
                })
              }
            >
              {shortDesc ? null : "New!"}
            </span>
          )}
          {shortDesc ? shortDesc : content}
        </p>
        {isHovered === "informUser" && (
          <ToolTip
            toolTip="Add Article to My Memory by clicking on New"
            styled="RTNAOneNewsArticle"
          />
        )}
      </div>
    </>
  );
};

export default RTNAOneNewsArticle;
