import { useState } from 'react'

import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import './TabComponent.scss'
import { TabData } from './TabComponent.config'
import CompanySelect from '../CompanySelect/CompanySelect'

function TabComponent() {
  const [company, setCompany] = useState("");

  return (
    <div className='pt-2'>
      <CompanySelect setCompany={setCompany} />
      <Tabs
        defaultActiveKey={TabData[0].label}
        id='uncontrolled-tabs'
        className='mb-3 sticky top-0 bg-white z-10'
      >
        {TabData.map(({ label, value, desc }) => (
          <Tab eventKey={label} title={label} key={value}>
            {desc(company)}
          </Tab>
        ))}
      </Tabs>
    </div>
  )
}

export default TabComponent;
