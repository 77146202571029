import React from "react";
// CONTEXT
import {
  useNotebookContext,
  useViewPortContext,
  useNavContext,
} from "../../context/allContexts";
import CloseModalBtn, {
  CloseModalBtnTheme,
} from "../CloseModalBtn/CloseModalBtn";
import styles from "./Notebook.module.scss";
// COMPONENTS
import SelectANote from "./SelectANote/SelectANote";
import SingleNote from "./SingleNote/SingleNote";

const Notebook = () => {
  const { whichMenu, navDispatch } = useNavContext();
  // @ts-ignore
  const { width } = useViewPortContext();
  const { notebookState } = useNotebookContext();

  const { showSelectANote, fullscreen } = notebookState;

  if (whichMenu !== "notebook") return null;

  return (
    <div
      className={`${styles.notebook__container} ${
        fullscreen
          ? styles.notebook__container__fullWidth
          : styles.notebook__container__partialWidth
      }`}
    >
      <div className={styles.notebook__closeBtn}>
        <CloseModalBtn
          // close={() => notebookDispatch({ type: "closed" })}
          close={() => navDispatch({ type: "change which menu", menu: "" })}
          theme={
            width > 1211 ? CloseModalBtnTheme.DARK : CloseModalBtnTheme.LIGHT
          }
        />
      </div>

      {/* Select a note */}
      {showSelectANote && <SelectANote />}
      {/* note WYSIWYG */}
      {!showSelectANote && <SingleNote />}
    </div>
  );
};

export default Notebook;
