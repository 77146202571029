import styles from "./OverwriteModal.module.scss";
import {
  useSearchContext,
  useNavContext,
} from "../../../../../context/allContexts";
import { useSaveFiltersContext } from "../../SaveFiltersContext";
import { useUpdateFilterMutation } from "../../../../../../codeGenFE";
import { useQueryClient } from "react-query";

import { AiOutlineWarning } from "react-icons/ai";
import { customErrorHandler } from "../../../../../../utils/customErrorHandler";
import { SearchContextActions } from "../../../../../context/allContexts/SearchContext/SearchContextTypes";
import toast from "react-hot-toast";

const OverwriteModal = () => {
  const { searchState, searchDispatcher } = useSearchContext();
  // @ts-ignore
  const { navDispatch } = useNavContext();
  const { saveFilterState, SaveFiltersDispatch } = useSaveFiltersContext();
  const { filterNameInput, duplicateFilter, filterToEdit } = saveFilterState;

  // Save functionality
  const qClient = useQueryClient();

  const { mutate } = useUpdateFilterMutation({
    onSuccess: (data) => {
      SaveFiltersDispatch({ type: "clear" });
      qClient.invalidateQueries("getUserSavedFilters");
      navDispatch({ type: "set open filter", name: "" });
      searchDispatcher({
        type: SearchContextActions.LOAD_SAVED_FILTER_SET,
        filters: data.updateFilter,
      });
      toast.success(`Successfully updated ${data.updateFilter.name}!`);
    },
    onError: (error) => {
      console.log("error from useSaveFilterMutation :>> ", error);
      SaveFiltersDispatch({
        type: "save error",
        msg: customErrorHandler(`${error}`),
        retry: overwrite,
      });
    },
  });

  function overwrite() {
    mutate({
      updateSaveFilterId: duplicateFilter?._id
        ? duplicateFilter?._id
        : filterToEdit!._id,
      updateSaveFilterInput: {
        name: filterNameInput ? filterNameInput : filterToEdit!.name,
        topics: searchState.searchTopicChoice,
        relationshipType: searchState.relSearchParams?.category
          ? searchState.relSearchParams.category
          : null,
        relationshipText: searchState.relSearchParams?.relationship
          ? searchState.relSearchParams.relationship
          : null,
        tag: searchState.tagFilterChoice,
        dataSource: searchState.dataSourceChoice,
        category: searchState.categoryChoice,
        driver: searchState.driverChoice,
        outcome: searchState.outcomeChoice,
        country: searchState.countryChoice,
        otherLocation: searchState.otherLocation,
        org: searchState.org,
        person: searchState.person,
        product: searchState.product,
      },
    });
  }

  function cancel() {
    SaveFiltersDispatch({
      type: "set duplicate filter",
      filter: null,
    });
  }

  return (
    <div className={styles.overWriteModalWrapper}>
      <AiOutlineWarning className={styles.warningIcon} />
      <p className={styles.warningContent}>
        "{duplicateFilter?.name ? duplicateFilter.name : filterToEdit?.name}"
        filter already exists. Do you want to replace it?
      </p>
      <div className={styles.btnWrapper}>
        <button className={styles.cancelBtn} onClick={cancel}>
          cancel
        </button>
        <button className={styles.replaceBtn} onClick={overwrite}>
          Replace
        </button>
      </div>
    </div>
  );
};

export default OverwriteModal;
