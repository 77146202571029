import { useReducer } from "react";

// components
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import LoadingCube from "../../../Components/LoadingCube/LoadingCube";

// context
import { useUserContext } from "../../../context/allContexts";

// styles
import styles from "./ChangePassword.module.scss";
import { useChangePassword } from "../../../../rq-hooks/useChangePassword";

const passwordReducer = (state, action) => {
  switch (action.type) {
    case "input":
      return {
        ...state,
        [action.input]: action.value,
      };
    case "close":
      return {
        ...state,
        oldPass: "",
        newPass: "",
        confirmPass: "",
        uiError: "",
      };
    case "uiError":
      return {
        ...state,
        uiError: action.payload,
      };
    case "close error":
      return {
        ...state,
        uiError: "",
      };
    default:
      break;
  }
  return state;
};

const initState = {
  oldPass: "",
  newPass: "",
  confirmPass: "",
  uiError: "",
};

function ChangePassword({ showChangePass, setShowChangePass }) {
  const { user } = useUserContext();
  const [state, passChangeDispatcher] = useReducer(passwordReducer, initState);

  const { oldPass, newPass, confirmPass, uiError } = state;
  const { mutate, status, error, reset } = useChangePassword({
    onSuccess: () => {
      // console.log("data from useChangePassword", data);
    },
    onError: (err) => {
      console.log("err from useChangePassword", err);
    },
    onSettled: () => {
      // reset();
    },
  });

  const submitNewPass = async () => {
    reset();
    if (newPass !== confirmPass) {
      passChangeDispatcher({
        type: "uiError",
        payload: "New password and confirm password do not match",
      });
    } else if (oldPass === "" || newPass === "" || confirmPass === "") {
      passChangeDispatcher({
        type: "uiError",
        payload: "Please fill out every field",
      });
    } else if (newPass.length < 12) {
      passChangeDispatcher({
        type: "uiError",
        payload: "Sorry, your password must be more than 12 characters",
      });
    } else {
      mutate({ email: user.email, oldPassword: oldPass, newPassword: newPass });
    }
  };

  if (showChangePass) {
    return (
      <div className={styles.changePass__div}>
        {/* message is at bottom */}
        <Message
          status={status}
          error={error}
          state={state}
          uiError={uiError}
          reset={reset}
          passChangeDispatcher={passChangeDispatcher}
          setShowChangePass={setShowChangePass}
        />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitNewPass();
          }}
          className={styles.changePass__form}>
          <label className={styles.label}>
            Old Password:
            <input
              className={styles.input}
              type="password"
              value={oldPass}
              onChange={(e) =>
                passChangeDispatcher({
                  type: "input",
                  input: "oldPass",
                  value: e.target.value,
                })
              }
            />
          </label>
          <label className={styles.label}>
            New Password:
            <input
              className={styles.input}
              type="password"
              value={newPass}
              onChange={(e) =>
                passChangeDispatcher({
                  type: "input",
                  input: "newPass",
                  value: e.target.value,
                })
              }
            />
          </label>
          <label className={styles.label}>
            Confirm Password:
            <input
              className={styles.input}
              type="password"
              value={confirmPass}
              onChange={(e) =>
                passChangeDispatcher({
                  type: "input",
                  input: "confirmPass",
                  value: e.target.value,
                })
              }
            />
          </label>
          <div>
            <button className={styles.submit} type="submit">
              OKAY
            </button>
            <button
              className={styles.cancel}
              onClick={() => {
                passChangeDispatcher({ type: "close" });
                setShowChangePass(false);
              }}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return null;
  }
}

function Message({
  status,
  error,
  passChangeDispatcher,
  setShowChangePass,
  reset,
  uiError,
}) {
  if (status === "loading") {
    return <LoadingCube />;
  } else if (status === "success") {
    return (
      <div className={styles.success__div}>
        <AiOutlineCheck className={styles.success__icon} />
        <p className={styles.success__p}>
          YOU HAVE SUCCESSFULLY CHANGED YOUR PASSWORD.
        </p>
        <button
          className={styles.success__button}
          onClick={() => {
            reset();
            setShowChangePass(false);
            passChangeDispatcher({ type: "close" });
          }}>
          OKAY
        </button>
      </div>
    );
  } else if (status === "error" || uiError) {
    return (
      <div className={styles.error__div}>
        <AiOutlineClose className={styles.error__icon} />
        <p className={styles.error__p}>{uiError ? uiError : error.message}</p>
        <button
          className={styles.error__btn}
          onClick={() => {
            reset();
            passChangeDispatcher({ type: "close error" });
            // setShowChangePass(false);
          }}>
          OKAY
        </button>
      </div>
    );
  } else {
    return null;
  }
}

export default ChangePassword;
