import React, { FC, useEffect, useState } from "react";
// import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  // Sphere,
  // Graticule
} from "react-simple-maps";
// import { axisBottom } from "d3";
import ReactTooltip from "react-tooltip";
import countryFilterConverter from "./countryFilterConverter";

// import axios from "axios";

// ============================================================================================================== //
//  NOTE: When you change this component out to be hand created D3 graph, remove react-tooltip as an npm package
// ============================================================================================================== //

interface Props {
  data: any;
  max: number | undefined;
  mapColor: any;
  mapBackgroundColor: any;
  dataColor: any;
  openAddToSearchFilters?: (arg: string) => void;
}

// ==== old geo url ===
// const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

// ==== new geo url ===
const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const WorldChloroplethChart: FC<Props> = ({
  data,
  max = 1,
  mapColor,
  mapBackgroundColor,
  dataColor,
  openAddToSearchFilters,
}) => {
  const colorScale = scaleLinear()
    .domain([0, max])
    // .range(["#ffffff", "#003cbb"]);
    .range([mapColor, dataColor]);

  // const [data, setData] = useState([
  //   {
  //     articles: "0",
  //     ISO3: "",
  //   },
  // ]);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [data]);

  if (data?.length > 0) {
    return (
      <>
        <ComposableMap
          projectionConfig={{
            rotate: [-10, 0, 0],
            scale: 170,
          }}
          style={{ backgroundColor: mapBackgroundColor, borderRadius: "12px" }}
          width={800}
          height={425}
        >
          {/* Lat long lines and border for the globe if desired */}
          {/* <Sphere stroke="#E4E5E6" strokeWidth={0.5} /> */}
          {/* <Graticule stroke="#E4E5E6" strokeWidth={0.5} /> */}
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const d = data.find(
                  (s: any) => s.ISO3 === geo.id
                );
                return (
                  <Geography
                    data-tip={`${geo.properties.name}: ${
                      d && d.value ? d.value : 0
                    }`}
                    key={geo.rsmKey}
                    geography={geo}
                    fill={d ? colorScale(d.value) : mapColor}
                    onClick={() => {
                      if (openAddToSearchFilters){
                        openAddToSearchFilters(
                          countryFilterConverter(geo.properties.name)
                        );
                      }
                    }}
                    onMouseEnter={() => {
                      ReactTooltip.rebuild();
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
        <ReactTooltip />
      </>
    );
  } else {
    return (
      <>
        <ComposableMap
          projectionConfig={{
            rotate: [-10, 0, 0],
            scale: 170,
          }}
          style={{ backgroundColor: mapBackgroundColor, borderRadius: "12px" }}
          width={800}
          height={425}
        >
          {/* Lat long lines and border for the globe if desired */}
          {/* <Sphere stroke="#E4E5E6" strokeWidth={0.5} /> */}
          {/* <Graticule stroke="#E4E5E6" strokeWidth={0.5} /> */}
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                return (
                  <Geography key={geo.rsmKey} geography={geo} fill={mapColor} />
                );
              })
            }
          </Geographies>
        </ComposableMap>
        <ReactTooltip />
      </>
    );
  }
};

export default WorldChloroplethChart;

// country 2 letter and full name to three letter lookup
// 2 letter === iso 2
// 3 letter  === iso 3
