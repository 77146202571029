import { useEffect } from "react";
import { useNavContext, useSearchContext } from "../../../context/allContexts";
import RelationshipFilter from "./FilterComponents/RelationshipFilter";
import TagsFilter from "./FilterComponents/TagsFilter";
import TopicFilter from "./FilterComponents/TopicFilter";
import DataSourceFilter from "./FilterComponents/DataSourceFilter";
import CategoriesFilter from "./FilterComponents/CategoryFilter";
import DriverFilter from "./FilterComponents/DriverFilter";
import OutcomeFilter from "./FilterComponents/OutcomeFilter";

import styles from "./Filters.module.scss";
// import SingleSelectDropdown from "../../SingleSelectDropdown/SingleSelectDropdown";
import CountryFilter from "./FilterComponents/CountryFilter";
// import {
//   useGetUserSavedFiltersQuery,
//   useDeleteFilterMutation,
// } from "../../../../codeGenFE";
import { SearchContextActions } from "../../../context/allContexts/SearchContext/SearchContextTypes";
import SaveFiltersInput from "./FilterComponents/SaveFiltersInput/SaveFiltersInput";
// import OverwriteModal from "./FilterComponents/OverwriteModal/OverwriteModal";
import CloseModalBtn, {
  CloseModalBtnTheme,
} from "../../CloseModalBtn/CloseModalBtn";
import SavedFiltersDropdown from "./FilterComponents/SavedFiltersDropdown/SavedFiltersDropdown";
import CompanyFilter from "./FilterComponents/CompanyFilter";
import OtherLocationFilter from "./FilterComponents/OtherLocationFilter";
import ProductFilter from "./FilterComponents/ProductFilter";
import PersonFilter from "./FilterComponents/personFilter";

const Filters = () => {
  // const qClient = useQueryClient();

  const { filterAnimationState, navDispatch, openFilter } = useNavContext();
  const { searchDispatcher } = useSearchContext();
  // const { currentActiveFilter } = searchState;

  const animationDuration = 300;

  useEffect(() => {
    if (filterAnimationState === `closing`) {
      setTimeout(() => {
        navDispatch({ type: "toggle filters", filterState: "closed" });
      }, animationDuration);
    } else if (filterAnimationState === `opening`) {
      setTimeout(() => {
        navDispatch({ type: "toggle filters", filterState: "open" });
      }, animationDuration);
    }
  }, [filterAnimationState]);

  return (
    <div className={styles.container} data-isfiltersopen={filterAnimationState}>
      <h3 className={styles.header}>Filters</h3>
      {/* desktop close btn */}
      <div className={`${styles.closeBtn} ${styles.desktopOnly}`}>
        <CloseModalBtn
          close={() => navDispatch({ type: "close filters" })}
          theme={CloseModalBtnTheme.DARK}
        />
      </div>
      {/* Mobile close button */}
      <div className={`${styles.closeBtn} ${styles.mobileOnly}`}>
        <CloseModalBtn
          close={() =>
            navDispatch({ type: "toggle filters", filterState: "closing" })
          }
          theme={CloseModalBtnTheme.DARK}
        />
      </div>

      <button
        className={styles.button}
        onClick={() =>
          searchDispatcher({ type: SearchContextActions.RESET_FILTERS })
        }>
        Reset
      </button>
      <SaveFiltersInput />

      <div className={styles.dropdowns}>
        {/* Saved Filters */}
        <div
          className={styles.dropdown}
          data-open={`${openFilter === "saved filters"}`}>
          <SavedFiltersDropdown
            isOpen={openFilter === "saved filters"}
            changeOpenFilter={() =>
              navDispatch({
                type: "set open filter",
                name: openFilter === "saved filters" ? "" : "saved filters",
              })
            }
          />
        </div>
        {/* Topics Filter */}

        <TopicFilter
          openFilter={openFilter}
          setOpenFilter={() =>
            navDispatch({
              type: "set open filter",
              name: openFilter === "topic" ? "" : "topic",
            })
          }
        />
        {/* Relationships Filter */}
        <RelationshipFilter
          openFilter={openFilter}
          setOpenFilter={() =>
            navDispatch({
              type: "set open filter",
              name: openFilter === "relationship" ? "" : "relationship",
            })
          }
        />
        {/* Tags Filter */}
        <TagsFilter
          openFilter={openFilter}
          setOpenFilter={() =>
            navDispatch({
              type: "set open filter",
              name: openFilter === "tags" ? "" : "tags",
            })
          }
        />
        {/* Data Sources Filter */}
        <DataSourceFilter
          openFilter={openFilter}
          setOpenFilter={() =>
            navDispatch({
              type: "set open filter",
              name: openFilter === "data source" ? "" : "data source",
            })
          }
        />
        {/* Categories Filter */}
        <CategoriesFilter
          openFilter={openFilter}
          setOpenFilter={() =>
            navDispatch({
              type: "set open filter",
              name: openFilter === "category" ? "" : "category",
            })
          }
        />
        {/* Drivers Filter */}
        <DriverFilter
          openFilter={openFilter}
          setOpenFilter={() =>
            navDispatch({
              type: "set open filter",
              name: openFilter === "driver" ? "" : "driver",
            })
          }
        />
        {/* Outcomes Filter */}
        <OutcomeFilter
          openFilter={openFilter}
          setOpenFilter={() =>
            navDispatch({
              type: "set open filter",
              name: openFilter === "outcome" ? "" : "outcome",
            })
          }
        />
        {/* Outcomes Filter */}
        <div
          className={styles.dropdown}
          data-open={`${openFilter === "country"}`}>
          <CountryFilter
            openFilter={openFilter}
            setOpenFilter={() =>
              navDispatch({
                type: "set open filter",
                name: openFilter === "country" ? "" : "country",
              })
            }
          />
        </div>
        {/* Trending Filters */}
        <div
          className={styles.dropdown}
          data-open={`${openFilter === "company"}`}>
          <CompanyFilter
            isOpen={openFilter === "company"}
            toggleOpenFilter={() =>
              navDispatch({
                type: "set open filter",
                name: openFilter === "company" ? "" : "company",
              })
            }
          />
        </div>
        <div
          className={styles.dropdown}
          data-open={`${openFilter === "product"}`}>
          <ProductFilter
            isOpen={openFilter === "product"}
            toggleOpenFilter={() =>
              navDispatch({
                type: "set open filter",
                name: openFilter === "product" ? "" : "product",
              })
            }
          />
        </div>
        <div
          className={styles.dropdown}
          data-open={`${openFilter === "person"}`}>
          <PersonFilter
            isOpen={openFilter === "person"}
            toggleOpenFilter={() =>
              navDispatch({
                type: "set open filter",
                name: openFilter === "person" ? "" : "person",
              })
            }
          />
        </div>
        <div
          className={styles.dropdown}
          data-open={`${openFilter === "other location"}`}>
          <OtherLocationFilter
            isOpen={openFilter === "other location"}
            toggleOpenFilter={() =>
              navDispatch({
                type: "set open filter",
                name: openFilter === "other location" ? "" : "other location",
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
