import { FC } from "react";

// icons
import { FaRegNewspaper } from "react-icons/fa";
import { FiSend, FiLock } from "react-icons/fi";
import { AiOutlineQuestion, AiOutlineExclamation } from "react-icons/ai";

// context
import { useUserContext } from "../../../../context/allContexts";

// styles
import styles from "./NewsPrivOrCompLabel.module.scss";
import { Maybe, UserMetaData } from "../../../../../codeGenFE";

interface Props {
  pubStatus: string | undefined | null;
  userMetaData: UserMetaData[] | null | undefined;
}

const NewsPrivOrCompLabel = ({ pubStatus, userMetaData }: Props) => {
  const { user } = useUserContext();

  let status = "";

  if (userMetaData) {
    userMetaData.forEach((u) => {
      if (u && u.userId === user._id) {
        if (!u.permanent) {
          status = "feed";
          if(u.sharedBy)
            status = "shared";  
        } else if (u.sharedBy) {
          status = "shared";
        } else {
          status = "private";
        }
      }
    });
  } else if (pubStatus === "error") {
    status = "error";
  } else if (pubStatus === "help") {
    status = "help";
  }
   switch (status) {
    case "shared":
      return (
        <div style={{ background: "#abdd9c" }} className={styles.articleLabel}>
          <FiSend className={styles.labelIcon} />
          <p>Shared</p>
        </div>
      );
    case "feed":
      return (
        <div style={{ background: "#c1cdf8" }} className={styles.articleLabel}>
          <FaRegNewspaper className={styles.labelIcon} />
          <p>Feed</p>
        </div>
      );
    case "private":
      return (
        <div style={{ background: "#abb0b9" }} className={styles.articleLabel}>
          <FiLock className={styles.labelIcon} />
          <p>Private</p>
        </div>
      );
    // case "sharedBy":
    //   // @ts-ignore
    //   return (
    //     <div style={{ background: "#bfe7ff" }} className={styles.articleLabel}>
    //       <FiSend className={styles.labelIcon} />
    //       <p>Shared By</p>
    //     </div>
    //   );
    case "help":
      return (
        <div
          style={{ background: "#ffd5b6" }}
          className={styles.articleLabel__dark}
        >
          <AiOutlineQuestion className={styles.labelIcon} />
          <p>Help is Here!</p>
        </div>
      );
    case "error":
      return (
        <div
          style={{ background: "#ff6B6B" }}
          className={styles.articleLabel__dark}
        >
          <AiOutlineExclamation
            className={styles.labelIcon}
            style={{ color: "white" }}
          />
          <p style={{ color: "white" }}>Error with Pluaris.</p>
        </div>
      );
    default:
      return <p>loading...</p>;
  }
};

export default NewsPrivOrCompLabel;
