import { REST_BASE_ENDPOINT } from "../../constants";

export const API_BASE_URL = REST_BASE_ENDPOINT;

export const TECH_M_ENDPOINTS = {
  FINANCIAL: (company) => `/techmahindra/financial?company=${company}`,
  GENERAL: (company) => `/techmahindra/general?company=${company}`,
  TRENDS: (company) => `/techmahindra/trends?company=${company}`,
}

