import { toast } from "react-hot-toast";
import { axios } from "../../../../utils/axiosConfig";
import { REST_BASE_ENDPOINT, API_ENDPOINTS, POST_HEADERS } from "../../../../constants";

export const createCompany = async (
  firstName,
  lastName,
  newCompany,
  newCompanyPayload,
  loaderFunction,
  email
) => {
  if (firstName !== "" && lastName !== "") {
    try {
      const res = await axios.post(
        `${REST_BASE_ENDPOINT}/auth/landing/company/create`,
        {
          companyName: firstName + " " + lastName + " " + Date.now(),
          tempCompanyId: localStorage.getItem("companyId"),
          email,
        }
      );

      newCompany(
        {
          ...newCompanyPayload,
          tempCompanyId: localStorage.getItem("companyId"),
          company: res.data.data,
        },
        loaderFunction
      );
      return res.data.data;
    } catch (err) {
      loaderFunction(false);
      return err?.response?.data?.message || err.message;
    }
  }
  else {
    toast.error("Something went wrong! Please try again.")
  }
};


export function createCompanyId(companyName, userEmail) {
  return axios.post(`${REST_BASE_ENDPOINT}${API_ENDPOINTS.CREATE_COMPANY}`, {
      companyName: companyName,
      email: userEmail,
      tempCompanyId: localStorage.getItem("companyId"),
  }, {
    headers: POST_HEADERS,
  });
}
