import React from "react";

import styles from "./AccountFooter.module.scss";

const AccountFooter = () => {
  return (
    <footer className={styles.footer}>
      <p className={styles.support}>
        Customer Support -{" "}
        <a
          className={styles.smallPhoneBreak}
          href="mailto:info@nowigence.com?subject=Customer%20support"
          target="_blank"
        >
          info@nowigence.com
        </a>
      </p>

      <p className={styles.copyright}>
        © 2022{" "}
        <a href="https://www.nowigence.com/" target="_blank">
          Nowigence Inc
        </a>
        , <span className={styles.smallPhoneBreak}>All rights reserved.</span>
      </p>
      <p className={styles.version}>version 3.0.1</p>
      <a
        className={styles.termsAndConditions}
        href="/account/termsandconditions"
        // target="_blank"
        // rel="noopener"
      >
        Terms &amp; Conditions
      </a>
    </footer>
  );
};

export default AccountFooter;
