import React, { FC, useEffect, useReducer } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { AiOutlinePlusCircle } from "react-icons/ai";

import styles from "./AMComparisonCards.module.scss";
import AskMeModal from "../AskMeModal/AskMeModal";
import {
  useUserProfileContext,
  useViewPortContext,
} from "../../../context/allContexts";
import { Topic, useFetchUserTopicsQuery } from "../../../../codeGenFE";

interface State {
  activeCard: number;
  showRightArrow: boolean;
  showLeftArrow: boolean;
  showAskMeTopicModal: boolean;
  viewPortSize: number;
  activeTopic: string;
}

type Actions =
  | { type: "reset" }
  | { type: "changeActive"; activeIndex: number }
  | { type: "showScrollArrows" }
  | { type: "hideScrollArrows" }
  | { type: "open topic modal"; topic: string }
  | { type: "close topic modal" };

const comparisonReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "reset":
      return {
        ...state,
        activeCard: 0,
      };
    case "changeActive":
      return {
        ...state,
        activeCard: action.activeIndex,
      };
    case "showScrollArrows":
      return {
        ...state,
        showRightArrow: true,
        showLeftArrow: true,
      };
    case "hideScrollArrows":
      return {
        ...state,
        showRightArrow: false,
        showLeftArrow: false,
      };
    case "open topic modal":
      return {
        ...state,
        showAskMeTopicModal: true,
        activeTopic: action.topic,
      };
    case "close topic modal":
      return {
        ...state,
        showAskMeTopicModal: false,
        activeTopic: "",
      };
  }
  return state;
};

const initState: State = {
  activeCard: 0,
  showRightArrow: false,
  showLeftArrow: false,
  showAskMeTopicModal: false,
  viewPortSize: 0,
  activeTopic: "",
};

interface Props {
  entityInfo: any;
  chooseTopic: any;
}

const AMComparisonCards: FC<Props> = ({ entityInfo, chooseTopic }) => {
  const [comparisonState, comparisonDispatch] = useReducer(
    comparisonReducer,
    initState
  );
  const {
    activeCard,
    showLeftArrow,
    showRightArrow,
    showAskMeTopicModal,
    activeTopic,
  } = comparisonState;

  // const { setTopicToMonitor, getUserProfileData, questions, topicDupCheck } =
  //   useUserProfileContext();
  // @ts-ignore
  const { topicDupCheck, availableTopics } = useUserProfileContext();

  // fetch list of topics

  // @ts-ignore
  const { width } = useViewPortContext();

  interface ScrollArgs {
    el: any;
    move: number;
  }

  const scrollDir = ({ el, move }: ScrollArgs) => {
    el.scrollBy({
      top: 0,
      left: move,
      behavior: "smooth",
    });
  };

  const plusButtonOnclick = (entity_name: string) => {
    comparisonDispatch({ type: "open topic modal", topic: entity_name });
  };

  useEffect(() => {
    if (entityInfo[activeCard]) {
      chooseTopic(entityInfo[activeCard].entity_name);
    }
  }, [entityInfo, activeCard]);

  useEffect(() => {
    const scrollContainer = document.querySelector(
      `.${styles.cards__scrollContainer}`
    );

    if (scrollContainer) {
      scrollContainer.scrollTo(0, 0);
      if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
        comparisonDispatch({ type: "showScrollArrows" });
      } else {
        comparisonDispatch({ type: "hideScrollArrows" });
      }

      comparisonDispatch({ type: "reset" });
    }
  }, [entityInfo]);

  // useEffect(() => {
  //   getUserProfileData();
  // }, []);

  // Tracks if the scroll arrows should show or hide
  useEffect(() => {
    const scrollContainer = document.querySelector(
      `.${styles.cards__scrollContainer}`
    );
    if (scrollContainer) {
      scrollContainer.scrollTo(0, 0);
      if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
        comparisonDispatch({ type: "showScrollArrows" });
      } else {
        comparisonDispatch({ type: "hideScrollArrows" });
      }
    }
  }, [width]);

  const changeActiveCard = (index: number) => {
    if (activeCard === index) return;
    if (activeCard !== index) {
      comparisonDispatch({ type: "changeActive", activeIndex: index });
    }
  };

  if (!entityInfo || entityInfo.length === 0) {
    return (
      <p className={styles.noEntityWiki__p}>No EntityWiki for this search</p>
    );
  }

  return (
    <div className={styles.cardsWrapper}>
      {showLeftArrow && <PrevBtn scrollDir={scrollDir} />}
      <div className={styles.cards__scrollContainer}>
        {entityInfo.map((entity: any, idx: number) => {
          const { entity_name, entity_type, image_url, summary, url } = entity;
          return (
            <div
              key={idx}
              className={`${styles.cardWrapper} ${
                activeCard === idx ? styles.activeCard : null
              }`}
              onClick={() => changeActiveCard(idx)}>
              <div className={styles.imgContainer}>
                <img
                  className={styles.entityImg}
                  src={image_url && image_url.split(":")[1]}
                  alt={`${entity_name && entity_name} image`}
                />
              </div>
              <a
                className={styles.entityLink}
                href={url ? url : null}
                target="_blank"
                rel="noopener noreferrer">
                <h5 className={styles.entityTitle}>{entity_name}</h5>
              </a>
              <div className={styles.summaryContainer}>
                <p className={styles.summaryText}>{summary}</p>
              </div>
              {!topicDupCheck(entity_name, availableTopics) && (
                <AiOutlinePlusCircle
                  onClick={() => plusButtonOnclick(entity_name)}
                  className={styles.plusIcon}
                />
              )}
            </div>
          );
        })}
      </div>
      {activeTopic && (
        <AskMeModal
          close={() => comparisonDispatch({ type: "close topic modal" })}
          topic={activeTopic}
        />
      )}
      {/* {alertSuccessAskMeModal && (
        <div
          className={`${styles.askMeModalTopicNewsPreviewWrapper} ${styles.alertPreviewWrapper}`}
        >
          <div className={styles.message__div}>
            <div className={styles.message__heading}>
              <AiOutlineCheck className={`${styles.iconCheck}`} />
              <h3 className={styles.message__h3}>Success!</h3>
            </div>
            <p className={styles.message__p}>
              The topic and a few articles for that topic have been added
              successfully!
            </p>
            <button
              className={styles.message__button}
              onClick={() => setAlertSuccessAskMeModal(false)}
            >
              OKAY
            </button>
          </div>
        </div>
      )} */}
      {showRightArrow && <NextBtn scrollDir={scrollDir} />}
    </div>
  );
};

export default AMComparisonCards;

function NextBtn({ scrollDir }: any) {
  return (
    <div
      className={styles.nextCardContainer}
      onClick={() =>
        scrollDir({
          el: document.querySelector(`.${styles.cards__scrollContainer}`),
          move: 310,
        })
      }>
      <FiChevronRight className={styles.arrowRight} />
    </div>
  );
}
function PrevBtn({ scrollDir }: any) {
  return (
    <div
      className={styles.nextCardContainer}
      onClick={() =>
        scrollDir({
          el: document.querySelector(`.${styles.cards__scrollContainer}`),
          move: -310,
        })
      }>
      <FiChevronLeft className={styles.arrowRight} />
    </div>
  );
}
