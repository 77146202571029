import { createContext, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { Type, useMeQuery, useRewardsDetails } from "../../../codeGenFE";
import { useLogout } from "../../../rq-hooks";
import { useUserListQuery } from "../../../codeGenFE";
import {
  useUserRewardsMutation,
  useUserRewardLogsMutation,
} from "../../../codeGenFE/index";
import axios from "axios";
import {
  LINKEDIN_CLIENT_ID,
  LINKEDIN_CLIENT_SECRET,
  REST_BASE_ENDPOINT,
  API_ENDPOINTS,
  TWITTER_RESR_API_URL,
  LOCAL_STORAGE_KEYS,
} from "../../../constants";
import toast from "react-hot-toast";
import { useTokenProcess } from "../../../rq-hooks/useTokenProcess";
import { useRewardsContext } from "./RewardsContext";
import { useTwitterProcess } from "../../../rq-hooks/useTwitterProcess";

export const UserContext = createContext();
const SocialTypes = {
  LINKEDIN: "linkedin",
  TWITTER: "Twiiter",
};

export function UserProvider(props) {
  // HOOKS
  const history = useHistory();
  const qClient = useQueryClient();
  // STATE
  const [user, setUser] = useState(null);
  const [initLocation] = useState(history.location.pathname);
  const [linkedInAccessToken, setAccessToken] = useState(
    localStorage.getItem("linkedInAccessToken") || null
  );
  const [twitterAccessToken, setTwitterAccessToken] = useState(
    localStorage.getItem("twitterAccessToken") || null
  );
  const [twitterAccessTokenSecret, setTwitterAccessTokenSecret] = useState(
    localStorage.getItem("twitterAccessTokenSecret") || null
  );
  const [twitterToken, setTwitterToken] = useState(
    localStorage.getItem("twitter_oauth_token") || null
  );
  const [twitterVerifier, setTwitterVerifier] = useState(
    localStorage.getItem("twitter_oauth_verifier") || null
  );
  const [initialLocation, setInitialLocation] = useState("");
  const [callBackUrl, setCallbackUrl] = useState(null);
  const [disableShare, setDisableShare] = useState(true);
  const [linkedInUserData, setLinkedinUserData] = useState(null);
  const [totalPoints, setTotalPoints] = useState(null);
  const [totalToken, setTotalToken] = useState(null);
  const [nearestPointForToken, setNearestPoint] = useState(null);
  const [token, setToken] = useState(null);
  const [rewardDetails, setRewardDetails] = useState(null);
  const [isReloaded, setIsReloaded] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [nftBadge, setNftBadge] = useState(null);
  const [nftErrorCounter, setNftErrorCounter] = useState(0);
  const [clientId, setClientId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const locationData = useLocation();
  // const {rewardsDispatch} = useRewardsContext()
  // Check if user is a single user to their company
  useUserListQuery(
    {},
    {
      enabled: !!user,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: (data) => {
        if (data.userList.length > 0) {
          setDisableShare(false);
        } else {
          setDisableShare(true);
        }
      },
    }
  );

  // RQ HOOKS
  const { mutate: logout } = useLogout({
    onSettled: () => {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      localStorage.removeItem("userId");
      localStorage.removeItem("userPrefAdded");
      localStorage.removeItem("companyId");
      localStorage.removeItem(LOCAL_STORAGE_KEYS.THEME);
      setUser(null);
      qClient.clear();
      history.push("/");
      const allowed = [
        "/termsandconditions",
        "/marketing",
        "/",
        "/loginlight",
        "/loginlight",
        "/signupdark",
        "/signuplight",
      ];
      if (!allowed.includes(history.location.pathname)) {
        history.push("/");
      }
    },

    onSuccess: () => {
      localStorage.removeItem("userId");
      localStorage.removeItem("companyId");
      localStorage.removeItem(LOCAL_STORAGE_KEYS.THEME);
    },
  });

  useEffect(() => {
    if (
      !twitterAccessToken &&
      !twitterToken &&
      !twitterVerifier &&
      !twitterAccessTokenSecret
    ) {
      localStorage.removeItem("twitter_oauth_verifier");
      localStorage.removeItem("twitterAccessToken");
      localStorage.removeItem("twitterAccessTokenSecret");
      localStorage.removeItem("twitter_oauth_token");
    }
  }, [
    twitterAccessToken,
    twitterToken,
    twitterVerifier,
    twitterAccessTokenSecret,
  ]);

  const handleCallback = (url) => setCallbackUrl(url);

  const { mutate: rewardsMutate } = useUserRewardsMutation();
  const { mutate } = useTokenProcess({
    onSuccess: (data) => {
      // console.log(data, "process")
      toast.success(
        `Congratulations! ${data.tokens_transferred} token has been processed`,
        { duration: 10000 }
      );
    },
  });
  const { mutate: rewardsLogMutate } = useUserRewardLogsMutation({
    onSuccess: () => {
      if (initialLocation === Type.LOGIN) {
        rewardsMutate({
          options: {
            type: initialLocation === Type.LOGIN ? Type.LOGIN : Type.ReVisited,
          },
        });
      }
    },
  });

  const fetchToken = async (code) => {
    try {
      const { data } = await axios.post(
        `${REST_BASE_ENDPOINT}${API_ENDPOINTS.LI_ACCESS_TOKEN}`,
        {
          code,
          // eslint-disable-next-line no-restricted-globals
          url: encodeURI(`${location.origin}${location.pathname}`),
        }
      );
      const accessToken = data.data.access_token;
      setAccessToken(accessToken);
      // setIsReloaded(true)
      localStorage.setItem("reloaded", true);
      return accessToken;
    } catch (err) {
      // throw new Error(err);
    }
  };

  const fetchTwitterToken = async (token, verifier) => {
    try {
      const { data } = await axios.post(
        `${REST_BASE_ENDPOINT}${API_ENDPOINTS.TW_ACCESS_TOKEN}`,
        {
          token,
          verifier,
        }
      );
      const accessData = data.data.split("&");
      const accessToken = accessData[0];
      const accessTokenSecret = accessData[1];
      // toast.success(`Congratulations! You have successfully integrated with Twitter.`, {
      //   duration: 10000
      // });
      // setTwitterAccessToken(accessToken)
      // setTwitterAccessTokenSecret(accessTokenSecret)
      localStorage.setItem("twitterAccessToken", accessToken);
      localStorage.setItem("twitterAccessTokenSecret", accessTokenSecret);
      // localStorage.setItem('reloaded', true)
      toast.success(
        "Congratulations! You have successfully integrated with Twitter."
      );
      // setIsReloaded(true)
      return accessToken;
    } catch (err) {
      // throw new Error(err);
    }
  };

  const { refetch: refetchMe, status: useMeStatus } = useMeQuery(
    {},
    {
      refetchOnWindowFocus: false,
      // Retry is to fix having to press login button twice.  Don't remove unless you fix that issue first.
      retry: 2,
      onSuccess: async (data) => {
        console.log(data?.me);
        // if (data?.me) {
        setUser(data?.me);
        setIsVerified(data?.me?.emailVerified);
        setNftBadge(data?.me?.nftData);
        setNftErrorCounter(data?.me?.nftErrorCounter);
        setClientId(data?.me?.clientId);
        setClientSecret(data?.me?.clientSecret);
        // console.log(data)
        // eslint-disable-next-line no-restricted-globals
        // console.log(location)
        const allowed = [
          "/termsandconditions",
          "/marketing",
          "/",
          "/loginlight",
          "/loginlight",
          "/signupdark",
          "/signuplight",
        ];
        // console.log(history.location)
        let type = null;
        setInitialLocation(Type.LOGIN);
        if (
          history.location.pathname !== "/my-memory/reader" ||
          history.location.pathname === "/"
        ) {
          type = Type.LOGIN;
        } else {
          // type = Type.ReVisited
        }
        let queryFound = true;
        let queryParams = null;
        let code = null;
        let twitter_oauth_token = null;
        let twitter_oauth_verifier = null;

        // In the below condition system is getting code param that is being
        // recieved in redirect url by linkedin or collects oauth_token and
        // oauth_verifier value from redirect url after authorization given
        // by user to app and twitter redirects.
        // Saves the data got from twitter redirect in state and local storage and use it.
        if (locationData.search.length === 0) {
          localStorage.removeItem("reloaded");
        } else if (locationData.search.length > 0) {
          queryParams = new URLSearchParams(locationData.search);
          if (
            queryParams.has("oauth_token") &&
            queryParams.has("oauth_verifier")
          ) {
            twitter_oauth_token = queryParams.get("oauth_token");
            twitter_oauth_verifier = queryParams.get("oauth_verifier");
            setTwitterToken(twitter_oauth_token);
            setTwitterVerifier(twitter_oauth_verifier);
            localStorage.setItem("twitter_oauth_token", twitter_oauth_token);
            localStorage.setItem(
              "twitter_oauth_verifier",
              twitter_oauth_verifier
            );
            localStorage.setItem("reloaded", true);
          } else if (queryParams.has("code")) {
            code = queryParams.get("code");
            // setIsReloaded(true)
          }
        }
        if (!code && !twitter_oauth_token && !twitter_oauth_verifier) {
          localStorage.removeItem("reloaded");
        }
        let accessToken = null;
        let twitterAccessToken = null;

        if (code) {
          accessToken = await fetchToken(code);

          localStorage.setItem("linkedInAccessToken", accessToken);
          setAccessToken(localStorage.getItem("linkedInAccessToken"));
          toast.success(
            `Congratulations! You have successfully integrated with LinkedIn.`,
            {
              duration: 10000,
            }
          );
        }

        let userData = null;

        if (type)
          // rewardsLogMutate({
          //   options: {
          //     type: type,
          //   },
          // });
        if (code) localStorage.setItem("reloaded", true);
        if (allowed.includes(history.location.pathname)) {
          !code
            ? twitter_oauth_token && twitter_oauth_verifier
              ? history.push(
                  `/my-memory/reader?oauth_token=${twitter_oauth_token}&oauth_verifier=${twitter_oauth_verifier}`
                )
              : history.push("/my-memory/reader")
            : history.push(`/my-memory/reader?code=${code}`);
          if (code || (twitter_oauth_token && twitter_oauth_verifier))
            localStorage.setItem("reloaded", true);
        } else {
          history.push(initLocation);
        }
        // }
      },
      onError: (error) => {
        console.log("error from userContexxt useMe", error);
      },
    }
  );
  const refetch = () => {};
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        refetchMe,
        refetch,
        initLocation,
        logout,
        disableShare,
        useMeStatus,
        handleCallback,
        rewardDetails,
        nearestPointForToken,
        totalToken,
        totalPoints,
        token,
        isReloaded,
        // setIsReloaded
        linkedInAccessToken,
        twitterToken,
        twitterVerifier,
        setAccessToken,
        setTwitterToken,
        setTwitterVerifier,
        setIsVerified,
        isVerified,
        setNftBadge,
        nftBadge,
        nftErrorCounter,
        setNftErrorCounter,
        clientId,
        clientSecret,
        setClientId,
        setClientSecret,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => {
  return useContext(UserContext);
};
