import { createContext, useContext, useReducer } from "react";
import {
  MyMemoryState,
  MyMemoryActions,
  MyMemoryActionChoices,
} from "./MyMemoryTypes";
// import axios from "axios";

// contexts
// import { useUserContext } from "../../context/allContexts";

// @ts-ignore
export const MyMemoryContext = createContext();

const myMemoryReducer = (
  state: MyMemoryState,
  action: MyMemoryActions
): MyMemoryState => {
  switch (action.type) {
    // case "toggleFilesTrackingModal": {
    case MyMemoryActionChoices.TOGGLE_SUM_DRIV_OVERLAY: {
      return {
        ...state,
        showSumAndDriverOverlay: action.payload,
        selectedArticleID: action.selectedArticleID,
        selectedArticleDB: action.selectedArticleDB,
      };
    }
    // case MyMemoryActionChoices.FILE_UPLOADED: {
    //   return {
    //     ...state,
    //     files: action.payload
    //   };
    // }
    // case MyMemoryActionChoices.REMOVE_FILE_UPLOADS: {
    //   return {
    //     ...state,
    //     files: state.files?.filter((name) => name !== action.payload)
    //   };
    // }
    case MyMemoryActionChoices.SET_HEIGHT:
      return {
        ...state,
        height: action.height,
      };
    case MyMemoryActionChoices.TOGGLE_DISPLAY_INFO_MODAL:
      return {
        ...state,
        displayInfoModal: action.payload
          ? action.payload
          : !state.displayInfoModal,
      };
    case MyMemoryActionChoices.TRIGGER_CLOSING_ARTICLES:
      return {
        ...state,
        triggerClosingArticles: action.payload
          ? action.payload
          : !state.triggerClosingArticles,
      };
    case MyMemoryActionChoices.SET_SKIP:
      return {
        ...state,
        filesSkip: localStorage.getItem("reloaded") && localStorage.getItem("pfSkip") ? parseInt(localStorage.getItem("pfSkip") || `${action.filesSkip}`) :  action.filesSkip,
        v2Skip: localStorage.getItem("reloaded") && localStorage.getItem("pv2Skip") ? parseInt(localStorage.getItem("pv2Skip") || `${action.v2Skip}`) :  action.v2Skip
      };
    case MyMemoryActionChoices.SET_ARTICLES:
      let data = []
      if(action.articles && action.articles.length > 0) {
        data.push(action.articles[action.articles.length - 1])
      }
      return {
        ...state,
        articles: data
      };    
    default:
      return state;
  }
};

const initState: MyMemoryState = {
  showSumAndDriverOverlay: false,
  height: window.scrollY,
  displayInfoModal: false,
  triggerClosingArticles: false,
  selectedArticleID: null,
  selectedArticleDB: null,
  v2Skip: null,
  filesSkip: null,
  articles: null,
};

export function MyMemoryProvider(props: any) {
  const [myMemState, myMemDispatcher] = useReducer(myMemoryReducer, initState);

  // const {
  //   showSumAndDriverOverlay,
  //   height,
  //   displayInfoModal,
  //   triggerClosingArticles
  // } = myMemState;

  return (
    <MyMemoryContext.Provider value={{ myMemState, myMemDispatcher }}>
      {props.children}
    </MyMemoryContext.Provider>
  );
}

export const useMyMemoryContext = () => {
  return useContext(MyMemoryContext);
};
