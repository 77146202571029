import { useState } from 'react';
import Select from 'react-select';

import TableRow from "./TableRow.component";
import styles from "./PowerOfOne.module.scss";

const timeFrameOptions = [
    {
        value: 'annual data dec 2021',
        label: 'Annual data - Dec 2021'
    },
    {
        value: 'latest twelve months - Sep 2022',
        label: 'Latest twelve months - Sep 2022'
    }
]

const PowerOfOne = ({ data }) => {
    const [timeFrame, setTimeFrame] = useState(timeFrameOptions[0].value)

    const timeFrameChange = (data) => {
        setTimeFrame(data.value);
    }

    return (
        <>
            <div className="my-4" style={{ fontSize: "22px", fontWeight: 700 }}>
                <h3>Power of One</h3>
            </div>
            <div className='d-flex align-items-center mb-4'>
                <p style={{
                    fontSize: '16px',
                    marginRight: '20px'
                }}>Period:</p>
                <Select
                    className="w-52 pt-1 z-20 text-base"
                    classNamePrefix="select"
                    defaultValue={timeFrameOptions[0]}
                    name="timeFrame"
                    options={timeFrameOptions}
                    onChange={timeFrameChange}
                />
            </div>
            <table className={"table " + styles.tableTypo}>
                <thead>
                    <tr className={styles.primaryHeader}>
                        <th scope="col" colSpan="2"></th>
                        <th scope="col">Power of One</th>
                    </tr>
                    <tr className={styles.secondaryHeader}>
                        <th scope="col"></th>
                        <th scope="col">
                            Improvement <br /> (1% or 1 Day)
                        </th>
                        <th scope="col">
                            Cash  Flow Benefits <br />
                            (Millions - CHF)
                        </th>
                    </tr>
                </thead>

                {
                    Object.keys(data).map((el, i) => (
                        <>
                            <thead className={styles.tertiaryHeader} key={`thead-${i}`}>
                                <tr>
                                    <th scope="col">{el}</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody key={`tbody-${i}`}>
                                {
                                    Object.keys(data[el]).map((rowEl) =>{
                                        console.log('powerrr')
                                        console.log(data[el]?.[rowEl]?.[timeFrame]?.[0]?.["Cash Flow Benefits (Millions – CHF)"])
                                        return  <TableRow
                                            key={rowEl}
                                            increment={el === 'Growth'}
                                            rowData={{
                                                label: rowEl,
                                                // TODO: edit `Cash Flow Benefits (Millions – CHF)` once backend is updated
                                                value: parseInt(data[el]?.[rowEl]?.[timeFrame]?.[0]?.["Cash Flow Benefits (Millions – CHF)"])
                                            }}
                                        />}
                                    )
                                }
                            </tbody>
                        </>
                    ))
                }
                {/* <thead className={styles.tertiaryHeader}>
                <tr>
                    <th scope="col">Growth</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {PO1data.growth.map((el) => <TableRow rowData={el} increment />)}
            </tbody>
            <thead className={styles.tertiaryHeader}>
                <tr>
                    <th scope="col">Profitability</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {PO1data.profitability.map((el) => <TableRow rowData={el} />)}
            </tbody> */}
            </table>
        </>
    )
}


export default PowerOfOne;
