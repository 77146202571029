import Carousel from "react-bootstrap/Carousel";
import FocusAreaCard from "./focusAreaCard";

const FocusArea = ({ data }) => {

  return (
    <>
      <div className="text-dark my-4">
        <div className="text-start mx-3 px-2">
          <h2 className="text-dark fw-bold fs-3 mt-5 mb-2">
            Strategic focus areas
          </h2>
          <p className="fs-6 text-secondary my-3">
            T-Mobile has a number of strategic focus areas as it seeks to grow
            its business and deliver high-quality products and services to its
            customers.
          </p>
        </div>
        <div className="my-2 d-flex mx-3">
          <Carousel>
            {data.focusArea.map((item) => (
              <Carousel.Item interval={50000}>
                <div className="my-2">
                  <div className="text-dark my-4">
                    <div className="my-2 d-flex">
                      <FocusAreaCard
                        title={item.title}
                        content={item.content}
                      />
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default FocusArea;
