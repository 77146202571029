import { REST_BASE_ENDPOINT } from "../constants";
import { useMutation } from "react-query";
import { axios } from "../utils/axiosConfig";

interface ConsentObj {
  givenConcent: string;
}

export function useBoardConsent(options: any) {
  // console.log("running useBoardConsent");

  return useMutation(async (consentObj: ConsentObj) => {
    try {
      const { data } = await axios.post(
        `${REST_BASE_ENDPOINT}/preferences/board_consent`,
        consentObj
      );
      // console.log("data from useBoardConsent", data);
      if (data) {
        return data;
      }
    } catch (err) {
      console.log("err from useBoardConsent", err.response.data);
      throw new Error(err.response.data.message);
    }
  }, options);
}
