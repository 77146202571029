import React, { useState, useEffect } from "react";
import CloseModalBtn from "../../CloseModalBtn/CloseModalBtn";

import styles from "./RewardsInfoModal.module.scss";


const RewardsInfoModal = ({ close, rewardsInfoModal }) => {
  const [shouldRender, setShouldRender] = useState(rewardsInfoModal);

  useEffect(() => {
    if (rewardsInfoModal) setShouldRender(true);
  }, [rewardsInfoModal]);

  const onAnimationEnd = () => {
    if (!rewardsInfoModal) setShouldRender(false);
  };
  return (
    shouldRender && (
      <React.Fragment>
        <div
          onAnimationEnd={onAnimationEnd}
          className={`${styles.backdrop} ${
            rewardsInfoModal ? styles.backdrop : styles.fadeOut
          }`}
          onClick={() => close()}
        />
        <div
          className={`${styles.myMemoryInfoModalWrapper} ${
            rewardsInfoModal ? styles.myMemoryInfoModalWrapper : styles.slideUp
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.closeBtn}>
            <CloseModalBtn theme="light" close={close} />
          </div>
          <h2>Work with Pluaris and earn Crypto Tokens</h2>
          <div>
            <ul>
                <li>Pluaris offers reward points for using its various features. </li>
                <li>You will get points as 4s and 2s for different actions like uploading a file or URL or asking a question on the 'Ask me' page or sharing content on Twitter & LinkedIn. </li>
                <li>Once points cross the threshold of twenty a blockchain based digital token is awarded and gets saved in your digital wallet. You can see the total tokens awarded in 'My Account' page or in your wallet details accessed from the Algorand icon at the top right corner.</li>
            </ul>
          </div>
          <p>
            So why waiting, Go start using the Pluaris !
          </p>
        </div>
      </React.Fragment>
    )
  );
};

export default RewardsInfoModal;
