import React, { useState } from 'react'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps'

const geoUrl =
  'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json'

const ExpansionPlans = ({ expansions }) => {
  const [hoveredMarker, setHoveredMarker] = useState(null)

  function convertArray(expansions) {
    const newArray = []
    expansions.forEach((expansion) => {
      if (expansion.state) {
        newArray.push({
          markerOffset: -15,
          name: expansion.state,
          legend: expansion.legend,
          coordinates: [expansion.long, expansion.lat],
        })
      }
    })

    return newArray
  }

  const markers = convertArray(expansions)
  return (
    <>
      <div
        className="mt-5 text-center"
        style={{ fontSize: '22px', fontWeight: 700 }}
      >
        <h3>Expansion plans (Product/geographic/service)</h3>
      </div>
      <ComposableMap>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#EEEFEF"
                stroke="#868686"
              />
            ))
          }
        </Geographies>
        {markers.map(({ name, coordinates, markerOffset, legend }) => (
          <Marker key={name} coordinates={coordinates}>
            <rect
              x={25}
              y={-3}
              width={250}
              height={20}
              fill="#fff"
              style={{ borderRadius: '10px' }}
              stroke="#5D5A6D"
              strokeWidth={0.5}
              visibility={hoveredMarker === name ? 'visible' : 'hidden'}
            />
            <circle
              r={4}
              fill="var(--OneLinkColor)"
              stroke="#fff"
              strokeWidth={0.5}
              onMouseEnter={() => setHoveredMarker(name)}
              onMouseLeave={() => setHoveredMarker(null)}
            />
            <text
              textAnchor="middle"
              y={markerOffset}
              style={{
stroke: '#090909',
                fontFamily: 'system-ui',
                fill: '#000',
                visibility: hoveredMarker === name ? 'visible' : 'hidden',
              }}
            >
              {name}
            </text>
            <text
              x={35}
              y={10}
              style={{
                fontFamily: 'system-ui',
                fill: '#5D5A6D',
                fontSize: '8px',
                backgroundColor: 'pink',
              }}
              visibility={hoveredMarker === name ? 'visible' : 'hidden'}
            >
              {legend}
            </text>
          </Marker>
        ))}
      </ComposableMap>
    </>
  )
}

export default ExpansionPlans
