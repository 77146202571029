import React, { useState } from "react";

import { AiOutlineSearch, AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";

import ConfirmModal from "../../ConfirmModal/ConfirmModal";

// import {
//   useTagsContext,
//   useUserContext,
// } from "../../../../context/allContexts";

import styles from "./UPSearchBar.module.scss";
import { useUserProfileContext } from "../../../../context/allContexts/UserProfileContext";
import {
  ShowModalType,
  UPActiveMode,
  UserProfileActionChoices,
} from "../../userProfileTypes";
import toast from "react-hot-toast";
// import {
// useAddTagsToUserProfileMutation,
// useUpdateTagFromUserProfileMutation,
// } from "../../../../../codeGenFE";
// import { customErrorHandler } from "../../../../../utils/customErrorHandler";
// import { useQueryClient } from "react-query";

const UPSearchBar = (
  {
    // searchTopics,
    // setSearchTopics,
    // searchTags,
    // setSearchTags,
    // isTopicsOrTags,
    // setIsTopicsOrTags,
    // newTag,
    // setNewTag,
    // addTagsToUser,
    // oldTag,
    // confirmModal,
    // setConfirmModal,
  }
) => {
  // const qClient = useQueryClient();

  // @ts-ignore
  const {
    // @ts-ignore
    userProfileState,
    // @ts-ignore
    userProfileDispatcher,
    // @ts-ignore
    updateTagMutation,
    // @ts-ignore
    addTagMutate,
  } = useUserProfileContext();

  const {
    availableTags,
    searchTagsString,
    activeMode,
    tagToAdd,
    tagToEdit,
    editTagInput,
    showModalType,
    uiTagError,
    searchTopicString,
  } = userProfileState;

  const addTag = (newTag: string) => {
    // validation
    let tag = newTag.trim();

    let dup = false;
    availableTags?.forEach((t: string) => {
      if (t.toLowerCase() === tag.toLowerCase()) dup = true;
    });
    if (dup) {
      toast.error("This tag already exists");
      return;
    }

    if (tag !== "") {
      addTagMutate({ userTags: { tags: [newTag] } });
    }
    userProfileDispatcher({
      type: UserProfileActionChoices.SET_UI_TAG_ERROR,
      uiTagError: "You must provide a tag.",
    });
    // setUITagError("You must provide a tag.");
  };

  const whichInput = (isTopicsOrTags: UPActiveMode) => {
    switch (isTopicsOrTags) {
      case UPActiveMode.SEARCH_TOPICS:
        return (
          <div
            className={`${styles.searchWrapper} ${styles.searchWrapper_animateLeft}`}
          >
            <div className={styles.searchInputContainer}>
              <input
                className={`${styles.topicCardInput}
                ${styles.animateInputleft}
                `}
                type="text"
                placeholder="Search Your Topics!"
                value={searchTopicString}
                // value={""}
                onChange={(e) =>
                  userProfileDispatcher({
                    type: UserProfileActionChoices.CHANGE_TOPIC_SEARCH_STRING,
                    payload: e.target.value,
                  })
                }
              />
              <AiOutlineSearch
                className={`${styles.searchIcon} ${
                  isTopicsOrTags === UPActiveMode.SEARCH_TOPICS
                    ? styles.moveSearchRight
                    : styles.moveSearch
                }`}
                // onClick={() =>     userProfileDispatcher({type: UserProfileActionChoices.CHANGE_MODE, activeMode: UPActiveMode.SEARCH_TAGS})}
              />
              <AiOutlinePlus
                className={`${styles.plusIcon} ${
                  activeMode === UPActiveMode.ADD_TAGS
                    ? styles.animate
                    : styles.animateOff
                } `}
                // onClick={() => enterTaggingFunc(newTag && newTag)}
                onClick={() => {
                  userProfileDispatcher({
                    type: UserProfileActionChoices.CHANGE_MODE,
                    activeMode: UPActiveMode.ADD_TAGS,
                  });
                }}
              />
            </div>
          </div>
        );
      case UPActiveMode.SEARCH_TAGS:
        return (
          <div
            className={`${styles.searchWrapper} ${styles.searchWrapper_animateLeft}`}
          >
            <div className={styles.searchInputContainer}>
              <input
                className={`${styles.topicCardInput}
                  ${styles.animateInputleft}
                  `}
                type="text"
                placeholder="Search Your Tags!"
                value={searchTagsString}
                onChange={(e) =>
                  userProfileDispatcher({
                    type: UserProfileActionChoices.CHANGE_TAGS_SEARCH_STRING,
                    searchTagsString: e.target.value,
                  })
                }
              />
              <AiOutlineSearch
                className={`${styles.searchIcon} ${styles.moveSearchRight}`}
                onClick={() =>
                  userProfileDispatcher({
                    type: UserProfileActionChoices.CHANGE_MODE,
                    activeMode: UPActiveMode.SEARCH_TAGS,
                  })
                }
              />
              <AiOutlinePlus
                className={`${styles.plusIcon} ${styles.animateOff}`}
                // onClick={() => enterTaggingFunc(newTag && newTag)}
                onClick={() => {
                  userProfileDispatcher({
                    type: UserProfileActionChoices.CHANGE_MODE,
                    activeMode: UPActiveMode.ADD_TAGS,
                  });
                }}
              />
            </div>
          </div>
        );
      case UPActiveMode.ADD_TAGS:
        return (
          <div
            className={`${styles.searchWrapper} ${styles.searchWrapper_animateRight}`}
          >
            <div className={styles.searchInputContainer}>
              <input
                className={`${styles.topicCardInput} ${styles.animateInputright}`}
                type="text"
                placeholder="Add Tag!"
                value={tagToAdd}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    addTag(tagToAdd);
                  }
                }}
                onChange={(e) =>
                  userProfileDispatcher({
                    type: UserProfileActionChoices.CHANGE_TAG_TO_ADD,
                    tagToAdd: e.target.value,
                  })
                }
              />
              <AiOutlineSearch
                className={`${styles.searchIcon} ${styles.moveSearch}`}
                onClick={() =>
                  userProfileDispatcher({
                    type: UserProfileActionChoices.CHANGE_MODE,
                    activeMode: UPActiveMode.SEARCH_TAGS,
                  })
                }
              />
              <AiOutlinePlus
                className={`${styles.plusIcon} ${styles.animate} `}
                // onClick={() => enterTaggingFunc(newTag && newTag)}
                onClick={() => addTag(tagToAdd)}
              />
            </div>
          </div>
        );
      // case "editTagging":
      case UPActiveMode.EDIT_TAGS:
        return (
          <div
            className={`${styles.searchWrapper} ${styles.searchWrapper_animateRight}`}
          >
            <div className={styles.searchInputContainer}>
              <input
                className={`${styles.topicCardInput}
                ${styles.animateInputright}
                `}
                type="text"
                placeholder="Edit Tag!"
                value={editTagInput}
                // value={tagToEdit}
                onChange={(e: any) =>
                  userProfileDispatcher({
                    type: UserProfileActionChoices.EDIT_TAG_INPUT,
                    editTagInput: e.target.value.toLowerCase(),
                  })
                }
                // onChange={(e) => setNewTag(e.target.value.toLowerCase())}
              />
              <AiOutlineSearch
                className={`${styles.searchIcon} ${styles.moveSearch}`}
                onClick={() =>
                  userProfileDispatcher({
                    type: UserProfileActionChoices.CHANGE_MODE,
                    activeMode: UPActiveMode.SEARCH_TAGS,
                  })
                }
              />
              <AiOutlineCheck
                className={`${styles.plusIcon} ${styles.animate} `}
                onClick={() =>
                  userProfileDispatcher({
                    type: UserProfileActionChoices.SHOW_MODAL_TYPE,
                    payload: ShowModalType.EDIT,
                  })
                }
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* {whichInput(isTopicsOrTags)} */}
      {whichInput(activeMode)}
      {/* {confirmModal === "edit" && (
        <ConfirmModal
          type="edit"
          label="Edit for all Articles"
          func={() => {
            editUserTag(newTag, oldTag);
            setConfirmModal(false);
            setIsTopicsOrTags("tags");
          }}
          close={() => {
            setConfirmModal(false);
            setIsTopicsOrTags("tags");
          }}
        />
      )} */}
    </>
  );
};

export default UPSearchBar;
