import FinancialTab from "../../Pages/ControlPanel/FinancialTab";
import CompanyTrendsTab from "../../Pages/ControlPanel/CompanyTrends/CompanyTrendsTab.component";
import GeneralPage from "../../Pages/ControlPanel/General";

export const TabData = [
  {
    label: "General",
    value: "general",
    desc: (company) => (
      <div>
        <GeneralPage currentCompany={company} />
      </div>
    ),
  },
  {
    label: "Financial",
    value: "financial",
    desc: (company) => <FinancialTab currentCompany={company} />,
  },

  {
    label: "Company Trends",
    value: "companyTrends",
    desc: (company) => <CompanyTrendsTab currentCompany={company} />
  },
];
