import React from "react";
import { useUserContext } from "../../../context/allContexts";

import styles from "./ConsentModal.module.scss";

const ConsentModal = ({ handleConsentClose, submitBoardChange }) => {
  const { logout } = useUserContext();

  return (
    <div className={styles.backdrop} onClick={() => logout()}>
      <div
        className={styles.modalBody}
        onClick={(e) => {
          e.stopPropagation();
          handleConsentClose();
        }}
      >
        <div className={styles.modalContent}>
          <div className={styles.content}>
            <h1 className={styles.consentModal__title}>
              Nowigence Disclosure Statement:
            </h1>

            <p className={styles.consentModal__p}>
              The forward-looking statements that we make or that are made by
              others on our behalf are based on our knowledge of our business
              and our operating environment and assumptions that we believe to
              be or will believe to be reasonable when such forward-looking
              statements were or are made. As a consequence of the factors
              described above, the other risks, uncertainties and factors we
              disclose below and in the other reports as mentioned above, other
              risks not known to us at this time, changes in facts, assumptions
              not being realized or other circumstances, our actual results may
              differ materially from those discussed in or implied or
              contemplated by our forward-looking statements. Consequently, this
              cautionary statement qualifies all forward looking statements we
              make or that are made on our behalf, including those made herein
              and incorporated by reference herein.
            </p>

            <p className={styles.consentModal__p}>
              We cannot assure you that the results or developments expected or
              anticipated by us will be realized or, even if substantially
              realized, that those results or developments will result in the
              expected consequences for us or affect us, our business, our
              operations or our operating results in the manner or to the extent
              we expect. We caution readers not to place undue reliance on such
              forward-looking statements, which speak only as of their dates. We
              undertake no obligation to revise or update any of the
              forward-looking statements to reflect subsequent events or
              circumstances except to the extent required by applicable law.
            </p>

            <p className={styles.consentModal__p}>
              Confidentiality Notice: This document is confidential and contains
              proprietary information and intellectual property of Nowigence
              Inc. Neither this document nor any of the information contained
              herein may be reproduced or disclosed under any circumstances
              without the express written permission of Nowigence Inc.. Please
              be aware that disclosure, copying, distribution or use of this
              document and the information contained therein is strictly
              prohibited.
            </p>
            <div className={styles.btnContainer}>
              <button
                onClick={submitBoardChange}
                className={styles.consentModal__agreeBtn}
              >
                Agree
              </button>
              <button
                className={styles.consentModal__disagreeBtn}
                onClick={() => logout()}
              >
                Disagree
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsentModal;
