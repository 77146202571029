import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ContainerCard from '../../../../Components/ContainerCard'
import styles from './companyProfile.module.scss'
import copyIcon from '../../../../../assets/img/copyIcon.png'
import emailIcon from '../../../../../assets/img/emailIcon.png'
import pinIcon from '../../../../../assets/img/pinIcon.png'

import CompanyCard from './companyCard'

const CompanyProfile = ({ data }) => {
  console.log('leader', data.leaderShip)

  function limit (string = '', limit = 250) {
    return string.substring(0, limit) + '...'
  }

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState('')

  function openModal (content) {
    setModalContent(content)
    setModalIsOpen(true)
  }

  function closeModal () {
    setModalIsOpen(false)
  }
  return (
    <>
      <div className='my-5'>
        <div className='d-flex gap-1'>
          <ContainerCard
            isLoading={false}
            children={
              <div className='my-3'>
                <p className='my-2 fs-6 lh-base'>
                  {data.companyProfile.desc.length > 250
                    ? limit(data.companyProfile.desc) + '  '
                    : data.companyProfile.desc}
                  {data.companyProfile.desc.length > 250 && (
                    <span
                      className=' text-blue-600 cursor-pointer'
                      style={{ color: '#0c6efd' }}
                      onClick={() => openModal(data.companyProfile.desc)}
                    >
                      Read More
                    </span>
                  )}
                </p>
                <div className='d-flex my-4 justify-content-between align-items-center text-primary'>
                  <OverlayTrigger
                    key='top'
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        {data.companyProfile.website}
                      </Tooltip>
                    }
                  >
                    <a
                      className='text-decoration-none cursor-pointer'
                      onClick={() => {
                        window.open(`https://${data.companyProfile.website}`)
                      }}
                    >
                      <div className='d-flex align-items-center'>
                        <img width='20px' src={copyIcon} />
                        <span
                          style={{ width: '150px' }}
                          className='mx-2 d-inline-block text-truncate'
                        >
                          {data.companyProfile.website}
                        </span>
                      </div>
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key='top'
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        {data.companyProfile.location}
                      </Tooltip>
                    }
                  >
                    <a className='text-decoration-none cursor-pointer'>
                      <div className='d-flex align-items-center'>
                        <img width='20px' src={pinIcon} />
                        <span
                          style={{ width: '150px' }}
                          className='mx-2 d-inline-block text-truncate'
                        >
                          {data.companyProfile.location}
                        </span>
                      </div>
                    </a>
                  </OverlayTrigger>
            {data?.companyProfile?.email !== '' &&
                  <OverlayTrigger
                    key='top'
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        {data.companyProfile.email}
                      </Tooltip>
                    }
                  >
      
                    <a className='text-decoration-none cursor-pointer'>
                      <div className='d-flex align-items-center'>
                        <img width='20px' src={emailIcon} />
                        <span
                          style={{ width: '150px' }}
                          className='mx-2 d-inline-block text-truncate'
                        >
                          {data.companyProfile.email}
                        </span>
                      </div>
                    </a>
           
                  </OverlayTrigger>
 }
                </div>

              </div>
            }
            title='Company Profile'
            className={styles.subheading + ' w-50 mx-2'}
          />
          <Modal
            size='lg'
            show={modalIsOpen}
            onHide={closeModal}
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                More about the Company
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <h4 className='fs-5 leading-6'> More about the Company</h4> */}
              <br></br>
              <p>{modalContent}</p>
            </Modal.Body>
            <Modal.Footer>
              <button
                style={{
                  backgroundColor: '#0c6efd',
                  color: 'white',
                  border: 'none'
                }}
                className='btn btn-primary'
                onClick={closeModal}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
          <CompanyCard info={data.leaderShip} />
        </div>
      </div>
    </>
  )
}
export default CompanyProfile
