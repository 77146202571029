import { FC } from "react";
import { useArticlesTrendingQuery } from "../../../../../codeGenFE";
import { useSearchContext } from "../../../../context/allContexts";
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";
import { trendingPlaceholderData } from "../../../../Pages/Dashboard/DashInfoContainer/Trending/TrendingUtils";
import DropdownDisplayBelow from "../../../DropdownDisplayBelow/DropdownDisplayBelow";

interface Props {
  isOpen: boolean;
  toggleOpenFilter: () => void;
}

const OtherLocationFilter: FC<Props> = ({ isOpen, toggleOpenFilter }) => {
  const { searchDispatcher, searchState, searchInputOpts } = useSearchContext();

  const { data } = useArticlesTrendingQuery(
    {
      inputOpts: searchInputOpts,
    },
    {
      refetchOnWindowFocus: false,
      initialData: trendingPlaceholderData,
      keepPreviousData: true,
    }
  );

  const moversLoc = data?.articlesTrending?.movers?.location
    ? (data?.articlesTrending?.movers?.location.map(
        (c) => c?.label
      ) as string[])
    : [];
  const mentionsLoc = data?.articlesTrending?.mentions?.location
    ? (data?.articlesTrending?.mentions?.location.map(
        (c) => c?.label
      ) as string[])
    : [];

  return (
    <DropdownDisplayBelow
      selectableItems={[...moversLoc, ...mentionsLoc].filter((s) => s !== "")}
      isDropdownOpen={isOpen}
      toggleDropdown={toggleOpenFilter}
      dropdownDisplay={"Location"}
      selectionDisplay={searchState?.otherLocation}
      select={(loc) =>
        searchDispatcher({
          type: SearchContextActions.SET_OTHER_LOCATION,
          location: loc,
        })
      }
      unselect={() =>
        searchDispatcher({
          type: SearchContextActions.SET_OTHER_LOCATION,
          location: null,
        })
      }
      makeSearchable={false}
    />
  );
};

export default OtherLocationFilter;
