import { FC, useReducer } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Type, useUserRewardLogsMutation, useUserRewardsMutation } from "../../../../../codeGenFE";
// DATA HOOKS
import { useFileUpload } from "../../../../../rq-hooks/pythonRestApi/useFileUpload";
// UTILS
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
// CONTEXT
import { useUserContext, useNavContext, useRewardsContext } from "../../../../context/allContexts";
import { MyMemoryActionChoices } from "../../../../Pages/MyMemory/MyMemoryTypes";
// COMPONENTS
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../LoadingCube/LoadingCube";
import QuickUploadMessagingModal from "../../QuickUploadMessagingModal/QuickUploadMessagingModal";
// STYLES
import styles from "../QuickUpload.module.scss";
// TYPES
import {
  ActiveUploadType,
  QuickUploadActionChoices,
} from "../QuickUploadTypes";
import QUShare from "../QUShare/QUShare";
import {
  ActionChoices,
  Actions,
  DataForNode,
  InputErrors,
  Props,
  State,
} from "./FileUploadTypes";

const fileUploadReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionChoices.SET_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    case ActionChoices.TOGGLE_IS_PRIVATE:
      return {
        ...state,
        isPrivate: action.boolean,
      };
    case ActionChoices.TOGGLE_SHARE_ALL:
      return {
        ...state,
        shareAll: action.boolean,
      };
    case ActionChoices.SET_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: action.selectedUsers,
      };
    case ActionChoices.RESET_SHARE:
      return {
        ...state,
        selectedUsers: [],
        shareAll: false,
        isPrivate: true,
      };
    case ActionChoices.SET_INPUT_ERROR:
      return {
        ...state,
        inputError: action.error,
      };
    case ActionChoices.CLEAR_INPUT_ERRORS:
      return {
        ...state,
        inputError: null,
      };
    case ActionChoices.CLOSE_WARNING:
      return {
        ...state,
        closeWarning: action.boolean,
      };
    case ActionChoices.SET_FILES:
      return {
        ...state,
        fileList: action.fileList,
        inputError: null,
      };
    case ActionChoices.REMOVE_FILE:
      return {
        ...state,
        fileList: state.fileList.filter((_, index) => {
          return index !== action.index;
        }),
      };
    default:
      return state;
  }
};

const initState: State = {
  fileList: [],
  tags: [],
  isPrivate: true,
  selectedUsers: [],
  shareAll: false,
  inputError: null,
  closeWarning: false,
};

const FileUpload: FC<Props> = ({ quickUploadDispatcher }) => {
  const { user, disableShare } = useUserContext();
  // @ts-ignore
  const { navDispatch } = useNavContext();
  const [fileUploadState, fileUploadDispatcher] = useReducer(
    fileUploadReducer,
    initState
  );
  const {
    fileList,
    // tags,
    isPrivate,
    shareAll,
    selectedUsers,
    inputError,
    closeWarning,
  } = fileUploadState;

  // In this function system just sending our file/s data to useFileUpload() hook 
  // that is calling our python api and on success we are mutating 
  // userRewardLogsMutation()
  const {
    mutate: pythonMutate,
    status: pythonStatus,
    error: pythonError,
    reset: pythonResetMutation,
  } = useFileUpload({
    onSuccess: (data: any) => {
      // const fileIds = data;
      //
      // if (!fileIds) {
      //   fileUploadDispatcher({
      //     type: ActionChoices.SET_INPUT_ERROR,
      //     error: "Sorry, couldn't upload files at this time.",
      //   });
      //   return;
      // }
      //
      // const data1: DataForNode = {
      //   shareAll: shareAll,
      //   uploadIds: fileIds,
      // };
      // if (tags) data1.tags = tags;
      // if (selectedUsers) {
      //   data1.recipients = selectedUsers;
      //   data1.userFullName = `${user?.name} ${
      //     user.lastName ? user.lastName : null
      //   }`;
      // }
      // mutate({ inputOpts: data1 });
      // myMemDispatcher({
      //   type: MyMemoryActionChoices.FILE_UPLOADED,
      //   payload: data
      // })
    },
    onError: (err: any) => {
      console.log("err from FileUpload, useFileUploader onError", err);
      fileUploadDispatcher({
        type: ActionChoices.SET_INPUT_ERROR,
        error: "Sorry, couldn't upload your file(s) at this time.",
      });
    },
  });

  // const {
  //   mutate,
  //   status,
  //   error,
  //   reset: resetMutation,
  // } = useShareAndTagUploadedFilesMutation();

  const getFileExtension = (filename: any) => {
    return filename.toLowerCase().split(".").pop();
  };

  const uploadFile = (event: any) => {
    let fileListArray: any[] = [];
    if (fileList.length) {
      fileListArray = fileList;
    }
    let files = Array.from(event.target.files);
    files.forEach((file: any) => {
      event.target.value = "";
      let fileExtension = getFileExtension(file.name);
      if (
        fileExtension === "tif" ||
        fileExtension === "docx" ||
        fileExtension === "pdf" ||
        fileExtension === "txt" ||
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        fileListArray.push({ name: file.name, value: file });
      } else {
        fileUploadDispatcher({
          type: ActionChoices.SET_INPUT_ERROR,
          error: `The file format .${fileExtension} is not supported`,
        });
      }
    });
    fileUploadDispatcher({
      type: ActionChoices.SET_FILES,
      fileList: fileListArray,
    });
  };

  async function sendFilesToPython() {
    // let formData = new FormData();
    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //   },
    // };
    // formData.append("company_id", user.company);
    // formData.append("user_id", user._id);
    // // @ts-ignore
    // formData.append("share_all", shareAll ? true : false);
    // // @ts-ignore
    // formData.append("share_to", [...recipients]);
    // // @ts-ignore
    // // formData.append("tags", tags ? tags : null);
    // // formData.append("user_relationship_type");
    // // @ts-ignore
    // fileList.map((file: any, index: number) => {
    //   formData.append(`file${index}`, file.value);
    // });

    // upload files to python endpoint wait for [ids] to return se onSuccess for
    // next action
    // pythonMutate({ formData, config });
    pythonMutate({
      files: fileList,
      company_id: user.company,
      user_id: user._id,
      share_all: shareAll ? true : false,
      share_to: selectedUsers,
    });
  }

  async function submitFiles(event: MouseEvent) {
    event.preventDefault();
    if (fileList.length <= 0) {
      fileUploadDispatcher({
        type: ActionChoices.SET_INPUT_ERROR,
        error: InputErrors.FILE_REQUIRED,
      });
      return;
    } else if (
      !isPrivate &&
      Number(selectedUsers.length) === 0 &&
      shareAll === false
    ) {
      fileUploadDispatcher({
        type: ActionChoices.SET_INPUT_ERROR,
        error: InputErrors.NO_USERS_TO_SHARE,
      });
      return;
    } else {
      sendFilesToPython();
    }
  }

  return (
    <div className={styles.quickUploadModal__wrapper}>
      {/* ========== HANDLE SUCCESS | WARNING | ERROR STATES FROM BACKEND ========== */}
      <QuickUploadMessagingModal
        success={pythonStatus === "success"}
        warning={closeWarning}
        error={customErrorHandler(pythonError) && inputError}
        close={() => {
          fileUploadDispatcher({
            type: ActionChoices.CLOSE_WARNING,
            boolean: false,
          });
          // TODO: Jon b -> added this as it's a different kind of error... it
          // works but may want to consider changing it... Don't feel important
          // enough giving future styling changes to mess with right nimport { useMyMemoryContext } from '../../../../Pages/MyMemory/MyMemoryContext';
          // worth looking at if you want to solve another way.
          fileUploadDispatcher({
            type: ActionChoices.SET_INPUT_ERROR,
            error: null,
          });
          // resetMutation();
          pythonResetMutation();
        }}
      />

      {/* ========== Loading ========== */}
      {pythonStatus === "loading" && (
        <div className={styles.loadingWrapper}>
          <LoadingCube theme={LoadingCubeTheme.LIGHT} />
        </div>
      )}

      {/* ========== Top Button Row ========== */}
      <div className={styles.quickUploadModal__urlTextWrapper}>
        <button
          className={styles.urlTextWrapper_btn}
          onClick={() =>
            quickUploadDispatcher({
              type: QuickUploadActionChoices.CHANGE_UPLOAD_TYPE,
              newUploadType: ActiveUploadType.URL,
            })
          }>
          URL
        </button>
        <button
          onClick={() =>
            quickUploadDispatcher({
              type: QuickUploadActionChoices.CHANGE_UPLOAD_TYPE,
              newUploadType: ActiveUploadType.TEXT,
            })
          }
          className={styles.urlTextWrapper_btn}>
          TEXT
        </button>
        <button className={`${styles.urlTextWrapper_btn} ${styles.active}`}>
          FILE
        </button>
      </div>
      {/* ========== URL Input ========== */}
      <div className={styles.quickUploadModal__URLWrapper}>
        {/* ========== File Upload ========== */}

        <div className={styles.quickUploadModal__uploadContainer}>
          {inputError && (
            <label className={styles.inputError}>{inputError}</label>
          )}
          <div className={styles.quickUploadModal__fileType}>
            {" "}
            *.PDF,.DOCX,.TXT,.PNG,.JPG,.JPEG,.TIF
          </div>
          <div className={styles.quickUploadModal__file}>
            CHOOSE FILE
            <input
              type="file"
              onChange={uploadFile}
              title=""
              className={styles.quickUploadModal__inputFile}
              multiple
            />
          </div>
          <div>
            <ul className={styles.quickUploadModal__fileList}>
              {fileList &&
                // @ts-ignore
                fileList.map((file: any, index: number) => {
                  return (
                    <li
                      key={index}
                      className={styles.quickUploadModal__fileListContainer}>
                      <span className={styles.quickUploadModal__fileListName}>
                        {file.name}
                      </span>
                      <span
                        className={styles.quickUploadModal__fileListSpan}
                        onClick={() => {
                          fileUploadDispatcher({
                            type: ActionChoices.REMOVE_FILE,
                            index: index,
                          });
                        }}>
                        <AiOutlineClose
                          className={styles.quickUploadModal__fileListClose}
                        />
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>

        {/* ========== Tags Dropdown ========== */}
        {/*<AddTagsDropdown
          selectedTags={tags}
          setSelectedTags={(tags) =>
            fileUploadDispatcher({ type: ActionChoices.SET_TAGS, tags: tags })
          }
          />*/}
      </div>
      {inputError === InputErrors.NO_USERS_TO_SHARE && (
        <QuickUploadMessagingModal
          error={inputError}
          close={() =>
            fileUploadDispatcher({ type: ActionChoices.CLEAR_INPUT_ERRORS })
          }
        />
      )}
      <div className={styles.quickUploadModal__privateShareUploadsWrapper}>
        <div className={styles.privNShare}>
          <button
            className={`${styles.privNShare_btn} ${
              isPrivate ? styles.active : null
            }`}
            onClick={(e) => {
              e.preventDefault();
              fileUploadDispatcher({
                type: ActionChoices.TOGGLE_IS_PRIVATE,
                boolean: !isPrivate,
              });
            }}>
            PRIVATE
          </button>
          <button
            className={`${styles.privNShare_btn} ${
              !isPrivate ? styles.active : null
            } ${disableShare ? styles.disableShare : ""}`}
            onClick={(e) => {
              e.preventDefault();
              if (!disableShare) {
                fileUploadDispatcher({
                  type: ActionChoices.TOGGLE_IS_PRIVATE,
                  boolean: !isPrivate,
                });
              } else {
                return null;
              }
            }}>
            SHARE
          </button>
        </div>
        <button
          className={styles.uploadBtn}
          onClick={(e: any) => submitFiles(e)}>
          UPLOAD
        </button>
      </div>
      <QUShare
        shareAll={shareAll}
        setShareAll={(boolean: boolean) =>
          fileUploadDispatcher({
            type: ActionChoices.TOGGLE_SHARE_ALL,
            boolean: boolean,
          })
        }
        selectedUsers={selectedUsers}
        setSelectedUsers={(selectedUsers: [] | string[]) =>
          fileUploadDispatcher({
            type: ActionChoices.SET_SELECTED_USERS,
            selectedUsers: selectedUsers,
          })
        }
        cancel={() => fileUploadDispatcher({ type: ActionChoices.RESET_SHARE })}
        hidden={isPrivate || pythonStatus !== "idle"}
      />
      <button
        className={styles.nevermind}
        onClick={() => navDispatch({ type: "change which menu", menu: "" })}
        // onClick={(e) => {
        //   e.preventDefault();
        //   fileUploadDispatcher({
        //     type: ActionChoices.CLOSE_WARNING,
        //     boolean: true,
        //   });
        //   }}
      >
        Nevermind
      </button>
    </div>
  );
};

export default FileUpload;
