import { FC } from "react";
import { useArticlesTrendingQuery } from "../../../../../codeGenFE";
import { useSearchContext } from "../../../../context/allContexts";
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";
import { trendingPlaceholderData } from "../../../../Pages/Dashboard/DashInfoContainer/Trending/TrendingUtils";
import DropdownDisplayBelow from "../../../DropdownDisplayBelow/DropdownDisplayBelow";

interface Props {
  isOpen: boolean;
  toggleOpenFilter: () => void;
}

const ProductFilter: FC<Props> = ({ isOpen, toggleOpenFilter }) => {
  const { searchDispatcher, searchState, searchInputOpts } = useSearchContext();

  const { data } = useArticlesTrendingQuery(
    {
      inputOpts: searchInputOpts,
    },
    {
      refetchOnWindowFocus: false,
      initialData: trendingPlaceholderData,
      keepPreviousData: true,
    }
  );

  const moversProduct = data?.articlesTrending?.movers?.product
    ? (data?.articlesTrending?.movers?.product.map((c) => c?.label) as string[])
    : [];
  const mentionsProduct = data?.articlesTrending?.mentions?.product
    ? (data?.articlesTrending?.mentions?.product.map(
        (c) => c?.label
      ) as string[])
    : [];

  return (
    <DropdownDisplayBelow
      selectableItems={[...moversProduct, ...mentionsProduct].filter(
        (s) => s !== ""
      )}
      isDropdownOpen={isOpen}
      toggleDropdown={toggleOpenFilter}
      dropdownDisplay={"Product"}
      selectionDisplay={searchState?.product}
      select={(p) =>
        searchDispatcher({
          type: SearchContextActions.SET_PRODUCT,
          product: p,
        })
      }
      unselect={() =>
        searchDispatcher({
          type: SearchContextActions.SET_PRODUCT,
          product: null,
        })
      }
      makeSearchable={false}
    />
  );
};

export default ProductFilter;
