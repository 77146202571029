import React, { useState, useEffect } from "react";
import CloseModalBtn from "../../../CloseModalBtn/CloseModalBtn";

import styles from "./AlgoRandModalInfo.module.scss";


const AlgoRandModalInfo = ({ close, algoInfoModal }) => {
  const [shouldRender, setShouldRender] = useState(algoInfoModal);

  useEffect(() => {
    if (algoInfoModal) setShouldRender(true);
  }, [algoInfoModal]);

  const onAnimationEnd = () => {
    if (!algoInfoModal) setShouldRender(false);
  };
  return (
    shouldRender && (
      <React.Fragment>
        <div
          onAnimationEnd={onAnimationEnd}
          className={`${styles.backdrop} ${
            algoInfoModal ? styles.backdrop : styles.fadeOut
          }`}
          onClick={() => close()}
        />
        <div
          className={`${styles.myMemoryInfoModalWrapper} ${
            algoInfoModal ? styles.myMemoryInfoModalWrapper : styles.slideUp
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.closeBtn}>
            <CloseModalBtn theme="light" close={close} />
          </div>
          <h2>Nowigence has brought the blockchain based Reward System for you in collaboration with Algorand Inc. </h2>
          <p>
            Your Pluaris wallet address is provided to you.
          </p>
          <p>
            Pluaris wallet is used to store the reward tokens.
          </p>
          <p>
            You can transfer tokens to other account holders and to complete the verification please go to 'My Account' from the left navigation panel. On My Account click 'verify' button given along with you email.
          </p>
          <p>
            You need to add recipient's wallet address to transfer the tokens.  
          </p>
        </div>
      </React.Fragment>
    )
  );
};

export default AlgoRandModalInfo;
