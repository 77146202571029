import React from "react";

// STYLES
import styles from "./NoRisksOrOppMessage.module.scss";

const NoRisksOrOppMessage = ({ error, theme }) => {
  return (
    <div className={styles.noRisksMessage}>
      <p
        className={`${
          theme === "light" ? styles.noRisksLight : styles.noRisksDark
        }`}>
        {error}
      </p>
    </div>
  );
};

export default NoRisksOrOppMessage;
