import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function DriverHelperModal (props) {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          What are these Drivers?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Drivers are defined as the intent or reason behind a decision being
        made. Articles within your knowledge base that contain sentences with a
        driver are automatically identified and categorized. The sentences
        containing the presence of a driver or the description of an action
        happening or about to happen are listed as the Driver points or Drivers.
        Some of the main Drivers are:
        <Row className='pt-3'>
          <Col>
            <ul style={{ listStyleType: 'circle' }}>
              <li>➥ Debt Reduction</li>
              <li>➥ Digitization</li>
              <li>➥ Funding/Growth</li>
              <li>➥ Labor Unrest</li>
              <li>➥ Liability</li>
              <li>➥ Market/Customer Demand</li>
              <li>➥ Market Expansion</li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li>➥ Product Development/Improvement</li>
              <li>➥ Product Launch</li>
              <li>➥ Productivity/Operational Efficiency</li>
              <li>➥ Quality Assurance</li>
              <li>➥ Increase/Decrease in Cost</li>
              <li>➥ Regulatory actions</li>
              <li>➥ Safety actions</li>
              <li>➥ Sociopolitical causes</li>
            </ul>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={props.onHide}
          className='btn'
          style={{
            backgroundColor: '#3cc0f6',
            color: 'white',
            border: 'none'
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}
