import React, { useEffect, useReducer } from "react";
import axios from "axios";

import styles from "./ActualAnswerOverlay.module.scss";

// COMPONENTS
import CloseModalBtn, {
  CloseModalBtnTheme,
} from "../../../../Components/CloseModalBtn/CloseModalBtn";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../../Components/LoadingCube/LoadingCube";
import ComponentMessagingOverlay from "../../../../Components/ComponentMessagingOverlay/ComponentMessagingOverlay";
// UTILS
import { sortDateFormatter } from "../../../../util/sortDateFormatter";
import { useArticleQuery, WhichDb } from "../../../../../codeGenFE";
import { customErrorHandler } from "../../../../../utils/customErrorHandler";

interface Props {
  close: () => void;
  sequenceID: string;
  pubPlatform: string;
  answer: string;
  sentence: string;
  question: string;
}

const ActualAnswerOverlay = ({
  close,
  sequenceID,
  pubPlatform,
  answer,
  sentence,
  question,
}: Props) => {
  const { data, status, error } = useArticleQuery({
    articleId: sequenceID,
    whichDB:
      pubPlatform !== "note" ? WhichDb.ProductionV2 : WhichDb.ProductionFiles,
  });

  // useEffect(() => {
  //   const goGetArticle = async () => {
  //     aaOverlayDispatch({ typs: "isLoading" });
  //     const articleId = sequenceId;
  //     try {
  //       if (pubPlatform !== "note") {
  //         let fetchRes = await axios.get("/api/newsData/fetchUploaded", {
  //           params: { articleId: articleId, isText: "url" },
  //         });
  //         const { article } = fetchRes.data;
  //         if (!article) throw "No Article Found with that id";
  //         aaOverlayDispatch({ type: "success", payload: article });
  //         // const { pubStatus } = fetchRes.data.article;
  //       } else {
  //         let fetchRes = await axios.get("/api/newsData/fetchUploaded", {
  //           params: { articleId: articleId, isText: "text" },
  //         });
  //         const { article } = fetchRes.data;
  //         if (!article) throw "No Article Found with that id";
  //         aaOverlayDispatch({ type: "success", payload: article });
  //         // const { pubStatus } = fetchRes.data.article;
  //       }
  //     } catch (error) {
  //       aaOverlayDispatch({
  //         type: "error",
  //         error: "Something went wrong, please try again",
  //       });
  //       return { error };
  //     }
  //   };
  //   if (sequenceId) {
  //     goGetArticle();
  //   }
  // }, []);

  // const highlightAnswer = (content, answer, sentence, question) => {
  //   let updatedSentence = sentence.slice(question.length + 1);
  //   // regex
  //   // let updatedSentence2 = updatedSentence1.replace(/^(.*?):\s/, "");
  //   console.log("updatedSentence", updatedSentence);
  //   // regex finds the opening < till the next closing > and then goes until the
  //   // final > and replaces it with nothing.
  //   let cleanedSentence = updatedSentence.replace(/<[^>]+>/g, "");
  //   let colorChange = updatedSentence.replace(/#003cbb/g, "#2196f3");
  //   console.log("length of cleanedSentence", cleanedSentence.length);
  //   console.log("length of colorChange", colorChange.length);
  //   console.log("colorChange", colorChange);
  //   console.log("cleanedSentence", cleanedSentence);
  //   let newContent = content.replace(/\n/g, " ");
  //   let updated = newContent.replace(cleanedSentence, colorChange);
  //   console.log("updated", updated);
  //
  //   return content;
  // };

  const highlightAnswer = (answer: string, content: string) => {
    return content.replace(
      answer,
      `<span style="color: #2196f3; font-weight: bold; font-family: var(--slantText);">${answer}</span>`
    );
  };

  if (status === "loading") {
    return (
      <div className={styles.actualAnswerOverlayWrapper}>
        <div className={styles.backdrop} onClick={() => close()} />
        <div className={styles.closeBtnDiv}>
          <div className={styles.BTN}>
            <CloseModalBtn theme={CloseModalBtnTheme.LIGHT} close={close} />
          </div>
        </div>
        <LoadingCube
          theme={LoadingCubeTheme.LIGHT}
          height="30px"
          width="30px"
          hasBackground={false}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.actualAnswerOverlayWrapper}>
        <div className={styles.backdrop} onClick={() => close()} />
        <div className={styles.closeBtnDiv}>
          <div className={styles.BTN}>
            <CloseModalBtn theme={CloseModalBtnTheme.LIGHT} close={close} />
          </div>
        </div>
        <ComponentMessagingOverlay
          close={() => close()}
          message={customErrorHandler(error)}
          showX={true}
        />
      </div>
    );
  }

  if (data) {
    const _source = data?.article?._source;
    const { title, sortDate, content } = _source;
    return (
      <>
        <div className={styles.backdrop} onClick={() => close()} />
        <div
          onClick={(e) => e.stopPropagation()}
          className={styles.actualAnswerOverlayWrapper}
        >
          <div className={styles.closeBtnDiv}>
            <div className={styles.BTN}>
              <CloseModalBtn theme={CloseModalBtnTheme.LIGHT} close={close} />
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.aaOverlay__titleWrapper}>
              <h3 className={styles.aaOverlay__h3}>
                From <span className={styles.significant}>{title}</span>
              </h3>
              <p className={styles.pipe}>|</p>
              <p className={styles.date}>
                Uploaded on {sortDateFormatter(sortDate)}
              </p>
            </div>
            <div className={styles.contentParagraphWrapper}>
              <div
                dangerouslySetInnerHTML={{
                  __html: highlightAnswer(answer, content),
                }}
                className={styles.aaOverlay__p}
              />
              {/*highlightAnswer(content, answer, sentence, question)*/}
              {/* {highlightAnswer(answer, content)} */}
              {/* </p> */}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default ActualAnswerOverlay;
