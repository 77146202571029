import React, { FC, useState } from "react";
import styles from "./ACDisplayTags.module.scss";

interface Props {
  t: string;
  updateEditTagInputArray: (arg: string) => void;
  editByKeyPress: (status: boolean) => void;
}
export const ACDisplayTagsEditInput: FC<Props> = ({
  t,
  updateEditTagInputArray,
  editByKeyPress,
}) => {
  const [updatedTag, setUpdatedTag] = useState<string|null>(null)
  const keyboardEditTag = (e: any) => {
    if(updatedTag){
      editByKeyPress(true)
    }
  }
  return (
    <div className={styles.displayWrapper}>
      <div className={styles.SingleLIInput__inputWrapper}>
        <input
          className={styles.SingleLIInput__input}
          value={t}
          onChange={(e) => {
            setUpdatedTag(e.target.value)
            e.preventDefault();
            updateEditTagInputArray(e.target.value);
          }}
          onKeyPress={(e) => e.key === "Enter" ? keyboardEditTag(e) : null}
        />
      </div>
    </div>
  );
};
