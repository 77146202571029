export const sortDateFormatter = (sortDate) => {
    const newDate = new Date(sortDate * 1000);
    const year = newDate.getFullYear()
    let monthName = newDate.getMonth() + 1
    if(monthName < 10) {
      monthName = `0${monthName}`
    }
    let day = newDate.getDate()
    if(day < 10) {
      day = `0${day}`
    }
    return `${year}-${monthName}-${day}`
}
