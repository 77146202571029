import React from "react";
import styles from "./ANoResults.module.scss";

import robot from "../../../../../assets/img/not_found.png";

const ANoResults = ({ answer }) => {
  if (
    answer ===
      "Sorry, your question is not included in our external knowledge. We are expanding our knowledge sources over time. Please check your answer later." ||
    answer ===
      "Sorry, your question is not included in our institutional knowledge. We are expanding our knowledge sources over time. Please check your answer later."
  ) {
    return (
      <div className={styles.container}>
        <div className={styles.noResults__imgContainer}>
          <img
            className={styles.noResults__img}
            src={robot}
            alt="Pluaris Robo 404 Image for no results"
          />
        </div>
      </div>
    );
  }
  return null;
};

export default ANoResults;
