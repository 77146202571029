import { REST_BASE_ENDPOINT } from "../constants";
import { useQuery } from "react-query";
import { axios } from "../utils/axiosConfig";

const getBoardConsentData = async () => {
  try {
    const { data } = await axios.get(
      `${REST_BASE_ENDPOINT}/preferences/board_consent`
    );
    // console.log("data from getBoardConsent", data);
    if (data) {
      return data;
    }
  } catch (err) {
    console.log("err from getBoardConsent", err.response.data);
    throw new Error(err.response.data.message);
  }
};

export const useGetBoardConsent = (options: any) => {
  // console.log("running getBoardConsent");
  return useQuery(BOARD_CONSENT, () => getBoardConsentData(), options);
};

export const BOARD_CONSENT = "board_consent";
