import React, { createContext, useContext, useReducer, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useUserContext, useTPIServiceContext } from "../allContexts";
export const ThirdPartyIntegrationContext = createContext();

const thirdPartyIntegrationReducer = (state, action) => {
  switch (action.type) {
    case "setCurrentIntegration":
      return {
        ...state,
        currentIntegration: action.value,
        serviceSelected: true,
      };

    case "toggleModal":
      return { ...state, tPISettingsInputInfoModal: action.value };

    case "closeService":
      return {
        ...state,
        currentIntegration: action.value,
        tPISettingsInputInfoModal: false,
        success: false,
        serviceSelected: false,
      };

    case "resetFields":
      return {
        ...state,
        success: action.status,
        message: action.message,
        statusMessage: action.statusMessage,
        tPISettingsInputInfoModal: action.value,
      };
    /*
    case "toggleAddButtonTerminationFooter": 
      return {
             ...state,
             hideAddButton:action.addButton,
             hideTerminationFooter:action.terminationFooter,
    }*/
    /*
    case "toggleSalesforceAddButtonTerminationFooter": 
      return {
             ...state,
             hideSalesforceAddButton:action.salesforceAddButton,
             hideSalesforceTerminationFooter:action.salesforceTerminationFooter,
      } 
    case "toggleOneDriveAddButtonTerminationFooter": 
      return {
             ...state,
             hideOneDriveAddButton:action.oneDriveAddButton,
             hideOneDriveTerminationFooter:action.oneDriveTerminationFooter,
      } */

    case "toggleGoogleDriveAddButtonTerminationFooter":
      return {
        ...state,
        hideGoogleDriveAddButton: action.googleDriveAddButton,
        hideGoogleDriveTerminationFooter: action.googleDriveTerminationFooter,
      };
    /*
    case "slackIntegration":
      return {
        ...state,
        slackStatus:action.value
      }*/
    /*
    case "salesforceIntegration":
      return {
        ...state,
        salesforceStatus:action.value
      }
    
    case "oneDriveIntegration":
      return {
        ...state,
        oneDriveStatus:action.value,
      }
    */
    case "googleDriveIntegration":
      return {
        ...state,
        googleDriveStatus: action.value,
      };
    /*
    case "loading": {
      return {
        ...state,
        loader:action.value
      }
    }*/
    /*
    case "oneDriveLoading": {
      return {
        ...state,
        oneDriveLoader:action.value
      }
    }
    
    case "salesforceLoading": {
      return {
        ...state,
        salesforceLoader:action.value
      }
    }

    case "salesforceUploaderLoading": {
      return {
        ...state,
        salesforceUploadLoader:action.value,
        salesforceUploadingMessage:action.salesforceUploadingMessage
      }
    }
    */
    case "googleDriveLoading": {
      return {
        ...state,
        googleDriveLoader: action.value,
      };
    }
    /*
    case "uploaderLoading": {
      return {
        ...state,
        uploadLoader:action.value,
        uploadingMessage:action.uploadingMessage
      }
    }*/
    /*
    case "googleDriveUploaderLoading": {
      return {
        ...state,
        googleDriveUploadLoader:action.value,
        googleDriveUploadingMessage:action.googleDriveUploadingMessage
      }
    } */
    /*
    case "switchToggleView": {
      return {
        ...state,
        switchToggle:action.value
      }
    }*/
    /*
    case "salesForceSwitchToggleView": {
      return {
        ...state,
        salesForceSwitchToggle:action.value
      }
    }
    case "oneDriveSwitchToggleView": {
      return {
        ...state,
        oneDriveSwitchToggle:action.value
      }
    } */

    case "googleDriveSwitchToggleView": {
      return {
        ...state,
        googleDriveSwitchToggle: action.value,
      };
    }

    case "tpiOpenStatus": {
      return { ...state, [action.field]: action.value };
    }

    case "updateService": {
      return { ...state, services: action.value };
    }

    case "updateGoogleDriveEmail": {
      return { ...state, googleDriveEmail: action.value };
    }

    case "isAdminSetup": {
      return { ...state, [action.field]: action.value };
    }
  }
  return state;
};

const initState = {
  currentIntegration: "none",
  message: "",
  statusMessage: "",
  success: false,
  tPISettingsInputInfoModal: false,
  serviceSelected: false,
  //hideAddButton:false,
  //hideTerminationFooter:true,
  //hideSalesforceAddButton:false,
  //hideSalesforceTerminationFooter:true,
  //hideOneDriveAddButton:false,
  //hideOneDriveTerminationFooter:true,
  hideGoogleDriveAddButton: false,
  hideGoogleDriveTerminationFooter: true,
  //slackStatus:false,
  //salesforceStatus: false,
  //oneDriveStatus:false,
  googleDriveStatus: false,
  //loader:false,
  //salesforceLoader:false,
  //oneDriveLoader:false,
  googleDriveLoader: false,
  //uploadLoader: false,
  //uploadingMessage: '',
  googleDriveUploadLoader: false,
  googleDriveUploadingMessage: "",
  //salesforceUploadLoader: false,
  //salesforceUploadingMessage: '',
  //switchToggle:false,
  //salesForceSwitchToggle: false,
  //oneDriveSwitchToggle: false,
  googleDriveSwitchToggle: false,
  //slackOpen: false,
  //oneDriveOpen: false,
  //salesforceOpen: false,
  googleDriveOpen: false,
  services: [],
  googleDriveUserOpen: false,
  googleDriveEmail: "",
  //isSlackAdminSetup: true,
  isGoogledriveAdminSetup: true,
  //isOnedriveAdminSetup: true,
  //isSalesforceAdminSetup: true,
  //slackAdminOpen:false,
  //slackErrorOpen:false,
};

export function ThirdPartyIntegrationProvider(props) {
  const [
    thridPartyIntegrationState,
    thirdPartyIntegrationDispatch,
  ] = useReducer(thirdPartyIntegrationReducer, initState);
  const { userState } = useUserContext();
  //const {admin}  = userState;
  const { serviceData } = useTPIServiceContext();

  const {
    serviceSelected,
    currentIntegration,
    success,
    services,
    tPISettingsInputInfoModal,
    statusMessage,
    message,
    googleDriveUserOpen,
    googleDriveStatus,
    hideGoogleDriveAddButton,
    hideGoogleDriveTerminationFooter,
    googleDriveLoader,
    googleDriveUploadLoader,
    googleDriveUploadingMessage,
    googleDriveSwitchToggle,
    googleDriveOpen,
    googleDriveEmail,
    isGoogledriveAdminSetup,
  } = thridPartyIntegrationState;
  /*
  const { serviceSelected,currentIntegration,success,services,tPISettingsInputInfoModal,
          slackStatus,statusMessage,message,hideAddButton,hideTerminationFooter,loader,
          uploadLoader,uploadingMessage, switchToggle, slackOpen, googleDriveUserOpen, 
          googleDriveStatus,hideGoogleDriveAddButton,hideGoogleDriveTerminationFooter,
          googleDriveLoader, googleDriveUploadLoader,googleDriveUploadingMessage,
          googleDriveSwitchToggle, googleDriveOpen, googleDriveEmail,
          oneDriveStatus,hideOneDriveAddButton,hideOneDriveTerminationFooter,
          oneDriveLoader,oneDriveSwitchToggle,oneDriveOpen,salesforceStatus,
          hideSalesforceAddButton,hideSalesforceTerminationFooter,salesforceLoader,
          salesforceUploadLoader, salesforceUploadingMessage,salesForceSwitchToggle,
          salesforceOpen, isSlackAdminSetup, isGoogledriveAdminSetup, isOnedriveAdminSetup,
          isSalesforceAdminSetup, slackAdminOpen, slackErrorOpen } = thridPartyIntegrationState; */

  let location = useLocation();

  useEffect(() => {
    if (serviceData.length > 0) {
      thirdPartyIntegrationDispatch({
        type: "updateService",
        value: serviceData,
      });
    }
  }, [serviceData]);

  useEffect(() => {
    if (location.pathname === "/dashboard/account" && location.search) {
      let params = new URLSearchParams(location.search);
      let status = params.get("status");
      //let salesforcestatus = params.get('salesforceSuccess');
      let googleDriveAdminStatus = params.get("googleDriveAdminSuccess");
      let googleDriveUserStatus = params.get("googleDriveSuccess");
      //let slackAdminStatus = params.get('adminStatus');
      /*if(status  === 'Success'){
        thirdPartyIntegrationDispatch({type:'tpiOpenStatus',field:'slackOpen', value:true});
        thirdPartyIntegrationDispatch({ type: 'switchToggleView', value:true });
        thirdPartyIntegrationDispatch({
          type: 'resetFields' , 
          status: true,
          statusMessage:'Ok',
          message:"Saved Successfully",
          value:!tPISettingsInputInfoModal});  
      }
      if(status === 'Error'){
        thirdPartyIntegrationDispatch({type:'tpiOpenStatus',field:'slackErrorOpen', value:true});
        thirdPartyIntegrationDispatch({ type: 'switchToggleView', value:true });
        thirdPartyIntegrationDispatch({
            type: 'resetFields' ,
            status: false,
            statusMessage:'Try again',
            message:"Something went wrong",
            value:!tPISettingsInputInfoModal});
      }*/
      /*
      if(salesforcestatus  === 'Success'){
        thirdPartyIntegrationDispatch({type:'tpiOpenStatus',field:'salesforceOpen', value:true});
      }
      if(status  === 'success'){
        thirdPartyIntegrationDispatch({type:'tpiOpenStatus',field:'oneDriveOpen', value:true});
      }*/
      if (googleDriveUserStatus === "Success") {
        thirdPartyIntegrationDispatch({
          type: "tpiOpenStatus",
          field: "googleDriveUserOpen",
          value: true,
        });
       thirdPartyIntegrationDispatch({
          type: "resetFields",
          status: true,
          statusMessage: "Ok",
          message: "Great!  Now head on over to your Google Drive and right click on a folder or file you would like to load into Pluaris and click on share. Once the share modal opens, enter in the Share To email address.",
          value: !tPISettingsInputInfoModal,
        });
      }
      if (googleDriveAdminStatus === "Success") {
        thirdPartyIntegrationDispatch({
          type: "tpiOpenStatus",
          field: "googleDriveOpen",
          value: true,
        });
        thirdPartyIntegrationDispatch({
          type: "resetFields",
          status: true,
          statusMessage: "Ok",
          message: "Saved Successfully",
          value: !tPISettingsInputInfoModal,
        });
      }
      /*
      if(slackAdminStatus === 'Success'){
        thirdPartyIntegrationDispatch({type:'tpiOpenStatus',field:'slackAdminOpen', value:true});
        thirdPartyIntegrationDispatch({ type: 'switchToggleView', value:false });
        thirdPartyIntegrationDispatch({
          type: 'resetFields' , 
          status: true,
          statusMessage:'Ok',
          message:"Saved Successfully",
          value:!tPISettingsInputInfoModal});  
      }
      if(slackAdminStatus === 'Error'){
        thirdPartyIntegrationDispatch({type:'tpiOpenStatus',field:'slackAdminOpen', value:true});
        thirdPartyIntegrationDispatch({ type: 'switchToggleView', value:false });
        thirdPartyIntegrationDispatch({
          type: 'resetFields' , 
          status: false,
          statusMessage:'Try Again',
          message:"Something went wrong",
          value:!tPISettingsInputInfoModal});  
      }*/
    }
  }, []);
  /*
  useEffect(() => {
    checkForSlackSwitchChanges();
  }, [switchToggle]); */
  /*
  useEffect(() => {
    checkForOneDriveSwitchChanges(); 
  }, [oneDriveSwitchToggle]);
  */
  useEffect(() => {
    checkForGoogleDriveSwitchChanges();
  }, [googleDriveSwitchToggle]);
  /*
  useEffect(() => {
    checkForSalesForceSwitchChanges();
  }, [salesForceSwitchToggle]);
  */
  /*
  const checkForSlackSwitchChanges = async (e) => {
    if(userState.company !== '') {
    try {
      thirdPartyIntegrationDispatch({type:'loading', value:true});
      let slackAdminResponse = await axios.get(`https://bison.nowigence.ai:8443/slack/tokens?company=${userState.company}`);
      if(slackAdminResponse) {
        let data = slackAdminResponse.data.data;
        if(data === "null"){
          thirdPartyIntegrationDispatch({ type: 'isAdminSetup', field: 'isSlackAdminSetup', value: true });
        }
        thirdPartyIntegrationDispatch({type:'loading', value:false});
      }
    } catch (err) {
      console.log(err);
      thirdPartyIntegrationDispatch({type:'loading', value:false});
    }
    }
  }*/

  const checkForGoogleDriveSwitchChanges = async (e) => {
    try {
      thirdPartyIntegrationDispatch({
        type: "googleDriveLoading",
        value: true,
      });
      // let googleAdminResponse = await axios.get(`https://bison.nowigence.ai:8443/googledrive/admin/config?comp=${userState.company}`);
      let googleAdminResponse = await axios.get(
        `https://fire.nowigence.ai/googledrive/admin/config?comp=${userState.company}`
      );
      if (googleAdminResponse) {
        let data = googleAdminResponse.data.admin_data;
        if (data !== null) {
          thirdPartyIntegrationDispatch({
            type: "isAdminSetup",
            field: "isGoogledriveAdminSetup",
            value: true,
          });
        } else {
          thirdPartyIntegrationDispatch({
            type: "isAdminSetup",
            field: "isGoogledriveAdminSetup",
            value: false,
          });
        }
        thirdPartyIntegrationDispatch({
          type: "googleDriveLoading",
          value: false,
        });
      }
    } catch (err) {
      thirdPartyIntegrationDispatch({
        type: "googleDriveLoading",
        value: false,
      });
      thirdPartyIntegrationDispatch({
        type: "resetFields",
        status: false,
        statusMessage: "Try again",
        message: "Something went wrong",
        value: !tPISettingsInputInfoModal,
      });
    }
  };

  /*
  const checkForSalesForceSwitchChanges = async () => {
  try {
    thirdPartyIntegrationDispatch({type:'salesforceLoading', value:true});
    let salesforceAdminResponse = await axios.get(`https://triton.nowigence.ai/salesforce/admin/config?comp=${userState.company}`);
    if(salesforceAdminResponse) {
      let data = salesforceAdminResponse.data.admin_data;
      if(data !== null) {
        thirdPartyIntegrationDispatch({ type: 'isAdminSetup', field: 'isSalesforceAdminSetup', value: true });
      } else {
        thirdPartyIntegrationDispatch({ type: 'isAdminSetup', field: 'isSalesforceAdminSetup', value: false });
      }
      thirdPartyIntegrationDispatch({type:'salesforceLoading', value:false});
    }
  } catch (err) {
    thirdPartyIntegrationDispatch({type:'salesforceLoading', value:false});
    thirdPartyIntegrationDispatch({
      type: 'resetFields' ,
      status: false,
      statusMessage:'Try again',
      message:"Something went wrong",
      value:!tPISettingsInputInfoModal});
    }  
  }

  const checkForOneDriveSwitchChanges = async () => {
  if(userState.company !== '') {
  try {
    thirdPartyIntegrationDispatch({type:'oneDriveLoading', value:true});
    let oneDriveAdminResponse = await axios.get(`https://triton.nowigence.ai/onedrive/tokens?company=${userState.company}`);
    if(oneDriveAdminResponse) {
      let data = oneDriveAdminResponse.data.company;
      if(data !== "null") {
        thirdPartyIntegrationDispatch({ type: 'isAdminSetup', field: 'isOnedriveAdminSetup', value: true });
      } else {
        thirdPartyIntegrationDispatch({ type: 'isAdminSetup', field: 'isOnedriveAdminSetup', value: false });
      }
      thirdPartyIntegrationDispatch({type:'oneDriveLoading', value:false});
    }
  } catch (err) {
    thirdPartyIntegrationDispatch({type:'oneDriveLoading', value:false});
    thirdPartyIntegrationDispatch({
      type: 'resetFields' ,
      status: false,
      statusMessage:'Try again',
      message:"Something went wrong",
      value:!tPISettingsInputInfoModal});
    }
    }
  } */

  useEffect(() => {
    if (services.length > 0) {
      /*if(slackOpen){
        changeIntegration('slack');
        //handleAddToConfig();
      }
      if(slackErrorOpen){
        changeIntegration('slack');
      }*/
      /*
      if(salesforceOpen){
        changeIntegration('salesforce');
        salesforcehandleAddToConfig();
      }
      if(oneDriveOpen){
        changeIntegration('onedrive');
        oneDrivehandleAddToConfig();
      } */
      if (googleDriveOpen) {
        changeIntegration("googledrive");
      }
      if (googleDriveUserOpen) {
        changeIntegration("googledrive");
      //  googleDriveUserFlow();
      }
      /*
      if(slackAdminOpen){
        changeIntegration('slack');
      }*/
      //slackServiceStatus();
      //salesforceServiceStatus();
      //oneDriveServiceStatus();
      googleDriveServiceStatus();
    }
  }, [services]);
 /*
  const googleDriveUserFlow = async () => {
    // await googleDriveServiceStatus();
    
    thirdPartyIntegrationDispatch({
      type: "resetFields",
      status: true,
      statusMessage: "Ok",
      message: "Great!  Now head on over to your Google Drive and right click on a folder or file you would like to load into Pluaris and click on share. Once the share modal opens, enter in the Share To email address.",
      value: !tPISettingsInputInfoModal,
    });
  };*/

  /*
  const salesforcehandleAddToConfig = async() => {
    try {
      thirdPartyIntegrationDispatch({type:'salesforceLoading', value:true});
      let salesforceConfigAddResponse = await axios.post (`https://triton.nowigence.ai/salesforce/user/config/add`,{
        userId:userState._id,
        comp:userState.company
      })
      if(salesforceConfigAddResponse) {
        thirdPartyIntegrationDispatch({type:'salesforceLoading', value:false});
        await salesforceServiceStatus();
        salesforceUpload();
      }  
    } catch (error) {
      thirdPartyIntegrationDispatch({type:'salesforceLoading', value:false});
      thirdPartyIntegrationDispatch({
        type: 'resetFields' ,
        status: false,
        statusMessage:'Try again',
        message:"Something went wrong",
        value:!tPISettingsInputInfoModal});
    }
  } 

  const salesforceUpload = async() => {
    try {
      thirdPartyIntegrationDispatch({type:'salesforceUploaderLoading', value:true, salesforceUploadingMessage: 'Uploading Salesforce Content'});
      let salesforceUploadResponse = await axios.post (`https://triton.nowigence.ai/salesforce/user/data/upload`,{
        comp:userState.company,  
        userId:userState._id,
      })
      if(salesforceUploadResponse) {
        thirdPartyIntegrationDispatch({type:'salesforceUploaderLoading', value:false, salesforceUploadingMessage: ''});
        thirdPartyIntegrationDispatch({
          type: 'resetFields' ,
          status: true,
          statusMessage:'Ok',
          message:"Successfully Integrated with Salesforce",
          value:!tPISettingsInputInfoModal});
      }  
    } catch (error) {
      thirdPartyIntegrationDispatch({type:'salesforceUploaderLoading', value:false, salesforceUploadingMessage: ''});
      thirdPartyIntegrationDispatch({
        type: 'resetFields' ,
        status: false,
        statusMessage:'Try again',
        message:"Something went wrong",
        value:!tPISettingsInputInfoModal});
    }
  } */
  // this call is happening from backend now
  /*
  const handleAddToConfig = async() => {
    try {
      let slackConfigAddResponse = await axios.post (`https://triton.nowigence.ai/slack/user/config/add`,{
        email:userState.email,
        user:userState._id,
        company:userState.company
      })
      if(slackConfigAddResponse) {
        await slackServiceStatus();
        thirdPartyIntegrationDispatch({
          type: 'resetFields' ,
          status: true,
          statusMessage:'Ok',
          message:"Successfully Integrated with Slack",
          value:!tPISettingsInputInfoModal});
      }  
    } catch (error) {
      thirdPartyIntegrationDispatch({
        type: 'resetFields' ,
        status: false,
        statusMessage:'Try again',
        message:"Something went wrong",
        value:!tPISettingsInputInfoModal});
    }
  } */
  /*
  const oneDrivehandleAddToConfig = async() => {
    try {
      thirdPartyIntegrationDispatch({type:'oneDriveLoading', value:true});
      let oneDriveConfigAddResponse = await axios.post (`https://triton.nowigence.ai/onedrive/user/config/add`,{
        email:userState.email,
        user:userState._id,
        company:userState.company
      })
      if(oneDriveConfigAddResponse) {
        await oneDriveServiceStatus();
        uploadToOneDrive();
      }  
    } catch (error) {
      thirdPartyIntegrationDispatch({type:'oneDriveLoading', value:false});
      thirdPartyIntegrationDispatch({
        type: 'resetFields' ,
        status: false,
        statusMessage:'Try again',
        message:"Something went wrong",
        value:!tPISettingsInputInfoModal});
    }
  }

  const uploadToOneDrive = async () => {
    try {
          thirdPartyIntegrationDispatch({type:'uploaderLoading', value:true, uploadingMessage: 'Uploading One Drive Content'});
          let oneDriveApiResponse = await axios.get(`https://triton.nowigence.ai/onedrive/${userState.company}`);
          if(oneDriveApiResponse) {
            thirdPartyIntegrationDispatch({type:'uploaderLoading', value:false, uploadingMessage: ''});
            thirdPartyIntegrationDispatch({
              type: 'resetFields' ,
              status: true,
              statusMessage:'Ok',
              message:"Successfully Integrated with One Drive ", 
              value:!tPISettingsInputInfoModal});
          }
        } catch (err) {
          thirdPartyIntegrationDispatch({type:'uploaderLoading', value:false, uploadingMessage: ''});
          thirdPartyIntegrationDispatch({
            type: 'resetFields' ,
            status: false,
            statusMessage:'Try again',
            message:"Something went wrong",
            value:!tPISettingsInputInfoModal});
        }
  }*/

  /*
  const slackServiceStatus = async () => {
    try {
      thirdPartyIntegrationDispatch({type:'loading', value:true});
      let slackServiceResponse = await axios.get(`https://bison.nowigence.ai:8443/slack/user/config?company=${userState.company}&user=${userState._id}&email=${userState.email}`);
      if(slackServiceResponse) {
        let status = slackServiceResponse.data.success === 'false' ? false : true;
        thirdPartyIntegrationDispatch({ type: 'slackIntegration', value:status });
        services.map((value) => {
          if(value.name === 'slack'){
            value.isActive = status;
          }
          return value;
        });
        thirdPartyIntegrationDispatch({ type: 'loading', value:false });
      }
    } catch (err) {
      thirdPartyIntegrationDispatch({ type: 'loading', value:false });
      thirdPartyIntegrationDispatch({
        type: 'resetFields' ,
        status: false,
        statusMessage:'Try again',
        message:"Something went wrong",
        value:!tPISettingsInputInfoModal
      });
    }
  }*/
  /*
  const salesforceServiceStatus = async () => {   
    try { 
      let salesforceServiceResponse = await axios.get(`https://triton.nowigence.ai/salesforce/user/config/exists`, {
        params: {
          userId:userState._id,
          comp:userState.company
        }
      });
      if(salesforceServiceResponse) {
        let status = salesforceServiceResponse.data.result;
        thirdPartyIntegrationDispatch({ type: 'salesforceIntegration', value:status });
        if(status){
            services.map((value) => {
              if(value.name === 'salesforce'){
                value.isActive = status;
              }
            return value;
            });
          }
      }
    } catch (err) {
      thirdPartyIntegrationDispatch({
        type: 'resetFields' ,
        status: false,
        statusMessage:'Try again',
        message:"Something went wrong",
        value:!tPISettingsInputInfoModal
      });    
    }
  }

  const oneDriveServiceStatus = async () => {
    try {
      thirdPartyIntegrationDispatch({type:'oneDriveLoading', value:true});
      let oneDriveServiceResponse = await axios.get(`https://triton.nowigence.ai/onedrive/user/config?company=${userState.company}&user=${userState._id}&email=${userState.email}`);
      if(oneDriveServiceResponse) {
        let status = oneDriveServiceResponse.data.success === 'false' ? false : true;
        thirdPartyIntegrationDispatch({ type: 'oneDriveIntegration', value:status });
        services.map((value) => {
          if(value.name === 'onedrive'){
            value.isActive = status;
          }
          return value;
        });
        thirdPartyIntegrationDispatch({ type: 'oneDriveLoading', value:false });
      }
    } catch (err) {
      thirdPartyIntegrationDispatch({ type: 'oneDriveLoading', value:false });
      thirdPartyIntegrationDispatch({
        type: 'resetFields' ,
        status: false,
        statusMessage:'Try again',
        message:"Something went wrong",
        value:!tPISettingsInputInfoModal
      });
    }
  } */

  const googleDriveServiceStatus = async () => {
    try {
      thirdPartyIntegrationDispatch({
        type: "googleDriveLoading",
        value: true,
      });
      let googleDriveServiceResponse = await axios.get(
        // `https://bison.nowigence.ai:8443/integrations/config/user?userId=${
        `https://fire.nowigence.ai/integrations/config/user?userId=${
          userState._id
        }&comp=${userState.company}&admin=${
          userState.admin === true ? "Y" : "N"
        }`
      );
      if (googleDriveServiceResponse) {
        let responseServices = googleDriveServiceResponse.data.services;
        let googleStatus = responseServices.filter((data) => {
          return data.name === "googledrive";
        });
        let status = googleStatus[0]?.isActive;
        thirdPartyIntegrationDispatch({
          type: "googleDriveIntegration",
          value: status,
        });
        if (status) {
          services.map((value) => {
            if (value.name === "googledrive") {
              value.isActive = status;
            }
            return value;
          });
        }
        thirdPartyIntegrationDispatch({
          type: "googleDriveLoading",
          value: false,
        });
      }
    } catch (err) {
      thirdPartyIntegrationDispatch({
        type: "googleDriveLoading",
        value: false,
      });
      thirdPartyIntegrationDispatch({
        type: "resetFields",
        status: false,
        statusMessage: "Try again",
        message: "Something went wrong",
        value: !tPISettingsInputInfoModal,
      });
    }
  };
  const changeIntegration = (data) => {
    services.map((value) => {
      if (value.name != data) {
        value.isSelected = false;
      }
      return value;
    });
    thirdPartyIntegrationDispatch({
      type: "setCurrentIntegration",
      value: data,
    });
  };

  const closeServiceDetails = (data) => {
    services.map((value) => {
      value.isSelected = true;
      return value;
    });
    thirdPartyIntegrationDispatch({ type: "closeService", value: "none" });
  };

  const tPIDeactivation = (data) => {
    services.map((value) => {
      value.isSelected = true;
      if (value.name === data.currentIntegration) {
        value.isActive = false;
      }
      /*
        if(data.currentIntegration === 'slack'){
          thirdPartyIntegrationDispatch({ type: 'slackIntegration', value:false });
        }*/
      /*  
        if(data.currentIntegration === 'onedrive'){
          thirdPartyIntegrationDispatch({ type: 'oneDriveIntegration', value:false });
        }
        if(data.currentIntegration === 'salesforce'){
          thirdPartyIntegrationDispatch({ type: 'salesforceIntegration', value:false });
        } */
      if (data.currentIntegration === "googledrive") {
        thirdPartyIntegrationDispatch({
          type: "googleDriveIntegration",
          value: false,
        });
      }
      return value;
    });
    thirdPartyIntegrationDispatch({ type: "closeService", value: "none" });
  };

  return (
    <ThirdPartyIntegrationContext.Provider
      value={{
        serviceSelected,
        tPIDeactivation,
        currentIntegration,
        success,
        message,
        services,
        statusMessage,
        tPISettingsInputInfoModal,
        closeServiceDetails,
        changeIntegration,
        thirdPartyIntegrationDispatch,
        thridPartyIntegrationState,
        // hideAddButton,
        // hideTerminationFooter,
        // hideSalesforceAddButton,
        // hideSalesforceTerminationFooter,
        // hideOneDriveAddButton,
        // hideOneDriveTerminationFooter,
        hideGoogleDriveAddButton,
        hideGoogleDriveTerminationFooter,
        // slackStatus,
        // slackServiceStatus,
        // salesforceStatus,
        // oneDriveStatus,
        googleDriveStatus,
        // salesforceServiceStatus,
        // oneDriveServiceStatus,
        googleDriveServiceStatus,
        // salesforceUpload,
        // loader,
        // salesforceLoader,
        // oneDriveLoader,
        googleDriveLoader,
        //uploadLoader,
        //uploadingMessage,
        googleDriveUploadLoader,
        googleDriveUploadingMessage,
        // salesforceUploadLoader,
        // salesforceUploadingMessage,
        // switchToggle,
        // salesForceSwitchToggle,
        // oneDriveSwitchToggle,
        googleDriveSwitchToggle,
        //slackOpen,
        //salesforceOpen,
        //oneDriveOpen,
        googleDriveOpen,
        googleDriveUserOpen,
        googleDriveEmail,
        //isSlackAdminSetup,
        isGoogledriveAdminSetup,
        //isOnedriveAdminSetup,
        //isSalesforceAdminSetup,
        //slackAdminOpen,
        //slackErrorOpen,
      }}
    >
      {props.children}
    </ThirdPartyIntegrationContext.Provider>
  );
}

export const useThirdPartyIntegrationContext = () => {
  return useContext(ThirdPartyIntegrationContext);
};
