import React, { useEffect, useReducer, useState } from "react";
import { useGetSingleNoteQuery } from "../../../../codeGenFE";
import { customErrorHandler } from "../../../../utils/customErrorHandler";
// CONTEXT
import { useNotebookContext, useViewPortContext } from "../../../context/allContexts";
// UTIL
import {
  convertDate,
  convertDateWithTime,
} from "../../../util/MMDDYYwithslashtimestampFormater";
import ComponentMessagingOverlay from "../../ComponentMessagingOverlay/ComponentMessagingOverlay";
import DashErrorMessage from "../../DashErrorMessage/DashErrorMessage";
import DraftEditor from "../../DraftEditor/draftEditor";
import DeleteConfirmNotebook from "./DeleteConfirmNotebook/DeleteConfirmNotebook";
import NBShareModal from "./NBShareModal/NBShareModal";
import PDFConfirm from "./PDFConfirm/PDFConfirm";
// STYLES
import styles from "./SingleNote.module.scss";
import SingleNoteTitle from "./SingleNoteTitle/SingleNoteTitle";
import { axios } from '../../../../utils/axiosConfig';
import { REST_BASE_ENDPOINT } from "../../../../constants";
import { saveAs } from 'file-saver'
import SocialShareConfirm from './SocialShareConfirm/index';
import GPT3Tokenizer  from 'gpt3-tokenizer';
import LoadingCube, { LoadingCubeTheme } from "../../LoadingCube/LoadingCube";
import toast from "react-hot-toast";

export enum SingleNoteActionChoices {
  SET_SAVING,
  SET_NOTE_ERROR_MSG,
  SET_EXPAND_MORE,
  ADD_CHAT_GPT_TEXT,
  SET_CHAT_GPT_TYPING,
}

export interface SingleNoteState {
  saving: boolean;
  noteErrorMsg: string | null;
  expandMore: boolean | null;
  rawData: string | null;
  chatGPTtext: string | null;
  chatGPTTextTyping: boolean;
  retry: () => void;
}

export type SingleNoteActions =
  | { type: SingleNoteActionChoices.SET_SAVING; payload: boolean }
  | { type: SingleNoteActionChoices.ADD_CHAT_GPT_TEXT; payload: string }
  | { type: SingleNoteActionChoices.SET_CHAT_GPT_TYPING; payload: boolean }
  | { type: SingleNoteActionChoices.SET_EXPAND_MORE; payload: {
    status: boolean,
    rawData: any[] 
  } }
  | {
      type: SingleNoteActionChoices.SET_NOTE_ERROR_MSG;
      msg: string | null;
      retry: () => void;
    };

const singleNoteReducer = (
  state: SingleNoteState,
  action: SingleNoteActions
): SingleNoteState => {
  switch (action.type) {
    case SingleNoteActionChoices.SET_SAVING:
      return {
        ...state,
        saving: action.payload,
      };
    case SingleNoteActionChoices.SET_NOTE_ERROR_MSG:
      console.log("action.msg :>> ", action.msg);
      return {
        ...state,
        noteErrorMsg: action.msg === 'Sorry, an unexpected error has occurred' ? "Sorry, there is a delay in loading the data, this could be due to network issue, Please retry." : action.msg,
        retry: action.retry,
      };

    case SingleNoteActionChoices.ADD_CHAT_GPT_TEXT:
      // console.log("action.msg :>> ", action.msg);
      return {
        ...state,
        chatGPTtext: action.payload
      };  
    case SingleNoteActionChoices.SET_CHAT_GPT_TYPING:
      return {
        ...state,
        chatGPTTextTyping: action.payload
      }  
    case SingleNoteActionChoices.SET_EXPAND_MORE:
      // console.log("action.msg :>> ", action.msg);
      let textArray = action.payload.rawData.map((raw: any) => raw.text)
      return {
        ...state,
        expandMore: action.payload.status,
        rawData: textArray.join(""),
      };  
    default:
      return state;
  }
};

const initState: SingleNoteState = {
  saving: false,
  noteErrorMsg: null,
  expandMore: null,
  rawData: null,
  chatGPTtext: null,
  chatGPTTextTyping: false,
  retry: () => {},
};

var DraftExporter = require('draft-js-exporter');
const NoteBookPdf = (note: any) => <div  dangerouslySetInnerHTML={{__html: note}}/>

const SingleNote = () => {
  const { notebookState, notebookDispatch } = useNotebookContext();
  const { currentActiveNoteId, editorOverlay } = notebookState;
  const [currentRawState, setCurrentRawState] = useState("<></>")
  const [fetchingContentFromChatgpt, setFetchingContentFromChatgpt] = useState(false)
  // @ts-ignore
  const { width } = useViewPortContext();
  const [singleNoteState, singleNoteDispatch] = useReducer(
    singleNoteReducer,
    initState
  );
  const { saving, noteErrorMsg, retry } = singleNoteState;

  useEffect(() => {
    if(localStorage.getItem("reloaded") && localStorage.getItem('activeNotebook') && localStorage.getItem('type'))
    {
      console.log(localStorage.getItem('type'), "type")
      notebookDispatch({ type: "set editor overlay", name:  localStorage.getItem('type')})
    }
  },[])

  const fetchChatGPT = async () => {
    if(!singleNoteState.expandMore) {
      return toast.error(`Expansion is done for topics or short statements, Please reduce the length of your input and try again. For example: "Gig economy in India"  or  "Impact of agriculture waste burning on climate"`, {
        duration: 10000,
        style: {
          maxWidth: "400px",
          height: "130px",
          font: "1rem"
        },
      })
    }
    setFetchingContentFromChatgpt(true)
    singleNoteDispatch({type: SingleNoteActionChoices.SET_CHAT_GPT_TYPING, payload: true})
    try {
      var config: any = {
        method: 'post',
        url: `${REST_BASE_ENDPOINT}/auth/text/completion`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : {
          "text": `show me 15 points related to these: ${singleNoteState.rawData}`
        }
      };
      const res = await axios(config)
      if(res?.data?.data) {
        singleNoteDispatch({type: SingleNoteActionChoices.ADD_CHAT_GPT_TEXT, payload: res?.data?.data})
        setFetchingContentFromChatgpt(false)
      }
    } catch(err: any) {
      singleNoteDispatch({type: SingleNoteActionChoices.SET_CHAT_GPT_TYPING, payload: false})
      setFetchingContentFromChatgpt(false)
      return toast.error(err?.response?.data?.message || "Something went wrong on auto fetch. Please contact to the Pluaris Support team.")
    }
  }

  const { data, error, refetch, isFetching } = useGetSingleNoteQuery(
    {
      noteId: currentActiveNoteId,
    },
    { enabled: !!currentActiveNoteId }
  );

  const note = data?.getSingleNote;

  const giveMeDate = (unixtime: number) => {
    const dateLabel = `${convertDate(unixtime)} at ${convertDateWithTime(
      unixtime
    )}`;
    return dateLabel;
  };

  if (error) {
    return (
      <DashErrorMessage
        refetch={refetch}
        error={customErrorHandler(error)}
        isFetching={isFetching}
      />
    );
  }

  const currentStateData = (state: any) => {
    setCurrentRawState(state)
  }

  const handleExport = async (type: string) => {
      if(currentRawState) {
        var parser = new DOMParser();
        var initialNotebookDoc  = parser.parseFromString(currentRawState || "<></>", 'text/html')
        var noteBookDoc = parser.parseFromString(currentRawState || "<></>", 'text/html');
        const content = noteBookDoc.querySelector("body")?.innerHTML
        const titleHtml = document.querySelector(
          "#notebookPdfTargetTitle"
        )?.innerHTML;
        var title = document.querySelector(
          "#notebookPdfTargetTitle"
        )?.innerHTML
        var header = `<div style="text-align: center; margin-bottom: 40px;"><h2>${title}</h2></div>`
        var topMenu = `
        <div class="topMenu" style="grid-area: topMenu;           width: 100%;           height: 50px;           z-index: 400;           display: -webkit-flex;           display: -moz-box;           display: -ms-flexbox;           display: flex;           -webkit-align-items: center;           -moz-box-align: center;           -ms-flex-align: center;           align-items: center;           -webkit-justify-content: space-evenly;           -moz-box-pack: space-evenly;           -ms-flex-pack: space-evenly;           justify-content: space-between;">
          <div class="TopMenuContainer" style="display: flex;                   width: 100%; max-width: 20%;                   align-items: center;                   justify-content: space-between;                   height: 100%;                            padding: 0 10px;                   position: relative;">
              <div class="topMenu__logoNNameNButtonContainer" style="display: -webkit-flex;                   display: -moz-box;                   display: -ms-flexbox;                   display: flex;                   -webkit-justify-content: flex-start;                   -moz-box-pack: start;                   -ms-flex-pack: start;                   justify-content: flex-start;                   -webkit-align-items: center;                   -moz-box-align: center;                   -ms-flex-align: center;                   align-items: center;                   width: -webkit-max-content;                   width: -moz-max-content;                   width: max-content;                   height: 100%;">
              <img class="logo" src="https://pluarisazurestorage.blob.core.windows.net/nowigence-web-resources/images/image (3).png" alt="Nowigence Logo" style="height: 30px;                     width: auto;                     max-height: 30px;"></img>
              </div>
          </div>
        </div>
        `
        const headerNode = new DOMParser().parseFromString(header, "text/xml").querySelector('div')
        const topMenuNode = new DOMParser().parseFromString(topMenu, "text/xml").querySelector('div')
        if (content && titleHtml && headerNode && topMenuNode) {
          const parent = document.createElement('div');
          parent.className = "main"
          parent.style.minHeight = "100vh";
          parent.style.width = "100%";
          parent.style.display = "flex";
          parent.style.flexDirection = "column";
          parent.innerHTML = (noteBookDoc.body.innerHTML)
          // noteBookDoc.body.replaceChild(parent)
          // noteBookDoc.body.innerHTML = parent.innerHTML 
          var meta = document.createElement('meta');
          // meta.httpEquiv = "Content-Type";
          // meta.content = "text/html;charset=utf-8";
          meta.setAttribute('charset', "utf-8")
          noteBookDoc.getElementsByTagName('head')[0].appendChild(meta);
          // noteBookDoc.head.insertAdjacentElement('afterbegin', )
          console.log(noteBookDoc.head, "akash")
          noteBookDoc.body.innerHTML = "";
          noteBookDoc.body.insertAdjacentElement('afterbegin', parent)
          noteBookDoc.body.querySelector('div.main')?.insertAdjacentElement('afterbegin', headerNode)
          if(noteBookDoc.body.querySelector('div.main'))
            noteBookDoc.body.querySelector('div.main')?.insertAdjacentElement('afterbegin', topMenuNode)
          if(type !== 'html') {
            console.log(noteBookDoc.documentElement.innerHTML, "tada")
            axios({
              method: 'post',
              responseType: 'arraybuffer', //Force to receive data in a Blob Format
              url: `${REST_BASE_ENDPOINT}/auth/export-notebook`,
              data: {html: noteBookDoc.documentElement.innerHTML, type}
            })
              .then(res => {
                  // const blob = new Blob([res.data], {
                  //     type: 'application/octet-stream'
                  // })
                  // console.log(res)
                  // saveAs(blob, title ? `${title}.pdf` : `notebook.pdf`)
                  if(type === 'pdf'){
                    const blob = new Blob([res.data], {
                      type: 'application/octet-stream'
                    })
                    saveAs(blob, title ? `${title}.pdf` : `notebook.pdf`)
                  } else {
                    const blob = new Blob([res.data], {
                      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    })
                    saveAs(blob, title ? `${title}.docx` : `notebook.docx`)
                  }
              })
              .catch(error => {
                  console.log(error);
                  console.log(error.message);
              });
          } else {
            console.log(noteBookDoc.documentElement.innerHTML)
            axios({
              method: 'post',
              responseType: 'arraybuffer', //Force to receive data in a Blob Format
              url: `${REST_BASE_ENDPOINT}/auth/export-notebook`,
              data: {html: initialNotebookDoc.documentElement.innerHTML, type: "html", title}
            })
              .then(res => {
                const blob = new Blob([res.data], {
                  type: 'text/html'
                })
                console.log(res)
                saveAs(blob, title ? `${title}.html` : `notebook.html`)
              })
              .catch(error => {
                  console.log(error.message);
              });
          }
        }
      }
  }

  return (
    <div className={styles.singleNoteContainer}>
      {/* title */}
      {note && <SingleNoteTitle note={note} setSaving={singleNoteDispatch} />}
      {/* button row */}
      {/* Rich text editor */}
      {note && (
        <div className={styles.editorContainer}>
          <DraftEditor note={note} setSaving={singleNoteDispatch} currentStateData={(state: any) => currentStateData(state)} singleNoteState={singleNoteState}/>
          {editorOverlay === "share" && (
            <NBShareModal
              note={note}
              close={() =>
                notebookDispatch({ type: "set editor overlay", name: false })
              }
            />
          )}
          {editorOverlay === "delete" && (
            <DeleteConfirmNotebook
              close={() =>
                notebookDispatch({ type: "set editor overlay", name: false })
              }
            />
          )}
          {(editorOverlay === "linkedin" || editorOverlay === 'twitter') && (
            <SocialShareConfirm
              close={() =>
                notebookDispatch({ type: "set editor overlay", name: false })
              }
              note={note}
            />
          )}
          {editorOverlay === "PDF" && (
            <PDFConfirm
              close={() =>
                notebookDispatch({
                  type: "set editor overlay",
                  name: false,
                })
              }
              handleExport={handleExport}
            />
          )}
          {/* save button */}
          <div className={styles.buttonContainer}>
            {
              notebookState.chatGTPApiExist && <div className={`${styles.saveContainer} ${styles.expandMore}`}>
              <button className={styles.noteSaveBtn} onClick={fetchChatGPT} disabled={singleNoteState.chatGPTTextTyping}>
                {!fetchingContentFromChatgpt ? "Auto Expand" : "Fetching Data..."}
              </button>
            </div>
            }
              <div className={styles.saveContainer}>
                {!noteErrorMsg && (
                  <p className={styles.saveDate}>
                    {saving ? "Saving:" : "Last Save:"}
                    {note?.richText?.metaData?.lastUpdated &&
                      ` ${giveMeDate(note.richText.metaData.lastUpdated)}`}
                  </p>
                )}
                <button className={styles.noteSaveBtn}>Save</button>
              </div>
          </div>
          {/* {
            singleNoteState.expandMore ? 
            <div className={styles.buttonContainer}>
              <div className={styles.saveContainer}>
                <button className={styles.noteSaveBtn} onClick={fetchChatGPT} disabled={singleNoteState.chatGPTTextTyping}>
                  {!fetchingContentFromChatgpt ? "Auto Expand" : "Fetching Data..."}
                </button>
              </div>
              <div className={styles.saveContainer}>
                {!noteErrorMsg && (
                  <p className={styles.saveDate}>
                    {saving ? "Saving:" : "Last Save:"}
                    {note?.richText?.metaData?.lastUpdated &&
                      ` ${giveMeDate(note.richText.metaData.lastUpdated)}`}
                  </p>
                )}
                <button className={styles.noteSaveBtn}>Save</button>
              </div>
            </div>
            :
            <div className={styles.saveContainer}>
              {!noteErrorMsg && (
                <p className={styles.saveDate}>
                  {saving ? "Saving:" : "Last Save:"}
                  {note?.richText?.metaData?.lastUpdated &&
                    ` ${giveMeDate(note.richText.metaData.lastUpdated)}`}
                </p>
              )}
              <button className={styles.noteSaveBtn}>Save</button>
            </div>
          } */}
        </div>
      )}
      {noteErrorMsg && (
        <ComponentMessagingOverlay
          showX={false}
          message={noteErrorMsg}
          close={retry}
          buttonText={"Retry"}
        />
      )}
    </div>
  );
};

export default SingleNote;
