import Fuse from "fuse.js";
import React, { FC, useEffect, useReducer } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useUserTagsQuery } from "../../../codeGenFE";
import { customErrorHandler } from "../../../utils/customErrorHandler";
import LoadingCube from "../LoadingCube/LoadingCube";
import styles from "./AddTagsDropdown.module.scss";

enum AddTagsDropdownChoices {
  SET_TAG,
  SET_AVAILABLE_TAGS,
  SET_FILTERED_TAGS,
  SET_TAG_DROPDOWN,
}

type AddTagsDropdownState = {
  tag: string;
  filteredTags: string[];
  tagDropdown: boolean;
};

type AddTagsDropdownActions =
  | {
      type: AddTagsDropdownChoices.SET_TAG;
      tag: string;
    }
  | { type: AddTagsDropdownChoices.SET_FILTERED_TAGS; filteredTags: string[] }
  | { type: AddTagsDropdownChoices.SET_TAG_DROPDOWN; tagDropdown: boolean };

const ATDDReducer = (
  state: AddTagsDropdownState,
  action: AddTagsDropdownActions
): AddTagsDropdownState => {
  switch (action.type) {
    case AddTagsDropdownChoices.SET_TAG:
      return {
        ...state,
        tag: action.tag,
      };
    case AddTagsDropdownChoices.SET_TAG_DROPDOWN:
      return {
        ...state,
        tagDropdown: action.tagDropdown,
      };
    case AddTagsDropdownChoices.SET_FILTERED_TAGS:
      return {
        ...state,
        filteredTags: action.filteredTags,
      };
    default:
      return state;
  }
};

const initState: AddTagsDropdownState = {
  tag: "",
  filteredTags: [],
  tagDropdown: false,
};

interface Props {
  setSelectedTags: (newTags: string[]) => void;
  selectedTags: string[];
}

// const AddTagsDropdown: FC<Props> = ({ tags, setTags }) => {
const AddTagsDropdown: FC<Props> = ({ setSelectedTags, selectedTags }) => {
  const { data, status, error } = useUserTagsQuery();
  const availableTags = data?.userTags;

  const [ATDDState, ATDDDispatch] = useReducer(ATDDReducer, initState);
  const { tag, filteredTags, tagDropdown } = ATDDState;

  // @ts-ignore
  useEffect(() => {
    let link = document.querySelector(".deal-with-focus-with-javascript");

    if (link) {
      // @ts-ignore
      link.addEventListener("focus", function () {
        // @ts-ignore
        link.parentElement.classList.add("focus");
      });
      // @ts-ignore
      link.addEventListener("blur", function () {
        // @ts-ignore
        link.parentElement.classList.remove("focus");
      });
    }
  });

  useEffect(() => {
    if (tag.trim() === "") {
      ATDDDispatch({
        type: AddTagsDropdownChoices.SET_FILTERED_TAGS,
        // @ts-ignore
        filteredTags: availableTags ? availableTags : [],
      });
    } else {
      // Fuzzy search here
      const options = {
        shouldSort: true,
        threshold: 0.2,
        location: 0,
        distance: 500,
        minMatchCharLength: 1,
      };
      if (availableTags && availableTags.length > 0) {
        let fuse = new Fuse(availableTags, options);
        let result = fuse.search(tag).map((i) => i.item);
        ATDDDispatch({
          type: AddTagsDropdownChoices.SET_FILTERED_TAGS,
          // @ts-ignore
          filteredTags: result,
        });
      }
    }
  }, [tag, availableTags]);

  function addTag(tag: string) {
    tag = tag.trim();
    if (!tag) return null;
    let duplicate = false;
    selectedTags.forEach((string) => {
      if (string === tag) {
        duplicate = true;
      }
    });
    if (duplicate) return null;
    setSelectedTags([...selectedTags, tag]);
    ATDDDispatch({ type: AddTagsDropdownChoices.SET_TAG, tag: "" });
  }

  function keyboardAddTag(e: KeyboardEvent) {
    if (e.key === "Enter" && tag !== "") {
      if (filteredTags[0]) {
        addTag(filteredTags[0]);
      } else {
        addTag(tag);
      }
    }
  }

  const removeTag = (t: string) => {
    let newTags = selectedTags.filter((string) => {
      return string !== t;
    });
    setSelectedTags(newTags);
  };

  return (
    <div
      className={styles.tags_container}
      onMouseLeave={() =>
        ATDDDispatch({
          type: AddTagsDropdownChoices.SET_TAG_DROPDOWN,
          tagDropdown: false,
        })
      }
    >
      <div
        className={`${styles.dropdown_display} ${
          tagDropdown ? styles.dropDown_open : null
        }`}
      >
        {selectedTags &&
          selectedTags.map((t) => {
            return (
              <div
                key={t}
                className={styles.chosenTags}
                onClick={(e) => {
                  e.stopPropagation();
                  removeTag(t);
                }}
              >
                <span className={styles.chosenTags__tag}>{t}</span>
                <span className={styles.chosenTags__remove}>X</span>
              </div>
            );
          })}
        <div className={styles.addTag}>
          <input
            type="text"
            value={tag}
            onKeyPress={(e: any) => keyboardAddTag(e)}
            onClick={() =>
              ATDDDispatch({
                type: AddTagsDropdownChoices.SET_TAG_DROPDOWN,
                tagDropdown: true,
              })
            }
            onChange={(e) =>
              ATDDDispatch({
                type: AddTagsDropdownChoices.SET_TAG,
                tag: e.target.value,
              })
            }
            placeholder="add tag"
            className={styles.addTag_input}
          />
          <AiOutlinePlus
            className={styles.addTag_Btn}
            onClick={(e) => {
              e.preventDefault();
              addTag(tag);
            }}
          />
        </div>
      </div>
      {tagDropdown && (
        <div className={styles.tagList_container}>
          <div className={styles.tagList__scrollContainer}>
            {status === "loading" && (
              <div className={styles.loadingWrapper}>
                <LoadingCube height={50} width={50} hasBackground={false} />
              </div>
            )}
            {status === "error" && (
              <div className={styles.tagsErrorMsg}>
                {customErrorHandler(error)}
              </div>
            )}

            {filteredTags &&
              filteredTags
                // @ts-ignore
                .sort((a, b) => a.toLowerCase() > b.toLowerCase())
                .map((filteredTag) => {
                  return (
                    <button
                      key={filteredTag}
                      className={styles.tagList__tag}
                      onClick={(e) => {
                        e.preventDefault();
                        addTag(filteredTag);
                      }}
                    >
                      {filteredTag}
                    </button>
                  );
                })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddTagsDropdown;
