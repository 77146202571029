import React, { useState, createContext, useContext } from "react";

import styles from "./DashModal.module.scss";
import classes from "../../Pages/Dashboard/ControlPanel.module.scss";
import CloseModalBtn from "../../Components/CloseModalBtn/CloseModalBtn";

export const DashModalContext = createContext();

export function DashModalProvider(props) {
  const [modalDashContent, setDashModalContent] = useState(
    <p>
      You need to set what you want to display here with setDashModalContent
      from context
    </p>
  );

  //const Loader = () => {
  //return <Spinner />;
  //};
  const DashModal = (toggleModal) => {
    return (
      <div className={`${styles.modalBody} ${classes.dashModalArea}`}>
        <div className={styles.xIcon}>
          <CloseModalBtn theme="light" close={() => toggleModal(false)} />
        </div>
        <div className={styles.modalContent}> {modalDashContent} </div>
      </div>
    );
  };

  return (
    <DashModalContext.Provider
      value={{
        DashModal,
        setDashModalContent,
      }}
    >
      {props.children}
    </DashModalContext.Provider>
  );
}

export const useDashModalContext = () => useContext(DashModalContext);
