export const trendingPlaceholderData = {
  articlesTrending: {
    movers: {
      company: [
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
      ],
      product: [
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
      ],
      person: [
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
      ],
      location: [
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
      ],
    },
    mentions: {
      company: [
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
      ],
      product: [
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
      ],
      person: [
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
      ],
      location: [
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
        { label: "", value: 0 },
      ],
    },
  },
};
