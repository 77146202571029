import LinkedInIcon from "../../../assets/img/footer-linkedin.svg";
import FacebookIcon from "../../../assets/img/footer-facebook.svg";
import TwitterIcon from "../../../assets/img/footer-twitter.svg";
import YoutubeIcon from "../../../assets/img/footer-youtube.svg";

const socials = [
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/nowigence-inc./",
    icon: (
      <svg
        className="linkedin"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        width="30px"
        height="30px"
      >
        {" "}
        <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M10.496,8.403 c0.842,0,1.403,0.561,1.403,1.309c0,0.748-0.561,1.309-1.496,1.309C9.561,11.022,9,10.46,9,9.712C9,8.964,9.561,8.403,10.496,8.403z M12,20H9v-8h3V20z M22,20h-2.824v-4.372c0-1.209-0.753-1.488-1.035-1.488s-1.224,0.186-1.224,1.488c0,0.186,0,4.372,0,4.372H14v-8 h2.918v1.116C17.294,12.465,18.047,12,19.459,12C20.871,12,22,13.116,22,15.628V20z" />
      </svg>
    ),
  },
  {
    name: "Facebook",
    href: "https://www.facebook.com/Nowigence/",
    icon: (
      <svg
        className="linkedin"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        width="30px"
        height="30px"
      >
        {" "}
        <path d="M15,3C8.373,3,3,8.373,3,15c0,6.016,4.432,10.984,10.206,11.852V18.18h-2.969v-3.154h2.969v-2.099c0-3.475,1.693-5,4.581-5 c1.383,0,2.115,0.103,2.461,0.149v2.753h-1.97c-1.226,0-1.654,1.163-1.654,2.473v1.724h3.593L19.73,18.18h-3.106v8.697 C22.481,26.083,27,21.075,27,15C27,8.373,21.627,3,15,3z" />
      </svg>
    ),
  },
  {
    name: "Twitter",
    href: "https://twitter.com/Nowigence",
    icon: (
      <svg
        className="linkedin"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        width="30px"
        height="30px"
      >
        {" "}
        <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
      </svg>
    ),
  },
  {
    name: "YouTube",
    href: "https://www.youtube.com/channel/UCJBS6rVipgZTxDfBPCoNZlA",
    icon: (
      <svg
        className="linkedin"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="30px"
        height="30px"
      >
        <path d="M42.042,13.856C43.773,15.577,44,18.311,44,25s-0.227,9.423-1.958,11.144C40.311,37.864,38.508,38,25,38	S9.689,37.864,7.958,36.144S6,31.689,6,25s0.227-9.423,1.958-11.144S11.492,12,25,12S40.311,12.136,42.042,13.856z M21.76,30.933	l9.717-5.63L21.76,19.76V30.933z" />
      </svg>
    ),
  },
];

export default function NewFooter() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden py-20 px-6 sm:py-24 lg:px-8">
        <div className="mt-10 flex justify-center space-x-10">
          {socials.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 cursor-pointer "
              target="_blank"
            >
              {item.icon}
            </a>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          Nowigence, Inc. © 2023
        </p>
      </div>
    </footer>
  );
}
