export const GQL_ENDPOINT = `https://app.nowigence.ai/graphql`;
export const GQL_SUBSCRIPTION_ENDPOINT = `wss://app.nowigence.ai/graphql`;
export const REST_BASE_ENDPOINT = `https://app.nowigence.ai`;
export const PYTHON_REST_BASE_ENDPOINT = `https://fireload.lille.ai`;
export const URL = "https://app.nowigence.ai";
export const LINKEDIN_CLIENT_ID = '868bordkbw6hm6';

export const LINKEDIN_CLIENT_SECRET = 'kOALvAhpSOkYx4MN';

export const TWITTER_RESR_API_URL = 'https://api.twitter.com/';

export const BLOCKCHAIN_URL = 'https://blockchain.nowigence.ai/';

export const MAX_KEYWORDS_LIMIT = 3;

export const VERCEL_DOMAIN = 'app.nowigence.ai';

export const REWARD_LOGIN_COUNT = 2;

export const API_ENDPOINTS = {
    TW_ACCESS_TOKEN: '/auth/twitter/access-token',
    ARTICLE: '/auth/landing/article',
    LI_POST: '/auth/landing/linkedin/post',
    LI_ACCESS_TOKEN: '/auth/linkedin/token',
    LI_PROFILE: '/auth/linkedin/me',
    TW_POST: '/auth/landing/twitter/post',
};

export const POST_HEADERS = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers':
        'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
};

export const LOCAL_STORAGE_KEYS = {
    THEME: 'Theme',
    ACCESS_TOKEN: 'accessToken',
};
