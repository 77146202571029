import { FC } from "react";
import { useArticlesTrendingQuery } from "../../../../../codeGenFE";
import { useSearchContext } from "../../../../context/allContexts";
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";
import { trendingPlaceholderData } from "../../../../Pages/Dashboard/DashInfoContainer/Trending/TrendingUtils";
import DropdownDisplayBelow from "../../../DropdownDisplayBelow/DropdownDisplayBelow";

interface Props {
  isOpen: boolean;
  toggleOpenFilter: () => void;
}

const PersonFilter: FC<Props> = ({ isOpen, toggleOpenFilter }) => {
  const { searchDispatcher, searchState, searchInputOpts } = useSearchContext();

  const { data } = useArticlesTrendingQuery(
    {
      inputOpts: searchInputOpts,
    },
    {
      refetchOnWindowFocus: false,
      initialData: trendingPlaceholderData,
      keepPreviousData: true,
    }
  );

  const moversProduct = data?.articlesTrending?.movers?.person
    ? (data?.articlesTrending?.movers?.person.map((p) => p?.label) as string[])
    : [];
  const mentionsProduct = data?.articlesTrending?.mentions?.person
    ? (data?.articlesTrending?.mentions?.person.map(
        (p) => p?.label
      ) as string[])
    : [];

  return (
    <DropdownDisplayBelow
      selectableItems={[...moversProduct, ...mentionsProduct].filter(
        (s) => s !== ""
      )}
      isDropdownOpen={isOpen}
      toggleDropdown={toggleOpenFilter}
      dropdownDisplay={"Person"}
      selectionDisplay={searchState?.person}
      select={(p) =>
        searchDispatcher({
          type: SearchContextActions.SET_PERSON,
          person: p,
        })
      }
      unselect={() =>
        searchDispatcher({
          type: SearchContextActions.SET_PERSON,
          person: null,
        })
      }
      makeSearchable={false}
    />
  );
};

export default PersonFilter;
