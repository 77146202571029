import React, { useState, useEffect } from "react";

import styles from "./MyMemoryInfoModal.module.scss";

import CloseModalBtn from "../../../Components/CloseModalBtn/CloseModalBtn";

const MyMemoryInfoModal = ({ close, myMemoryInfoModal }) => {
  const [shouldRender, setShouldRender] = useState(myMemoryInfoModal);

  useEffect(() => {
    if (myMemoryInfoModal) setShouldRender(true);
  }, [myMemoryInfoModal]);

  const onAnimationEnd = () => {
    if (!myMemoryInfoModal) setShouldRender(false);
  };
  return (
    shouldRender && (
      <React.Fragment>
        <div
          onAnimationEnd={onAnimationEnd}
          className={`${styles.backdrop} ${
            myMemoryInfoModal ? styles.backdrop : styles.fadeOut
          }`}
          onClick={() => close()}
        />
        <div
          className={`${styles.myMemoryInfoModalWrapper} ${
            myMemoryInfoModal ? styles.myMemoryInfoModalWrapper : styles.slideUp
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.closeBtn}>
            <CloseModalBtn theme="light" close={close} />
          </div>
          <h2>Reader</h2>
          <p className={styles.readerInfoSubText}>
            Reader contains all the user uploaded content, shared content and daily retrieved articles as per the user's topic monitoring keywords
          </p>
          <p>
            This is a collection of all information relevant to you. Refine search results with complex searches by using the Keyword Filter at the top of the page and the Filters in the left navigation.
          </p>
          <p>
            For example, you can find information that may be of risk to your customers. Articles can also be shared with other users or on LinkedIn & Twitter.
          </p>
          <p>
          Note: If an article is from the news, it will expire in 30 days. To save it permanently click on the 3 dots on the right of each article and click "Add to My Memory".
          Each aticles when expanded shows summary points of the content and also drivers that represent some action statements in the content.
          </p>
          <p>
          *Information includes all acceptable data types which are news, text, and URL’s.
          </p>
        </div>
      </React.Fragment>
    )
  );
};

export default MyMemoryInfoModal;
