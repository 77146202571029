import React, { useEffect, useState } from "react";
import { FC } from "react";
import { useUserProfileContext } from "../../../../../context/allContexts/UserProfileContext";
// import { useUserContext } from "../../../../../context/allContexts";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../../../Components/LoadingCube/LoadingCube";
import styles from "../UPTopicsOrTags.module.scss";
import { AiOutlineBars } from "react-icons/ai";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import RecentTopicNewsArticles from "../../../RecentTopicNewsArticles/RecentTopicNewsArticles";
import {
  ShowModalType,
  UserProfileActionChoices,
} from "../../../userProfileTypes";
import {
  Topic,
  // useFetchTopFiveNewsPreviewQuery,
} from "../../../../../../codeGenFE";
// import { useFetchTopicsFromPython } from "../../../../../../rq-hooks/pythonRestApi/useFetchTopics";
// import { RelationType } from "../../../../../Components/QuickUploadBtn/QuickUpload/QuickUploadTypes";
// import { useQueryClient } from "react-query";
import ConfirmModal from "../../../ConfirmModal/ConfirmModal";

interface Props {}
const UPTopics: FC<Props> = () => {
  // const { user } = useUserContext();
  // const qClient = useQueryClient();

  const {
    //@ts-ignore
    userProfileState,
    //@ts-ignore
    userProfileDispatcher,
    //@ts-ignore
    UPIsFetching,
    //@ts-ignore
    delTopicMutation,
  } = useUserProfileContext();
  const {
    topicToMonitor,
    // excludedTopicsArray,
    // relationship,
    // countryArray,
    showModalType,
    topicToDelete,
    topics,
  } = userProfileState;
  const [topicPreviewToFetch, setTopicPreviewToFetch] = useState<Topic | null>(
    null
  );

  useEffect(() => {
    setTopicPreviewToFetch(null);
  }, [topicToMonitor]);

  // const codes =
  //   countryArray &&
  //   countryArray.length > 0 &&
  //   countryArray.map((c: any) => {
  //     return c.marketCode;
  //   });
  // let defaultCode: string[] | string = "nope";
  // if (!countryArray || countryArray.length === 0) {
  //   defaultCode = ["en-US"];
  // }

  return (
    <div className={styles.scrollAZContainer}>
      {UPIsFetching && (
        <div className={styles.loadingContainer}>
          <LoadingCube theme={LoadingCubeTheme.DARK} height={150} width={150} />
        </div>
      )}
      <RecentTopicNewsArticles
        topicData={topicPreviewToFetch}
        close={() => {
          // Remove preview data
          setTopicPreviewToFetch(null);
        }}
      />
      <div
        className={`${styles.scrollAZContainer__innerContainer} ${
          topics && topics.length > 3 && styles.flexInnerContainer
        }`}
      >
        {topics &&
          topics
            // @ts-ignore
            .sort((a, b) => {
              if (a.topic.toLowerCase() < b.topic.toLowerCase()) return -1;
              if (a.topic.toLowerCase() > b.topic.toLowerCase()) return 1;
              return 0;
            })
            .map((item: any, index: number) => {
              const currentTopic = item.topic;
              let prevTopic = null;
              if (index !== 0) {
                prevTopic = topics?.[index - 1]?.topic;
              }
              return (
                <React.Fragment key={item.id}>
                  <CapitalLetterLabel
                    currentItem={currentTopic}
                    prevItem={prevTopic}
                    index={index}
                  />
                  <div className={styles.topicWrapper}>
                    <li key={item.id}>{item.topic}</li>
                    <div
                      className={`${styles.iconContainer}
                      `}
                    >
                      <AiOutlineBars
                        className={styles.icon}
                        title="Show recent related articles"
                        onClick={() => {
                          setTopicPreviewToFetch(item);
                        }}
                      />
                      <FiEdit2
                        className={styles.icon}
                        onClick={() => {
                          // editQuestionById(item.id);
                          userProfileDispatcher({
                            type: UserProfileActionChoices.SET_TOPIC_TO_EDIT,
                            topic: item,
                          });
                          const target = document.querySelector(
                            "#editTopicScrollTarget"
                          );
                          if (target)
                            target.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                        }}
                        title="Edit topic"
                      />
                      <FiTrash2
                        className={styles.icon}
                        title="Delete topic"
                        onClick={() => {
                          userProfileDispatcher({
                            type: UserProfileActionChoices.SHOW_MODAL_TYPE,
                            payload: ShowModalType.DELETE,
                          });
                          userProfileDispatcher({
                            type: UserProfileActionChoices.SET_TOPIC_TO_DELETE,
                            topicId: item.id,
                          });
                        }}
                      />
                      {showModalType === ShowModalType.DELETE &&
                        topicToDelete === item.id && (
                          <ConfirmModal
                            type={ShowModalType.DELETE}
                            label="Delete This Topic?"
                            func={() => {
                              delTopicMutation({ topicId: topicToDelete });
                            }}
                            close={() => {
                              userProfileDispatcher({
                                type: UserProfileActionChoices.SHOW_MODAL_TYPE,
                                showModalType: ShowModalType.NONE,
                              });
                            }}
                          />
                        )}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
      </div>
    </div>
  );
};

export default UPTopics;

interface Test {
  currentItem?: any;
  prevItem?: any;
  index: number;
}
const CapitalLetterLabel: FC<Test> = ({ currentItem, prevItem, index }) => {
  // If checks for old data that hasn't been normalized yet.
  if (!currentItem?.[0]) {
    return null;
  }
  if (
    index === 0 ||
    !prevItem ||
    !prevItem[0] ||
    currentItem?.[0]?.toLowerCase() !== prevItem?.[0]?.toLowerCase()
  ) {
    return (
      <p className={styles.scrollAZ__p}>{currentItem?.[0]?.toUpperCase()}</p>
    );
  } else {
    return null;
  }
};
