import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Carousel from 'react-bootstrap/Carousel'
import step1 from '../../../assets/gifs/slide1.mp4'
import step2 from '../../../assets/gifs/slide2.mp4'
import step3 from '../../../assets/gifs/slide3.mp4'

export default function NavigationSteps (props) {
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          How it works?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='mx-auto'>
        <Carousel>
          <Carousel.Item>
            <video
              className='d-block w-75 mx-auto'
              src={step2}
              autoPlay
              loop
              alt='First slide'
            />
          </Carousel.Item>
          <Carousel.Item>
            <video
              className='d-block w-75 mx-auto'
              src={step3}
              autoPlay
              loop
              alt='First slide'
            />
          </Carousel.Item>
          <Carousel.Item>
            <video
              className='d-block w-75 mx-auto'
              src={step1}
              autoPlay
              loop
              alt='First slide'
            />
          </Carousel.Item>
        </Carousel>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={props.onHide} className="btn" style={{backgroundColor: '#3cc0f6', color : 'white', border: 'none'}}>Close</button>
      </Modal.Footer>
    </Modal>
  )
}
