import Fuse from "fuse.js";
import React, { Dispatch, FC, useEffect, useReducer } from "react";
import { Users, useUserListQuery } from "../../../codeGenFE";
import { customErrorHandler } from "../../../utils/customErrorHandler";
import { useViewPortContext } from "../../context/allContexts";
import ComponentMessagingOverlay from "../ComponentMessagingOverlay/ComponentMessagingOverlay";
import LoadingCube, { LoadingCubeTheme } from "../LoadingCube/LoadingCube";
import styles from "./ShareList.module.scss";

enum NameOrEmail {
  NAME = "name",
  EMAIL = "email",
}

enum ActionChoices {
  SET_USER_LIST_SEARCH_STR,
  SET_FILTERED_USER_LIST,
  TOGGLE_NAME_OR_EMAIL,
}

type State = {
  userListSearchStr: string;
  filteredUserList: [] | Users[];
  displayNameOrEmail: NameOrEmail;
};

type Actions =
  | {
      type: ActionChoices.SET_USER_LIST_SEARCH_STR;
      value: string;
    }
  | { type: ActionChoices.SET_FILTERED_USER_LIST; userList: [] | Users[] }
  | { type: ActionChoices.TOGGLE_NAME_OR_EMAIL };

const shareListReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case ActionChoices.SET_USER_LIST_SEARCH_STR:
      return {
        ...state,
        userListSearchStr: action.value,
      };
    case ActionChoices.SET_FILTERED_USER_LIST:
      return {
        ...state,
        filteredUserList: action.userList,
      };
    case ActionChoices.TOGGLE_NAME_OR_EMAIL:
      return {
        ...state,
        displayNameOrEmail:
          state.displayNameOrEmail === NameOrEmail.NAME
            ? NameOrEmail.EMAIL
            : NameOrEmail.NAME,
      };
    default:
      return state;
  }
};

const initState: State = {
  userListSearchStr: "",
  filteredUserList: [],
  displayNameOrEmail: NameOrEmail.NAME,
};

export enum ShareListTheme {
  DARK = "dark",
  LIGHT = "light",
}
type Theme = {
  desktop: ShareListTheme;
  mobile: ShareListTheme;
};

interface Props {
  shareAll: boolean;
  setShareAll: Dispatch<boolean>;
  selectedUsers: [] | string[];
  setSelectedUsers: Dispatch<[] | string[]>;
  // mobileLightTheme: boolean;
  theme: {
    desktop: ShareListTheme;
    mobile: ShareListTheme;
  };
  checkNoUsers?: () => void
}

const ShareList: FC<Props> = ({
  shareAll,
  setShareAll,
  selectedUsers,
  setSelectedUsers,
  // mobileLightTheme = false,
  theme = { desktop: ShareListTheme.DARK, mobile: ShareListTheme.DARK },
  checkNoUsers
}) => {
  const [shareListState, shareListDispatch] = useReducer(
    shareListReducer,
    initState
  );
  const { width }: any = useViewPortContext();
  const { userListSearchStr, filteredUserList, displayNameOrEmail } =
    shareListState;

  // ===================== New Stuff ===================== //

  const { data, status, error, refetch, isFetching } = useUserListQuery();
  const userList = data?.userList;

  // ===================== End New Stuff ===================== //
  useEffect(() => {
    console.log(userList, "userList")
    if (userList && userList !== null && userList.length) {
      if (userListSearchStr === "") {
        shareListDispatch({
          type: ActionChoices.SET_FILTERED_USER_LIST,
          // @ts-ignore
          userList: userList,
        });
      } else {
        // Fuzzy search here
        const options = {
          shouldSort: true,
          threshold: 0.2,
          location: 0,
          distance: 500,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: ["name", "lastName", "email"],
        };
        let fuse = new Fuse(userList!, options);
        // @ts-ignore
        let result: [] | Users[] = fuse
          .search(userListSearchStr)
          .map((i) => i.item);
        shareListDispatch({
          type: ActionChoices.SET_FILTERED_USER_LIST,
          userList: result,
        });
      }
    } else {
      if(userList !== undefined && checkNoUsers) 
        checkNoUsers()
    }
  }, [userListSearchStr, userList]);

  // @ts-ignore
  function selectUser(user) {
    // @ts-ignore
    if (selectedUsers.includes(user._id)) {
      const updatedList = selectedUsers.filter((id) => id !== user._id);
      setSelectedUsers(updatedList);
    } else {
      const newList = [...selectedUsers, user._id];
      setSelectedUsers(newList);
    }
  }

  function toggleShareAll() {
    if (shareAll) {
      setShareAll(false);
      setSelectedUsers([]);
    } else {
      setShareAll(true);
      let newUserList = userList!.map((info: any) => {
        return info._id;
      });
      setSelectedUsers(newUserList);
    }
  }

  // if (error) {
  //   return <h3>{userListError}</h3>;
  // }

  function whichTheme() {
    let styling = `${styles.shareList__div}`;
    if (theme.desktop && theme.desktop === "dark") {
      styling += ` ${styles.desktopDarkTheme}`;
    }

    if (theme.mobile && theme.mobile === "dark") {
      styling += ` ${styles.mobileDarkTheme}`;
    }
    return styling;
  }

  if (error) {
    return (
      <ComponentMessagingOverlay
        buttonText={"Retry?"}
        close={refetch}
        message={customErrorHandler(error)}
        showX={true}
      />
    );
  }

  if (!userList && status !== "loading") {
    return (
      <div className={styles.noUsersWrapper}>
        <p className={styles.noUsersMsg}>No Shareable Users Found</p>
      </div>
    );
  }
  return (
    <>
    {
      isFetching ? 
      <LoadingCube
          theme={LoadingCubeTheme.LIGHT}
          height={width < 1210 ? 100 : 30}
          width={width < 1210 ? 100 : 30}
          isFullScreen={width < 1210 ? true : false}
      />
      : 
      <div className={`${whichTheme()} ${!filteredUserList || !filteredUserList.length ? styles.noShareList : ""}`}>
        {
          !filteredUserList || !filteredUserList.length
          ?
            <p>No team member added for sharing</p>
          :
          <>
            <input
              type="text"
              placeholder="Search"
              value={userListSearchStr}
              onChange={(e) => {
                shareListDispatch({
                  type: ActionChoices.SET_USER_LIST_SEARCH_STR,
                  value: e.target.value,
                });
                setShareAll(false);
              }}
              className={styles.searchInputField}
            />

            {filteredUserList.length > 0 && (
              <>
                <label className={`${styles.sendAll} ${styles.checkboxLabel}`}>
                  <input
                    type="checkbox"
                    className={`${styles.customCheckbox}`}
                    checked={shareAll}
                    onChange={toggleShareAll}
                  />
                  Send to All
                  <span className={styles.customCheckbox} />
                  <button
                    className={styles.emailOrNameBtn}
                    onClick={() => {
                      shareListDispatch({ type: ActionChoices.TOGGLE_NAME_OR_EMAIL });
                    }}
                  >
                    show{" "}
                    {displayNameOrEmail === NameOrEmail.NAME
                      ? NameOrEmail.EMAIL
                      : NameOrEmail.NAME}
                  </button>
                </label>
              </>
            )}

            <div className={styles.names__div}>
              {status === "loading" && (
                <div className={styles.loading__container}>
                  <LoadingCube
                    hasBackground={false}
                    theme={LoadingCubeTheme.LIGHT}
                    height={100}
                    width={100}
                  />
                </div>
              )}
              {filteredUserList &&
                // @ts-ignore
                filteredUserList.map((userInfo: any) => {
                  return (
                    <label
                      key={userInfo._id}
                      className={`${styles.names__label} ${styles.checkboxLabel} ${
                        theme.mobile === "light" && styles.checkboxLabel__light
                      }`}
                    >
                      <input
                        name={userInfo._id}
                        className={`${styles.customCheckbox}`}
                        type="checkbox"
                        value={userInfo._id}
                        // @ts-ignore
                        checked={selectedUsers.includes(userInfo._id)}
                        onChange={() => {
                          selectUser(userInfo);
                          setShareAll(false);
                        }}
                      />
                      {displayNameOrEmail === NameOrEmail.NAME
                        ? `${userInfo.name} ${
                            userInfo.lastName ? userInfo.lastName : ""
                          }`
                        : `${userInfo.email}`}
                      <span className={styles.customCheckbox} />
                    </label>
                  );
                })}
            </div> 
          </>
        }
      </div>
    }
    </>
  );

  // return (
  //   <div className={whichTheme()}>
  //     <input
  //       type="text"
  //       placeholder="Search"
  //       value={userListSearchStr}
  //       onChange={(e) => {
  //         shareListDispatch({
  //           type: ActionChoices.SET_USER_LIST_SEARCH_STR,
  //           value: e.target.value,
  //         });
  //         setShareAll(false);
  //       }}
  //       className={styles.searchInputField}
  //     />
  //     {filteredUserList.length > 0 && (
  //       <>
  //         <label
  //           className={`${styles.sendAll} ${styles.checkboxLabel} ${
  //             theme.mobile === "light" && styles.checkboxLabel__light
  //           }`}
  //         >
  //           <input
  //             type="checkbox"
  //             className={`${styles.customCheckbox}`}
  //             value={shareAll}
  //             checked={shareAll}
  //             onChange={toggleShareAll}
  //           />
  //           Send to All
  //           <span className={styles.customCheckbox} />
  //         </label>
  //       </>
  //     )}
  //         {userList.map((userInfo: any) => {
  //       <div className={styles.names__div}>
  //         {userList && userList.map((userInfo: any) => {
  //           return (
  //             <label
  //               key={userInfo._id}
  //               className={`${styles.names__label} ${styles.checkboxLabel} ${
  //                 theme.mobile === "light" && styles.checkboxLabel__light
  //               }`}
  //             >
  //               <input
  //                 name={userInfo._id}
  //                 className={`${styles.customCheckbox}`}
  //                 type="checkbox"
  //                 value={userInfo._id}
  //                 // @ts-ignore
  //                 checked={selectedUsers.includes(userInfo._id)}
  //                 onChange={() => {
  //                   selectUser(userInfo);
  //                   setShareAll(false);
  //                 }}
  //               />
  //               {`${userInfo.name} ${
  //                 userInfo.lastName ? userInfo.lastName : ""
  //               }`}
  //               <span className={styles.customCheckbox} />
  //             </label>
  //           );
  //         })}
  //         {userList?.length === 0 && (
  //           <p className={`${styles.noUsers__p}`}>
  //             No users found for this search result
  //           </p>
  //         )}
  //       </div>
  //       </div>
  //   </div>
  // );
};

export default ShareList;
