import React, { FC } from "react";
import { ShowModalType } from "../userProfileTypes";

import styles from "./ConfirmModal.module.scss";

interface Props {
  func: () => void;
  close: () => void;
  type: ShowModalType;
  label: string;
}

const ConfirmModal: FC<Props> = ({ func, close, label, type }) => {
  if (type === ShowModalType.EDIT) {
    return (
      <div className={styles.iconModalWrapper__delete}>
        <h3>{label}</h3>
        <p className={styles.edit} onClick={() => func()}>
          EDIT
        </p>
        <p onClick={() => close()}>Cancel</p>
      </div>
    );
  }
  // else if (type === "share") {
  //   return (
  //     <div className={styles.iconModalWrapper__like}>
  //       <h3>{label}</h3>
  //     </div>
  //   );
  // } else
  else if (type === ShowModalType.DELETE) {
    return (
      <div className={styles.iconModalWrapper__delete}>
        <h3>{label}</h3>
        <p className={styles.delete} onClick={() => func()}>
          DELETE
        </p>
        <p onClick={() => close()}>Cancel</p>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ConfirmModal;
