import { FC } from "react";

// components
import WorldChloroplethChart from "../../../../Components/Charts/WorldChloroplethChart/WorldChloroplethChart";

// styles
import styles from "./AskMeWorldChloroplethChart.module.scss";
import { useArticlesGeographyQuery } from "../../../../../codeGenFE";
import { geoPlaceholderData } from "../../../Dashboard/DashInfoContainer/Geography/GeographyUtils";

interface Props {
  topic: string;
}

const AskMeWorldChloroplethChart: FC<Props> = ({ topic }) => {
  const { data, error, isFetching } = useArticlesGeographyQuery({
    inputOpts: { topic },
  });

  const countryData = data?.articlesGeography?.geography;
  const max = data?.articlesGeography?.max;

  return (
    <div className={styles.container}>
      <WorldChloroplethChart
        data={countryData}
        max={max || 1}
        mapColor="#eeeeee"
        mapBackgroundColor="#ffffff"
        dataColor="#003cbb" // pluaris blue
      />
    </div>
  );
};

export default AskMeWorldChloroplethChart;

// TODO: Add border radius of 12px to the map
