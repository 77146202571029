import React, { FC, useState } from "react";

import { AiOutlineCaretDown } from "react-icons/ai";
import { EnumType } from "typescript";
import styles from "./MultiLevelDropdown.module.scss";

type SubArray = string[];

interface Props {
  display: string;
  itemsArr: {
    display: string;
    dropdownData: string;
    subArray?: SubArray;
  }[];
  runDispatch: (arg: string, arg2?: string) => void;
  toggleDropdownVis: () => void;
  isOpen: boolean;
}

const MultiLevelDropdown: FC<Props> = ({
  display,
  itemsArr,
  runDispatch,
  toggleDropdownVis,
  isOpen,
}) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState<string | null>(null); // if one is clicked on it will be id'ed by string

  function toggleSubMenu(name: string) {
    if (isSubmenuOpen === name) {
      setIsSubmenuOpen(null);
    } else {
      setIsSubmenuOpen(name);
    }
  }

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <div
        className={styles.display__div}
        tabIndex={0}
        onKeyUp={(e) => {
          e.key === "Enter" && toggleDropdownVis();
        }}
        onClick={() => toggleDropdownVis()}>
        <p className={styles.display__text}>{display}</p>
        <AiOutlineCaretDown className={styles.display__arrow} />
      </div>
      {isOpen && (
        <div className={styles.dropdown__container}>
          {itemsArr.map((item, index) => {
            return (
              <div key={index}>
                <div
                  onClick={() =>
                    item?.subArray
                      ? toggleSubMenu(item.display)
                      : runDispatch(item.dropdownData)
                  }
                  className={styles.subDropdown__display}>
                  <button
                    onKeyUp={(e) => e.key === "Escape" && toggleDropdownVis()}
                    className={styles.dropdown__button}>
                    {item.display}
                  </button>
                  {item.subArray && (
                    <AiOutlineCaretDown className={styles.subdropdown__arrow} />
                  )}
                </div>
                {item?.subArray && isSubmenuOpen === item.display && (
                  <div className={styles.subDropdown__container}>
                    {item.subArray.map((subItem, idx) => {
                      return (
                        <button
                          key={idx}
                          className={styles.subDropdown__button}
                          onKeyUp={(e) =>
                            e.key === "Escape" && toggleDropdownVis()
                          }
                          onClick={() =>
                            runDispatch(item.dropdownData, subItem)
                          }>
                          {subItem}
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MultiLevelDropdown;
