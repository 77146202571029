import { useState } from "react";
import styles from "./acquisitions.module.scss";

const Acquisitions = ({ data }) => {
  const [size, setSize] = useState(5);
  function limit(string = "", limit = 240) {
    return string.substring(0, limit) + "...";
  }
  console.log("data", data.latestAquisition);

  return (
    <>
      <div className="my-5 ">
        <div className="text-start mx-3 px-2">
          <h2 className="text-dark fw-bold fs-3 my-5">
            Latest Acquisitions/ Products / services Launched
          </h2>
        </div>
        <div className="mx-3 px-4 py-1 border border-2 rounded rounded-2">
          <ul className={styles.list_styling}>
            {data.latestAquisition.slice(0, size).map((item, i) => (
              <li
                onClick={() => {
                  window.open(`${item.url}`);
                }}
                className="my-4 cursor-pointer"
              >
                <h3 className="my-2 fw-bold fs-5 text-dark">
                  {item.contents.title}
                </h3>
                <p className="my-2 text-secondary fs-6 lh-base">
                  {limit(item.contents.desc)}
                </p>
                <div className={styles.date + " text-primary  text-start my-3"}>
                  {item.date}
                </div>
                {i != data.latestAquisition.slice(0, size).length - 1 && <hr />}
              </li>
            ))}
            {data.latestAquisition.length > size && (
              <div className="text-end fs-6 my-2 text-primary cursor-pointer">
                <button onClick={() => setSize(data.latestAquisition.length)}>
                  Load more
                </button>
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Acquisitions;
