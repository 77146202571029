import React from "react";

import styles from "./AnswerTitle.module.scss";

const AnswerTitle = ({ webOrMemory, children }) => {
  switch (webOrMemory) {
    case "memory":
      return (
        <div className={styles.memory}>
          <h3
            className={`${styles.answer__title} ${styles.answer__title__memoryColor}`}
          >
            Suggested <span className={styles.blueWord}>reading</span> from your
            <span className={styles.blueWord}> Memory</span>
          </h3>
          {children}
        </div>
      );
    case "web":
      return (
        <div className={styles.web}>
          <h3
            className={`${styles.answer__title} ${styles.answer__title__webColor}`}
          >
            Suggested <span className={styles.blueWord}>reading</span> from the
            <span className={styles.blueWord}> WEB</span>
          </h3>
          {children}
        </div>
      );
    case "memAnswer":
      return (
        <div className={styles.web}>
          <h3
            className={`${styles.answer__title} ${styles.answer__title__memoryColor}`}
          >
            Suggested <span className={styles.blueWord}>answer</span> from your
            <span className={styles.blueWord}> Memory</span>
          </h3>
          {children}
        </div>
      );
    case "webAnswer":
      return (
        <div className={styles.web}>
          <h3
            className={`${styles.answer__title} ${styles.answer__title__webColor}`}
          >
            Suggested <span className={styles.blueWord}>answer</span> from the
            <span className={styles.blueWord}> Web</span>
          </h3>
          {children}
        </div>
      );
    case "trending":
      return (
        <div className={styles.memory}>
          <h3
            className={`${styles.answer__title} ${styles.answer__title__memoryColor}`}
          >
            Recommended <span className={styles.blueWord}>questions</span> to
            ask from your
            <span className={styles.blueWord}> memory</span>
          </h3>
          {children}
        </div>
      );
    default:
      return <></>;
  }
};

export default AnswerTitle;
