export function removeParamsOauth(location, navigate) {
  const queryParams = new URLSearchParams(location.search);

  if (queryParams.has('oauth_token')) {
    queryParams.delete('oauth_token');
    queryParams.delete('oauth_verifier');
    navigate.replace({
      search: queryParams.toString(),
    });
  }
}
