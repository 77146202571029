import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useStateLabel } from "../../../hooks/stateLabeler";
import styles from "./TopicNewsPreview.module.scss";

import SingleNewsPreview from "./SingleNewsPreview/SingleNewsPreview";
import LoadingCube from "../../../Components/LoadingCube/LoadingCube";

import {
  useUserProfileContext,
  useUserContext,
  useNewsContext,
} from "../../../context/allContexts";

import CloseModalBtn from "../../../Components/CloseModalBtn/CloseModalBtn";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

import axios from "axios";

const TopicNewsPreview = ({ close, showPreviewModal, isAskMeTopicModal }) => {
  const { fetchPreviewData, topicToMonitor, setTopicToMonitor } =
    useUserProfileContext();
  const { userState } = useUserContext();
  const { addMultipleNewsArticles } = useNewsContext();
  const [topicPreviews, setTopicPreviews] = useStateLabel([], "topicPreviews");
  const [selectedArticles, setSelectedArticles] = useStateLabel(
    [],
    "selectedArticles"
  );
  // TODO: convert to useReducer
  const [loading, setLoading] = useStateLabel(false, "loading");
  const [success, setSuccess] = useStateLabel(false, "success");
  const [error, setError] = useStateLabel(null, "error");
  // array of selected ID's

  const saveArticlesToDB = async () => {
    setLoading(true);
    try {
      const data = {
        userId: userState._id,
        comp: userState.company,
        sequenceIds: selectedArticles,
      };
      const seqIds = await axios.post(
        "https://fire.nowigence.ai/persist_topics_check",
        data
      );
      setLoading(false);
      if (seqIds.data.length > 0) {
        let addedArticles = await axios.get(
          "/api/newsData/fetchMultipleUploaded",
          { params: { articleIds: seqIds.data } }
        );
        addMultipleNewsArticles(addedArticles.data.articles);
      }
      setSelectedArticles([]);
      setSuccess(true);
      // close();
    } catch (err) {
      setError("Could not save Article(s), sorry");
    }
  };

  useEffect(() => {
    if (topicToMonitor.trim() === "") {
      setError("**Please enter a topic");
      setTopicToMonitor("");
      return;
    } else {
      const giveMeTopicResults = async () => {
        try {
          const results = await fetchPreviewData(
            userState._id,
            userState.company
          );
          if (showPreviewModal && results.data.length > 0) {
            setTopicPreviews(results.data);
          } else {
            setLoading(false);
            setError("No results found");
          }
        } catch (err) {
          setError("Something went wrong...");
        }
      };
      if (userState) {
        giveMeTopicResults();
      }
    }
  }, [
    userState,
    fetchPreviewData,
    setError,
    setTopicPreviews,
    showPreviewModal,
  ]);

  if (success) {
    return (
      <div
        className={
          !isAskMeTopicModal
            ? `${styles.topicNewsPreviewWrapper} ${styles.topicNewsPreview__message}`
            : `${styles.askMeModalTopicNewsPreviewWrapper} ${styles.topicNewsPreview__message}`
        }>
        <div className={styles.message__div}>
          <div className={styles.message__heading}>
            <AiOutlineCheck className={`${styles.iconCheck}`} />
            <h3 className={styles.message__h3}>Success!</h3>
          </div>
          <p className={styles.message__p}>
            The article(s) are now viewable in your My Memory page!
          </p>
          <Link
            to="/my-memory/reader"
            className={styles.topicNewsPreview__link}>
            Go to MyMemory
          </Link>
        </div>
        <button className={styles.message__button} onClick={close}>
          OKAY
        </button>
      </div>
    );
  } else if (error) {
    return (
      <div
        className={
          !isAskMeTopicModal
            ? `${styles.topicNewsPreviewWrapper} ${styles.topicNewsPreview__message}`
            : `${styles.askMeModalTopicNewsPreviewWrapper} ${styles.topicNewsPreview__message}`
        }>
        <div className={styles.message__div}>
          <div className={`${styles.message__heading} ${styles.err__heading}`}>
            <AiOutlineClose className={`${styles.iconX}`} />
            <h3 className={styles.message__h3}>{error}</h3>
          </div>
        </div>
        <button className={styles.message__button} onClick={close}>
          OKAY
        </button>
      </div>
    );
  } else {
    return (
      <div
        className={
          !isAskMeTopicModal
            ? styles.topicNewsPreviewWrapper
            : styles.askMeModalTopicNewsPreviewWrapper
        }>
        <div className={styles.scrollPadding}>
          <div className={styles.closeBtn}>
            <CloseModalBtn theme="light" close={close} />
          </div>
          <h2 className={styles.topicNewsPreview__heading}>News Preview</h2>
          <div className={styles.topicNewsItemsWrapper}>
            {topicPreviews.length > 0 && !loading ? (
              topicPreviews.map((art) => {
                return (
                  <SingleNewsPreview
                    selectedArticles={selectedArticles}
                    setSelectedArticles={setSelectedArticles}
                    isAskMeTopicModal={isAskMeTopicModal}
                    article={art}
                    key={art._id}
                    id={art._id}
                  />
                );
              })
            ) : (
              <div className={styles.loadingCubeWrapper}>
                <LoadingCube
                  hasBackground={false}
                  height="138px"
                  width="138px"
                />
              </div>
            )}
            {topicPreviews.length > 0 && !loading && (
              <button className={styles.addBtn} onClick={saveArticlesToDB}>
                Add to MyMemory
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default TopicNewsPreview;
