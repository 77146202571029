import { Fragment, useEffect, useState, useLayoutEffect } from "react";
import styles from "./landingPage.module.scss";

import linkedinlight from "../../../assets/img/atoms/linkedin.svg";
import line from "../../../assets/img/line.png";
import { useHistory } from "react-router-dom";

import { LINKEDIN_CLIENT_ID, LOCAL_STORAGE_KEYS } from "../../../constants";
import twitter from "../../../assets/img/atoms/twitter.svg";
import { useTwitterProcess } from "../../../rq-hooks/useTwitterProcess";

const SummaryMobile = ({ allData, keywords }) => {
  const [size, setSize] = useState(2);
  const [size1, setSize1] = useState(2);
  const [callBack, setCallBack] = useState(null);
  const navigate = useHistory();

  useLayoutEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    setCallBack(`${location.origin}${location.pathname.slice(0, -1)}`);
  }, []);

  console.log(allData);

  const handleLinkedInLogin = () => {
    // localStorage.setItem("reloaded", "true");
    // localStorage.setItem("allData", allData);
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${callBack}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
  };

  const { mutate } = useTwitterProcess({
    onSuccess: (data) => {
      const responseArray = data.split("&");
      window.location.href = `https://api.twitter.com/oauth/authorize?${responseArray[0]}`;
    },
  });
  const handleTwitterLogin = () => {
    console.log("In twitter provider");
    // localStorage.setItem("reloaded", "true");
    // localStorage.setItem("allData", allData);
    const callback = callBack;
    mutate({ callback });
  };

  if (allData) {
    function downloadFunction() {
      localStorage.setItem("summaryKeywords", keywords)
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.THEME) === "dark_theme") {
        localStorage.setItem("downloadPdf", true);
        navigate.push("/signupdark", {
          allData: allData,
          keywords: keywords,
        });
      } else {
        localStorage.setItem("downloadPdf", true);
        navigate.push("/signuplight", {
          allData: allData,
          keywords: keywords,
        });
      }
    }

    return (
      <Fragment>
        <div className={styles.summary + " "}>
          <div className="my-3 p-3">
            <div className=" px-4 text-start mt-3">
              <h2 className="fw-bold fs-3 my-2">Here’s your Summary for</h2>
              <h4 className="mt-3 fs-5">{allData?.title}</h4>
            </div>

            <div className="my-5">
              <div className="  bg-white mx-auto">
                <div
                  className={
                    styles.summaryContent +
                    " p-3 mx-4 my-3 border border-gray rounded rounded-2"
                  }
                >
                  <h4 className="my-1 fw-bold">Summary</h4>
                  {/* {allData?.summary.map((item, index) => (
                  <p className="my-2">{item}</p>
                ))} */}
                  {allData?.summary.slice(0, size).map((item, i) => {
                    return (
                      <div>
                        {i == size - 1 && (
                          <li
                            style={{ overflowWrap: "break-word" }}
                            className={" my-2  py-3  text-wrap"}
                          >
                            {item}
                          </li>
                        )}
                        {i !== size - 1 && (
                          <li
                            style={{ overflowWrap: "break-word" }}
                            className={
                              styles.borderBottom + " my-2  py-3  text-wrap"
                            }
                          >
                            {item}
                          </li>
                        )}
                      </div>
                    );
                  })}
                  {allData?.summary.length > size && (
                    <button
                      onClick={() => setSize(allData?.summary.length)}
                      className={
                        " text-left rounded rounded-2 py-2 mb-5  text-primary fs-6 cursor-pointer"
                      }
                    >
                      Read More
                    </button>
                  )}
                </div>
                {allData.driver[0] ==
                  "This article does not contain a driver." && <div></div>}
                {allData.driver[0] !==
                  "This article does not contain a driver." && (
                    <div
                      className={
                        styles.summaryContent +
                        " p-3 mx-4 mb-3 mt-4 border border-gray rounded rounded-2"
                      }
                    >
                      <h4 className="my-2 fw-bold">Drivers</h4>
                      {/* <p>{allData?.driver}</p> */}
                      {allData?.driver.slice(0, size1).map((item, i) => {
                        return (
                          <div>
                            {i == size1 - 1 && (
                              <li
                                style={{ overflowWrap: "break-word" }}
                                className={" my-2  py-3  text-wrap"}
                              >
                                {item}
                              </li>
                            )}
                            {i !== size1 - 1 && (
                              <li
                                style={{ overflowWrap: "break-word" }}
                                className={
                                  styles.borderBottom + " my-2  py-3  text-wrap"
                                }
                              >
                                {item}
                              </li>
                            )}
                          </div>
                        );
                      })}
                      {allData?.driver.length > size1 && (
                        <button
                          onClick={() => setSize1(allData?.driver.length)}
                          className={
                            " text-left rounded rounded-2 py-2 mb-5  text-primary fs-6 cursor-pointer"
                          }
                        >
                          Read More
                        </button>
                      )}
                    </div>
                  )}
              </div>
            </div>
            <div className={" px-0 my-5  d-flex justify-content-center"}>
              <div className={"w-100 mx-4 text-center"}>
                <div>
                  {/* <a> */}
                  <button
                    onClick={() => {
                      downloadFunction();
                    }}
                    className={
                      styles.downloadButton +
                      "  w-100 btn bg-primary  rounded rounded-2 py-3 mb-5 px-5 text-white fs-6 "
                    }
                  >
                    Download
                  </button>
                  {/* </a> */}
                </div>
                <div className=" mb-3 d-flex justify-content-center align-items-center">
                  <div className="">
                    <img src={line} />
                  </div>
                  <div className="mx-2"> or share on </div>
                  <div className="">
                    <img src={line} />
                  </div>
                </div>
                <div className="w-75 mx-auto d-flex align-items-center justify-content-evenly">
                  {/* <img src={image} /> */}
                  {/* <a href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=868bordkbw6hm6&redirect_uri=localhost:3000&scope=r_liteprofile%20r_emailaddress%20w_member_social">
                  {" "}
                  <img src={facebook} width="50px" />
                </a>
                <img src={google} width="70px" height="70px" />
                <img src={twitter} width="50px" /> */}
                  <img
                    className="pe-auto"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLinkedInLogin()}
                    src={linkedinlight}
                    width="50px"
                    height="50px"
                  />
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => handleTwitterLogin()}
                    className="pe-auto"
                    src={twitter}
                    width="50px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
};
export default SummaryMobile;
