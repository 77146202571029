import { Dispatch } from "react";
import { QuickUploadActions } from "../QuickUploadTypes";

export interface Props {
  quickUploadDispatcher: Dispatch<QuickUploadActions>;
}

export enum ActionChoices {
  SET_TAGS,
  TOGGLE_IS_PRIVATE,
  TOGGLE_SHARE_ALL,
  SET_SELECTED_USERS,
  RESET_SHARE,
  CLOSE,
  SET_INPUT_ERROR,
  CLEAR_INPUT_ERRORS,
  CLOSE_WARNING,
  SET_FILES,
  REMOVE_FILE,
}

export enum InputErrors {
  FILE_REQUIRED = "* File must be selected",
  INVALID_URL = "* Please enter a valid URL",
  NO_USERS_TO_SHARE = "Please add users to share with or press cancel",
  // FAILED_TO_SHARE = "Couldn't Share Article at this time.",
}

export type Actions =
  | { type: ActionChoices.SET_TAGS; tags: [] | string[] }
  | { type: ActionChoices.TOGGLE_IS_PRIVATE; boolean: boolean }
  | { type: ActionChoices.TOGGLE_SHARE_ALL; boolean: boolean }
  | { type: ActionChoices.SET_SELECTED_USERS; selectedUsers: [] | string[] }
  | { type: ActionChoices.RESET_SHARE }
  | { type: ActionChoices.SET_INPUT_ERROR; error: InputErrors | string | null }
  | { type: ActionChoices.CLEAR_INPUT_ERRORS }
  | { type: ActionChoices.CLOSE_WARNING; boolean: boolean }
  | { type: ActionChoices.SET_FILES; fileList: [] | any[] }
  | { type: ActionChoices.REMOVE_FILE; index: number };

export type State = {
  fileList: [] | any[];
  tags: [] | string[];
  isPrivate: boolean;
  shareAll: boolean;
  selectedUsers: [] | string[];
  inputError: InputErrors | string | null;
  closeWarning: boolean;
};

export interface DataForNode {
  shareAll: boolean;
  uploadIds: string[];
  tags?: string[];
  recipients?: string[];
  userFullName?: string;
}
