import axios from "axios";
import { createContext, useContext, useEffect, useReducer } from "react";
import toast from "react-hot-toast";
import { REST_BASE_ENDPOINT, API_ENDPOINTS } from "../../../constants";
import { useUserContext } from "./UserContext";

export const SocialLoginContext = createContext();

const socialLoginReducer = (state, action) => {
  switch (action.type) {
    case "set twitter creds":
      return {
        twitterToken: action.payload.token,
        twitterVerifier: action.payload.verifier,
        twitterAccessToken: action.payload.accessToken,
        twitterAccessTokenSecret: action.payload.accessTokenSecret,
      };
    case "set linkedin creds":
      return {
        ...state,
        linkedInAccessToken: action.payload.token,
        linkedInUserData: action.payload.userData,
      };
    case "set linkedin accesstoken":
      return {
        ...state,
        linkedInAccessToken: action.payload.token,
      };
    case "logout twitter":
      return {
        ...state,
        twitterToken: null,
        twitterVerifier: null,
        twitterAccessToken: null,
        twitterAccessTokenSecret: null,
      };
    case "logout linkedin":
      // console.log("linkedIn logout")
      return {
        ...state,
        linkedInAccessToken: null,
        linkedInUserData: null,
      };
    default:
      return state;
  }
};
const SocialTypes = {
  LINKEDIN: "linkedin",
  TWITTER: "Twiiter",
};

const initState = {
  twitterToken: localStorage.getItem("twitter_oauth_token") || null,
  twitterVerifier: localStorage.getItem("twitter_oauth_verifier") || null,
  twitterAccessToken: localStorage.getItem("twitterAccessToken") || null,
  twitterAccessTokenSecret:
    localStorage.getItem("twitterAccessTokenSecret") || null,
  linkedInAccessToken: localStorage.getItem("linkedInAccessToken") || null,
  linkedInUserData: null,
};

export function SocialLoginProvider(props) {
  const [socialLoginState, socialLoginDispatch] = useReducer(
    socialLoginReducer,
    initState
  );
  const {
    linkedInAccessToken,
    twitterVerifier,
    twitterToken,
    setAccessToken,
    setTwitterToken,
    setTwitterVerifier,
  } = useUserContext();

  const logoutLinkedin = (url) => {
    // Invisible window popup
    const win = window.open(
      url,
      "_blank",
      "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none"
    );

    setTimeout(() => {
      win.close();
    }, 4000);
  };

  const logoutIntegration = async (type) => {
    if (type === SocialTypes.TWITTER) {
      localStorage.removeItem("twitter_oauth_verifier");
      localStorage.removeItem("twitterAccessToken");
      localStorage.removeItem("twitterAccessTokenSecret");
      localStorage.removeItem("twitter_oauth_token");
      setTwitterToken(null);
      setTwitterVerifier(null);
      socialLoginDispatch({
        type: "logout twitter",
      });
    } else {
      localStorage.removeItem("linkedInAccessToken");
      localStorage.removeItem("linkedinDetails");
      localStorage.removeItem("integrated");
      socialLoginDispatch({
        type: "logout linkedin",
      });
      setAccessToken(null);
      await logoutLinkedin("https://linkedin.com/m/logout");
    }
    toast.success(`Logged out from ${type}`);
  };

  useEffect(() => {
    const getTwitterAccess = async (token, verifier) => {
      try {
        const { data } = await axios.post(
          `${REST_BASE_ENDPOINT}${API_ENDPOINTS.TW_ACCESS_TOKEN}`,
          {
            token,
            verifier,
          }
        );
        const accessData = data.data.split("&");
        const accessToken = accessData[0];
        const accessTokenSecret = accessData[1];
        localStorage.setItem("twitterAccessToken", accessToken);
        localStorage.setItem("twitterAccessTokenSecret", accessTokenSecret);
        toast.success(
          "Congratulations! You have successfully integrated with Twitter."
        );
        socialLoginDispatch({
          type: "set twitter creds",
          payload: {
            accessToken,
            accessTokenSecret,
            token,
            verifier,
          },
        });
        return accessToken;
      } catch (err) {
        // throw new Error(err);
      }
    };
    if (
      twitterVerifier &&
      twitterToken &&
      !localStorage.getItem("twitterAccessTokenSecret") &&
      !localStorage.getItem("twitterAccessToken")
    ) {
      getTwitterAccess(twitterToken, twitterVerifier);
    }
  }, [
    twitterVerifier,
    twitterToken,
    socialLoginState.twitterVerifier,
    socialLoginState.twitterToken,
  ]);

  useEffect(() => {
    const getLinkedinMe = async () => {
      let userData = null;
      let token = null;
      if (linkedInAccessToken) {
        token = linkedInAccessToken;
      }
      if (token && !socialLoginState.linkedInUserData) {
        try {
          const { data } = await axios.post(
            `${REST_BASE_ENDPOINT}${API_ENDPOINTS.LI_PROFILE}`,
            {
              accessToken: token,
            }
          );
          userData = data.data;
          // console.log(data,userData, "social")
          socialLoginDispatch({
            type: "set linkedin creds",
            payload: {
              userData,
              token,
            },
          });
        } catch (err) {
          toast.error(err.message);
          socialLoginDispatch({
            type: "logout linkedin",
          });
          localStorage.removeItem("linkedInAccessToken");
        }
      }
    };

    if (linkedInAccessToken || socialLoginState.linkedInAccessToken) {
      // console.log(socialLoginState.linkedInAccessToken, linkedInAccessToken)
      getLinkedinMe();
    }
  }, [linkedInAccessToken, socialLoginState.linkedInAccessToken]);
  // console.log(socialLoginState, "linkedInUserData")
  return (
    <SocialLoginContext.Provider
      value={{
        socialLoginState,
        socialLoginDispatch,
        logoutIntegration,
      }}
    >
      {props.children}
    </SocialLoginContext.Provider>
  );
}

export const useSocialLoginContext = () => {
  return useContext(SocialLoginContext);
};
