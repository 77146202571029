import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { AiOutlineCopy } from 'react-icons/ai';
import { useSocialLoginContext, useUserContext } from '../../../../context/allContexts';
import CloseModalBtn from '../../../CloseModalBtn/CloseModalBtn';
// import { Type, useRewardsDetails, useUserLinkedInShareMutation, useUserRewardLogsMutation, useUserRewardsMutation } from '../../../../../../codeGenFE';

import styles from './LogoutModal.module.scss'
import { useRewardsContext } from '../../../../context/allContexts/RewardsContext';

export enum CloseModalBtnTheme {
  LIGHT,
  DARK,
}
const LogoutModal = ({close, showPopup, type}: any) => {
  const [shouldRender, setShouldRender] = useState<any>(showPopup);
  const {rewardsState} = useRewardsContext()
  const{logoutIntegration} = useSocialLoginContext()

  // @ts-ignore
  // const { width } = useViewPortContext();

  useEffect(() => {
    if (showPopup) setShouldRender(true);
  }, [showPopup]);

  const onAnimationEnd = () => {
    if (!showPopup) setShouldRender(false);
  };

  return (
    shouldRender && (
        <React.Fragment>
            <div
            onAnimationEnd={onAnimationEnd}
            className={`${styles.backdrop} ${
                showPopup ? styles.backdrop : styles.fadeOut
            }`}
            onClick={() => close()}
            />
            <div
            className={`${styles.askMeInfoModalWrapper} ${
                showPopup ? styles.askMeInfoModalWrapper : styles.slideUp
            } ${type === 'Twiiter' ? styles.twitter : ""}`}
            onAnimationEnd={onAnimationEnd}
            >
                <div className={styles.closeBtn}>
                    <CloseModalBtn theme={CloseModalBtnTheme.LIGHT} close={close} />
                </div>
                <div className={styles.header}>
                    <h2>App already integrated</h2>
                </div>
                <div className={styles.tokenDetails}>
                    <div className={styles.details}>
                        <span>Do you want to deactivate?</span>
                        <span className={styles.subDetail}>Once deactivated you won't be able to post content?</span>
                    </div>
                    <div className={styles.buttonContainer}>
                        <button className={styles.button} onClick={() => logoutIntegration(type)}>Yes</button>
                        <button className={styles.button} onClick={() => close()}>No</button>
                    </div>
                </div>
            </div>
      </React.Fragment>
    )
  );
}

export default LogoutModal