import { createContext, useContext, useReducer, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { Type, useMeQuery, useRewardsDetails } from "../../../codeGenFE";
import toast from "react-hot-toast";
import { useTokenProcess } from "../../../rq-hooks/useTokenProcess";
import { useUserContext } from "./UserContext";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import { customErrorHandler } from "../../../utils/customErrorHandler";

export const RewardsContext = createContext();

const notebookReducer = (state, action) => {
    switch (action.type) {
      case "update points":
        return {...action.payloads};
      default:
        return state;
    }
  };
  
  const initState = {
    totalPoints: 0,
    totalToken: 0,
    nearestPointForToken: 0,
    token: 0,
  };



export function RewardsProvider(props) {
  // HOOKS
  const history = useHistory();
  const qClient = useQueryClient();
  // STATE
  const [totalPoints, setTotalPoints] = useState(null)
  const [totalToken, setTotalToken] = useState(null)
  const [nearestPointForToken, setNearestPoint] = useState(null)
  const [token, setToken] = useState(null)
  const [rewardDetails, setRewardDetails] = useState(null)
  const locationData = useLocation()
  const {user} = useUserContext()  
  const [rewardsState, rewardsDispatch] = useReducer(
    notebookReducer,
    initState
  );

  useEffect(() => {
    if(user) {
        rewardsDispatch({
            type: "update points",
            payloads: {
                totalPoints: user.totalPoints,
                nearestPointForToken: user.nearestPoint,
                totalToken: user.totalToken,
                token:user.token,
                pointsGotByNearest:user.pointsGotByNearest,
            }
        })
        refetch()
    }
  }, [user])

  // After successfully processing token system notify user about the same and save the 
  // response that we get from blockchain api like number of tokenProcess, 
  // userId in tokenProcessLogs collection.
  const {mutate} = useTokenProcess({
    onSuccess: (data) => {
      // console.log(data, "process")
      toast.success(`Congratulations! ${data.tokens_transferred} token has been processed`, {duration: 10000})
    },
    onError: (error) => {
      console.log(error)
      toast.success(`${customErrorHandler(error)}`)
    }
  })

  // By the function system getting updated reward details for the user and check 
  // that whether the user has awarded any token. If yes then use hook 
  // useTokenProcess() that will take userId and token amount and will use 
  // blockchain api to process the token. 
  const {refetch} = useRewardsDetails({}, {
    onSuccess: (data) => {
      const rewardsData = data?.userRewardsDetails
      if(rewardsData) {
        rewardsDispatch({ type: "update points", payloads: {
            totalPoints: rewardsData.totalPoints,
            nearestPointForToken: rewardsData.nearestPoint,
            totalToken: rewardsData.totalToken,
            token:rewardsData.token,
        } })
        console.log(user.totalPoints < rewardsData.totalPoints, "check")
        console.log(user.totalPoints , rewardsData.totalPoints, "point")
        if(user.totalPoints < rewardsData.totalPoints) toast("Congratulation! we have added reward points")
        if(rewardsData.token > 0) {
          // console.log({
          //   userId: user.id,
          //   tokens: rewardsData.token
          // })
          // mutate({
          //   userId: user._id,
          //   tokens: rewardsData.token
          // })
        }
      }
    }
  })

  // console.log(rewardsState)
  return (
    <RewardsContext.Provider
      value={{
        rewardDetails,
        refetch,
        rewardsState,
        rewardsDispatch
      }}
    >
      {props.children}
    </RewardsContext.Provider>
  );
}

export const useRewardsContext = () => {
  return useContext(RewardsContext);
};
