import { ArticleSource, SavedFilter } from "../../../../codeGenFE";

export enum SearchContextActions {
  CHANGE_SEARCH_STRING = "change search string",
  CLEAR_SEARCH = "clear search",
  SET_DISABLE_SEARCH = "set disable search",
  SET_COMPONENT_FETCH_STATUS = "set component fetch status",
  SET_RELATIONSHIP_SEARCH_PARAMETER = "set relationship search parameter",
  SET_TAG_SEARCH_PARAMETER = "set tag search parameter",
  CHANGE_DATA_SOURCE = "change data source",
  SET_TOPIC = "set topic",
  RESET_FILTERS = "reset filters",
  SET_CATEGORY_CHOICE = "set category choice",
  SET_DRIVER_CHOICE = "set driver choice",
  SET_OUTCOME_CHOICE = "set outcome choice",
  SET_COUNTRY_CHOICE = "set country choice",
  LOAD_SAVED_FILTER_SET = "load saved filter set",
  SET_OTHER_LOCATION = "set other location",
  SET_ORG = "set org",
  SET_PERSON = "set person",
  SET_PRODUCT = "set product",
  LOGOUT = "LOGOUT",
}
type RelSearchParams = {
  category: string;
  relationship: string;
};
export type SearchString = string | null;
export type TagFilterChoice = string | null;
export type SearchTopicChoice = string | null;
export type SearchCategoryChoice = string | null;
export type SearchDriverChoice = string | null;
export type SearchOutcomeChoice = string | null;
export type SearchCountryChoice = string | null;

export type SearchContextState = {
  searchString: SearchString;
  relSearchParams: RelSearchParams | null;
  tagFilterChoice: TagFilterChoice;
  dataSourceChoice: ArticleSource | null;
  searchTopicChoice: SearchTopicChoice;
  categoryChoice: SearchCategoryChoice;
  driverChoice: SearchDriverChoice;
  outcomeChoice: SearchOutcomeChoice;
  countryChoice: SearchCountryChoice;
  currentActiveFilter: string | null;
  otherLocation: string | null;
  org: string | null;
  person: string | null;
  product: string | null;
};
export type SearchContextAction =
  | { type: SearchContextActions.CHANGE_SEARCH_STRING; payload: string }
  | { type: SearchContextActions.CLEAR_SEARCH }
  | {
      type: SearchContextActions.SET_RELATIONSHIP_SEARCH_PARAMETER;
      payload: RelSearchParams | null;
    }
  | {
      type: SearchContextActions.SET_TAG_SEARCH_PARAMETER;
      payload: TagFilterChoice;
    }
  | {
      type: SearchContextActions.CHANGE_DATA_SOURCE;
      payload: ArticleSource | null;
    }
  | {
      type: SearchContextActions.SET_TOPIC;
      payload: SearchTopicChoice;
    }
  | { type: SearchContextActions.RESET_FILTERS }
  | {
      type: SearchContextActions.SET_CATEGORY_CHOICE;
      category: SearchCategoryChoice;
    }
  | {
      type: SearchContextActions.SET_DRIVER_CHOICE;
      driver: SearchDriverChoice;
    }
  | {
      type: SearchContextActions.SET_OUTCOME_CHOICE;
      outcome: SearchOutcomeChoice;
    }
  | {
      type: SearchContextActions.SET_COUNTRY_CHOICE;
      country: SearchCountryChoice;
    }
  | {
      type: SearchContextActions.SET_OTHER_LOCATION;
      location: string | null;
    }
  | {
      type: SearchContextActions.SET_ORG;
      org: string | null;
    }
  | {
      type: SearchContextActions.SET_PERSON;
      person: string | null;
    }
  | {
      type: SearchContextActions.SET_PRODUCT;
      product: string | null;
    }
  | { type: SearchContextActions.LOAD_SAVED_FILTER_SET; filters: SavedFilter }
  | { type: SearchContextActions.LOGOUT };
