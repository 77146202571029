import { Fragment } from "react";
import { Link } from "react-router-dom";
import styles from "./landingPage.module.scss";
import twitterBlack from "../../../assets/img/twitter_bla.jpeg";
import facebookBlack from "../../../assets/img/facebook_bla.jpeg";
import instagramBlack from "../../../assets/img/instagram_bl.jpeg";
import githubBlack from "../../../assets/img/github_bl.png";
const Footer = () => {
  return (
    <div className="my-5 py-sm-5 w-75 mx-auto">
      <div className="d-sm-flex mb-5 justify-content-between align-items-center">
        <div className="fs-2 fw-bold lh-base">
          Pluaris is your undue <br /> advantage 🚀
        </div>
        <div>
          <button className="btn btn-dark text-white px-4 my-sm-0 my-4 fs-6 py-2 rounded rounded-pill">
            Read more
          </button>
        </div>
      </div>
      <hr />
      <div className="d-sm-flex justify-content-between align-items-start my-5">
        <div className={styles.aboutContent}>
          <h5 className="mb-2 fw-bold">About Pluaris</h5>
          <p className="my-4 lh-base">
            Pluaris - The Intelligent Reading System for Instant Info. Read
            Smarter, Faster and More Efficiently with our KM Tool and with
            amazing AI & ML tools helping people across the globe
          </p>
          <div className="d-flex justify-content-start my-4">
            <img className="mx-3" width="30px" src={twitterBlack} />
            <img className="mx-3" width="25px" src={facebookBlack} />
            <img className="mx-3" width="30px" src={instagramBlack} />
            <img className="mx-3" width="30px" src={githubBlack} />
          </div>
        </div>
        <div className="my-4 my-sm-0 ">
          <ul className="p-0 my-sm-0 my-4">
            <a
              onClick={() => {
                window.open("https://www.nowigence.com");
              }}
              className="text-dark text-decoration-none cursor-pointer"
            >
              <li className="fw-bold fs-6 my-2">Company</li>
            </a>
            <a
              onClick={() => {
                window.open("https://www.nowigence.com/about-nowigence/");
              }}
              className="text-dark text-decoration-none cursor-pointer"
            >
              <li className=" fs-6 my-3">About</li>
            </a>
            <a
              onClick={() => {
                window.open("https://www.nowigence.com/data-science/");
              }}
              className="text-dark text-decoration-none cursor-pointer"
            >
              <li className=" fs-6 my-3">Features </li>
            </a>
            <a
              onClick={() => {
                window.open("https://www.nowigence.com/customer-stories/");
              }}
              className="text-dark text-decoration-none cursor-pointer"
            >
              <li className=" fs-6 my-3">Works</li>
            </a>
            <a
              onClick={() => {
                window.open("https://www.nowigence.com/careers/");
              }}
              className="text-dark text-decoration-none cursor-pointer"
            >
              <li className=" fs-6 my-3">Career </li>
            </a>
          </ul>
        </div>
        <div className="my-4 my-sm-0 ">
          <ul className="p-0 my-sm-0 my-4">
            <a className="text-dark text-decoration-none cursor-pointer">
              <li className="fw-bold fs-6 my-3">Help</li>
            </a>
            <a
              onClick={() => {
                window.open("https://www.nowigence.com/discover-nowigence/");
              }}
              className="text-dark text-decoration-none cursor-pointer"
            >
              <li className=" fs-6 my-3">Customer Support</li>
            </a>
            <a
              onClick={() => {
                window.open("https://www.nowigence.com/terms-of-use/");
              }}
              className="text-dark text-decoration-none cursor-pointer"
            >
              <li className=" fs-6 my-3">Terms & Conditions</li>
            </a>
            <a
              onClick={() => {
                window.open("https://www.nowigence.com/privacy-policy/");
              }}
              className="text-dark text-decoration-none cursor-pointer"
            >
              <li className=" fs-6 my-3">Privacy Policy</li>
            </a>
          </ul>
        </div>
        <div className="my-4 my-sm-0 ">
          <ul className="p-0 my-sm-0 my-4">
            <a className="text-dark text-decoration-none cursor-pointer">
              <li className="fw-bold fs-6 my-3">Resources</li>
            </a>
            <a
              onClick={() => {
                window.open("https://www.nowigence.com/faq/");
              }}
              className="text-dark text-decoration-none cursor-pointer"
            >
              <li className=" fs-6 my-3">FAQ</li>
            </a>
            <a
              onClick={() => {
                window.open("https://www.nowigence.com/help/");
              }}
              className="text-dark text-decoration-none cursor-pointer"
            >
              <li className=" fs-6 my-3">Knowledge Base</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
