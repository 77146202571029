import { useMemo, useState } from "react"
import styles from './PowerOfOne.module.scss';

const TableRow = ({ rowData, increment = false }) => {
    const [improvement, setImprovement] = useState(1.0)

    const value = useMemo(() => (improvement * rowData.value).toFixed(2), [improvement])

    return <tr>
        <td className="align-middle">{rowData.label}</td>
        <td className="align-middle">
            <div class={"form-group d-flex align-items-center justify-content-center " + styles.numberInputContainer}>
                {increment ? '+' : '-'} <input
                    type="number"
                    class="form-control text-center"
                    id="improvement"
                    value={improvement}
                    onChange={(e) => { setImprovement(e.target.value) }}
                /> %
            </div>
        </td>
        <td className="align-middle text-right">{value}</td>
    </tr>
}

export default TableRow;
