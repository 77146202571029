import { REST_BASE_ENDPOINT } from "../constants";
import { useMutation } from "react-query";
import { axios } from "../utils/axiosConfig";

interface ChangePWInput {
  email: string;
  password: string;
  newPassword: string;
}

export function useChangePassword(opts?: any) {
  return useMutation(async (changePWInput: ChangePWInput) => {
    try {
      const { data } = await axios.post(
        `${REST_BASE_ENDPOINT}/auth/change-password`,
        changePWInput
      );
      // console.log("data from useChangePW hook", data);
      if (data) {
        return data;
      }
    } catch (err) {
      console.log("err from useChangePW hook", err);
      throw new Error(err.response.data.message);
    }
  }, opts);
}
