import { FC, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { ArticleCardQuery, Maybe, Scalars } from "../../../../../codeGenFE";

import styles from "./ACReadMoreModal.module.scss";
import AddToNotebookBtn from "../../../../Components/AddToNotebookBtn/AddToNotebookBtn";

// import QuickUploadBtn from "../../../../Components/QuickUploadBtn/QuickUploadBtn";
// import { useFilesTrackingContext } from "../../FilesTrackingContext";
import { useNavContext } from "../../../../context/allContexts";
import { useMyMemoryContext } from "../../MyMemoryContext";
import DisplayHarvestDate from "../DisplayHarvestDate/DisplayHarvestDate";
import { MdSearch } from "react-icons/md";

interface Props {
  toggleModal: () => void;
}

const ACReadMoreModal: FC<Props> = ({ toggleModal }) => {
  // const { readMoreModalContent, readMoresummaryList } = useFilesTrackingContext();
  // @ts-ignore
  const { myMemState } = useMyMemoryContext();
  const [driverPoints, setDriverPoints] =
    useState<Maybe<Array<Maybe<Scalars["String"]>>>>();
  const [summaryPoints, setSummaryPoints] =
    useState<Maybe<Array<Maybe<Scalars["String"]>>>>();
  const [searchStr, setSearchStr] = useState<string | null>(null);
  const { selectedArticleID, selectedArticleDB, showSumAndDriverOverlay } =
    myMemState;
  const { whichMenu } = useNavContext();
  const qClient = useQueryClient();
  const cacheData: ArticleCardQuery | undefined = qClient.getQueryData([
    "articleCard",
    {
      articleId: selectedArticleID,
      whichDB: selectedArticleDB,
    },
  ]);
  // console.log("cacheData", cacheData);
  if (!cacheData || !cacheData.article) {
    // TODO: do something else here
    return null;
  }
  const { _source, userMetaData } = cacheData?.article;
  const { orig_url, title, source, harvestDate, uploadDate, summary, driver } =
    _source;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const getUpdatedList = () => {
      let driverArray: any = driver,
        summaryArray: any = summary;
      if (driver?.length || summary?.length) {
        if (searchStr && searchStr.length > 0) {
          driverArray =
            driver &&
            driver.length > 0 &&
            driver[0] !== "This article does not contain a driver."
              ? driver?.filter((driver) =>
                  driver?.toLowerCase().includes(searchStr.toLowerCase())
                )
              : driver;
          summaryArray = summary?.filter((summaryPoint) =>
            summaryPoint?.toLowerCase().includes(searchStr.toLowerCase())
          );
        }
        setDriverPoints(driverArray || []);
        setSummaryPoints(summaryArray || []);
      }
    };
    getUpdatedList();
  }, [driver, summary, searchStr]);

  return (
    // <div
    //   className={`${styles.myMemoryModalReadMore} ${
    //     whichMenu === "notebook" ? styles.customWidth : styles.fullWidth
    //   }`}
    // >
    <div className={`${styles.myMemoryModalReadMore} ${styles.fullWidth}`}>
      <div className={styles.myMemoryModalReadMoreContainer}>
        <div className={styles.quickuploadLayoutSection}>
          <div className={styles.quickuploadLayout_div}>
            {/*<div className={styles.quickuploadLayout_emptyDiv}></div>
            <div>
              <QuickUploadBtn disableTips={true} />
            </div>*/}
          </div>
        </div>
        {showSumAndDriverOverlay && (
          <div className={styles.myMemoryModalReadMoreSection}>
            <div className={styles.myMemoryModalReadMoreHeader}>
              <div className="d-flex  flex-column">
                <div className={styles.summaryDriverFilter}>
                  <input
                    type="text"
                    name="search"
                    placeholder="Keyword filter from summary or driver"
                    className={styles.summaryDriverFilterInput}
                    value={searchStr ? searchStr : ""}
                    onChange={(e) => setSearchStr(e.target.value)}
                  />
                  <div title="Search" className={styles.searchIcon_container}>
                    <MdSearch className={styles.icon} />
                  </div>
                </div>
                <div>
                  {!orig_url || orig_url === "No url for this file" ? (
                    <h4
                      style={{ cursor: "default" }}
                      className={styles.myMemoryModalReadMoreTitle}
                    >
                      {title}
                    </h4>
                  ) : (
                    <a
                      href={orig_url}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                      className={styles.myMemoryModalReadMoreTitle}
                    >
                      {title ? (
                        title === "" ? (
                          <p>
                            Event is not available. Please click here to go the
                            external source.
                          </p>
                        ) : (
                          title
                        )
                      ) : (
                        "N/A"
                      )}
                    </a>
                  )}
                </div>
              </div>

              <div className={styles.publicationNameDateSection}>
                <span>{source?.name}</span>
                <DisplayHarvestDate
                  harvestDate={harvestDate}
                  uploadDate={uploadDate}
                  userMetaData={userMetaData}
                />
              </div>
            </div>

            {/* LEFT SIDE */}

            <div className={styles.sectionOne}>
              <div className={styles.contentHeader}>
                <span className={styles.keyPointLength}>
                  {summaryPoints?.length}
                </span>
                SUMMARY
              </div>
              <div className={styles.contentbox}>
                {summaryPoints?.map((content: any, summaryIndex: number) => {
                  return (
                    <div
                      key={summaryIndex}
                      className={styles.content__container}
                    >
                      <div
                        className={styles.myMemoryModalReadMore__addContainer}
                      >
                        <p className={styles.myMemoryPoints}>
                          {summaryPoints?.length > 0 ? summaryIndex + 1 : ""}.
                        </p>
                        <AddToNotebookBtn
                          content={content}
                          style={"unordered-list-item"}
                        />
                      </div>
                      <div className={styles.myMemoryModalReadMoreContent}>
                        {content}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* RIGHT SIDE */}
            <div className={styles.sectionTwo}>
              <div className={styles.contentHeader}>
                <span className={styles.keyPointLength}>
                  {driverPoints?.[0] ===
                  "This article does not contain a driver."
                    ? 0
                    : driverPoints?.length}
                </span>
                DRIVER{driverPoints && driverPoints?.length <= 1 ? "" : "S"}{" "}
              </div>
              <div className={styles.contentbox}>
                {driverPoints?.map((d: any, driverIndex: number) => {
                  return (
                    <div
                      key={driverIndex}
                      className={styles.content__container}
                    >
                      <div
                        className={styles.myMemoryModalReadMore__addContainer}
                      >
                        <p className={styles.myMemoryPoints}>
                          {d.length > 0 &&
                          d !== "This article does not contain a driver."
                            ? driverIndex + 1 + "."
                            : null}
                        </p>
                        {d !== "This article does not contain a driver." ? (
                          <AddToNotebookBtn
                            content={d}
                            style={"unordered-list-item"}
                          />
                        ) : null}
                      </div>
                      <div className={styles.myMemoryModalReadMoreContent}>
                        {d}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={styles.closeBtn} onClick={toggleModal}>
              Exit
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ACReadMoreModal;
