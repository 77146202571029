import React from 'react'
import TextLoop from 'react-text-loop'

export default function LoaderLoop() {
  return (
    <div>
      <TextLoop delay={1000}  mask={true} fade={false} >
          <span style={{padding: '4px'}}>Pluaris</span>
          <span style={{padding: '4px'}}>We</span>
          <span style={{padding: '4px'}}>It</span> 
      </TextLoop>
      <TextLoop delay={1000} mask={true} fade={false}>
          <span style={{padding: '4px'}}>is</span>
          <span style={{padding: '4px'}}>are</span>
          <span style={{padding: '4px'}}>might</span>
      </TextLoop>
      <TextLoop delay={1000} mask={true} fade={false}>
          <span style={{padding: '4px'}}>generating summary</span>
          <span style={{padding: '4px'}}>working on it</span>
          <span style={{padding: '4px'}}>take long generating</span>
      </TextLoop>
    </div>
  )
}
