import React from 'react';
import styles from './landingPage.module.scss';

export function StatsFunction() {
  return <div
    class={styles.footer +
      ' my-5 d-sm-flex justify-content-center pt-2 text-dark  '}
  >
    <div class=" d-flex justify-content-center align-items-center mx-2 ">
      <div
        class="fs-2 mx-1 fw-bolder"
        style={{ fontFamily: 'Montserrat' }}
      >
        2943
      </div>
      <div class="mx-2 fw-bold text-start lh-1 ">
        Article
        <br /> summarized
      </div>
    </div>
    <div
      class={styles.rotated + ' fw-bold d-flex align-items-center'}
    >
      \\\\\
    </div>
    <div class=" d-flex justify-content-center mx-2">
      <div
        class="fs-2 mx-1 fw-bolder lh-1"
        style={{ fontFamily: 'Montserrat' }}
      >
        1M +
      </div>
      <div class="mx-2  fw-bold lh-1  text-start">
        Scripts
        <br /> Every Day
      </div>
    </div>
  </div>;
}
