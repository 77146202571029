import React, { Dispatch, FC, useEffect, useReducer } from "react";
import {
  AiFillCheckCircle,
  AiFillLinkedin,
  AiFillTwitterSquare,
  AiOutlineCloudDownload,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useQueryClient } from "react-query";
// REACT-QUERY
import { Maybe, Note, useUpdateNoteMutation } from "../../../../../codeGenFE";
// COMPONENTS
import BackArrow from "../../../../../Icons/ArrowNotebook30/ArrowNotebook30";
// UTILS
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
// CONTEXT
import {
  useNotebookContext,
  useNavContext,
  useUserContext,
  useSocialLoginContext,
} from "../../../../context/allContexts";
import CloseModalBtn, {
  CloseModalBtnTheme,
} from "../../../CloseModalBtn/CloseModalBtn";
import { SingleNoteActionChoices, SingleNoteActions } from "../SingleNote";
// STYLES
import styles from "./SingleNoteTitle.module.scss";
enum ActionChoices {
  ENTER_EDIT_TITLE_MODE,
  EDIT_TITLE,
  COMPLETE_EDIT,
}
type State = {
  isEditingTitle: boolean | null;
  newTitle: string;
  showPDF: boolean;
};
type Actions =
  | { type: ActionChoices.ENTER_EDIT_TITLE_MODE }
  | { type: ActionChoices.EDIT_TITLE; value: string }
  | { type: ActionChoices.COMPLETE_EDIT }; // SNTReducer = SingleNoteTitleReducer
const SNTReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionChoices.ENTER_EDIT_TITLE_MODE:
      return {
        ...state,
        isEditingTitle: true,
      };
    case ActionChoices.EDIT_TITLE:
      return {
        ...state,
        newTitle: action.value,
      };
    case ActionChoices.COMPLETE_EDIT:
      return {
        ...state,
        isEditingTitle: false,
        newTitle: "",
      };
    default:
      return state;
  }
};
const initState: State = {
  isEditingTitle: null,
  newTitle: "",
  showPDF: false,
};
interface Props {
  note: Maybe<Note>;
  setSaving: Dispatch<SingleNoteActions>;
}
const SingleNoteTitle: FC<Props> = ({ note, setSaving }) => {
  const title = note?._source?.title;
  const { disableShare } = useUserContext();
  const { notebookState, notebookDispatch } = useNotebookContext();
  const { navDispatch } = useNavContext();
  const { fullscreen, currentActiveNoteId, editorOverlay } = notebookState;
  const {socialLoginState} = useSocialLoginContext()
  const {
    linkedInUserData, twitterToken, twitterVerifier} = socialLoginState
  const qClient = useQueryClient();
  const { mutate, error, isLoading } = useUpdateNoteMutation({
    onSuccess: (data) => {
      SNTDispatch({ type: ActionChoices.COMPLETE_EDIT });
      const updatedNote = data?.updateNote;
      if (updatedNote) {
        qClient.setQueryData(
          ["getSingleNote", { noteId: currentActiveNoteId }],
          { getSingleNote: { ...updatedNote } }
        );
        setSaving({
          type: SingleNoteActionChoices.SET_NOTE_ERROR_MSG,
          msg: null,
          retry: () => {},
        });
      }
    },
    onError: (err: any) => {
      console.log(`Error logs from notebook error ${error}`)
      setSaving({
        type: SingleNoteActionChoices.SET_NOTE_ERROR_MSG,
        msg: customErrorHandler(err),
        retry: () => {
          // console.log("note :>> ", note);
          // console.log("currentActiveNoteId :>> ", currentActiveNoteId);
          if (note) {
            mutate({
              updates: {
                noteId: currentActiveNoteId,
                richText: note.richText,
                title: newTitle,
              },
            });
          }
        },
      });
    },
  }); // Change saving message next to dummy save button

  useEffect(() => {
    if (isLoading) {
      setSaving({ type: SingleNoteActionChoices.SET_SAVING, payload: true });
    } else {
      setSaving({ type: SingleNoteActionChoices.SET_SAVING, payload: false });
    }
  }, [isLoading]); // local reducer state

  const [SNTState, SNTDispatch] = useReducer(SNTReducer, initState);
  const { newTitle, isEditingTitle } = SNTState;
  const submitTitleChange = (e: any) => {
    if (e.keyCode === 13) {
      // KeyCode 13 === "enter"
      if (newTitle.trim() !== "" && note) {
        // submit title change to backend
        mutate({
          updates: {
            noteId: currentActiveNoteId,
            richText: note.richText,
            title: newTitle,
          },
        });
      }
    } else if (e.key === "Escape") {
      SNTDispatch({ type: ActionChoices.COMPLETE_EDIT });
    }
  };

  const resetPlaceholder = () => {
    if(title === "Note Name Here...") {
      SNTDispatch({
        type: ActionChoices.EDIT_TITLE,
        value: "",
      });
    }
  }
  const handleTitleEdit = (e: any) => {
    SNTDispatch({
      type: ActionChoices.EDIT_TITLE,
      value: e.target.value,
    });
  };
  useEffect(() => {
    if (isEditingTitle) {
      if (title) {
        // Typeguard to make typescript happy
        SNTDispatch({ type: ActionChoices.EDIT_TITLE, value: title });
      }
      const input: HTMLInputElement | null = document.querySelector(
        `.${styles.editTitleInput}`
      );
      if (input) input.focus();
    }
  }, [isEditingTitle]);
  return (
    <div className={styles.SNTWrapper}>
      <BackArrow
        className={styles.arrowIcon}
        onClick={() => notebookDispatch({ type: "toggle showSelectANote" })}
      />
      <div className={styles.notebook__closeBtn}>
        <CloseModalBtn
          theme={CloseModalBtnTheme.DARK}
          close={() => {
            notebookDispatch({ type: "close notebook" })
            navDispatch({ type: "change which menu", menu: "" })
          }}
        />
      </div>
      <div className={styles.titleNIconsContainer}>
        {title && !isEditingTitle && (
          // {title && (
          <h3
            id={"notebookPdfTargetTitle"}
            className={styles.noteTitle}
            title={title}
            onClick={() =>
              SNTDispatch({ type: ActionChoices.ENTER_EDIT_TITLE_MODE })
            }>
            {title}
          </h3>
        )}
        {isEditingTitle && (
          <div className={styles.EditTitleInputDiv}>
            <input
              type="text"
              placeholder={title && title !== "Note Name Here..." ? title : ""}
              value={newTitle}
              className={styles.editTitleInput}
              onChange={(e) => handleTitleEdit(e)}
              onKeyUp={(e) => submitTitleChange(e)}
              onFocusCapture={(e) => resetPlaceholder()}
              onBlur={(e: any) => {
                e.keyCode = 13;
                submitTitleChange(e);
              }}
            />
          </div>
        )}
        <div className={styles.iconContainer}>
          {fullscreen ? (
            <AiOutlineMenuUnfold
              title="Toggle Fullscreen"
              onClick={() => {
                notebookDispatch({
                  type: "toggle",
                  key: "fullscreen",
                  value: !fullscreen,
                });
              }}
              className={styles.icon}
            />
          ) : (
            <AiOutlineMenuFold
              title="Toggle Fullscreen"
              onClick={() => {
                notebookDispatch({
                  type: "toggle",
                  key: "fullscreen",
                  value: !fullscreen,
                });
              }}
              className={styles.icon}
            />
          )}
          {/* {!isEditingTitle && ( */}
          {/* <FiEdit2
            title="Edit Title"
            className={styles.icon}
            // onClick={() => SNTDispatch({ type: "enter edit title mode" })}
          /> */}
          {/* )} */}
          {/* {isEditingTitle && (
            <AiOutlineCheck
              title="Confirm Title Change"
              className={styles.icon}
              onClick={(e: any) => submitTitleChange(e)}
            />
          )} */}
          {!disableShare && (
            <AiOutlineShareAlt
              className={`${styles.icon} ${editorOverlay === 'share' ? styles.active : ""}`}
              title="Share Note"
              onClick={() =>
                notebookDispatch({
                  type: "set editor overlay",
                  name: "share",
                })
              }
            />
          )}
          <AiOutlineCloudDownload
            title="Download PDF"
            className={`${styles.icon} ${editorOverlay === 'PDF' ? styles.active : ""}`}
            onClick={() =>
              notebookDispatch({
                type: "set editor overlay",
                name: "PDF",
              })
            }
          />
          <FiTrash2
            title="Delete Note"
            className={`${styles.icon} ${editorOverlay === 'delete' ? styles.active : ""}`}
            onClick={() =>
              notebookDispatch({
                type: "set editor overlay",
                name: "delete",
              })
            }
          />
          <div className={styles.socialLogin}>
            <AiFillLinkedin 
              title="Share note on linkedin"
              className={`${styles.icon} ${editorOverlay === 'linkedin' ? styles.active : ""}`}
              onClick={() =>
                notebookDispatch({
                  type: "set editor overlay",
                  name: "linkedin",
                })
              }
            />
            {linkedInUserData ? <AiFillCheckCircle className={styles.checkMark} /> : "" }
          </div>
          <div className={styles.socialLogin}>
            <AiFillTwitterSquare 
              title="Share note on twitter"
              className={`${styles.icon} ${editorOverlay === 'twitter' ? styles.active : ""}`}
              onClick={() =>
                notebookDispatch({
                  type: "set editor overlay",
                  name: "twitter",
                })
              }
            />
            {twitterToken && twitterVerifier ? <AiFillCheckCircle className={styles.checkMark} /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleNoteTitle;
