import { useEffect, FC, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SaveFiltersProvider } from "./Filters/SaveFiltersContext";
import QuickUpload from "../QuickUploadBtn/QuickUpload/QuickUpload";

import { AiOutlineSetting, AiOutlineLogout, AiFillInfoCircle } from "react-icons/ai";
import { BiBrain, BiPin } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { HiOutlineBookOpen } from "react-icons/hi";

import {
  useUserContext,
  useNavContext,
  useViewPortContext,
  useNotebookContext,
  useSearchContext,
  useRewardsContext,
} from "../../context/allContexts";
import { SearchContextActions } from "../../context/allContexts/SearchContext/SearchContextTypes";
import styles from "./MainNavigation.module.scss";
import MobileControls from "../MobileControls/MobileControls";
import Checkmark from "../../../Icons/Checkmark";
import { BsUpload } from "react-icons/bs";
import Filters from "./Filters/Filters";
import { FaTrophy } from "react-icons/fa";
import { REWARD_LOGIN_COUNT } from '../../../constants';
import { ordinal_suffix_of } from "../../util/common";
import InfoButton from "../../../Icons/InfoButton";
import RewardsInfoModal from "./RewardsInfoModal";

const MainNavigation: FC = () => {
  const { logout, user, nearestPointForToken,
    totalToken,
    totalPoints,
    token } = useUserContext();
  const {rewardsState} = useRewardsContext()  
  const {
    toggleNav,
    whichMenu,
    filterAnimationState,
    navDispatch,
    isFiltersDisabled,
  } = useNavContext();
  // @ts-ignore
  const { width } = useViewPortContext();
  const { notebookDispatch } = useNotebookContext();
  const { calcFiltersApplied } = useSearchContext();
  const { searchDispatcher } = useSearchContext();
  const [showInfo, setShowInfo] = useState(false)
  const location = useLocation();
  
  useEffect(() => {
    const animationTiming = 300;
    if (toggleNav === `closing`) {
      setTimeout(() => {
        navDispatch({ type: "closed" });
      }, animationTiming);
    } else if (toggleNav === `opening`) {
      setTimeout(() => {
        navDispatch({ type: "open" });
      }, animationTiming);
    }
  }, [toggleNav]);

  const myMemItems = [
    {
      // icon: <BiBrain className={styles.icon} />,
      text: "Reader",
      path: "/my-memory/reader",
      disabled: false,
    },
    {
      // icon: <AiOutlineSearch className={styles.icon} />,
      text: "Ask Me",
      path: "/my-memory/askme",
      disabled: false,
    },
    {
      // icon: <AiOutlineHome className={styles.icon} />,
      text: "Control Panel",
      path: "/my-memory/control-panel",
      disabled: false,
    },
    // {
    //   // icon: <AiOutlineBarChart className={styles.icon} />,
    //   text: "Benchmark",
    //   path: "/my-memory/benchmark",
    //   disabled: true,
    // },
    {
      // icon: <AiOutlineFileText className={styles.icon} />,
      text: "Report Builder",
      path: "/inputs/report-builder",
      disabled: true,
    },
    // {
    //   icon: <AiOutlineGlobal className={styles.icon} />,
    //   text: "Topic Monitoring",
    //   path: "/inputs/topic-monitoring",
    //   disabled: false,
    // },
  ];

  const inputsItems = [
    {
      text: "Quick Upload",
      open: () => {
        whichMenu === "quickUpload"
          ? navDispatch({ type: "change which menu", menu: "" })
          : navDispatch({ type: "change which menu", menu: "quickUpload" });
      },
      // path: "/inputs/topic-monitoring",
      disabled: false,
    },
    {
      text: "Topic Monitoring",
      // open: () => {},
      path: "/inputs/topic-monitoring",
      disabled: false,
    },
    // {
    //   text: "Integrations",
    //   open: () => {},
    //   // path: "/inputs/topic-monitoring",
    //   disabled: true,
    // },
  ];

  const checkNameLength = (name: string) => {
    if (name.length >= 17) {
      return (
        <span className={`${styles.headingText} ${toggleNav !== "closed" ? styles.personal : ""}`}>
          {user.name}
          <Checkmark
            height="20px"
            width="20px"
            style={{ marginLeft: "8px" }}
            // className={styles.checkmark}
          />
        </span>
      );
    } else {
      return (
        <span className={`${styles.headingText} ${toggleNav !== "closed" ? styles.personal : ""}`}>
          {user.name}
          {user.lastName ? ` ${user.lastName}` : ""}
          <Checkmark
            height="20px"
            width="20px"
            style={{ marginLeft: "8px" }}
          />
        </span>
      );
    }
  };
  return (
    <div className={styles.outerWrapper}>
      <nav
        className={styles.container}
        data-isnavopen={toggleNav}
        onClick={() => {
          toggleNav === "closed" && navDispatch({ type: "opening" });
        }}
        onMouseEnter={() => {
          toggleNav !== "opening" &&
            toggleNav !== "closing" &&
            toggleNav !== "open" &&
            toggleNav !== "pinned open" &&
            navDispatch({ type: "opening" });
        }}
        onMouseLeave={() => {
          toggleNav !== "closing" &&
            toggleNav !== "closed" &&
            toggleNav !== "pinned open" &&
            toggleNav !== "keep open for filters" &&
            navDispatch({ type: "closing" });
        }}
      >
        <div className={styles.row}>
          {toggleNav === "pinned open" ? (
            <BiPin
              className={styles.icon}
              fill="#2196f3"
              onClick={() =>
                toggleNav === "pinned open" && navDispatch({ type: "open" })
              }
            />
          ) : (
            <BiPin
              className={`${styles.icon} ${
                width <= 1210 ? styles.invisibleIcon : ""
              }`}
              // fill="none"
              onClick={() =>
                width > 1210 &&
                toggleNav === "open" &&
                navDispatch({ type: "pinned open" })
              }
            />
          )}
          {checkNameLength(
            `${user.name} ${user.lastName ? user.lastName : ""}`
          )}
        </div>
        {/* < hr /> */}
        {/* <div className={`${styles.row} ${styles.subRow} ${styles.shrinkWithMenu}`}>
          <FaTrophy className={styles.icon} />
          <div className={styles.rewardTextWrapper}>
            <div className={styles.rewardsHeader}>
              <p className={styles.totalPoints}><span className={styles.totalPointsNumber}>{rewardsState.totalPoints}</span>Reward Points</p>
              <AiFillInfoCircle
                className={styles.infoBtn}
                style={{cursor: "pointer"}}
                onClick={() => setShowInfo(true)}
              />
            </div>
            {
              user?.loginLogsCount ? 
              <p className={styles.nextToken}>Reward points start after {ordinal_suffix_of(REWARD_LOGIN_COUNT)} login</p>:
              <p className={styles.nextToken}>{rewardsState.nearestPointForToken - rewardsState.totalPoints === 0 ? rewardsState.pointsGotByNearest : rewardsState.nearestPointForToken - rewardsState.totalPoints} points away from the next token</p>
            }
          </div>
        </div> */}
        <button
          className={`${isFiltersDisabled ? styles.disabled : ""} ${
            styles.row
          } ${styles.mobileRemove} ${styles.filterBtn}`}
          disabled={
            isFiltersDisabled ||
            !!(toggleNav === "opening" || toggleNav === "closing")
          }
          onClick={() => {
            filterAnimationState === "open" &&
              navDispatch({ type: "toggle filters", filterState: "closing" });
            filterAnimationState === "closed" &&
              navDispatch({ type: "open filters" });
          }}
        >
          <span
            data-filter-badge-state={toggleNav}
            className={styles.filterBadge}
          >
            {calcFiltersApplied()}
          </span>
          <FiFilter className={`${styles.icon} ${styles.svg}`} />
          <span className={styles.headingText}>
            {isFiltersDisabled ? "Disabled" : "Filters"}
          </span>
        </button>
        {/* My Memory section */}
        <button className={`${styles.row} ${styles.noCursor}`}>
          <BiBrain
            className={styles.icon}
            data-isactive={
              location?.pathname.includes("my-memory") ? "active" : "false"
            }
          />
          <span className={styles.headingText}>My Memory</span>
        </button>
        {myMemItems.map((i, idx) => {
          return (
            <NavBtnOrLink
              key={idx}
              disabled={i.disabled}
              text={i.text}
              path={i?.path}
            />
          );
        })}
        {/* =========== inputs section ===========*/}
        <button
          className={`${styles.row} ${styles.headingText} ${styles.noCursor}`}
        >
          <BsUpload
            className={styles.icon}
            data-isactive={
              location?.pathname.includes("inputs") ? "active" : "false"
            }
          />
          <span className={styles.headingText}>Inputs</span>
        </button>
        {inputsItems.map((i, idx) => {
          return (
            <NavBtnOrLink
              key={idx}
              text={i.text}
              onClickDispatch={i?.open}
              path={i?.path}
              disabled={i.disabled}
            />
          );
        })}
        {/* =========== Notebook =========== */}
        <button
          className={`${styles.row} ${styles.mobileRemove}`}
          disabled={!!(toggleNav === "opening" || toggleNav === "closing")}
          onClick={(e) => {
            e.stopPropagation();
            whichMenu === "notebook"
              ? navDispatch({ type: "change which menu", menu: "" })
              : navDispatch({ type: "change which menu", menu: "notebook" });
          }}
        >
          <HiOutlineBookOpen className={styles.icon} />
          <span className={styles.headingText}>Notebook</span>
        </button>

        {/* =========== My Account =========== */}
        <NavLink
          to={"/account"}
          exact
          activeStyle={{ color: "#2196f3" }}
          // onClick={(e) => e.preventDefault()} // when reactivating this, add navDispatch({ type: "closing" })
          className={styles.row}
        >
          <AiOutlineSetting
            data-isactive={
              location?.pathname.includes("account") ? "active" : "false"
            }
            className={`${styles.icon}`}
          />
          <span
            // className={`${styles.text} ${styles.disabledIcon}`}
            className={`${styles.headingText}`}
            title={"My Account"}
          >
            My Account
          </span>
        </NavLink>

        {/* =========== logout =========== */}
        <button
          className={styles.row}
          onClick={() => {
            notebookDispatch({ type: "logout" });
            searchDispatcher({ type: SearchContextActions.LOGOUT });
            navDispatch({ type: "logout" });
            logout();
          }}
        >
          <AiOutlineLogout className={styles.icon} />
          <span className={styles.headingText}>Sign Out</span>
        </button>
        {/* set mobile controls here and then they'll magically be on every page! and we only have to manage nav open here */}
      </nav>
      <div
        className={styles.filters}
        data-open-animation={filterAnimationState}
      >
        <SaveFiltersProvider>
          <Filters />
        </SaveFiltersProvider>
      </div>
      <QuickUpload />
      <MobileControls />
      <RewardsInfoModal 
        rewardsInfoModal={showInfo}
        close={() => setShowInfo(false)}
      />
    </div>
  );
};

export default MainNavigation;

interface NavBtnOrLinkProps {
  path?: string | undefined;
  text: string;
  disabled: boolean;
  onClickDispatch?: undefined | (() => void); // for buttons
}

const NavBtnOrLink: FC<NavBtnOrLinkProps> = ({
  path,
  text,
  disabled = false,
  onClickDispatch = () => {},
}) => {
  const { toggleNav, navDispatch } = useNavContext();

  // Links
  if (path) {
    return (
      <div
        className={`${styles.row} ${styles.subRow} ${styles.shrinkWithMenu} ${
          text === "Quick Upload" ? styles.mobileRemove : ""
        }`}
      >
        {/* added invisible icons to make them squish during animations the same as the others.  Made sure it's an icon already brought in for performance */}
        <BiBrain
          className={`${styles.icon} ${styles.invisibleIcon}`}
          data-isactive={
            path && path.includes("my-memory") ? "active" : "false"
          }
        />
        <NavLink
          to={path}
          exact
          activeStyle={{ color: "#2196f3", fontWeight: "bold" }}
          onClick={(e) =>
            disabled || toggleNav === "opening" || toggleNav === "closing"
              ? e.preventDefault()
              : toggleNav !== "pinned open" && navDispatch({ type: "closing" })
          }
          className={`${styles.text} ${disabled ? styles.disabled : null}`}
        >
          <span title={!disabled ? text : "Coming Soon"}>{text}</span>
        </NavLink>
      </div>
    );
  }

  // Nav Buttons (i.e. anything that's not a link)
  return (
    <div
      className={`${styles.row} ${styles.subRow} ${styles.shrinkWithMenu} ${
        text === "Quick Upload" ? styles.mobileRemove : ""
      }`}
    >
      {/* added invisible icons to make them squish during animations the same as the others.  Made sure it's an icon already brought in for performance */}
      <BiBrain
        className={`${styles.icon} ${styles.invisibleIcon}`}
        data-isactive={path && path.includes("my-memory") ? "active" : "false"}
      />

      <button
        onClick={onClickDispatch}
        className={`${styles.text} ${disabled ? styles.disabled : ""}`}
      >
        {text}
      </button>
    </div>
  );
};
