import {
  Type,
  useArticleCardQuery,
  useDeleteNoteMutation,
  useGetSingleNoteQuery,
  useTwitterShareMutation,
  useUserLinkedInShareMutation,
  useUserRewardLogsMutation,
  useUserRewardsMutation,
} from "../../../../../codeGenFE";
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
// CONTEXT
import {
  useNotebookContext,
  useSocialLoginContext,
  useUserContext,
} from "../../../../context/allContexts";
import ComponentMessagingOverlay from "../../../ComponentMessagingOverlay/ComponentMessagingOverlay";
// STYLES
import styles from "./SocialShareConfirm.module.scss";
import { toast } from "react-hot-toast";
import { useRewardsContext } from "../../../../context/allContexts/RewardsContext";
import { useEffect, useLayoutEffect, useState } from "react";
import { LINKEDIN_CLIENT_ID } from "../../../../../constants";
import { useTwitterProcess } from "../../../../../rq-hooks/useTwitterProcess";
import { setTimeout } from "timers";

const SocialShareConfirm = ({ close, note }: any) => {
  // context
  const { notebookState, notebookDispatch } = useNotebookContext();
  const { currentActiveNoteId } = notebookState;
  const [callBack, setCallBack] = useState<any>(null);

  const [linkedInPostId, setLinkedInPostId] = useState<any>(null);
  const [twitterPostId, setTwitterPostId] = useState<any>(null);
  const [twitterError, setTwitterError] = useState<any>({
    extendedError: false,
    emptyError: false,
  });
  const [linkedinError, setLinkedinError] = useState<any>({
    extendedError: false,
    emptyError: false,
  });
  const [article, setArticle] = useState<any>(null);

  useLayoutEffect(() => {
    localStorage.setItem("type", notebookState.editorOverlay);
    localStorage.setItem("pfSkip", "0");
    localStorage.setItem("pv2Skip", "0");
  }, []);
  useEffect(() => {
    console.log("notebook");
    setTimeout(() => {
      const cachedReloaded = localStorage.getItem("reloaded");
      if (cachedReloaded) {
        localStorage.removeItem("reloaded");
        localStorage.removeItem("activeNotebook");
        localStorage.removeItem("type");
      }
    }, 3000);
    // eslint-disable-next-line no-restricted-globals
    setCallBack(`${location.origin}${location.pathname}`);
  }, []);

  const { socialLoginState, socialLoginDispatch } = useSocialLoginContext();
  const {
    linkedInUserData,
    linkedInAccessToken,
    twitterAccessToken,
    twitterAccessTokenSecret,
    twitterToken,
    twitterVerifier,
  } = socialLoginState;
  const { refetch: rewardsDetail } = useRewardsContext();
  const [preview, setPreview] = useState<any>(null);
  const { mutate: twitterProcess } = useTwitterProcess({
    onSuccess: (data: any) => {
      const responseArray = data.split("&");
      window.location.href = `https://api.twitter.com/oauth/authorize?${responseArray[0]}`;
    },
  });
  // In this function system is adding reward points on the basis of URL upload action
  // and on success system is refetching updated reward point details  by
  // userRewardsDetails()
  const { mutate: rewardsMutate } = useUserRewardsMutation({
    onSuccess: async () => {
      await rewardsDetail();
      close();
    },
  });

  // In this function system is logging reward actions and on success we are
  // mutating userRewardsMutation()
  const { mutate: rewardsLogMutate } = useUserRewardLogsMutation({
    onSuccess: () => {
      console.log("success");
      const obj =
        notebookState.editorOverlay === "linkedin"
          ? { linkedinId: linkedInPostId }
          : { twitterPostId: twitterPostId };
      rewardsMutate({
        options: {
          type:
            notebookState.editorOverlay === "linkedin"
              ? Type.Linkedin
              : Type.Twitter,
          meta: {
            articleId: notebookState.currentActiveNoteId,
            ...obj,
          },
        },
      });
    },
  });

  // In this function our system uploads a post to the linkedin account integrated
  // for the user by the help of access token using useUserLinkedInShareMutation()
  // which uses graphql mutation userLinkedInShare() and on success system is
  // mutating userRewardLogsMutation()
  const { isLoading, mutate, error, reset } = useUserLinkedInShareMutation({
    onSuccess: (data) => {
      // close()
      setLinkedInPostId(data.userLinkedInShare);
      // rewardsLogMutate({
      //   options: {
      //     type: Type.Linkedin,
      //   },
      // });
      toast.success("Article posted on linkedin successfully!");
    },
    onError: (err: any) => {
      if (err.includes("Content is a duplicate of"))
        toast.error("Duplicate text cannot be posted!");
      else toast.error(customErrorHandler(err));
    },
  });

  // In this function our system uploads a post to the twitter account integrated for
  // the user by the help of access token using useTwitterShareMutation() which uses
  // graphql mutation userTwitterShare() and on success system is mutating
  // userRewardLogsMutation()
  const { isLoading: twitterShareLoading, mutate: twitterShare } =
    useTwitterShareMutation({
      onSuccess: (data: any) => {
        console.log(data.userTwitterShare.data.id);
        setTwitterPostId(data.userTwitterShare.data.id);
        // rewardsLogMutate({
        //   options: {
        //     type: Type.Twitter,
        //   },
        // });
        toast.success("Article posted on twitter successfully!");
      },
      onError: (err: any) => {
        if (err === "Unauthorized") {
          socialLoginDispatch({
            type: "logout twitter",
          });
        }
        toast.error(err);
      },
    });

  useEffect(() => {
    let pointers = `<h2>${note._source.title.replace(/\.[^/.]+$/, "")}</h2>`;
    note?.richText?.content?.blocks.forEach(
      (block: any, index: number) => (pointers += ` <br /><p>${block.text}</p>`)
    );
    const result = pointers.replace(/[`!#$%^&+=\;':"\\|.,\/?]/g, " ");
    // if(notebookState.editorOverlay === 'linkedin') {
    //   const updatedData = result.replace(/[`@*()_\[\]{}|<>\/~]/g, "\\$&")
    //   setPreview(updatedData)
    // } else {
    //   setPreview(result)
    // }
    setPreview(pointers);
  }, [note?.richText?.content]);

  const handleShare = () => {
    let pointers = note._source.title.replace(/\.[^/.]+$/, "");
    note?.richText?.content?.blocks.forEach(
      (block: any, index: number) => (pointers += `\n\n${block.text}`)
    );
    const result = pointers.replace(/[`!#$%^&+=\;':"\\|.,\/?]/g, " ");
    if (notebookState.editorOverlay === "linkedin") {
      const updatedData = result.replace(/[`@*()_\[\]{}|<>\/~]/g, "\\$&");
      if (updatedData.length > 3000) {
        setLinkedinError({
          extendedError: true,
          emptyError: false,
        });
        return;
      }
      if (!linkedInUserData) {
        window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${callBack}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
      }
      mutate({
        options: {
          token: linkedInAccessToken,
          author: `urn:li:person:${linkedInUserData.id}`,
          data: updatedData,
          image: "",
        },
      });
    } else {
      if (result) {
        if (result.length > 280) {
          setTwitterError({
            extendedError: true,
            emptyError: false,
          });
          return;
        }
        if (result.length === 0) {
          setTwitterError({
            extendedError: false,
            emptyError: true,
          });
          return;
        }
        if (!twitterToken && !twitterVerifier) {
          twitterProcess({
            callback: encodeURI(callBack),
          });
          return;
        }
        twitterShare({
          options: {
            text: result,
            token: twitterAccessToken,
            secret: twitterAccessTokenSecret,
          },
        });
      }
    }
  };

  if (error) {
    if (error.includes("Content is a duplicate of")) {
      return (
        <ComponentMessagingOverlay
          showX={true}
          message={"Duplicate text cannot be posted!"}
          close={reset}
        />
      );
    }
    return (
      <ComponentMessagingOverlay
        showX={true}
        message={customErrorHandler(error)}
        close={reset}
      />
    );
  }
  if (linkedinError.extendedError) {
    return (
      <ComponentMessagingOverlay
        showX={true}
        message={
          "Sharing to Linkedin cannot have more than 3000 characters, Go back to the list of Notes, select and open the note and edit it."
        }
        close={() =>
          setLinkedinError({ ...linkedinError, extendedError: false })
        }
      />
    );
  }
  if (twitterError.extendedError) {
    return (
      <ComponentMessagingOverlay
        showX={true}
        message={
          "Sharing to Twitter cannot have more than 280 characters, Go back to the list of Notes, select and open the note and edit it."
        }
        close={() => setTwitterError({ ...twitterError, extendedError: false })}
      />
    );
  }
  if (twitterError.emptyError) {
    return (
      <ComponentMessagingOverlay
        showX={true}
        message={"Twitter content connot be empty"}
        close={() => setTwitterError({ ...twitterError, emptyError: false })}
      />
    );
  }
  return (
    <div className={styles.deleteConfirmWrapper}>
      <p className={styles.deleteP}>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: preview ? preview : "<></>" }}
        />
      </p>
      <div className={styles.btnContainer}>
        <button
          className={styles.confirmBtn}
          onClick={() => {
            // mutate({ noteId: currentActiveNoteId });
            handleShare();
          }}
        >
          Share
        </button>
        <button className={styles.cancelBtn} onClick={() => close()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SocialShareConfirm;
