import React, { useState, createContext, useContext } from "react";
// import Spinner from "../../../Common/Spinner";
import LoadingCube from "../../../Common/LoadingCube/LoadingCube";

export const SpinnerContext = createContext();

export function SpinnerProvider(props) {
  const [loading, setLoading] = useState(false);

  const Loader = (theme) => {
    // return <Spinner />;
    return <LoadingCube theme={theme} />;
  };

  return (
    <SpinnerContext.Provider value={{ loading, setLoading, Loader }}>
      {props.children}
    </SpinnerContext.Provider>
  );
}

export const useSpinnerContext = () => {
  return useContext(SpinnerContext);
};
