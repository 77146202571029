import icon from "../../../../../assets/img/BrandPocket.png";
import styles from "./riskAssociated.module.scss";

const RiskAssociated = ({ data }) => {
  return (
    <>
      <div className="my-5">
        <div className="text-start mx-3 mt-5 px-2">
          <h2 className="text-dark fw-bold fs-3 my-3">
            Risk Associated with Company
          </h2>
        </div>
        <div className="mx-3 my-2">
          <ul>
            {data.risks.map((item) => (
              <li className="my-4 mx-2 grid grid-flow-col auto-cols-max align-items-start">
                <div className="grid w-12 float-left">
                  <img src={icon} style={{width: '25px'}} />
                </div>
                <div className="mx-3 w-full">
                  <h3 className=" fw-bold fs-5 text-dark">{item.title}</h3>
                  <p className="my-2 text-secondary fs-6 lh-base" style={{width: '800px'}}>
                    {item.content}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default RiskAssociated;
