import { useQueryClient } from "react-query";
import { FaPlus } from "react-icons/fa";
import {
  useGetUserSavedFiltersQuery,
  useSaveFilterMutation,
} from "../../../../../../codeGenFE";
import {
  useSearchContext,
  useNavContext,
} from "../../../../../context/allContexts";
import { useSaveFiltersContext } from "../../SaveFiltersContext";

import styles from "./saveFiltersInput.module.scss";
import OverwriteModal from "../OverwriteModal/OverwriteModal";
import { customErrorHandler } from "../../../../../../utils/customErrorHandler";
import SaveFilterErrorModal from "../SaveFilterErrorModal/SaveFilterErrorModal";
import toast from "react-hot-toast";

const SaveFiltersInput = () => {
  const { navDispatch } = useNavContext();
  const { searchState } = useSearchContext();
  // const { } = searchState;
  // @ts-ignore
  const { saveFilterState, SaveFiltersDispatch } = useSaveFiltersContext();
  const { toggleSaveInput, filterNameInput, duplicateFilter, saveErrorMsg } =
    saveFilterState;

  const qClient = useQueryClient();

  // Save mutation
  const {
    mutate: saveFilters,
    // status: saveFiltersStatus,
    // error: saveFiltersError,
  } = useSaveFilterMutation({
    onSuccess: () => {
      // console.log("data from save filters :>> ", data);
      toast.success(`Saved ${filterNameInput}`);
      SaveFiltersDispatch({ type: "toggle save input", payload: false });
      qClient.invalidateQueries("getUserSavedFilters");
    },
    onError: (data) => {
      console.log("error from saveFilterMutation :>> ", data);
      SaveFiltersDispatch({
        type: "save error",
        msg: customErrorHandler(`${data}`),
        retry: save,
      });
    },
  });

  // Get saved filters to check names against.
  const { data: filterSets } = useGetUserSavedFiltersQuery();
  // Make a list of filter set names
  // const names = filterSets?.getUserSavedFilters?.map((f) => f?.name);

  // Save functionality
  function save() {
    if (filterNameInput) {
      // Check for duplicates
      const dup = filterSets?.getUserSavedFilters.filter(
        (f) => f?.name === filterNameInput
      )[0];
      if (dup) {
        // If there is a duplicate, show a warning.
        SaveFiltersDispatch({
          type: "set duplicate filter",
          filter: dup,
        });
      } else {
        // Save mutation
        saveFilters({
          saveFilterSaveFilterInput: {
            name: filterNameInput,
            topics: searchState.searchTopicChoice,
            relationshipType: searchState.relSearchParams?.category,
            relationshipText: searchState.relSearchParams?.relationship,
            tag: searchState.tagFilterChoice,
            dataSource: searchState.dataSourceChoice,
            category: searchState.categoryChoice,
            driver: searchState.driverChoice,
            outcome: searchState.outcomeChoice,
            country: searchState.countryChoice,
          },
        });
      }
    }
  }

  if (toggleSaveInput === false) {
    // Button to trigger display of save input and save button
    return (
      <div className={styles.container} data-save-filter-input="closed">
        <button
          className={styles.toggleBtn}
          onClick={() => {
            SaveFiltersDispatch({ type: "toggle save input", payload: true });
            navDispatch({ type: "set open filter", name: "" });
          }}
        >
          Save
        </button>
      </div>
    );
  } else {
    // Save input and save button
    return (
      <div className={styles.container} data-save-filter-input="open">
        <div
          className={styles.invisBG}
          onClick={() => SaveFiltersDispatch({ type: "clear" })}
        />
        <input
          type="text"
          className={styles.input}
          onChange={(e) =>
            SaveFiltersDispatch({
              type: "filter name input",
              payload: e.target.value,
            })
          }
          onKeyUp={(e) => {
            e.key === "Escape" && SaveFiltersDispatch({ type: "clear" });
            e.key === "Enter" && !!filterNameInput.trim() && save();
            e.key === "Enter" &&
              !filterNameInput.trim() &&
              SaveFiltersDispatch({ type: "clear" });
          }}
        />
        <FaPlus onClick={save} className={styles.saveBtn} />
        {duplicateFilter && <OverwriteModal />}
        {saveErrorMsg && <SaveFilterErrorModal />}
      </div>
    );
  }
};

export default SaveFiltersInput;
