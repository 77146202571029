import React, { FC, useEffect, useReducer } from "react";
import { AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { useNotebookContext, useNavContext } from "../../context/allContexts";
import { useAddContentToNotesMutation } from "../../../codeGenFE";
import { useQueryClient } from "react-query";
import styles from "./AddToNotebookBtn.module.scss";

// Usage notes:
//
// <AddToNotebookBtn content={<content to be added>} style={<style>} />
// - content: should be a string that will be added to the rich text editor
// - style should contain one of the following based on how it should appear:
//     - "header-one"
//     - "header-two"
//     - "header-three"
//     - "unordered-list-item"
//     - "ordered-list-item"
//     - "blockquote"
//     - "code-block"
// - any other string in the style will turn the rich text editor string into a span, which may cause problems, please refrain from entering anything else.

enum ActionChoices {
  INIT = "init",
  NORMAL = "normal",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

type Actions =
  | { current: ActionChoices.INIT; next: ActionChoices.LOADING }
  | { current: ActionChoices.NORMAL; next: ActionChoices.LOADING }
  | { current: ActionChoices.LOADING; next: ActionChoices.SUCCESS }
  | { current: ActionChoices.LOADING; next: ActionChoices.ERROR }
  | { current: ActionChoices.SUCCESS; next: ActionChoices.NORMAL }
  | { current: ActionChoices.ERROR; next: ActionChoices.NORMAL };

type State = {
  currentState: ActionChoices;
  disableBtn: boolean;
};

const ATNBreducer = (state: State, action: Actions): State => {
  switch (action.current) {
    case ActionChoices.INIT:
      switch (action.next) {
        case ActionChoices.LOADING:
          return {
            ...state,
            currentState: ActionChoices.LOADING,
            disableBtn: true,
          };
      }
    case ActionChoices.NORMAL:
      switch (action.next) {
        case ActionChoices.LOADING:
          return {
            ...state,
            currentState: ActionChoices.LOADING,
            disableBtn: true,
          };
      }
    case ActionChoices.LOADING:
      switch (action.next) {
        case ActionChoices.SUCCESS:
          return {
            ...state,
            currentState: ActionChoices.SUCCESS,
            disableBtn: true,
          };
        case ActionChoices.ERROR:
          return {
            ...state,
            currentState: ActionChoices.ERROR,
            disableBtn: true,
          };
      }
    case ActionChoices.SUCCESS:
      switch (action.next) {
        case ActionChoices.NORMAL:
          return {
            ...state,
            currentState: ActionChoices.NORMAL,
            disableBtn: false,
          };
      }
    case ActionChoices.ERROR:
      switch (action.next) {
        case ActionChoices.NORMAL:
          return {
            ...state,
            currentState: ActionChoices.NORMAL,
            disableBtn: false,
          };
      }
    default:
      return state;
  }
};

const initState: State = {
  currentState: ActionChoices.NORMAL,
  disableBtn: false,
};

interface Props {
  content: string;
  style: string;
  isDisabled?: boolean;
}

const AddToNotebookBtn: FC<Props> = ({
  content,
  // style,
  isDisabled = false,
}) => {
  const qClient = useQueryClient();
  const { notebookState, notebookDispatch } = useNotebookContext();
  const { navDispatch } = useNavContext();

  const { noteIdsForAddToNotebook, currentActiveNoteId } = notebookState;
  // reducer destructure
  const [ATNBState, ATNBDispatch] = useReducer(ATNBreducer, initState);
  const { currentState, disableBtn } = ATNBState;
  const { mutate } = useAddContentToNotesMutation({
    onSuccess: () => {
      if (currentActiveNoteId) {
        qClient.invalidateQueries([
          "getSingleNote",
          { noteId: currentActiveNoteId },
        ]);
      }
      ATNBDispatch({
        current: ActionChoices.LOADING,
        next: ActionChoices.SUCCESS,
      });
    },
    onError: (err) => {
      console.log("err from useAddContentToNotesMutation :>> ", err);
      ATNBDispatch({
        current: ActionChoices.LOADING,
        next: ActionChoices.ERROR,
      });
    },
  });

  async function addContent(event: any) {
    event.stopPropagation();
    // if no notes currently selected, trigger warning to select a note(s)
    if (noteIdsForAddToNotebook.length === 0) {
      notebookDispatch({ type: "ask user to select a note" });
      navDispatch({ type: "change which menu", menu: "notebook" });
      return;
    }
    mutate({ noteIds: noteIdsForAddToNotebook, content: content });
  }

  useEffect(() => {
    if (
      currentState === ActionChoices.SUCCESS ||
      currentState === ActionChoices.ERROR
    ) {
      setTimeout(() => {
        ATNBDispatch({ current: currentState, next: ActionChoices.NORMAL });
      }, 2000);
    }
  }, [currentState]);

  return (
    <>
      <button
        className={styles.addToNotebookBtn}
        disabled={isDisabled || disableBtn}
        onClick={(e) => {
          addContent(e);
        }}
        data-state={currentState}
      >
        <AiOutlinePlus className={styles.addIcon} />
        <AiOutlineCheck className={styles.successIcon} />
        <AiOutlineClose className={styles.errorIcon} />
      </button>
    </>
  );
};

export default AddToNotebookBtn;
