import { createContext, useContext, useEffect, useLayoutEffect, useReducer } from "react";
import { REST_BASE_ENDPOINT } from "../../../constants";
import { axios } from "../../../utils/axiosConfig";

export const NotebookContext = createContext();

const notebookReducer = (state, action) => {
  switch (action.type) {
    case "toggle showSelectANote":
      return { ...state, showSelectANote: !state.showSelectANote, editorOverlay: false };
    case "close showSelectANote":
      return {
        ...state,
        showSelectANote: true,
      };
    case "clear noteIdsForAddToNotebook":
      return {
        ...state,
        noteIdsForAddToNotebook: [],
      };
    case "ask user to select a note":
      return {
        ...state,
        toggleNotebook: "open",
        showSelectANote: true,
        noNoteSelectedWarning: true,
      };

    case "share linkedin":
      return {
        ...state,
        shareLinkedin: true,
      };
    case "share twitter":
      return {
        ...state,
        shareTwitter: true,
      };
    case "twitter length extended":
      return {
        ...state,
        twitterTextExtended: true
      };      
    case "toggle":
      return {
        ...state,
        [action.key]: action.value,
      };
    case "close notebook":
      return initState; 
    case "toggle id in noteIdsForAddToNotebook":
      if (state.noteIdsForAddToNotebook.includes(action.id)) {
        return {
          ...state,
          noteIdsForAddToNotebook: state.noteIdsForAddToNotebook.filter(
            (id) => id !== action.id
          ),
        };
      } else {
        return {
          ...state,
          noNoteSelectedWarning: false,
          noteIdsForAddToNotebook: [
            ...state.noteIdsForAddToNotebook,
            action.id,
          ],
        };
      }
    case "change current active note Id":
      return {
        ...state,
        currentActiveNoteId: action.id,
        showSelectANote: !state.showSelectANote,
      };
    case "set editor overlay":
      return {
        ...state,
        editorOverlay: action.name,
        showSelectANote: action?.showNoteList ? true : false,
      };
    case "logout":
      return initState;
    case "chat gpt api exist":
      return {
        ...state,
        chatGTPApiExist: action.payload,
      };     
    default:
      return state;
  }
};

const initState = {
  toggleNotebook: "closed",
  showSelectANote: true,
  showShare: false,
  noNoteSelectedWarning: false,
  fullscreen: false,
  selectedNotes: [],
  currentActiveNoteId: null,
  noteIdsForAddToNotebook: [],
  editorOverlay: false,
  twitterTextExtended: false,
  shareLinkedin: false,
  shareTwitter: false,
  chatGTPApiExist: false,
};

export function NotebookProvider(props) {
  const [notebookState, notebookDispatch] = useReducer(
    notebookReducer,
    initState
  );

  useLayoutEffect(() => {
    const fetchChatGPTApi = async () => {
      try {
        const res = await axios.get(`${REST_BASE_ENDPOINT}/auth/chat-gpt-valid`)
        console.log(res.data)
        if( res.data.data === "API exists!") {
          notebookDispatch({ type: "chat gpt api exist", payload: true })
        }
      } catch(err) {
        notebookDispatch({ type: "chat gpt api exist", payload: false })
      }
    }
    fetchChatGPTApi()
  }, [])

  return (
    <NotebookContext.Provider
      value={{
        notebookState,
        notebookDispatch,
      }}
    >
      {props.children}
    </NotebookContext.Provider>
  );
}

export const useNotebookContext = () => {
  return useContext(NotebookContext);
};
