import { FC } from "react";
// STYLES
import styles from "./PDFConfirm.module.scss";

interface Props {
  close: () => void;
  handleExport: (type: string) => void;
}
const PDFConfirm: FC<Props> = ({ close, handleExport }) => {
  function downloadPDF() {
    var printWindow = window.open("", "", "height=400,width=800");
    const titleHtml = document.querySelector(
      "#notebookPdfTargetTitle"
    )?.innerHTML;
    const titleText = document.querySelector(
      "#notebookPdfTargetTitle"
    )?.textContent;
    const content = document.querySelector(
      "#editorPdfTargetContent"
    )?.innerHTML;
    if (printWindow && content && titleHtml) {
      printWindow.document.write(`<html><head><title>${titleText}</title>`);
      printWindow.document.write(
        `</head><body style='font-family: sans-serif;' id="content">`
      );
      printWindow.document.write(
        `<h1 style="text-align: center;">${titleHtml}</h1>`
      );
      printWindow.document.write(content);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  }

  return (
    <div className={styles.deleteConfirmWrapper}>
      <div className={styles.btnContainer}>
      <button
          className={`${styles.confirmBtn} ${styles.htmlDownload} downloadBtn`}
          onClick={() => {
            // console.log("DOWNLOAD PDF");
            // downloadPDF();
            handleExport('html')
          }}>
          Download HTML
        </button>
        <button
          className={`${styles.confirmBtn} downloadBtn`}
          onClick={() => {
            // console.log("DOWNLOAD PDF");
            // downloadPDF();
            handleExport('doc')
          }}>
          Download DOCX
        </button>
        <button
          className={`${styles.confirmBtn} downloadBtn`}
          onClick={() => {
            // console.log("DOWNLOAD PDF");
            // downloadPDF();
            handleExport('pdf')
          }}>
          Download PDF
        </button>
        <button className={styles.cancelBtn} onClick={() => close()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PDFConfirm;
