import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { AiOutlineUp } from "react-icons/ai";
import { useQueryClient } from "react-query";
// Components
import MyMemoryRoboImg from "../../../assets/img/My Data.png";
import {
  ArticleMeta,
  TasksStatusQuery,
  useArticlesFilteredVsTotalStatQuery,
  WhichDb,
} from "../../../codeGenFE";
import InfoButton from "../../../Icons/InfoButton";
import { useMyMemoryInfiniteArticles } from "../../../rq-hooks";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../Components/LoadingCube/LoadingCube";
import MainNavigation from "../../Components/MainNavigation/MainNavigation";
import {
  useSearchContext,
  useSocialLoginContext,
  useUserContext,
  useViewPortContext,
} from "../../context/allContexts";
import ReaderCard from "./ArticleCard/ReaderCard";
// Styles
import styles from "./MyMemory.module.scss";
// import { errorCard, noResults } from "./MyMemoryCustomMessaging";
import { errorCard } from "./MyMemoryCustomMessaging";
import MyMemoryInfoModal from "./MyMemoryInfoModal/MyMemoryInfoModal";
import { useMyMemoryContext } from "./MyMemoryContext";
import { MyMemoryActionChoices } from "./MyMemoryTypes";
import ACReadMoreModal from "./ArticleCard/ACReadMoreModal/ACReadMoreModal";
import TopMenu from "../../Components/TopMenu/TopMenu";
import { Link } from "react-router-dom";
import SocialLogin from "../SocialLogin";
import { useSubscriptionContext } from '../../context/allContexts/SubscriptionContext';
import Pagination from "./Pagination";
import { generatePDF } from '../../util/generatePDF';

const MyMemory: FC = () => {
  // @ts-ignore
  const { myMemState, myMemDispatcher } = useMyMemoryContext();
  const qClient = useQueryClient();
  const { displayInfoModal, showSumAndDriverOverlay,v2Skip, filesSkip, articles } = myMemState;
  const [modalShow, setModalShow] = useState(false)
  const [navEnabled, setNavEnabled] = useState(false)
  // @ts-ignore
  const { width } = useViewPortContext();
  const { user, isReloaded } = useUserContext();
  // const {fileData}: any = useSubscriptionContext()

  const {socialLoginState, socialLoginDispatch} = useSocialLoginContext()
  const checkForDownload = localStorage.getItem("downloadPdf");
  const {
    linkedInUserData, 
    twitterToken, twitterVerifier} = socialLoginState


  // ------------------- Article Database State ------------------- //
  const { searchState } = useSearchContext();
  const {
    dataSourceChoice,
    relSearchParams,
    searchString,
    searchTopicChoice,
    tagFilterChoice,
  } = searchState;

  const searchVariables = {
    searchStr: searchString,
    relationshipCategory: relSearchParams?.category,
    relationshipValue: relSearchParams?.relationship,
    tag: tagFilterChoice,
    articleSource: dataSourceChoice,
    topic: searchTopicChoice,
    category: searchState.categoryChoice,
    driver: searchState.driverChoice,
    outcome: searchState.outcomeChoice,
    country: searchState.countryChoice,
    otherLocation: searchState.otherLocation,
    org: searchState.org,
    person: searchState.person,
    product: searchState.product
  };

  const {
    data,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    error,
  } = useMyMemoryInfiniteArticles({pv2Skip: v2Skip, pfSkip: filesSkip, isReloaded: localStorage.getItem('reloaded') && localStorage.getItem('reloaded') === 'true' ? true: false, ...searchVariables}, {
    onSuccess: async (data: any) => {
      // console.log(data, "articles")
      myMemDispatcher({
        type: MyMemoryActionChoices.SET_ARTICLES,
        articles: data?.pages
      })
    },
    onError: (err: any) => {
      console.log("Err from useMyMemoryInfiniteArticles...", err);
    },
  });

  useLayoutEffect(() => {
    if(checkForDownload){
      generatePDF()
      localStorage.removeItem('newUser')
      localStorage.removeItem("downloadPdf")
    }
    if((!localStorage.getItem('hideSocialIntegrationPopup') || (localStorage.getItem('hideSocialIntegrationPopup') && localStorage.getItem('hideSocialIntegrationPopup') === '0')) && (!linkedInUserData && (!twitterToken && !twitterVerifier))) {
      setTimeout(() => {
        setModalShow(true)
      }, 5000) 
    }
  }, [])

  useEffect(() => {
    const newSearchFetch = async () => {
      try {
        // No need to do anything with the err because the query hook will handle it.
        await qClient.fetchInfiniteQuery("myMemoryInfinite");
      } catch (error) {}
    };
    if(!localStorage.getItem('reloaded'))
      newSearchFetch();
  }, [searchState, qClient]);

  const pages = data?.pages;
  // ------------------- Article Stats State ------------------- //
  const { data: statsData, error: statsError } =
    useArticlesFilteredVsTotalStatQuery({ inputOpts: searchVariables });

  const numTotalArticles = statsData?.articlesFilteredVsTotalStat.total;
  const numTotalFeeds = statsData?.articlesFilteredVsTotalStat.totalFeeds;
  const numTotalPrivate = statsData?.articlesFilteredVsTotalStat.totalPrivate;
  const numTotalShared = statsData?.articlesFilteredVsTotalStat.totalShared;
  const numFilteredArticles =
    statsData?.articlesFilteredVsTotalStat.filteredTotal;

  // ------------------- fifteen day line ------------------- //

  // Linux epoch time 15 days ago in seconds
  const date = new Date();
  const fifteendayCutOff = Math.floor(date.setDate(date.getDate() - 15) / 1000);
  let fifteenDayLineShown = false;

  // ------------------- Scroll Code ------------------- //

  const [height, setHeight] = useState(window.scrollY);
  const [closeTrigger, setCloseTrigger] = useState(false);
  const [searchBarShow, setSearchBarShow] = useState(true);

  const scrollToTop = () => {
    const items2Close = document.querySelectorAll(".targetCloseAll");
    // @ts-ignore
    items2Close.forEach((item) => item.click());
    setCloseTrigger(!closeTrigger);
  };

  useEffect(() => {
    console.log(searchState)
    myMemDispatcher({
      type: MyMemoryActionChoices.SET_SKIP,
      v2Skip: 0,
      filesSkip: 0,
    })
  }, [searchState])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 650);
  }, [closeTrigger]);

  useEffect(() => {
    const handleResize = (e: any) => {
      if(!localStorage.getItem('reloaded')) {
        localStorage.setItem('currentScroll', e.target.documentElement.scrollTop)
      }  
      setHeight(window.scrollY);
    };
    window.addEventListener("scroll", handleResize);
    return () => window.removeEventListener("scroll", handleResize);
  }, [height]);

  useEffect(() => {
    const loadMore = (e: any) => {
      let positionFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
      if (positionFromBottom <= 40 && !isFetchingNextPage && !localStorage.getItem('reloaded')) {
        // fetchNextPage();
      }
    };
    window.addEventListener("scroll", loadMore);
    return () => window.removeEventListener("scroll", loadMore);
  }, [height]);

  useEffect(() => {
    if(showSumAndDriverOverlay)
      setSearchBarShow(false)
    else  
      setSearchBarShow(true)  
  }, [showSumAndDriverOverlay])

  useEffect(() => {
    if(v2Skip !== null && filesSkip !== null) {
      if(!localStorage.getItem('reloaded') && navEnabled) {
        console.log(v2Skip, filesSkip, "effect")
        localStorage.setItem("pfSkip", filesSkip)  
        localStorage.setItem("pv2Skip", v2Skip)
        fetchNextPage()
      }
    }
  }, [v2Skip, filesSkip])

  useEffect(() => {
    if(articles && articles?.length > 0) {
      if(localStorage.getItem('reloaded') && localStorage.getItem('reloaded') === 'true' && localStorage.getItem('currentScroll') && !localStorage.getItem('activeNotebook')) {
        const currentScroll = localStorage.getItem('currentScroll') || '0'
        setTimeout(() => {
          window.scrollTo({ top: parseFloat(currentScroll), behavior: "smooth" });
          localStorage.removeItem('reloaded')
        }, 5000);
      }
    }
  }, [articles])
  useEffect(() => {
    if(articles && articles?.length > 0) {
      if(!localStorage.getItem('reloaded')) {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 650);
      }
    }
  }, [v2Skip, filesSkip])
  


  // console.log(v2Skip, filesSkip, "skip")
  // console.log(pages, "laoding")
  const checkFifteenDay = (userMetaData: any) => {
    const filteredMeta = userMetaData.find((meta: any) => meta.userId === user._id)
    return filteredMeta && filteredMeta.date < fifteendayCutOff ? true : false
  }
  // ================================ JSX Section ================================ //
  return (
    <div className={styles.myMemoryContainer}>
      {/* <TopMenuUpload topLevelElement={styles.topMenu} disableTips={true} /> */}
       <div className={styles.topMenu}>
        <TopMenu showSearch={searchBarShow} />
      </div>
      
      <div className={styles.leftMenu}>
        <MainNavigation />
      </div>
      <div className={styles.myMemoryContent}>
        <div className={styles.welcome}>
          <h1 className={styles.welcomeText}>
            Welcome to Pluaris!
          </h1>
          <h2 className={styles.welcomeSubText}>
            Start uploading new content or use <Link className={styles.topicMonitoring} to={"/inputs/topic-monitoring"}>topic monitoring</Link>
          </h2>
        </div>
        {/* {
          numTotalArticles && <>
                <div className={styles.stats}>
                  <div className={`${styles.sources} ${styles.feeds}`}>
                    <span>{numTotalFeeds}</span>
                    <p>Articles Feed</p>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={`${styles.sources} ${styles.private}`}>
                    <span>{numTotalPrivate}</span>
                    <p>Private</p>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={`${styles.sources} ${styles.shared}`}>
                    <span>{numTotalShared}</span>
                    <p>Shared</p>
                  </div>
                </div>   
          </>
        }
        <img
          className={styles.myMemoryImg}
          src={MyMemoryRoboImg}
          alt="Pluaris Robot Logo"
        /> */}
        <div className={styles.header}>
          <h3>READER</h3>
          <InfoButton
            className={styles.infoBtn}
            onClick={() =>
              myMemDispatcher({
                type: MyMemoryActionChoices.TOGGLE_DISPLAY_INFO_MODAL,
              })
            }
          />
        </div>
        {/* <div>
          {
            fileProcessingName && <ul className={styles.processingFiles}>
              {
                fileProcessingName.map((file) => <li className={styles.articleStats} style={{color: "red"}}>{file}</li>)
              }
            </ul>
          }
        </div> */}
        {/* Info Button Modal */}
        <MyMemoryInfoModal
          close={() =>
            myMemDispatcher({
              type: MyMemoryActionChoices.TOGGLE_DISPLAY_INFO_MODAL,
              payload: false,
            })
          }
          myMemoryInfoModal={displayInfoModal}
        />
        {/* <div className={`${styles.searchContainer} ${styles.desktopOnly}`}>
          <V2Search />
        </div> */}
        {/* Articles */}
        {(isFetching) && (
          <div className={styles.articles_container}>
            <LoadingCube
              theme={LoadingCubeTheme.DARK}
              isFullScreen={width < 1210 ? true : false}
              width={100}
              height={100}
            />
          </div>
        )}
        {/* --------------- Article Page Stats --------------- */}
        {statsData && (
          <p className={styles.articleStats}>
            You have {numTotalArticles} articles
          </p>
        )}
        {!error && articles && articles?.length > 0 && (
          <>
            <div className={styles.articles_container} id="target">
              {articles?.map((p: any, idx: any) => {
                return (
                  <React.Fragment key={idx}>
                    {p?.articles?.map((a: ArticleMeta, innerIdx: number) => {
                      // Fifteen day line
                      if (
                        !fifteenDayLineShown &&
                        checkFifteenDay(a.userMetaData)
                      ) {
                        fifteenDayLineShown = true;
                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "row",
                                marginTop: "-20px",
                                marginBottom: "40px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  height: "2px",
                                  width: "100%",
                                  background: "var(--OneLinkColor)",
                                }}
                              ></div>
                              <p
                                style={{
                                  minWidth: "8rem",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  fontStyle: "italic",
                                }}
                              >
                                15 Days
                              </p>
                              <div
                                style={{
                                  height: "2px",
                                  width: "100%",
                                  background: "var(--OneLinkColor)",
                                }}
                              ></div>
                            </div>
                            <ReaderCard key={a?._id} data={a} />
                          </>
                        );
                      } else {
                        return <ReaderCard key={a?._id} data={a} />;
                      }
                    })}
                    {/* {p?.articles?.length < 25 && (
                      // No Articles Found Card
                      <ReaderCard key="noResKey" data={noResults} />
                    )} */}
                    {p?.articles?.length === 0 && (
                      <p style={{ fontSize: "32px" }}>No Articles Found</p>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            {
              width > 1200 ?
              !error && articles && articles?.length > 0 &&
              <Pagination 
                total={numFilteredArticles ? numFilteredArticles : 0}
                limit={25}
                navEnabled={() => setNavEnabled(true)}
              /> : ""
            }
          </>
        )}
        {
          (!isLoading || !isFetching) && <SocialLogin
          showAskMeInfoModal={modalShow}
          onHide={(notIntegrate: boolean) => {
            localStorage.setItem('hideSocialIntegrationPopup', notIntegrate)
            setModalShow(false)
          }}
        />
        }
        {/* Error Card */}
        {error && !isFetching && (
          <div className={styles.articles_container} id="target">
            <ReaderCard key="noResKey" data={errorCard} />
          </div>
        )}
        {/* Scroll To Top Button */}
        {!!height && height > 200 && (
          <div
            onClick={() => scrollToTop()}
            className={styles.scrollTopContainer}
          >
            <AiOutlineUp className={styles.scrollArrow} />
          </div>
        )}
        {/* Fetching Loader Animation */}
        {(isFetching || isLoading) && (
          <div className={styles.isFetchingNextPageContainer}>
            <LoadingCube
              theme={LoadingCubeTheme.DARK}
              height={width < 1210 ? 100 : 30}
              width={width < 1210 ? 100 : 30}
              isFullScreen={width < 1210 ? true : false}
            />
          </div>
        )}
      </div>
      {/* Mobile Menu */}
      {showSumAndDriverOverlay && (
        <div className={styles.myMemoryReadMoreDesktopSection}>
          <ACReadMoreModal
            toggleModal={() =>
              myMemDispatcher({
                type: MyMemoryActionChoices.TOGGLE_SUM_DRIV_OVERLAY,
                payload: false,
              })
            }
          />
        </div>
      )}
      {
        width <= 1200 ? 
        !error && articles && articles?.length > 0 && articles[0]?.articles &&
        <Pagination 
          total={numFilteredArticles ? numFilteredArticles : 0}
          limit={25}
          navEnabled={() => setNavEnabled(true)}
        /> : ""
      }
    </div>
  );
};

export default MyMemory;
