import { REST_BASE_ENDPOINT } from "../constants";
import { useMutation } from "react-query";
import { axios } from "../utils/axiosConfig";

interface TokenProcessInput {
  callback: string;
}

export function useTwitterProcess(opts?: any) {
  return useMutation(async (tokenProcessInput: TokenProcessInput) => {
    try {
      const { data } = await axios.post(
        `${REST_BASE_ENDPOINT}/auth/twitter/request-token`,
        tokenProcessInput
      );
      if (data.data) {
        return data.data;
      }
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, opts);
}
