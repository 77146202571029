import React, { FC, useReducer } from "react";
import { Note, Type, useShareNoteMutation, useUserRewardLogsMutation, useUserRewardsMutation } from "../../../../../codeGenFE";
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
// Context
import { useNotebookContext, useUserContext } from "../../../../context/allContexts";
// import { useShareNoteSubContext } from "../../../../context/subscribableContext";
import ComponentMessagingOverlay from "../../../ComponentMessagingOverlay/ComponentMessagingOverlay";
// Components
import ShareList, { ShareListTheme } from "../../../ShareList/ShareList";
// Styles
import styles from "./NBShareModal.module.scss";

enum ActionChoices {
  TOGGLE_SHARE_ALL,
  CHANGE_SHARED_USER_LIST,
  SUCCESS,
  FAILED,
  CLOSE,
}

type State = {
  shareAll: boolean;
  selectedUsers: string[] | [];
  success: boolean;
  error: boolean | string;
  retry: () => void;
};

type Actions =
  | { type: ActionChoices.TOGGLE_SHARE_ALL; boolean: boolean }
  | {
      type: ActionChoices.CHANGE_SHARED_USER_LIST;
      users: string[] | [];
    }
  | { type: ActionChoices.CLOSE }
  | { type: ActionChoices.SUCCESS }
  | { type: ActionChoices.FAILED; msg: string; retry: () => void };

const NBShareReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case ActionChoices.TOGGLE_SHARE_ALL:
      return {
        ...state,
        shareAll: action.boolean,
      };
    case ActionChoices.CHANGE_SHARED_USER_LIST:
      return {
        ...state,
        selectedUsers: action.users,
      };
    case ActionChoices.SUCCESS:
      return {
        ...state,
        success: true,
        error: "",
      };
    case ActionChoices.FAILED:
      return {
        ...state,
        success: false,
        error: action.msg,
        retry: action.retry,
      };
    case ActionChoices.CLOSE:
      return {
        ...state,
        success: false,
        error: false,
        selectedUsers: [],
        shareAll: false,
      };
    default:
      return state;
  }
};

const initState: State = {
  shareAll: false,
  selectedUsers: [],
  success: false,
  error: "",
  retry: () => {},
};

interface Props {
  close: () => void;
  note: Note;
}

const NBShareModal: FC<Props> = ({ close, note }) => {
  // context
  // const { notebookState, notebookDispatch } = useNotebookContext();
  const { notebookDispatch } = useNotebookContext();
  // @ts-ignore
  // const { setSharedNote, sharedNote } = useShareNoteSubContext();
  // convienience destructure
  // const { notes, currentNoteIndex, showShare } = notebookState;
  const {refetch} = useUserContext()
  // reducer state and dispatch
  const [NBShareState, NBShareDispatch] = useReducer(NBShareReducer, initState);
  // convienience destructure
  const { shareAll, selectedUsers, success, error, retry } = NBShareState;
  const {mutate: rewardsMutate} = useUserRewardsMutation({
    onSuccess: () => {
      refetch()
    },
  })
  const {mutate: rewardsLogMutate} = useUserRewardLogsMutation({
    onSuccess: () => {
      rewardsMutate({
        options: {
          type: Type.Notebook,
          meta: {
            selectedUsers,
            note
          }
        }
      })
    }
  })

  const { mutate } = useShareNoteMutation({
    onSuccess: () => {
      // console.log("SUCCESS: ", data);
      NBShareDispatch({ type: ActionChoices.SUCCESS });
      // rewardsLogMutate({
      //   options: {
      //     type: Type.Notebook
      //   }
      // })
    },
    onError: (error) => {
      console.log("ERROR: ", error);
      NBShareDispatch({
        type: ActionChoices.FAILED,
        msg: customErrorHandler(error),
        retry: () => mutate({ note: note, users: selectedUsers }),
      });
    },
  });

  const setShareAll = (bool: boolean) => {
    NBShareDispatch({ type: ActionChoices.TOGGLE_SHARE_ALL, boolean: bool });
  };

  const setSelectedUsers = (userIds: [] | string[]) => {
    NBShareDispatch({
      type: ActionChoices.CHANGE_SHARED_USER_LIST,
      users: userIds,
    });
  };

  async function shareNote() {
    mutate({ note: note, users: selectedUsers });
  }

  if (success) {
    return (
      <div className={styles.overlayWrapper}>
        <ComponentMessagingOverlay
          showCheck={true}
          message={"Success!"}
          close={() => {
            NBShareDispatch({ type: ActionChoices.CLOSE });
            notebookDispatch({
              type: "toggle",
              key: "editorOverlay",
              value: false,
            });
          }}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.overlayWrapper}>
        <ComponentMessagingOverlay
          showX={true}
          message={`${error}`}
          buttonText={"Retry?"}
          close={retry}
        />
      </div>
    );
  }

  return (
    <div className={styles.nbShareWrapper}>
      <div className={styles.shareListWrapper}>
        <ShareList
          shareAll={shareAll}
          setShareAll={setShareAll}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          theme={{ desktop: ShareListTheme.LIGHT, mobile: ShareListTheme.DARK }}
        />
      </div>
      <div className={styles.nbShareBtns__div}>
        {/*TODO check disabled is working when noteboot is up*/}
        <button
          className={styles.shareBtn}
          data-disabled={selectedUsers.length <= 0 ? "disableShareBtn" : null}
          disabled={selectedUsers.length <= 0}
          onClick={() => shareNote()}
        >
          Share
        </button>
        <button className={styles.cancelBtn} onClick={() => close()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default NBShareModal;
