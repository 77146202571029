import React from "react";

import styles from "./ArticleCardImage.module.scss";

// PLACEHOLDER IMAGES
// import newsPlaceholder from "../../../../../assets/img/NEWSPLACEHOLDER2.png";
// import urlPlaceholder from "../../../../../assets/img/URLPLACEHOLDER2.png";
// import textPlaceholder from "../../../../../assets/img/TEXTPLACEHOLDER2.png";
import helpPlaceholder from "../../../../../assets/img/no-results.png";

const ArticleCardImage = ({ proImageLink, pubStatus, name, title }) => {
  // if no image load default image
  // const noImageUpload = (image, pubStatus, src) => {
  //   if (pubStatus === "public") {
  //     return proImageLink !== "No processed image link for this file"
  //       ? image
  //       : newsPlaceholder;
  //   } else if (pubStatus === "shared" && src !== "note") {
  //     return proImageLink !== "No processed image link for this file"
  //       ? image
  //       : urlPlaceholder;
  //   } else if (pubStatus === "shared" && src === "note") {
  //     return proImageLink !== "No processed image link for this file"
  //       ? image
  //       : textPlaceholder;
  //   } else if (pubStatus === "private" && src !== "note") {
  //     return proImageLink !== "No processed image link for this file"
  //       ? image
  //       : urlPlaceholder;
  //   } else if (pubStatus === "private" && src === "note") {
  //     return proImageLink !== "No processed image link for this file"
  //       ? image
  //       : textPlaceholder;
  //   } else if (pubStatus === "help" && src !== "note") {
  //     return proImageLink !== "No processed image link for this file"
  //       ? image
  //       : helpPlaceholder;
  //   } else if (pubStatus === "error" && src != "note") {
  //     // TODO: have Designer create broken robot image
  //     return proImageLink !== "No processed image link for this file"
  //       ? image
  //       : helpPlaceholder;
  //   }
  // };

  return (
    <div className={styles.articleImgContainer}>
      <img
        className={styles.articleImg}
        // src={noImageUpload(proImageLink, pubStatus, name)}
        src={pubStatus !== "help" ? proImageLink : helpPlaceholder}
        alt={title}
      />
    </div>
  );
};

export default ArticleCardImage;
