import React, { FC, useEffect } from "react";

// components
import CloseModalBtn, {
  CloseModalBtnTheme,
} from "../../../Components/CloseModalBtn/CloseModalBtn";
import { AiOutlineClose } from "react-icons/ai";
import RTNAOneNewsArticle from "./RTNAOneNewsArticle/RTNAOneNewsArticle";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../Components/LoadingCube/LoadingCube";

// styles
import styles from "./RecentTopicNewsArticles.module.scss";
import {
  TopFiveNewsPreviewInput,
  Topic,
  useFetchTopFiveNewsPreviewQuery,
} from "../../../../codeGenFE";

function inputOptsBuilder(topicData: Topic | null): TopFiveNewsPreviewInput {
  const inputOpts: any = {
    topic: topicData?.topic ? topicData?.topic : "Testing",
    topicType: topicData?.relationshipType,
    subscriptionReason: topicData?.relationshipText
      ? topicData?.relationshipText
      : "",
    excludedTopicKeywords: topicData?.excludedKeywords
      ? topicData?.excludedKeywords
      : [],
  };

  if (topicData?.country && topicData.country.length > 0) {
    inputOpts.marketCode = topicData.country.map((c) => c!.marketCode);
  }
  return inputOpts;
}

interface RecentTopicNewsArticlesInput {
  close: () => void;
  topicData: Topic | null;
}

const RecentTopicNewsArticles: FC<RecentTopicNewsArticlesInput> = ({
  close,
  topicData,
}) => {
  // ========================= Data Fetching ========================= //
  const { data, status, isFetching } = useFetchTopFiveNewsPreviewQuery(
    {
      inputOpts: inputOptsBuilder(topicData),
    },
    {
      enabled: !!topicData && !!topicData?.relationshipType,
      retryDelay: 2000,
    }
  );

  const previewArticles = data?.fetchTopFiveNewsPreview;

  if (!topicData) {
    return null;
  }

  if (status === "loading") {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingCube
          theme={LoadingCubeTheme.DARK}
          height="138px"
          width="138px"
        />
      </div>
    );
  } else if (previewArticles && previewArticles.length > 0) {
    return (
      <>
        {isFetching && (
          <div className={styles.loadingWrapper}>
            <LoadingCube
              theme={LoadingCubeTheme.DARK}
              height="138px"
              width="138px"
            />
          </div>
        )}
        <div className={styles.recentTopicNewsArticles__scrollContainer}>
          <div className={styles.recentTopicNewsArticles__wrapper}>
            <div className={styles.closeBtnWrapper}>
              <div className={styles.closeBtn}>
                <CloseModalBtn theme={CloseModalBtnTheme.DARK} close={close} />
              </div>
            </div>
            <div className={styles.newsContent__wrapper}>
              <p className={styles.label}>Recent News</p>
              {previewArticles &&
                previewArticles.map((one: any) => (
                  <RTNAOneNewsArticle key={one._id} dataForOne={one} />
                ))}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.recentTopicNewsArticles__scrollContainer}>
        <div className={styles.recentTopicNewsArticles__wrapper}>
          <div className={styles.closeBtnWrapper}>
            <div className={styles.closeBtn}>
              <CloseModalBtn
                theme={CloseModalBtnTheme.DARK}
                close={() => {
                  close();
                }}
              />
            </div>
          </div>
          <div className={styles.noArticles__div}>
            <AiOutlineClose className={styles.iconX} />
            <p className={styles.noArticles__p}>Sorry, no articles found!</p>
          </div>
        </div>
      </div>
    );
  }
};

export default RecentTopicNewsArticles;
