import React, { FC } from "react";
// components
import { MdArrowDropDown } from "react-icons/md";
import { ArticleSource } from "../../../../../codeGenFE";
// Data
import { useSearchContext } from "../../../../context/allContexts";
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";
import DropdownDisplayBelow from "../../../DropdownDisplayBelow/DropdownDisplayBelow";
// styles
import styles from "./filters.module.scss";

interface Props {
  openFilter: string;
  setOpenFilter: (arg: string) => void;
}

const DataSourceFilter: FC<Props> = ({ openFilter, setOpenFilter }) => {
  // const { dataSourceChoice, changeDataSourceChoice } = useSearchContext();
  const { searchState, searchDispatcher } = useSearchContext();
  const { dataSourceChoice } = searchState;

  // NEW STATE
  // const [dataSourceDisplayed, setDataSourceDisplayed] = useState("Data Source");

  const dataSources = [
    ArticleSource.News,
    ArticleSource.Shared,
    ArticleSource.Private,
  ];

  // // main dropdown
  // function toggleOpen() {
  //   if (openFilter === "data source") {
  //     setOpenFilter("");
  //   } else {
  //     setOpenFilter("data source");
  //   }
  // }

  // useEffect(() => {
  //   if (dataSourceChoice !== "") {
  //     // setDataSourceDisplayed(dataSourceChoice);
  //     searchDispatcher({type: SearchContextActions.CHANGE_DATA_SOURCE, payload: dataSourceChoice});
  //   } else {
  //     setDataSourceDisplayed("Data Source");
  //   }
  // }, [dataSourceChoice]);

  // function changeDataSource(dataSource) {
  //   if (!dataSource) {
  //     searchDispatcher({
  //       type: SearchContextActions.CHANGE_DATA_SOURCE,
  //       payload: null,
  //     });
  //     // changeDataSourceChoice("");
  //     toggleOpen();
  //   } else {
  //     searchDispatcher({
  //       type: SearchContextActions.CHANGE_DATA_SOURCE,
  //       payload: dataSource,
  //     });
  //     // changeDataSourceChoice(dataSource);
  //     toggleOpen();
  //   }
  // }

  return (
    <div
      className={styles.outcomeFilterWrapper}
      data-is-open={`${openFilter === "data source"}`}
    >
      <DropdownDisplayBelow
        selectableItems={dataSources}
        isDropdownOpen={openFilter === "data source"}
        toggleDropdown={() =>
          setOpenFilter(openFilter === "data source" ? "" : "data source")
        }
        dropdownDisplay={"Data Sources"}
        selectionDisplay={searchState?.dataSourceChoice}
        select={(source) =>
          searchDispatcher({
            type: SearchContextActions.CHANGE_DATA_SOURCE,
            payload: source as ArticleSource,
          })
        }
        unselect={() =>
          searchDispatcher({
            type: SearchContextActions.CHANGE_DATA_SOURCE,
            payload: null,
          })
        }
        makeSearchable={false}
        capitalizeDisplays={true}
      />
    </div>

    // <div className={styles.dropdown__container}>
    //   <div
    //     className={`${styles.filterDisplay} ${
    //       openFilter === "data source"
    //         ? styles.filterDisplay__open
    //         : styles.filterDisplay__close
    //     }`}
    //     onClick={() => toggleOpen()}
    //   >
    //     <p className={styles.filterName}>
    //       {dataSourceChoice ? dataSourceChoice.toLowerCase() : "Data Sources"}
    //     </p>
    //     <span className={styles.downArrow}>
    //       <MdArrowDropDown
    //         style={{
    //           transform: `rotate(${
    //             openFilter === "data source" ? "180" : "0"
    //           }deg)`,
    //         }}
    //       />
    //     </span>
    //   </div>

    //   <div
    //     className={`${styles.dropdown__selectionList} ${
    //       openFilter === "data source" ? null : styles.dropdown__closed
    //     } ${styles.darkTheme__light}`}
    //   >
    //     {/* Reset Button */}
    //     <div className={`${styles.dropdown__item} `}>
    //       <button
    //         onClick={() => changeDataSource("")}
    //         className={`${styles.dropdown__btn} ${styles.resetSingleFilter}
    //           ${styles.darkTheme__light}
    //         `}
    //       >
    //         Reset
    //       </button>
    //     </div>
    //     {/* <CategoriesDropdown categories={categories} /> */}
    //     {dataSources.map((dataSource) => {
    //       return (
    //         <SelectableDataSource
    //           key={dataSource}
    //           changeDataSource={changeDataSource}
    //           dataSource={dataSource}
    //         />
    //       );
    //     })}

    //     {/* display loading below */}
    //   </div>
    // </div>
  );
};

export default DataSourceFilter;

// function SelectableDataSource({ dataSource, changeDataSource }) {
//   return (
//     <div className={`${styles.dropdown__item} `}>
//       <button
//         onClick={() => changeDataSource(dataSource)}
//         className={`${styles.dropdown__btn} ${styles.capitalize}
//           ${styles.darkTheme__light}
//         `}
//       >
//         {dataSource.toLowerCase()}
//       </button>
//     </div>
//   );
// }
