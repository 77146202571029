import React from "react";
import {
  DashModalProvider,
  NavProvider,
  UserProvider,
  SubscriptionProvider,
  ViewPortProvider,
  // WhichMenuProvider,
  // TPIServiceProvider,
  // ThirdPartyIntegrationProvider,
  FileUploadProvider,
  NotebookProvider,
  // SpinnerProvider,
  SearchProvider,
  // NewsProvider,
  // ErrorProvider,
  // CompanyUploadsProvider,
  // PrivateUploadsProvider,
  UserProfileProvider,
  RewardsProvider,
  SocialLoginProvider,
  // TagsProvider,
} from "./allContexts";

function ProviderComposer({ contexts, children }) {
  return contexts.reduceRight(
    (kids, parent) =>
      React.cloneElement(parent, {
        children: kids,
      }),
    children
  );
}

function ContextProvider({ children }) {
  return (
    <ProviderComposer
      contexts={[
        // <ErrorProvider />,
        <ViewPortProvider />,
        <UserProvider />,
        <RewardsProvider />,
        <SubscriptionProvider />,
        // <TPIServiceProvider />,
        // <SpinnerProvider />,
        // <NewsProvider />,
        // <CompanyUploadsProvider />,
        // <PrivateUploadsProvider />,
        <SocialLoginProvider/>,
        <SearchProvider />,
        <DashModalProvider />,
        <UserProfileProvider />,
        // <TagsProvider />,
        <NavProvider />,
        // <ThirdPartyIntegrationProvider />,
        <FileUploadProvider />,
        // <WhichMenuProvider />,
        <NotebookProvider />,
      ]}>
      {children}
    </ProviderComposer>
  );
}

export { ContextProvider };
