import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import ExpansionPlans from "./ExpansionPlans/ExpansionPlans.component";
import InvestmentPlans from "./InvestmentPlans/InvestmentPlans.component";
import PeopleIntelligence from "./peopleIntelligence/peopleIntelligence";
import PowerOfOne from "./PowerOfOne/PowerofOne.component";

import { API_BASE_URL, TECH_M_ENDPOINTS } from "../../../Constants";
import ContainerCard from "../../../Components/ContainerCard";

export default function CompanyTrendsTab({ currentCompany }) {
  const [loading, setLoading] = useState(true);

  const [trendsData, setTrendsData] = useState({});
console.log("currentCompany", currentCompany);
  const getTrendsData = async () => {
    if (!!currentCompany) {
      setLoading(true);
      await axios
        .get(API_BASE_URL + TECH_M_ENDPOINTS.TRENDS(currentCompany))
        .then((res) => {
          setTrendsData(res.data.data[0]);
          console.log("comapny data:", res.data.data[0]);
        })
        .catch(() => {
          toast.error("Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getTrendsData();
  }, [currentCompany]);

  return loading ? (
    <ContainerCard isLoading />
  ) : (
    <div>
      <InvestmentPlans keyInvestments={trendsData?.keyInvestments ?? []} />
      <div style={{ marginTop: "85px" }}>
        <PowerOfOne data={trendsData?.powerOfOne ?? {}} />
      </div>
      <div style={{ marginTop: "85px" }}>
        <ExpansionPlans expansions={trendsData?.exapnsions} />
      </div>
      <PeopleIntelligence data={trendsData} company={currentCompany} />
    </div>
  );
}
