import React, { useReducer, useState } from "react";
import { REST_BASE_ENDPOINT } from "../../../../constants";
import { useHistory } from "react-router-dom";
// componentes
import LoadingCube from "../../../Components/LoadingCube/LoadingCube";

// styles
import styles from "./ForgotPage.module.scss";
import cssStyles from "./Login.module.scss"
import backArrow from "../../../../assets/img/backArrow.png";

//libraries
import { MdMail } from "react-icons/md";
import axios from "axios";

// import { useSpinnerContext } from "../../../context/allContexts";

const forgotPageReducer = (state, action) => {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: true,
        error: "",
      };
    case "success":
      return {
        ...state,
        success: `
        <p style="text-align: center;">An Email has been sent to you. 
        <br/> 
        Please check your email to reset your password</p>`,
        error: "",
        loading: false,
      };
    case "error":
      return {
        ...state,
        error: action.payload,
        success: "",
        loading: false,
      };
    case "field":
      return {
        ...state,
        [action.field]: action.value,
      };
  }
  return state;
};

const initState = {
  email: "",
  error: "",
  success: "",
  loading: false,
};

const ForgotPasswordScreen = ({ changeForm }) => {
  // const { Loader } = useSpinnerContext();
  const [state, dispatch] = useReducer(forgotPageReducer, initState);
  const { email, error, success, loading } = state;
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  let history = useHistory();
  const handleSubmit = async (e) => {
    dispatch({ type: "loading" });
    try {
      e.preventDefault();
      const user = {
        email: email,
      };
      let res = await axios.post(
        `${REST_BASE_ENDPOINT}/auth/forgot-password`,
        // "https://app.nowigence.ai/auth/forgot-password",
        // "http://localhost:5000/auth/forgot-password",
        user
      );
      if (res.data.message === "success") {
        dispatch({ type: "success" });
      } else if (res.data.error) {
        dispatch({
          type: "error",
          payload: "Email doesn't exist in our database",
        });
      }
    } catch (err) {
      dispatch({
        type: "error",
        payload: err.response.status === 404 
          && err.response.data 
          && err.response.data.message === "User not found" 
          ? "Please check the email that you have entered." 
          : "Something went wrong please try again later.",
      });
    }
  };
  console.log(success)
  return (
    <>
     
     
      {loading && (
        <div className={styles.loadingContainer}>
          <LoadingCube height="15vw" width="15vw" theme="dark" />
        </div>
      )}
<div className={cssStyles.mobileLight}>
    <div className={cssStyles.imageMobile + ' h-screen'}>
 <div className="mb-2">
            <img
              className="cursor-pointer p-4"
              onClick={history.goBack}
              src={backArrow}
            />
          </div>
      <form onSubmit={(e) => handleSubmit(e)} className={styles.form + " my-auto relative top-1/4"}>
        <h4 className="p-4 text-lg">Enter your registered email address to receive a verification email</h4>
 {error && <p className={styles.errorMessage}>{error}</p>}
 {success && <p className={styles.successMessage}><div dangerouslySetInnerHTML={{__html: `${success}`}} /></p>}
      {!success && <>
        <div className={styles.inputDiv}>
          <MdMail className={styles.icon} />
          <input
            className={styles.input +
                          " bg-none my-1 rounded rounded-1 px-4 py-2 focus:transparent"}
            type="email"
            value={email}
            placeholder="Enter your email address"
            onFocus={(e) => e.target.placeholder = ""}
            onBlur={(e) => e.target.placeholder = "Enter your email address"}
            onChange={(e) => {
              dispatch({
                type: "field",
                field: "email",
                value: e.target.value,
              });
            }}
          />
        </div>
        <button
          type="submit"
        style={{
                      color: "white !important",
                      background: "#57b8ff",
fontSize: '15px'
                    }}
          disabled={!isValidEmail(email)}
          className={`${cssStyles.buttonLight + " p-2 w-20 my-3 rounded rounded-2 fw-bold text-dark"} ${
            isValidEmail(email) ? null : styles.disabled
          }`}>
          Submit
        </button>
      </>}
        <button className={styles.forgotBtn + " p-2 w-100 my-3 rounded rounded-2 fw-bold text-dark"} onClick={history.goBack}>
          Back to Login
        </button>
      </form>
    </div>
</div>
    </>
  );
};

export default ForgotPasswordScreen;
