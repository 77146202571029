import { createContext, Dispatch, useContext, useReducer } from "react";
import { SavedFilter } from "../../../../codeGenFE";

const initState: State = {
  toggleSaveInput: false,
  filterNameInput: "",
  // toggleOverwriteModal: false,
  duplicateFilter: null,
  saveErrorMsg: "",
  saveErrorRetry: null,
  filterToEdit: null,
};

export const SaveFiltersContext = createContext<{
  saveFilterState: State;
  SaveFiltersDispatch: Dispatch<Actions>;
}>({ saveFilterState: initState, SaveFiltersDispatch: () => null });

interface State {
  toggleSaveInput: boolean;
  filterNameInput: string;
  // toggleOverwriteModal: boolean;
  duplicateFilter: SavedFilter | null;
  saveErrorMsg: string;
  saveErrorRetry: null | (() => void);
  filterToEdit: SavedFilter | null;
}

type Actions =
  | { type: "toggle save input"; payload: boolean }
  | { type: "filter name input"; payload: string }
  // | { type: "toggle overwrite modal"; payload: boolean }
  | { type: "set duplicate filter"; filter: SavedFilter | null }
  | { type: "clear" }
  | { type: "change filter to edit"; payload: SavedFilter | null }
  | { type: "save error"; msg: string; retry: null | (() => void) };

const saveFiltersReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "toggle save input":
      return {
        ...state,
        toggleSaveInput: action.payload,
      };
    case "filter name input":
      return {
        ...state,
        filterNameInput: action.payload,
      };
    // case "toggle overwrite modal":
    //   return {
    //     ...state,
    //     toggleOverwriteModal: action.payload,
    //   };
    case "change filter to edit":
      return {
        ...state,
        filterToEdit: action.payload,
      };
    case "set duplicate filter":
      return {
        ...state,
        duplicateFilter: action.filter,
        filterToEdit: null,
        saveErrorMsg: "",
        saveErrorRetry: null,
      };

    case "save error":
      return {
        ...state,
        saveErrorMsg: action.msg,
        saveErrorRetry: action.retry,
      };

    case "clear":
      return initState;

    default:
      return state;
  }
};

export function SaveFiltersProvider(props: any) {
  const [saveFilterState, SaveFiltersDispatch] = useReducer(
    saveFiltersReducer,
    initState
  );

  return (
    <SaveFiltersContext.Provider
      value={{ saveFilterState, SaveFiltersDispatch }}
    >
      {props.children}
    </SaveFiltersContext.Provider>
  );
}

interface SaveFiltersContextStuff {
  saveFilterState: State;
  SaveFiltersDispatch: Dispatch<Actions>;
}

export const useSaveFiltersContext = (): SaveFiltersContextStuff =>
  useContext(SaveFiltersContext);
