import { useMemo } from "react"

const range = (start, end) => {
    let length = end - start + 1;
    /*
        Create an array of certain length and set the elements within it from
      start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
    totalPage,
    pageSize,
    siblingCount = 1,
    pageNumber
}) => {
    const paginationRange = useMemo(() => {
        const totalPageNumbers = siblingCount + 5;
        /*
            Case 1:
            If the number of pages is less than the page numbers we want to show in our
            paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPage) {
            return range(1, totalPage);
        }
        /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex = Math.max(pageNumber - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            pageNumber + siblingCount,
            totalPage
        );
        /*
            We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPage - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPage;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);
      
            return [...leftRange, 'DOTS', totalPage];
        }
        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {
        
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
            totalPage - rightItemCount + 1,
            totalPage
            );
            return [firstPageIndex, 'DOTS', ...rightRange];
        }
        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, 'DOTS', ...middleRange, 'DOTS', lastPageIndex];
        }
    }, [totalPage, pageNumber, siblingCount, pageSize])
    return paginationRange
}