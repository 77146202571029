import React, { Dispatch, FC } from "react";
import {
  ACDisplayTagsActionChoices,
  ACDisplayTagsActions,
} from "../ACDisplayTags";
import { ACDisplayTagsEditInput } from "../ACDisplayTagsEditInput";

import styles from "../ACDisplayTags.module.scss";

interface Props {
  editTagsList: string[];
  dispatcher: Dispatch<ACDisplayTagsActions>;
  editByKeyPress: (status: boolean) => void;
}

const EditTags: FC<Props> = ({ editTagsList, dispatcher, editByKeyPress }) => {
  return (
    <ul className={styles.tags__UL}>
      {editTagsList &&
        editTagsList.map((t: any, idx: number) => (
          <ACDisplayTagsEditInput
            key={idx}
            t={t}
            editByKeyPress={editByKeyPress}
            updateEditTagInputArray={(newTag) => {
              dispatcher({
                type: ACDisplayTagsActionChoices.CHANGE_EDIT_TAGS_LIST,
                tags: editTagsList.map((t, i) => (i === idx ? newTag : t)),
              });
            }}
          />
        ))}
    </ul>
  );
};

export default EditTags;
