import React from "react";

import styles from "./DisplaySetupTopicOrSharedBy.module.scss";

import { useUserContext } from "../../../../context/allContexts";

const DisplaySetupTopicOrSharedBy = ({ keywordList, pubStatus, sharedBy, userMetaData }) => {
  const { user } = useUserContext();
  const displaySetupTopicOrSharedBy = (info, status, sharedBy, userMetaData, user) => {
    // console.log('info', info)
    // console.log('status', status)
    // console.log("user: ", user)
    if(userMetaData) {
      userMetaData.forEach((u) => {
        if(u && u.userId === user._id) {
          if(u.sharedBy && u.sharedBy.length > 0 && u.sharedBy[0].name) {
            status = 'shared'
            sharedBy = u.sharedBy[0].name
          }
        }
      })
    }
    switch (status) {
      // if shared display Shared By: person sharing
      case "shared":
        return (
          <>
            <span className={styles.pipe}>|</span>
            <span className={styles.text}>Shared By: {sharedBy}</span>
          </>
        );
      // if news display how topic relates to user.
      case "public":
        return (
          <>
            <span className={styles.pipe}>|</span>
            <span className={styles.text}>Setup Topic: {info[user._id]}</span>
          </>
        );
      case "private":
        return null;
      default:
        return null;
    }
  };
  return displaySetupTopicOrSharedBy(keywordList, pubStatus, sharedBy, userMetaData, user);
};

export default DisplaySetupTopicOrSharedBy;
