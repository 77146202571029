// components
import { createContext, Dispatch, FC, useContext, useReducer } from "react";
import { ArticlesInputs, ArticleSource } from "../../../../codeGenFE";
import {
  SearchContextAction,
  SearchContextActions,
  SearchContextState,
} from "./SearchContextTypes";

const searchReducer = (
  state: SearchContextState,
  action: SearchContextAction
): SearchContextState => {
  switch (action.type) {
    case SearchContextActions.CHANGE_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      };
    case SearchContextActions.CLEAR_SEARCH:
      return {
        ...state,
        searchString: null,
        relSearchParams: null,
        tagFilterChoice: null,
        dataSourceChoice: null,
        searchTopicChoice: null,
        categoryChoice: null,
        driverChoice: null,
        outcomeChoice: null,
        countryChoice: null,
        currentActiveFilter: null,
        otherLocation: null,
        org: null,
        person: null,
        product: null,
      };

    case SearchContextActions.SET_RELATIONSHIP_SEARCH_PARAMETER:
      return {
        ...state,
        currentActiveFilter: null,
        relSearchParams: action.payload,
      };

    case SearchContextActions.SET_TAG_SEARCH_PARAMETER:
      return {
        ...state,
        currentActiveFilter: null,
        tagFilterChoice: action.payload,
      };

    case SearchContextActions.CHANGE_DATA_SOURCE:
      return {
        ...state,
        currentActiveFilter: null,
        dataSourceChoice: action.payload,
      };

    case SearchContextActions.SET_TOPIC:
      return {
        ...state,
        currentActiveFilter: null,
        searchTopicChoice: action.payload,
      };

    case SearchContextActions.SET_CATEGORY_CHOICE:
      return {
        ...state,
        currentActiveFilter: null,
        categoryChoice: action.category,
      };

    case SearchContextActions.SET_DRIVER_CHOICE:
      return {
        ...state,
        currentActiveFilter: null,
        driverChoice: action.driver,
      };

    case SearchContextActions.SET_OUTCOME_CHOICE:
      return {
        ...state,
        currentActiveFilter: null,
        outcomeChoice: action.outcome,
      };

    case SearchContextActions.SET_COUNTRY_CHOICE:
      return {
        ...state,
        currentActiveFilter: null,
        countryChoice: action.country,
      };

    case SearchContextActions.SET_OTHER_LOCATION:
      return {
        ...state,
        currentActiveFilter: null,
        otherLocation: action.location,
      };

    case SearchContextActions.SET_ORG:
      return {
        ...state,
        currentActiveFilter: null,
        org: action.org,
      };

    case SearchContextActions.SET_PERSON:
      return {
        ...state,
        currentActiveFilter: null,
        person: action.person,
      };

    case SearchContextActions.SET_PRODUCT:
      return {
        ...state,
        currentActiveFilter: null,
        product: action.product,
      };

    case SearchContextActions.LOAD_SAVED_FILTER_SET:
      return {
        ...state,
        searchString: null,
        currentActiveFilter: action.filters._id,
        tagFilterChoice: action.filters.tag ? action.filters.tag : null,
        dataSourceChoice: action.filters.dataSource
          ? (action.filters.dataSource as ArticleSource)
          : null,
        searchTopicChoice: action.filters.topics ? action.filters.topics : null,
        categoryChoice: action.filters.category
          ? action.filters.category
          : null,
        driverChoice: action.filters.driver ? action.filters.driver : null,
        outcomeChoice: action.filters.outcome ? action.filters.outcome : null,
        countryChoice: action.filters.country ? action.filters.country : null,
        relSearchParams: action.filters?.relationshipType
          ? {
              category: action.filters.relationshipType!,
              relationship: action.filters.relationshipText!,
            }
          : null,
        otherLocation: action.filters.otherLocation
          ? action.filters.otherLocation
          : null,
        org: action.filters.org ? action.filters.org : null,
        person: action.filters.person ? action.filters.person : null,
        product: action.filters.product ? action.filters.product : null,
      };

    case SearchContextActions.RESET_FILTERS:
      return {
        ...initState
      };
    case SearchContextActions.LOGOUT:
      return {
        ...initState,
      };

    default:
      return {
        ...state,
      };
  }
};

const initState: SearchContextState = {
  searchString: null,
  relSearchParams: null,
  tagFilterChoice: null,
  dataSourceChoice: null,
  searchTopicChoice: null,
  categoryChoice: null,
  driverChoice: null,
  outcomeChoice: null,
  countryChoice: null,
  currentActiveFilter: null,
  otherLocation: null,
  org: null,
  person: null,
  product: null,
};

export const SearchContext = createContext<{
  searchState: SearchContextState;
  searchDispatcher: Dispatch<SearchContextAction>;
  searchInputOpts: ArticlesInputs;
  calcFiltersApplied: () => number;
}>({
  searchState: initState,
  searchDispatcher: () => null,
  searchInputOpts: {},
  calcFiltersApplied: () => 0,
});

export const SearchProvider: FC = ({ children }) => {
  const [searchState, searchDispatcher] = useReducer(searchReducer, initState);

  // This marries the front and backend naming conventions for all search filter arguments for graphql filter queries.  This is for ease of management.
  // !Note: if you change anything on the left side and don't change the corrisponding graphql key value, THIS WILL BREAK SEARCH FILTERS EVERYWHERE.  It's located in projectRoot/graphql/searchFilters & projectRoot/graphql/articleData/index.graphql => ArticlesInputs, RisksAndOppsInputs, TopFiveRisksAndOppsInputs and ArticleMetaInputs
  const searchInputOpts: ArticlesInputs = {
    searchStr: searchState.searchString,
    relationshipCategory: searchState.relSearchParams?.category,
    relationshipValue: searchState.relSearchParams?.relationship,
    tag: searchState.tagFilterChoice,
    articleSource: searchState.dataSourceChoice,
    topic: searchState.searchTopicChoice,
    category: searchState.categoryChoice,
    driver: searchState.driverChoice,
    outcome: searchState.outcomeChoice,
    country: searchState.countryChoice,
    otherLocation: searchState.otherLocation,
    person: searchState.person,
    product: searchState.product,
    org: searchState.org,
  };

  const calcFiltersApplied = (): number => {
    const modifiedPosFilters = { ...searchInputOpts };
    delete modifiedPosFilters?.relationshipValue;
    const possibleFilters = Object.values(modifiedPosFilters);

    let count = 0;
    possibleFilters.forEach((t) => {
      if (t) count++;
    });
    return count;
  };

  return (
    <SearchContext.Provider
      value={{
        searchState,
        searchInputOpts,
        searchDispatcher,
        calcFiltersApplied,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

interface SearchContextStuff {
  searchState: SearchContextState;
  searchDispatcher: Dispatch<SearchContextAction>;
  searchInputOpts: ArticlesInputs;
  calcFiltersApplied: () => number;
}

export const useSearchContext = (): SearchContextStuff =>
  useContext(SearchContext);
