import React, { FC } from "react";
import { WhichDb } from "../../../../../../codeGenFE";
import { customErrorHandler } from "../../../../../../utils/customErrorHandler";

import styles from "../ACDisplayTags.module.scss";

interface Props {
  error: string;
  retry?: () => void;
  cancel: any;
}

const TagActionErrorMsg: FC<Props> = ({ error, retry, cancel }) => {
  return (
    <ul className={styles.tags__UL}>
      <p className={styles.errorMsg}>{error}</p>
      <div className={styles.btnWrapper}>
        {retry && (
          <button className={styles.deleteTagsConfirm} onClick={retry}>
            Retry
          </button>
        )}
        <button className={styles.cancelTagsConfirm} onClick={cancel}>
          {retry ? "Cancel" : "Okay"}
        </button>
      </div>
    </ul>
  );
};

export default TagActionErrorMsg;
