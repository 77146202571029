import { FC } from "react";
import styles from "./SaveFilterErrorModal.module.scss";
// import { useSearchContext } from "../../../../../context/allContexts";
import { useSaveFiltersContext } from "../../SaveFiltersContext";
// import {
//   useSaveFilterMutation,
//   useUpdateFilterMutation,
// } from "../../../../../../codeGenFE";
// import { useQueryClient } from "react-query";

import { AiOutlineWarning } from "react-icons/ai";

const SaveFilterErrorModal: FC = () => {
  // const { searchState } = useSearchContext();
  // @ts-ignore
  const { saveFilterState, SaveFiltersDispatch } = useSaveFiltersContext();
  const { saveErrorRetry } = saveFilterState;

  // Save functionality
  // const qClient = useQueryClient();

  // const {
  //   mutate: saveFilters,
  //   status: saveFiltersStatus,
  //   error: saveFiltersError,
  // } = useSaveFilterMutation({
  //   onSuccess: () => {
  //     SaveFiltersDispatch({ type: "toggle save input", payload: false });
  //     qClient.invalidateQueries("getUserSavedFilters");
  //   },
  // });

  // const { mutate, status, error } = useUpdateFilterMutation({
  //   onSuccess: (data) => {
  //     SaveFiltersDispatch({ type: "clear" });
  //     qClient.invalidateQueries("getUserSavedFilters");
  //   },
  // });

  function cancel() {
    SaveFiltersDispatch({
      type: "set duplicate filter",
      filter: null,
    });
  }

  return (
    <div className={styles.overWriteModalWrapper}>
      <AiOutlineWarning className={styles.warningIcon} />
      <p className={styles.warningContent}>
        Filter failed to save. Would you like to try again?
      </p>
      <div className={styles.btnWrapper}>
        <button className={styles.cancelBtn} onClick={cancel}>
          cancel
        </button>
        <button
          className={styles.replaceBtn}
          onClick={() => saveErrorRetry && saveErrorRetry()}>
          Retry
        </button>
      </div>
    </div>
  );
};

export default SaveFilterErrorModal;
