import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CloseModalBtn from "../../../Components/CloseModalBtn/CloseModalBtn";

import styles from "./askMeInfoModal2.module.scss";

const AskMeInfoModal2 = ({ close, showAskMeInfoModal }) => {
  const [shouldRender, setShouldRender] = useState(showAskMeInfoModal);

  useEffect(() => {
    if (showAskMeInfoModal) setShouldRender(true);
  }, [showAskMeInfoModal]);

  const onAnimationEnd = () => {
    if (!showAskMeInfoModal) setShouldRender(false);
  };

  return (
    shouldRender && (
      <React.Fragment>
        <div
          onAnimationEnd={onAnimationEnd}
          className={`${styles.backdrop} ${
            showAskMeInfoModal ? styles.backdrop : styles.fadeOut
          }`}
          onClick={() => close()}
        />
        <div
          className={`${styles.askMeInfoModalWrapper} ${
            showAskMeInfoModal ? styles.askMeInfoModalWrapper : styles.slideUp
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.closeBtn}>
            <CloseModalBtn theme="light" close={close} />
          </div>
          <h2>Ask Me Topic Suggestions</h2>
          <p>
            Below are some suggested topics that we have found. You may add
            these topics to your{" "}
            <Link to="/userPreference" className={styles.AMIM2__link}>
              Topic Monitoring
            </Link>{" "}
            by clicking the plus button.
          </p>
        </div>
      </React.Fragment>
    )
  );
};

export default AskMeInfoModal2;
