import { REST_BASE_ENDPOINT } from "../constants";
import { useMutation } from "react-query";
import { axios } from "../utils/axiosConfig";

interface LoginInput {
  email: string;
  password: string;
}

export function useLogin(opts?: any) {
  return useMutation(async (loginInput: LoginInput) => {
    try {
      const { data } = await axios.post(
        `${REST_BASE_ENDPOINT}/auth/login`,
        loginInput
      );
      if (data.data) {
        return data.data;
      }
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, opts);
}
