import { useInfiniteQuery } from "react-query";
import { GQL_ENDPOINT } from "../constants";
import { axios } from "../utils/axiosConfig";

// async function fetchArticlesFunc({ pageParam }: any, searchVariables: any) {
async function fetchArticlesFunc({ pageParam }: any, searchVariables: any) {
  try {
    let pfSkip = pageParam && pageParam.pfSkip ? pageParam.pfSkip : searchVariables.pfSkip ? searchVariables.pfSkip : 0
    let pv2Skip = pageParam && pageParam.pv2Skip ? pageParam.pv2Skip : searchVariables.pv2Skip ? searchVariables.pv2Skip : 0
    if(pageParam && pageParam.pfSkip)
      pfSkip = pageParam.pfSkip
    else if(searchVariables.pfSkip)
      pfSkip = searchVariables.pfSkip
    else  
      pfSkip = 0

    if(pageParam && pageParam.pv2Skip)
      pv2Skip = pageParam.pv2Skip
    else if(searchVariables.pv2Skip)
      pv2Skip = searchVariables.pv2Skip
    else  
      pv2Skip = 0


    let limit = 25
    if('isReloaded' in searchVariables) delete searchVariables.isReloaded
    const updatedSearchedVariables = {
      searchStr: searchVariables.searchString,
      relationshipCategory: searchVariables.relSearchParams?.category,
      relationshipValue: searchVariables.relSearchParams?.relationship,
      tag: searchVariables.tagFilterChoice,
      articleSource: searchVariables.dataSourceChoice,
      topic: searchVariables.searchTopicChoice,
      category: searchVariables.categoryChoice,
      driver: searchVariables.driverChoice,
      outcome: searchVariables.outcomeChoice,
      country: searchVariables.countryChoice,
      otherLocation: searchVariables.otherLocation,
      org: searchVariables.org,
      person: searchVariables.person,
      product: searchVariables.product,
    }
    const initVars = {
      inputOpts: {...searchVariables,  pv2Skip: pv2Skip ? pv2Skip : 0, pfSkip: pfSkip ? pfSkip : 0, limit },
    };
    // 'isReloaded' in searchVariables ? delete searchVariables.isReloaded : searchVariables
    const variables = pageParam
      ? { inputOpts: {...searchVariables, pfSkip, pv2Skip, limit} }
      : initVars;
    const query = `
    query fetch25Articles($inputOpts: ArticleMetaInputs!) {
      fetch25Articles(inputOpts: $inputOpts) {
        pfSkip
        pv2Skip
        articles {
          _id
          proImageLink
          pubStatus
          _source {
            title
            harvestDate
            content
            sortDate
            uploadDate
            orig_url
            summary
            source {
              name
            }
          }
          userMetaData {
            userId
            permanent
            date
            sharedBy {
              userId
              name
            }
          }
          keywordList
          sharedBy
          whichDB
        }
      }
    }
    `;
    const data = await axios.post(GQL_ENDPOINT, {
      query,
      variables,
      body: JSON.stringify({ query, variables }),
    });

    if (!data?.data?.data?.fetch25Articles) {
      // console.log("error from myMem Infinite Articles :>> ", data);
      throw data?.data?.errors?.[0]?.message
        ? data?.data?.errors?.[0]?.message
        : "Something went wrong";
    }
    if (data) {
      // console.log("data from fetch myMemory :>> ", data.data.data);
      return data.data.data.fetch25Articles;
    }
    return null
  } catch (err) {
    console.log("err from fetchArticlesFunc", err);
    throw err;
  }
}

export const useMyMemoryInfiniteArticles = (
  searchVariables: any,
  options?: any
): any => {
  const upgradedOptions = {
    ...options,
    getNextPageParam: (lastPage: any, pages: any) => {
      // if (searchVariables. pages && pages[pages?.length - 1].articles?.length < 25)
      //   return undefined;
      let pfSkip = searchVariables.pfSkip !== null ? searchVariables.pfSkip : lastPage.pfSkip
      let pv2Skip = searchVariables.pv2Skip !== null ? searchVariables.pv2Skip : lastPage.pv2Skip
      return {
        pfSkip: pfSkip,
        pv2Skip: pv2Skip,
      };
    }
  };
  if('isReloaded' in searchVariables && searchVariables['isReloaded'] && localStorage.getItem("pfSkip") && localStorage.getItem("pv2Skip")) {
    searchVariables.pfSkip = parseInt(localStorage.getItem("pfSkip") || '0')
    searchVariables.pv2Skip = parseInt(localStorage.getItem("pv2Skip") || '0')
    // console.log(searchVariables.filesSkip, searchVariables.v2Skip, "fetch")
    // limit = parseInt(localStorage.getItem("pfSkip") || '0') + parseInt(localStorage.getItem("pv2Skip") || '0') 
  }
  return useInfiniteQuery(
    "myMemoryInfinite",
    (pageParams) => {
      return fetchArticlesFunc(pageParams, searchVariables)
    },
    upgradedOptions
  );
};
