import React, { FC, useState } from "react";
// icons
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
// types
import { RelationText, RelationType } from "../QuickUploadTypes";
// styles
import styles from "./QURelationDropdown.module.scss";

// context
// import { useUserProfileContext } from "../../../../context/allContexts";

type ChangeTextAndTypeArgs = {
  relText: RelationText;
  relType: RelationType;
};

interface QURelationDropdownProps {
  close: () => void;
  reset: () => void;
  changeRelationTextandType: (arg0: ChangeTextAndTypeArgs) => void;
}

const QURelationDropdown: FC<QURelationDropdownProps> = ({
  close,
  reset,
  changeRelationTextandType,
}) => {
  // const { reset, setRelationTextandType } = useUserProfileContext();
  const [subDropDown, setSubDropDown] = useState("");

  const toggleSubDropDown = (sub: string) => {
    if (sub === subDropDown) {
      setSubDropDown("");
    } else {
      setSubDropDown(sub);
    }
  };

  return (
    <div className={styles.quRelationDropdown__wrapper}>
      <li
        className={styles.quRelationDropdown__reset}
        onClick={() => {
          reset();
        }}
      >
        Reset
      </li>
      <li
        className={styles.quRelationDropdown__selectionLI}
        onClick={() => toggleSubDropDown("companySubDropDown")}
      >
        Company
        <span className={styles.iconSpan}>
          {subDropDown !== "companySubDropDown" ? (
            <IoMdArrowDropdown className={styles.icon} />
          ) : (
            <IoMdArrowDropup className={styles.icon} />
          )}
        </span>
      </li>
      {subDropDown === "companySubDropDown" && (
        <div className={styles.quRelationDropdown__company__dropDown}>
          <li
            className={styles.company__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.CUSTOMER,
                relType: RelationType.COMPANY,
              });
            }}
          >
            Customer
          </li>
          <li
            className={styles.company__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.COMPETITOR,
                relType: RelationType.COMPANY,
              });
            }}
          >
            Competitor
          </li>
          <li
            className={styles.company__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.SUPPLIER,
                relType: RelationType.COMPANY,
              });
            }}
          >
            Supplier
          </li>
          <li
            className={styles.company__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.PROSPECT,
                relType: RelationType.COMPANY,
              });
            }}
          >
            Prospect
          </li>
          <li
            className={styles.company__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.OTHER,
                relType: RelationType.COMPANY,
              });
            }}
          >
            Other
          </li>
        </div>
      )}
      <li
        className={styles.quRelationDropdown__selectionLI}
        onClick={() => toggleSubDropDown("industrySubDropDown")}
      >
        Industry
        <span className={styles.iconSpan}>
          {subDropDown !== "industrySubDropDown" ? (
            <IoMdArrowDropdown className={styles.icon} />
          ) : (
            <IoMdArrowDropup className={styles.icon} />
          )}
        </span>
      </li>
      {subDropDown === "industrySubDropDown" && (
        <div className={styles.industryDropDownWrapper}>
          <li
            className={styles.industry__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.I_COMPETE_WITHIN_THIS_INDUSTRY,
                relType: RelationType.INDUSTRY,
              });
            }}
          >
            I compete within this industry
          </li>
          <li
            className={styles.industry__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.I_SELL_INTO_THIS_INDUSTRY,
                relType: RelationType.INDUSTRY,
              });
            }}
          >
            I sell into this industry
          </li>
          <li
            className={styles.industry__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.I_BUY_FROM_THIS_INDUSTRY,
                relType: RelationType.INDUSTRY,
              });
            }}
          >
            I buy from this industry
          </li>
          <li
            className={styles.industry__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.THIS_IS_AN_ADJACENT_INDUSTRY,
                relType: RelationType.INDUSTRY,
              });
            }}
          >
            This is an adjacent industry
          </li>
          <li
            className={styles.industry__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.OTHER,
                relType: RelationType.INDUSTRY,
              });
            }}
          >
            Other
          </li>
        </div>
      )}
      <li
        className={styles.quRelationDropdown__selectionLI}
        onClick={() => toggleSubDropDown("productSubDropDown")}
      >
        Product
        <span className={styles.iconSpan}>
          {subDropDown !== "productSubDropDown" ? (
            <IoMdArrowDropdown className={styles.icon} />
          ) : (
            <IoMdArrowDropup className={styles.icon} />
          )}
        </span>
      </li>
      {subDropDown === "productSubDropDown" && (
        <>
          <li
            className={styles.product__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.I_BUY_THIS_PRODUCT,
                relType: RelationType.PRODUCT,
              });
            }}
          >
            I buy this product
          </li>
          <li
            className={styles.product__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.I_SELL_THIS_PRODUCT,
                relType: RelationType.PRODUCT,
              });
            }}
          >
            I sell this product
          </li>
          <li
            className={styles.product__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.THIS_IS_A_SUBSTITUTE_PRODUCT,
                relType: RelationType.PRODUCT,
              });
            }}
          >
            This is a substitute product
          </li>
          <li
            className={styles.product__selection__li}
            onClick={() => {
              changeRelationTextandType({
                relText: RelationText.OTHER,
                relType: RelationType.PRODUCT,
              });
            }}
          >
            Other
          </li>
        </>
      )}
      <li
        className={styles.other__selection__li}
        onClick={() => {
          changeRelationTextandType({
            relText: RelationText.SELF,
            relType: RelationType.SELF,
          });
        }}
      >
        Self
      </li>
      <li
        className={styles.other__selection__li}
        onClick={() => {
          changeRelationTextandType({
            relText: RelationText.OTHER,
            relType: RelationType.OTHER,
          });
        }}
      >
        Other
      </li>
    </div>
  );
};

export default QURelationDropdown;
