import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useNavContext } from "../../../context/allContexts";
import stylesSucc from "./QuickUploadSuccessModal.module.scss";
import stylesErr from "./QuickUploadErrorModal.module.scss";
import stylesWarn from "./QuickUploadWarningModal.module.scss";

interface Props {
  success?: boolean;
  warning?: boolean;
  error?: string | boolean;
  close: () => void;
}

const QuickUploadMessagingModal: FC<Props> = ({
  close,
  success,
  warning,
  error,
}) => {
  const { navDispatch } = useNavContext();
  const location = useLocation();

  const closeFunc = () => {
    close();
    if (!error) {
      navDispatch({ type: "change which menu", menu: "" });
    }
  };

  if (success) {
    return (
      <div className={stylesSucc.modalWrapper}>
        <AiOutlineCheck className={stylesSucc.iconCheck} />
        {/*<p className={stylesSucc.modal_p}>YOU HAVE UPLOADED IT SUCCESSFULLY!</p>*/}
        <p className={stylesSucc.modal_p}>
          Information sent to pluaris robot... <br />
          Processing now! Multi-page files will take longer for the processing to be completed.
        </p>
        {location.pathname !== "/my-memory/reader" && (
          <Link
            className={stylesSucc.myMemoryLink}
            to="/my-memory/reader"
            onClick={() => closeFunc()}>
            Go to Reader
          </Link>
        )}
        <button onClick={() => closeFunc()}>OKAY</button>
      </div>
    );
  }

  if (warning) {
    return (
      <div className={stylesWarn.modalWrapper}>
        <AiOutlineClose className={stylesWarn.iconX} />
        <p className={stylesWarn.modal_p}>
          YOU'RE ABOUT TO RESET EVERYTHING AND CLOSE THIS WINDOW.
        </p>
        <div className={stylesWarn.warningBtnContainer}>
          <button onClick={() => closeFunc()}>OKAY</button>
          <button className={stylesWarn.cancelBtn} onClick={() => close()}>
            Cancel
          </button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={stylesErr.modalWrapper}>
        <AiOutlineClose className={stylesErr.iconX} />
        <p className={stylesErr.modal_p}>{error}</p>
        <div className={stylesErr.warningBtnContainer}>
          <button onClick={() => closeFunc()}>OKAY</button>
        </div>
      </div>
    );
  }

  return null;
};

export default QuickUploadMessagingModal;
