import { REST_BASE_ENDPOINT } from "../constants";
import { useMutation } from "react-query";
import { axios } from "../utils/axiosConfig";

export function useLogout(options: any) {
  return useMutation(async () => {
    try {
      const { data } = await axios.post(`${REST_BASE_ENDPOINT}/auth/logout`);
      // console.log("data from useLogout", data);
      if (data.data) {
        return data.data;
      }
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, options);
}
