import axios from "axios";
import { useMutation } from "react-query";
import { PYTHON_REST_BASE_ENDPOINT } from "../../constants";
// axiosConfig axios is for protected routes to node backend. python has none of
// this implemented so can't use custom axios have to use default axios
// import { axios } from "../utils/axiosConfig";

interface TextUploadInput {
  title: string;
  content: string;
  company_id: string;
  user_id: string;
  share_all?: boolean;
  share_to?: string[];
  tags?: string[];
  user_relationship_type?: string;
  user_relationship_value?: string;
}

export function useTextUpload(options?: any) {
  // console.log("running useTextUpload");

  return useMutation(async (textUploadInput: TextUploadInput) => {
    try {
      const data = textUploadInput;
      const { data: resData } = await axios.post(
        `${PYTHON_REST_BASE_ENDPOINT}/quick_upload/text`,
        data
      );
      if (resData) {
        // console.log("resData from python text upload: ", resData);
        return resData;
      }
    } catch (err) {
      console.log("err from text upload python: ", err);
      throw new Error(err.response.data.message);
    }
  }, options);
}
