import React, { useState, useEffect } from "react";
import CloseModalBtn from "../../../Components/CloseModalBtn/CloseModalBtn";

import styles from "./askMeInfoModal.module.scss";

const AskMeInfoModal = ({ close, showAskMeInfoModal }) => {
  const [shouldRender, setShouldRender] = useState(showAskMeInfoModal);

  useEffect(() => {
    if (showAskMeInfoModal) setShouldRender(true);
  }, [showAskMeInfoModal]);

  const onAnimationEnd = () => {
    if (!showAskMeInfoModal) setShouldRender(false);
  };

  return (
    shouldRender && (
      <React.Fragment>
        <div
          onAnimationEnd={onAnimationEnd}
          className={`${styles.backdrop} ${
            showAskMeInfoModal ? styles.backdrop : styles.fadeOut
          }`}
          onClick={() => close()}
        />
        <div
          className={`${styles.askMeInfoModalWrapper} ${
            showAskMeInfoModal ? styles.askMeInfoModalWrapper : styles.slideUp
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.closeBtn}>
            <CloseModalBtn theme="light" close={close} />
          </div>
          <h2>Ask Me</h2>
          <p>
            Pluaris gives you the power to ask questions against your data, like
            an “Internal Google”. For most accurate results, please ask specific
            detailed questions.
          </p>
          <div>
            <ul>
              <li>
                Good Example: What was Amazon’s reported net income for 2018?
              </li>
              <li>Bad Example: What is Amazon doing?</li>
              <li>Good Example: Who won the gold for India in commonwealth games?</li>
              <li>Bad Example: Indian goldmedalist</li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default AskMeInfoModal;
