import React, { useEffect, useReducer } from "react";
import { dispatch } from "react-hot-toast/dist/core/store";
import {
  Type,
  useShareMultipleArticlesMutationMutation,
  useUserRewardLogsMutation,
  useUserRewardsMutation,
  WhichDb,
} from "../../../../codeGenFE";
import {
  MainDocument,
  OtherDocument,
  AskMeResult,
} from "../../../../rq-hooks/pythonRestApi/useAskMe";
import { useURLUpload } from "../../../../rq-hooks/pythonRestApi/useURLUpload";
import { customErrorHandler } from "../../../../utils/customErrorHandler";
import ComponentMessagingOverlay, {
  CmoTheme,
} from "../../../Components/ComponentMessagingOverlay/ComponentMessagingOverlay";
import RelationDropdown from "../../../Components/RelationDropdown/RelationDropdown";
// Components
import ShareList, {
  ShareListTheme,
} from "../../../Components/ShareList/ShareList";
// Context
import {
  useCompanyUploadsContext,
  usePrivateUploadsContext,
  useRewardsContext,
  useUserContext,
} from "../../../context/allContexts";
// httpRequests
import {
  getArticleById,
  shareArticle,
  uploadContent,
} from "../../../util/httpRequests";
import AArticle from "./AArticle/AArticle";
import AFooter from "./AFooter/AFooter";
import ANoResults from "./ANoResults/ANoResults";
// import AnswerKPND from "./AnswerKPND/AnswerKPND";
// Styles
import styles from "./Answer.module.scss";
import ErrorAnswer from "./ErrorAnswer/ErrorAnswer";
import LoadingAnswer from "./LoadingAnswer/LoadingAnswer";
import SuccessAnswer from "./SuccessAnswer/SuccessAnswer";

interface State {
  loading: boolean;
  success: boolean;
  error: boolean;
  errorData: any; // TODO: Fix or delete
  message: null | string;
  showShare: boolean;
  shareArticles: boolean;
  addToMemory: boolean;
  shareAll: boolean;
  selectedUsers: [] | string[];
  selectedArticles: [] | string[];
  relationshipText: null | string;
  relationshipType: null | string;
  disableBtn: boolean;
  showKeypointsNDrivers: string | boolean;
}

type relationshipObj = { text: string; type: string };

export type AnswerActions =
  | { type: "enable" }
  | { type: "disable" }
  | { type: "show share" }
  | { type: "close share" }
  | { type: "(de)select users"; payload: [] | string[] }
  | { type: "toggle share all"; payload: boolean }
  | { type: "select article"; payload: string }
  | { type: "selectAllArticles"; payload: [] | string[] }
  | { type: "removeAllSelectedArticles" }
  | { type: "set relationship"; payload: { text: string; type: string } }
  | { type: "reset" }
  | { type: "show KPND"; id: string }
  | { type: "hide KPND" }
  | { type: "share articles"; status: boolean }
  | { type: "add to memory" };

const answerReducer = (state: State, action: AnswerActions): State => {
  switch (action.type) {
    // disable btn enable and disable
    case "enable":
      return {
        ...state,
        disableBtn: false,
      };
    case "disable":
      return {
        ...state,
        disableBtn: true,
      };
    // display or hide share menu
    case "show share":
      return {
        ...state,
        showShare: true,
      };
    case "share articles":
      return {
        ...state,
        shareArticles: action.status,
      };  
    case "add to memory":
      return {
        ...state,
        addToMemory: true,
      };  
    case "close share":
      return {
        ...state,
        showShare: false,
        shareAll: false,
        selectedUsers: [],
        disableBtn: true,
        // tags: [],
      };

    // selecting and unselecting who to share with
    case "(de)select users":
      return {
        ...state,
        selectedUsers: action.payload,
      };
    case "toggle share all":
      return {
        ...state,
        shareAll: action.payload,
      };
    // add or remove articles to be shared
    case "select article":
      // @ts-ignore
      if (state.selectedArticles.includes(action.payload)) {
        return {
          ...state,
          selectedArticles: state.selectedArticles.filter(
            (id) => id !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          selectedArticles: [...state.selectedArticles, action.payload],
        };
      }

    case "selectAllArticles":
      return {
        ...state,
        selectedArticles: action.payload,
      };
    case "removeAllSelectedArticles":
      return {
        ...state,
        selectedArticles: [],
      };
    case "set relationship":
      return {
        ...state,

        relationshipText: action.payload.text,
        relationshipType: action.payload.type,
      };
    case "reset":
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
        errorData: null,
        message: null,
        showShare: false,
        shareAll: false,
        selectedUsers: [],
        selectedArticles: [],
        relationshipText: null,
        relationshipType: null,
        disableBtn: true,
        showKeypointsNDrivers: false,
        addToMemory: false,
      };
    case "show KPND":
      return {
        ...state,
        showKeypointsNDrivers: action.id,
      };
    case "hide KPND":
      return {
        ...state,
        showKeypointsNDrivers: false,
      };
  }
  return state;
};

const initState: State = {
  loading: false,
  success: false,
  error: false,
  errorData: null,
  message: null,
  showShare: false,
  shareAll: false,
  shareArticles: false,
  selectedUsers: [],
  selectedArticles: [],
  relationshipText: null,
  relationshipType: null,
  disableBtn: true,
  showKeypointsNDrivers: false,
  addToMemory: false,
};

interface Props {
  webOrMem: string;
  data: AskMeResult;
}

const Answer = ({ webOrMem, data: relevantNews }: Props) => {
  const { user } = useUserContext();

  const userFullName = `${user.firstName} ${user.lastName && user.lastName}`;

  const [answerState, answerDispatcher] = useReducer(answerReducer, initState);
  // DATA
  const {
    loading,
    success,
    error,
    errorData,
    message,
    showShare,
    shareAll,
    selectedUsers,
    selectedArticles,
    relationshipText,
    relationshipType,
    disableBtn,
    showKeypointsNDrivers,
    shareArticles,
  } = answerState;

  function setSelectedUsers(users: string[] | []) {
    answerDispatcher({ type: "(de)select users", payload: users });
  }
  function setShareAll(bool: boolean) {
    answerDispatcher({ type: "toggle share all", payload: bool });
  }
  function setRelation(relationshipObj: relationshipObj) {
    answerDispatcher({ type: "set relationship", payload: relationshipObj });
  }
  // function closeRelationshipDropdown() {
  //   answerDispatcher({ type: "close relationship dropdown" });
  // }
  const { refetch } = useRewardsContext();
  const {mutate: rewardsMutate} = useUserRewardsMutation({
    onSuccess: () => {
      refetch()
    }
  })
  const {mutate: rewardsLogMutate} = useUserRewardLogsMutation({
    onSuccess: () => {
      console.log("success")
      rewardsMutate({
        options:{
          type: shareArticles ? Type.ArticleShared : Type.ArticleUpload,
          meta: {
            date: new Date()
          }
        }
      })
    }
  })
  const {
    mutate: uploadURL,
    status: uploadUrlStatus,
    error: uploadUrlError,
    reset: resetMutation,
  } = useURLUpload();

  const sumbitUrls = (from?: string) => {
    localStorage.removeItem('urlUploadedFrom')
    // loop over web articles to submit.
    for (let i = 0; i < selectedArticles.length; i++) {
      const article: OtherDocument = relevantNews.other_documents.filter(
        (a) => a.id === selectedArticles[i]
      )[0];
      // console.log("tada")
      if(from === 'share')
        localStorage.setItem('urlUploadedFrom', `${window.location.pathname}`)
      uploadURL({
        company_id: user.company,
        user_id: user._id,
        url: article.url,
        share_all: shareAll,
        share_to: selectedUsers,
        user_relationship_type: relationshipType as string,
        user_relationship_value: relationshipText as string,
        signup: true
      });
    }
    // display a message about having uploaded the articles and we wil  let them know when they're finished uploading.

    // clear out all the selected urls and share list etc.
  };
  
  const {
    mutate: shareMemArticles,
    error: shareMemErr,
    status: shareMemStatus,
    reset: resetMem,
  } = useShareMultipleArticlesMutationMutation();

  const submitMems = () => {
    if (selectedArticles.length === 0) return; // add error for user to see here.
    const articles = selectedArticles.map((a) => {
      const article = relevantNews.other_documents.filter(
        (art) => art.id === a
      )[0];

      return { articleId: article.id, whichDB: article.db_origin as WhichDb };
    });

    shareMemArticles({
      options: {
        articles: articles,
        recipients: selectedUsers,
        shareAll: shareAll,
        userFullName: `${user.name}${user.lastName ? ` ${user.lastName}` : ""}`,
      },
    });
  };

  // Enables and disables the submit share button on the share modal
  useEffect(() => {
    let isDisabled = true;
    if (selectedUsers.length > 0) {
      isDisabled = false;
    }
    if (
      webOrMem === "web" &&
      isDisabled === false &&
      relationshipText === null
    ) {
      isDisabled = true;
    }
    if (isDisabled) {
      answerDispatcher({ type: "disable" });
    } else {
      answerDispatcher({ type: "enable" });
    }
  }, [selectedUsers, relationshipText]);

  if (loading) {
    return (
      <div className={styles.card}>
        <LoadingAnswer message={message} />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.card}>
        <ErrorAnswer
          errorData={errorData}
          dispatcher={answerDispatcher}
          retryShare={() => {
            // Todo: hook up retry
            // console.log("Clicked Retry");
            // retryShare
          }}
        />
      </div>
    );
  }

  if (success) {
    return (
      <div className={styles.card}>
        <SuccessAnswer
          articleNums={selectedArticles.length}
          close={() => answerDispatcher({ type: "reset" })}
        />
      </div>
    );
  }

  if(answerState.addToMemory) {
    console.log(answerState.selectedArticles)
    if(answerState.selectedArticles.length > 0) {
      sumbitUrls()
      answerDispatcher({ type: "reset" });
    }
  }

  return (
    <div className={styles.card}>
      <ul className={styles.article__ul} data-overlay={showKeypointsNDrivers}>
        {relevantNews?.other_documents?.map((article: any, idx: number) => {
          // console.log("article here:", article);
          if (article.title === "") {
            return null;
          }
          return (
            <AArticle
              key={idx}
              idx={idx}
              article={article}
              selectedArticles={selectedArticles}
              selectArticle={(id) =>
                answerDispatcher({
                  type: "select article",
                  payload: id,
                })
              }
              answerDispatcher={answerDispatcher}
              showKeypointsNDrivers={showKeypointsNDrivers}
              webOrMem={webOrMem}
            />
          );
        })}
      </ul>
      <div className={styles.footer}>
        <AFooter
          webOrMem={webOrMem}
          selectAllArticles={() => {
            answerDispatcher({
              type: "selectAllArticles",
              payload:
                selectedArticles.length === relevantNews.other_documents.length
                  ? []
                  : relevantNews.other_documents.map((d) => d.id),
            });
          }}
          selectedArticles={selectedArticles}
          answerDispatcher={answerDispatcher}
        />
        {/* ======================== SHARE MODAL ======================== */}
      </div>
      {showShare && (
        <div className={styles.share__container}>
          <div className={styles.share__forms}>
            {webOrMem === "web" && (
              <div className={styles.relationship__wrapper}>
                <RelationDropdown
                  setRelationTextandType={(relationshipObj: any) =>
                    answerDispatcher({
                      type: "set relationship",
                      payload: relationshipObj,
                    })
                  }
                />
              </div>
            )}
            <div className={styles.shareListContainer}>
              {(shareMemStatus === "success" ||
                uploadUrlStatus === "success") && (
                <ComponentMessagingOverlay
                  close={() => {
                    answerDispatcher({ type: "reset" });
                    resetMutation();
                    resetMem();
                  }}
                  message={`Success!`}
                  buttonText={`OK`}
                  showCheck={true}
                  theme={CmoTheme.DARK}
                />
              )}
              {(uploadUrlError || shareMemErr) && (
                <ComponentMessagingOverlay
                  close={() => {
                    answerDispatcher({ type: "reset" });
                    resetMutation();
                    resetMem();
                  }}
                  message={customErrorHandler(uploadUrlError || shareMemErr)}
                  buttonText={`OK`}
                  showX={true}
                  theme={CmoTheme.DARK}
                />
              )}
              <ShareList
                shareAll={shareAll}
                setShareAll={(bool) => {
                  answerDispatcher({ type: "toggle share all", payload: bool });
                }}
                selectedUsers={selectedUsers}
                setSelectedUsers={(users) =>
                  answerDispatcher({ type: "(de)select users", payload: users })
                }
                theme={{
                  desktop: ShareListTheme.DARK,
                  mobile: ShareListTheme.DARK,
                }}
              />
            </div>
          </div>
          <div className={styles.shareBtn__container}>
            <button
              className={`${styles.shareBtn__button}
              ${
                null
                // disableBtn ? styles.shareBtn__disabled : styles.shareBtn__button
              }`}
              disabled={selectedUsers.length === 0}
              // onClick={submitShare}

              onClick={(e) => {
                e.preventDefault();
                answerDispatcher({ type: "share articles", status: true });
                webOrMem === "web" && sumbitUrls('share');
                webOrMem !== "web" && submitMems();
              }}>
              Share
            </button>
            <button
              className={`${styles.shareBtn__button} ${styles.shareBtn__button__noBorder}`}
              onClick={() => answerDispatcher({ type: "close share" })}>
              Cancel
            </button>
          </div>
        </div>
      )}
      <ANoResults answer={relevantNews?.main_document?.answer} />
    </div>
  );
};

export default Answer;

// TODO: Topic search should try searching for topic using topic filter first, then if 0 results, use fuzzy search to search for topic
