import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import SwiperCore, { Virtual, Keyboard, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AiFillCheckCircle } from 'react-icons/ai'
import SlideNextButton from './SlideNextButton'
import BackArrow from '../../../assets/img/arrowlight.png'
import 'swiper/react/swiper-react.js'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.scss'
// import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/scrollbar/scrollbar.scss'
import SkipButton from './SkipButton'
import { REST_BASE_ENDPOINT } from '../../../constants'
import { checkPrime } from 'crypto'
import { button } from '@material-tailwind/react'
SwiperCore.use([Virtual])

export default function StepsSwiper({ users, open }) {
  const [swiperRef, setSwiperRef] = useState(null)

  const slideTo = (index) => {
    swiperRef.slideTo(index - 1, 0)
  }

  const [viewCards, setViewCards] = useState(true)

  const [selectedOptions, setSelectedOptions] = useState([])

  const [tickIcon, setTickIcon] = useState(true)

  const [chosenUser, setChosenUser] = useState(null)

  const [chosenFormat, setchosenFormat] = useState('card')

  const [mode, setMode] = useState('dark')

  // const [x, setX] = useState();

  const choosedUser = (index) => {
    for (let i = 0; i < users.length; i++) {
      const ele = document.getElementById(i)
      ele.style.border = '1px solid #4E4D8D'
    }
    const ele = document.getElementById(index)
    ele.style.border = '1px solid rgb(60, 192, 246)'
    ele.style.boxShadow = '0px 10px 40px rgba(57, 68, 123, 0.25)'
    setChosenUser(users[index])
  }

  const onMouseEnter = (index) => {
    const ele = document.getElementById(index)
    if (ele.style.border != '1px solid rgb(60, 192, 246)') {
      ele.style.border = '1px solid white'
    }
  }

  const onMouseLeave = (index) => {
    const ele = document.getElementById(index)
    if (ele.style.border != '1px solid rgb(60, 192, 246)') {
      ele.style.border = '1px solid #4E4D8D'
    }
  }

  // console.log(selectedOptions);

  const handleClick = (option) => {
    // console.log("X", x);
    if (selectedOptions.length > minOptions - 2) {
      const ele = document.getElementById('minOptions')
      ele.style.color = 'white'
    }
    // if (selectedOptions.includes(option)) {
    //   const arr = selectedOptions;
    //   const newArr = arr.filter((o) => o !== option);
    //   console.log(arr);
    //   setSelectedOptions(selectedOptions.filter((o) => o !== option));
    //   console.log("@@@==", selectedOptions);
    // } else {
    //   const arr = selectedOptions;
    //   arr.push(option);
    //   setSelectedOptions(arr);
    //   console.log(arr);
    //   console.log(selectedOptions);
    // }
    // console.log("@@@", selectedOptions.length);
    // console.log(selectedOptions);
    setSelectedOptions(
      selectedOptions.includes(option)
        ? selectedOptions.filter((o) => o !== option)
        : [...selectedOptions, option],
    )
    console.log(selectedOptions)
  }
  // const changeView = () => {
  //   setViewCards(!viewCards);
  //   if (chosenFormat == "card") {
  //     setchosenFormat("list");
  //   }
  //   if (chosenFormat == "list") {
  //     setchosenFormat("card");
  //   }
  // };

  // const changeMode = (mode) => {
  //   mode === "dark" ? setTickIcon(true) : setTickIcon(false);
  //   mode === "dark" ? setMode("dark") : setMode("light");
  // };

  // let options = ["a", "b"];
  const [options, setOptions] = useState([])
  const [minOptions, setMinOptions] = useState()

  const keywords = () => {
    const localStorageKeywords = localStorage.getItem('summaryKeywords')
    // console.log("Function Keywords Called");
    if (localStorageKeywords !== undefined && localStorageKeywords !== null) {
      setOptions(localStorageKeywords.split(','))
    } else if (chosenUser.userName === 'Student') {
      setOptions([
        'Career Options',
        'Vocational Studies',
        'Job fair',
        'Student Jobs',
        'Technology courses',
        'Campus interview preparation',
        'Homeschooling',
        'Online learning',
        'graduate courses',
        'postgraduate courses',
      ])
    } else if (chosenUser.userName === 'Organisation') {
      setOptions([
        'Income tax saving',
        'Office supplies',
        'Leadership Courses',
        'Project planning',
        'Hiring help',
        'Technology training',
        'Skills enhancement',
        'Event Planning',
        'Outsourcing options',
        'Customer Service',
        'Customer Satisfaction',
        'Work From Home',
      ])
    } else {
      setOptions([
        'Technology Trends',
        'Affordable Healthcare ',
        'Career Options',
        'Climate Change',
        'Super Foods',
        'Investment Ideas',
        'Cheap Vacations',
      ])
    }
  }

  useEffect(() => {
    const x = options.length
    // setX(x);
    if (x < 4) {
      setMinOptions(1)
    } else {
      setMinOptions(3)
    }
  }, [options.length])

  const sendData = () => {
    console.log(selectedOptions.length)
    console.log(minOptions)
    if (selectedOptions.length < minOptions) {
      const ele = document.getElementById('minOptions')
      console.log('====================================')
      console.log('I am here')
      console.log('====================================')
      ele.style.color = 'red'
    } else {
      console.log('Not Here')
      const data = {
        type: chosenUser.userName.toLowerCase(),
        keys: selectedOptions.join(),
        view: chosenFormat,
        theme: mode,
      }
      console.log(data)
      open(false)
      const token = localStorage.getItem('accessToken')
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      axios
        .post(`${REST_BASE_ENDPOINT}/newVersion/preferences`, data, config)
        .then((response) => {
          console.log(response.data)
          localStorage.setItem('userPrefAdded', true)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <Swiper
        onSwiper={setSwiperRef}
        centeredSlides={false}
        virtual
        preventInteractionOnTransition={true}
        allowTouchMove={false}
        mousewheel={false}
        swipeHandler={false}
        grabCursor={false}
        freeMode={false}
        keyboard={false}
        style={{
          background: 'transparent !important',
          fontFamily: 'Nunito Sans, sans-serif',
        }}
        modules={[Keyboard, Mousewheel]}
        className="mySwiper"
      >
        {/* 1st slide */}
        <SwiperSlide>
          <div
            style={{
              background:
                'radial-gradient(87.75% 105.76% at 43.36% 32.19%, #172749 0%, #0F1B34 100%)',
              color: 'white',
              fontFamily: 'Nunito Sans, sans-serif',
            }}
            className=" rounded-[20px] h-[460px] relative"
          >
            <div className=" items-center flex flex-col pt-[42px] pl-[32px] pr-[32px] pb-[24px]">
              <div className="items-start justify-between  mx-auto">
                <h3 className="text-[24px] font-semibold tracking-[.004em]">
                  Who Are You?
                </h3>
              </div>
              <div className="flex space-x-2 mt-[30px] mb-[50px]">
                <div
                  style={{
                    background: '#3CC0F6',
                    fontFamily: 'Nunito Sans, sans-serif',
                  }}
                  className="w-[51.39px] h-[13.01px] rounded-[17px] cursor-pointer"
                  onClick={() => slideTo(1)}
                ></div>
                <div
                  style={{
                    background: 'rgba(255, 255, 255, 0.16)',
                    fontFamily: 'Nunito Sans, sans-serif',
                  }}
                  className="w-[51.39px] h-[13.01px] rounded-[17px] cursor-pointer"
                  onClick={() => {
                    if (chosenUser !== null) {
                      keywords()
                      slideTo(2)
                    }
                  }}
                ></div>
              </div>
              <div className="flex flex-row  leading-[29px] space-x-3 mb-[65px]">
                {users.map((item, index) => (
                  <div
                    style={{
                      border: '1px solid #4E4D8D',
                    }}
                    id={index}
                    onClick={() => choosedUser(index)}
                    onMouseEnter={() => onMouseEnter(index)}
                    onMouseLeave={() => onMouseLeave(index)}
                    className="box-border cursor-pointer rounded-[6px] border-[#4E4D8D] w-[133px] h-[153px] pt-[24px] "
                  >
                    <div className="text-[18px] font-semibold leading-4 flex  items-center justify-center mx-auto pb-[18px]">
                      {item.userName}
                    </div>
                    <div className="w-[90px]  bg-gradient-to-r h-[2px] ml-[20px] from-[#3CC0F6] to-transparent "></div>
                    <div
                      // style={{ paddingTop: "10px" }}
                      className="flex flex-row p-3"
                    >
                      <img
                        className="h-[50px] mx-auto my-auto"
                        src={item.image}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ display: 'contents' }} className=" cursor-pointer">
                {chosenUser === null ? (
                  <div
                    style={{
                      border: '1px solid black',
                      background: '#3CC0F6',
                      color: 'black',
                      width: '107px',
                      opacity: '0.4',
                    }}
                    className=" flex w-14 text-center cursor-pointer  justify-center font-bold ml-auto text-sm mb-1 py-3 rounded-lg "
                    type="button"
                  >
                    Continue
                  </div>
                ) : (
                  <SlideNextButton keywords={keywords} text="Continue" />
                )}
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* 2nd slide */}
        <SwiperSlide>
          <div
            style={{
              background:
                'radial-gradient(87.75% 105.76% at 43.36% 32.19%, #172749 0%, #0F1B34 100%)',
              color: 'white',
            }}
            className=" rounded-[20px] h-[460px] relative"
          >
            <img
              style={{
                color: 'white',
                marginRight: 'auto',
                position: 'absolute',
                // height: "10px",
                // width: "10px",
                top: '30px',
                left: '25px',
                cursor: 'pointer',
              }}
              src={BackArrow}
              onClick={() => {
                const ele = document.getElementById('minOptions')
                ele.style.color = 'white'
                setSelectedOptions([])
                slideTo(1)
              }}
            />
            <div>
              <div className="flex flex-col  items-center justify-center pt-[35px] pl-[32px] pr-[32px] pb-[24px]">
                <div className="flex flex-col items-center mx-auto">
                  <h3 className="text-[24px] font-semibold tracking-[.004em]">
                    Choose your Interest
                  </h3>
                  <div
                    id="minOptions"
                    style={{
                      color: '#FFFFFF',
                      fontFamily: 'Nunito Sans, sans-serif',
                    }}
                    className="text-[16px] font-normal flex items-center font-semibold tracking-[.004em] pt-[10px]"
                  >
                    Select atleast {minOptions} of them
                  </div>
                </div>
                <div className="flex space-x-2 mt-[20px] mb-[40px]">
                  <div
                    style={{
                      background: 'rgba(255, 255, 255, 0.16)',
                      fontFamily: 'Nunito Sans, sans-serif',
                    }}
                    className="w-[51.39px] h-[13.01px] rounded-[17px] cursor-pointer"
                    onClick={() => {
                      slideTo(1)
                      setSelectedOptions([])
                    }}
                  ></div>
                  <div
                    style={{
                      background: '#3CC0F6',
                      fontFamily: 'Nunito Sans, sans-serif',
                    }}
                    className="w-[51.39px] h-[13.01px] rounded-[17px] cursor-pointer"
                    onClick={() => slideTo(2)}
                  ></div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'flex-start',
                  }}
                  className="flex flex-wrap h-[140px]  overflow-y-scroll mb-[60px] custom-scroll"
                >
                  {options.map((option, index) => (
                    <div
                      className="border flex align-middle m-2 pt-1 pb-1 pl-3 pr-3 rounded-[6px] cursor-pointer"
                      onClick={() => handleClick(option)}
                      style={{
                        background: selectedOptions.includes(option)
                          ? 'rgba(255, 255, 255, 0.16)'
                          : 'rgba(255, 255, 255, 0.02)',
                        border: selectedOptions.includes(option)
                          ? '1px solid white'
                          : '1px solid #4E4D8D',
                        fontSize: '14px',
                        alignItems: 'center',
                        height: '30px',
                        paddingTop: '5px',
                        paddingBottom: '10px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      {option}
                    </div>
                  ))}
                </div>
                <div className="w-[100%] flex mt-[20px]">
                  {/* <SkipButton /> */}
                  <div
                    style={{
                      border: '1px solid black',
                      background: '#3CC0F6',
                      color: 'black',
                      fontFamily: 'Nunito Sans, sans-serif',
                      width: '85px',
                    }}
                    className=" flex w-12 text-center cursor-pointer justify-center font-bold ml-auto text-sm mb-1 py-3 rounded-lg "
                    type="button"
                    onClick={sendData}
                  >
                    Done
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}
