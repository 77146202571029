import React, { FC } from "react";
import { useDriversByTopicQuery } from "../../../../codeGenFE";
import { customErrorHandler } from "../../../../utils/customErrorHandler";

// COMPONENTS
import AddToNotebookBtn from "../../../Components/AddToNotebookBtn/AddToNotebookBtn";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../Components/LoadingCube/LoadingCube";

// STYLES
import styles from "./AMDrivingDecisions.module.scss";

interface Props {
  topic: string;
}

const AMDrivingDecisions: FC<Props> = ({ topic }) => {
  const { data, status, error } = useDriversByTopicQuery({ topic });

  const drivers = data?.driversByTopic;

  if (status === "loading") {
    return (
      <div className={styles.driverContainer}>
        <li className={styles.driverLI}>
          <LoadingCube
            hasBackground={false}
            height={100}
            width={100}
            theme={LoadingCubeTheme.LIGHT}
          />
        </li>
      </div>
    );
  }

  if (status === "error") {
    return (
      <div className={styles.driverContainer}>
        <li className={styles.driverLI}>{customErrorHandler(`${error}`)}</li>
      </div>
    );
  }

  if (!drivers || drivers.length === 0) {
    return (
      <div className={styles.driverContainer}>
        <li className={styles.driverLI}>No Drivers found for this topic</li>
      </div>
    );
  }

  return (
    <div className={styles.driverContainer}>
      {drivers &&
        drivers.map((driver, idx) => {
          return (
            <li className={styles.driverLI} key={idx}>
              <div>
                {driver && (
                  <AddToNotebookBtn content={driver} style={"unstyled"} />
                )}
              </div>
              {driver}
            </li>
          );
        })}
    </div>
  );
};

export default AMDrivingDecisions;
