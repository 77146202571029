import { PYTHON_REST_BASE_ENDPOINT } from "../../constants";
import { useMutation } from "react-query";
import axios from "axios";
import { Maybe } from "../../codeGenFE";

interface AskMeInput {
  question: string;
  user_id: string;
  company_id: string;
}

export type MainDocument = {
  id: string;
  db_origin: string; // empty string for external main documents
  title: string;
  url: string;
  harvest_date: string;
  pub_platform: string;
  category: string; // empty string for external main documents
  answer: string;
  answer_sentence: string;
  full_content: string;
  summary?: string; // only on internal documents
};

export type OtherDocument = {
  id: string;
  db_origin: string;
  title: string;
  url: string;
  harvest_date: string;
  pub_platform: string;
  summary: string;
};

type AskMeEntity = {
  entity_name: string; // topic
  entity_type: string;
  url: string;
  summary: string;
  image_url: string;
};

export type AskMeResult = {
  main_document: MainDocument;
  other_documents: OtherDocument[];
};

type AskMeResponse = {
  internal_results: AskMeResult;
  external_results: AskMeResult;
  entity_results: AskMeEntity[];
};

// type News = {
//   first2sents: string;
//   harvestDate: string;
//   pubPlatform: string;
//   sequenceId: string;
//   title: string;
//   url: string;
// };
//
// export interface AskMeDomainResult {
//   answer: string;
//   harvestDate: string;
//   planType: string;
//   pubPlatform: string;
//   releventNews: [] | News[];
//   releventSents: string;
//   sentence: string;
//   sequenceID: string;
//   shortDesc: string;
//   stat: {
//     subclass: string;
//     title: string;
//     url: string;
//   };
// }
//
// export interface AskMeGoogleResult {
//   answer: string;
//   harvestDate: string;
//   planType: string;
//   pubPlatform: string;
//   releventNews: [] | News[];
//   releventSents: string;
//   sentence: string;
//   sequenceID: string;
//   shortDesc: string;
//   title: string;
//   url: string;
// }

// export interface AskMeData {
//   companyName: string;
//   domainResult: AskMeDomainResult;
//   entityWikiInfo: [] | any[]; // ?
//   googleResult: AskMeGoogleResult;
//   question: string;
//   user_uploaded_content: string;
// }

export const useAskMe = (options?: any) => {
  return useMutation(async (askMeInput: AskMeInput): Promise<AskMeResponse> => {
    // console.log("askMeInput", askMeInput);
    try {
      const res = await axios.post(
        `${PYTHON_REST_BASE_ENDPOINT}/ask-me`,
        askMeInput
      );

      // console.log("useAskMe res", res);
      if (!res?.data) throw "@Sorry no results";

      return res.data;
    } catch (err) {
      throw new Error("Couldn't ask question at this time.");
      // throw new Error(`${err}`);
    }
  }, options);
};
