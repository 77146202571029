import React, { useReducer } from "react";

// icons
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

// styles
import styles from "./RelationDropdown.module.scss";

const relReducer = (state, action) => {
  switch (action.type) {
    case "toggle dropdown":
      return {
        ...state,
        isDropdownOpen: action.bool,
      };
    case "change display":
      return {
        ...state,
        display: action.payload,
      };
    case "toggle subdropdown":
      return {
        ...state,
        subDropDown: state.subDropDown === action.name ? null : action.name,
      };
    default:
      return state;
  }
};

const initState = {
  isDropdownOpen: false,
  display: null,
  subDropdown: null,
};

const RelationDropdown = ({ setRelationTextandType }) => {
  const [relState, relDispatcher] = useReducer(relReducer, initState);

  const { isDropdownOpen, display, subDropDown } = relState;

  function changeRelationship({ text, type }) {
    relDispatcher({ type: "change display", payload: text });
    setRelationTextandType({
      text: text,
      type: type,
    });
  }

  const companyRelations = [
    { text: "Customer", type: "company" },
    { text: "Competitor", type: "company" },
    { text: "Supplier", type: "company" },
    { text: "Prospect", type: "company" },
    { text: "Other", type: "company" },
  ];
  const industryRelations = [
    { text: "I compete within this industry", type: "industry" },
    { text: "I sell into this industry", type: "industry" },
    { text: "I buy from this industry", type: "industry" },
    { text: "This is an adjacent industry", type: "industry" },
    { text: "Other", type: "industry" },
  ];
  const productRelations = [
    { text: "I buy this product", type: "product" },
    { text: "I sell this product", type: "product" },
    { text: "This is a substitute product", type: "product" },
    { text: "Other", type: "product" },
  ];

  return (
    <div
      className={styles.rel__container}
      onClick={() =>
        relDispatcher({ type: "toggle dropdown", bool: !isDropdownOpen })
      }
      onMouseLeave={() =>
        relDispatcher({ type: "toggle dropdown", bool: false })
      }
    >
      <div
        className={`${styles.rel__display} ${
          isDropdownOpen ? styles.rel__display__open : null
        }`}
      >
        {display ? display : "Relationship"}
        {isDropdownOpen ? (
          <IoMdArrowDropup className={styles.rel__displayIcon} />
        ) : (
          <IoMdArrowDropdown className={styles.rel__displayIcon} />
        )}
      </div>
      <div
        className={`${styles.dropdown__wrapper} ${
          isDropdownOpen ? styles.dropdown__wrapper__open : null
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* =========== COMPANY SECTION =========== */}
        <li
          className={styles.relationDropdown__dropDown}
          onClick={() =>
            relDispatcher({
              type: "toggle subdropdown",
              name: "company",
            })
          }
        >
          Company
          <span className={styles.iconSpan}>
            {subDropDown !== "company" ? (
              <IoMdArrowDropdown className={styles.icon} />
            ) : (
              <IoMdArrowDropup className={styles.icon} />
            )}
          </span>
        </li>
        {subDropDown === "company" && (
          <div className={styles.relationDropdown__wrapper}>
            {companyRelations.map((rel) => {
              return (
                <li
                  key={rel.text + rel.type}
                  className={styles.relationDropdown__dropDown}
                  onClick={() => {
                    changeRelationship({
                      text: rel.text,
                      type: rel.type,
                    });
                  }}
                >
                  {rel.text}
                </li>
              );
            })}
          </div>
        )}
        {/* =========== INDUSTRY SECTION =========== */}
        <li
          className={styles.relationDropdown__dropDown}
          onClick={() =>
            relDispatcher({ type: "toggle subdropdown", name: "industry" })
          }
        >
          Industry
          <span className={styles.iconSpan}>
            {subDropDown !== "industry" ? (
              <IoMdArrowDropdown className={styles.icon} />
            ) : (
              <IoMdArrowDropup className={styles.icon} />
            )}
          </span>
        </li>
        {subDropDown === "industry" && (
          <div className={styles.industryDropDownWrapper}>
            {industryRelations.map((rel) => {
              return (
                <li
                  key={rel.text + rel.type}
                  className={styles.subdropdown__selection__li}
                  onClick={() => {
                    changeRelationship({
                      text: rel.text,
                      type: rel.type,
                    });
                  }}
                >
                  {rel.text}
                </li>
              );
            })}
          </div>
        )}
        {/* =========== PRODUCT SECTION =========== */}
        <li
          className={styles.relationDropdown__dropDown}
          onClick={() =>
            relDispatcher({ type: "toggle subdropdown", name: "product" })
          }
        >
          Product
          <span className={styles.iconSpan}>
            {subDropDown !== "product" ? (
              <IoMdArrowDropdown className={styles.icon} />
            ) : (
              <IoMdArrowDropup className={styles.icon} />
            )}
          </span>
        </li>
        {subDropDown === "product" && (
          <>
            {productRelations.map((rel) => {
              return (
                <li
                  key={rel.text + rel.type}
                  className={styles.subdropdown__selection__li}
                  onClick={() => {
                    changeRelationship({
                      text: rel.text,
                      type: rel.type,
                    });
                  }}
                >
                  {rel.text}
                </li>
              );
            })}
          </>
        )}
        <li
          className={styles.other__selection__li}
          onClick={() => {
            changeRelationship({ text: "Self", type: "self" });
          }}
        >
          Self
        </li>
        <li
          className={styles.other__selection__li}
          onClick={() => {
            changeRelationship({ text: "Other", type: "other" });
          }}
        >
          Other
        </li>
      </div>
    </div>
  );
};

export default RelationDropdown;
