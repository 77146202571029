const InvestmentPlans = ({ keyInvestments = [] }) => <div className="text-center mx-auto">
    <div className="my-5" style={{ fontSize: "22px", fontWeight: 700 }}>
        <b>Key investment plans</b>
    </div>
    <div className="row mx-3">
        {keyInvestments.map((el, i) => (
            <div
                className="col"
                style={{ width: "245px", marginRight: "44px" }}
            >
                <div
                    className="circle mb-3 mx-auto"
                    style={{
                        backgroundColor: "rgb(79, 119, 204)",
                        borderRadius: "75%",
                        width: "43px",
                        height: "43px",
                        padding: "12px",
                        color: "white",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                >
                    {i + 1}
                </div>
                <div
                    className="mb-3"
                    style={{ fontSize: "18px", fontWeight: 600 }}
                >
                    {el.title.substring(0, 60)}
                </div>
                <div
                    style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#667085",
                        textAlign: "center",
                        lineHeight: "21px",
                    }}
                >
                    {el.description.substring(0, 250)}
                </div>
            </div>
        ))}
    </div>
</div>


export default InvestmentPlans;
