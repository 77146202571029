import axios from "axios";
import { useMutation } from "react-query";
import { PYTHON_REST_BASE_ENDPOINT } from "../../constants";
// axiosConfig axios is for protected routes to node backend. python has none of
// this implemented so can't use custom axios have to use default axios
// import { axios } from "../utils/axiosConfig";

// type File {
//   TODO:
//   // add when we know
// }

interface FileUploadInput {
  // formData: any;
  // config: any;
  files: any[];
  company_id: string;
  user_id: string;
  share_all?: boolean;
  share_to?: string[];
  // tags?: string[];
  // user_relationship_type?: string;
  // user_relationship_value?: string;
}

export function useFileUpload(options?: any) {
  // console.log("running useFileUpload");

  return useMutation(async (fileUploadInput: FileUploadInput) => {
    try {
      const data = fileUploadInput;
      let formData = new FormData();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      formData.append("company_id", data.company_id);
      formData.append("user_id", data.user_id);
      // @ts-ignore
      formData.append("share_all", data.share_all ? true : false);
      // @ts-ignore
      formData.append("share_to", data.share_to);
      // @ts-ignore
      // formData.append("tags", tags ? tags : null);
      // formData.append("user_relationship_type");
      // @ts-ignore
      // console.log("data.files", data.files);
      data.files.map((file: any) => {
        formData.append(`files`, file.value);
        // old and no longer works
        // data.files.map((file: any, index: number) => {
        // formData.append(`file${index}`, file.value);
      });
      // console.log("formData", formData);
      const { data: resData } = await axios.post(
        `${PYTHON_REST_BASE_ENDPOINT}/quick_upload/file`,
        formData,
        config
      );
      if (resData) {
        // console.log("resData from python file upload: ", resData);
        return resData;
      }
    } catch (err) {
      console.log("err from file upload python: ", err);
      throw new Error(err.response.data.message);
    }
  }, options);
}
