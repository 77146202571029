import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import NavLight from "./NavLight";
import Footer from "./Footer";
import styles from "./landingPage.module.scss";
import left from "../../../assets/img/left.png";
import right from "../../../assets/img/right.png";
import ball1 from "../../../assets/img/ball1.png";
import ball2 from "../../../assets/img/ball2.png";
import uploadLight from "../../../assets/img/uploadLight.png";
import Summary from "./Summary";
import SummaryMobile from "./SummaryMobile";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { SubscriptionClient } from "graphql-subscriptions-client";
import { GQL_SUBSCRIPTION_ENDPOINT } from "../../../constants";
import { toast, ToastContainer } from "react-toastify";
import shareLinkedinImage from "../../../../src/assets/img/sharepng.png";
import shareTwitterImage from "../../../../src/assets/img/twi.png";
import ProgressBar from "@ramonak/react-progress-bar";
import { TextLoopComponent } from "./TextLoop";
import "react-toastify/dist/ReactToastify.css";
import LoaderLoop from "./LoaderLoop";
import { headersApiLevel } from "./headersApiLevel";
import { useLocation } from "react-router-dom";
import { removeParamsOauth } from "./removeParamsOauth";
import { createUser } from "../../../services/signUpUser";
import LoadingCube from "../../Components/LoadingCube/LoadingCube";

import { getBulletPoints } from "../../util/bulletPoints";
import NavigationSteps from "../../Components/NavigationSteps/NavigationSteps";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { StatsFunction } from "./StatsFunction";

import {
  REST_BASE_ENDPOINT,
  API_ENDPOINTS,
  POST_HEADERS,
} from "../../../constants";

import { linkedinAccessToken, submitLinkedinData } from "./linkedinUser";
import { fetchArticleData } from "./getData";
import OnboardingModal from "../../Components/OnboardingModal/OnBoardingModal";
import NewFooter from "./NewFooter";

export default function HomeLight() {
  var route = window.location.href;
  const changeRouteTwitter = () =>
    route.includes("?oauth_token") ? true : false;

  const [urlInput, setUrlInput] = useState("");
  const [endpoint, setEndpoint] = useState("");
  const [allData, setAllData] = useState();

  const [code, setCode] = useState(null);
  const [error, setError] = useState(false);
  const [showLinkedinModal, setshowLinkedinModal] = useState(
    localStorage.getItem("integrated") && localStorage.getItem("allData")
      ? true
      : false
  );
  const [showTwitterModal, setshowTwitterModal] = useState(
    changeRouteTwitter()
  );
  const [progress, setProgress] = useState(0);
  const [sectionData, setSectionData] = useState(null);
  const [linkedinShareValue, setLinkedinShareValue] = useState("");
  const messagesEndRef = useRef(null);
  const [changeRoute, setChangeRoute] = useState(false);
  const [size, setSize] = useState(0);
  const [, setTwitterAccessToken] = useState(
    localStorage.getItem("twitterAccessToken") || null
  );
  const [twitterAccessTokenSecret, setTwitterAccessTokenSecret] = useState(
    localStorage.getItem("twitterAccessTokenSecret") || null
  );
  const [tokenCall, setTokenCall] = useState(false);
  const [pushLogin, canLogin] = useState(false);
  const [linkedindata, setLinkedindata] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useHistory();
  const location = useLocation();
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [interval, setProgressInterval] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);

  const checkLocalStorage = localStorage.getItem("allData");

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  function notify() {
    return toast.error("⚠️ Please enter a valid URL", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  useEffect(() => {
    scrollToBottom();
  }, [allData]);

  useEffect(() => {
    if (sectionData) {
      error ? setProgress(0) : setProgress(15);

      let newProgress = 16;

      if (
        !error &&
        sectionData.error === false &&
        progress !== 0 &&
        !invalidUrl
      ) {
        let tempProgress = setInterval(() => {
          if (newProgress < 95 && newProgress >= 15) {
            newProgress++;
            setProgress(newProgress);
          }
        }, 1000);

        setProgressInterval(tempProgress);
      }

      setTimeout(() => {
        const getData = localStorage.getItem("allData");
        setAllData(JSON.parse(getData));
      }, 4900);
    }
  }, [sectionData, error]);

  useEffect(() => {
    if (progress === 100) {
      clearInterval(interval);
    }
  }, [progress]);

  const hiddenFileInput = useRef(null);

  function urlHandler(e) {
    setEndpoint("url-upload");
    setProgress(0);
    const value = e.target.value;
    const trimmed = value.replace(/\s/g, "");
    // console.log(trimmed);
    setUrlInput(trimmed);
  }

  function postFormData(e) {
    setInvalidUrl(false);
    e.preventDefault();
    var expression =
      /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    checkDataforUrl(regex);
  }

  function checkDataforUrl(regex) {
    var myHeaders = new Headers();
    var fileHeader = new Headers();
    headersAPIFile();
    headersAPI();
    localStorage.removeItem("allData");

    const raw = {
      tempCompanyId: localStorage.getItem("companyId"),
      tempUserId: localStorage.getItem("userId"),
      url: urlInput,
    };

    var formdata = new FormData();
    formdata.append("tempCompanyId", localStorage.getItem("companyId"));
    formdata.append("tempUserId", localStorage.getItem("userId"));
    formdata.append("files", urlInput);
    const url = endpoint === "url-upload" ? raw : formdata;

    const fileUploadOptions = {
      method: "POST",
      headers: fileHeader,
      data: url,
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      data: url,
    };

    const upload =
      endpoint === "url-upload" ? requestOptions : fileUploadOptions;

    if (endpoint === "url-upload") {
      setProgress(10);
      setInvalidUrl(false);
      axios({
        ...upload,
        url: `${REST_BASE_ENDPOINT}/auth/landing/${endpoint}`,
      })
        // .then((response) => {
        //   setProgress(50);
        //   return response.text();
        // })
        .then((result) => {
          setProgress(50);
          if (result?.data) {
            setProgress(70);
            dataSection(JSON.stringify(result.data));
          }
        })
        .catch((error) => {
          setProgress(0);
          toast.error("Please enter a valid URL.");
          console.log("error", error);
        });
    } else {
      if (size > 3000000) {
        toast.error("Upload file with size lesser than 3 MB", {
          position: "top-center",
          autoClose: 5000,
          progress: undefined,
          theme: "colored",
        });
        setProgress(0);
      } else {
        const response = axios.post(
          `${REST_BASE_ENDPOINT}/auth/landing/${endpoint}`,
          url,
          {
            headers: fileHeader,
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let percent = Math.floor((loaded * 100) / total);
              if (percent < 100) {
                setProgress(percent);
              }
            },
          }
        );
        response.then((result) => {
          setProgress(20);
          fileUploadSection(result.data);
          setAllData(result.data._source);
        });
      }
    }

    function fileUploadSection(result) {
      const data = result;
      setSectionData(data);
      if (data.error) {
        notify();
        setAllData(null);
        setProgress(0);
        setError(true);
        localStorage.removeItem("allData");
      } else {
        setProgress(0);
        setError(false);
      }
      return console.log(result);
    }

    function dataSection(result) {
      const data = JSON.parse(result);
      setSectionData(data);
      if (data.error && endpoint === "url-upload") {
        notify();
        setAllData(null);
        setProgress(0);
        setError(true);
        localStorage.removeItem("allData");
      } else {
        setProgress(0);
        setError(false);
      }
      return console.log(result);
    }

    function headersAPI() {
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Credentials", "true");
      myHeaders.append(
        "Access-Control-Allow-Methods",
        "GET,HEAD,OPTIONS,POST,PUT"
      );
      myHeaders.append(
        "Access-Control-Allow-Headers",
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      );
      myHeaders.append(
        "Access-Control-Expose-Headers",
        "Access-Control-Allow-Origin, Access-Control-Allow-Credentials"
      );
      myHeaders.append("Access-Control-Max-Age", "3600");
    }

    function headersAPIFile() {
      headersApiLevel(fileHeader);
    }
  }

  function uploadHandler(e) {
    hiddenFileInput.current.click();
  }

  const handleChange = (event) => {
    localStorage.removeItem("allData");
    setProgress(0);
    const fileUploaded = event.target.files[0];
    setEndpoint("file-upload");
    setSize(fileUploaded.size);
    setUrlInput(event.target.files[0]);
  };

  useEffect(() => {
    if (changeRouteTwitter() && !showTwitterModal) setshowTwitterModal(true);
  }, [location]);

  useEffect(() => {
    const changeChangeLocal = JSON.parse(checkLocalStorage);
    if (changeRouteTwitter()) {
      const url = new URL(window.location.href);
      const oAuthToken = url.searchParams.get("oauth_token");
      setLinkedinShareValue(getBulletPoints(changeChangeLocal));

      const oAuthVerifier = url.searchParams.get("oauth_verifier");

      localStorage.setItem("oAuthToken", oAuthToken);
      localStorage.setItem("oAuthVerifier", oAuthVerifier);

      if (
        localStorage.getItem("twitterAccessToken") === null ||
        localStorage.getItem("twitterAccessToken") === undefined
      ) {
        axios
          .post(
            `${REST_BASE_ENDPOINT}${API_ENDPOINTS.TW_ACCESS_TOKEN}`,
            {
              token: oAuthToken,
              verifier: oAuthVerifier,
            },
            {
              headers: POST_HEADERS,
            }
          )
          .then((result) => {
            if (!result?.data) {
              notify();
              setAllData(null);
              setProgress(0);
              setError(true);
              localStorage.removeItem("allData");
            } else {
              const accessData = result.data.data.split("&");
              const accessToken = accessData[0];
              const accessTokenSecret = accessData[1];

              setTwitterAccessToken(accessToken);
              setTwitterAccessTokenSecret(accessTokenSecret);
              localStorage.setItem("twitterAccessToken", accessToken);
              localStorage.setItem(
                "twitterAccessTokenSecret",
                accessTokenSecret
              );
              localStorage.setItem("reloaded", true);

              setError(false);
            }
            return result;
          })
          .catch((error) => console.log("error", error));
      }
    }
  }, [showTwitterModal]);

  useEffect(() => {
    function delaySubscription() {
      const userId = localStorage.getItem("userId");
      const query = `
        subscription articlesChangeSubscription {
          articlesChangeSubscription(userId: "${userId}") {
            articleId
            status
            database
            keywords
            taskId
            type
          }
        }
    `;
      return subscriptionGetData(
        query,
        setAllData,
        setProgress,
        setError,
        interval,
        setSectionData,
        setLinkedinShareValue,
        getBulletPoints
      );
    }

    delaySubscription();
    localStorage.removeItem("downloadPdf");

    setAllData(null);
  }, []);

  function subscriptionGetData(query) {
    const client = new SubscriptionClient(GQL_SUBSCRIPTION_ENDPOINT, {
      reconnect: true,
      lazy: true,
      connectionCallback: (error) => {
        error && console.error(error);
      },
    });

    const subscription = client.request({ query }).subscribe({
      next({ data }) {
        if (data.articlesChangeSubscription) {
          if (data) {
            setKeywords(data.articlesChangeSubscription.keywords);
            fetchArticleData(
              data,
              setAllData,
              setProgress,
              setError,
              interval,
              setSectionData,
              setLinkedinShareValue,
              getBulletPoints
            );
          }
          return data;
        } else if (data.articlesChangeSubscription == null) {
          setInvalidUrl(true);
          setProgress(0);
          return toast.error(
            "There is something wrong with the upload you are trying, we are working on it. Please try a different URL or file.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              draggable: false,
              progress: undefined,
              toastId: "noDataToast",
            }
          );
        }
      },
    });
    return () => subscription.unsubscribe();
  }

  const resetParams = () => {
    removeParamsOauth(location, navigate);
  };

  const resetModal = (e) => {
    e.preventDefault();

    localStorage.removeItem("integrated");
    setshowLinkedinModal(false);
    setshowTwitterModal(false);
    resetParams();
    setLinkedinShareValue("");
    setLinkedindata("");
    localStorage.removeItem("allData");
  };

  useEffect(() => {
    if (invalidUrl) {
      setSectionData(null);

      clearInterval(interval);
      setProgress(0);
    }
  }, [invalidUrl]);

  const handleMessageChange = (e) => {
    e.preventDefault();
    setLinkedinShareValue(e.target.value);
  };

  const submitTwitterData = async (e) => {
    e.preventDefault();

    // get textarea value
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    console.log("data", data);
    const checkaccess = localStorage.getItem("twitterAccessToken");
    const secretAccess = localStorage.getItem("twitterAccessTokenSecret");
    const checktwitterDetails = localStorage.getItem("twitterDetails");
    const twitterDetails = JSON.parse(checktwitterDetails);

    if (data.twitterFieldValue.length > 280) {
      toast.error("Twitter Accepts less than 280 Characters", {
        position: "top-center",
        autoClose: 5000,
        theme: "colored",
      });
    }
    if (data.twitterFieldValue.length <= 280) {
      await axios
        .post(
          `${REST_BASE_ENDPOINT}${API_ENDPOINTS.TW_POST}`,
          {
            token: checkaccess,
            text: data.twitterFieldValue.replace(
              /[\(*\)\[\]\{\}<>@|~_]/gm,
              (x) => "\\" + x
            ),
            secret: secretAccess,
          },
          {
            headers: POST_HEADERS,
          }
        )
        .then((response) =>
          createTwitterPostThenLogin(response.status, twitterDetails)
        )
        .catch((error) => console.log("error", error));

      function createTwitterPostThenLogin(response, twitterDetails) {
        localStorage.removeItem("allData");

        if (response === 200) {
          toast.success("Posted Successfully, navigating to sign up", {
            position: "top-center",
            autoClose: 5000,
            theme: "colored",
          });
          setTimeout(() => {
            navigate.push("signuplight");
          }, 4000);
        } else if (response !== 200) {
          toast.error("Enter less than 280 Characters", {
            position: "top-center",
            autoClose: 5000,
            theme: "colored",
          });
        }
      }
    }
  };

  // function keyChangeHandler(brr) {
  //   let arr = "";

  //   for (let i = 0; i < keywords.length; i++) {
  //     if (i < keywords.length - 1) {
  //       arr = arr + keywords[i] + ",";
  //     } else {
  //       arr = arr + keywords[i];
  //     }
  //   }
  //   return arr;
  // }

  useEffect(() => {
    if (changeRoute) {
      const updatedUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: updatedUrl }, "", updatedUrl);
    }
  }, [changeRoute]);

  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setCode(urlParams.get("code"));
    setChangeRoute(route.includes("?code=") ? true : false);
  }, []);

  // after login -> Access Token save in Local -> then remove TempId then don't regenerate tempId if accesstoken is there ;
  useEffect(() => {
    const changeChangeLocal = JSON.parse(checkLocalStorage);

    if (code) {
      setLinkedindata(changeChangeLocal);

      setLinkedinShareValue(getBulletPoints(changeChangeLocal));
      setshowLinkedinModal(true);
      const localAccessLinkedinCheck = localStorage.getItem(
        "linkedInAccessToken"
      );

      if (
        localAccessLinkedinCheck === undefined ||
        localAccessLinkedinCheck === null
      ) {
        // get param from url
        setTokenCall(true);
        axios
          .post(
            `${REST_BASE_ENDPOINT}${API_ENDPOINTS.LI_ACCESS_TOKEN}`,
            {
              code: code,
              url: window.location.origin,
            },
            {
              headers: POST_HEADERS,
            }
          )
          .then((result) => {
            if (result?.data) linkedinAccessToken(result.data);
          });
        return changeChangeLocal;
      }

      return;
    }

    return () => {
      setshowLinkedinModal(false);
    };
  }, [code]);

  useEffect(() => {
    if (showLinkedinModal) {
      localStorage.setItem("integrated", showLinkedinModal);
    }
  }, [showLinkedinModal]);

  const retrieveSummaryValue = JSON.parse(
    localStorage.getItem("allData") || `{}`
  );

  return (
    <Fragment>
      <ToastContainer />
      {loading && <LoadingCube isFullScreen={true} width={100} height={100} />}
      <div>
        <div className={styles.bgImage}>
          <NavLight className="" dataFetched={allData ? true : false} />
          <div
            class={
              styles.subfinal +
              "  d-flex justify-content-between align-items-center text-center"
            }
          >
            <div
              className={
                styles.displayNone +
                " w-50 d-sm-flex h-25 align-items-end text-start"
              }
            >
              <img width="80%" className={styles.positionFirst} src={left} />
              <img
                width="180px"
                className={styles.floating}
                height="180px"
                src={ball1}
              />
            </div>
            <div
              className={
                styles.height +
                "  d-flex justify-content-between align-items-center w-100 py-4 px-4 my-0 my-sm-4"
              }
            >
              <div
                className={
                  styles.zindex +
                  " w-100  align-center pt-4 pb-sm-0 pb-4  rounded rounded-5 px-sm-4 px- items-center align-items-center"
                }
              >
                <div className="my-4 mt-sm-0 w-100 mx-auto">
                  {window.innerWidth < 575 && (
                    <div
                      class={
                        styles.bigText +
                        " text-center text-dark fs-1  mb-3 tw-bg-white mt-sm-3 tw-my-8 pt-sm-5 pt-3 pt-md-0   lh-md boldtext custom-wd "
                      }
                    >
                      Solving
                      <br /> Information Overload
                      <br /> for
                      <span class={styles.developers + " fw-bolder"}>
                        {" "}
                        {TextLoopComponent()}
                      </span>
                    </div>
                  )}
                  {window.innerWidth > 575 && (
                    <>
                      <div
                        class={
                          styles.bigText +
                          " text-center text-dark  mb-3 tw-bg-white mt-sm-3 tw-my-8 pt-sm-5 pt-3 pt-md-0   lh-sm boldtext custom-wd "
                        }
                      >
                        Solving Information Overload<br /> for
                        <span class={styles.developers + " fw-bolder"}>
                          {" "}
                          {TextLoopComponent()}
                        </span>
                      </div>
                      <AiOutlineInfoCircle
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "12px",
                          top: "12px",
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={() => setModalShow(true)}
                      ></AiOutlineInfoCircle>
                    </>
                  )}
                  <NavigationSteps
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                  <div class="my-5 w-75 w-sm-100 mx-auto text-center text-dark lh-base fs-sm-5 fs-6 text-sm">
                    <b>Read Faster</b> - Knowledge is power.<br></br>
                    The world's most powerful knowledge management tool
                  </div>
                  <div
                    class={
                      styles.size +
                      " text-center my-3  w-sm-75  mx-auto  rounded rounded-2 d-flex justify-content-between "
                    }
                  >
                    {window.innerWidth > 575 && (
                      <form
                        class={
                          " w-75 mx-auto d-sm-flex justify-content-between "
                        }
                        onSubmit={postFormData}
                      >
                        <div
                          className={
                            styles.lightBorder +
                            "  py-2 ps-3 pe-2 rounded rounded-2  w-100"
                          }
                        >
                          <div>
                            <div
                              className={
                                styles.inputColor +
                                "  d-flex  justify-content-between "
                              }
                            >
                              <input
                                value={
                                  endpoint === "url-upload" || ""
                                    ? urlInput
                                    : urlInput.name
                                }
                                onChange={urlHandler}
                                class={
                                  styles.inputBox +
                                  " p-2 w-100  text-dark border border-0"
                                }
                                placeholder="Enter URL or Upload"
                              />
                              <button
                                type="submit"
                                disabled={urlInput == ""}
                                class={
                                  styles.getButton +
                                  " border w-50 border-0  font-bold  rounded rounded-1 text-white bg-dark py-2 px-2 " +
                                  (urlInput == "" && styles.disabled)
                                }
                              >
                                Get insights
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            styles.lightBorder + " mx-3 rounded rounded-2 "
                          }
                        >
                          <div
                            onClick={uploadHandler}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                            class={
                              styles.inputBox +
                              " rounded rounded-2 w-100 h-100 font-bold  shadow-md fw-bolder  px-3 py-2 "
                            }
                          >
                            <img src={uploadLight} />
                          </div>
                          <input
                            accept="application/pdf, .docx, .txt, .rtf, .png, .jpg, .jpeg, .gif"
                            type="file"
                            ref={hiddenFileInput}
                            onClick={() => setProgress(0)}
                            max-size="500000"
                            onChange={handleChange}
                            style={{ display: "none" }}
                          />
                        </div>
                      </form>
                    )}
                    {window.innerWidth < 575 && (
                      <div style={{margin: 'auto'}}>
                        <form
                          class={" w-100 d-sm-flex justify-content-between "}
                          onSubmit={postFormData}
                        >
                          <div
                            className={
                              styles.lightBorder +
                              "  py-2 ps-3  pe-2 rounded rounded-2  w-100"
                            }
                          >
                            <div>
                              <div
                                className={"  d-flex  justify-content-between "}
                              >
                                <input
                                  value={
                                    endpoint === "url-upload" || ""
                                      ? urlInput
                                      : urlInput.name
                                  }
                                  onChange={urlHandler}
                                  className={
                                    styles.small +
                                    " p-2 w-100  text-dark  rounded rounded-2   "
                                  }
                                  placeholder="Enter URL or Upload"
                                />
                                <div
                                  className={
                                    styles.lightBorder +
                                    " mx-3 rounded rounded-2 "
                                  }
                                >
                                  <button
                                    onClick={uploadHandler}
                                    class={
                                      styles.inputBox +
                                      " rounded rounded-2 w-100 h-100 font-bold  shadow-md fw-bolder  px-2 py-1 "
                                    }
                                  >
                                    <img src={uploadLight} />
                                  </button>
                                  <input
                                    accept="application/pdf, .docx, .txt, .rtf, .png, .jpg, .jpeg, .gif"
                                    type="file"
                                    ref={hiddenFileInput}
                                    max-size="1000000"
                                    onChange={handleChange}
                                    style={{ display: "none" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          <div className="px-3">
                            <button
                              type="submit"
                              disabled={urlInput == ""}
                              class={
                                styles.getButton2 +
                                "  border text-white bg-dark border-0 w-100 rounded rounded-2 mb-sm-5 mt-3 py-2 fw-bold px-4 " +
                                (urlInput == "" && styles.disabled)
                              }
                            >
                              Get insights
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                  {progress >= 10 && progress <= 99 && (
                    <p>
                      <LoaderLoop />
                    </p>
                  )}
                  {progress !== 0 && (
                    <ProgressBar
                      className={styles.wrapper + " mb-5 px-3 mt-sm-5"}
                      borderRadius={10}
                      labelColor="#fff"
                      labelSize="0.9rem"
                      bgColor="#3cc0f6"
                      baseBgColor="blue"
                      labelClassName="label"
                      completed={`${progress}`}
                    ></ProgressBar>
                  )}
                  {StatsFunction()}
                </div>
              </div>
            </div>

            <div
              className={
                styles.displayNone +
                " text-end d-sm-flex justify-content-center align-items-start h-25 w-50 "
              }
            >
              <img
                width="120px"
                height="120px"
                className={styles.positionSecond + " " + styles.floating}
                src={ball2}
              />{" "}
              <img width="65%" src={right} />
            </div>
          </div>
        </div>
      </div>
      {showTwitterModal && checkLocalStorage && (
        <Modal
          show={showTwitterModal}
          onHide={resetModal}
          backdrop="static"
          keyboard={false}
          animation={false}
          style={{ height: "570px" }}
        >
          <form onSubmit={submitTwitterData}>
            <Modal.Header>
              <Modal.Title>
                <img
                  style={{ height: "23px" }}
                  src={shareTwitterImage}
                  alt="twitter share"
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="tw-flex  justify-content-center align-items-center"
                style={{ height: "350px", overflow: "auto" }}
              >
                <h3>{linkedindata?.title}</h3>
                <textarea
                  value={linkedinShareValue}
                  className="custom-rounded-ta"
                  name="twitterFieldValue"
                  onChange={handleMessageChange}
                />
                {linkedinShareValue?.length >= 280 && (
                  <p className="text-danger" style={{ height: "40px" }}>
                    Enter less than 280 Characters
                  </p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={resetModal}
                type="button"
                className="btn btn-secondary"
                style={{ color: "black" }}
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{ backgroundColor: "#0a66c2", color: "white" }}
                className={
                  linkedinShareValue?.length <= 280
                    ? "enabled btn btn-primary"
                    : "disabled btn btn-primary"
                }
              >
                Share
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}

      {showLinkedinModal && checkLocalStorage && (
        <Modal
          show={showLinkedinModal}
          onHide={resetModal}
          backdrop="static"
          keyboard={false}
          animation={false}
          style={{ height: "570px" }}
        >
          <form
            onSubmit={(e) =>
              submitLinkedinData(
                e,
                setLoading,
                pushLogin,
                canLogin,
                setshowLinkedinModal,
                createUser
              )
            }
          >
            <Modal.Header>
              <Modal.Title>
                <img style={{ height: "23px" }} src={shareLinkedinImage} />{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="tw-flex  justify-content-center align-items-center"
                style={{ height: "350px", overflow: "auto" }}
              >
                <h3>{linkedindata?.title}</h3>
                {}
                <textarea
                  value={
                    linkedinShareValue
                      ? linkedinShareValue
                      : getBulletPoints(retrieveSummaryValue)
                  }
                  name="linkedinTextField"
                  className="custom-rounded-ta"
                  onChange={handleMessageChange}
                />
                {}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={resetModal}
                type="button"
                style={{ color: "black" }}
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{ backgroundColor: "#0a66c2", color: "white" }}
                className={"enabled btn btn-primary"}
              >
                Share
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {allData && (
        <div ref={messagesEndRef}>
          {window.innerWidth < 575 ? (
            <SummaryMobile allData={allData} keywords={keywords} />
          ) : (
            <Summary allData={allData} keywords={keywords} />
          )}
        </div>
      )}

      {/* <Footer /> */}
      <NewFooter />
    </Fragment>
  );
}
