import React from "react";

// components
import MainNavigation from "../../Components/MainNavigation/MainNavigation";
// import InfoButton from "../../../Icons/InfoButton";
import UPInputs from "./UPInputs/UPInputs";
import UPTopicsCard from "./UPTopicsCard/UPTopicCard";

// images
import BookBot from "../../../assets/img/User Profile-17.png";

// styles
import styles from "./UserProfile.module.scss";
import TopMenu from "../../Components/TopMenu/TopMenu";


const openTutorialInNewWindow = () => {
  const url = 'https://www.nowigence.com/tutorials'
  window.open(url, '_blank')
}

const UserProfile = () => {
  return (
    <div className={styles.userProfileContainer}>
      <div className={styles.topMenu}>
        <TopMenu showSearch={false} />
      </div>
      <div className={styles.leftMenu}>
        <MainNavigation />
      </div>
      <div className={styles.userProfileContent}>
        <div className={styles.imgNTitleWrapper}>
          <div className={styles.imgWrapper}>
            <img src={BookBot} alt="Book Logo" />
          </div>
          <div className={styles.header}>
            <h3>TOPIC MONITORING</h3>
            {/* <InfoButton /> */}
          </div>
          <p>
            Monitor over 170k web news sources with automated retrieval and analysis
            of relevant news articles. Pluaris will create a personalized, annotated
            news feed in the Reader's page on your topics of interest.
          </p>
          <p>
            <button onClick={openTutorialInNewWindow} className={styles.tutorialButton}>Tutorial</button>
          </p>
        </div>
        <div className={styles.topicFormWrapper} id={"editTopicScrollTarget"}>
          <UPInputs />
        </div>
        <UPTopicsCard />
      </div>
    </div>
  );
};

export default UserProfile;
