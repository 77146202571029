import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useUserContext } from "../../context/allContexts";
import NoAccessModal from "./NoAccessModal/NoAccessModal";

const AccountNeedsAttention = () => {
  const qClient = useQueryClient();
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const { user } = useUserContext();

  const disableAccount = [
    // bill larrick laritech
    "5eab10c3f3812103065b1896",
    // brian rath lorientcap
    "5cf97f039616db085f70b4b4",
    // Jon B
    // "5dd54ffe9b0fe61e36f7691c",
  ];

  function check() {
    // const user = qClient.getQueryData("ME");
    // const _id = user?.me?.user?._id;
    if (user._id && disableAccount.includes(user._id.toString())) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const answer = check();
    if (answer) {
      setShowNoAccessModal(true);
    } else {
      setShowNoAccessModal(false);
    }
  }, [qClient]);

  const handleNoAccessClose = async (e) => {
    e.stopPropagation();
    if (check()) {
      setShowNoAccessModal(true);
    } else {
      setShowNoAccessModal(false);
    }
  };

  if (showNoAccessModal) {
    return (
      <NoAccessModal
        showNoAccessModal={showNoAccessModal}
        handleNoAccessClose={handleNoAccessClose}
      />
    );
  } else {
    return null;
  }
};

export default AccountNeedsAttention;
