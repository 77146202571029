import { REST_BASE_ENDPOINT } from "../constants";
import { useMutation } from "react-query";
import { axios } from "../utils/axiosConfig";

interface TokenProcessInput {
  userId: string;
  tokens: number
}

export function useTokenProcess(opts?: any) {
  return useMutation(async (tokenProcessInput: TokenProcessInput) => {
    try {
      const { data } = await axios.post(
        `${REST_BASE_ENDPOINT}/auth/token/process`,
        tokenProcessInput
      );
      if (data.data) {
        return data.data;
      }
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, opts);
}
