export enum RelationType {
  OTHER = "other",
  COMPANY = "company",
  INDUSTRY = "industry",
  PRODUCT = "product",
  SELF = "self",
  DEFAULT = "default",
}

export enum RelationText {
  // For almost every type
  OTHER = "Other",

  // Company
  CUSTOMER = "Customer",
  COMPETITOR = "Competitor",
  SUPPLIER = "Supplier",
  PROSPECT = "Prospect",

  // Industry
  I_COMPETE_WITHIN_THIS_INDUSTRY = "I compete within this industry",
  I_SELL_INTO_THIS_INDUSTRY = "I sell into this industry",
  I_BUY_FROM_THIS_INDUSTRY = "I buy from this industry",
  THIS_IS_AN_ADJACENT_INDUSTRY = "This is an adjacent industry",

  // Products
  I_BUY_THIS_PRODUCT = "I buy this product",
  I_SELL_THIS_PRODUCT = "I sell this product",
  THIS_IS_A_SUBSTITUTE_PRODUCT = "This is a substitute product",

  // About user themselves
  SELF = "Self",

  // Default for display and checking if none selected
  SELECT_HOW_THIS_TOPIC_RELATES_TO_YOU = "Select how this topic relates to you",
}

// For multilevel dropdown
export const RelTextAndTypeCombinations = [
  {
    display: "Company",
    dropdownData: RelationType.COMPANY,
    subArray: [
      RelationText.CUSTOMER as string,
      RelationText.COMPETITOR as string,
      RelationText.SUPPLIER as string,
      RelationText.PROSPECT as string,
      RelationText.OTHER as string,
    ],
  },
  {
    display: "Industry",
    dropdownData: RelationType.INDUSTRY,
    subArray: [
      RelationText.I_COMPETE_WITHIN_THIS_INDUSTRY as string,
      RelationText.I_SELL_INTO_THIS_INDUSTRY as string,
      RelationText.I_BUY_FROM_THIS_INDUSTRY as string,
      RelationText.THIS_IS_AN_ADJACENT_INDUSTRY as string,
      RelationText.OTHER as string,
    ],
  },
  {
    display: "Product",
    dropdownData: RelationType.PRODUCT,
    subArray: [
      RelationText.I_BUY_THIS_PRODUCT as string,
      RelationText.I_SELL_THIS_PRODUCT as string,
      RelationText.THIS_IS_A_SUBSTITUTE_PRODUCT as string,
      RelationText.OTHER as string,
    ],
  },
  {
    display: "Self",
    dropdownData: RelationType.SELF,
  },
  {
    display: "Other",
    dropdownData: RelationType.OTHER,
  },
];

export interface RelationTextAndType {
  text: RelationText;
  type: RelationType;
}

export enum ActiveUploadType {
  URL,
  TEXT,
  FILE,
}

export enum QuickUploadActionChoices {
  CHANGE_UPLOAD_TYPE,
}

export type QuickUploadState = {
  uploadType: ActiveUploadType;
};

export type QuickUploadActions = {
  type: QuickUploadActionChoices.CHANGE_UPLOAD_TYPE;
  newUploadType: ActiveUploadType;
};
