import React, { useEffect, useLayoutEffect, useState } from 'react'
import CloseModalBtn from '../../Components/CloseModalBtn/CloseModalBtn';
import styles from './SocialLogin.module.scss'
import twitter from "../../../assets/img/twitter.png";
import linkedin from "../../../assets/img/linkedin.png";
import { LINKEDIN_CLIENT_ID } from '../../../constants';
import { useUserContext } from '../../context/allContexts';
import { useTwitterProcess } from '../../../rq-hooks/useTwitterProcess';

const SocialLogin = ({showAskMeInfoModal, onHide}) => {
  const [shouldRender, setShouldRender] = useState(showAskMeInfoModal);
  const [callBack, setCallBack] = useState(null);
  const {handleCallback, linkedInAccessToken, linkedInUserData} = useUserContext()
  const [notIntegrate, setNotIntegrate] = useState(0)
  const {mutate} = useTwitterProcess({
    onSuccess: (data: any) => {
      const responseArray = data.split('&')
      console.log(responseArray)
      // 'https://api.twitter.com/oauth/authorize?oauth_token=Z6eEdO8MOmk394WozF5oKyuAv855l4Mlqo7hxxxxxx'
      window.location.href = `https://api.twitter.com/oauth/authorize?${responseArray[0]}`
    }
  })
  useEffect(() => {
    if (showAskMeInfoModal) setShouldRender(true);
  }, [showAskMeInfoModal]);

  const onAnimationEnd = () => {
    if (!showAskMeInfoModal) setShouldRender(false);
  };
  useLayoutEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    setCallBack(`${location.origin}${location.pathname}`)
    handleCallback(callBack)
  }, [])

  const handleHandleTwitterLogin = () => {
    mutate({
      callback: encodeURI(callBack)
    })
  } 

  return (
    shouldRender && (
      <React.Fragment>
        <div
          onAnimationEnd={onAnimationEnd}
          className={`${styles.backdrop} ${
            showAskMeInfoModal ? styles.backdrop : styles.fadeOut
          }`}
          onClick={() => onHide(notIntegrate)}
        />
        <div
          className={`${styles.askMeInfoModalWrapper} ${
            showAskMeInfoModal ? styles.askMeInfoModalWrapper : styles.slideUp
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.closeBtn}>
            <CloseModalBtn theme="light" close={() => onHide(notIntegrate)} />
          </div>
          <span className={styles.logo}>
            <img src="https://www.nowigence.com/wp-content/uploads/2020/09/White-Logo.png" />
          </span>
          <div className={styles.header}><h2>Share Insights on LinkedIn / Twitter and become a Thought leader!</h2></div>
          <div className={styles.content}><p>
            Integrate with LinkedIn or Twitter to share your insights and earn points every time you do.
          </p></div>
          
          <div className={styles.socialContainer}>
            <div className={styles.socialLogins}>
              <a href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${callBack}&scope=r_liteprofile%20r_emailaddress%20w_member_social`} className={styles.linkedin}>
                <img src={linkedin} alt="Linkedin" />
              </a>
              <a href="#" onClick={() => handleHandleTwitterLogin()} className={styles.twitter}>
                <img src={twitter} alt="Twitter" />
              </a>
            </div>
          </div>
          <div className={styles.socialContainer}>
            <div className={`${styles.socialLogins} ${styles.input}`}>
              <input type="checkbox" name="notIntegrate" id="" value={1} onChange={(e) => setNotIntegrate(e.target.value)} />
              <span>I don't want to integrate</span>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
}

export default SocialLogin