// LIBRARY IMPORTS
import { FC, useEffect, useLayoutEffect, useReducer, useState } from "react";
import { AiOutlineLinkedin, AiOutlineShareAlt, AiOutlineTwitter } from "react-icons/ai";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";

// ICONS
import { FaCaretDown, FaCaretUp, FaRegTrashAlt } from "react-icons/fa";

// CONTEXT
import {
  useSocialLoginContext,
  useUserContext,
  useViewPortContext,
} from "../../../context/allContexts";
import ShareModal from "../ShareModal/ShareModal";
import ACDisplaySharedBy from "./ACDisplaySharedBy/ACDisplaySharedBy";

//STYLES
import styles from "./ReaderCard.module.scss";

// COMPONENTS
import AddToNotebookBtn from "../../../Components/AddToNotebookBtn/AddToNotebookBtn";
// import ToolTip from "../../../Components/ToolTip/ToolTip";
import ArticleCardImage from "./ArticleCardImage/ArticleCardImage";
import ContentModal from "./ContentModal/ContentModal";
import DisplayHarvestDate from "./DisplayHarvestDate/DisplayHarvestDate";
import DisplayLinkOrModal from "./DisplayLinkOrModal/DisplayLinkOrModal";
import DisplaySetupTopicOrSharedBy from "./DisplaySetupTopicOrSharedBy/DisplaySetupTopicOrSharedBy";
import NewsPrivOrCompLabel from "./NewsPrivOrCompanyLabel/NewsPrivOrCompLabel";
import ACDisplayTags from "./ACDisplayTags/ACDisplayTags";
import Benchmarks from "./Benchmarks/Benchmarks";
import InsightsNer from "./InsightsNER/InsightsNer";
import OtherNER from "./OtherNER/OtherNER";
import RiskOppInfo from "./RiskOppInfo/RiskOppInfo";
import { useMyMemoryContext } from "../MyMemoryContext";
import { MyMemoryActionChoices } from "../MyMemoryTypes";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../Components/LoadingCube/LoadingCube";
import ComponentMessagingOverlay from "../../../Components/ComponentMessagingOverlay/ComponentMessagingOverlay";

// MISC
import {
  ArticleMeta,
  Maybe,
  useArticleCardQuery,
  useDeleteSingleArticleMutation,
  UserMetaData,
  useSaveNewsArticleMutation,
  useUserListQuery,
} from "../../../../codeGenFE";
import { customErrorHandler } from "../../../../utils/customErrorHandler";
import { BiBrain } from "react-icons/bi";
import { HiDotsHorizontal } from "react-icons/hi";
import LinkedinShare from "./SocialShare/LinkedinShare/index";
import { LINKEDIN_CLIENT_ID } from '../../../../constants';
import TwitterShare from "./SocialShare/TwitterShare";
import { useTwitterProcess } from "../../../../rq-hooks/useTwitterProcess";
import { setTimeout } from "timers";

export enum ArticleCardActionChoices {
  SHOW_SUMMARY_ARTICLE_MOBILE,
  SHOW_DRIVER_ARTICLE_MOBILE,
  SET_ARTICLE_MENU,
  SET_IS_HOVERED,
  SET_SHARE_MODAL,
  SET_SHOW_DELETE_CONFIRM,
  SET_IS_EXPANDED,
  CHANGE_SUMMARY_DISPLAY_COUNT,
  CHANGE_DRIVER_DISPLAY_COUNT,
  SHARE_ARTICLE_LINKEDIN,
  SHARE_ARTICLE_TWITTER,
}

type ArticleCardState = {
  articleSummaryDisplayCount: number;
  articleDriversDisplayCount: number;
  shareModal: boolean;
  showDeleteConfirmation: boolean;
  isHovered: boolean | string;
  isExpanded: boolean;
  showArticleMenu: boolean;
  shareArticleLinkedin: boolean;
  shareArticleTwitter: boolean;
};

export type ArticleCardActions =
  | {
      type: ArticleCardActionChoices.SHOW_SUMMARY_ARTICLE_MOBILE;
      payload: number;
    }
  | {
      type: ArticleCardActionChoices.SHOW_DRIVER_ARTICLE_MOBILE;
      payload: number;
    }
  | {
      type: ArticleCardActionChoices.SET_SHARE_MODAL;
      payload: boolean;
    }
  | {
      type: ArticleCardActionChoices.SET_IS_HOVERED;
      payload?: boolean | string;
    }
  | { type: ArticleCardActionChoices.SET_IS_EXPANDED; payload: boolean }
  | {
      type: ArticleCardActionChoices.CHANGE_DRIVER_DISPLAY_COUNT;
      count: number;
    }
  | {
      type: ArticleCardActionChoices.CHANGE_SUMMARY_DISPLAY_COUNT;
      count: number;
    }
  | { type: ArticleCardActionChoices.SET_ARTICLE_MENU; isOpen: boolean }
  | { type: ArticleCardActionChoices.SHARE_ARTICLE_LINKEDIN; payload: boolean; }
  | { type: ArticleCardActionChoices.SHARE_ARTICLE_TWITTER; payload: boolean; }
  | { type: ArticleCardActionChoices.SET_SHOW_DELETE_CONFIRM; isOpen: boolean };

const myMemoryReadMoreReducer = (
  state: ArticleCardState,
  action: ArticleCardActions
) => {
  switch (action.type) {
    // case "showSummaryArticleInMobile": {
    case ArticleCardActionChoices.SHOW_SUMMARY_ARTICLE_MOBILE: {
      return {
        ...state,
        // articleSummaryDisplayCount: action.articleSummaryDisplayCount,
        articleSummaryDisplayCount: action.payload,
      };
    }
    // case "showDriversArticleInMobile": {
    case ArticleCardActionChoices.SHOW_DRIVER_ARTICLE_MOBILE: {
      return {
        ...state,
        // articleDriversDisplayCount: action.articleDriversDisplayCount,
        articleDriversDisplayCount: action.payload,
      };
    }
    case ArticleCardActionChoices.SET_SHARE_MODAL: {
      return {
        ...state,
        shareModal: action.payload,
      };
    }
    case ArticleCardActionChoices.SHARE_ARTICLE_LINKEDIN: {
      return {
        ...state,
        shareArticleLinkedin: action.payload,
      };
    }
    case ArticleCardActionChoices.SHARE_ARTICLE_TWITTER: {
      return {
        ...state,
        shareArticleTwitter: action.payload,
      };
    }
    case ArticleCardActionChoices.SET_SHOW_DELETE_CONFIRM: {
      return {
        ...state,
        showDeleteConfirmation: action.isOpen,
      };
    }
    case ArticleCardActionChoices.SET_IS_HOVERED: {
      return {
        ...state,
        isHovered: action.payload ? action.payload : !state.isHovered,
      };
    }
    case ArticleCardActionChoices.SET_ARTICLE_MENU: {
      return {
        ...state,
        showArticleMenu: action.isOpen,
        showDeleteConfirmation: false,
      };
    }
    case ArticleCardActionChoices.SET_IS_EXPANDED: {
      return {
        ...state,
        isExpanded: action.payload,
      };
    }
    case ArticleCardActionChoices.CHANGE_SUMMARY_DISPLAY_COUNT:
      return {
        ...state,
        articleSummaryDisplayCount: action.count,
      };
    case ArticleCardActionChoices.CHANGE_DRIVER_DISPLAY_COUNT:
      return {
        ...state,
        articleDriversDisplayCount: action.count,
      };
    default:
      return state;
  }
};

const initState: ArticleCardState = {
  articleSummaryDisplayCount: 7,
  articleDriversDisplayCount: 7,
  showArticleMenu: false,
  shareModal: false,
  showDeleteConfirmation: false,
  isHovered: false,
  isExpanded: false,
  shareArticleLinkedin: false,
  shareArticleTwitter: false,
};

// TODO: Type this out
interface Props {
  data: ArticleMeta;
}

const ReaderCard: FC<Props> = ({ data }) => {
  const qClient = useQueryClient();
  const [callBack, setCallBack] = useState<any>(null);
  const [extendedPopup, setExtendedPopup] = useState<any>(false);
  const { user, disableShare } = useUserContext();
  const {socialLoginState, socialLoginDispatch} = useSocialLoginContext()
  const [shareUsersExists, setShareUsersExists] = useState(false)
  const {
    linkedInUserData, 
    twitterToken, twitterVerifier} = socialLoginState
  const { data: shareList, status, error: shareError, refetch: shareRefetch, isFetching: shareIsFetching } = useUserListQuery();  
  // @ts-ignore
  const { myMemDispatcher } = useMyMemoryContext();


  useLayoutEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    setCallBack(`${location.origin}${location.pathname}`)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if(localStorage.getItem('reloaded') && localStorage.getItem('reloaded') === 'true' && localStorage.getItem('articleShared') && localStorage.getItem('shareType') && localStorage.getItem('articleShared') === data._id) {
        myMemoryReadMoreDispatch({
          type: localStorage.getItem('shareType') === "twitter" ? ArticleCardActionChoices.SHARE_ARTICLE_TWITTER : ArticleCardActionChoices.SHARE_ARTICLE_LINKEDIN,
          payload: true,
        })
      }
    }, 2000)
  },[data])

  useEffect(() => {
    const userList = shareList?.userList;
    console.log(userList, "userList")
    if(!userList || !userList.length) setShareUsersExists(false)
    else setShareUsersExists(true)
  }, [shareList])
  

  const [myMemoryReadMoreState, myMemoryReadMoreDispatch] = useReducer(
    myMemoryReadMoreReducer,
    initState
  );
  const {
    articleSummaryDisplayCount,
    articleDriversDisplayCount,
    showArticleMenu,
    isHovered,
    shareModal,
    showDeleteConfirmation,
    isExpanded,
    shareArticleLinkedin,
    shareArticleTwitter,
  } = myMemoryReadMoreState;

  // @ts-ignore
  const { width } = useViewPortContext();
  const [isShowingContentModal, setIsShowingContentModal] = useState(false);
  const {mutate: twitterProcess} = useTwitterProcess({
    onSuccess: (data: any) => {
      const responseArray = data.split('&')
      // 'https://api.twitter.com/oauth/authorize?oauth_token=Z6eEdO8MOmk394WozF5oKyuAv855l4Mlqo7hxxxxxx'
      window.location.href = `https://api.twitter.com/oauth/authorize?${responseArray[0]}`
    }
  })

  const {
    data: remainingData,
    error,
    isFetching,
    refetch,
  } = useArticleCardQuery(
    { articleId: data._id!, whichDB: data.whichDB! },
    { enabled: !!isExpanded, staleTime: Infinity }
  );

  const user_ro = remainingData?.article.user_ro;
  const ner_norm = remainingData?.article.ner_norm;
  const tags = remainingData?.article.tags;
  const sentiment_score = remainingData?.article.sentiment_score;
  const PlanType = remainingData?.article._source.PlanType;
  const sequenceId = remainingData?.article._source.sequenceId;
  // const summary = remainingData?.article?._source?.summary;
  const driver = remainingData?.article?._source?.driver;
  

  // Add to my memory mutation
  const { mutate: saveNewsArticle } = useSaveNewsArticleMutation({
    onSuccess: async () => {
      toast.success("Successfully saved article to your memory!");
      await qClient.fetchInfiniteQuery("myMemoryInfinite");
    },
  });

  // Delete Mutation
  const { mutate } = useDeleteSingleArticleMutation({
    onSuccess: async () => {
      try {
        // Not managing the error state here, because it will be handled by the hook that deals with it.
        toast.success("Article deleted, retrieving new articles!", {
          duration: 3000,
        });
        await qClient.fetchInfiniteQuery("myMemoryInfinite");
      } catch (error) {}
    },
  });

  // destructuring for readability below
  const {
    _source,
    keywordList,
    pubStatus,
    proImageLink,
    sharedBy,
    userMetaData,
    _id,
  } = data;

  const harvestDate = _source?.harvestDate;
  const title = _source?.title;
  const uploadDate = _source?.uploadDate;
  const orig_url = _source?.orig_url;
  const summary = _source?.summary;
  const name = _source?.source?.name;

  // This is commented out at the moment at Andrews request.  Do not delete.
  // in the future this will need to be figured out on the python side as to how
  // we will remove duplicates
  const removeDuplicateSumAndDrivers = (
    summary: Maybe<string>[] | [],
    driver: Maybe<string>[] | []
  ) => {
    let filteredSummaryList = summary?.filter((sum) => {
      for (let i = 0; i < driver.length; i++) {
        if (sum === driver[i]) {
          return false;
        }
      }
      return true;
    });

    return (
      <div className={styles.sumNDrivers}>
        <ul>
          <h2>SUMMARY</h2>
          {filteredSummaryList
            ?.slice(0, articleSummaryDisplayCount)
            .map((s: any, id: any) => {
              if (s !== "") {
                return (
                  <li key={id}>
                    <div className={styles.sumNDrivers__Btn}>
                      <AddToNotebookBtn content={s} style={"unstyled"} />
                    </div>
                    {s}
                  </li>
                );
              }
              return;
            })}
        </ul>
        {/* Read more summaries button */}
        {filteredSummaryList?.length > 7 && (
          <div className={styles.myMemoryReadMoreBtnSection}>
            <div className={styles.myMemoryReadMore}>
              <button
                className={styles.myMemoryReadMoreBtn}
                onClick={() => {
                  if (width > 900) {
                    myMemDispatcher({
                      type: MyMemoryActionChoices.TOGGLE_SUM_DRIV_OVERLAY,
                      payload: true,
                      selectedArticleID: _id,
                      selectedArticleDB: data.whichDB,
                    });
                  } else {
                    myMemoryReadMoreDispatch({
                      type: ArticleCardActionChoices.CHANGE_SUMMARY_DISPLAY_COUNT,
                      count:
                        articleSummaryDisplayCount > 7
                          ? 7
                          : filteredSummaryList.length,
                    });
                  }
                }}
              >
                Read More
              </button>
            </div>
          </div>
        )}
        <ul>
          <h2>DRIVER{driver.length <= 1 ? "" : "S"}</h2>
          {driver.slice(0, articleDriversDisplayCount).map((driv, i) => {
            return (
              <li key={i}>
                <div className={styles.sumNDrivers__Btn}>
                  {driv !== "This article does not contain a driver." ? (
                    <AddToNotebookBtn
                      content={driv as string}
                      style={"unstyled"}
                    />
                  ) : null}
                </div>
                {driv}
              </li>
            );
          })}
        </ul>
        {/* Read more drivers button */}
        {driver.length > 7 && (
          <div className={styles.myMemoryReadMoreBtnSection}>
            <div className={styles.myMemoryReadMore}>
              <button
                className={styles.myMemoryReadMoreBtn}
                onClick={() => {
                  if (width > 900) {
                    myMemDispatcher({
                      type: MyMemoryActionChoices.TOGGLE_SUM_DRIV_OVERLAY,
                      payload: true,
                      selectedArticleID: _id,
                      selectedArticleDB: data.whichDB,
                    });
                  } else {
                    myMemoryReadMoreDispatch({
                      type: ArticleCardActionChoices.CHANGE_DRIVER_DISPLAY_COUNT,
                      count: articleDriversDisplayCount > 7 ? 7 : driver.length,
                    });
                  }
                }}
              >
                Read {articleDriversDisplayCount === 7 ? "More" : "Less"}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleLinkedInLogin = (id: any) => {
    localStorage.setItem('reloaded', "true")
    localStorage.setItem('shareType', "linkedin")
    localStorage.setItem('articleShared', id)
    localStorage.removeItem('activeNotebook')
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${callBack}&scope=r_liteprofile%20r_emailaddress%20w_member_social`
  }
  const handleTwitterLogin = (id: any) => {
    localStorage.setItem('reloaded', "true")
    localStorage.setItem('shareType', "twitter")
    localStorage.setItem('articleShared', id)
    localStorage.removeItem('activeNotebook')
    // refetch();
    if(!twitterToken && !twitterVerifier) {
      twitterProcess({
        callback: encodeURI(callBack)
      })
    } else {
      if(data._source?.source?.name === "note") {
        if(data._source?.content && data._source?.content.length > 280) {
          setExtendedPopup(true)
          setTimeout(() => {
            setExtendedPopup(false)
          }, 5000)
          return 
        }
      }
      myMemoryReadMoreDispatch({
        type: ArticleCardActionChoices.SHARE_ARTICLE_TWITTER,
        payload: true,
      })
    }
  }
  return (
    <div className={styles.verticleExpandedParentContainer}>
      {isShowingContentModal && (
        <ContentModal
          articleId={_id!}
          whichDb={data.whichDB!}
          isShowingContentModal={isShowingContentModal}
          setIsShowingContentModal={setIsShowingContentModal}
        />
      )}
      <NewsPrivOrCompLabel
        pubStatus={pubStatus}
        userMetaData={userMetaData && (userMetaData as UserMetaData[])}
      />
      <ArticleCardImage
        proImageLink={proImageLink}
        pubStatus={pubStatus}
        // Todo: Add in missing data below
        name={name === "file" ? "note" : name}
        // name={"name"}
        title={title}
      />
      <div className={styles.articleHeadingContainer}>
        <DisplayLinkOrModal
          orig_url={orig_url!}
          title={title!}
          sharedBy={sharedBy!}
          setIsShowingContentModal={setIsShowingContentModal}
        />
        <div className={styles.srcAndDateContainer}>
          <p>
            <span className={styles.capUserTitles}>
              {name && name === "file" ? "note" : name}
            </span>
            <DisplayHarvestDate
              harvestDate={harvestDate}
              uploadDate={uploadDate && uploadDate}
              userMetaData={userMetaData}
            />
            <DisplaySetupTopicOrSharedBy
              keywordList={keywordList}
              pubStatus={pubStatus}
              sharedBy={sharedBy}
              userMetaData={userMetaData && (userMetaData as UserMetaData[])}
            />
          </p>
          <ul>
            <li className={isExpanded ? undefined : styles.firstSentence}>
              {summary?.[0] ? summary[0] : ""}
            </li>
          </ul>
          <div
            className={`${styles.moreUpDownContainer} ${
              isExpanded ? "targetCloseAll" : null
            }`}
            onClick={() => {
              myMemoryReadMoreDispatch({
                type: ArticleCardActionChoices.SET_IS_EXPANDED,
                payload: !isExpanded,
              });
              // myMemDispatcher({
              //   type: MyMemoryActionChoices.SET_SELECTED_ARTICLEID,
              //   selectedArticleID: _id,
              // });
            }}
          >
            {isExpanded ? (
              <FaCaretUp className={styles.upAndDownToggleIcon} />
            ) : (
              <FaCaretDown className={styles.upAndDownToggleIcon} />
            )}

            <span className={styles.upAndDownMoreBtn}>
              {isExpanded ? `Less` : "More"}
            </span>
          </div>
        </div>
        {pubStatus !== "help" && pubStatus !== "error" && (
          <div className={styles.iconContainer}>
            <button
              className={styles.threeDotMenu}
              onClick={() => {
                myMemoryReadMoreDispatch({
                  type: ArticleCardActionChoices.SET_ARTICLE_MENU,
                  isOpen: !showArticleMenu,
                });
              }}
            >
              <HiDotsHorizontal className={styles.dots} />
            </button>
            {showArticleMenu && (
              <div
                className={styles.articleCardFloatingMenu}
                onMouseLeave={() =>
                  {
                    myMemoryReadMoreDispatch({
                      type: ArticleCardActionChoices.SET_ARTICLE_MENU,
                      isOpen: false,
                    });
                    setExtendedPopup(false)
                  }
                }
              >
                {
                  shareUsersExists && 
                  <button
                    className={styles.iconBtn}
                    onClick={() =>
                      myMemoryReadMoreDispatch({
                        type: ArticleCardActionChoices.SET_SHARE_MODAL,
                        payload: true,
                      })
                    }
                  >
                    <AiOutlineShareAlt className={styles.icon} /> Share
                  </button>
                }
                <button
                  className={styles.iconBtn}
                  onClick={() =>
                    {
                      linkedInUserData ? 
                      myMemoryReadMoreDispatch({
                        type: ArticleCardActionChoices.SHARE_ARTICLE_LINKEDIN,
                        payload: true,
                      })
                      :
                      handleLinkedInLogin(data?._id)
                    }
                  }
                >
                  <AiOutlineLinkedin className={styles.icon} /> Share on Linkedin
                </button> 
                {
                    data._source?.source?.name !== 'file' ? 
                    <div className={styles.twitterContent}>
                      <button
                          className={styles.iconBtn}
                          onClick={() =>
                            {
                              handleTwitterLogin(data?._id)
                            }
                          }
                        >
                          <AiOutlineTwitter className={styles.icon} /> Share on Twitter
                        </button>  
                    </div> : ""
                } 
                <button
                  className={styles.iconBtn}
                  onClick={() =>
                    myMemoryReadMoreDispatch({
                      type: ArticleCardActionChoices.SET_SHOW_DELETE_CONFIRM,
                      isOpen: !showDeleteConfirmation,
                    })
                  }
                >
                  <FaRegTrashAlt className={styles.icon} /> Delete
                </button>
                {/* Only shows if this is a news article */}
                {data.pubStatus === "public" && (
                  <button
                    className={styles.iconBtn}
                    onClick={() => {
                      saveNewsArticle({
                        saveNewsArticleId: data._id!,
                        whichDb: data.whichDB!,
                      });
                    }}
                  >
                    <BiBrain className={styles.icon} /> Add to My Memory
                  </button>
                )}
                {/* Delete Confirmation Overlay */}
                {showDeleteConfirmation && (
                  <div className={styles.deleteConfirmModal}>
                    <p className={styles.deleteConfirmModal__text}>
                      Are you sure?
                    </p>
                    {data._source?.source?.name === "note" && (
                      <p className={styles.deleteConfirmModal__text}>
                        Warning: This will delete the article's note as well!
                      </p>
                    )}
                    <div className={styles.deleteConfirmModal__btnWrapper}>
                      <button
                        className={`${styles.deleteConfirmModal__btn} ${styles.deleteConfirmModal__cancel}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          myMemoryReadMoreDispatch({
                            type: ArticleCardActionChoices.SET_SHOW_DELETE_CONFIRM,
                            isOpen: false,
                          });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className={styles.deleteConfirmModal__btn}
                        onClick={() =>
                          mutate({
                            options: {
                              articleId: data._id!,
                              whichDB: data.whichDB!,
                            },
                          })
                        }
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* <div className={styles.toolTipWrapper}>
              {!disableShare && (
                <AiOutlineShareAlt
                  className={`${styles.icon} ${
                    pubStatus === "shared" && styles.disabled
                  }`}
                  onClick={() =>
                    pubStatus !== "shared" &&
                    myMemoryReadMoreDispatch({
                      type: ArticleCardActionChoices.SET_SHARE_MODAL,
                      payload: true,
                    })
                  }
                  onMouseEnter={() =>
                    myMemoryReadMoreDispatch({
                      type: ArticleCardActionChoices.SET_IS_HOVERED,
                      payload: "share",
                    })
                  }
                  onMouseLeave={() =>
                    myMemoryReadMoreDispatch({
                      type: ArticleCardActionChoices.SET_IS_HOVERED,
                      payload: false,
                    })
                  }
                />
              )}
              {isHovered === "share" && width > 650 && (
                <ToolTip toolTip="Share" /> // styled is here because js/ts is dumb
              )}
            </div>
            <div className={styles.toolTipWrapper}>
              <FaRegTrashAlt
                className={styles.icon}
                onClick={() =>
                  myMemoryReadMoreDispatch({
                    type: ArticleCardActionChoices.SET_ICON_MODAL,
                    payload: "delete",
                  })
                }
              />
              {isHovered === "delete" && width > 650 && (
                <ToolTip toolTip="Delete Article" />
              )}
            </div>
            {iconModal === "delete" && (
              // @ts-ignore
              <IconModal
                func={() =>
                  mutate({
                    options: { articleId: data._id!, whichDB: data.whichDB! },
                  })
                }
                close={() =>
                  myMemoryReadMoreDispatch({
                    type: ArticleCardActionChoices.SET_ICON_MODAL,
                    payload: false,
                  })
                }
                label={"Are you sure?"}
                type="delete"
              />
            )}
            {iconModal === "share" && (
              <IconModal label="Shared Article" type="share" />
            )} */}
          </div>
        )}
        {shareModal && (
          <ShareModal
            article={data!}
            close={() =>
              myMemoryReadMoreDispatch({
                type: ArticleCardActionChoices.SET_SHARE_MODAL,
                payload: false,
              })
            }
            iconModalTrigger={myMemoryReadMoreDispatch}
          />
        )}
      </div>
      {
        extendedPopup ? <span className={styles.extendedPopup}>
        Note must have less than 281 characters for posting to twitter
      </span> : ""
      }
      {
        shareArticleLinkedin ? <LinkedinShare 
        close={() => myMemoryReadMoreDispatch({
            type: ArticleCardActionChoices.SHARE_ARTICLE_LINKEDIN,
            payload: false,
          })
        }
        refetch={refetch} 
        showLinkedinSharePopup={shareArticleLinkedin}
        article={data}
        driver={driver}
        isFetching={isFetching}
        /> : 
        ""
      }
      {
        shareArticleTwitter ? <TwitterShare 
        close={() => myMemoryReadMoreDispatch({
            type: ArticleCardActionChoices.SHARE_ARTICLE_TWITTER,
            payload: false,
          })
        } 
        showTwitterPopup={shareArticleTwitter}
        article={data}
        /> : 
        ""
      }
      {isExpanded && (
        <div className={styles.insights}>
          <h2>INSIGHTS</h2>
          <RiskOppInfo
            sentiment_score={sentiment_score}
            pubStatus={pubStatus}
            user_ro={user_ro}
          />
          <hr className={styles.line} />
          <p className={styles.insights__p}>Category: {PlanType}</p>
          <hr className={styles.line} />
          {orig_url !== "No url for this file" && (
            <p className={styles.insights__p}>Related Article(s):</p>
          )}
          {orig_url !== "No url for this file" && (
            <Benchmarks
              sequenceId={sequenceId}
              // orig_url={orig_url && orig_url}
            />
          )}
          {orig_url !== "No url for this file" && (
            <hr className={styles.line} />
          )}
          {pubStatus !== "help" && (
            <>
              <ACDisplayTags
                // @ts-ignore
                tags={tags?.[user._id] ? tags[user._id] : []}
                id={_id!}
                WhichDb={data.whichDB!}
                label="Article Tag(s):"
                pubStatus={pubStatus!}
              />
              <hr className={styles.line} />
            </>
          )}
          <InsightsNer
            ner={ner_norm?.ORG ? ner_norm.ORG : []}
            label="Company(s): "
            category={"company"}
          />
          <hr className={styles.line} />
          <InsightsNer
            ner={ner_norm?.PRODUCT ? ner_norm.PRODUCT : []}
            label="Product(s): "
            category={"product"}
          />
          <hr className={styles.line} />
          <InsightsNer
            ner={ner_norm?.PERSON ? ner_norm.PERSON : []}
            label="People: "
            category={"person"}
          />
          <hr className={styles.line} />
          <InsightsNer
            ner={ner_norm?.GPE ? ner_norm.GPE : []}
            label="Location(s): "
            category={"location"}
          />
          {pubStatus !== "help" && <hr className={styles.line} />}
          {userMetaData && (
            <ACDisplaySharedBy
              label="Shared By:"
              userMetaData={userMetaData as UserMetaData[]}
              uId={user._id}
            />
          )}
          {/* // todo: Add this back in */}
          {/* {userMetaData?.filter(u => u?.userId === user.id)?.[0]?.sharedBy.length > 0 && <hr className={styles.line} />} */}
          {pubStatus !== "help" && <OtherNER ner={ner_norm} label="Other: " />}
        </div>
      )}
      {summary &&
        driver &&
        isExpanded &&
        removeDuplicateSumAndDrivers(summary, driver)}
      {error && isExpanded && (
        <ComponentMessagingOverlay
          close={refetch}
          message={customErrorHandler(error)}
          showX={true}
          buttonText={"Retry?"}
        />
      )}
      {isFetching && isExpanded && (
        <div className={styles.fetchingSpinnerWrapper}>
          <LoadingCube
            hasBackground={width > 900 ? false : true}
            height={width > 900 ? 15 : 100}
            width={width > 900 ? 15 : 100}
            theme={width > 900 ? LoadingCubeTheme.DARK : LoadingCubeTheme.DARK}
          />
        </div>
      )}
    </div>
  );
};

export default ReaderCard;
