import React, { FC, useEffect, useState } from "react";

// components
import { MdArrowDropDown } from "react-icons/md";
import { useFetchUserCategoriesQuery } from "../../../../../codeGenFE";

// Data
import { useSearchContext } from "../../../../context/allContexts";
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";
import DropdownDisplayBelow from "../../../DropdownDisplayBelow/DropdownDisplayBelow";

// styles
import styles from "./filters.module.scss";

interface Props {
  openFilter: string;
  setOpenFilter: (arg: string) => void;
}

const CategoryFilter: FC<Props> = ({ openFilter, setOpenFilter }) => {
  const { searchState, searchDispatcher } = useSearchContext();

  const categoryChoice = searchState.categoryChoice;

  const { data, status, error } = useFetchUserCategoriesQuery();

  const categories = data?.fetchUserCategories;

  return (
    <div
      className={styles.outcomeFilterWrapper}
      data-is-open={`${openFilter === "category"}`}
    >
      <DropdownDisplayBelow
        selectableItems={categories ? (categories as string[]) : []}
        isDropdownOpen={openFilter === "category"}
        toggleDropdown={() =>
          setOpenFilter(openFilter === "category" ? "" : "category")
        }
        dropdownDisplay={"Categories"}
        selectionDisplay={categoryChoice}
        select={(category) =>
          searchDispatcher({
            type: SearchContextActions.SET_CATEGORY_CHOICE,
            category: category,
          })
        }
        unselect={() =>
          searchDispatcher({
            type: SearchContextActions.SET_CATEGORY_CHOICE,
            category: null,
          })
        }
        makeSearchable={true}
      />
    </div>
  );
};

export default CategoryFilter;
