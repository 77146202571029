import React, { FC } from "react";

import styles from "./IntegrationTooltip.module.scss";

// pass in "string" name of component. style changes in styles
interface Props {
  toolTip: string;
  type?: string;
}

const IntegrationTooltip: FC<Props> = ({ toolTip, type }) => {
  let specialStyles = null;
  return (
    <div className={`${styles.toolTipWrapper} ${type === 'twitter' ? styles.twitter: "" }`}>
      <p>{toolTip}</p>
    </div>
  );
};

export default IntegrationTooltip;
