// import React, { useReducer } from "react";
import { useUserListQuery } from "../../../../../codeGenFE";
// import { useStateLabel } from "../../../../hooks/stateLabeler";
import ShareList, { ShareListTheme } from "../../../ShareList/ShareList";
import styles from "./QUShare.module.scss";

// const shareListReducer = (state, action) => {
//   switch (action.type) {
//     case "showChosen":
//       return {
//         ...state,
//         showChosen: true,
//       };
//     case "share all":
//       return {
//         ...state,
//         showChosen: true,
//       };
//   }
//   return state;
// };

// const initState = {
//   isOpen: false,
//   showChosen: false,
// };

function QUShare({
  shareAll,
  setShareAll,
  selectedUsers,
  setSelectedUsers,
  cancel,
  hidden,
}) {
  // const [shareListState, shareListDispatch] = useReducer(
  //   shareListReducer,
  //   initState
  // );

  // const [showChosen, setShowChosen] = useStateLabel(false, "showChosen");
  const { data } = useUserListQuery(null, {
    onSuccess: () => {
      // console.log("data from userLists", data);
    },
    onError: (err) => {
      console.log("err", err);
    },
  });

  if (hidden) {
    return null;
    // } else if (showChosen) {
    //   return (
    //     <ShowChosen
    //       selectedUsers={selectedUsers}
    //       shareAll={shareAll}
    //       setShowChosen={setShowChosen}
    //       userList={data?.userList}
    //     />
    //   );
  } else {
    return (
      <div className={styles.container}>
        <h4 className={styles.shareList__h4}>Share List</h4>
        <div className={styles.shareListContainer}>
          <ShareList
            shareAll={shareAll}
            setShareAll={setShareAll}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            theme={{
              desktop: ShareListTheme.LIGHT,
              mobile: ShareListTheme.LIGHT,
            }}
          />
        </div>

        {/*<div className={styles.shareBtn__container}>
          <button
            className={`${styles.button} ${styles.button__border}`}
            // onClick={() => setShowChosen(true)}>
            onClick={() => setShowChosen(true)}>
            Add
          </button>
          <button className={`${styles.button}`} onClick={() => cancel()}>
            Cancel
          </button>
        </div>*/}
      </div>
    );
  }
}

export default QUShare;

{
  /*function ShowChosen({ selectedUsers, setShowChosen, shareAll, userList }) {
  const userLookup = {};

  userList.forEach((user) => {
    let name = user.name;
    if (user.lastName) {
      name = name + " " + user.lastName;
    }
    userLookup[user._id] = name;
  });

  if (shareAll) {
    return (
      <div className={`${styles.container} ${styles.showAllChosen__div}`}>
        <h4>Sharing with Everyone</h4>
        <button onClick={() => setShowChosen(false)} className={styles.backBtn}>
          back
        </button>
      </div>
    );
  } else {
    return (
      <div className={`${styles.container} ${styles.chosenNames__div}`}>
        <h4 className={styles.chosenNames__header}>Sharing with:</h4>
        <div className={styles.choseNameslist}>
          {selectedUsers.map((user) => {
            return (
              <p className={styles.sharedNames} key={user}>
                {userLookup[user]}
              </p>
            );
          })}
        </div>
        <button onClick={() => setShowChosen(false)} className={styles.backBtn}>
          back
        </button>
      </div>
    );
  }
}*/
}
