import { FC, useReducer } from "react";
import { MdArrowDropDown } from "react-icons/md";
// import { useUploadContentMutation } from "../../../../../codeGenFE";
import { useURLUpload } from "../../../../../rq-hooks/pythonRestApi/useURLUpload";
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
import { useUserContext, useNavContext, useRewardsContext } from "../../../../context/allContexts";
// COMPONENTS
import QURelationDropdown from "../QURelationDropdown/QURelationDropdown";
import QUShare from "../QUShare/QUShare";
import AddTagsDropdown from "../../../AddTagsDropdown/AddTagsDropdown";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../LoadingCube/LoadingCube";
import QuickUploadMessagingModal from "../../QuickUploadMessagingModal/QuickUploadMessagingModal";
// STYLES
import styles from "../QuickUpload.module.scss";
// TYPES
import {
  ActiveUploadType,
  QuickUploadActionChoices,
  RelationText,
  RelationType,
} from "../QuickUploadTypes";
import {
  ActionChoices,
  Actions,
  InputErrors,
  Props,
  State,
} from "./UrlUploadTypes";
import { Type, useUserRewardLogsMutation, useUserRewardsMutation } from "../../../../../codeGenFE";
const urlUploadReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionChoices.CHANGE_URL_STRING:
      return {
        ...state,
        url: action.url,
        inputError: null,
      };

    case ActionChoices.CHANGE_RELATIONSHIP:
      return {
        ...state,
        isRelationshipDropdownOpen: false,
        inputError: null,
        relationship: {
          text: action.relText,
          type: action.relType,
        },
      };
    case ActionChoices.TOGGLE_RELATIONSHIP_DROPDOWN:
      return {
        ...state,
        isRelationshipDropdownOpen: !state.isRelationshipDropdownOpen,
      };
    case ActionChoices.RESET_RELATIONSHIP:
      return {
        ...state,
        isRelationshipDropdownOpen: false,
        relationship: {
          text: initState.relationship.text,
          type: initState.relationship.type,
        },
        inputError: null,
      };
    case ActionChoices.SET_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    case ActionChoices.TOGGLE_IS_PRIVATE:
      return {
        ...state,
        isPrivate: action.boolean,
      };
    case ActionChoices.TOGGLE_SHARE_ALL:
      return {
        ...state,
        shareAll: action.boolean,
      };
    case ActionChoices.SET_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: action.selectedUsers,
      };
    case ActionChoices.RESET_SHARE:
      return {
        ...state,
        selectedUsers: [],
        shareAll: false,
        isPrivate: true,
      };
    case ActionChoices.SET_INPUT_ERROR:
      return {
        ...state,
        inputError: action.error,
      };
    case ActionChoices.CLEAR_INPUT_ERRORS:
      return {
        ...state,
        inputError: null,
      };
    case ActionChoices.CLOSE_WARNING:
      return {
        ...state,
        closeWarning: action.boolean,
      };
    default:
      return state;
  }
};

const initState: State = {
  url: "",
  relationship: {
    text: RelationText.SELECT_HOW_THIS_TOPIC_RELATES_TO_YOU,
    type: RelationType.DEFAULT,
  },
  isRelationshipDropdownOpen: false,
  tags: [],
  isPrivate: true,
  selectedUsers: [],
  shareAll: false,
  inputError: null,
  closeWarning: false,
};

const UrlUpload: FC<Props> = ({ quickUploadDispatcher }) => {
  const { user, disableShare } = useUserContext();
  const {refetch} = useRewardsContext()
  const { navDispatch } = useNavContext();
  const [urlUploadState, urlUploadDispatcher] = useReducer(
    urlUploadReducer,
    initState
  );

  const {
    url,
    isRelationshipDropdownOpen,
    relationship,
    tags,
    isPrivate,
    shareAll,
    selectedUsers,
    inputError,
    closeWarning,
  } = urlUploadState;


  // In this function system just sending our url data to useURLUpload() hook 
  // that is calling our 
  // python api and on success system is mutating userRewardLogsMutation()
  const { mutate, status, error, reset: resetMutation } = useURLUpload();

  const isValidUrl = (str: string) => {
    try {
      new URL(str);
      return true;
    } catch (_) {
      return false;
    }
  };

  function submitUrl(event: any) {
    event.preventDefault();
    if (url.length <= 0) {
      urlUploadDispatcher({
        type: ActionChoices.SET_INPUT_ERROR,
        error: InputErrors.URL_REQUIRED,
      });
      return;
    } else if (!isValidUrl(url)) {
      urlUploadDispatcher({
        type: ActionChoices.SET_INPUT_ERROR,
        error: InputErrors.INVALID_URL,
      });
      return;
    } else if (
      !isPrivate &&
      Number(selectedUsers.length) === 0 &&
      shareAll === false
    ) {
      urlUploadDispatcher({
        type: ActionChoices.SET_INPUT_ERROR,
        error: InputErrors.NO_USERS_TO_SHARE,
      });
      return;
    } else {
      const data1 = {
        user_id: user._id,
        company_id: user.company,
        url: url.replace(/\s/g, ""),
        share_all: shareAll ? true : false,
        user_relationship_type: relationship.type && relationship.type,
        user_relationship_value: relationship.text && relationship.text,
      };
      // @ts-ignore
      if (tags) data1.tags = tags;

      // @ts-ignore
      if (selectedUsers) data1.share_to = selectedUsers;

      // @ts-ignore
      data1.share_all = shareAll ? true : false;

      // @ts-ignore
      // data1.userFullName = `${user?.name} ${user?.lastName}`;

      // mutate({ inputOpts: data1 });
      localStorage.setItem('urlUploadedFrom', `quickUpload`)
      mutate(data1);
    }
  }

  return (
    <div className={styles.quickUploadModal__wrapper}>
      {/* ========== HANDLE SUCCESS | WARNING | ERROR STATES FROM BACKEND ========== */}
      <QuickUploadMessagingModal
        success={status === "success"}
        warning={closeWarning}
        error={error && customErrorHandler(error)}
        close={() => {
          urlUploadDispatcher({
            type: ActionChoices.CLOSE_WARNING,
            boolean: false,
          });
          resetMutation();
        }}
      />

      {/* ========== Loading ========== */}
      {status === "loading" && (
        <div className={styles.loadingWrapper}>
          <LoadingCube theme={LoadingCubeTheme.LIGHT} />
        </div>
      )}

      {/* ========== Top Button Row ========== */}
      <div className={styles.quickUploadModal__urlTextWrapper}>
        <button className={`${styles.urlTextWrapper_btn} ${styles.active}`}>
          URL
        </button>
        <button
          onClick={() =>
            quickUploadDispatcher({
              type: QuickUploadActionChoices.CHANGE_UPLOAD_TYPE,
              newUploadType: ActiveUploadType.TEXT,
            })
          }
          className={styles.urlTextWrapper_btn}>
          TEXT
        </button>
        <button
          className={styles.urlTextWrapper_btn}
          onClick={() =>
            quickUploadDispatcher({
              type: QuickUploadActionChoices.CHANGE_UPLOAD_TYPE,
              newUploadType: ActiveUploadType.FILE,
            })
          }>
          FILE
        </button>
      </div>
      {/* ========== URL Input ========== */}
      <div className={styles.quickUploadModal__URLWrapper}>
        {(inputError === InputErrors.INVALID_URL ||
          inputError === InputErrors.URL_REQUIRED) && (
          <label className={styles.inputError}>{inputError}</label>
        )}
        <input
          type="text"
          placeholder="Enter a URL"
          value={url}
          onChange={(e) =>
            urlUploadDispatcher({
              type: ActionChoices.CHANGE_URL_STRING,
              url: e.target.value,
            })
          }
          className={styles.quickUploadModal__input}
        />
        {/* ========== Relationship Dropdown ========== */}

        <div
          className={`${styles.dropDown} ${
            isRelationshipDropdownOpen ? styles.activeDropdown : null
          }`}>
          <p
            onClick={() =>
              urlUploadDispatcher({
                type: ActionChoices.TOGGLE_RELATIONSHIP_DROPDOWN,
              })
            }>
            {relationship.text ===
            RelationText.SELECT_HOW_THIS_TOPIC_RELATES_TO_YOU
              ? "Relationship"
              : relationship.text}
          </p>
          <span
            onClick={() =>
              urlUploadDispatcher({
                type: ActionChoices.TOGGLE_RELATIONSHIP_DROPDOWN,
              })
            }>
            <MdArrowDropDown />
          </span>
          {isRelationshipDropdownOpen && (
            // <QURelationDropdown close={() => setRelationDropDown(false)} />
            <QURelationDropdown
              close={() =>
                urlUploadDispatcher({
                  type: ActionChoices.TOGGLE_RELATIONSHIP_DROPDOWN,
                })
              }
              reset={() =>
                urlUploadDispatcher({
                  type: ActionChoices.RESET_RELATIONSHIP,
                })
              }
              changeRelationTextandType={({ relText, relType }) =>
                urlUploadDispatcher({
                  type: ActionChoices.CHANGE_RELATIONSHIP,
                  relText: relText,
                  relType: relType,
                })
              }
            />
          )}
        </div>
        {/* ========== Relationship Dropdown ========== */}
        <AddTagsDropdown
          selectedTags={tags}
          setSelectedTags={(tags) =>
            urlUploadDispatcher({ type: ActionChoices.SET_TAGS, tags: tags })
          }
        />
      </div>
      {inputError === InputErrors.NO_USERS_TO_SHARE && (
        <QuickUploadMessagingModal
          error={customErrorHandler(inputError)}
          close={() =>
            urlUploadDispatcher({ type: ActionChoices.CLEAR_INPUT_ERRORS })
          }
        />
      )}
      <div className={styles.quickUploadModal__privateShareUploadsWrapper}>
        <div className={styles.privNShare}>
          <button
            className={`${styles.privNShare_btn} ${
              isPrivate ? styles.active : null
            }`}
            onClick={(e) => {
              e.preventDefault();
              urlUploadDispatcher({
                type: ActionChoices.TOGGLE_IS_PRIVATE,
                boolean: !isPrivate,
              });
            }}>
            PRIVATE
          </button>
          <button
            className={`${styles.privNShare_btn} ${
              !isPrivate ? styles.active : null
            } ${disableShare ? styles.disableShare : ""}`}
            onClick={(e) => {
              e.preventDefault();
              if (!disableShare) {
                urlUploadDispatcher({
                  type: ActionChoices.TOGGLE_IS_PRIVATE,
                  boolean: !isPrivate,
                });
              } else {
                return null;
              }
            }}>
            SHARE
          </button>
        </div>
        <button className={styles.uploadBtn} onClick={(e) => submitUrl(e)}>
          UPLOAD
        </button>
      </div>
      <QUShare
        shareAll={shareAll}
        setShareAll={(boolean: boolean) =>
          urlUploadDispatcher({
            type: ActionChoices.TOGGLE_SHARE_ALL,
            boolean: boolean,
          })
        }
        selectedUsers={selectedUsers}
        setSelectedUsers={(selectedUsers: [] | string[]) =>
          urlUploadDispatcher({
            type: ActionChoices.SET_SELECTED_USERS,
            selectedUsers: selectedUsers,
          })
        }
        cancel={() => urlUploadDispatcher({ type: ActionChoices.RESET_SHARE })}
        hidden={isPrivate || status !== "idle"}
      />
      <button
        className={styles.nevermind}
        onClick={() => navDispatch({ type: "change which menu", menu: "" })}
        // onClick={(e) => {
        //   e.preventDefault();
        //   urlUploadDispatcher({
        //     type: ActionChoices.CLOSE_WARNING,
        //     boolean: true,
        //   });
        // }}
      >
        Nevermind
      </button>
    </div>
  );
};

export default UrlUpload;
