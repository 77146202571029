import React, { useEffect, useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillLinkedin, AiOutlineCopy } from "react-icons/ai";
import { BLOCKCHAIN_URL } from "../../../../constants";
import InfoButton from "../../../../Icons/InfoButton";
import { useNFTBadge } from "../../../../rq-hooks/useNFTBadge";
import { useTokenTransfer } from "../../../../rq-hooks/useTokenTransfer";
import { useTotalTokensAvailable } from "../../../../rq-hooks/useTotalTokensAvailable";
import {
  useRewardsContext,
  useUserContext,
  useViewPortContext,
} from "../../../context/allContexts";
import CloseModalBtn from "../../CloseModalBtn/CloseModalBtn";
import LoadingCube, { LoadingCubeTheme } from "../../LoadingCube/LoadingCube";
// import { Type, useRewardsDetails, useUserLinkedInShareMutation, useUserRewardLogsMutation, useUserRewardsMutation } from '../../../../../../codeGenFE';

import styles from "./AlgoRandModal.module.scss";
import AlgoRandModalInfo from "./AlgoRandModalInfo";

export enum CloseModalBtnTheme {
  LIGHT,
  DARK,
}
const AlgoRandModal = ({
  close,
  showPopup,
  transferToken,
  setTransferToken,
}: any) => {
  const [shouldRender, setShouldRender] = useState<any>(showPopup);
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [totalToken, setTotalToken] = useState<any>(0);
  const {
    isVerified,
    nftBadge,
    setNftBadge,
    nftErrorCounter,
    setNftErrorCounter,
  } = useUserContext();
  const [badge, setBadge] = useState<any>(nftBadge);
  const [counter, setErrorCounter] = useState<any>(nftErrorCounter);
  // @ts-ignore
  const { width } = useViewPortContext();
  // const { mutate: totalTokens } = useTotalTokensAvailable({
  //   onSuccess: (data: any) => {
  //     console.log(data);
  //     setTotalToken(data?.balance);
  //   },
  // });
  const { mutate: nftVerify, isLoading: nftLoading } = useNFTBadge({
    onSuccess: (data: any) => {
      console.log(data);
      setBadge(data?.nft_url);
      setErrorCounter(0);
      toast.success("NFT verified");
    },
    onError: (data: any) => {
      console.log(data);
      setErrorCounter((prevCounter: any) => {
        prevCounter++;
        return prevCounter++;
      });
      toast.error(data.message);
    },
  });
  const { mutate, isLoading } = useTokenTransfer({
    onSuccess: () => {
      toast.success("Token has been transferred!");
      // totalTokens();
    },
    onError: (data: any) => {
      console.log(data);
      toast.error(data.message);
      // totalTokens();
    },
  });

  useLayoutEffect(() => {
    // totalTokens();
  }, [showPopup]);

  const { user } = useUserContext();
  const { rewardsState } = useRewardsContext();
  const [token, setToken] = useState<any>();
  const [tokenAmount, setTokenAmount] = useState<any>(0);
  const [errors, setErrors] = useState<any>({
    amount: null,
    address: null,
  });

  // @ts-ignore
  //   const { width } = useViewPortContext();

  useEffect(() => {
    if (showPopup) setShouldRender(true);
  }, [showPopup]);

  const onAnimationEnd = () => {
    if (!showPopup) setShouldRender(false);
  };

  const handleClick = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Address Copied!");
  };

  const isInValid = () => {
    let error = false;
    let errorObj = {};
    if (!token || token.length === 0) {
      errorObj = { address: "Please provide wallet address!" };
      error = true;
    }
    // console.log(tokenAmount)
    if (!tokenAmount || tokenAmount <= 0) {
      errorObj = { ...errorObj, amount: "Please provide token amount!" };
      error = true;
    }
    if (tokenAmount && parseInt(tokenAmount) > parseFloat(totalToken)) {
      errorObj = { ...errorObj, amount: "Not enough token!" };
      error = true;
    }
    if (error) {
      console.log(errorObj);
      setErrors((prevError: any) => ({ ...prevError, ...errorObj }));
    }
    return error;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const invalid = isInValid();
    if (invalid) return;
    mutate({
      tokenAmount: parseInt(tokenAmount),
      toWalletAddress: token,
    });
  };

  const handleNFT = () => {
    if (!isVerified)
      return toast.error("Please verify your email from My Account!");
    nftVerify();
  };
  return (
    shouldRender && (
      <React.Fragment>
        <div
          onAnimationEnd={onAnimationEnd}
          className={`${styles.backdrop} ${
            showPopup ? styles.backdrop : styles.fadeOut
          }`}
          onClick={() => {
            setNftBadge(badge);
            setNftErrorCounter(counter);
            close();
          }}
        />
        <div
          className={`${styles.askMeInfoModalWrapper} ${
            showPopup ? styles.askMeInfoModalWrapper : styles.slideUp
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          {!transferToken ? (
            <div className={styles.closeBtn}>
              <CloseModalBtn
                theme={CloseModalBtnTheme.LIGHT}
                close={() => {
                  setNftBadge(badge);
                  setNftErrorCounter(counter);
                  close();
                }}
              />
            </div>
          ) : (
            <>
              <div className={styles.backButton}>
                <button
                  onClick={() => {
                    setTransferToken(false);
                    setErrors({
                      amount: null,
                      address: null,
                    });
                  }}
                >
                  Back
                </button>
              </div>
              <div className={styles.closeBtn}>
                <CloseModalBtn theme={CloseModalBtnTheme.LIGHT} close={close} />
              </div>
            </>
          )}
          {!transferToken ? (
            <>
              <div className={styles.header}>
                <h2>Alogorand Wallet Details</h2>
                <InfoButton
                  className={styles.infoBtn}
                  onClick={() => setShowInfo(true)}
                  style={{cursor: "pointer"}}
                />
              </div>
              <div className={styles.tokenDetails}>
                <div className={styles.details}>
                  <span>Wallet Address: </span>
                  <span className={styles.values} style={{ marginLeft: "5px" }}>
                    {user.walletAddress}
                  </span>
                  <AiOutlineCopy
                    onClick={() => handleClick(user.walletAddress)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className={styles.details}>
                  <span>Tokens Awarded: </span>
                  <span className={`${styles.values} ${styles.points}`}>
                    {totalToken}
                  </span>
                </div>
                <div className={styles.details}>
                  <span className={styles.spanLast}>NFT Badge: </span>
                  <div className={styles.nftBadge}>
                    {!badge || badge.length === 0 ? (
                      <>
                        {counter > 1 ? (
                          <span className={`${styles.values} ${styles.nft}`}>
                            NFT Badge Failed
                          </span>
                        ) : (
                          <span className={`${styles.values} ${styles.nft}`}>
                            Verification Pending
                          </span>
                        )}
                        {counter === 1 ? (
                          <a
                            href="javascript:void(0);"
                            className={nftLoading ? styles.button__loading : ""}
                            onClick={() => handleNFT()}
                          >
                            <span className={styles.button__text}>
                              {!nftLoading ? "Verify" : ""}
                            </span>
                          </a>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <img
                        src={`${BLOCKCHAIN_URL}${badge}`}
                        className={styles.badge}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.details}>
                  <button onClick={() => setTransferToken(true)}>
                    Transfer Token
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <h2 className={styles.tokenTransferHeader}>Transfer Token</h2>
              <div className={styles.tokenDetails}>
                <form onSubmit={handleSubmit}>
                  <div className={`${styles.details} ${styles.inputContainer}`}>
                    <span className={`${styles.spanDiv} my-1`}>
                      To Wallet:{" "}
                    </span>
                    <div className="my-0 w-75">
                      <div className={styles.inputErrorContainer}>
                        <input
                          type="text"
                          name="wallet"
                          value={token}
                          onChange={(e: any) => {
                            setToken(e.target.value);
                            setErrors((error: any) => ({
                              ...error,
                              address: null,
                            }));
                          }}
                        />
                      </div>
                      {errors.address && (
                        <span className={styles.error}>{errors.address}</span>
                      )}
                    </div>
                  </div>
                  <div
                    className={`${styles.details} ${styles.inputContainer} ${styles.tokenAmount}`}
                  >
                    <span className="my-1">Token Amount: </span>
                    <div className="my-0 w-75">
                      <div className={styles.inputErrorContainer}>
                        <input
                          type="number"
                          name="tokenAmount"
                          value={tokenAmount}
                          onChange={(e: any) => {
                            setTokenAmount(e.target.value);
                            setErrors((error: any) => ({
                              ...error,
                              amount: null,
                            }));
                          }}
                          min={1}
                        />
                      </div>{" "}
                      {errors.amount && (
                        <span className={styles.error}>{errors.amount}</span>
                      )}
                    </div>
                  </div>
                  <div className={styles.details}>
                    <span>Total Token: </span>
                    <span className={`${styles.values} ${styles.points}`}>
                      {totalToken}
                    </span>
                  </div>
                  <div className={styles.buttonContainer}>
                    <button type="submit">Transfer Token</button>
                  </div>
                </form>
                <div className={styles.isFetchingNextPageContainer}>
                  {isLoading && (
                    <LoadingCube
                      theme={LoadingCubeTheme.DARK}
                      height={width < 1210 ? 100 : 30}
                      width={width < 1210 ? 100 : 30}
                      isFullScreen={width < 1210 ? true : false}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <AlgoRandModalInfo 
          close={() => setShowInfo(false)}
          algoInfoModal={showInfo}
        />
      </React.Fragment>
    )
  );
};

export default AlgoRandModal;
