import { FC } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { MyMemoryProvider } from '../v2/Pages/MyMemory/MyMemoryContext';
import ControlPanel from '../v2/Pages/Dashboard/ControlPanel';
import MyMemory from '../v2/Pages/MyMemory/MyMemory';
import UserProfile from '../v2/Pages/UserProfile/UserProfile';
import AskMe from '../v2/Pages/AskMe/AskMe';
import Account from '../v2/Pages/Account/Account';
import TermsAndConditions from '../v2/Pages/TermsAndConditions/TermsAndConditions';
import { Toaster } from 'react-hot-toast';
import MahindraPanel from '../v2/Pages/Dashboard/MahindraPanel';
import { useUserContext } from '../v2/context/allContexts';
import OnboardingModal from '../v2/Components/OnboardingModal/OnBoardingModal';

const AuthenticatedApp: FC = () => {
  let queryParams = null;
  // eslint-disable-next-line no-restricted-globals
  const locationData = useLocation();
  let queryFound = true;
  if (locationData.search.length === 0) {
    queryFound = false;
  } else if (locationData.search.length > 0) {
    queryParams = new URLSearchParams(locationData.search);
    if (!queryParams.has('token')) {
      queryFound = false;
    }
  }

  const { user } = useUserContext();

  console.log(user);

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            fontSize: '0.9rem',
          },
        }}
      />
      <Switch>
        <Route
          exact
          path="/my-memory/control-panel" // Dashboard now will be named as Control Panel
          component={() =>
            user.dashboardType === 'normal' ? (
              <ControlPanel />
            ) : (
              <MahindraPanel />
            )
          }
        />
        <Route
          path="/my-memory/reader" // Articles now will be named Reader
          component={() => (
            <MyMemoryProvider>
              {!user.prefFilled && user!==undefined && !localStorage.getItem('userPrefAdded') ? <OnboardingModal /> : null}
              <MyMemory />
            </MyMemoryProvider>
          )}
        />
        <Route
          exact
          path="/inputs/topic-monitoring"
          component={() => <UserProfile />}
        />
        <Route exact path="/my-memory/askme" component={() => <AskMe />} />
        <Route
          exact
          path="/account/termsandconditions"
          component={TermsAndConditions}
        />
        <Route exact path="/account" component={() => <Account />} />
        <Route path="/ryanseasteregg" component={() => <RyansEEgg />} />
        <Route path="*" component={() => <ControlPanel />} />
      </Switch>
    </>
  );
};

export default AuthenticatedApp;

const RyansEEgg = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <h2 style={{ fontSize: '3.5rem', margin: '2% 0' }}>
        Hold on a minute there player
      </h2>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/d7cZShVF9YI`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};
