import { AiOutlineMail } from "react-icons/ai";

import styles from "./NoAccessModal.module.scss";
import { useUserContext } from "../../../context/allContexts";

const NoAccessModal = ({ handleNoAccessClose }) => {
  const { user, logout } = useUserContext();
  const name = `${user.name}${user?.lastName ? ` ${user.lastName}` : ""}`;

  return (
    <div className={styles.backdrop} onClick={logout}>
      <div className={styles.modalBody} onClick={(e) => handleNoAccessClose(e)}>
        <div className={styles.modalContent}>
          <div className={styles.content}>
            <h1 className={styles.consentModal__title}>{`Sorry ${name}:`}</h1>

            <p className={styles.consentModal__p}>
              It seems your account is in need of some attention. If you would
              like to continue using Pluaris please contact us so that we can
              address the hold on your account.
            </p>
            <p className={styles.consentModal__p}>
              We look forward to speaking with you and having you back on our
              platform. Please Contact Support so that we can assist you
              further.
            </p>

            <div className={styles.btnContainer}>
              {/*TODO: CHECK this working with mail client*/}
              <a
                className={styles.nowigenceTeam__a}
                href="mailto:marcus.woodard@nowigence.com?subject=I%20need%20help%20accessing%20my%20account%20data&body=Hello%20Marcus,%0d%0a%0d%0aPlease%20let%20us%20know%20how%20we%20assist%20you?"
              >
                Nowigence Team
                <AiOutlineMail className={styles.nowigenceTeam__icon} />
              </a>
              <button
                className={styles.consentModal__disagreeBtn}
                onClick={logout}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAccessModal;
