import React from 'react';
import TextLoop from 'react-text-loop';

export function TextLoopComponent() {
  return <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
    <span>Developers</span>
    <span>Data Analysts</span>
    <span>Entrepreneurs</span>
    <span>Marketers</span>
    <span>Students</span>
    <span>Professors</span>
    <span>Researchers</span>
    <span>Journalists</span>
    <span>Bloggers</span>
    <span>Content Writers</span>
    <span>Editors</span>
    <span>Businesses</span>
  </TextLoop>;
}
