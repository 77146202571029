import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
} from "react";

// @ts-ignore
export const ViewPortContext = createContext();

interface Props {
  children: FC;
}

export const ViewPortProvider: FC<Props> = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width, height]);

  return (
    <ViewPortContext.Provider value={{ width, height }}>
      {props.children}
    </ViewPortContext.Provider>
  );
};

export const useViewPortContext = () => {
  return useContext(ViewPortContext);
};
