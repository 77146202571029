import React, { Dispatch, FC, useEffect } from "react";
import styles from "./AArticle.module.scss";

import AnswerKPND from "../AnswerKPND/AnswerKPND";

import { AiOutlineBars } from "react-icons/ai";
import { AnswerActions } from "../Answer";

interface Props {
  idx: number;
  article: any;
  selectedArticles: [] | string[];
  selectArticle: (arg: string) => void;
  answerDispatcher: Dispatch<AnswerActions>;
  showKeypointsNDrivers: boolean | string;
  webOrMem: string;
}

const AArticle: FC<Props> = ({
  idx,
  article,
  selectedArticles,
  selectArticle,
  answerDispatcher,
  showKeypointsNDrivers,
  webOrMem,
}) => {
  return (
    <li key={idx} className={styles.article__li}>
      {showKeypointsNDrivers === article.id && (
        <AnswerKPND
          article={article}
          close={() => answerDispatcher({ type: "reset" })}
        />
      )}
      <label className={styles.checkboxLabel}>
        <input
          type="checkbox"
          className={styles.article__checkbox}
          checked={selectedArticles.includes(
            // @ts-ignore
            article.id ? article.id : article.url
          )}
          onChange={() =>
            // If memory, id is used, else article's url is used as identifier.
            // selectArticle(article.id ? article.id : article.url)
            selectArticle(article.id)
          }
        />
        <div className={styles.article__outerDiv}>
          <div className={styles.article__titleWrapper}>
            <a
              className={styles.article__a}
              href={article.url}
              target="_blank"
              rel="noreferrer"
            >
              <h6>{article.title}</h6>
            </a>
            {webOrMem === "memory" && (
              <div
                className={`${styles.article__btnContainer} ${styles.mobileOnly}`}
                onClick={() =>
                  answerDispatcher({
                    type: "show KPND",
                    id: article.id,
                  })
                }
              >
                <AiOutlineBars
                  title="Key Points"
                  className={styles.article__btn}
                />
              </div>
            )}
          </div>
          <div className={styles.article__innerDiv}>
            <p>{article.first2sents}</p>
          </div>
        </div>
        <span className={styles.customCheckbox}></span>
      </label>
      {webOrMem === "memory" && (
        <div
          className={`${styles.article__btnContainer} ${styles.desktopOnly}`}
          onClick={() =>
            answerDispatcher({ type: "show KPND", id: article.id })
          }
        >
          <AiOutlineBars title="Key Points" className={styles.article__btn} />
        </div>
      )}
    </li>
  );
};

export default AArticle;
