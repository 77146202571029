import axios from 'axios'
import { toast } from 'react-hot-toast'
import { LOCAL_STORAGE_KEYS, REST_BASE_ENDPOINT } from '../constants'

export const socialLogin = async (email, loaderFunction) => {
  await axios
    .post(`${REST_BASE_ENDPOINT}/auth/landing/user/social/login`, {
      email: email,
      password: '',
    })
    .then((res) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, res.data.data.accessToken)
      loaderFunction(false)
      console.group('socialLogin')
      let url = window.location.href
      let params = new URLSearchParams(url.search)
      params.delete('code')
      window.history.replaceState(
        {},
        document.title,
        `${window.location.pathname}?${params.toString()}`,
      )
      console.log(params.toString())
      console.log('loader', loaderFunction)
      console.groupEnd()
      window.location.reload()
    })
    .catch((err) => {
      setTimeout(() => {
        window.location.reload()
      }, 1200)
      return toast.error(`${err.response.data.message}`, {
        position: 'top-center',
        theme: 'colored',
      })
    })
}

export const createUser = async (payload, loaderFunction) => {
  console.log('creating user ', payload)
  let url = window.location.href
  let params = new URLSearchParams(url.search)
  params.delete('code')
  console.log(params.toString())
  await axios
    .post(`${REST_BASE_ENDPOINT}/auth/landing/user/create`, payload)
    .then((res) => {
      socialLogin(payload.email, loaderFunction)
    })
    .catch((err) => {
      if (err.response.data.message === 'User already exists')
        socialLogin(payload.email, loaderFunction)
    })
}
