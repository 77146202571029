import { REST_BASE_ENDPOINT } from "../constants";
import { useMutation } from "react-query";
import { axios } from "../utils/axiosConfig";

interface OTPInput {
    otp: string;
}

export function useOTPSend(opts: any) {
  return useMutation(async () => {
    try {
      const { data } = await axios.post(
        `${REST_BASE_ENDPOINT}/auth/email-verification/send`
      );
      console.log(data)
      if (data.message) {
        return data.message;
      }
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, opts);
}

export function useOTPVerify(opts?: any) {
    return useMutation(async (otpInput: OTPInput) => {
      try {
        console.log(opts)
        const { data } = await axios.post(
          `${REST_BASE_ENDPOINT}/auth/email-verification/verify`, otpInput
        );
        console.group(data)
        if (data.message) {
          return data.message;
        }
      } catch (err) {
        throw new Error(err.response.data.message);
      }
    }, opts);
  }
