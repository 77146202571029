import React from "react";

import styles from "./TipsModal.module.scss";

import CloseModalBtn from "../../../../Components/CloseModalBtn/CloseModalBtn";

const TipsModal = ({ close }) => {
  return (
    <div className={styles.tipsModalWrapper}>
      <div className={styles.closeBtn}>
        <CloseModalBtn theme="light" close={close} />
      </div>
      <h2>Relevancy Tips</h2>
      <p>
        Experiencing irrelevant results? Consider the following tips to increase
        relevancy:
      </p>
      <p>
        - Enter long-tail topics or phrases if you find results to be less
        relevant. Long-tail topics will return less news but will usually have a
        higher precision, as they are more specific.
      </p>
      <p>- Wrap a topic in "quotes" to enforce stricter matching.</p>
      <p>
        - Add excluded topics to stop news that contains entered words or
        phrases from being stored in your memory.
      </p>
      {/* <p>- Select country(s) to focus and narrow the geographic reach.</p>  */}
      <p>
        - Utilize the Show Preview button to assess the quality of news before
        adding to your profile.
      </p>
    </div>
  );
};

export default TipsModal;
