import React, { createContext, useContext, useReducer, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const NavContext = createContext();

const navReducer = (state, action) => {
  switch (action.type) {
    case "open":
      localStorage.setItem("pinNavOpen", false);
      return { ...state, toggleNav: "open" };

    // closed is initial state
    case "closed":
      localStorage.setItem("pinNavOpen", false);
      return { ...state, toggleNav: "closed" };

    case "closing":
      localStorage.setItem("pinNavOpen", false);
      return { ...state, toggleNav: "closing" };

    case "opening":
      return {
        ...state,
        toggleNav: "opening",
        whichMenu: state.whichMenu === "notebook" ? "notebook" : "", // Don't close notebook only.
        filterAnimationState:
          state.filterAnimationState === "open"
            ? "closing"
            : state.filterAnimationState,
      };
    case "opening mobile":
      return {
        ...state,
        toggleNav: "opening",
        whichMenu: "",
        filterAnimationState:
          state.filterAnimationState === "open"
            ? "closing"
            : state.filterAnimationState,
      };
    case "pinned open":
      localStorage.setItem("pinNavOpen", true);
      return {
        ...state,
        toggleNav: "pinned open",
      };
    case "change which menu":
      console.log(action.menu)
      if (state.toggleNav !== "pinned open")
        localStorage.setItem("pinNavOpen", false);
      return {
        ...state,
        whichMenu: action.menu,
        toggleNav: state.toggleNav === "open" ? "closing" : state.toggleNav,
      };
    case "toggle filters":
      return {
        ...state,
        filterAnimationState: action.filterState,
        openFilter: "",
      };
    case "open filters":
      if (state.toggleNav !== "pinned open")
        localStorage.setItem("pinNavOpen", false);
      return {
        ...state,
        filterAnimationState: "opening",
        toggleNav:
          state.toggleNav === "pinned open"
            ? "pinned open"
            : "keep open for filters",
      };
    case "close filters":
      if (state.toggleNav !== "pinned open")
        localStorage.setItem("pinNavOpen", false);
      return {
        ...state,
        filterAnimationState: "closing",
        toggleNav: state.toggleNav === "pinned open" ? "pinned open" : "open",
      };
    case "set open filter":
      return {
        ...state,
        openFilter: action.name,
      };
    case "toggle saved filters disable":
      return {
        ...state,
        isFiltersDisabled: action.payload,
      };
    case "logout":
      return initState;
    default:
      return state;
  }
};

const initState = {
  toggleNav:
    localStorage.getItem("pinNavOpen") === "true" && window.innerWidth > 1210
      ? "pinned open"
      : "closed",
  whichMenu: "",
  filterAnimationState: "closed",
  openFilter: "",
  isFiltersDisabled: false,
};

export function NavProvider(props) {
  const { pathname } = useLocation();
  const [navState, navDispatch] = useReducer(navReducer, initState);
  const {
    toggleNav,
    whichMenu,
    filterAnimationState,
    openFilter,
    isFiltersDisabled,
  } = navState;

  useEffect(() => {
    if (
      pathname !== "/my-memory/reader" &&
      pathname !== "/my-memory/control-panel"
    ) {
      navDispatch({ type: "toggle saved filters disable", payload: true });
    } else {
      navDispatch({ type: "toggle saved filters disable", payload: false });
    }
  }, [pathname]);
  // console.log(localStorage.getItem("reloaded"), "notebook")
  useEffect(() => {
    if(localStorage.getItem("reloaded") && localStorage.getItem('activeNotebook'))
      navDispatch({ type: "change which menu", menu: "notebook" })
  },[localStorage.getItem("reloaded")])

  return (
    <NavContext.Provider
      value={{
        toggleNav,
        whichMenu,
        filterAnimationState,
        openFilter,
        navDispatch,
        isFiltersDisabled,
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
}

export const useNavContext = () => {
  return useContext(NavContext);
};
