import React, { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useStateLabel } from "../../../../hooks/stateLabeler";
import { copyText } from "../../../../util/copyText";
import styles from "./ACDisplayTags.module.scss";

interface Props {
  t: any;
  showDeleteConfirmation: () => void;
  showX: boolean;
}
export const ACDisplaySingleTag: FC<Props> = ({
  t,
  showDeleteConfirmation,
  showX,
}) => {
  const [copiedText, setCopiedText] = useStateLabel(false, "copiedText");

  const copyFunc = (text: string) => {
    copyText(text);
    setCopiedText(true);

    setTimeout(() => {
      setCopiedText(false);
    }, 450);
  };

  return (
    <div className={styles.displayWrapper}>
      <li
        className={`${styles.insights__tags} ${
          copiedText ? styles.copiedOverlay : null
        }`}
        onClick={() => copyFunc(t)}
      >
        {t}
      </li>
      <span className={styles.tagsSpan} onClick={showDeleteConfirmation}>
        {showX && <AiOutlineClose className={styles.closeIcon} />}
      </span>
    </div>
  );
};
