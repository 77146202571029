import { WhichDb } from "../../../codeGenFE";

export enum MyMemoryActionChoices {
  SET_HEIGHT,
  TOGGLE_DISPLAY_INFO_MODAL,
  TRIGGER_CLOSING_ARTICLES,
  TOGGLE_SUM_DRIV_OVERLAY,
  // SET_SELECTED_ARTICLEID,
  FILE_UPLOADED,
  REMOVE_FILE_UPLOADS,
  SET_SKIP,
  SET_ARTICLES,
}

export type MyMemoryState = {
  height: number;
  displayInfoModal: boolean;
  triggerClosingArticles: boolean;
  showSumAndDriverOverlay: boolean;
  selectedArticleID: string | null;
  selectedArticleDB: WhichDb | null;
  files?: string[];
  FILE?: string;
  v2Skip: number | null; 
  filesSkip: number | null;
  articles: string[] | null;
};

export type MyMemoryActions =
  | { type: MyMemoryActionChoices.SET_HEIGHT; height: number }
  | { type: MyMemoryActionChoices.TOGGLE_DISPLAY_INFO_MODAL; payload?: boolean }
  | { type: MyMemoryActionChoices.TRIGGER_CLOSING_ARTICLES; payload?: boolean }
  | {
      type: MyMemoryActionChoices.TOGGLE_SUM_DRIV_OVERLAY;
      payload: boolean;
      selectedArticleID: string | null;
      selectedArticleDB: WhichDb | null;
    }
  | {
    type: MyMemoryActionChoices.FILE_UPLOADED;
    payload: string[];
  }
  | {
    type: MyMemoryActionChoices.REMOVE_FILE_UPLOADS;
    payload: string;
  }|
  {
    type: MyMemoryActionChoices.SET_SKIP;
    v2Skip: number; filesSkip: number
  }|
  {
    type: MyMemoryActionChoices.SET_ARTICLES;
    articles: string[]
  };
// | {
//     type: MyMemoryActionChoices.SET_SELECTED_ARTICLEID;
//     selectedArticleID: string | null;
//   };
