import React, { FC, useState } from "react";

import { AiOutlineCaretDown } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import styles from "./SingleSelectDropdown.module.scss";

type Item = {
  submitData: string;
  display: string;
};

interface Props {
  display: string;
  itemsArr?: [] | Item[];
  runDispatch: (arg: string) => void;
  toggleDropdownVis: () => void;
  isOpen: boolean;
  deleteItem?: (submitData: string) => void;
  newStyle?: boolean;
}

const SingleSelectDropdown: FC<Props> = ({
  display,
  itemsArr,
  runDispatch,
  toggleDropdownVis,
  isOpen,
  deleteItem = null,
  newStyle = false,
}) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState<null | number>(
    null
  );

  return (
    <div
      data-newstyle={`${newStyle}`}
      data-isopen={`${isOpen}`}
      className={styles.container}
      onClick={(e) => e.stopPropagation()}
      // onMouseLeave={() => isOpen && toggleDropdownVis()}
    >
      <div
        className={styles.display__div}
        tabIndex={0}
        onKeyUp={(e) => {
          e.key === "Enter" && toggleDropdownVis();
          e.key === "Enter" && setShowConfirmDelete(null);
        }}
        onClick={() => {
          toggleDropdownVis();
          setShowConfirmDelete(null);
        }}
      >
        <p className={styles.display__text}>{display}</p>
        <AiOutlineCaretDown
          className={styles.display__arrow}
          style={{
            transform: `rotate(${isOpen ? "180" : "0"}deg)`,
          }}
        />
      </div>
      {isOpen && (
        <div className={styles.dropdown__container}>
          {itemsArr?.length === 0 && (
            <button disabled={true} className={styles.dropdown__button}>
              No Data
            </button>
          )}
          {itemsArr?.map((item: Item, index: number) => (
            <button
              key={index}
              tabIndex={0}
              className={styles.dropdown__button}
              onKeyUp={(e) => {
                e.key === "Escape" && toggleDropdownVis();
                e.key === "Escape" && setShowConfirmDelete(null);
              }}
              onClick={() => item.submitData && runDispatch(item.submitData)}
            >
              <span>{item.display}</span>
              {deleteItem && showConfirmDelete !== index && (
                <FiTrash2
                  onClick={(e) => {
                    e.stopPropagation();
                    // deleteItem(item.submitData);
                    setShowConfirmDelete(index);
                  }}
                />
              )}
              {deleteItem && item?.submitData && showConfirmDelete === index && (
                <span
                  className={styles.confirm}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowConfirmDelete(null);
                    deleteItem(item.submitData);
                  }}
                >
                  Confirm?
                </span>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SingleSelectDropdown;
