import { REST_BASE_ENDPOINT, API_ENDPOINTS, POST_HEADERS, GQL_SUBSCRIPTION_ENDPOINT } from "../../../constants";
import { toast } from "react-toastify";
import { axios } from "../../../utils/axiosConfig";



export function fetchArticleData(data, setAllData, setProgress, setError, interval, setSectionData, setLinkedinShareValue, getBulletPoints) {
    axios.post(`${REST_BASE_ENDPOINT}${API_ENDPOINTS.ARTICLE}`, {
            articleId: data.articlesChangeSubscription.articleId,
            database: data.articlesChangeSubscription.database,
            tempUserId: localStorage.getItem("userId"),
    }, {
        headers: POST_HEADERS,
    })
        .then((result) => {
            if (result?.data) passSummaryPageData(result.data, setAllData, setProgress, setError, interval, setSectionData, setLinkedinShareValue, getBulletPoints)
        })
        .catch((error) => console.log("error", error));
}

function passSummaryPageData(result, setAllData, setProgress, setError, interval, setSectionData, setLinkedinShareValue, getBulletPoints) {
    if (result.error) {
        setAllData(null);
        setProgress(0);
        setError(true);
        localStorage.removeItem("allData");
    } else {
        clearInterval(interval);
        setSectionData(null);
        setProgress(100);
        setError(false);
    }
    setAllData(result.data._source);
    localStorage.setItem("allData", JSON.stringify(result.data._source));

    setLinkedinShareValue(getBulletPoints(result.data._source));
    return result;
}

export function subscriptionGetData(query, setInvalidUrl, setKeywords, SubscriptionClient, setAllData, setProgress, setError, interval, setSectionData, setLinkedinShareValue, getBulletPoints) {
    const client = new SubscriptionClient(GQL_SUBSCRIPTION_ENDPOINT, {
        reconnect: true,
        lazy: true,
        connectionCallback: (error) => {
            error && console.error(error);
        },
    });

    const subscription = client.request({ query }).subscribe({
        next({ data }) {
            if (data.articlesChangeSubscription) {
                if (data) {
                    setKeywords(data.articlesChangeSubscription.keywords);
                    fetchArticleData(data, setAllData, setProgress, setError, interval, setSectionData, setLinkedinShareValue, getBulletPoints);
                }
                return data;
            } else if (data.articlesChangeSubscription == null) {
                setInvalidUrl(true);
                setProgress(0);
                return toast.error(
                    "There is something wrong with the upload you are trying, we are working on it. Please try a different URL or file.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        draggable: false,
                        progress: undefined,
                        toastId: "noDataToast",
                    }
                );
            }
        },
    });
    return () => subscription.unsubscribe();
}