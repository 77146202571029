import React, { useReducer } from "react";
import { useQueryClient } from "react-query";
// CONTEXT
// import { useFeedbackMutation, FeedbackItems } from "../../../rq-hooks";
import {
  NerCorrectionInput,
  useNerCorrectionFeedbackMutation,
} from "../../../codeGenFE";
import ComponentMessagingOverlay, {
  CmoTheme,
} from "../ComponentMessagingOverlay/ComponentMessagingOverlay";
// COMPONENTS
import SingleSelectDropdown from "../SingleSelectDropdown/SingleSelectDropdown";
// STYLES
import styles from "./FeedbackHack.module.scss";

const fbhReducer = (state: any, action: any) => {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: true,
      };
    case "success":
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    case "error":
      return {
        ...state,
        loading: false,
        errorMessage: `Sorry something went wrong, \n please try again later.`,
      };
    case "reset":
      return {
        ...initState,
      };
    case "formError":
      return {
        ...state,
        formError: action.payload,
      };
    case "toggleTextArea":
      return {
        ...state,
        showTextArea: !state.showTextArea,
      };
    case "selectReason":
      return {
        ...state,
        reason: action.payload,
        whichDropdownOpen: false,
        otherReason: "",
        formError: false,
        userSuggestedCategory: "",
      };
    case "which dropdown open":
      return {
        ...state,
        whichDropdownOpen:
          action.ddName === state.whichDropdownOpen ? false : action.ddName,
      };
    case "other reason text":
      return {
        ...state,
        otherReason: action.payload,
        formError: action.payload ? false : "noOtherReason",
      };
    case "userSuggestedCategory text":
      return {
        ...state,
        whichDropdownOpen: false,
        userSuggestedCategory: action.payload,
        formError: action.payload ? false : "noOtherReason",
      };
  }
  return state;
};

const initState = {
  loading: false,
  showTextArea: false,
  errorMessage: false,
  successMessage: false,
  reason: "",
  otherReason: "",
  whichDropdownOpen: false,
  userSuggestedCategory: "",
  formError: false,
};

const FeedbackHack = ({ isOpen, close, itemClicked, itemCategory }: any) => {
  const qClient = useQueryClient();
  const [fbhState, fbhDispatch] = useReducer(fbhReducer, initState);
  const {
    errorMessage,
    successMessage,
    reason,
    otherReason,
    userSuggestedCategory,
    whichDropdownOpen,
    formError,
  } = fbhState;
  const { mutate } = useNerCorrectionFeedbackMutation({
    onSuccess: async (data) => {
      fbhDispatch({
        type: "success",
        payload: data?.nerCorrectionFeedback,
      });
      qClient.invalidateQueries("articleCard");
    },
    onError: () => {
      fbhDispatch({ type: "error" });
    },
  });

  const submitFeedback = async () => {
    fbhDispatch({ type: "loading" });
    if (!reason) {
      fbhDispatch({ type: "formError", payload: "noReason" });
      return;
    }
    if (!userSuggestedCategory && reason === "wrong category") {
      fbhDispatch({ type: "formError", payload: "noCategoryReason" });
      return;
    }
    if (
      (reason === "other" && !otherReason) ||
      (reason === "wrong category" && !userSuggestedCategory)
    ) {
      fbhDispatch({ type: "formError", payload: "noOtherReason" });
      return;
    }
    try {
      const feedbackOptions: NerCorrectionInput = {
        category: itemCategory.toUpperCase(),
        entity: itemClicked,
        reason,
      };
      if (otherReason) feedbackOptions.otherReason = otherReason;
      if (userSuggestedCategory)
        feedbackOptions.userSuggestedCategory =
          userSuggestedCategory.toUpperCase();
      mutate({ feedbackOptions });
    } catch (err) {
      fbhDispatch({ type: "error" });
    }
  };

  const reasonOptions = [
    {
      submitData: "wrong category",
      display: `${itemClicked} Doesn't Belong In ${itemCategory}`,
    },
    {
      submitData: "don't show me this",
      display: `I Just Don't Want To See ${itemClicked}`,
    },
    { submitData: "other", display: `Other` },
  ];

  const reasonDisplay = () => {
    let str = "Choose Reason...";
    reasonOptions.forEach((r) => {
      if (r.submitData === reason) {
        str = r.display;
      }
    });
    return str;
  };

  const wrongCatOptions = () => {
    const categories = [
      { submitData: "company", display: "Company" },
      { submitData: "product", display: "Product" },
      { submitData: "person", display: "Person" },
      { submitData: "location", display: "Location" },
      { submitData: "none", display: "None of The Above" },
    ];
    const filteredCats = categories.filter(
      (entity) => entity.submitData !== itemCategory.toLowerCase()
    );
    return filteredCats;
  };

  const wrongCategoryDispay = () => {
    let str = "Please choose a category";
    const categories = wrongCatOptions();
    categories.forEach((c) => {
      if (c.submitData === userSuggestedCategory.toLowerCase()) {
        str = c.display;
      }
    });
    return str;
  };

  if (!isOpen) return null;
  return (
    <div
      // PREVENT BUBBLING TO STOP OPEN AND CLOSING
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (successMessage) qClient.invalidateQueries("articlesTrending");
        close();
      }}
      className={styles.feedbackHack__container}
    >
      <div
        className={styles.feedbackHack__card}
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className={styles.feedback__label}>What is wrong?</h3>
        {/* reason dropdown */}
        {/* ?otherReason text input */}
        <div
          className={styles.ssdd__wrapper}
          data-open={whichDropdownOpen === "reason"}
        >
          {formError === "noReason" && (
            <p className={styles.error__p}>*Please select a Reason</p>
          )}
          <SingleSelectDropdown
            display={reasonDisplay()}
            itemsArr={reasonOptions}
            runDispatch={(data: any) =>
              fbhDispatch({ type: "selectReason", payload: data })
            }
            toggleDropdownVis={() =>
              fbhDispatch({ type: "which dropdown open", ddName: "reason" })
            }
            isOpen={whichDropdownOpen === "reason"}
          />
        </div>
        {reason === "other" && (
          <div className={styles.ssdd__wrapper}>
            {formError === "noOtherReason" && (
              <p className={styles.error__p}>*Please select a Reason</p>
            )}
            <input
              onChange={(e) =>
                fbhDispatch({
                  type: "other reason text",
                  payload: e.target.value,
                })
              }
              className={styles.feedbackHack__input}
              type="text"
              placeholder="Please tell us what else is
      wrong..."
            />
          </div>
        )}
        {reason === "wrong category" && (
          <div
            className={`${styles.ssdd__wrapper} ${styles.wrongCat}`}
            data-input={reason}
            data-open={whichDropdownOpen === "wrong cat"}
          >
            {formError === "noCategoryReason" && (
              <p className={styles.error__p}>
                *Please select where you think this belongs
              </p>
            )}
            <SingleSelectDropdown
              display={wrongCategoryDispay()}
              itemsArr={[...wrongCatOptions()]}
              runDispatch={(data: any) =>
                fbhDispatch({
                  type: "userSuggestedCategory text",
                  payload: data,
                })
              }
              toggleDropdownVis={() =>
                fbhDispatch({
                  type: "which dropdown open",
                  ddName: "wrong cat",
                })
              }
              isOpen={whichDropdownOpen === "wrong cat"}
            />
          </div>
        )}
        <div className={styles.btnContainer}>
          <button onClick={() => submitFeedback()} className={styles.submitBtn}>
            Submit
          </button>
          <button onClick={close} className={styles.cancelBtn}>
            Cancel
          </button>
        </div>
        {successMessage && (
          // @ts-ignore
          <ComponentMessagingOverlay
            close={() => {
              fbhDispatch({ type: "reset" });
              qClient.invalidateQueries("articlesTrending");
              close();
            }}
            message={successMessage}
            showCheck={true}
            theme={CmoTheme.DARK}
          />
        )}
        {errorMessage && (
          // @ts-ignore
          <ComponentMessagingOverlay
            close={() => {
              fbhDispatch({ type: "reset" });
              close();
            }}
            message={errorMessage}
            showX={true}
            theme={CmoTheme.LIGHT}
          />
        )}
      </div>
    </div>
  );
};

export default FeedbackHack;
