import React, { useEffect, useLayoutEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { AiFillLinkedin } from 'react-icons/ai';
import { Type, useRewardsDetails, useUserLinkedInShareMutation, useUserRewardLogsMutation, useUserRewardsMutation } from '../../../../../../codeGenFE';
import { useTokenProcess } from '../../../../../../rq-hooks/useTokenProcess';
import { customErrorHandler } from '../../../../../../utils/customErrorHandler';
import CloseModalBtn from '../../../../../Components/CloseModalBtn/CloseModalBtn';
import LoadingCube, { LoadingCubeTheme } from '../../../../../Components/LoadingCube/LoadingCube';
import { useRewardsContext, useSocialLoginContext, useUserContext, useViewPortContext } from '../../../../../context/allContexts';
import { uploadContent } from '../../../../../util/httpRequests';
import styles from './LinkedinShare.module.scss'

export enum CloseModalBtnTheme {
  LIGHT,
  DARK,
}
const LinkedinShare = ({close, showLinkedinSharePopup, article, driver, refetch, isFetching}: any) => {
  const [shouldRender, setShouldRender] = useState<any>(showLinkedinSharePopup);
  const [linkedInPostId, setLinkedInPostId] = useState<any>(null);
  useLayoutEffect(() => {
    refetch()
    setTimeout(() => {
      localStorage.removeItem('articleShared')
      localStorage.removeItem('shareType')
    }, 5000) 
  }, [])
  const {socialLoginState} = useSocialLoginContext()
  const {
    linkedInUserData, 
    linkedInAccessToken} = socialLoginState
  const {refetch: rewardsDetail} = useRewardsContext()

  // In this function system is adding reward points on the basis of URL upload action 
  // and on success system is refetching updated reward point details  by 
  // userRewardsDetails()
  const {mutate: rewardsMutate} = useUserRewardsMutation({
    onSuccess: async () => {
      await rewardsDetail()
      close()
    }
  })

  // In this function system is logging reward actions and on success we are 
  // mutating userRewardsMutation()
  const {mutate: rewardsLogMutate} = useUserRewardLogsMutation({
    onSuccess: () => {
      console.log("success")
      rewardsMutate({
        options:{
          type: Type.Linkedin,
          meta: {
            articleId: article._id,
            linkedinId: linkedInPostId,
          }
        }
      })
    }
  })

  // In this function our system uploads a post to the linkedin account integrated 
  // for the user by the help of access token using useUserLinkedInShareMutation() 
  // which uses graphql mutation userLinkedInShare() and on success system is 
  // mutating userRewardLogsMutation()
  const {isLoading, mutate} = useUserLinkedInShareMutation({
    onSuccess: (data) => {
      // close()
      setLinkedInPostId(data.userLinkedInShare)
      // rewardsLogMutate({
      //   options: {
      //     type: Type.Linkedin
      //   }
      // })
      toast.success("Article posted on linkedin successfully!")
    },
    onError: (err) => {
      toast.error(customErrorHandler(err))
    }
  })

  // @ts-ignore
  const { width } = useViewPortContext();

  useEffect(() => {
    if (showLinkedinSharePopup) setShouldRender(true);
  }, [showLinkedinSharePopup]);

  const onAnimationEnd = () => {
    if (!showLinkedinSharePopup) setShouldRender(false);
  };

  const handleClick = () => {
    let pointers = (article._source.title).replace(/\.[^/.]+$/, "")
    if(article._source.summary && article._source.summary.length > 0){
      pointers += '\n\nSummary: \n'
      article._source.summary?.forEach((item: string) => pointers += ` - ${item} \n`)
    }
    if(driver && driver.length > 0 && driver[0] !== "This article does not contain a driver."){
      pointers += '\nDrivers: \n'
      driver?.forEach((item: string) => pointers += ` - ${item} \n`)
    }
    let result = pointers
    result = pointers.replace(/[`!#$%^&+=\;':"\\|.,\/?]/g, " ")
    const updatedData = result.replace(/[`@*()_\[\]{}|<>\/~]/g, "\\$&")
    if(updatedData && updatedData.length > 3000) {
      return toast.error("Post cannot be greater then 3000 characters")
    }
    mutate({
      options: {
        token: linkedInAccessToken,
        author: `urn:li:person:${linkedInUserData.id}`,
        data: updatedData,
        image: article.proImageLink
      }
    })
  }
  var title = (article._source.title).replace(/\.[^/.]+$/, "");
  // console.log(article._source)
  // console.log((article._source.title).replace(/(.*)\.[^.]+$/, ""))
  // console.log(filenameText)
  return (
    shouldRender && (
      <React.Fragment>
        <div
          onAnimationEnd={onAnimationEnd}
          className={`${styles.backdrop} ${
            showLinkedinSharePopup ? styles.backdrop : styles.fadeOut
          }`}
          onClick={() => close()}
        />
        <div
          className={`${styles.myMemoryInfoModalWrapper} ${
            showLinkedinSharePopup ? styles.myMemoryInfoModalWrapper : styles.slideUp
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          {
            !isFetching && 
            <>
              <div className={styles.header}>
                <div className={styles.headerText}><h2>Linked</h2></div>
                <div><AiFillLinkedin className={styles.linkedin} size={35} /></div>
              </div>
              <div className={styles.closeBtn}>
                <CloseModalBtn theme={CloseModalBtnTheme.LIGHT} close={close} />
              </div>
              <div className={styles.card}>
                <div className={styles.cardBody}>
                  <div className={styles.imageSection}>
                    <h2>{title}</h2>
                  </div>
                  <div className={styles.details}>
                    {
                      article._source.summary &&
                      <div className={styles.summary}>
                        <h2>Summary</h2>
                        <ul className={styles.list}>
                          {
                            article._source.summary.map((summary: string, index: number) => <li key={index} className={styles.listItem}>{summary}</li>)
                          }
                        </ul>
                      </div>
                    }
                    {
                      (driver && driver.length > 0 && driver[0] !== "This article does not contain a driver.") && <div className={styles.driver}>
                      <h2>Driver</h2>
                      <ul className={styles.list}>
                        {
                          driver.map((item: string, index:number) => <li key={index} className={styles.listItem}>{item}</li>)
                        }
                      </ul>
                    </div>
                    }
                    <div className={styles.image}>
                      <img src={article.proImageLink} alt={article._source.title} />
                    </div>
                  </div>
                </div>
                <div className={styles.cardFooter}>
                    <button
                      onClick={handleClick}
                      className={styles.changePassBtn}>
                      Share
                    </button>
                </div>
              </div>
            </>
          }
          <div className={styles.isFetchingNextPageContainer}>
                {
                  (isLoading || isFetching) && <LoadingCube
                    theme={LoadingCubeTheme.DARK}
                    height={width < 1210 ? 100 : 30}
                    width={width < 1210 ? 100 : 30}
                    isFullScreen={width < 1210 ? true : false}
                  />
                }
          </div>
        </div>
      </React.Fragment>
    )
  );
}

export default LinkedinShare