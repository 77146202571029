import React from "react";

import { AiOutlineCheck } from "react-icons/ai";

import styles from "./SuccessAnswer.module.scss";

const SuccessAnswer = ({ close, articleNums }) => {
  return (
    <div className={styles.overlayWrapper}>
      <AiOutlineCheck className={styles.iconCheck} />
      <p className={styles.modal_p}>
        {`Successfully shared ${articleNums} article${
          articleNums > 1 ? "s" : ""
        }!`}
      </p>
      <button className={styles.overlayBtn} onClick={() => close()}>
        OKAY
      </button>
    </div>
  );
};

export default SuccessAnswer;
