import { REST_BASE_ENDPOINT } from "../constants";
import { useMutation } from "react-query";
import { axios } from "../utils/axiosConfig";

interface TokenTransferInput {
  tokenAmount: number;
  toWalletAddress: string;
}

export function useTokenTransfer(opts?: any) {
  return useMutation(async (tokenProcessInput: TokenTransferInput) => {
    try {
      const { data } = await axios.post(
        `${REST_BASE_ENDPOINT}/auth/transfer-token`,
        tokenProcessInput
      );
      if (data.data) {
        return data.data;
      }
    } catch (err) {
      if(err.response && err.response.data && err.response.data.data && err.response.data.data.msg)  
        throw new Error(err.response.data.data.msg);
      else  
      throw new Error(err.response.data.message);
    }
  }, opts);
}
