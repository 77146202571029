import React, { FC, useEffect, useState } from "react";
import { IoMdArrowDropright } from "react-icons/io";
// icons
import { MdArrowDropDown } from "react-icons/md";
import { useFetchUserRelationshipsQuery } from "../../../../../codeGenFE";
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
// Data
import { useSearchContext } from "../../../../context/allContexts";
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";
import { AiOutlineClose } from "react-icons/ai";
// utils
// styles
import styles from "./filters.module.scss";

export const TestName = "testName";

interface Props {
  openFilter: string;
  setOpenFilter: (arg: string) => void;
}

const RelationshipFilter: FC<Props> = ({ openFilter, setOpenFilter }) => {
  // const { user } = useUserContext();
  const { searchState, searchDispatcher } = useSearchContext();
  const { relSearchParams } = searchState;
  const { data, error, isFetching } = useFetchUserRelationshipsQuery();
  // LOCAL STATE
  const [relationshipDisplayed, setRelationshipDisplayed] = useState("");
  // const [relCategories, setRelCategories] = useState([]);
  const [showSubDropdown, setShowSubDropdown] = useState("");

  useEffect(() => {
    if (relSearchParams?.relationship) {
      setRelationshipDisplayed(relSearchParams?.relationship);
    } else if (relSearchParams?.category === "other") {
      setRelationshipDisplayed("Other");
    } else {
      setRelationshipDisplayed("");
    }
  }, [relSearchParams]);

  if (isFetching) {
    return (
      <div className={styles.dropdown__container}>
        <div
          className={`${styles.filterDisplay} ${styles.filterDisplay__close} `}>
          <p className={styles.filterName}>Loading Relationships...</p>
          <span className={styles.downArrow}>
            <MdArrowDropDown />
          </span>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className={styles.dropdown__container}>
        <div
          className={`${styles.filterDisplay} ${styles.filterDisplay__close}`}>
          <p className={styles.filterName}>No Relationships Data...</p>
          <span className={styles.downArrow}>
            <MdArrowDropDown />
          </span>
        </div>
      </div>
    );
  }

  const relationshipData = data?.fetchUserRelationships;

  if (error) {
    console.log(customErrorHandler(error));
  }

  // main dropdown
  function toggleOpen() {
    if (openFilter === "relationship") {
      setOpenFilter("");
    } else {
      setOpenFilter("relationship");
    }
  }

  function toggleSubmenu(category: string) {
    if (showSubDropdown === category) {
      setShowSubDropdown("");
    } else {
      setShowSubDropdown(`${category}`);
    }
  }

  function selectItem(info: any) {
    searchDispatcher({
      type: SearchContextActions.SET_RELATIONSHIP_SEARCH_PARAMETER,
      payload: info,
    });
    toggleOpen();
  }

  return (
    <div className={styles.dropdown__container}>
      <div
        className={`${styles.filterDisplay}
        ${
          openFilter === "relationship"
            ? styles.filterDisplay__open
            : styles.filterDisplay__close
        } ${relationshipDisplayed ? styles.activeColor : ""}`}
        onClick={() => toggleOpen()}>
        <p className={styles.filterName}>Relationships</p>
        <span className={styles.downArrow}>
          <MdArrowDropDown
            style={{
              transform: `rotate(${
                openFilter === "relationship" ? "180" : "0"
              }deg)`,
            }}
          />
        </span>
      </div>

      <div
        className={`${styles.dropdown__selectionList} ${
          openFilter === "relationship" ? null : styles.dropdown__closed
        } ${styles.darkTheme__light}`}>
        {/* Reset Button */}
        <div className={`${styles.dropdown__item} `}>
          <button
            onClick={() => selectItem(null)}
            className={`${styles.dropdown__btn} ${styles.resetSingleFilter}
            ${styles.darkTheme__light}
            `}>
            Reset
          </button>
        </div>
        {/* Categories */}
        {relationshipData
          .sort((a, b) => (a!.category < b!.category ? -1 : 1))
          .map((cat, index) => {
            return (
              <div key={cat!.category} className={`${styles.dropdown__item} `}>
                <div
                  onClick={() =>
                    cat!.category !== "other"
                      ? toggleSubmenu(cat!.category)
                      : selectItem({
                          category: cat!.category,
                          relationship: "Other",
                        })
                  }>
                  <span
                    className={`${styles.dropdown__btn}
                    ${styles.darkTheme__light}
                    ${styles.display}`}>
                    {cat!.category}
                    {cat!.category !== "other" && <IoMdArrowDropright />}
                  </span>
                  {/* Values */}
                  {showSubDropdown === cat!.category ? (
                    <div className={styles.subDropdown}>
                      {cat!.relationships.sort().map((rel) => {
                        return (
                          <button
                            key={rel}
                            onClick={() =>
                              selectItem({
                                category: cat!.category,
                                relationship: rel,
                              })
                            }
                            className={`${styles.display} ${styles.subDropdown__selection}
                          ${styles.darkTheme__light}
                          `}>
                            {rel}
                          </button>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
      {relationshipDisplayed && (
        <div className={styles.selectionDisplay}>
          <p className={styles.selectedItem}>
            <button
              className={styles.selectedItem__btn}
              onClick={() => {
                searchDispatcher({
                  type: SearchContextActions.SET_RELATIONSHIP_SEARCH_PARAMETER,
                  payload: null,
                });
              }}>
              <AiOutlineClose className={styles.icon} />
            </button>{" "}
            {relationshipDisplayed}
          </p>
        </div>
      )}
    </div>
  );
};

export default RelationshipFilter;
