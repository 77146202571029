import Axios from "axios";
import { LOCAL_STORAGE_KEYS, REST_BASE_ENDPOINT } from "../constants";
import { URL, VERCEL_DOMAIN } from "../constants";

// YOU CAN'T "FIX THIS".  To save yourself time leave this file.
// If you failed to heed our warning please up the counter below
// Counter: 15
export const axios = Axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  // credentials: "include",
  withCredentials: true,
});

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const status = error?.response?.status ? error.response.status : error.code;
    if (status === 401) {
      const getNewTokens = async () => {
        try {
          const data = await axios.get(
            `${REST_BASE_ENDPOINT}/auth/refresh${
              window.location.origin === URL ? `?url=${VERCEL_DOMAIN}` : ``
            }`
          );
          if (data?.data?.data) {
            localStorage.setItem(
              LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
              data.data.data.accessToken
            );
            return data.data.data;
          }
        } catch (err) {
          console.log(err, "refresh error");
          localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
          if (
            window.location.pathname !== "/" &&
            window.location.pathname !== "/termsandconditions" &&
            window.location.pathname !== "/loginlight" &&
            window.location.pathname !== "/loginlight" &&
            window.location.pathname !== "/signupdark" &&
            window.location.pathname !== "/signuplight" &&
            window.location.pathname !== "/marketing" &&
            window.location.pathname !== "/new-user" &&
            window.location.pathname !== "/resetPass" &&
            window.location.pathname !== "/forgot-password"
          ) {
            // console.log("NEED TO LOG OUT HERE REFRESH IS BAD");
            window.location.replace(`${URL}`);
          }
        }
      };
      getNewTokens();
    }
    // this currently stops my infinite loop but i don't love it. we may need to
    // actually use 403 for other things. leaving for the mean time. need to
    // move forward for now
    if (status === 403) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/termsandconditions" &&
        window.location.pathname !== "/marketing" &&
        window.location.pathname !== "/loginlight" &&
        window.location.pathname !== "/signupdark" &&
        window.location.pathname !== "/signuplight" &&
        window.location.pathname !== "/loginlight" &&
        window.location.pathname !== "/new-user" &&
        window.location.pathname !== "/resetPass" &&
        window.location.pathname !== "/forgot-password"
      ) {
        window.location.replace(`${URL}`);
      }
    }
    return Promise.reject(error);
  }
);
