import Fuse from "fuse.js";
import { createContext, useContext, useEffect, useReducer } from "react";
import { useUserContext } from ".";
import {
  Topic,
  useAddTagsToUserProfileMutation,
  useAddTopicToUserPreferencesMutation,
  useDeleteTagFromUserProfileMutation,
  useDeleteTopicFromUserPreferencesMutation,
  useEditTopicFromUserPreferencesMutation,
  useFetchUserTopicsQuery,
  useUpdateTagFromUserProfileMutation,
  useUserTagsQuery,
} from "../../../codeGenFE";
import { useQueryClient } from "react-query";
import {
  ShowModalType,
  TagUserMessaging,
  UPActiveMode,
  UserProfileActionChoices,
  UserProfileActions,
  UserProfileState,
} from "../../Pages/UserProfile/userProfileTypes";
import {
  RelationText,
  RelationType,
} from "../../Components/QuickUploadBtn/QuickUpload/QuickUploadTypes";
import { useFetchTopicsFromPython } from "../../../rq-hooks/pythonRestApi/useFetchTopics";
import toast from "react-hot-toast";

// @ts-ignore
export const UserProfileContext = createContext();

const userProfileReducer = (
  state: UserProfileState,
  action: UserProfileActions
): UserProfileState => {
  switch (action.type) {
    case UserProfileActionChoices.SET_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    case UserProfileActionChoices.CHANGE_TAGS_SEARCH_STRING:
      return {
        ...state,
        searchTagsString: action.searchTagsString,
      };
    case UserProfileActionChoices.CHANGE_MODE:
      return {
        ...state,
        activeMode: action.activeMode,
        showLoading: false,
        searchTagsString: "",
        searchTopicString: "",
      };
    case UserProfileActionChoices.CHANGE_TAG_USER_MESSAGING:
      return {
        ...state,
        tagUserMessaging: action.tagUserMessaging,
      };
    case UserProfileActionChoices.CHANGE_TAG_TO_ADD:
      return {
        ...state,
        tagToAdd: action.tagToAdd,
      };
    case UserProfileActionChoices.SHOW_MODAL_TYPE:
      return {
        ...state,
        showModalType: action.payload,
      };
    case UserProfileActionChoices.SET_TAG_TO_EDIT:
      return {
        ...state,
        activeMode: action.activeMode,
        tagToEdit: action.tagToEdit,
        editTagInput: action.tagToEdit,
      };
    case UserProfileActionChoices.EDIT_TAG_INPUT:
      return {
        ...state,
        editTagInput: action.editTagInput,
      };
    case UserProfileActionChoices.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: action.errorMsg,
        showModalType: ShowModalType.NONE,
        showLoading: false,
      };
    case UserProfileActionChoices.SHOW_LOADING:
      return {
        ...state,
        showLoading: action.boolean,
        showModalType: ShowModalType.NONE,
      };
    case UserProfileActionChoices.SET_UI_TAG_ERROR:
      return {
        ...state,
        uiTagError: action.uiTagError,
      };
    case UserProfileActionChoices.SET_TOPIC_TO_MONITOR:
      return {
        ...state,
        topicToMonitor: action.topicToMonitor,
      };
    case UserProfileActionChoices.SET_EXCLUDED_TOPIC_INPUT:
      return {
        ...state,
        excludedTopicInput: action.excludedTopicInput,
      };
    case UserProfileActionChoices.ADD_EXCLUDED_TOPIC_TO_ARRAY:
      return {
        ...state,
        excludedTopicsArray: [...state.excludedTopicsArray, action.payload],
        excludedTopicInput: "",
      };
    case UserProfileActionChoices.REMOVE_EXCLUDED_TOPIC_FROM_ARRAY:
      return {
        ...state,
        excludedTopicsArray: state.excludedTopicsArray.filter(
          (t) => t !== action.payload
        ),
      };
    case UserProfileActionChoices.TOGGLE_RELATION_DROPDOWN:
      return {
        ...state,
        isRelationDropVisible: action.boolean,
      };
    case UserProfileActionChoices.SET_RELATIONSHIP:
      return {
        ...state,
        relationship: {
          relText: action.rel.relText,
          relType: action.rel.relType,
        },
      };
    case UserProfileActionChoices.SET_COUNTRY_ARRAY:
      return {
        ...state,
        // @ts-ignore
        countryArray: action.countryArray,
      };
    case UserProfileActionChoices.TOGGLE_COUNTRY_DROPDOWN:
      return {
        ...state,
        showCountryDropdown: action.showCountryDropdown,
      };
    case UserProfileActionChoices.SET_TOPICS:
      return {
        ...state,
        topics: action.payload,
      };
    case UserProfileActionChoices.CHANGE_TOPIC_SEARCH_STRING:
      return {
        ...state,
        searchTopicString: action.payload,
      };
    case UserProfileActionChoices.RESET_TOPIC_FORM:
      return {
        ...state,
        topicId: "",
        topicToMonitor: "",
        excludedTopicsArray: [],
        excludedTopicInput: "",
        isRelationDropVisible: false,
        relationship: {
          relText: RelationText.SELECT_HOW_THIS_TOPIC_RELATES_TO_YOU,
          relType: RelationType.OTHER,
        },
        showCountryDropdown: false,
        countryArray: [],
      };
    case UserProfileActionChoices.SET_TOPIC_TO_EDIT:
      return {
        ...state,
        topicId: action.topic.id,
        topicToMonitor: action.topic.topic,
        relationship: {
          // @ts-ignore
          relText: action.topic.relationshipText,
          // @ts-ignore
          relType: action.topic.relationshipType,
        },
        countryArray: action?.topic?.country ? action.topic.country : [],
        excludedTopicsArray: action?.topic?.excludedKeywords
          ? action?.topic?.excludedKeywords
          : [],
      };
    case UserProfileActionChoices.SET_TOPIC_TO_DELETE:
      return {
        ...state,
        topicToDelete: action.topicId,
      };

    case UserProfileActionChoices.RESET_CONTEXT:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

const initState: UserProfileState = {
  // ---------- TAGS ---------- //
  tags: [],
  activeMode: UPActiveMode.SEARCH_TOPICS,
  tagUserMessaging: TagUserMessaging.DELETED,
  tagToAdd: "",
  showModalType: ShowModalType.NONE,
  tagToEdit: "",
  editTagInput: "",
  errorMsg: "",
  showLoading: false,
  uiTagError: "",
  searchTagsString: "",

  // ---------- TOPICS ---------- //
  // TOPIC FORM (TOP OF PAGE)
  topicToMonitor: "",
  excludedTopicsArray: [],
  excludedTopicInput: "",
  isRelationDropVisible: false,
  relationship: {
    relText: RelationText.SELECT_HOW_THIS_TOPIC_RELATES_TO_YOU,
    relType: RelationType.OTHER,
  },
  showCountryDropdown: false,
  countryArray: [],

  // TOPIC CARD (SEARCH / SELECT AREA)
  topicId: "",
  searchTopicString: "",
  topics: [],
  topicToDelete: "",
};

export function UserProfileProvider(props: any) {
  const { user } = useUserContext();
  const qClient = useQueryClient();

  const [userProfileState, userProfileDispatcher] = useReducer(
    userProfileReducer,
    initState
  );

  const { tags, searchTagsString, searchTopicString, tagUserMessaging } =
    userProfileState;

  // ====================================================================================
  //                                      Tags
  // ====================================================================================

  // Tags
  const {
    data: tagsData,
    error: tagsError,
    isFetching: tagsIsFetching,
  } = useUserTagsQuery(
    {},
    { enabled: !!user, refetchOnMount: false, staleTime: Infinity }
  );
  const availableTags = tagsData?.userTags;

  // Tag Mutations
  const { mutate: updateTagMutation } = useUpdateTagFromUserProfileMutation({
    onSuccess: () => {
      // console.log("success update tags", data);
      qClient.invalidateQueries("userTags");
      userProfileDispatcher({
        type: UserProfileActionChoices.CHANGE_MODE,
        activeMode: UPActiveMode.SEARCH_TAGS,
      });
    },
    onError: (err: any) => {
      console.log("error updating tags", err);
      userProfileDispatcher({
        type: UserProfileActionChoices.SET_ERROR_MESSAGE,
        errorMsg: err,
      });
    },
  });

  const { mutate: addTagMutate, isLoading: addTagLoading } =
    useAddTagsToUserProfileMutation({
      onSuccess: () => {
        userProfileDispatcher({
          type: UserProfileActionChoices.SET_UI_TAG_ERROR,
          uiTagError: "",
        });
        userProfileDispatcher({
          type: UserProfileActionChoices.CHANGE_TAG_USER_MESSAGING,
          tagUserMessaging: TagUserMessaging.ADDED,
        });
        // setUITagError("");
        userProfileDispatcher({
          type: UserProfileActionChoices.CHANGE_TAG_TO_ADD,
          tagToAdd: "",
        });
        qClient.invalidateQueries("userTags");
        toast.success(`Tag added!`);
      },
      onError: (err: any) => {
        console.log("err :>> ", err);
        userProfileDispatcher({
          type: UserProfileActionChoices.SET_ERROR_MESSAGE,
          errorMsg: err,
        });
      },
    });

  const { mutate: deleteTagMutation } = useDeleteTagFromUserProfileMutation({
    onSuccess: () => {
      // console.log(":) success data: ", data);
      userProfileDispatcher({
        type: UserProfileActionChoices.SHOW_MODAL_TYPE,
        payload: ShowModalType.NONE,
      });
      qClient.invalidateQueries("userTags");
      userProfileDispatcher({
        type: UserProfileActionChoices.SHOW_LOADING,
        boolean: false,
      });
      toast.success(`Tag deleted!`);
    },
    onError: (err) => {
      console.log(":() ERROR data: ", err);
      userProfileDispatcher({
        type: UserProfileActionChoices.SHOW_LOADING,
        boolean: false,
      });
    },
  });

  useEffect(() => {
    if (searchTagsString === "") {
      userProfileDispatcher({
        type: UserProfileActionChoices.SET_TAGS,
        tags: availableTags,
      });
    } else {
      const options = {
        shouldSort: true,
        threshold: 0.5,
        location: 0,
        distance: 500,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [],
      };

      // typeguard on Fuse to keep from fighting it
      let fuse = new Fuse(availableTags ? availableTags : [], options);
      let result = fuse.search(searchTagsString).map((i) => i.item);
      userProfileDispatcher({
        type: UserProfileActionChoices.SET_TAGS,
        tags: result.sort(),
      });
    }
  }, [availableTags, searchTagsString]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // ====================================================================================
  //                                      Topics
  // ====================================================================================
  const { data: topicsData, isFetching: isFetchingTopics } =
    useFetchUserTopicsQuery(
      {},
      { enabled: !!user, refetchOnWindowFocus: false, staleTime: Infinity }
    );

  const availableTopics = topicsData?.fetchUserTopics;

  useEffect(() => {
    if (availableTopics) {
      if (searchTopicString === "") {
        userProfileDispatcher({
          type: UserProfileActionChoices.SET_TOPICS,
          // @ts-ignore
          payload: availableTopics,
        });
      } else {
        const options = {
          shouldSort: true,
          threshold: 0.5,
          location: 0,
          distance: 500,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: ["topic"],
        };

        // typeguard on Fuse to keep from fighting it
        let fuse = new Fuse(availableTopics ? availableTopics : [], options);
        let result = fuse.search(searchTopicString).map((i) => i.item);
        userProfileDispatcher({
          type: UserProfileActionChoices.SET_TOPICS,
          // @ts-ignore
          payload: result,
        });
      }
    } else {
      userProfileDispatcher({
        type: UserProfileActionChoices.SET_TOPICS,
        payload: [],
      });
    }
  }, [availableTopics, searchTopicString]);

  const {
    mutate: addTopicMutation,
    isLoading: ATMutationLoading,
    error: addTopicError,
    reset: addTopicReset,
  } = useAddTopicToUserPreferencesMutation({
    onSuccess: () => {
      qClient.invalidateQueries("fetchUserTopics");
      userProfileDispatcher({
        type: UserProfileActionChoices.RESET_TOPIC_FORM,
      });
      userProfileDispatcher({
        type: UserProfileActionChoices.CHANGE_TAG_USER_MESSAGING,
        tagUserMessaging: TagUserMessaging.ADDED,
      });
      toast.success(`Successfully added topic!`);
    },
  });

  const {
    mutate: editTopicMutation,
    isLoading: editTopicMutationLoading,
    error: editTopicError,
    reset: editTopicReset,
  } = useEditTopicFromUserPreferencesMutation({
    onSuccess: (data) => {
      userProfileDispatcher({
        type: UserProfileActionChoices.RESET_TOPIC_FORM,
      });
      qClient.invalidateQueries("fetchUserTopics");
      userProfileDispatcher({
        type: UserProfileActionChoices.CHANGE_TAG_USER_MESSAGING,
        tagUserMessaging: TagUserMessaging.EDITED,
      });
      toast.success(`Topic changed!`);
    },
  });

  const {
    mutate: delTopicMutation,
    isLoading: delTopicMutationLoading,
    error: delTopicError,
    reset: delTopicReset,
  } = useDeleteTopicFromUserPreferencesMutation({
    onSuccess: () => {
      userProfileDispatcher({
        type: UserProfileActionChoices.SHOW_MODAL_TYPE,
        payload: ShowModalType.NONE,
      });
      qClient.invalidateQueries("fetchUserTopics");
      userProfileDispatcher({
        type: UserProfileActionChoices.CHANGE_TAG_USER_MESSAGING,
        tagUserMessaging: TagUserMessaging.DELETED,
      });
      toast.success(`Deleted topic!`);
    },
    onError: (err: any) => {
      userProfileDispatcher({
        type: UserProfileActionChoices.SET_ERROR_MESSAGE,
        errorMsg: err,
      });
    },
  });

  function topicDupCheck(topic: string, topics: Topic[] | []) {
    let dup = false;

    if (topics && topics.length > 0) {
      topics.forEach((item) => {
        // console.log("item.topic :>> ", item.topic.toLowerCase());
        // console.log("topic :>> ", topic.toLowerCase());
        if (item.topic.toLowerCase() === topic.toLowerCase()) {
          dup = true;
        }
      });
    }
    return dup;
  }

  useEffect(() => {
    if (tagUserMessaging !== TagUserMessaging.INIT) {
      setTimeout(() => {
        userProfileDispatcher({
          type: UserProfileActionChoices.CHANGE_TAG_USER_MESSAGING,
          tagUserMessaging: TagUserMessaging.INIT,
        });
      }, 1200);
    }
  }, [tagUserMessaging]);

  return (
    <UserProfileContext.Provider
      value={{
        userProfileState,
        userProfileDispatcher,
        availableTags: availableTags ? availableTags : [],
        tags: tags ? tags : [],
        tagsError,
        availableTopics,
        topicDupCheck,
        updateTagMutation,
        addTagMutate,
        deleteTagMutation,
        scrollToTop,
        addTopicMutation,
        topicInputError: addTopicError || editTopicError,
        topicDelError: delTopicError,
        addTopicReset,
        delTopicReset,
        delTopicMutation,
        editTopicMutation,
        editTopicReset,
        UPIsFetching:
          ATMutationLoading ||
          tagsIsFetching ||
          isFetchingTopics ||
          addTagLoading ||
          delTopicMutationLoading ||
          editTopicMutationLoading,
      }}>
      {props.children}
    </UserProfileContext.Provider>
  );
}

export const useUserProfileContext = () => {
  return useContext(UserProfileContext);
};
