import React, { useReducer } from "react";
// context
import { useNavContext } from "../../../context/allContexts";
import FileUpload from "./FileUpload/FileUpload";
import {
  ActiveUploadType,
  QuickUploadActionChoices,
  QuickUploadActions,
  QuickUploadState,
  // RelationText,
} from "./QuickUploadTypes";
import TextUpload from "./TextUpload/TextUpload";
import UrlUpload from "./UrlUpload/UrlUpload";

import styles from "./QuickUpload.module.scss";

const quickUploadReducer = (
  state: QuickUploadState,
  action: QuickUploadActions
) => {
  switch (action.type) {
    case QuickUploadActionChoices.CHANGE_UPLOAD_TYPE:
      return {
        ...state,
        uploadType: action.newUploadType,
      };
    default:
      return {
        ...state,
      };
  }
};

const initState: QuickUploadState = {
  uploadType: ActiveUploadType.URL,
};

const QuickUpload = () => {
  const { whichMenu } = useNavContext();
  // local state
  const [quickUploadState, quickUploadDispatch] = useReducer(
    quickUploadReducer,
    initState
  );
  const { uploadType } = quickUploadState;

  if (whichMenu !== "quickUpload") return null;

  return (
    <div
      className={styles.quickUpload__wrapper}
      onClick={(e) => e.stopPropagation()}>
      {uploadType === ActiveUploadType.URL && (
        <UrlUpload quickUploadDispatcher={quickUploadDispatch} />
      )}
      {uploadType === ActiveUploadType.TEXT && (
        <TextUpload quickUploadDispatcher={quickUploadDispatch} />
      )}
      {uploadType === ActiveUploadType.FILE && (
        <FileUpload quickUploadDispatcher={quickUploadDispatch} />
      )}
    </div>
  );
};

export default QuickUpload;
