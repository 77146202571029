import { createContext, useReducer, useContext } from "react";

const fileUploadReducer = (state, action) => {
  switch (action.type) {
    case "updateFiles":
      return {
        ...state,
        fileList: action.value,
      };
    case "removeFile":
      return {
        ...state,
        fileList: state.fileList.filter((file) => file.name !== action.name),
      };
    case "fileUploadLoading":
      return {
        ...state,
        fileUploadLoader: action.value,
        fileUploadingMessage: action.fileUploadingMessage,
        shareList: action.value,
      };
  }
  return state;
};

const initState = {
  fileList: [],
  fileUploadLoader: false,
  fileUploadingMessage: "",
  shareList: false,
};

export const FileUploadContext = createContext();

export function FileUploadProvider(props) {
  const [fileUploadState, fileUploadDispatch] = useReducer(
    fileUploadReducer,
    initState
  );
  const { fileList, fileUploadLoader, fileUploadingMessage, shareList } =
    fileUploadState;

  return (
    <FileUploadContext.Provider
      value={{
        fileList,
        fileUploadLoader,
        fileUploadingMessage,
        fileUploadState,
        shareList,
        fileUploadDispatch,
      }}>
      {props.children}
    </FileUploadContext.Provider>
  );
}

export const useFileUploadContext = () => {
  return useContext(FileUploadContext);
};
