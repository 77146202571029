import { Fragment, useEffect, useState, useLayoutEffect } from "react";
import styles from "./landingPage.module.scss";
import linkedinlight from "../../../assets/img/atoms/linkedin.svg";
import { useHistory } from "react-router-dom";
import { LINKEDIN_CLIENT_ID, LOCAL_STORAGE_KEYS } from "../../../constants";
import twitter from "../../../assets/img/atoms/twitter.svg";
import { useTwitterProcess } from "../../../rq-hooks/useTwitterProcess";
import LoadingCube from "../../Components/LoadingCube/LoadingCube";
import { AiFillQuestionCircle } from "react-icons/ai";
import DriverHelperModal from "../../Components/DriverHelperModal/DriverHelperModal";

const Summary = ({ allData, keywords }) => {
  const [size, setSize] = useState(5);
  const [size1, setSize1] = useState(5);
  const [modalShow, setModalShow] = useState(false);
  const [callBack, setCallBack] = useState(null);
  const navigate = useHistory();

  console.log("dataAllKeys", keywords);
  localStorage.setItem("summaryKeywords", keywords.toString());

  useLayoutEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    setCallBack(`${location.origin}${location.pathname.slice(0, -1)}`);
  }, []);

  console.log(allData);

  const handleLinkedInLogin = () => {
    // localStorage.setItem('reloaded', 'true')
    // localStorage.setItem("allData", allData);
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${callBack}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
  };

  const { mutate } = useTwitterProcess({
    onSuccess: (data) => {
      const responseArray = data.split("&");
      window.location.href = `https://api.twitter.com/oauth/authorize?${responseArray[0]}`;
    },
  });

  const handleTwitterLogin = () => {
    // localStorage.setItem("reloaded", "true");

    const callback = callBack;
    mutate({ callback });
  };

  if (allData) {
    function downloadFunction() {
      localStorage.setItem("summaryKeywords", keywords);
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.THEME) === "dark_theme") {
        localStorage.setItem("downloadPdf", true);
        navigate.push("/signupdark", {
          allData: allData,
          keywords: keywords,
        });
      } else {
        localStorage.setItem("downloadPdf", true);
        navigate.push("/signuplight", {
          allData: allData,
          keywords: keywords,
        });
      }
    }

    if (allData.content !== "log is omitted") {
      return (
        <Fragment>
          <div className={styles.summary + " "}>
            <div className="py-5 my-5 mx-0 py-0">
              <div className="row  w-100 d-sm-flex flex-sm-wrap justify-content-between px-0 mx-0">
                <div className="col-sm-4 d-sm-block d-none mx-auto "></div>
                <div className="col-sm-4 px-4 text-center mt-3">
                  <h2 className="fw-bold fs-2  my-2">
                    Here’s your Summary for
                  </h2>
                  <h4 className="mt-4 fs-5">{allData?.title}</h4>
                </div>
                <div
                  className={"col-sm-4 px-0 my-5 d-flex justify-content-center"}
                >
                  <div className={"w-100 text-center"}>
                    <div>
                      {/* <a href="/signuplight"> */}
                      <button
                        onClick={downloadFunction}
                        className={
                          styles.downloadButton +
                          "  btn bg-primary w-50 rounded rounded-2 py-2 mb-5 px-5 text-white fs-6 "
                        }
                      >
                        Download
                      </button>
                      {/* </a> */}
                    </div>
                    <div className="w-50 mx-auto d-flex align-items-center justify-content-evenly">
                      <img
                        className="pe-auto"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleLinkedInLogin()}
                        src={linkedinlight}
                        width="50px"
                        height="50px"
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => handleTwitterLogin()}
                        className="pe-auto"
                        src={twitter}
                        width="50px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className=" w-75 bg-white mx-auto row d-flex flex-wrap justify-content-evenly">
                  <div
                    className={` ${
                      allData?.driver[0] ==
                      "This article does not contain a driver."
                        ? "col-sm-12"
                        : "col-sm-5"
                    } p-3  mx-3 border border-gray rounded rounded-2`}
                  >
                    <h4 className="my-3 fs-5 fw-bold">Summary</h4>
                    {allData?.summary.slice(0, size).map((item, i) => {
                      return (
                        <div>
                          {i == allData.summary.length - 1 && (
                            <li
                              style={{ overflowWrap: "break-word" }}
                              className={" my-2 lh-sm py-3 text-wrap"}
                            >
                              {item}
                            </li>
                          )}
                          {i !== allData.summary.length - 1 && (
                            <li
                              style={{ overflowWrap: "break-word" }}
                              className={
                                styles.borderBottom +
                                " my-2 lh-sm py-3 text-wrap"
                              }
                            >
                              {item}
                            </li>
                          )}
                        </div>
                      );
                    })}
                    {allData?.summary.length > size && (
                      <button
                        onClick={() => setSize(allData?.summary.length)}
                        className={
                          " text-left rounded rounded-2 py-2 mb-5  text-primary fs-6 cursor-pointer"
                        }
                      >
                        Read More
                      </button>
                    )}
                  </div>
                  {allData.driver[0] ==
                    "This article does not contain a driver." && <div></div>}
                  {allData.driver[0] !==
                    "This article does not contain a driver." && (
                    <div className="col-sm-5 p-3 mx-3 my-4 my-sm-0 border border-gray rounded rounded-2">
                      <div className="d-flex justify-content-center align-items-center justify-content-between">
                        <div>
                          <h4 className="my-3 fs-5 fw-bold">Drivers</h4>
                        </div>
                        <div>
                          {" "}
                          <AiFillQuestionCircle
                            onClick={() => setModalShow(true)}
                            style={{
                              height: "25px",
                              width: "25px",
                              cursor: "pointer",
                            }}
                            className="float-right"
                          />{" "}
                        </div>
                      </div>
                      <DriverHelperModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                      {allData?.driver.slice(0, size1).map((item, i) => {
                        return (
                          <div>
                            {i == allData.driver.length - 1 && (
                              <li
                                style={{ overflowWrap: "break-word" }}
                                className={" my-2 lh-sm py-3 text-wrap"}
                              >
                                {item}
                              </li>
                            )}
                            {i !== allData.driver.length - 1 && (
                              <li
                                style={{ overflowWrap: "break-word" }}
                                className={
                                  styles.borderBottom +
                                  " my-2 lh-sm  py-3 text-wrap"
                                }
                              >
                                {item}
                              </li>
                            )}
                          </div>
                        );
                      })}
                      {allData?.driver.length > size1 && (
                        <button
                          onClick={() => setSize1(allData?.driver.length)}
                          className={
                            " text-left rounded rounded-2 py-2 mb-5  text-primary fs-6 cursor-pointer"
                          }
                        >
                          Read More
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  } else {
    <LoadingCube hasBackground={false} />;
  }
};
export default Summary;
