import React from "react";
import { useStateLabel } from "../../../../hooks/stateLabeler";

import styles from "./SingleNewsPreview.module.scss";

const SingleNewsPreview = ({
  article,
  selectedArticles,
  setSelectedArticles,
  id,
  isAskMeTopicModal,
}) => {
  const [showMore, setShowMore] = useStateLabel(false, "showMore");

  const handleShow = (str, isClicked) => {
    if (showMore === str) {
      setShowMore(false);
    } else {
      setShowMore(str);
    }
  };

  const askMeModalStyleTopicHeader = {
    fontSize: "0.9rem",
    color: "var(--OneLinkColor)",
  };

  const askMeModalStyleTopicText = {
    fontSize: "1.05rem",
  };

  const toggleSelectArticle = (id) => {
    if (selectedArticles.includes(id)) {
      setSelectedArticles(selectedArticles.filter((cId) => id !== cId));
    } else {
      setSelectedArticles([...selectedArticles, id]);
    }
  };

  return (
    <div className={styles.singleNewsPreviewWrapper}>
      <label className={styles.checkboxLabel}>
        <input
          type="checkbox"
          value={id}
          name="selectedArticle"
          checked={selectedArticles.includes(id)}
          onChange={() => toggleSelectArticle(id)}
        />
        <span className={styles.customCheckbox}></span>
      </label>
      <div className={styles.previewContent}>
        <a
          className={styles.singleNewsPreview__link}
          href={article._source.orig_url}
          target="_blank"
          rel="noreferrer noopener"
        >
          <h2
            style={isAskMeTopicModal && askMeModalStyleTopicHeader}
            className={styles.singleNewsPreview__title}
          >
            {article._source.title}
          </h2>
        </a>
        <p
          style={isAskMeTopicModal && askMeModalStyleTopicText}
          className={`${styles.singleNewsPreview__text} ${
            showMore === "more" ? styles.more : null
          }`}
          onClick={() => handleShow("more")}
        >
          {article._source.content}
        </p>
      </div>
    </div>
  );
};

export default SingleNewsPreview;
