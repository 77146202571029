import React, { useLayoutEffect } from 'react'
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai'
import styles from './Pagination.module.scss'
import { useState } from 'react';
import { useEffect } from 'react';
import { useMyMemoryContext } from '../MyMemoryContext';
import { useMyMemoryInfiniteArticles } from '../../../../rq-hooks';
import { MyMemoryActionChoices } from '../MyMemoryTypes';
import { usePagination } from '../../../hooks/usePagination';
import { useSearchContext } from '../../../context/allContexts';

interface PaginationInterface {
    total: number, limit: number, navEnabled: () => void
}

const Pagination = ({total, limit, navEnabled}: PaginationInterface) => {
    const [pageNumber, setPageNumber] = useState(localStorage.getItem("reloaded") && localStorage.getItem("page") ? parseInt(localStorage.getItem("page") || '1') : 1)
    const [totalPage, setTotalPage] = useState(0)
    const { myMemState, myMemDispatcher } = useMyMemoryContext();
    const { searchState } = useSearchContext();
    const paginationRange: any = usePagination({
        totalPage, pageSize: limit, siblingCount: 1, pageNumber
    })
   
    useEffect(() => {
        console.log(searchState)
        myMemDispatcher({
          type: MyMemoryActionChoices.SET_SKIP,
          v2Skip: 0,
          filesSkip: 0,
        })
        setPageNumber(1)
    }, [searchState])

    const totalPages = (total % limit) === 0 ? total / limit : Math.floor(total / limit) + 1
    useEffect(() => {
        setTotalPage(totalPages)
    }, [totalPages])

    useEffect(() => {
        localStorage.setItem('page', `${pageNumber}`)
        console.log(pageNumber)
        myMemDispatcher({
            type: MyMemoryActionChoices.SET_SKIP,
            v2Skip: pageNumber === 1 ? 0 : ((pageNumber - 1) * limit) - pageNumber * 2,
            filesSkip: pageNumber === 1 ? 0 : pageNumber * 2,
        })
    }, [pageNumber])

    const changePageNumber = (dir: string) => {
        navEnabled()
        if(dir === 'next' && totalPage > pageNumber){ 
            setPageNumber((number) => number + 1)
        }
        if(dir === 'prev' && (pageNumber - 1) > 0){ 
            setPageNumber((number) => number - 1)
        }
    }
    return (
        <div className={styles.container}>  
            <ul className={styles.page}>
                <li className={`${styles.btn} ${pageNumber === 1 ? styles.disabled : ""}`}>
                    <AiOutlineDoubleLeft style={{cursor: "pointer"}} onClick={() => changePageNumber('prev')} /> 
                </li>
                {paginationRange.map((page: number | string) => {
                
                    // If the pageItem is a DOT, render the DOTS unicode character
                    if (page === 'DOTS') {
                        return <li className={styles.dots}>&#8230;</li>;
                    }
                    // Render our Page Pills
                    return (
                        <li className={`${styles.number} ${page === pageNumber ? styles.active : ""}`} key={page} onClick={() => {
                            setPageNumber(page)
                            navEnabled()
                        }}> {page}</li>
                    );
                })}
                {/* <li className={styles.dots}>...</li>
                <li className={styles.number}> {total}</li> */}
                <li className={`${styles.btn} ${pageNumber === paginationRange[paginationRange.length - 1] ? styles.disabled : ""}`}>
                    <AiOutlineDoubleRight style={{cursor: "pointer"}} onClick={() => changePageNumber('next')} />
                </li>
            </ul>
        </div>
    )
}

export default Pagination