import React, { FC, useEffect, useReducer } from "react";

import {
  useSearchContext,
  useUserContext,
} from "../../../../context/allContexts";
import Trending from "../../../Dashboard/DashInfoContainer/Trending/Trending";
import { useStateLabel } from "../../../../hooks/stateLabeler";
import { v4 as uuidv4 } from "uuid";

import styles from "./AskMeTrending.module.scss";
import {
  Maybe,
  TrendingCategorySet,
  TrendingItem,
  useArticlesTrendingQuery,
  useFetchUserTopicsQuery,
} from "../../../../../codeGenFE";
import { useQueryClient } from "react-query";

let padding = {
  top: 100,
  right: 0,
  bottom: 100,
  left: 100,
};

interface State {
  organizations: [] | string[];
  products: [] | string[];
  locations: [] | string[];
  person: [] | string[];
  max: number;
  width: number;
}

type Actions =
  | { type: "set trending data"; payload: any }
  | { type: "no data" };

const trendingReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "set trending data":
      const newMax: number =
        action.payload.max + (10 - (action.payload.max % 10));

      return {
        ...state,
        organizations: action.payload.orgs,
        products: action.payload.products,
        locations: action.payload.locations,
        person: action.payload.person,
        max: newMax,
        width: action.payload.chartWidth,
      };

    case "no data":
      return {
        ...state,
        organizations: [],
        products: [],
        locations: [],
        person: [],
      };

    default:
      return state;
  }
};

const initState: State = {
  organizations: [],
  products: [],
  locations: [],
  person: [],
  max: 1,
  width: 100,
};

interface Props {
  topic: string;
}

const AskMeTrending: FC<Props> = ({ topic }) => {
  const {
    data: userTopicsRes,
    status: userTopicsStatus,
    error: userTopicsError,
  } = useFetchUserTopicsQuery();

  const userTopics = userTopicsRes?.fetchUserTopics
    ? userTopicsRes.fetchUserTopics?.map((t) => t!.topic)
    : [];

  function findTopic(userTopics: [] | string[], topic: string) {
    let findTopic = topic;
    userTopics.forEach((t) => {
      if (t.toLowerCase() === topic.toLowerCase()) findTopic = t;
    });
    return findTopic;
  }

  const { data, status, error } = useArticlesTrendingQuery(
    {
      inputOpts: { topic: findTopic(userTopics, topic) },
    },
    {
      enabled: userTopicsStatus === "success" && !!topic,
    }
  );

  const qClient = useQueryClient();
  useEffect(() => {
    qClient.invalidateQueries("articlesTrending");
  }, [topic]);

  const products = data?.articlesTrending?.mentions?.product;
  const person = data?.articlesTrending?.mentions?.person;
  const organizations = data?.articlesTrending?.mentions?.company;
  const locations = data?.articlesTrending?.mentions?.location;

  const [trendingState, trendingDispatcher] = useReducer(
    trendingReducer,
    initState
  );

  function maxCalc(mentions: TrendingCategorySet | undefined) {
    let max = 1;
    if (mentions) {
      if (mentions.company) {
        mentions.company.forEach((c) => {
          if (c!.value > max) {
            max = c!.value;
          }
        });
      }
      if (mentions.location) {
        mentions.location.forEach((l) => {
          if (l!.value > max) {
            max = l!.value;
          }
        });
      }
      if (mentions.person) {
        mentions.person.forEach((p) => {
          if (p!.value > max) {
            max = p!.value;
          }
        });
      }
      if (mentions.product) {
        mentions.product.forEach((p) => {
          if (p!.value > max) {
            max = p!.value;
          }
        });
      }
    }

    // action.payload.max + (10 - (action.payload.max % 10)
    return max + (10 - (max % 10));
  }

  const max = maxCalc(data?.articlesTrending?.mentions);

  if (
    organizations?.length === 0 &&
    products?.length === 0 &&
    locations?.length === 0 &&
    person?.length === 0
  ) {
    return <p className={styles.noData}> No trending data, sorry! </p>;
  }

  return (
    <>
      <div className={styles.chartContainer}>
        <div className={styles.yAxis}>
          {/* ========== Left Axis Labels ========== */}
          <div className={`${styles.yAxis__label} ${styles.leftLabel1}`}>
            {max}
          </div>
          <div className={`${styles.yAxis__label} ${styles.leftLabel2}`}>
            {Math.trunc(max * 0.8) !== max * 0.8
              ? Math.trunc(max * 0.8 * 10) / 10
              : max * 0.8}
          </div>
          <div className={`${styles.yAxis__label} ${styles.leftLabel3}`}>
            {Math.trunc(max * 0.6) !== max * 0.6
              ? Math.trunc(max * 0.6 * 10) / 10
              : max * 0.6}
          </div>
          <div className={`${styles.yAxis__label} ${styles.leftLabel4}`}>
            {Math.trunc(max * 0.4) !== max * 0.4
              ? Math.trunc(max * 0.4 * 10) / 10
              : max * 0.4}
          </div>
          <div className={`${styles.yAxis__label} ${styles.leftLabel5}`}>
            {Math.trunc(max * 0.2) !== max * 0.2
              ? Math.trunc(max * 0.2 * 10) / 10
              : max * 0.2}
          </div>
          <div className={`${styles.yAxis__label} ${styles.leftLabel6}`}>0</div>
        </div>
        {/* bar graphs */}
        {/* ========== Background Lines ========== */}
        <div className={styles.chart}>
          <hr className={`${styles.line1} ${styles.lines}`} />
          <hr className={`${styles.line2} ${styles.lines}`} />
          <hr className={`${styles.line3} ${styles.lines}`} />
          <hr className={`${styles.line4} ${styles.lines}`} />
          <hr className={`${styles.line5} ${styles.lines}`} />
          <hr className={`${styles.line6} ${styles.lines}`} />

          {/* ========== Org Category ========== */}
          {organizations && organizations?.length > 0 && (
            <ChartGroup data={organizations as ChartDataPoint[]} max={max} />
          )}
          {/* ========== Products Category ========== */}
          {products && products?.length > 0 && (
            <ChartGroup data={products as ChartDataPoint[]} max={max} />
          )}
          {/* ========== Locations Category ========== */}
          {locations && locations?.length > 0 && (
            <ChartGroup data={locations as ChartDataPoint[]} max={max} />
          )}
          {/* ========== People Category ========== */}
          {person && person?.length > 0 && (
            <ChartGroup data={person as ChartDataPoint[]} max={max} />
          )}
          {/* ========== X Axis Category Labels ========== */}
          <div className={styles.xAxis__div}>
            {organizations && organizations.length > 0 && (
              <div className={styles.xAxis__group}>COMPANY</div>
            )}
            {products && products.length > 0 && (
              <div className={styles.xAxis__group}>PRODUCT</div>
            )}
            {locations && locations.length > 0 && (
              <div className={styles.xAxis__group}>LOCATION</div>
            )}
            {person && person.length > 0 && (
              <div className={styles.xAxis__group}>PERSON</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AskMeTrending;

interface ChartDataPoint {
  label: string;
  value: number;
}

interface ChartGroupProps {
  data: ChartDataPoint[];
  max: number;
}

function ChartGroup({ data, max }: ChartGroupProps) {
  if (!data) {
    return null;
  }

  return (
    <div className={styles.category}>
      {data.slice(0, 3).map((item: ChartDataPoint) => {
        return (
          <div
            key={uuidv4()}
            className={styles.item__bar}
            style={{
              height: `${(item.value / max) * 100}%`,
              width: `${(item.value / max) * 100}%`,
            }}
          >
            <p className={styles.item__p}>
              {item.label}
              <span className={styles.mobileYLabel}>: {item.value}</span>
            </p>
          </div>
        );
      })}
    </div>
  );
}
