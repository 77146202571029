import React from "react";
import styles from "./ErrorAnswer.module.scss";

// Libraries
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";

const ErrorAnswer = ({ errorData, dispatcher, retryShare }) => {
  return (
    <div className={styles.error__container}>
      <h3 className={styles.error__title}>Sorry we encountered a glitch</h3>
      <ul className={styles.error__ul}>
        {/* error data is the array of responses that are received when trying to upload the articles.  If any are errors, it will trigger this to show */}
        {errorData.map((err, index) => {
          return (
            <li className={styles.error__li} key={err.id}>
              {err.success ? (
                <AiOutlineCheck className={styles.error__icon__Check} />
              ) : (
                <AiOutlineClose className={styles.error__icon__x} />
              )}
              <span className={styles.error__span}>{err.message}</span>
              {err.canRetry && (
                <button
                  className={styles.error__btn__retry}
                  onClick={async () => {
                    const res = await retryShare(err);
                    if (res === "success") {
                      // change icon to checkmark and success message
                      let updatedData = errorData;
                      updatedData[index].canRetry = false;
                      updatedData[index].message = "Success!";
                      updatedData[index].error = false;
                      updatedData[index].success = true;
                      dispatcher({
                        type: "retry",
                        data: updatedData,
                      });
                    } else {
                      // change text to sorry didn't work and remove retry from this index.
                      let updatedData = errorData;
                      updatedData[index].canRetry = false;
                      updatedData[index].message =
                        "Sorry this isn't working right now, try again later";
                      updatedData[index].error = true;
                      updatedData[index].success = false;
                      dispatcher({
                        type: "retry",
                        data: updatedData,
                      });
                    }
                  }}
                >
                  Retry?
                </button>
              )}
            </li>
          );
        })}
      </ul>
      <button
        className={styles.error__btn__close}
        onClick={() => dispatcher({ type: "reset" })}
      >
        close
      </button>
    </div>
  );
};

export default ErrorAnswer;
