import React, { FC } from "react";
import { WhichDb } from "../../../../../../codeGenFE";

import styles from "../ACDisplayTags.module.scss";

interface Props {
  deleteTag: () => void;
  cancel: () => void;
}

const DeleteConfirmation: FC<Props> = ({ deleteTag, cancel }) => {
  return (
    <ul className={styles.tags__UL}>
      <li className={styles.tagsConfirmDisplay}>Are you sure?</li>
      <div className={styles.btnWrapper}>
        <button className={styles.deleteTagsConfirm} onClick={deleteTag}>
          Delete
        </button>
        <button className={styles.cancelTagsConfirm} onClick={cancel}>
          Cancel
        </button>
      </div>
    </ul>
  );
};

export default DeleteConfirmation;
