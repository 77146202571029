import { useDeleteNoteMutation } from "../../../../../codeGenFE";
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
// CONTEXT
import { useNotebookContext } from "../../../../context/allContexts";
import ComponentMessagingOverlay from "../../../ComponentMessagingOverlay/ComponentMessagingOverlay";
// STYLES
import styles from "./DeleteConfirmNotebook.module.scss";

const DeleteConfirmNotebook = ({ close }) => {
  // context
  const { notebookState, notebookDispatch } = useNotebookContext();
  const { currentActiveNoteId } = notebookState;

  const { mutate, reset, error } = useDeleteNoteMutation({
    onSuccess: () => {
      notebookDispatch({
        type: "set editor overlay",
        name: null,
        showNoteList: true,
      });
    },
  });

  if (error) {
    return (
      <ComponentMessagingOverlay
        showX={true}
        message={customErrorHandler(error)}
        close={reset}
      />
    );
  }

  return (
    <div className={styles.deleteConfirmWrapper}>
      <p className={styles.deleteP}>Just want to make sure,</p>
      <p className={styles.deleteP}>
        this action will delete the corresponding article.
      </p>
      <div className={styles.btnContainer}>
        <button
          className={styles.confirmBtn}
          onClick={() => {
            mutate({ noteId: currentActiveNoteId });
          }}
        >
          Delete
        </button>
        <button className={styles.cancelBtn} onClick={() => close()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteConfirmNotebook;
