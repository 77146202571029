import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useBoardConsent, useGetBoardConsent } from "../../../rq-hooks";
import ConsentModal from "./ConsentModal/ConsentModal";
import { useUserContext } from "../../context/allContexts";

// import styles from "./BoardConsent.module.scss";

const BoardConsent = () => {
  const qClient = useQueryClient();
  const { user } = useUserContext();
  const {
    data: bData,
    status: bStatus,
    error: bError,
    refetch,
  } = useGetBoardConsent({
    enabled: false,
    refetchOnWindowFocus: false,
  });
  const { mutate, error, data, status } = useBoardConsent({
    onSuccess: (data) => {
      // console.log("data here", data);
      // const hasBoardConsent = data?.response?.bo
      if (data && data?.response && "board_consent" in data.response) {
        qClient.invalidateQueries("board_consent");
        return "Yes";
      } else {
        setShowConsentModal(true);
      }
    },
    onError: (err) => {
      console.log("error from useBoardConsent", err);
    },
  });
  const [showConsentModal, setShowConsentModal] = useState(false);

  const handleConsentClose = async () => {
    const answer = await check();
    if (answer === "Yes") {
      setShowConsentModal(!showConsentModal);
    } else {
      setShowConsentModal(true);
    }
  };

  // https://app.nowigence.ai/preferences/board_consent
  const submitBoardChange = async (e) => {
    e.stopPropagation();
    // console.log("running submitBoardChange");
    const consentObj = { givenConcent: "Yes" };
    // console.log({ consentObj });
    mutate(consentObj);
  };

  useEffect(() => {
    // console.log("this is running.....");
    switch (status) {
      case "error":
        console.log("error", error);
        break;
      case "loading":
        // console.log("loading", status.loading);
        break;
      case "success":
        // console.log("success", data);
        if (data?.success === "Succesfully added") {
          setShowConsentModal(false);
        }
        break;
      default:
        break;
    }
  }, [status, data]);

  useEffect(() => {
    switch (bStatus) {
      case "error":
        console.log("get error", bError);
        break;
      case "loading":
        // console.log("getloading", bStatus.loading);
        break;
      case "success":
        if (bData) {
          // console.log("bData", bData);
          // console.log("get success", bData);
          // console.log("board_consent" in bData);
          if (bData && "board_consent" in bData) {
            qClient.invalidateQueries("board_consent");
            return "Yes";
          } else {
            setShowConsentModal(true);
          }
        }
        break;
      default:
        break;
    }
  }, [bStatus, bData]);

  const check = async () => {
    const requiresDisclaimer = [
      //jon
      "5db858ca9b0fe61e36f76907",
      //jon
      "5da7666e9b0fe61e36f768f8",
      //jon
      "5d8b5d71b1b3ac2677b9a870",
      // jon bernal
      "5dd54ffe9b0fe61e36f7691c",
      // molly test
      "60145a0bfa370d56968888bf",
      // board@nowigence.com
      "5d2e3d3621f03801972ff94e",
    ];
    requiresDisclaimer.map((individual) => {
      if (user._id === individual.toString()) {
        refetch();
      } else {
        return "Yes";
      }
    });
  };

  useEffect(() => {
    check();
  }, []);

  if (showConsentModal) {
    return (
      <ConsentModal
        showConsentModal={showConsentModal}
        handleConsentClose={handleConsentClose}
        submitBoardChange={submitBoardChange}
      />
    );
  } else {
    return null;
  }
};

export default BoardConsent;
