// This function takes argument from error -> checks for @ sign which is added
// to all custom error messages thrown on the backend. if it's found it removes
// the @ and returns that message else it return a custom error message "Sorry,
// an unexpected error has occurred"

export const customErrorHandler = (errorMsg: any) => {
  if (errorMsg?.[0] === "@") return errorMsg.substring(1);
  // console.log(errorMsg);
  return "Sorry, an unexpected error has occurred";
};
