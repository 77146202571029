import { FC } from "react";
import styles from "./AFooter.module.scss";
import { useUserContext } from "../../../../context/allContexts";

// Libraries
import { TiThumbsUp, TiThumbsDown } from "react-icons/ti";
import { toast } from "react-hot-toast";

interface Props {
  webOrMem?: string;
  selectAllArticles: () => void;
  selectedArticles: string[] | [];
  answerDispatcher: (arg: any) => void;
}

const AFooter: FC<Props> = ({
  webOrMem,
  selectAllArticles,
  selectedArticles,
  answerDispatcher,
}) => {
  const { disableShare } = useUserContext();
  return (
    <>
      <div className={styles.footer__innerDiv}>
        <button
          className={`${styles.footer__btn} ${styles.footer__btn__noBorder}`}
          onClick={() => selectAllArticles()}>
          Select All
        </button>
        <button
          onClick={() => {
            disableShare ?  toast.error("You don't have team members for sharing") : answerDispatcher({ type: "show share" })
          }}
          className={`${styles.footer__btn} ${
            (!selectedArticles.length) &&
            styles.footer__btn__disabled
          }`}
          disabled={!selectedArticles.length}>
          Share
        </button>
        {
          webOrMem === "web" ?
          <button
            onClick={() => {
              answerDispatcher({ type: "add to memory" });
              answerDispatcher({ type: "share articles", status: false })
              toast.success("This article will be added soon!", { duration: 4000 })
            }}
            className={`${styles.footer__btn} ${
              (disableShare || !selectedArticles.length) &&
              styles.footer__btn__disabled
            }`}
            disabled={!selectedArticles.length ? true : false}>
            Add to memory
          </button>
          :
          ""
        }
        {/* <button>Add to Note</button> */}
      </div>
      <div className={styles.footer__innerDiv}>
        <TiThumbsUp className={styles.thumbs} />
        <TiThumbsDown className={styles.thumbs} />
      </div>
    </>
  );
};

export default AFooter;
