import React, { FC, useState } from "react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
// import { useQueryClient } from "react-query";
// import { useDeleteTagFromUserProfileMutation } from "../../../../../../codeGenFE";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../../../Components/LoadingCube/LoadingCube";
import ConfirmModal from "../../../ConfirmModal/ConfirmModal";
import { useUserProfileContext } from "../../../../../context/allContexts/UserProfileContext";
import {
  ShowModalType,
  UPActiveMode,
  UserProfileActionChoices,
} from "../../../userProfileTypes";

import styles from "../UPTopicsOrTags.module.scss";

const UPTags: FC = () => {
  const {
    // @ts-ignore
    tags,
    // @ts-ignore
    userProfileDispatcher,
    // @ts-ignore
    userProfileState,
    // @ts-ignore
    deleteTagMutation,
    // @ts-ignore
    UPIsFetching,
    // @ts-ignore
    updateTagMutation,
  } = useUserProfileContext();
  const { showModalType, showLoading, tagToEdit, editTagInput } =
    userProfileState;

  // const qClient = useQueryClient();

  // TODO: MAKE DELETE MUTATION
  // const { mutate: deleteTagMutation } = useDeleteTagFromUserProfileMutation({
  //   onSuccess: (data) => {
  //     console.log(":) success data: ", data);
  //     userProfileDispatcher({
  //       type: UserProfileActionChoices.SHOW_MODAL_TYPE,
  //       showModalType: ShowModalType.NONE,
  //     });
  //     qClient.invalidateQueries("userTags");
  //     userProfileDispatcher({
  //       type: UserProfileActionChoices.SHOW_LOADING,
  //       boolean: false,
  //     });
  //   },
  //   onError: (err) => {
  //     console.log(":() ERROR data: ", err);
  //     userProfileDispatcher({
  //       type: UserProfileActionChoices.SHOW_LOADING,
  //       boolean: false,
  //     });
  //   },
  // });
  const [tagToDel, setTagToDel] = useState("");

  return (
    <div className={styles.scrollAZContainer}>
      {(showLoading || UPIsFetching) && (
        <div className={styles.loadingContainer}>
          <LoadingCube
            hasBackground={true}
            height={100}
            width={100}
            theme={LoadingCubeTheme.DARK}
          />
        </div>
      )}
      {showModalType === ShowModalType.DELETE && (
        // oldTag === item && (
        <ConfirmModal
          type={ShowModalType.DELETE}
          label="This will delete the tag from every article, are you sure?"
          func={() => {
            if (tagToDel) {
              deleteTagMutation({ tag: tagToDel });
              userProfileDispatcher({
                type: UserProfileActionChoices.SHOW_LOADING,
                boolean: true,
              });
            }
          }}
          close={() => {
            setTagToDel("");
            userProfileDispatcher({
              type: UserProfileActionChoices.SHOW_MODAL_TYPE,
              showModalType: ShowModalType.NONE,
            });
          }}
        />
      )}
      {showModalType === ShowModalType.EDIT && (
        <ConfirmModal
          close={() =>
            userProfileDispatcher({
              type: UserProfileActionChoices.SHOW_MODAL_TYPE,
              activeMode: ShowModalType.NONE,
            })
          }
          func={() => {
            updateTagMutation({
              newTag: editTagInput,
              oldTag: tagToEdit,
            });
            userProfileDispatcher({
              type: UserProfileActionChoices.SHOW_LOADING,
              boolean: true,
            });
          }}
          label={`Are you sure you want to change ${tagToEdit} to ${editTagInput} on every article?`}
          type={ShowModalType.EDIT}
        />
      )}
      <div
        className={`${styles.scrollAZContainer__innerContainer} ${
          tags && tags.length > 3 && styles.flexInnerContainer
        }`}
      >
        {tags &&
          tags
            .sort((a: string, b: string) => sortFunc(a, b))
            .map((item: string, index: number) => {
              let prevTag = null;
              if (index !== 0) {
                prevTag = tags[index - 1];
              }

              return (
                <React.Fragment key={index}>
                  {/* Capital Letter Label is located at bottom of file */}
                  <CapitalLetterLabel
                    currentItem={item}
                    prevItem={prevTag}
                    index={index}
                  />
                  <div className={styles.topicWrapper}>
                    <li key={index}>{item}</li>
                    <div className={styles.iconContainer}>
                      {/* <AiOutlineBars className={styles.icon} /> */}
                      <FiEdit2
                        className={styles.icon}
                        title="Edit tag"
                        onClick={() => {
                          userProfileDispatcher({
                            type: UserProfileActionChoices.SET_TAG_TO_EDIT,
                            activeMode: UPActiveMode.EDIT_TAGS,
                            tagToEdit: item,
                          });
                          // setIsTopicsOrTags("editTagging");
                          // setOldTag(item);
                          // setNewTag(item);
                        }}
                      />
                      <FiTrash2
                        className={styles.icon}
                        title="Delete tag"
                        onClick={() => {
                          // setConfirmModal("delete");
                          userProfileDispatcher({
                            type: UserProfileActionChoices.SHOW_MODAL_TYPE,
                            payload: ShowModalType.DELETE,
                          });
                          setTagToDel(item);
                          // setOldTag(item);
                        }}
                      />
                      {/*TODO: WIP need backend resolver to delete ... then come back here*/}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
      </div>
    </div>
  );
};

export default UPTags;

function sortFunc(a: string, b: string) {
  return a!.toLowerCase() < b!.toLowerCase() ? -1 : 1;
}

interface CapitalLetterProps {
  currentItem: string;
  prevItem: string | null;
  index: number;
}

function CapitalLetterLabel({
  currentItem,
  prevItem,
  index,
}: CapitalLetterProps) {
  // If checks for old data that hasn't been normalized yet.
  if (!currentItem?.[0]) {
    return null;
  }
  if (
    index === 0 ||
    !prevItem ||
    !prevItem[0] ||
    currentItem[0].toLowerCase() !== prevItem[0].toLowerCase()
  ) {
    return <p className={styles.scrollAZ__p}>{currentItem[0].toUpperCase()}</p>;
  } else {
    return null;
  }
}
