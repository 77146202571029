import { saveAs } from 'file-saver';
import { axios } from '../../utils/axiosConfig';
import { REST_BASE_ENDPOINT } from '../../constants';

export function generatePDF() {
  console.log('generating PDF')
    generationTime()

  function generationTime() {
    const getData = JSON.parse(localStorage.getItem('allData'))
    const title =  (getData?.title).replace(/\.[^/.]+$/, "")
    const summary = getData?.summary
    let consolidatedData = `<h1>${title}</h1><br><br><h2>Summary</h2><br><ul>`
    summary.forEach((text) => consolidatedData += `<li>${text}</li>`)
    consolidatedData += `</ul>`
    const drivers = getData.driver
    consolidatedData += `<br><h2>Drivers</h2><br><ul>`
    drivers.forEach((text) => consolidatedData += `<li>${text}</li>`)
    consolidatedData += `</ul>`

    axios({
      method: 'post',
      responseType: 'arraybuffer', //Force to receive data in a Blob Format
      url: `${REST_BASE_ENDPOINT}/auth/export-notebook`,
      data: {html: consolidatedData, type: 'pdf'}
    })
      .then(res => {
          const blob = new Blob([res.data], {
            type: 'application/octet-stream'
          })
          saveAs(blob, title ? `${title}.pdf` : `notebook.pdf`)
          localStorage.removeItem("downloadPdf")
      })
      .catch(error => {
          console.log(error.message);
      });
  }
}
