import { useUserContext } from "../../../../context/allContexts";

import styles from "./RiskOppInfo.module.scss";

const RiskOppInfo = ({ sentiment_score, pubStatus, user_ro }) => {
  const { user } = useUserContext();

  const riskOppInfo = (score, status, userIdObj) => {
    // info: 0, risk: -1, opp: 1
    if (!score) return null;
    if (status !== "public" || !userIdObj[user._id]) {
      switch (score) {
        case 0:
          return <p className={styles.insights__p}>Outcome: Informational</p>;
        case -1:
          return <p className={styles.insights__p}>Outcome: Risk</p>;
        case 1:
          return <p className={styles.insights__p}>Outcome: Opportunity</p>;
        default:
          return null;
      }
    } else {
      const myScore = userIdObj[user._id][0];
      switch (myScore) {
        case 0:
          return <p className={styles.insights__p}>Outcome: Informational</p>;
        case -1:
          return <p className={styles.insights__p}>Outcome: Risk</p>;
        case 1:
          return <p className={styles.insights__p}>Outcome: Opportunity</p>;
        default:
          return null;
      }
    }
  };

  return riskOppInfo(sentiment_score?.[0], pubStatus, user_ro);
};

export default RiskOppInfo;
