import { useSwiper } from "swiper/react";

export default function SlideNextButton({ text, keywords }) {
  const swiper = useSwiper();
  const clickhandler = () => {
    keywords();
    swiper.slideNext();
  };

  return (
    <div
      style={{
        border: "1px solid black",
        background: "#3CC0F6",
        color: "black",
        width: "107px",
      }}
      className=" flex w-14 text-center cursor-pointer  justify-center font-bold ml-auto text-sm mb-1 py-3 rounded-lg "
      type="button"
      onClick={clickhandler}
    >
      {text}
    </div>
  );
}
