import React, { useState, useEffect } from 'react'
import CompanyProfile from './companyProfile/companyProfile'
import SwotAnalysis from '../../Dashboard/SwotAnalysis'
import RecentBoard from './RecentBoard/RecentBoard'
import Acquisitions from './Acquisitions/acquisitions'
import FocusArea from './focusArea/focusArea'
import axios from 'axios'
import PainPoints from './points/painpoints'
import RiskAssociated from './riskAssociated/riskAssociated'
import { API_BASE_URL, TECH_M_ENDPOINTS } from '../../../Constants'
import { toast } from 'react-toastify'
import LoadingCube from '../../../Components/LoadingCube/LoadingCube'

export default function GeneralPage({ currentCompany }) {

  const [loading, setLoading] = useState(true)
  const [finData, setFinData] = useState({})

  const getGeneralData = async (company) => {
    // setLoading(true)
    await axios
      .get(API_BASE_URL + TECH_M_ENDPOINTS.GENERAL(company))
      .then((res) => {
        setFinData(res.data.data)
      })
      .catch(() => {
        toast.error('Something went wrong')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (currentCompany) getGeneralData(currentCompany)
  }, [currentCompany])

  console.group('general data')
  console.log(finData)
  console.log(currentCompany)
  console.groupEnd()
  return (
    <div>
      {loading ? (
        <div>
          <LoadingCube />
        </div>
      ) : (
        <>
          <CompanyProfile data={finData} />
          <SwotAnalysis analysis={finData} />
          <RecentBoard announcements={finData} />
          <PainPoints data={finData} />
          <Acquisitions data={finData} />
          <FocusArea data={finData} />
          <RiskAssociated data={finData} />
        </>
      )}
    </div>
  )
}
