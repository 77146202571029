import { FC } from "react";
import { useArticlesTrendingQuery } from "../../../../../codeGenFE";
import { useSearchContext } from "../../../../context/allContexts";
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";
import { trendingPlaceholderData } from "../../../../Pages/Dashboard/DashInfoContainer/Trending/TrendingUtils";
import DropdownDisplayBelow from "../../../DropdownDisplayBelow/DropdownDisplayBelow";

interface Props {
  isOpen: boolean;
  toggleOpenFilter: () => void;
}

const CompanyFilter: FC<Props> = ({ isOpen, toggleOpenFilter }) => {
  const { searchDispatcher, searchState, searchInputOpts } = useSearchContext();

  const { data } = useArticlesTrendingQuery(
    {
      inputOpts: searchInputOpts,
    },
    {
      refetchOnWindowFocus: false,
      initialData: trendingPlaceholderData,
      keepPreviousData: true,
    }
  );

  const moversComp = data?.articlesTrending?.movers?.company
    ? (data?.articlesTrending?.movers?.company.map((c) => c?.label) as string[])
    : [];
  const mentionsComp = data?.articlesTrending?.mentions?.company
    ? (data?.articlesTrending?.mentions?.company.map(
        (c) => c?.label
      ) as string[])
    : [];

  return (
    <DropdownDisplayBelow
      selectableItems={[...moversComp, ...mentionsComp].filter((s) => s !== "")}
      isDropdownOpen={isOpen}
      toggleDropdown={toggleOpenFilter}
      dropdownDisplay={"Company"}
      selectionDisplay={searchState?.org}
      select={(company) =>
        searchDispatcher({
          type: SearchContextActions.SET_ORG,
          org: company,
        })
      }
      unselect={() =>
        searchDispatcher({
          type: SearchContextActions.SET_ORG,
          org: null,
        })
      }
      makeSearchable={false}
    />
  );
};

export default CompanyFilter;
