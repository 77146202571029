import { SubscriptionClient } from "graphql-subscriptions-client";
import { createContext, useContext, useEffect, useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { Type, useUserRewardLogsMutation, useUserRewardsMutation } from "../../../codeGenFE";
import { GQL_SUBSCRIPTION_ENDPOINT } from "../../../constants";
import { useUserContext, useRewardsContext } from "../allContexts";

// @ts-ignore
export const SubscriptionContext = createContext();

export function SubscriptionProvider(props: any) {
  let rewardType: any = null
  const qClient = useQueryClient();
  const { user } = useUserContext();
  const [type, setType] = useState<any>(null)
  const [metaData, setMetaData] = useState<any>(null)
  const [fileData, setFileData] = useState(null)
  const {refetch} = useRewardsContext()

  // In this function system adding reward points on the basis of URL upload action 
  // and on success system refetching updated reward point details  by 
  // userRewardsDetails()
  const {mutate: rewardsMutate} = useUserRewardsMutation({
    onSuccess: () => {
      refetch()
    },
  })

  // In this function system logging reward actions and on 
  // success we are mutating userRewardsMutation()
  const {mutate: rewardsLogMutate} = useUserRewardLogsMutation({
    onSuccess: (data) => {
      const res: any = data.userRewardLogs
      rewardsMutate({
        options: {
          type: res,
          meta: fileData
        }
      })
    }
  })

  useEffect(() => {
    if (user && user._id) {
      const query = `
        subscription noteShared {
            noteShared(userId: "${user._id}") {
              noteId
            }
          }
      `;

      const client = new SubscriptionClient(GQL_SUBSCRIPTION_ENDPOINT, {
        reconnect: true,
        lazy: true, // only connect when there is a query
        connectionCallback: (error) => {
          error && console.error(error);
          return;
        },
      });

      const subscription = client.request({ query }).subscribe({
        next({ data }: any) {
          // console.log("subscription data :>> ", data);
          if (data) {
            qClient.invalidateQueries("getUserNotes");
            toast(`You have a new note!`);
          }
          return;
        },
      });

      return () => subscription.unsubscribe();
    }
    return;
  }, [user]);

  useEffect(() => {
    if (user && user._id) {
      const query = `
        subscription articlesChangeSubscription {
          articlesChangeSubscription(userId: "${user._id}") {
            articleId
            status
            database
            taskId
            type
            keywords
          }
        }
      `;

      const client = new SubscriptionClient(GQL_SUBSCRIPTION_ENDPOINT, {
        reconnect: true,
        lazy: true, // only connect when there is a query
        connectionCallback: (error) => {
          error && console.error(error);
          return;
        },
      });

      const subscription = client.request({ query }).subscribe({
        next({ data }: any) {
          let rewardType = null
          console.log(data)
          if (data?.articlesChangeSubscription?.status === "error")
            toast.error(
              `Upload failed at this time.  Please try again or contact us if it continues to fail.`
            );
          if (data?.articlesChangeSubscription?.status === "complete") {
            setFileData(data?.articlesChangeSubscription)
            if(data?.articlesChangeSubscription.type) {
              if(data?.articlesChangeSubscription.type === 'file') {
                rewardType = Type.FileUpload
                // setType(Type.FileUpload)
              } else if(data?.articlesChangeSubscription.type === 'text') {
                rewardType = Type.TextUpload
                // setType(Type.TextUpload)
              } else {
                rewardType = Type.UrlUpload
                // setType(Type.UrlUpload)
              }
            }
            // Refresh dashboard
            const keys = [
              "articlesRateOfChange",
              "articlesCategoryBreakdown",
              "articlesTopFiveRisksAndOpps",
              "articlesTrending",
              "articlesGeography",
              "articlesCities",
              "articlesMainDrivers",
              "articlesRisksAndOpps",
              "articlesFilteredVsTotalStat",
              // refresh ask me data points
              "driversByTopic",
            ];
            qClient.invalidateQueries({
              predicate: (query: any) => {
                return !!keys.includes(query.queryKey[0]);
              },
            });
            if((localStorage.getItem('urlUploadedFrom') && localStorage.getItem('urlUploadedFrom') === '/my-memory/askme') ) return
            toast(`You have new article(s)`);
            // if(rewardType)
            //   rewardsLogMutate({
            //     options: {
            //       type: rewardType
            //     }
            //   })

            // Refresh myMemory
            const fetch = async () => {
              try {
                await qClient.fetchInfiniteQuery("myMemoryInfinite");
              } catch (err) {}
            };
            fetch();
          }
        },
      });

      return () => subscription.unsubscribe();
    }
    return;
  }, [user]);

  return (
    <SubscriptionContext.Provider value={
      {fileData}
    }>
      {props.children}
    </SubscriptionContext.Provider>
  );
}

export const useSubscriptionContext = () => {
  return useContext(SubscriptionContext);
};
