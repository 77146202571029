import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import StepsSwiper from "./StepsSwiper";
import { truncate } from "fs";
import Individual from "../../../assets/img/Individual.png";
import Student from "../../../assets/img/Student.png";
import Organisation from "../../../assets/img/Organisation.png";

export default function OnboardingModal() {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  const User = [
    {
      userName: "Student",
      image: Student,
    },
    {
      userName: "Individual",
      image: Individual,
    },
    {
      userName: "Organisation",
      image: Organisation,
    },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        style={{ zIndex: 9999 }}
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto backdrop-blur-md">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                style={{ background: "transparent !important" }}
                className="bg-transparent relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:w-full sm:max-w-lg "
              >
                <StepsSwiper users={User} open={setOpen} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
