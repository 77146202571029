import React, { lazy, Suspense } from 'react'
import LoadingCube from '../../Components/LoadingCube/LoadingCube'
import MainNavigation from '../../Components/MainNavigation/MainNavigation'

// Components
import TopMenu from '../../Components/TopMenu/TopMenu'
import styles from './ControlPanel.module.scss'
import TabComponent from '../../Components/Tabs/TabComponent'

function MahindraPanel () {
  return (
    <>
      <div className={styles.dashboard}>
        {/* Fixed Menu w/ artimis (quick files), search and quick notes */}
        <div className={styles.topMenu}>
          <TopMenu showSearch={true} />
        </div>
        {/* Left Menu */}
        <div className={styles.leftMenu}>
          <MainNavigation />
          {/* <Filters /> */}
        </div>
        <div>
          <Suspense
            fallback={
              <LoadingCube isFullScreen={true} height='200px' width='200px' />
            }
          >
            <div className={styles.scrollComponent}>
              <TabComponent />
            </div>
          </Suspense>
        </div>
      </div>
    </>
  )
}

export default MahindraPanel
