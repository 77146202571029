import React, { useState } from "react";
import { Link } from "react-router-dom";

import styles from "./NBInfoModal.module.scss";

// COMPONENTS
import CloseModalBtn from "../../../CloseModalBtn/CloseModalBtn";
import AddToNotebookBtn from "../../../AddToNotebookBtn/AddToNotebookBtn";

const createOrFancyData = {
  create: [
    <span>Create a note(s) to work with...</span>,
    <span>
      Edit a note by clicking the note title or select a note by clicking
      activate. You may select multiple notes to add content to.
    </span>,
    <span className={styles.hasBtn}>
      Search for dynamic content notated by the{" "}
      <AddToNotebookBtn isDisabled={true} />
    </span>,
  ],
  fancy: [
    <span>Click Activate on any note(s) you want to add content to</span>,
    <span className={styles.hasBtn}>
      Look for <AddToNotebookBtn isDisabled={true} /> and click to send content
      to your 'Active' note(s)
    </span>,
    <span>
      View your changes in the note by clicking the note(s) title in the list
      view.
    </span>,
  ],
};

const NBInfoModal = ({ close }) => {
  const [noteCRUDInfo, setNoteCRUDInfo] = useState("create");

  const whichContent = (createOrFancy) => {
    switch (createOrFancy) {
      case "create":
        setNoteCRUDInfo("create");
        break;
      case "fancy":
        setNoteCRUDInfo("fancy");
        break;
    }
  };

  return (
    <div className={styles.nbInfo__wrapper}>
      <div className={styles.xIcon__wrapper}>
        <CloseModalBtn theme="light" close={() => close()} />
      </div>
      <div className={styles.backdrop} onClick={() => close()} />
      <h2 className={styles.nbInfo__header}>Using your Notebook</h2>
      <section className={styles.nbInfo_section}>
        <h3 className={styles.sub_header}>Creating/Editing Notes</h3>
        {createOrFancyData.create.map((data, idx) => {
          return (
            <p className={styles.nbInfo__p} key={idx}>
              <span className={styles.bold}>Step {idx + 1}: </span>
              {data}
            </p>
          );
        })}
      </section>
      <section className={styles.nbInfo_section}>
        <h3 className={styles.sub_header}>Add Content from Pluaris</h3>
        {createOrFancyData.fancy.map((data, idx) => {
          return (
            <p className={styles.nbInfo__p} key={idx}>
              <span className={styles.bold}>Step {idx + 1}: </span>
              {data}
            </p>
          );
        })}
      </section>
      <section>
        <h3 className={styles.sub_header}>Additional Information</h3>
        <p className={styles.nbInfo__p}>
          Post your note to LinkedIn & Twitter using respective icons at top of note editor.
        </p>
        <p className={styles.nbInfo__p}>
          The notes that you create here will be available to you inside of your{" "}
          <Link to="/my-memory/reader" className={styles.nbInfo__link}>
            My Memory
          </Link>{" "}
          page.
        </p>
        <p className={styles.nbInfo__p}>
          You will be able to filter for it by clicking Advanced Search and then
          in the Data Source dropdown selecting "private text".
        </p>
        <p className={styles.nbInfo__p}>
          Any changes to your notes will be automatically updated in real time.
        </p>
      </section>
    </div>
  );
};

export default NBInfoModal;
