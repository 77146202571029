import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useStateLabel } from "../../../../hooks/stateLabeler";
import styles from "./TopicNewsPreview.module.scss";

import SingleNewsPreview from "./SingleNewsPreview/SingleNewsPreview";
import LoadingCube from "../../../../Components/LoadingCube/LoadingCube";

import { useRewardsContext, useUserContext } from "../../../../context/allContexts";
import { useUserProfileContext } from "../../../../context/allContexts/UserProfileContext";

import CloseModalBtn, {
  CloseModalBtnTheme,
} from "../../../../Components/CloseModalBtn/CloseModalBtn";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

import { customErrorHandler } from "../../../../../utils/customErrorHandler";
import { useFetchTopicsFromPython } from "../../../../../rq-hooks/pythonRestApi/useFetchTopics";
import { usePersistTopicsCheck } from "../../../../../rq-hooks/pythonRestApi/usePersistTopicsCheck";
import ComponentMessagingOverlay from "../../../../Components/ComponentMessagingOverlay/ComponentMessagingOverlay";
import { Type, useUserRewardLogsMutation, useUserRewardsMutation } from "../../../../../codeGenFE";

interface Props {
  close: () => void;
  showPreviewModal: boolean;
}

const TopicNewsPreview: FC<Props> = ({ close }) => {
  const {
    // @ts-ignore
    userProfileState,
  } = useUserProfileContext();

  const { topicToMonitor, excludedTopicsArray, relationship, countryArray } =
    userProfileState;

  const { user } = useUserContext();
  const {refetch} = useRewardsContext()
  const [selectedArticles, setSelectedArticles] = useStateLabel(
    [],
    "selectedArticles"
  );

  // In this function system is adding reward points on the basis of URL upload action 
  // and on success we are refetching updated reward point details  by
  // userRewardsDetails()
  const {mutate: rewardsMutate} = useUserRewardsMutation({
    onSuccess: () => {
      refetch()
    },
  })

  //In this function we are logging reward actions and on success we are mutating 
  // userRewardsMutation()
  const {mutate: rewardsLogMutate} = useUserRewardLogsMutation({
    onSuccess: () => {
      rewardsMutate({
        options: {
          type: Type.ArticleUpload,
          meta: {
            selectedArticles:selectedArticles
          }
        }
      })
    }
  })

  const {
    data,
    isFetching: loading,
    error,
  } = useFetchTopicsFromPython(
    {
      userId: user._id,
      comp: user.company,
      topic: topicToMonitor,
      topicType: relationship.relType,
      subscriptionReason: relationship.relText,
      excludedTopicKeywords: excludedTopicsArray,
      marketCode:
        countryArray && countryArray.length > 0 ? countryArray : ["en-US"],
    },
    {
      enabled: !!topicToMonitor,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const topicPreviews = data;

  // In this function our system send selected articles id to usePersistTopicsCheck() 
  // hook that is calling our python api and on success system is mutating 
  // userRewardLogsMutation()
  const {
    mutate: saveArticlesToDB,
    isLoading: saving,
    error: saveError,
    reset,
    isSuccess,
  } = usePersistTopicsCheck({
    onSuccess: () => {
      // rewardsLogMutate({
      //   options: {
      //     type: Type.ArticleUpload
      //   }
      // })
      setSelectedArticles([]);
    },
    onError: (error: any) => {
      console.log("error from persistTopicsCheck : ", error);
    },
  });

  if (isSuccess) {
    return (
      <div
        className={`${styles.topicNewsPreviewWrapper} ${styles.topicNewsPreview__message}`}>
        <div className={styles.message__div}>
          <div className={styles.message__heading}>
            <AiOutlineCheck className={`${styles.iconCheck}`} />
            <h3 className={styles.message__h3}>Success!</h3>
          </div>
          <p className={styles.message__p}>
            The article(s) are now viewable in your My Memory page!
          </p>
          <Link
            to="/my-memory/reader"
            onClick={() => close()}
            className={styles.topicNewsPreview__link}>
            Go to Reader
          </Link>
        </div>
        <button
          className={styles.message__button}
          onClick={() => {
            reset();
            close();
          }}>
          OKAY
        </button>
      </div>
    );
  } else if (error || saveError) {
    return (
      <div
        className={`${styles.topicNewsPreviewWrapper} ${styles.topicNewsPreview__message}`}>
        <div className={styles.message__div}>
          <div className={`${styles.message__heading} ${styles.err__heading}`}>
            <AiOutlineClose className={`${styles.iconX}`} onClick={close} />
            <h3 className={styles.message__h3}>
              {customErrorHandler(error || saveError)}
            </h3>
          </div>
        </div>
        <button className={styles.message__button} onClick={close}>
          OKAY
        </button>
      </div>
    );
  } else {
    return (
      <div className={styles.topicNewsPreviewWrapper}>
        <div className={styles.scrollPadding}>
          <div className={styles.closeBtn}>
            <CloseModalBtn theme={CloseModalBtnTheme.LIGHT} close={close} />
          </div>
          <h2 className={styles.topicNewsPreview__heading}>News Preview</h2>
          <div className={styles.topicNewsItemsWrapper}>
            {topicPreviews &&
              topicPreviews.length > 0 &&
              !loading &&
              !saving &&
              topicPreviews.map((art: any) => {
                return (
                  <SingleNewsPreview
                    selectedArticles={selectedArticles}
                    setSelectedArticles={setSelectedArticles}
                    article={art}
                    key={art._id}
                    id={art._id}
                  />
                );
              })}
            {topicPreviews?.length === 0 && !loading && !saving && (
              <ComponentMessagingOverlay
                showX={true}
                message="Couldn't find similar topics at this time."
                close={close}
              />
            )}
            {(loading || saving) && (
              <div className={styles.loadingCubeWrapper}>
                <LoadingCube
                  hasBackground={false}
                  height="138px"
                  width="138px"
                />
              </div>
            )}
            {topicPreviews && topicPreviews.length > 0 && !loading && !saving && (
              <button
                className={`${styles.addBtn} ${
                  selectedArticles.length === 0 ? styles.addBtn__disable : false
                }`}
                disabled={selectedArticles.length === 0 ? true : false}
                onClick={() =>
                  saveArticlesToDB({
                    comp: user.company,
                    userId: user._id,
                    sequenceIds: selectedArticles,
                  })
                }>
                Add to My Memory
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default TopicNewsPreview;
