import { useEffect, useState, FC } from "react";
import Fuse from "fuse.js";
import styles from "./DropdownDisplayBelow.module.scss";
import { MdArrowDropDown } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";

// This component is for having a dropdown with a static display on the dropdown itself (label is fixed to name of component basically) and the selected items are located below the dropdown itself in a column.  Weird, I know, but it's what was requested.  Dropdown display is the name of the component and selection display is the item that has been selected from the dropdown.

interface Props {
  selectableItems: string[] | [];
  dropdownDisplay: string;
  deleteFunc?: null | (() => void);
  unselect: (arg: string) => void;
  select: (arg: string) => void;
  makeSearchable?: boolean;
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
  selectionDisplay: string | null | undefined;
  capitalizeDisplays?: boolean;
}

const DropdownDisplayBelow: FC<Props> = ({
  selectableItems,
  dropdownDisplay, // also name of dropdown and what will be returned if dropdown is selected.
  unselect,
  select,
  makeSearchable = false,
  isDropdownOpen,
  toggleDropdown,
  selectionDisplay,
  capitalizeDisplays = false,
}) => {
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [selectionDisplay, setSelectionDisplay] = useState<null | string>(null);
  const [searchInput, setSearchInput] = useState("");
  const [searchableItems, setSearchableItems] = useState<[] | string[]>(
    selectableItems
  );
  const sort = (array: string[]) => {
    return array.sort((a: string, b: string) => {
      if(a.toLowerCase() < b.toLowerCase()) { return -1; }
      if(a.toLowerCase() > b.toLowerCase()) { return 1; }
      return 0;
    })
  }
  useEffect(() => {
    if (selectableItems) {
      if (searchInput) {
        let result = []
        const options = {
          shouldSort: true,
          threshold: 0.2,
          location: 0,
          distance: 1,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: [],
        };
        let fuse = new Fuse(selectableItems, options);
        result = fuse
          .search(searchInput)
          .map((i) => i.item)
          .sort();
        const sortedResult = sort(result)
        if(result && result.length > 0){
          setSearchableItems(sortedResult);
        } else {
          const options = {
            shouldSort: true,
            threshold: 0.2,
            location: 0,
            distance: 500,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [],
          };
          let fuse = new Fuse(selectableItems, options);
          result = fuse
            .search(searchInput)
            .map((i) => i.item)
            .sort();
          const sortedResult = sort(result)
          setSearchableItems(sortedResult);
        }
      } else {
        const sortedResult = sort(selectableItems)
        setSearchableItems(sortedResult);
      }
    }
  }, [searchInput, selectableItems]);

  useEffect(() => {
    if (isDropdownOpen && makeSearchable) {
      // @ts-ignore
      document.querySelector(`.${styles.searchInput}`).focus();
    }
  }, [isDropdownOpen]);

  return (
    <div className={styles.container}>
      <div className={styles.dropdownWrapper}>
        {/* Dropdown display */}
        <button
          onClick={toggleDropdown}
          className={`${styles.dropdownDisplay} ${
            selectionDisplay ? styles.activeColor : ""
          }`}
          data-isopen={`${isDropdownOpen}`}>
          {dropdownDisplay}{" "}
          <MdArrowDropDown
            className={`${isDropdownOpen ? styles.arrowReverse : styles.arrow}`}
          />
        </button>
        {/* Dropdown list */}
        {isDropdownOpen && (
          <div className={styles.dropdown}>
            {/* Search */}
            {makeSearchable && (
              <input
                className={styles.searchInput}
                placeholder="Search..."
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            )}

            {/* Selectable Item Dropdown */}
            {searchableItems.map((item, index) => {
              return (
                <button
                  className={`${styles.dropdownItems} ${
                    capitalizeDisplays ? styles.capitalCase : ""
                  }`}
                  onClick={() => {
                    select(item);
                    toggleDropdown();
                  }}
                  key={item + index}>
                  {capitalizeDisplays ? item.toLowerCase() : item}
                </button>
              );
            })}
          </div>
        )}
      </div>
      {/* Selected item */}
      {selectionDisplay && (
        <p
          className={`${styles.selectedItem} ${
            capitalizeDisplays ? styles.capitalCase : ""
          }`}>
          <button
            className={styles.selectedItem__btn}
            onClick={() => {
              unselect(selectionDisplay);
            }}>
            <AiOutlineClose className={styles.icon} />
          </button>{" "}
          {capitalizeDisplays
            ? selectionDisplay.toLowerCase()
            : selectionDisplay}
        </p>
      )}
    </div>
  );
};

export default DropdownDisplayBelow;
