import Carousel from 'react-bootstrap/Carousel'
import RecentCard from './RecentCard'
import bg1 from '../../../../../assets/img/bg1.png'
import bg2 from '../../../../../assets/img/bg2.png'
// import required modules
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
// import 'swiper/swiper.min.css'
// import 'swiper/components/pagination/pagination.scss'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';


// Import Swiper styles
import 'swiper/react/swiper-react.js';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.scss';
// import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/scrollbar/scrollbar.scss';
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

import './boardSwiper.scss'
import bg3 from '../../../../../assets/img/bg3.png'

function RecentBoard ({announcements}) {

console.log(announcements)


  return (
    <div className='my-5'>
      <div className='text-center'>
        <h2 className='text-dark fw-bold fs-3 my-5'>
          Recent board announcements
        </h2>
      </div>
      <Swiper
        slidesPerView={3}
        navigation={true}
        modules={[ Navigation]}
        className='boardSwiper px-3'
      >
        {announcements?.announcements.map(item => (
          <SwiperSlide className='gap-1'>
            <RecentCard
              title={item.title}
              image={item.image}
              para={item.content}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default RecentBoard