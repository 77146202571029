import { ChartsColorConfig } from './FinancialTab/FinancialTab.config'

export function convertAPIResponseToBarChartData(apiResponse) {
  const data = apiResponse.data
  const years = new Set()
  data.forEach((datum) => {
    datum.set.forEach((item) => {
      years.add(item.year)
    })
  })

  const new2 = Array.from(years)

  const chartData = {
    labels: [...new2],
    datasets: [],
    borderRadius: [0, 0, 30, 30],
    borderWidth: 1,
  }

  // Extract the data values from the API response and add them to the chartData object
  data.forEach((datum, i) => {
    const values = new Array(new2.length).fill(null) // Initialize an array of null values with the same length as the labels array
    datum.set.forEach((item) => {
      // Find the index of the year in the labels array and set the corresponding value in the values array
      const index = new2.indexOf(item.year)
      if (index !== -1) {
        values[index] = item.value
      }
    })

    const currentColor =
      ChartsColorConfig[i % ChartsColorConfig.length] ?? '#F98600'

    chartData.datasets.push({
      id: Date.now().toString(), // Generate a unique id for each dataset
      label: datum.title,
      data: values,
      backgroundColor: currentColor,
      borderColor: currentColor,
    })
  })

  return chartData
}

export const convertData = (data) => {
  const chartData = {
    labels: Object.keys(data),
    datasets: [],
  }

  Object.values(data).forEach((periodData) => {
    periodData.growth.forEach((datum, i) => {
      const companyIndex = chartData.datasets.findIndex(
        (dataset) => dataset.label === datum.company,
      )
      if (companyIndex === -1) {
        const currentColor =
          ChartsColorConfig[i % ChartsColorConfig.length] ?? '#F98600'
        chartData.datasets.push({
          label: datum.company,
          data: [datum.percentage],
          fill: false,
          backgroundColor: currentColor,
          borderColor: currentColor, // generate a random color for the line
        })
      } else {
        chartData.datasets[companyIndex].data.push(datum.percentage)
      }
    })
  })

  return chartData
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
