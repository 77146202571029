import React, { FC } from "react";

import styles from "./CloseModalBtn.module.scss";

import { MdClose } from "react-icons/md";

export enum CloseModalBtnTheme {
  LIGHT,
  DARK,
}

interface Props {
  close: () => void;
  theme: CloseModalBtnTheme;
}

const CloseModalBtn: FC<Props> = ({ close, theme }) => {
  return (
    <button
      className={`${styles.closeBtn} ${
        theme === CloseModalBtnTheme.LIGHT ? null : styles.darkTheme
      }`}
      onClick={() => close()}
    >
      <MdClose className={styles.closeIcon} />
    </button>
  );
};

export default CloseModalBtn;
