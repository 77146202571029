import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { fetcher } from '../utils/customFetcher';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ANY: any;
  DateTime: any;
  JSON: any;
};

export type Article = {
  __typename?: 'Article';
  _id: Scalars['String'];
  _source: _Source;
  keyword?: Maybe<Scalars['String']>;
  ner?: Maybe<Ner>;
  ner_norm?: Maybe<Ner>;
  origin: Scalars['String'];
  proImageLink: Scalars['String'];
  pubStatus: Scalars['String'];
  sentiment_score: Array<Maybe<Scalars['Float']>>;
  keywordList?: Maybe<Scalars['JSON']>;
  tags?: Maybe<Scalars['JSON']>;
  user_ro?: Maybe<Scalars['JSON']>;
  whichDB?: Maybe<WhichDb>;
  userMetaData: Array<Maybe<UserMetaData>>;
  sharedBy?: Maybe<Scalars['String']>;
  autoremove?: Maybe<Scalars['DateTime']>;
};

export type RequestDetails = {
  __typename?: 'RequestDetails';
  filename: Scalars['String'];
};

export type Tasks = {
  __typename?: 'Tasks';
  _id?: Maybe<Scalars['ID']>;
  company_id: Scalars['String'];
  status: Scalars['String'];
  request_type: Scalars['String'];
  request_details: RequestDetails;
  document_id: Scalars['String'];
};

export type ArticleChangeRes = {
  __typename?: 'ArticleChangeRes';
  articleId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  database?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['String']>;
};

export type ArticleMeta = {
  userList: any;
  __typename?: 'ArticleMeta';
  _id?: Maybe<Scalars['ID']>;
  proImageLink?: Maybe<Scalars['String']>;
  pubStatus?: Maybe<Scalars['String']>;
  _source?: Maybe<ArticleMetaSource>;
  userMetaData: Array<Maybe<UserMetaData>>;
  keywordList?: Maybe<Scalars['JSON']>;
  sharedBy?: Maybe<Scalars['String']>;
  whichDB?: Maybe<WhichDb>;
};

export type ArticleMetaInputs = {
  pv2Skip: Scalars['Int'];
  pfSkip: Scalars['Int'];
  searchStr?: Maybe<Scalars['String']>;
  relationshipCategory?: Maybe<Scalars['String']>;
  relationshipValue?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  articleSource?: Maybe<ArticleSource>;
  topic?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  otherLocation?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
};

export type TasksStatusInputs = {
  whichDB: WhichDb;
};

export type ArticleMetaSource = {
  __typename?: 'ArticleMetaSource';
  title?: Maybe<Scalars['String']>;
  harvestDate?: Maybe<Scalars['String']>;
  sortDate?: Maybe<Scalars['String']>;
  uploadDate?: Maybe<Scalars['String']>;
  orig_url?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  summary?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<ArticleMetaSourceName>;
};

export type ArticleMetaSourceName = {
  __typename?: 'ArticleMetaSourceName';
  name?: Maybe<Scalars['String']>;
};

export enum ArticleSource {
  Private = 'PRIVATE',
  News = 'NEWS',
  Shared = 'SHARED',
}

export type ArticlesFilteredVsTotalStatRes = {
  __typename?: 'ArticlesFilteredVsTotalStatRes';
  filteredTotal: Scalars['Int'];
  total: Scalars['Int'];
  totalPrivate: Scalars['Int'];
  totalShared: Scalars['Int'];
  totalFeeds: Scalars['Int'];
};

export type ArticlesInputs = {
  searchStr?: Maybe<Scalars['String']>;
  relationshipCategory?: Maybe<Scalars['String']>;
  relationshipValue?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  articleSource?: Maybe<ArticleSource>;
  topic?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  otherLocation?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
};

export type CategoryBreakdown = {
  __typename?: 'CategoryBreakdown';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type City = {
  __typename?: 'City';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type Content = {
  __typename?: 'Content';
  blocks: Array<Maybe<Scalars['JSON']>>;
  entityMap?: Maybe<Scalars['JSON']>;
};

export type Country = {
  __typename?: 'Country';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type DelArticleTagInput = {
  whichDB: WhichDb;
  tag: Scalars['String'];
  articleId: Scalars['String'];
};

export type EditTopicInput = {
  id: Scalars['String'];
  topic: Scalars['String'];
  relationshipText: Scalars['String'];
  relationshipType: Scalars['String'];
  excludedKeywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<Array<Maybe<UtCountryInput>>>;
};

export type Fetch25ArticlesRes = {
  __typename?: 'Fetch25ArticlesRes';
  pfSkip: Scalars['Int'];
  pv2Skip: Scalars['Int'];
  articles: Array<Maybe<ArticleMeta>>;
};

export type GeneralError = {
  __typename?: 'GeneralError';
  message: Scalars['String'];
};

export type GeoItems = {
  __typename?: 'GeoItems';
  ISO3: Scalars['String'];
  value: Scalars['Int'];
};

export type GeographyRes = {
  __typename?: 'GeographyRes';
  geography?: Maybe<Array<Maybe<GeoItems>>>;
  max?: Maybe<Scalars['Int']>;
};

export type InputError = {
  __typename?: 'InputError';
  source: Scalars['String'];
  message: Scalars['String'];
};

export enum IsRiskOrOpp {
  Risks = 'RISKS',
  Opportunities = 'OPPORTUNITIES',
  Informational = 'INFORMATIONAL',
}

export enum ListUsersBy {
  Id = 'ID',
  Email = 'EMAIL',
}

export type MainDriverItem = {
  __typename?: 'MainDriverItem';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type MetaData = {
  __typename?: 'MetaData';
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['Int']>;
  sharedBy?: Maybe<Scalars['String']>;
};

export type MultiArticleShareArticleInfo = {
  articleId: Scalars['String'];
  whichDB: WhichDb;
};

export type Mutation = {
  __typename?: 'Mutation';
  test?: Maybe<Scalars['String']>;
  nerCorrectionFeedback: Scalars['String'];
  addTopicToUserPreferences: Scalars['Boolean'];
  deleteTopicFromUserPreferences: Scalars['Boolean'];
  editTopicFromUserPreferences: Scalars['Boolean'];
  deleteSingleArticle: Scalars['Boolean'];
  shareSingleArticle: Scalars['Boolean'];
  userRewardLogs: Scalars['String'];
  userRewards: Scalars['Boolean'];
  userLinkedInShare: Scalars['Boolean'];
  userTwitterShare: Scalars['Boolean'];
  shareMultipleArticles: Scalars['Boolean'];
  notifyArticleChanges: Scalars['Boolean'];
  saveNewsArticle: Scalars['Boolean'];
  makeNewNote: Scalars['ID'];
  deleteNote: Scalars['Boolean'];
  updateNote: Note;
  shareNote: Scalars['Boolean'];
  addContentToNotes: Scalars['Boolean'];
  saveFilter: Scalars['Boolean'];
  deleteFilter: Scalars['Boolean'];
  updateFilter: SavedFilter;
  addTagsToArticle: Article;
  addTagsToUserProfile: Scalars['Boolean'];
  deleteTagFromArticle: Scalars['Boolean'];
  deleteTagFromUserProfile: Scalars['Boolean'];
  updateArticleTags: Scalars['Boolean'];
  updateTagFromUserProfile: Scalars['Boolean'];
};

export type MutationNerCorrectionFeedbackArgs = {
  options: NerCorrectionInput;
};

export type MutationAddTopicToUserPreferencesArgs = {
  inputOpts: NewTopicInput;
};

export type MutationDeleteTopicFromUserPreferencesArgs = {
  topicId: Scalars['String'];
};

export type MutationEditTopicFromUserPreferencesArgs = {
  inputOpts: EditTopicInput;
};

export type MutationDeleteSingleArticleArgs = {
  options: SingleArticleDeleteInput;
};

export type MutationShareSingleArticleArgs = {
  options: ShareSingleArticleInput;
};

export type MutationShareMultipleArticlesArgs = {
  options: ShareMultipleArticlesInput;
};

export type MutationNotifyArticleChangesArgs = {
  options: NotifyArticleChangesInput;
};

export type MutationSaveNewsArticleArgs = {
  id: Scalars['String'];
  whichDb: WhichDb;
};

export type MutationDeleteNoteArgs = {
  noteId: Scalars['ID'];
};

export type MutationUpdateNoteArgs = {
  updates: UpdateArgs;
};

export type MutationShareNoteArgs = {
  users: Array<Scalars['ID']>;
  note: Scalars['JSON'];
};

export type MutationAddContentToNotesArgs = {
  content: Scalars['String'];
  noteIds: Array<Scalars['String']>;
};

export type MutationSaveFilterArgs = {
  saveFilterInput: SaveFilterInput;
};

export type MutationDeleteFilterArgs = {
  filterId: Scalars['String'];
};

export type MutationUpdateFilterArgs = {
  filterId: Scalars['String'];
  saveFilterInput: SaveFilterInput;
};

export type MutationAddTagsToArticleArgs = {
  tagArgs: TagArgs;
};

export type MutationAddTagsToUserProfileArgs = {
  userTags: UserTags;
};

export type MutationDeleteTagFromArticleArgs = {
  delOptions: DelArticleTagInput;
};

export type MutationDeleteTagFromUserProfileArgs = {
  tag: Scalars['String'];
};

export type MutationUpdateArticleTagsArgs = {
  updateArgs: UpdateArticleTagsInput;
};

export type MutationUpdateTagFromUserProfileArgs = {
  newTag: Scalars['String'];
  oldTag: Scalars['String'];
};

export type NerCorrectionInput = {
  category: UserSuggestedCategory;
  entity: Scalars['String'];
  reason: Scalars['String'];
  otherReason?: Maybe<Scalars['String']>;
  userSuggestedCategory?: Maybe<UserSuggestedCategory>;
};

export type Ner = {
  __typename?: 'Ner';
  CARDINAL?: Maybe<Array<Maybe<Scalars['String']>>>;
  ORDINAL?: Maybe<Array<Maybe<Scalars['String']>>>;
  DATE?: Maybe<Array<Maybe<Scalars['String']>>>;
  FAC?: Maybe<Array<Maybe<Scalars['String']>>>;
  NORP?: Maybe<Array<Maybe<Scalars['String']>>>;
  QUANITTY?: Maybe<Array<Maybe<Scalars['String']>>>;
  LOC?: Maybe<Array<Maybe<Scalars['String']>>>;
  ORG?: Maybe<Array<Maybe<Scalars['String']>>>;
  GPE?: Maybe<Array<Maybe<Scalars['String']>>>;
  PERSON?: Maybe<Array<Maybe<Scalars['String']>>>;
  PRODUCT?: Maybe<Array<Maybe<Scalars['String']>>>;
  PERCENT?: Maybe<Array<Maybe<Scalars['String']>>>;
  MONEY?: Maybe<Array<Maybe<Scalars['String']>>>;
  WORK_OF_ART?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NewTopicInput = {
  topic: Scalars['String'];
  relationshipText: Scalars['String'];
  relationshipType: Scalars['String'];
  excludedKeywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<Array<Maybe<UtCountryInput>>>;
  prePopulateData?: Maybe<Scalars['Boolean']>;
};

export type Note = {
  __typename?: 'Note';
  _id: Scalars['ID'];
  _source?: Maybe<Notebook_Source>;
  richText: RichText;
  owner: Scalars['ID'];
};

export type NoteMeta = {
  __typename?: 'NoteMeta';
  _id: Scalars['ID'];
  title: Scalars['String'];
  date?: Maybe<Scalars['Int']>;
  sharedBy?: Maybe<Scalars['String']>;
};

export type NoteShareSubRes = {
  __typename?: 'NoteShareSubRes';
  noteId: Scalars['ID'];
  recipients: Array<Scalars['String']>;
};

export type Notebook_Source = {
  __typename?: 'Notebook_source';
  content?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type NotifyArticleChangesInput = {
  taskId: Scalars['String'];
  status: Scalars['String'];
  userId: Scalars['String'];
  companyId: Scalars['String'];
  documentId?: Maybe<Scalars['String']>;
  database?: Maybe<Scalars['String']>;
  shareAll?: Maybe<Scalars['Boolean']>;
  shareTo: Array<Maybe<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  me: User;
  userLookupTable: Scalars['JSON'];
  userList: Array<Maybe<Users>>;
  fetchUserTopics: Array<Maybe<Topic>>;
  article: Article;
  fetch25Articles: Fetch25ArticlesRes;
  articlesFilteredVsTotalStat: ArticlesFilteredVsTotalStatRes;
  articlesRateOfChange: RateOfChange;
  articlesTrending: TrendingData;
  articlesCategoryBreakdown: Array<Maybe<CategoryBreakdown>>;
  articlesMainDrivers: Array<Maybe<MainDriverItem>>;
  articlesGeography: GeographyRes;
  articlesCities: Array<Maybe<City>>;
  articlesTopFiveRisksAndOpps: RisksAndOppsRes;
  articlesRisksAndOpps: RisksAndOppsRes;
  fetchTopFiveNewsPreview: Array<Maybe<Article>>;
  driversByTopic: Array<Maybe<Scalars['String']>>;
  getUserNotes: Array<Maybe<NoteMeta>>;
  getSingleNote: Note;
  fetchUserRelationships: Array<Maybe<Relationship>>;
  fetchUserTopicsForSearch: Array<Maybe<Scalars['String']>>;
  fetchUserCategories: Array<Maybe<Scalars['String']>>;
  fetchUserDrivers: Array<Maybe<Scalars['String']>>;
  getUserSavedFilters: Array<Maybe<SavedFilter>>;
  userTags: Array<Maybe<Scalars['String']>>;
};

export type QueryUserLookupTableArgs = {
  listBy: ListUsersBy;
};

export type QueryArticleArgs = {
  id: Scalars['ID'];
  whichDB: WhichDb;
};

export type QueryFetch25ArticlesArgs = {
  inputOpts: ArticleMetaInputs;
};

export type QueryArticlesFilteredVsTotalStatArgs = {
  inputOpts: ArticlesInputs;
};

export type QueryArticlesRateOfChangeArgs = {
  inputOpts?: Maybe<ArticlesInputs>;
};

export type QueryArticlesTrendingArgs = {
  inputOpts?: Maybe<ArticlesInputs>;
};

export type QueryArticlesCategoryBreakdownArgs = {
  inputOpts?: Maybe<ArticlesInputs>;
};

export type QueryArticlesMainDriversArgs = {
  inputOpts?: Maybe<ArticlesInputs>;
};

export type QueryArticlesGeographyArgs = {
  inputOpts?: Maybe<ArticlesInputs>;
};

export type QueryArticlesCitiesArgs = {
  inputOpts?: Maybe<ArticlesInputs>;
};

export type QueryArticlesTopFiveRisksAndOppsArgs = {
  inputOpts: TopFiveRisksAndOppsInputs;
};

export type QueryArticlesRisksAndOppsArgs = {
  inputOpts: RisksAndOppsInputs;
};

export type QueryFetchTopFiveNewsPreviewArgs = {
  inputOpts: TopFiveNewsPreviewInput;
};

export type QueryDriversByTopicArgs = {
  topic: Scalars['String'];
};

export type QueryGetUserNotesArgs = {
  searchStr?: Maybe<Scalars['String']>;
};

export type QueryGetSingleNoteArgs = {
  noteId: Scalars['String'];
};

export type RateOfChange = {
  __typename?: 'RateOfChange';
  newsCount: Scalars['Int'];
  newsROC: Scalars['String'];
  risks: Scalars['Int'];
  risksROC: Scalars['String'];
  opportunities: Scalars['Int'];
  opportunitiesROC: Scalars['String'];
  orgCount: Scalars['Int'];
  orgROC: Scalars['String'];
  privateCount: Scalars['Int'];
  privateROC: Scalars['String'];
};

export type Relationship = {
  __typename?: 'Relationship';
  category: Scalars['String'];
  relationships: Array<Scalars['String']>;
};

export type RichText = {
  __typename?: 'RichText';
  content: Content;
  metaData: MetaData;
};

export type RisksAndOpps = {
  __typename?: 'RisksAndOpps';
  _id: Scalars['ID'];
  title: Scalars['String'];
  confidence: Scalars['Float'];
  rioScore: Scalars['Int'];
  sortDate: Scalars['Int'];
  shortDesc?: Maybe<Scalars['String']>;
  whichDB?: Maybe<WhichDb>;
};

export type RisksAndOppsInputs = {
  isRiskOrOpp: IsRiskOrOpp;
  pv2Skip: Scalars['Int'];
  pfSkip: Scalars['Int'];
  searchStr?: Maybe<Scalars['String']>;
  relationshipCategory?: Maybe<Scalars['String']>;
  relationshipValue?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  articleSource?: Maybe<ArticleSource>;
  topic?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  otherLocation?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
};

export type RisksAndOppsRes = {
  __typename?: 'RisksAndOppsRes';
  risksAndOpps?: Maybe<Array<Maybe<RisksAndOpps>>>;
  pv2Skip?: Maybe<Scalars['Int']>;
  pfSkip?: Maybe<Scalars['Int']>;
};

export type SaveFilterInput = {
  name: Scalars['String'];
  topics?: Maybe<Scalars['String']>;
  relationshipType?: Maybe<Scalars['String']>;
  relationshipText?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  otherLocation?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
};

export type SavedFilter = {
  __typename?: 'SavedFilter';
  _id: Scalars['String'];
  name: Scalars['String'];
  topics?: Maybe<Scalars['String']>;
  relationshipType?: Maybe<Scalars['String']>;
  relationshipText?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  otherLocation?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
};

export type ShareMultipleArticlesInput = {
  articles: Array<MultiArticleShareArticleInfo>;
  recipients: Array<Scalars['String']>;
  shareAll: Scalars['Boolean'];
  userFullName: Scalars['String'];
};

export type ShareSingleArticleInput = {
  articleId: Scalars['String'];
  whichDB: WhichDb;
  recipients: Array<Scalars['String']>;
  shareAll: Scalars['Boolean'];
  userFullName: Scalars['String'];
};

export type UserRewardLogsTypeInput = {
  type: Type;
};

export type UserRewardsTypeInput = {
  type: Type | String;
  meta: any;
};

export type UserLinkedInShare = {
  data: Scalars['String'];
  author: Scalars['String'];
  image: Scalars['String'];
  token: Scalars['String'];
};

export type UserTwitterShare = {
  text: Scalars['String'];
  secret: Scalars['ANY'];
  token: Scalars['ANY'];
};

export type SharedBy = {
  __typename?: 'SharedBy';
  userId: Scalars['String'];
  name: Scalars['String'];
};

export type SingleArticleDeleteInput = {
  articleId: Scalars['String'];
  whichDB: WhichDb;
};

export type Source = {
  __typename?: 'Source';
  name?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  articlesChangeSubscription?: Maybe<ArticleChangeRes>;
  noteShared: NoteShareSubRes;
};

export type SubscriptionArticlesChangeSubscriptionArgs = {
  userId: Scalars['String'];
};

export type SubscriptionNoteSharedArgs = {
  userId: Scalars['String'];
};

export type TagArgs = {
  tagsList: Array<Scalars['String']>;
  whichDB: WhichDb;
  articleId: Scalars['String'];
};

export type TopFiveNewsPreviewInput = {
  topic: Scalars['String'];
  excludedTopicKeywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  topicType: Scalars['String'];
  subscriptionReason: Scalars['String'];
  marketCode?: Maybe<Array<Scalars['String']>>;
};

export type TopFiveRisksAndOppsInputs = {
  isRiskOrOpp: IsRiskOrOpp;
  searchStr?: Maybe<Scalars['String']>;
  relationshipCategory?: Maybe<Scalars['String']>;
  relationshipValue?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  articleSource?: Maybe<ArticleSource>;
  topic?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  otherLocation?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
};

export type Topic = {
  __typename?: 'Topic';
  id: Scalars['ID'];
  topic: Scalars['String'];
  relationshipText: Scalars['String'];
  relationshipType: Scalars['String'];
  excludedKeywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<Array<Maybe<UtCountry>>>;
};

export type TrendingCategorySet = {
  __typename?: 'TrendingCategorySet';
  product: Array<Maybe<TrendingItem>>;
  person: Array<Maybe<TrendingItem>>;
  location: Array<Maybe<TrendingItem>>;
  company: Array<Maybe<TrendingItem>>;
};

export type TrendingData = {
  __typename?: 'TrendingData';
  movers: TrendingCategorySet;
  mentions: TrendingCategorySet;
};

export type TrendingItem = {
  __typename?: 'TrendingItem';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type UtCountry = {
  __typename?: 'UTCountry';
  countryName: Scalars['String'];
  marketCode: Scalars['String'];
};

export type UtCountryInput = {
  countryName: Scalars['String'];
  marketCode: Scalars['String'];
};

export type UpdateArgs = {
  noteId: Scalars['ID'];
  title: Scalars['String'];
  richText: Scalars['JSON'];
};

export type UpdateArticleTagsInput = {
  tags: Array<Scalars['String']>;
  articleId: Scalars['String'];
  whichDB: WhichDb;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  email: Scalars['String'];
  name: Scalars['String'];
  lastName: Scalars['String'];
  companyName: Scalars['String'];
  admin: Scalars['String'];
  company: Scalars['String'];
  date: Scalars['String'];
  walletAddress: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  nftData: Scalars['String'];
  nftErrorCounter: Scalars['Int'];
  loginLogsCount: Scalars['Int'];
  dashboardType: Scalars['String'];
  clientId?: Scalars['String'];
  clientSecret?: Scalars['String'];
};

export type RewardDetails = {
  __typename?: 'RewardDetails';
  _id: Scalars['ID'];
  userId: Scalars['String'];
  companyId: Scalars['String'];
  totalPoints?: Maybe<Scalars['Float']>;
  nearestPoint?: Maybe<Scalars['Float']>;
  pointsGotByNearest?: Maybe<Scalars['Float']>;
  totalToken?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['Int']>;
};

export type UserMetaData = {
  __typename?: 'UserMetaData';
  userId: Scalars['String'];
  permanent: Scalars['Boolean'];
  date: Scalars['Int'];
  sharedBy?: Maybe<Array<SharedBy>>;
};

export enum UserSuggestedCategory {
  Company = 'COMPANY',
  Product = 'PRODUCT',
  Location = 'LOCATION',
  Person = 'PERSON',
  None = 'NONE',
}

export type UserTags = {
  tags: Array<Maybe<Scalars['String']>>;
};

export type Users = {
  __typename?: 'Users';
  _id: Scalars['ID'];
  name: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export enum WhichDb {
  ProductionV2 = 'productionV2',
  ProductionFiles = 'productionFiles',
  PythonCacheDB = 'python_backend_cache',
}

export enum Type {
  ReVisited = 'REVISITED',
  ArticleShared = 'ARTICLE_SHARED',
  Notebook = 'NOTEBOOK',
  NotebookCreate = 'NOTEBOOK_CREATE',
  ArticleUpload = 'ARTICLE_UPLOAD',
  FileUpload = 'FILE_UPLOAD',
  UrlUpload = 'URL_UPLOAD',
  TextUpload = 'TEXT_UPLOAD',
  Linkedin = 'LINKEDIN',
  Twitter = 'TWITTER',
  AskMe = 'ASKME',
  LOGIN = 'LOGIN',
}

export type _Source = {
  __typename?: '_Source';
  PlanType?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  dedup_status: Scalars['String'];
  driver?: Maybe<Array<Maybe<Scalars['String']>>>;
  driver_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  harvestDate?: Maybe<Scalars['String']>;
  images?: Maybe<Scalars['String']>;
  orig_url?: Maybe<Scalars['String']>;
  sequenceId?: Maybe<Scalars['String']>;
  shortDesc?: Maybe<Scalars['String']>;
  sortDate?: Maybe<Scalars['Int']>;
  source?: Maybe<Source>;
  summary?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  uploadDate?: Maybe<Scalars['String']>;
  summary_ext?: Maybe<Array<Maybe<Scalars['String']>>>;
  notebook_note_id?: Maybe<Scalars['String']>;
};

export type ArticleCardFragFragment = { __typename?: 'Article' } & Pick<
  Article,
  'sentiment_score' | 'user_ro' | 'tags'
> & {
    _source: { __typename?: '_Source' } & Pick<
      _Source,
      | 'title'
      | 'content'
      | 'summary'
      | 'PlanType'
      | 'sequenceId'
      | 'driver'
      | 'orig_url'
      | 'harvestDate'
      | 'uploadDate'
    > & { source?: Maybe<{ __typename?: 'Source' } & Pick<Source, 'name'>> };
    ner_norm?: Maybe<
      { __typename?: 'Ner' } & Pick<
        Ner,
        | 'GPE'
        | 'ORG'
        | 'PERSON'
        | 'PRODUCT'
        | 'CARDINAL'
        | 'DATE'
        | 'NORP'
        | 'QUANITTY'
        | 'FAC'
      >
    >;
    userMetaData: Array<
      Maybe<
        { __typename?: 'UserMetaData' } & Pick<
          UserMetaData,
          'userId' | 'permanent' | 'date'
        > & {
            sharedBy?: Maybe<
              Array<
                { __typename?: 'SharedBy' } & Pick<SharedBy, 'userId' | 'name'>
              >
            >;
          }
      >
    >;
  };

export type TasksStatusFragFragment = { __typename?: 'TasksStatus' } & Pick<
  Tasks,
  'company_id' | 'status' | 'request_type' | '_id' | 'document_id'
> & {
    request_details: { __typename?: 'RequestDetails' } & Pick<
      RequestDetails,
      'filename'
    >;
  };

export type TasksStatusRes = { __typename?: 'TasksStatusRes' } & {
  tasks: Array<Maybe<{ __typename?: 'Tasks' } & TasksStatusFragFragment>>;
};

export type FullArticleFragment = { __typename?: 'Article' } & Pick<
  Article,
  | '_id'
  | 'keyword'
  | 'origin'
  | 'proImageLink'
  | 'pubStatus'
  | 'sentiment_score'
  | 'keywordList'
  | 'tags'
  | 'sharedBy'
  | 'user_ro'
  | 'autoremove'
> & {
    _source: { __typename?: '_Source' } & Pick<
      _Source,
      | 'PlanType'
      | 'content'
      | 'dedup_status'
      | 'driver'
      | 'driver_type'
      | 'harvestDate'
      | 'images'
      | 'orig_url'
      | 'sequenceId'
      | 'shortDesc'
      | 'sortDate'
      | 'summary'
      | 'title'
      | 'uploadDate'
      | 'summary_ext'
      | 'notebook_note_id'
    > & { source?: Maybe<{ __typename?: 'Source' } & Pick<Source, 'name'>> };
    ner?: Maybe<
      { __typename?: 'Ner' } & Pick<Ner, 'ORG' | 'GPE' | 'PERSON' | 'PRODUCT'>
    >;
    userMetaData: Array<
      Maybe<
        { __typename?: 'UserMetaData' } & Pick<
          UserMetaData,
          'userId' | 'permanent' | 'date'
        > & {
            sharedBy?: Maybe<
              Array<
                { __typename?: 'SharedBy' } & Pick<SharedBy, 'userId' | 'name'>
              >
            >;
          }
      >
    >;
  };

export type MeDataFragment = { __typename?: 'User' } & Pick<
  User,
  | '_id'
  | 'name'
  | 'email'
  | 'lastName'
  | 'companyName'
  | 'admin'
  | 'company'
  | 'walletAddress'
  | 'emailVerified'
  | 'nftData'
  | 'nftErrorCounter'
  | 'clientId'
  | 'clientSecret'
>;

export type RewardsDataFragment = { __typename?: 'RewardDetails' } & Pick<
  RewardDetails,
  | '_id'
  | 'userId'
  | 'companyId'
  | 'totalPoints'
  | 'totalToken'
  | 'nearestPoint'
  | 'pointsGotByNearest'
  | 'token'
>;

export type DeleteSingleArticleMutationVariables = Exact<{
  options: SingleArticleDeleteInput;
}>;

export type DeleteSingleArticleMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteSingleArticle'
>;

export type SaveNewsArticleMutationVariables = Exact<{
  saveNewsArticleId: Scalars['String'];
  whichDb: WhichDb;
}>;

export type SaveNewsArticleMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'saveNewsArticle'
>;

export type ShareMultipleArticlesMutationMutationVariables = Exact<{
  options: ShareMultipleArticlesInput;
}>;

export type ShareMultipleArticlesMutationMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'shareMultipleArticles'>;

export type ShareSingleArticleMutationVariables = Exact<{
  options: ShareSingleArticleInput;
}>;

export type UserRewardLogsMutationVariables = Exact<{
  options: UserRewardLogsTypeInput;
}>;

export type UserRewardsMutationVariables = Exact<{
  options: UserRewardsTypeInput;
}>;

export type UserLinkedInMutationVariables = Exact<{
  options: UserLinkedInShare;
}>;

export type UserTwitterMutationVariables = Exact<{
  options: UserTwitterShare;
}>;

export type ShareSingleArticleMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'shareSingleArticle'
>;

export type UserRewardLogsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'userRewardLogs'
>;

export type UserRewardsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'userRewards'
>;

export type UserLinkedInShareMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'userLinkedInShare'
>;

export type UserTwitterShareMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'userTwitterShare'
>;

export type NerCorrectionFeedbackMutationVariables = Exact<{
  feedbackOptions: NerCorrectionInput;
}>;

export type NerCorrectionFeedbackMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'nerCorrectionFeedback'
>;

export type AddContentToNotesMutationVariables = Exact<{
  noteIds: Array<Scalars['String']> | Scalars['String'];
  content: Scalars['String'];
}>;

export type AddContentToNotesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'addContentToNotes'
>;

export type DeleteNoteMutationVariables = Exact<{
  noteId: Scalars['ID'];
}>;

export type DeleteNoteMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteNote'
>;

export type MakeNewNoteMutationVariables = Exact<{ [key: string]: never }>;

export type MakeNewNoteMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'makeNewNote'
>;

export type ShareNoteMutationVariables = Exact<{
  users: Array<Scalars['ID']> | Scalars['ID'];
  note: Scalars['JSON'];
}>;

export type ShareNoteMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'shareNote'
>;

export type UpdateNoteMutationVariables = Exact<{
  updates: UpdateArgs;
}>;

export type UpdateNoteMutation = { __typename?: 'Mutation' } & {
  updateNote: { __typename?: 'Note' } & Pick<Note, '_id' | 'owner'> & {
      _source?: Maybe<
        { __typename?: 'Notebook_source' } & Pick<
          Notebook_Source,
          'content' | 'title'
        >
      >;
      richText: { __typename?: 'RichText' } & {
        content: { __typename?: 'Content' } & Pick<
          Content,
          'entityMap' | 'blocks'
        >;
        metaData: { __typename?: 'MetaData' } & Pick<
          MetaData,
          'created' | 'createdBy' | 'lastUpdated' | 'sharedBy'
        >;
      };
    };
};

export type DeleteFilterMutationVariables = Exact<{
  filterId: Scalars['String'];
}>;

export type DeleteFilterMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteFilter'
>;

export type SaveFilterMutationVariables = Exact<{
  saveFilterSaveFilterInput: SaveFilterInput;
}>;

export type SaveFilterMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'saveFilter'
>;

export type UpdateFilterMutationVariables = Exact<{
  updateSaveFilterId: Scalars['String'];
  updateSaveFilterInput: SaveFilterInput;
}>;

export type UpdateFilterMutation = { __typename?: 'Mutation' } & {
  updateFilter: { __typename?: 'SavedFilter' } & Pick<
    SavedFilter,
    | '_id'
    | 'name'
    | 'topics'
    | 'relationshipType'
    | 'relationshipText'
    | 'tag'
    | 'dataSource'
    | 'category'
    | 'driver'
    | 'outcome'
    | 'country'
  >;
};

export type AddTagsToArticleMutationVariables = Exact<{
  tagArgs: TagArgs;
}>;

export type AddTagsToArticleMutation = { __typename?: 'Mutation' } & {
  addTagsToArticle: { __typename?: 'Article' } & Pick<
    Article,
    '_id' | 'tags'
  > & {
      _source: { __typename?: '_Source' } & Pick<
        _Source,
        | 'PlanType'
        | 'content'
        | 'dedup_status'
        | 'driver'
        | 'driver_type'
        | 'harvestDate'
        | 'images'
        | 'orig_url'
        | 'sequenceId'
        | 'shortDesc'
        | 'sortDate'
        | 'summary'
        | 'title'
        | 'uploadDate'
        | 'summary_ext'
      > & { source?: Maybe<{ __typename?: 'Source' } & Pick<Source, 'name'>> };
    };
};

export type AddTagsToUserProfileMutationVariables = Exact<{
  userTags: UserTags;
}>;

export type AddTagsToUserProfileMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'addTagsToUserProfile'
>;

export type DeleteTagFromArticleMutationVariables = Exact<{
  delOptions: DelArticleTagInput;
}>;

export type DeleteTagFromArticleMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteTagFromArticle'
>;

export type DeleteTagFromUserProfileMutationVariables = Exact<{
  tag: Scalars['String'];
}>;

export type DeleteTagFromUserProfileMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'deleteTagFromUserProfile'>;

export type UpdateArticleTagsMutationVariables = Exact<{
  updateArgs: UpdateArticleTagsInput;
}>;

export type UpdateArticleTagsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateArticleTags'
>;

export type UpdateTagFromUserProfileMutationVariables = Exact<{
  newTag: Scalars['String'];
  oldTag: Scalars['String'];
}>;

export type UpdateTagFromUserProfileMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'updateTagFromUserProfile'>;

export type AddTopicToUserPreferencesMutationVariables = Exact<{
  inputOpts: NewTopicInput;
}>;

export type AddTopicToUserPreferencesMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'addTopicToUserPreferences'>;

export type DeleteTopicFromUserPreferencesMutationVariables = Exact<{
  topicId: Scalars['String'];
}>;

export type DeleteTopicFromUserPreferencesMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'deleteTopicFromUserPreferences'>;

export type EditTopicFromUserPreferencesMutationVariables = Exact<{
  inputOpts: EditTopicInput;
}>;

export type EditTopicFromUserPreferencesMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'editTopicFromUserPreferences'>;

export type ArticleQueryVariables = Exact<{
  articleId: Scalars['ID'];
  whichDB: WhichDb;
}>;

export type ArticleQuery = { __typename?: 'Query' } & {
  article: { __typename?: 'Article' } & FullArticleFragment;
};

export type ArticleCardQueryVariables = Exact<{
  articleId: Scalars['ID'];
  whichDB: WhichDb;
}>;

export type TasksStatusQueryVariables = Exact<{
  whichDB: WhichDb;
}>;

export type ArticleCardQuery = { __typename?: 'Query' } & {
  article: { __typename?: 'Article' } & ArticleCardFragFragment;
};

export type TasksStatusQuery = { __typename?: 'Query' } & {
  articlesUploadingStatus: { __typename?: 'TasksStatus' } & TasksStatusRes;
};

export type ArticlesCategoryBreakdownQueryVariables = Exact<{
  inputOpts?: Maybe<ArticlesInputs>;
}>;

export type ArticlesCategoryBreakdownQuery = { __typename?: 'Query' } & {
  articlesCategoryBreakdown: Array<
    Maybe<
      { __typename?: 'CategoryBreakdown' } & Pick<
        CategoryBreakdown,
        'label' | 'value'
      >
    >
  >;
};

export type ArticlesCitiesQueryVariables = Exact<{
  inputOpts?: Maybe<ArticlesInputs>;
}>;

export type ArticlesCitiesQuery = { __typename?: 'Query' } & {
  articlesCities: Array<
    Maybe<{ __typename?: 'City' } & Pick<City, 'label' | 'value'>>
  >;
};

export type ArticlesFilteredVsTotalStatQueryVariables = Exact<{
  inputOpts: ArticlesInputs;
}>;

export type ArticlesFilteredVsTotalStatQuery = { __typename?: 'Query' } & {
  articlesFilteredVsTotalStat: {
    __typename?: 'ArticlesFilteredVsTotalStatRes';
  } & Pick<
    ArticlesFilteredVsTotalStatRes,
    'filteredTotal' | 'total' | 'totalPrivate' | 'totalShared' | 'totalFeeds'
  >;
};

export type ArticlesGeographyQueryVariables = Exact<{
  inputOpts?: Maybe<ArticlesInputs>;
}>;

export type ArticlesGeographyQuery = { __typename?: 'Query' } & {
  articlesGeography: { __typename?: 'GeographyRes' } & Pick<
    GeographyRes,
    'max'
  > & {
      geography?: Maybe<
        Array<
          Maybe<{ __typename?: 'GeoItems' } & Pick<GeoItems, 'ISO3' | 'value'>>
        >
      >;
    };
};

export type ArticlesMainDriversQueryVariables = Exact<{
  inputOpts?: Maybe<ArticlesInputs>;
}>;

export type ArticlesMainDriversQuery = { __typename?: 'Query' } & {
  articlesMainDrivers: Array<
    Maybe<
      { __typename?: 'MainDriverItem' } & Pick<
        MainDriverItem,
        'label' | 'value'
      >
    >
  >;
};

export type ArticlesRateOfChangeQueryVariables = Exact<{
  inputOpts?: Maybe<ArticlesInputs>;
}>;

export type ArticlesRateOfChangeQuery = { __typename?: 'Query' } & {
  articlesRateOfChange: { __typename?: 'RateOfChange' } & Pick<
    RateOfChange,
    | 'newsCount'
    | 'newsROC'
    | 'risks'
    | 'risksROC'
    | 'opportunities'
    | 'opportunitiesROC'
    | 'orgCount'
    | 'orgROC'
    | 'privateCount'
    | 'privateROC'
  >;
};

export type ArticlesRisksAndOppsQueryVariables = Exact<{
  inputOpts: RisksAndOppsInputs;
}>;

export type ArticlesRisksAndOppsQuery = { __typename?: 'Query' } & {
  articlesRisksAndOpps: { __typename?: 'RisksAndOppsRes' } & Pick<
    RisksAndOppsRes,
    'pv2Skip' | 'pfSkip'
  > & {
      risksAndOpps?: Maybe<
        Array<
          Maybe<
            { __typename?: 'RisksAndOpps' } & Pick<
              RisksAndOpps,
              | '_id'
              | 'title'
              | 'confidence'
              | 'rioScore'
              | 'sortDate'
              | 'whichDB'
            >
          >
        >
      >;
    };
};

export type ArticlesTopFiveRisksAndOppsQueryVariables = Exact<{
  inputOpts: TopFiveRisksAndOppsInputs;
}>;

export type ArticlesTopFiveRisksAndOppsQuery = { __typename?: 'Query' } & {
  articlesTopFiveRisksAndOpps: { __typename?: 'RisksAndOppsRes' } & {
    risksAndOpps?: Maybe<
      Array<
        Maybe<
          { __typename?: 'RisksAndOpps' } & Pick<
            RisksAndOpps,
            '_id' | 'title' | 'shortDesc'
          >
        >
      >
    >;
  };
};

export type ArticlesTrendingQueryVariables = Exact<{
  inputOpts?: Maybe<ArticlesInputs>;
}>;

export type ArticlesTrendingQuery = { __typename?: 'Query' } & {
  articlesTrending: { __typename?: 'TrendingData' } & {
    movers: { __typename?: 'TrendingCategorySet' } & {
      product: Array<
        Maybe<
          { __typename?: 'TrendingItem' } & Pick<
            TrendingItem,
            'label' | 'value'
          >
        >
      >;
      person: Array<
        Maybe<
          { __typename?: 'TrendingItem' } & Pick<
            TrendingItem,
            'label' | 'value'
          >
        >
      >;
      location: Array<
        Maybe<
          { __typename?: 'TrendingItem' } & Pick<
            TrendingItem,
            'label' | 'value'
          >
        >
      >;
      company: Array<
        Maybe<
          { __typename?: 'TrendingItem' } & Pick<
            TrendingItem,
            'label' | 'value'
          >
        >
      >;
    };
    mentions: { __typename?: 'TrendingCategorySet' } & {
      product: Array<
        Maybe<
          { __typename?: 'TrendingItem' } & Pick<
            TrendingItem,
            'label' | 'value'
          >
        >
      >;
      person: Array<
        Maybe<
          { __typename?: 'TrendingItem' } & Pick<
            TrendingItem,
            'label' | 'value'
          >
        >
      >;
      location: Array<
        Maybe<
          { __typename?: 'TrendingItem' } & Pick<
            TrendingItem,
            'label' | 'value'
          >
        >
      >;
      company: Array<
        Maybe<
          { __typename?: 'TrendingItem' } & Pick<
            TrendingItem,
            'label' | 'value'
          >
        >
      >;
    };
  };
};

export type ContentModalQueryVariables = Exact<{
  articleId: Scalars['ID'];
  whichDB: WhichDb;
}>;

export type ContentModalQuery = { __typename?: 'Query' } & {
  article: { __typename?: 'Article' } & Pick<Article, '_id' | 'sharedBy'> & {
      _source: { __typename?: '_Source' } & Pick<
        _Source,
        'title' | 'content' | 'orig_url'
      >;
    };
};

export type DriversByTopicQueryVariables = Exact<{
  topic: Scalars['String'];
}>;

export type DriversByTopicQuery = { __typename?: 'Query' } & Pick<
  Query,
  'driversByTopic'
>;

export type GetSingleNoteQueryVariables = Exact<{
  noteId: Scalars['String'];
}>;

export type GetSingleNoteQuery = { __typename?: 'Query' } & {
  getSingleNote: { __typename?: 'Note' } & Pick<Note, '_id' | 'owner'> & {
      richText: { __typename?: 'RichText' } & {
        metaData: { __typename?: 'MetaData' } & Pick<
          MetaData,
          'created' | 'createdBy' | 'lastUpdated' | 'sharedBy'
        >;
        content: { __typename?: 'Content' } & Pick<
          Content,
          'entityMap' | 'blocks'
        >;
      };
      _source?: Maybe<
        { __typename?: 'Notebook_source' } & Pick<
          Notebook_Source,
          'title' | 'content'
        >
      >;
    };
};

export type GetUserSavedFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserSavedFiltersQuery = { __typename?: 'Query' } & {
  getUserSavedFilters: Array<
    Maybe<
      { __typename?: 'SavedFilter' } & Pick<
        SavedFilter,
        | '_id'
        | 'name'
        | 'topics'
        | 'relationshipType'
        | 'relationshipText'
        | 'tag'
        | 'dataSource'
        | 'category'
        | 'driver'
        | 'outcome'
        | 'country'
      >
    >
  >;
};

export type FetchTopFiveNewsPreviewQueryVariables = Exact<{
  inputOpts: TopFiveNewsPreviewInput;
}>;

export type FetchTopFiveNewsPreviewQuery = { __typename?: 'Query' } & {
  fetchTopFiveNewsPreview: Array<
    Maybe<
      { __typename?: 'Article' } & {
        _source: { __typename?: '_Source' } & Pick<
          _Source,
          'content' | 'orig_url' | 'sequenceId' | 'shortDesc' | 'title'
        >;
      }
    >
  >;
};

export type FetchUserTopicsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUserTopicsQuery = { __typename?: 'Query' } & {
  fetchUserTopics: Array<
    Maybe<
      { __typename?: 'Topic' } & Pick<
        Topic,
        | 'id'
        | 'topic'
        | 'relationshipText'
        | 'relationshipType'
        | 'excludedKeywords'
      > & {
          country?: Maybe<
            Array<
              Maybe<
                { __typename?: 'UTCountry' } & Pick<
                  UtCountry,
                  'countryName' | 'marketCode'
                >
              >
            >
          >;
        }
    >
  >;
};

export type FetchUserCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUserCategoriesQuery = { __typename?: 'Query' } & Pick<
  Query,
  'fetchUserCategories'
>;

export type FetchUserDriversQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUserDriversQuery = { __typename?: 'Query' } & Pick<
  Query,
  'fetchUserDrivers'
>;

export type FetchUserRelationshipsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchUserRelationshipsQuery = { __typename?: 'Query' } & {
  fetchUserRelationships: Array<
    Maybe<
      { __typename?: 'Relationship' } & Pick<
        Relationship,
        'category' | 'relationships'
      >
    >
  >;
};

export type FetchUserTopicsForSearchQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchUserTopicsForSearchQuery = { __typename?: 'Query' } & Pick<
  Query,
  'fetchUserTopicsForSearch'
>;

export type GetUserNotesQueryVariables = Exact<{
  searchStr?: Maybe<Scalars['String']>;
}>;

export type GetUserNotesQuery = { __typename?: 'Query' } & {
  getUserNotes: Array<
    Maybe<
      { __typename?: 'NoteMeta' } & Pick<
        NoteMeta,
        '_id' | 'title' | 'date' | 'sharedBy'
      >
    >
  >;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;
export type RewardsDataQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & MeDataFragment;
};

export type RewardsDataQuery = { __typename?: 'Query' } & {
  userRewardsDetails: { __typename?: 'RewardDetails' } & RewardsDataFragment;
};

export type UserListQueryVariables = Exact<{ [key: string]: never }>;

export type UserListQuery = { __typename?: 'Query' } & {
  userList: Array<
    Maybe<
      { __typename?: 'Users' } & Pick<
        Users,
        '_id' | 'name' | 'lastName' | 'email'
      >
    >
  >;
};

export type UserLookupTableQueryVariables = Exact<{
  listBy: ListUsersBy;
}>;

export type UserLookupTableQuery = { __typename?: 'Query' } & Pick<
  Query,
  'userLookupTable'
>;

export type UserTagsQueryVariables = Exact<{ [key: string]: never }>;

export type UserTagsQuery = { __typename?: 'Query' } & Pick<Query, 'userTags'>;

export const ArticleCardFragFragmentDoc = `
    fragment ArticleCardFrag on Article {
  sentiment_score
  user_ro
  _source {
    title
    content
    summary
    PlanType
    sequenceId
    driver
    orig_url
    source {
      name
    }
    harvestDate
    uploadDate
  }
  tags
  ner_norm {
    GPE
    ORG
    PERSON
    PRODUCT
    CARDINAL
    DATE
    NORP
    QUANITTY
    FAC
  }
  userMetaData {
    userId
    permanent
    date
    sharedBy {
      userId
      name
    }
  }
}
    `;

export const TasksStatusFragFragmentDoc = `
    fragment TasksStatusFrag on Tasks {
      _id
      company_id
      status
      request_type
      document_id
      request_details {
        filename
      }
    }
    `;

export const FullArticleFragmentDoc = `
    fragment FullArticle on Article {
  _id
  _source {
    PlanType
    content
    dedup_status
    driver
    driver_type
    harvestDate
    images
    orig_url
    sequenceId
    shortDesc
    sortDate
    source {
      name
    }
    summary
    title
    uploadDate
    summary_ext
    notebook_note_id
  }
  keyword
  ner {
    ORG
    GPE
    PERSON
    PRODUCT
  }
  origin
  proImageLink
  pubStatus
  sentiment_score
  keywordList
  tags
  sharedBy
  user_ro
  autoremove
  userMetaData {
    userId
    permanent
    date
    sharedBy {
      userId
      name
    }
  }
}
    `;
export const MeDataFragmentDoc = `
    fragment MeData on User {
  _id
  name
  email
  lastName
  companyName
  admin
  company
  totalPoints
  token
  dashboardType
  prefFilled
  nearestPoint
  pointsGotByNearest
  totalToken
  walletAddress
  emailVerified
  nftData
  nftErrorCounter
  loginLogsCount
  dashboardType
  clientId
  clientSecret
}
    `;

export const RewardsDataFragmentDoc = `
    fragment MeData on RewardDetails {
  _id
  userId
  companyId
  totalPoints
  totalToken
  nearestPoint
  pointsGotByNearest
  token
}
    `;
export const DeleteSingleArticleDocument = `
    mutation deleteSingleArticle($options: SingleArticleDeleteInput!) {
  deleteSingleArticle(options: $options)
}
    `;
export const useDeleteSingleArticleMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteSingleArticleMutation,
    TError,
    DeleteSingleArticleMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteSingleArticleMutation,
    TError,
    DeleteSingleArticleMutationVariables,
    TContext
  >(
    fetcher<DeleteSingleArticleMutation, DeleteSingleArticleMutationVariables>(
      DeleteSingleArticleDocument
    ),
    options
  );
export const SaveNewsArticleDocument = `
    mutation saveNewsArticle($saveNewsArticleId: String!, $whichDb: WhichDB!) {
  saveNewsArticle(id: $saveNewsArticleId, whichDb: $whichDb)
}
    `;
export const useSaveNewsArticleMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    SaveNewsArticleMutation,
    TError,
    SaveNewsArticleMutationVariables,
    TContext
  >
) =>
  useMutation<
    SaveNewsArticleMutation,
    TError,
    SaveNewsArticleMutationVariables,
    TContext
  >(
    fetcher<SaveNewsArticleMutation, SaveNewsArticleMutationVariables>(
      SaveNewsArticleDocument
    ),
    options
  );
export const ShareMultipleArticlesMutationDocument = `
    mutation shareMultipleArticlesMutation($options: ShareMultipleArticlesInput!) {
  shareMultipleArticles(options: $options)
}
    `;
export const useShareMultipleArticlesMutationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ShareMultipleArticlesMutationMutation,
    TError,
    ShareMultipleArticlesMutationMutationVariables,
    TContext
  >
) =>
  useMutation<
    ShareMultipleArticlesMutationMutation,
    TError,
    ShareMultipleArticlesMutationMutationVariables,
    TContext
  >(
    fetcher<
      ShareMultipleArticlesMutationMutation,
      ShareMultipleArticlesMutationMutationVariables
    >(ShareMultipleArticlesMutationDocument),
    options
  );
export const ShareSingleArticleDocument = `
    mutation ShareSingleArticle($options: ShareSingleArticleInput!) {
  shareSingleArticle(options: $options)
}
    `;
export const useShareSingleArticleMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ShareSingleArticleMutation,
    TError,
    ShareSingleArticleMutationVariables,
    TContext
  >
) =>
  useMutation<
    ShareSingleArticleMutation,
    TError,
    ShareSingleArticleMutationVariables,
    TContext
  >(
    fetcher<ShareSingleArticleMutation, ShareSingleArticleMutationVariables>(
      ShareSingleArticleDocument
    ),
    options
  );

export const UserRewardLogTypeDocument = `
    mutation UserRewardLogs($options: UserRewardLogsTypeInput!) {
      userRewardLogs(options: $options)
    }
`;
export const useUserRewardLogsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UserRewardLogsMutation,
    TError,
    UserRewardLogsMutationVariables,
    TContext
  >
) =>
  useMutation<
    UserRewardLogsMutation,
    TError,
    UserRewardLogsMutationVariables,
    TContext
  >(
    fetcher<UserRewardLogsMutation, UserRewardLogsMutationVariables>(
      UserRewardLogTypeDocument
    ),
    options
  );

export const UserRewardDocument = `
    mutation UserRewards($options: UserRewardsInput!) {
      userRewards(options: $options)
    }
`;
export const useUserRewardsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UserRewardsMutation,
    TError,
    UserRewardsMutationVariables,
    TContext
  >
) =>
  useMutation<
    UserRewardsMutation,
    TError,
    UserRewardsMutationVariables,
    TContext
  >(
    fetcher<UserRewardsMutation, UserRewardsMutationVariables>(
      UserRewardDocument
    ),
    options
  );

export const UserLinkedInShareDocument = `
    mutation UserLinkedInShare($options: UserLinkedInShare!) {
      userLinkedInShare(options: $options)
    }
`;
export const useUserLinkedInShareMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UserLinkedInShareMutation,
    TError,
    UserLinkedInMutationVariables,
    TContext
  >
) =>
  useMutation<
    UserLinkedInShareMutation,
    TError,
    UserLinkedInMutationVariables,
    TContext
  >(
    fetcher<UserLinkedInShareMutation, UserLinkedInMutationVariables>(
      UserLinkedInShareDocument
    ),
    options
  );
export const UserTwitterShareDocument = `
    mutation UserTwitterShare($options: UserTwitterShare!) {
      userTwitterShare(options: $options)
    }
`;
export const useTwitterShareMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UserTwitterShareMutation,
    TError,
    UserTwitterMutationVariables,
    TContext
  >
) =>
  useMutation<
    UserTwitterShareMutation,
    TError,
    UserTwitterMutationVariables,
    TContext
  >(
    fetcher<UserTwitterShareMutation, UserTwitterMutationVariables>(
      UserTwitterShareDocument
    ),
    options
  );

export const NerCorrectionFeedbackDocument = `
    mutation nerCorrectionFeedback($feedbackOptions: NERCorrectionInput!) {
  nerCorrectionFeedback(options: $feedbackOptions)
}
    `;
export const useNerCorrectionFeedbackMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    NerCorrectionFeedbackMutation,
    TError,
    NerCorrectionFeedbackMutationVariables,
    TContext
  >
) =>
  useMutation<
    NerCorrectionFeedbackMutation,
    TError,
    NerCorrectionFeedbackMutationVariables,
    TContext
  >(
    fetcher<
      NerCorrectionFeedbackMutation,
      NerCorrectionFeedbackMutationVariables
    >(NerCorrectionFeedbackDocument),
    options
  );
export const AddContentToNotesDocument = `
    mutation addContentToNotes($noteIds: [String!]!, $content: String!) {
  addContentToNotes(noteIds: $noteIds, content: $content)
}
    `;
export const useAddContentToNotesMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    AddContentToNotesMutation,
    TError,
    AddContentToNotesMutationVariables,
    TContext
  >
) =>
  useMutation<
    AddContentToNotesMutation,
    TError,
    AddContentToNotesMutationVariables,
    TContext
  >(
    fetcher<AddContentToNotesMutation, AddContentToNotesMutationVariables>(
      AddContentToNotesDocument
    ),
    options
  );
export const DeleteNoteDocument = `
    mutation deleteNote($noteId: ID!) {
  deleteNote(noteId: $noteId)
}
    `;
export const useDeleteNoteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteNoteMutation,
    TError,
    DeleteNoteMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteNoteMutation,
    TError,
    DeleteNoteMutationVariables,
    TContext
  >(
    fetcher<DeleteNoteMutation, DeleteNoteMutationVariables>(
      DeleteNoteDocument
    ),
    options
  );
export const MakeNewNoteDocument = `
    mutation makeNewNote {
  makeNewNote
}
    `;
export const useMakeNewNoteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    MakeNewNoteMutation,
    TError,
    MakeNewNoteMutationVariables,
    TContext
  >
) =>
  useMutation<
    MakeNewNoteMutation,
    TError,
    MakeNewNoteMutationVariables,
    TContext
  >(
    fetcher<MakeNewNoteMutation, MakeNewNoteMutationVariables>(
      MakeNewNoteDocument
    ),
    options
  );
export const ShareNoteDocument = `
    mutation shareNote($users: [ID!]!, $note: JSON!) {
  shareNote(users: $users, note: $note)
}
    `;
export const useShareNoteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ShareNoteMutation,
    TError,
    ShareNoteMutationVariables,
    TContext
  >
) =>
  useMutation<ShareNoteMutation, TError, ShareNoteMutationVariables, TContext>(
    fetcher<ShareNoteMutation, ShareNoteMutationVariables>(ShareNoteDocument),
    options
  );
export const UpdateNoteDocument = `
    mutation updateNote($updates: UpdateArgs!) {
  updateNote(updates: $updates) {
    _id
    _source {
      content
      title
    }
    richText {
      content {
        entityMap
        blocks
      }
      metaData {
        created
        createdBy
        lastUpdated
        sharedBy
      }
    }
    owner
  }
}
    `;
export const useUpdateNoteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateNoteMutation,
    TError,
    UpdateNoteMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateNoteMutation,
    TError,
    UpdateNoteMutationVariables,
    TContext
  >(
    fetcher<UpdateNoteMutation, UpdateNoteMutationVariables>(
      UpdateNoteDocument
    ),
    options
  );
export const DeleteFilterDocument = `
    mutation deleteFilter($filterId: String!) {
  deleteFilter(filterId: $filterId)
}
    `;
export const useDeleteFilterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteFilterMutation,
    TError,
    DeleteFilterMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteFilterMutation,
    TError,
    DeleteFilterMutationVariables,
    TContext
  >(
    fetcher<DeleteFilterMutation, DeleteFilterMutationVariables>(
      DeleteFilterDocument
    ),
    options
  );
export const SaveFilterDocument = `
    mutation saveFilter($saveFilterSaveFilterInput: SaveFilterInput!) {
  saveFilter(saveFilterInput: $saveFilterSaveFilterInput)
}
    `;
export const useSaveFilterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaveFilterMutation,
    TError,
    SaveFilterMutationVariables,
    TContext
  >
) =>
  useMutation<
    SaveFilterMutation,
    TError,
    SaveFilterMutationVariables,
    TContext
  >(
    fetcher<SaveFilterMutation, SaveFilterMutationVariables>(
      SaveFilterDocument
    ),
    options
  );
export const UpdateFilterDocument = `
    mutation updateFilter($updateSaveFilterId: String!, $updateSaveFilterInput: SaveFilterInput!) {
  updateFilter(
    filterId: $updateSaveFilterId
    saveFilterInput: $updateSaveFilterInput
  ) {
    _id
    name
    topics
    relationshipType
    relationshipText
    tag
    dataSource
    category
    driver
    outcome
    country
  }
}
    `;
export const useUpdateFilterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateFilterMutation,
    TError,
    UpdateFilterMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateFilterMutation,
    TError,
    UpdateFilterMutationVariables,
    TContext
  >(
    fetcher<UpdateFilterMutation, UpdateFilterMutationVariables>(
      UpdateFilterDocument
    ),
    options
  );
export const AddTagsToArticleDocument = `
    mutation addTagsToArticle($tagArgs: TagArgs!) {
  addTagsToArticle(tagArgs: $tagArgs) {
    _id
    _source {
      PlanType
      content
      dedup_status
      driver
      driver_type
      harvestDate
      images
      orig_url
      sequenceId
      shortDesc
      sortDate
      source {
        name
      }
      summary
      title
      uploadDate
      summary_ext
    }
    tags
  }
}
    `;
export const useAddTagsToArticleMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    AddTagsToArticleMutation,
    TError,
    AddTagsToArticleMutationVariables,
    TContext
  >
) =>
  useMutation<
    AddTagsToArticleMutation,
    TError,
    AddTagsToArticleMutationVariables,
    TContext
  >(
    fetcher<AddTagsToArticleMutation, AddTagsToArticleMutationVariables>(
      AddTagsToArticleDocument
    ),
    options
  );
export const AddTagsToUserProfileDocument = `
    mutation addTagsToUserProfile($userTags: UserTags!) {
  addTagsToUserProfile(userTags: $userTags)
}
    `;
export const useAddTagsToUserProfileMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    AddTagsToUserProfileMutation,
    TError,
    AddTagsToUserProfileMutationVariables,
    TContext
  >
) =>
  useMutation<
    AddTagsToUserProfileMutation,
    TError,
    AddTagsToUserProfileMutationVariables,
    TContext
  >(
    fetcher<
      AddTagsToUserProfileMutation,
      AddTagsToUserProfileMutationVariables
    >(AddTagsToUserProfileDocument),
    options
  );
export const DeleteTagFromArticleDocument = `
    mutation deleteTagFromArticle($delOptions: DelArticleTagInput!) {
  deleteTagFromArticle(delOptions: $delOptions)
}
    `;
export const useDeleteTagFromArticleMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteTagFromArticleMutation,
    TError,
    DeleteTagFromArticleMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteTagFromArticleMutation,
    TError,
    DeleteTagFromArticleMutationVariables,
    TContext
  >(
    fetcher<
      DeleteTagFromArticleMutation,
      DeleteTagFromArticleMutationVariables
    >(DeleteTagFromArticleDocument),
    options
  );
export const DeleteTagFromUserProfileDocument = `
    mutation deleteTagFromUserProfile($tag: String!) {
  deleteTagFromUserProfile(tag: $tag)
}
    `;
export const useDeleteTagFromUserProfileMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteTagFromUserProfileMutation,
    TError,
    DeleteTagFromUserProfileMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteTagFromUserProfileMutation,
    TError,
    DeleteTagFromUserProfileMutationVariables,
    TContext
  >(
    fetcher<
      DeleteTagFromUserProfileMutation,
      DeleteTagFromUserProfileMutationVariables
    >(DeleteTagFromUserProfileDocument),
    options
  );
export const UpdateArticleTagsDocument = `
    mutation updateArticleTags($updateArgs: UpdateArticleTagsInput!) {
  updateArticleTags(updateArgs: $updateArgs)
}
    `;
export const useUpdateArticleTagsMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateArticleTagsMutation,
    TError,
    UpdateArticleTagsMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateArticleTagsMutation,
    TError,
    UpdateArticleTagsMutationVariables,
    TContext
  >(
    fetcher<UpdateArticleTagsMutation, UpdateArticleTagsMutationVariables>(
      UpdateArticleTagsDocument
    ),
    options
  );
export const UpdateTagFromUserProfileDocument = `
    mutation updateTagFromUserProfile($newTag: String!, $oldTag: String!) {
  updateTagFromUserProfile(newTag: $newTag, oldTag: $oldTag)
}
    `;
export const useUpdateTagFromUserProfileMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateTagFromUserProfileMutation,
    TError,
    UpdateTagFromUserProfileMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateTagFromUserProfileMutation,
    TError,
    UpdateTagFromUserProfileMutationVariables,
    TContext
  >(
    fetcher<
      UpdateTagFromUserProfileMutation,
      UpdateTagFromUserProfileMutationVariables
    >(UpdateTagFromUserProfileDocument),
    options
  );
export const AddTopicToUserPreferencesDocument = `
    mutation addTopicToUserPreferences($inputOpts: NewTopicInput!) {
  addTopicToUserPreferences(inputOpts: $inputOpts)
}
    `;
export const useAddTopicToUserPreferencesMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    AddTopicToUserPreferencesMutation,
    TError,
    AddTopicToUserPreferencesMutationVariables,
    TContext
  >
) =>
  useMutation<
    AddTopicToUserPreferencesMutation,
    TError,
    AddTopicToUserPreferencesMutationVariables,
    TContext
  >(
    fetcher<
      AddTopicToUserPreferencesMutation,
      AddTopicToUserPreferencesMutationVariables
    >(AddTopicToUserPreferencesDocument),
    options
  );
export const DeleteTopicFromUserPreferencesDocument = `
    mutation deleteTopicFromUserPreferences($topicId: String!) {
  deleteTopicFromUserPreferences(topicId: $topicId)
}
    `;
export const useDeleteTopicFromUserPreferencesMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteTopicFromUserPreferencesMutation,
    TError,
    DeleteTopicFromUserPreferencesMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteTopicFromUserPreferencesMutation,
    TError,
    DeleteTopicFromUserPreferencesMutationVariables,
    TContext
  >(
    fetcher<
      DeleteTopicFromUserPreferencesMutation,
      DeleteTopicFromUserPreferencesMutationVariables
    >(DeleteTopicFromUserPreferencesDocument),
    options
  );
export const EditTopicFromUserPreferencesDocument = `
    mutation editTopicFromUserPreferences($inputOpts: EditTopicInput!) {
  editTopicFromUserPreferences(inputOpts: $inputOpts)
}
    `;
export const useEditTopicFromUserPreferencesMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    EditTopicFromUserPreferencesMutation,
    TError,
    EditTopicFromUserPreferencesMutationVariables,
    TContext
  >
) =>
  useMutation<
    EditTopicFromUserPreferencesMutation,
    TError,
    EditTopicFromUserPreferencesMutationVariables,
    TContext
  >(
    fetcher<
      EditTopicFromUserPreferencesMutation,
      EditTopicFromUserPreferencesMutationVariables
    >(EditTopicFromUserPreferencesDocument),
    options
  );
export const ArticleDocument = `
    query article($articleId: ID!, $whichDB: WhichDB!) {
  article(id: $articleId, whichDB: $whichDB) {
    ...FullArticle
  }
}
    ${FullArticleFragmentDoc}`;
export const useArticleQuery = <TData = ArticleQuery, TError = unknown>(
  variables: ArticleQueryVariables,
  options?: UseQueryOptions<ArticleQuery, TError, TData>
) =>
  useQuery<ArticleQuery, TError, TData>(
    ['article', variables],
    fetcher<ArticleQuery, ArticleQueryVariables>(ArticleDocument).bind(
      null,
      variables
    ),
    options
  );
export const ArticleCardDocument = `
    query articleCard($articleId: ID!, $whichDB: WhichDB!) {
  article(id: $articleId, whichDB: $whichDB) {
    ...ArticleCardFrag
  }
}
    ${ArticleCardFragFragmentDoc}`;
export const useArticleCardQuery = <TData = ArticleCardQuery, TError = unknown>(
  variables: ArticleCardQueryVariables,
  options?: UseQueryOptions<ArticleCardQuery, TError, TData>
) =>
  useQuery<ArticleCardQuery, TError, TData>(
    ['articleCard', variables],
    fetcher<ArticleCardQuery, ArticleCardQueryVariables>(
      ArticleCardDocument
    ).bind(null, variables),
    options
  );

export const TasksStatusDocument = `
    query articlesUploadingStatus($whichDB: WhichDB!) {
    articlesUploadingStatus(whichDB: $whichDB) {
      tasks {...TasksStatusFrag}
    }
}
    ${TasksStatusFragFragmentDoc}`;
export const useTasksStatusQuery = <TData = TasksStatusQuery, TError = unknown>(
  variables: TasksStatusQueryVariables,
  options?: UseQueryOptions<TasksStatusQuery, TError, TData>
) =>
  useQuery<TasksStatusQuery, TError, TData>(
    ['tasksStatus', variables],
    fetcher<TasksStatusQuery, TasksStatusQueryVariables>(
      TasksStatusDocument
    ).bind(null, variables),
    options
  );

export const ArticlesCategoryBreakdownDocument = `
    query articlesCategoryBreakdown($inputOpts: ArticlesInputs) {
  articlesCategoryBreakdown(inputOpts: $inputOpts) {
    label
    value
  }
}
    `;
export const useArticlesCategoryBreakdownQuery = <
  TData = ArticlesCategoryBreakdownQuery,
  TError = unknown
>(
  variables?: ArticlesCategoryBreakdownQueryVariables,
  options?: UseQueryOptions<ArticlesCategoryBreakdownQuery, TError, TData>
) =>
  useQuery<ArticlesCategoryBreakdownQuery, TError, TData>(
    ['articlesCategoryBreakdown', variables],
    fetcher<
      ArticlesCategoryBreakdownQuery,
      ArticlesCategoryBreakdownQueryVariables
    >(ArticlesCategoryBreakdownDocument).bind(null, variables),
    options
  );
export const ArticlesCitiesDocument = `
    query articlesCities($inputOpts: ArticlesInputs) {
  articlesCities(inputOpts: $inputOpts) {
    label
    value
  }
}
    `;
export const useArticlesCitiesQuery = <
  TData = ArticlesCitiesQuery,
  TError = unknown
>(
  variables?: ArticlesCitiesQueryVariables,
  options?: UseQueryOptions<ArticlesCitiesQuery, TError, TData>
) =>
  useQuery<ArticlesCitiesQuery, TError, TData>(
    ['articlesCities', variables],
    fetcher<ArticlesCitiesQuery, ArticlesCitiesQueryVariables>(
      ArticlesCitiesDocument
    ).bind(null, variables),
    options
  );
export const ArticlesFilteredVsTotalStatDocument = `
    query articlesFilteredVsTotalStat($inputOpts: ArticlesInputs!) {
  articlesFilteredVsTotalStat(inputOpts: $inputOpts) {
    filteredTotal
    total
    totalPrivate
    totalShared
    totalFeeds
  }
}
    `;
export const useArticlesFilteredVsTotalStatQuery = <
  TData = ArticlesFilteredVsTotalStatQuery,
  TError = unknown
>(
  variables: ArticlesFilteredVsTotalStatQueryVariables,
  options?: UseQueryOptions<ArticlesFilteredVsTotalStatQuery, TError, TData>
) =>
  useQuery<ArticlesFilteredVsTotalStatQuery, TError, TData>(
    ['articlesFilteredVsTotalStat', variables],
    fetcher<
      ArticlesFilteredVsTotalStatQuery,
      ArticlesFilteredVsTotalStatQueryVariables
    >(ArticlesFilteredVsTotalStatDocument).bind(null, variables),
    options
  );
export const ArticlesGeographyDocument = `
    query articlesGeography($inputOpts: ArticlesInputs) {
  articlesGeography(inputOpts: $inputOpts) {
    geography {
      ISO3
      value
    }
    max
  }
}
    `;
export const useArticlesGeographyQuery = <
  TData = ArticlesGeographyQuery,
  TError = unknown
>(
  variables?: ArticlesGeographyQueryVariables,
  options?: UseQueryOptions<ArticlesGeographyQuery, TError, TData>
) =>
  useQuery<ArticlesGeographyQuery, TError, TData>(
    ['articlesGeography', variables],
    fetcher<ArticlesGeographyQuery, ArticlesGeographyQueryVariables>(
      ArticlesGeographyDocument
    ).bind(null, variables),
    options
  );
export const ArticlesMainDriversDocument = `
    query articlesMainDrivers($inputOpts: ArticlesInputs) {
  articlesMainDrivers(inputOpts: $inputOpts) {
    label
    value
  }
}
    `;
export const useArticlesMainDriversQuery = <
  TData = ArticlesMainDriversQuery,
  TError = unknown
>(
  variables?: ArticlesMainDriversQueryVariables,
  options?: UseQueryOptions<ArticlesMainDriversQuery, TError, TData>
) =>
  useQuery<ArticlesMainDriversQuery, TError, TData>(
    ['articlesMainDrivers', variables],
    fetcher<ArticlesMainDriversQuery, ArticlesMainDriversQueryVariables>(
      ArticlesMainDriversDocument
    ).bind(null, variables),
    options
  );
export const ArticlesRateOfChangeDocument = `
    query articlesRateOfChange($inputOpts: ArticlesInputs) {
  articlesRateOfChange(inputOpts: $inputOpts) {
    newsCount
    newsROC
    risks
    risksROC
    opportunities
    opportunitiesROC
    orgCount
    orgROC
    privateCount
    privateROC
  }
}
    `;
export const useArticlesRateOfChangeQuery = <
  TData = ArticlesRateOfChangeQuery,
  TError = unknown
>(
  variables?: ArticlesRateOfChangeQueryVariables,
  options?: UseQueryOptions<ArticlesRateOfChangeQuery, TError, TData>
) =>
  useQuery<ArticlesRateOfChangeQuery, TError, TData>(
    ['articlesRateOfChange', variables],
    fetcher<ArticlesRateOfChangeQuery, ArticlesRateOfChangeQueryVariables>(
      ArticlesRateOfChangeDocument
    ).bind(null, variables),
    options
  );
export const ArticlesRisksAndOppsDocument = `
    query articlesRisksAndOpps($inputOpts: RisksAndOppsInputs!) {
  articlesRisksAndOpps(inputOpts: $inputOpts) {
    risksAndOpps {
      _id
      title
      confidence
      rioScore
      sortDate
      whichDB
    }
    pv2Skip
    pfSkip
  }
}
    `;
export const useArticlesRisksAndOppsQuery = <
  TData = ArticlesRisksAndOppsQuery,
  TError = unknown
>(
  variables: ArticlesRisksAndOppsQueryVariables,
  options?: UseQueryOptions<ArticlesRisksAndOppsQuery, TError, TData>
) =>
  useQuery<ArticlesRisksAndOppsQuery, TError, TData>(
    ['articlesRisksAndOpps', variables],
    fetcher<ArticlesRisksAndOppsQuery, ArticlesRisksAndOppsQueryVariables>(
      ArticlesRisksAndOppsDocument
    ).bind(null, variables),
    options
  );
export const ArticlesTopFiveRisksAndOppsDocument = `
    query articlesTopFiveRisksAndOpps($inputOpts: TopFiveRisksAndOppsInputs!) {
  articlesTopFiveRisksAndOpps(inputOpts: $inputOpts) {
    risksAndOpps {
      _id
      title
      shortDesc
    }
  }
}
    `;
export const useArticlesTopFiveRisksAndOppsQuery = <
  TData = ArticlesTopFiveRisksAndOppsQuery,
  TError = unknown
>(
  variables: ArticlesTopFiveRisksAndOppsQueryVariables,
  options?: UseQueryOptions<ArticlesTopFiveRisksAndOppsQuery, TError, TData>
) =>
  useQuery<ArticlesTopFiveRisksAndOppsQuery, TError, TData>(
    ['articlesTopFiveRisksAndOpps', variables],
    fetcher<
      ArticlesTopFiveRisksAndOppsQuery,
      ArticlesTopFiveRisksAndOppsQueryVariables
    >(ArticlesTopFiveRisksAndOppsDocument).bind(null, variables),
    options
  );
export const ArticlesTrendingDocument = `
    query articlesTrending($inputOpts: ArticlesInputs) {
  articlesTrending(inputOpts: $inputOpts) {
    movers {
      product {
        label
        value
      }
      person {
        label
        value
      }
      location {
        label
        value
      }
      company {
        label
        value
      }
    }
    mentions {
      product {
        label
        value
      }
      person {
        label
        value
      }
      location {
        label
        value
      }
      company {
        label
        value
      }
    }
  }
}
    `;
export const useArticlesTrendingQuery = <
  TData = ArticlesTrendingQuery,
  TError = unknown
>(
  variables?: ArticlesTrendingQueryVariables,
  options?: UseQueryOptions<ArticlesTrendingQuery, TError, TData>
) =>
  useQuery<ArticlesTrendingQuery, TError, TData>(
    ['articlesTrending', variables],
    fetcher<ArticlesTrendingQuery, ArticlesTrendingQueryVariables>(
      ArticlesTrendingDocument
    ).bind(null, variables),
    options
  );
export const ContentModalDocument = `
    query contentModal($articleId: ID!, $whichDB: WhichDB!) {
  article(id: $articleId, whichDB: $whichDB) {
    _id
    _source {
      title
      content
      orig_url
    }
    sharedBy
  }
}
    `;
export const useContentModalQuery = <
  TData = ContentModalQuery,
  TError = unknown
>(
  variables: ContentModalQueryVariables,
  options?: UseQueryOptions<ContentModalQuery, TError, TData>
) =>
  useQuery<ContentModalQuery, TError, TData>(
    ['contentModal', variables],
    fetcher<ContentModalQuery, ContentModalQueryVariables>(
      ContentModalDocument
    ).bind(null, variables),
    options
  );
export const DriversByTopicDocument = `
    query driversByTopic($topic: String!) {
  driversByTopic(topic: $topic)
}
    `;
export const useDriversByTopicQuery = <
  TData = DriversByTopicQuery,
  TError = unknown
>(
  variables: DriversByTopicQueryVariables,
  options?: UseQueryOptions<DriversByTopicQuery, TError, TData>
) =>
  useQuery<DriversByTopicQuery, TError, TData>(
    ['driversByTopic', variables],
    fetcher<DriversByTopicQuery, DriversByTopicQueryVariables>(
      DriversByTopicDocument
    ).bind(null, variables),
    options
  );
export const GetSingleNoteDocument = `
    query getSingleNote($noteId: String!) {
  getSingleNote(noteId: $noteId) {
    _id
    owner
    richText {
      metaData {
        created
        createdBy
        lastUpdated
        sharedBy
      }
      content {
        entityMap
        blocks
      }
    }
    _source {
      title
      content
    }
  }
}
    `;
export const useGetSingleNoteQuery = <
  TData = GetSingleNoteQuery,
  TError = unknown
>(
  variables: GetSingleNoteQueryVariables,
  options?: UseQueryOptions<GetSingleNoteQuery, TError, TData>
) =>
  useQuery<GetSingleNoteQuery, TError, TData>(
    ['getSingleNote', variables],
    fetcher<GetSingleNoteQuery, GetSingleNoteQueryVariables>(
      GetSingleNoteDocument
    ).bind(null, variables),
    options
  );
export const GetUserSavedFiltersDocument = `
    query getUserSavedFilters {
  getUserSavedFilters {
    _id
    name
    topics
    relationshipType
    relationshipText
    tag
    dataSource
    category
    driver
    outcome
    country
  }
}
    `;
export const useGetUserSavedFiltersQuery = <
  TData = GetUserSavedFiltersQuery,
  TError = unknown
>(
  variables?: GetUserSavedFiltersQueryVariables,
  options?: UseQueryOptions<GetUserSavedFiltersQuery, TError, TData>
) =>
  useQuery<GetUserSavedFiltersQuery, TError, TData>(
    ['getUserSavedFilters', variables],
    fetcher<GetUserSavedFiltersQuery, GetUserSavedFiltersQueryVariables>(
      GetUserSavedFiltersDocument
    ).bind(null, variables),
    options
  );
export const FetchTopFiveNewsPreviewDocument = `
    query fetchTopFiveNewsPreview($inputOpts: TopFiveNewsPreviewInput!) {
  fetchTopFiveNewsPreview(inputOpts: $inputOpts) {
    _source {
      content
      orig_url
      sequenceId
      shortDesc
      title
    }
  }
}
    `;
export const useFetchTopFiveNewsPreviewQuery = <
  TData = FetchTopFiveNewsPreviewQuery,
  TError = unknown
>(
  variables: FetchTopFiveNewsPreviewQueryVariables,
  options?: UseQueryOptions<FetchTopFiveNewsPreviewQuery, TError, TData>
) =>
  useQuery<FetchTopFiveNewsPreviewQuery, TError, TData>(
    ['fetchTopFiveNewsPreview', variables],
    fetcher<
      FetchTopFiveNewsPreviewQuery,
      FetchTopFiveNewsPreviewQueryVariables
    >(FetchTopFiveNewsPreviewDocument).bind(null, variables),
    options
  );
export const FetchUserTopicsDocument = `
    query fetchUserTopics {
  fetchUserTopics {
    id
    topic
    relationshipText
    relationshipType
    excludedKeywords
    country {
      countryName
      marketCode
    }
  }
}
    `;
export const useFetchUserTopicsQuery = <
  TData = FetchUserTopicsQuery,
  TError = unknown
>(
  variables?: FetchUserTopicsQueryVariables,
  options?: UseQueryOptions<FetchUserTopicsQuery, TError, TData>
) =>
  useQuery<FetchUserTopicsQuery, TError, TData>(
    ['fetchUserTopics', variables],
    fetcher<FetchUserTopicsQuery, FetchUserTopicsQueryVariables>(
      FetchUserTopicsDocument
    ).bind(null, variables),
    options
  );
export const FetchUserCategoriesDocument = `
    query fetchUserCategories {
  fetchUserCategories
}
    `;
export const useFetchUserCategoriesQuery = <
  TData = FetchUserCategoriesQuery,
  TError = unknown
>(
  variables?: FetchUserCategoriesQueryVariables,
  options?: UseQueryOptions<FetchUserCategoriesQuery, TError, TData>
) =>
  useQuery<FetchUserCategoriesQuery, TError, TData>(
    ['fetchUserCategories', variables],
    fetcher<FetchUserCategoriesQuery, FetchUserCategoriesQueryVariables>(
      FetchUserCategoriesDocument
    ).bind(null, variables),
    options
  );
export const FetchUserDriversDocument = `
    query fetchUserDrivers {
  fetchUserDrivers
}
    `;
export const useFetchUserDriversQuery = <
  TData = FetchUserDriversQuery,
  TError = unknown
>(
  variables?: FetchUserDriversQueryVariables,
  options?: UseQueryOptions<FetchUserDriversQuery, TError, TData>
) =>
  useQuery<FetchUserDriversQuery, TError, TData>(
    ['fetchUserDrivers', variables],
    fetcher<FetchUserDriversQuery, FetchUserDriversQueryVariables>(
      FetchUserDriversDocument
    ).bind(null, variables),
    options
  );
export const FetchUserRelationshipsDocument = `
    query fetchUserRelationships {
  fetchUserRelationships {
    category
    relationships
  }
}
    `;
export const useFetchUserRelationshipsQuery = <
  TData = FetchUserRelationshipsQuery,
  TError = unknown
>(
  variables?: FetchUserRelationshipsQueryVariables,
  options?: UseQueryOptions<FetchUserRelationshipsQuery, TError, TData>
) =>
  useQuery<FetchUserRelationshipsQuery, TError, TData>(
    ['fetchUserRelationships', variables],
    fetcher<FetchUserRelationshipsQuery, FetchUserRelationshipsQueryVariables>(
      FetchUserRelationshipsDocument
    ).bind(null, variables),
    options
  );
export const FetchUserTopicsForSearchDocument = `
    query fetchUserTopicsForSearch {
  fetchUserTopicsForSearch
}
    `;
export const useFetchUserTopicsForSearchQuery = <
  TData = FetchUserTopicsForSearchQuery,
  TError = unknown
>(
  variables?: FetchUserTopicsForSearchQueryVariables,
  options?: UseQueryOptions<FetchUserTopicsForSearchQuery, TError, TData>
) =>
  useQuery<FetchUserTopicsForSearchQuery, TError, TData>(
    ['fetchUserTopicsForSearch', variables],
    fetcher<
      FetchUserTopicsForSearchQuery,
      FetchUserTopicsForSearchQueryVariables
    >(FetchUserTopicsForSearchDocument).bind(null, variables),
    options
  );
export const GetUserNotesDocument = `
    query getUserNotes($searchStr: String) {
  getUserNotes(searchStr: $searchStr) {
    _id
    title
    date
    sharedBy
  }
}
    `;
export const useGetUserNotesQuery = <
  TData = GetUserNotesQuery,
  TError = unknown
>(
  variables?: GetUserNotesQueryVariables,
  options?: UseQueryOptions<GetUserNotesQuery, TError, TData>
) =>
  useQuery<GetUserNotesQuery, TError, TData>(
    ['getUserNotes', variables],
    fetcher<GetUserNotesQuery, GetUserNotesQueryVariables>(
      GetUserNotesDocument
    ).bind(null, variables),
    options
  );
export const MeDocument = `
    query me {
  me {
    ...MeData
  }
}
    ${MeDataFragmentDoc}`;
export const useMeQuery = <TData = MeQuery, TError = unknown>(
  variables?: MeQueryVariables,
  options?: UseQueryOptions<MeQuery, TError, TData>
) =>
  useQuery<MeQuery, TError, TData>(
    ['me', variables],
    fetcher<MeQuery, MeQueryVariables>(MeDocument).bind(null, variables),
    options
  );

export const RewardsDocument = `
    query userRewardsDetails {
    userRewardsDetails {
    ...MeData
  }
}
    ${RewardsDataFragmentDoc}`;
export const useRewardsDetails = <TData = RewardsDataQuery, TError = unknown>(
  variables?: RewardsDataQueryVariables,
  options?: UseQueryOptions<RewardsDataQuery, TError, TData>
) =>
  useQuery<RewardsDataQuery, TError, TData>(
    ['userRewardsDetails', variables],
    fetcher<RewardsDataQuery, RewardsDataQueryVariables>(RewardsDocument).bind(
      null,
      variables
    ),
    {
      ...options,
      refetchOnWindowFocus: false,
      enabled: false, // disable this query from automatically running
    }
  );
export const UserListDocument = `
    query userList {
  userList {
    _id
    name
    lastName
    email
  }
}
    `;
export const useUserListQuery = <TData = UserListQuery, TError = unknown>(
  variables?: UserListQueryVariables,
  options?: UseQueryOptions<UserListQuery, TError, TData>
) =>
  useQuery<UserListQuery, TError, TData>(
    ['userList', variables],
    fetcher<UserListQuery, UserListQueryVariables>(UserListDocument).bind(
      null,
      variables
    ),
    options
  );
export const UserLookupTableDocument = `
    query userLookupTable($listBy: ListUsersBy!) {
  userLookupTable(listBy: $listBy)
}
    `;
export const useUserLookupTableQuery = <
  TData = UserLookupTableQuery,
  TError = unknown
>(
  variables: UserLookupTableQueryVariables,
  options?: UseQueryOptions<UserLookupTableQuery, TError, TData>
) =>
  useQuery<UserLookupTableQuery, TError, TData>(
    ['userLookupTable', variables],
    fetcher<UserLookupTableQuery, UserLookupTableQueryVariables>(
      UserLookupTableDocument
    ).bind(null, variables),
    options
  );
export const UserTagsDocument = `
    query userTags {
  userTags
}
    `;
export const useUserTagsQuery = <TData = UserTagsQuery, TError = unknown>(
  variables?: UserTagsQueryVariables,
  options?: UseQueryOptions<UserTagsQuery, TError, TData>
) =>
  useQuery<UserTagsQuery, TError, TData>(
    ['userTags', variables],
    fetcher<UserTagsQuery, UserTagsQueryVariables>(UserTagsDocument).bind(
      null,
      variables
    ),
    options
  );
