import * as React from "react";

function SvgCheckmark(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      {...props}
    >
      <path
        data-name="Path 50"
        d="M25.16 1.17A23.86 23.86 0 1049 25 23.85 23.85 0 0025.16 1.17z"
        fill="#2196f3"
      />
      <path
        data-name="Path 51"
        d="M37.25 20L24.32 32.89a2 2 0 01-2.81 0l-6.46-6.46a2 2 0 112.81-2.81l5.06 5.06 11.52-11.53A2 2 0 0137.26 20z"
        fill="#fafafa"
      />
    </svg>
  );
}

export default SvgCheckmark;
