import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import {
  removeTagFromMultiArticleFromContext,
  removeTagFromSingleArticleFromContext,
} from "../../util/removeTagFromContextUtil";
// import { UserContext } from "../allContexts";
import { useUserContext } from "../allContexts";

export const CompanyUploadsContext = createContext();

export function CompanyUploadsProvider(props) {
  // const { userState } = useContext(UserContext);
  const { userState } = useUserContext();
  const [companyUploads, setCompanyUploads] = useState([]);
  const [companyUploadsDaysOnState, setCompanyUploadsDaysOnState] = useState(0);
  let requestCount = 0; // debounce get request variable

  const fetchCompanyUploads = async ({ days = 14, globalFilterDate }) => {
    if (
      globalFilterDate * 2 > companyUploadsDaysOnState ||
      (companyUploadsDaysOnState !== "all" && globalFilterDate === "all")
    ) {
      let oldestSortDateOnState;
      if (companyUploads.length === 0) {
        oldestSortDateOnState = Math.round(new Date().getTime() / 1000);
      } else {
        oldestSortDateOnState =
          companyUploads[companyUploads.length - 1]._source.sortDate;
      }

      try {
        requestCount++;

        // TODO: PERFORMANCE UPGRADE ON PROMISES BELOW

        // Text uploads
        let companyTextUploadsResponse = await axios.get(
          "/api/newsData/notes/shared",
          {
            params: {
              days: days,
              oldestSortDateOnState: oldestSortDateOnState,
              requestCount: requestCount,
            },
          }
        );
        // url uploads
        let companyUploadsResponse = await axios.get("/api/newsData/shared", {
          params: {
            days: days,
            oldestSortDateOnState: oldestSortDateOnState,
            requestCount: requestCount,
          },
        });

        const textRequestCountRes = Number(
          companyTextUploadsResponse.data.requestCount
        );

        // checking request count so we only take in the most recent request and not multiple requests (creating multiple articles in the context)
        if (textRequestCountRes === requestCount) {
          setCompanyUploadsDaysOnState(days);

          setCompanyUploads([
            ...companyUploads,
            ...companyUploadsResponse.data.data,
            ...companyTextUploadsResponse.data.data,
          ]);
        }
      } catch (err) {
        console.log("err: ", err);
      }
    } else {
      // console.log(
      //   "company uploads isn't fetching because it already has it all on state"
      // );
    }
  };

  // TODO: REMOVE BEFORE BEING PUT INTO PRODUCTION
  // useEffect(() => {
  //   console.log("company :", companyUploads);
  // }, [companyUploads]);

  const clearCompanyUploadsContext = () => {
    setCompanyUploads([]);
    setCompanyUploadsDaysOnState(0);
  };

  const userClickedLikeCompany = (news) => {
    axios
      .post("/api/newsData/like", news)
      .then(async (res) => {
        if (res.status === 200) {
          // console.log("res company context", res)
          let updatedCompanyArray = await companyUploads.map((article) => {
            if (res.data.value._id === article._id) {
              return res.data.value;
            } else {
              return article;
            }
          });
          setCompanyUploads(updatedCompanyArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userClickedDislikeCompany = (news) => {
    axios
      .post("/api/newsData/dislike", news)
      .then(async (res) => {
        if (res.status === 200) {
          let updatedCompanyArray = await companyUploads.map((article) => {
            if (res.data.value._id === article._id) {
              return res.data.value;
            } else {
              return article;
            }
          });
          setCompanyUploads(updatedCompanyArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // delete shared article
  const deleteSharedItem = (article) => {
    let updatedSharedArray;

    const WhichDb =
      article._source.orig_url === "No url for this file" ? "text" : "url";
    const data = {
      WhichDb: WhichDb,
      articleId: article._id,
      articleTitle: article._source.title,
      privateOrNot: "not",
    };

    axios
      .post("/api/articles", data)
      .then(async (res) => {
        if (res.status === 200) {
          updatedSharedArray = await companyUploads.filter(
            (companyArticle) => companyArticle._id !== article._id
          );
          setCompanyUploads(updatedSharedArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addOneUploadedCompArticle = (article) => {
    setCompanyUploads([article, ...companyUploads]);
  };
  const addMultipleUploadedCompArticles = (articles) => {
    const updatedUploads = [...articles, ...companyUploads];
    setCompanyUploads(updatedUploads);
  };

  const removeCompanyTags = (tag, articleId = null) => {
    const updatedCompanyUploads = removeTagFromMultiArticleFromContext(
      tag,
      companyUploads,
      // user.data._id
      userState._id
    );
    setCompanyUploads(updatedCompanyUploads);
  };

  const removeSingleCompanyTags = (tag, articleId, articles, userId) => {
    const updatedCompanyUploads = removeTagFromSingleArticleFromContext(
      tag,
      articleId,
      articles,
      userId
    );
    setCompanyUploads(updatedCompanyUploads);
  };

  const addSingleCompanyTag = (tagToAdd, articleId, userId) => {
    const updated = companyUploads.map((article) => {
      if (article._id === articleId) {
        if (!article.tags) {
          article.tags = {};
        }
        if (!article.tags[userId]) {
          article.tags[userId] = [];
        }
        article.tags[userId].push(tagToAdd);
      }
      return article;
    });
    setCompanyUploads(updated);
  };

  const updateCompanyArticle = (article) => {
    const updatedArticles = companyUploads.map((currentArt) => {
      if (currentArt._id === article._id) {
        return article;
      }
      return currentArt;
    });
    setCompanyUploads(updatedArticles);
  };

  return (
    <CompanyUploadsContext.Provider
      value={{
        fetchCompanyUploads,
        companyUploads,
        companyUploadsDaysOnState,
        clearCompanyUploadsContext,
        userClickedLikeCompany,
        userClickedDislikeCompany,
        deleteSharedItem,
        addOneUploadedCompArticle,
        addMultipleUploadedCompArticles,
        removeCompanyTags,
        removeSingleCompanyTags,
        addSingleCompanyTag,
        updateCompanyArticle,
      }}
    >
      {props.children}
    </CompanyUploadsContext.Provider>
  );
}

export const useCompanyUploadsContext = () => useContext(CompanyUploadsContext);

// NOTES:
// url to get company wide text uploads = "https://app.nowigence.ai/api/newsData/notes/shared"
