import DropdownDisplayBelow from "../../../DropdownDisplayBelow/DropdownDisplayBelow";

// Data
import { useSearchContext } from "../../../../context/allContexts";

// TYPES
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";

// styles
import styles from "./filters.module.scss";
import { FC } from "react";

interface Props {
  openFilter: string;
  setOpenFilter: (arg: string) => void;
}

const OutcomeFilter: FC<Props> = ({ openFilter, setOpenFilter }) => {
  const { searchDispatcher, searchState } = useSearchContext();

  return (
    <div
      className={styles.outcomeFilterWrapper}
      data-is-open={`${openFilter === "outcome"}`}
    >
      <DropdownDisplayBelow
        selectableItems={["Informational", "Opportunity", "Risk"]}
        isDropdownOpen={openFilter === "outcome"}
        toggleDropdown={() =>
          openFilter === "outcome"
            ? setOpenFilter("")
            : setOpenFilter("outcome")
        }
        dropdownDisplay={"Outcomes"}
        selectionDisplay={searchState?.outcomeChoice}
        select={(outcome) =>
          searchDispatcher({
            type: SearchContextActions.SET_OUTCOME_CHOICE,
            outcome: outcome,
          })
        }
        unselect={() =>
          searchDispatcher({
            type: SearchContextActions.SET_OUTCOME_CHOICE,
            outcome: null,
          })
        }
        makeSearchable={false}
      />
    </div>
  );
};

export default OutcomeFilter;
