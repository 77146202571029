import React, { FC, useEffect, useReducer } from "react";

// CONTEXT
import { useSearchContext, useUserContext } from "../../../context/allContexts";

// HELPER FUNCTION
import { getRiskOrOppFunc } from "../getRiskOrOppFunc/getRiskOrOppFunc";

// COMPONENTS
import NoRisksOrOppMessage from "../NoRisksOrOppMessage/NoRisksOrOppMessage";
import AddToNotebookBtn from "../../../Components/AddToNotebookBtn/AddToNotebookBtn";

import styles from "./AMOpp.module.scss";
import {
  IsRiskOrOpp,
  useArticlesTopFiveRisksAndOppsQuery,
} from "../../../../codeGenFE";
import { v4 as uuidv4 } from "uuid";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../Components/LoadingCube/LoadingCube";

interface Props {
  topic: string;
}

const AMOpp: FC<Props> = ({ topic }: Props) => {
  const { data, status, error } = useArticlesTopFiveRisksAndOppsQuery({
    inputOpts: { isRiskOrOpp: IsRiskOrOpp.Opportunities, topic },
  });

  const articleData = data?.articlesTopFiveRisksAndOpps.risksAndOpps;

  const topOpps = articleData?.map((a) => a?.shortDesc);

  if (status === "loading") {
    return (
      <div
        className={styles.AMRiskWrapper}
        style={{
          position: "relative",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingCube
          height={100}
          width={100}
          hasBackground={false}
          theme={LoadingCubeTheme.LIGHT}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className={styles.AMRiskWrapper}
        style={{
          position: "relative",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NoRisksOrOppMessage error={`${error}`} theme="dark" />
      </div>
    );
  }

  if (!topOpps || topOpps.length === 0) {
    return (
      <div
        className={styles.AMOppWrapper}
        style={{
          position: "relative",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NoRisksOrOppMessage
          error={`No Opportunities found for ${topic}`}
          theme="dark"
        />
      </div>
    );
  }

  return (
    <div className={styles.AMOppWrapper}>
      <div className={styles.shortDesc__div}>
        {topOpps.map((opp) => {
          return (
            <li key={uuidv4()} className={styles.shortDesc__li}>
              <div>
                {opp && <AddToNotebookBtn content={opp} style={"unstyled"} />}
              </div>
              {opp}
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default AMOpp;
