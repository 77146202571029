import { useUserProfileContext } from "../../../context/allContexts/UserProfileContext";
import UPSearchBar from "./UPSearchBar/UPSearchBar";
import UPTags from "./UPTopicsOrTags/UPTags/UPTags";
import { UPActiveMode, UserProfileActionChoices } from "../userProfileTypes";
import ComponentMessagingOverlay from "../../../Components/ComponentMessagingOverlay/ComponentMessagingOverlay";
import { customErrorHandler } from "../../../../utils/customErrorHandler";
import UPTopics from "./UPTopicsOrTags/UPTopics/UPTopics";

import styles from "./UPTopicCard.module.scss";

const UPTopicCard = () => {
  // @ts-ignore
  const { userProfileDispatcher, userProfileState } = useUserProfileContext();
  const { activeMode, errorMsg, tags, topics } = userProfileState;

  return (
    <div className={styles.topicCardWrapper}>
      <div className={styles.topicsHeaderWrapper}>
        <div className={styles.topicsNTagsSeperator}>
          <div className={styles.topicsNTagsWrapper}>
            <button
              className={`${styles.topicsOrTags_a} ${
                activeMode === UPActiveMode.SEARCH_TOPICS ? styles.active : null
              }`}
              onClick={() =>
                userProfileDispatcher({
                  type: UserProfileActionChoices.CHANGE_MODE,
                  activeMode: UPActiveMode.SEARCH_TOPICS,
                })
              }
            >
              Your Topics
            </button>
            <button
              className={`${styles.topicsOrTags_a} ${
                activeMode !== UPActiveMode.SEARCH_TOPICS ? styles.active : null
              }`}
              onClick={() =>
                userProfileDispatcher({
                  type: UserProfileActionChoices.CHANGE_MODE,
                  activeMode: UPActiveMode.SEARCH_TAGS,
                })
              }
            >
              Article Tags
            </button>
          </div>
          <div className={styles.funTagsAndTopicsStats}>
            {activeMode === UPActiveMode.SEARCH_TOPICS ? (
              <div className={styles.funTagsAndTopicsStats_container}>
                <p className={styles.funTagsAndTopicsStats}>You have </p>
                <p className={styles.funTagsAndTopicsStats}>
                  <span>{topics.length}</span> topics
                </p>
              </div>
            ) : (
              <div className={styles.funTagsAndTopicsStats_container}>
                <p className={styles.funTagsAndTopicsStats}>You have </p>
                <p className={styles.funTagsAndTopicsStats}>
                  <span>{tags?.length ? tags.length : 0}</span> tags
                </p>
              </div>
            )}
          </div>
        </div>
        <UPSearchBar />
      </div>
      {activeMode !== UPActiveMode.SEARCH_TOPICS && <UPTags />}
      {activeMode === UPActiveMode.SEARCH_TOPICS && <UPTopics />}
      {errorMsg && (
        <ComponentMessagingOverlay
          close={() =>
            userProfileDispatcher({
              type: UserProfileActionChoices.SET_ERROR_MESSAGE,
              errorMsg: "",
            })
          }
          message={customErrorHandler(errorMsg)}
          buttonText={"Okay"}
          showX={true}
        />
      )}
    </div>
  );
};

export default UPTopicCard;
