import React, { Dispatch, FC } from "react";
import { ACDisplaySingleTag } from "../ACDisplaySingleTag";
import {
  ACDisplayTagsActionChoices,
  ACDisplayTagsActions,
  Mode,
} from "../ACDisplayTags";

import styles from "../ACDisplayTags.module.scss";

interface Props {
  displayed: string[];
  dispatcher: Dispatch<ACDisplayTagsActions>;
  mode: Mode;
}

const TagsList: FC<Props> = ({ displayed, dispatcher, mode }) => {
  return (
    <ul className={styles.tags__UL}>
      {displayed &&
        displayed.map((t: string, id: number) => (
          <ACDisplaySingleTag
            key={id}
            t={t}
            showDeleteConfirmation={() =>
              dispatcher({
                type: ACDisplayTagsActionChoices.SHOW_CONFIRM_DELETE_TAG,
                tag: t,
                showConfirmation: true,
              })
            }
            showX={mode === Mode.DELETE}
          />
        ))}
    </ul>
  );
};

export default TagsList;
