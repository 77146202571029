import React, { useReducer, createContext, useContext, useEffect } from "react";
import { useUserContext } from "./UserContext";
import axios from "axios";

const serviceReducer = (state, action) => {
  switch (action.type) {
    case "updateService":
      return {
        ...state,
        serviceData: action.value,
      };

    case "toggleModal":
      return {
        ...state,
        tPIServiceErrorModal: action.value,
      };

    case "error":
      return {
        ...state,
        serviceMessage: action.serviceMessage,
        tPIServiceErrorModal: action.value,
      };

    case "noTPIService":
      return {
        ...state,
        noServiceMessage: action.noServiceMessage,
        tPINoService: action.value,
      };
  }
  return state;
};

const initState = {
  serviceData: [],
  tPIServiceErrorModal: false,
  serviceMessage: "",
  tPINoService: false,
  noServiceMessage: "",
};
export const TPIServiceContext = createContext();

export function TPIServiceProvider(props) {
  const { userState } = useUserContext();
  const [serviceState, serviceDispatch] = useReducer(serviceReducer, initState);
  const {
    serviceData,
    tPIServiceErrorModal,
    serviceMessage,
    tPINoService,
    noServiceMessage,
  } = serviceState;

  useEffect(() => {
    if (userState.isReady) {
      const fetchAllServices = async () => {
        try {
          //  const res =  await axios.get(`https://bison.nowigence.ai:8443/integrations/config/user?userId=${userState._id}&comp=${userState.company}&admin=${userState.admin === true ? 'Y':'N'}`);
          const res = await axios.get(
            `https://fire.nowigence.ai/integrations/config/user?userId=${
              userState._id
            }&comp=${userState.company}&admin=${
              userState.admin === true ? "Y" : "N"
            }`
          );
          let data = res.data.services;
          let selected = { isSelected: true };
          let services = data.map((value) => {
            let updatedData = { ...value, ...selected };
            return updatedData;
          });
          serviceDispatch({ type: "updateService", value: services });
          if (services.length === 0) {
            serviceDispatch({
              type: "noTPIService",
              noServiceMessage:
                "There are no third party integrations available.",
              value: !tPINoService,
            });
          }
        } catch (err) {
          serviceDispatch({
            type: "error",
            serviceMessage: "Something went wrong",
            value: !tPIServiceErrorModal,
          });
        }
      };
      if (userState.isReady) {
        fetchAllServices();
      }
    }
  }, [userState]);

  return (
    <TPIServiceContext.Provider
      value={{
        serviceData,
        serviceDispatch,
        tPIServiceErrorModal,
        serviceMessage,
        tPINoService,
        noServiceMessage,
      }}
    >
      {props.children}
    </TPIServiceContext.Provider>
  );
}

export const useTPIServiceContext = () => {
  return useContext(TPIServiceContext);
};
