import { useState, useEffect, FC } from "react";
import { useStateLabel } from "../../../hooks/stateLabeler";

// components
import TipsModal from "./TipsModal/TipsModal";
// import AddedPopUp from "./AddedPopUp/AddedPopUp";
// import CountryList from "./CountryList/CountryList";
import TopicNewsPreview from "./TopicNewsPreview/TopicNewsPreview";
// import Fuse from "fuse.js";

// country data
import { CountryData } from "../../../util/countryData";

// context
import { useViewPortContext } from "../../../context/allContexts";
import { useUserProfileContext } from "../../../context/allContexts/UserProfileContext";

// styles
import styles from "./UPInputs.module.scss";

// icons
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { AiOutlineArrowDown } from "react-icons/ai";

import { UserProfileActionChoices } from "../userProfileTypes";
import {
  RelationText,
  RelationType,
  RelTextAndTypeCombinations,
} from "../../../Components/QuickUploadBtn/QuickUpload/QuickUploadTypes";
import { Maybe, Topic } from "../../../../codeGenFE";
import { useQueryClient } from "react-query";
import ComponentMessagingOverlay from "../../../Components/ComponentMessagingOverlay/ComponentMessagingOverlay";
import { customErrorHandler } from "../../../../utils/customErrorHandler";
import MultiLevelDropdown from "../../../Components/MultiLevelDropdown/MultiLevelDropdown";
import CountryListDropdown from "../../../Components/CountryListDropdown/CountryListDropdown";

interface Props {}
export const UPInputs: FC<Props> = () => {
  const qClient = useQueryClient();

  const {
    // @ts-ignore
    userProfileState,
    // @ts-ignore
    userProfileDispatcher,
    // @ts-ignore
    availableTopics,
    // @ts-ignore
    addTopicMutation,
    // @ts-ignore
    UPIsFetching,
    // @ts-ignore
    topicInputError,
    // @ts-ignore
    addTopicReset,
    // @ts-ignore
    editTopicMutation,
    // @ts-ignore
    editTopicReset,
  } = useUserProfileContext();
  const {
    topicToMonitor,
    excludedTopicInput,
    excludedTopicsArray,
    isRelationDropVisible,
    relationship,
    countryArray,
    topicId,
  } = userProfileState;
  // @ts-ignore
  // const { width } = useViewPortContext();

  const [tipsModal, setTipsModal] = useStateLabel(false, "tipsModal");
  const [showPreviewModal, setShowPreviewModal] = useStateLabel(
    false,
    "showPreviewModal"
  );

  useEffect(() => {
    qClient.removeQueries("Fetch_Topics_From_Python");
    setShowPreviewModal(false);
  }, [topicToMonitor]);

  enum UiError {
    TOPIC_EMPTY,
    DUPLICATE_TOPIC,
    RELATIONSHIP,
  }

  const [uiErrors, setUiErrors] = useState<Maybe<UiError>[]>([]);

  useEffect(() => {
    setUiErrors([]);
  }, [topicId]);

  function topicNameOnlyDupCheck(topic: string) {
    let dup = false;
    if (availableTopics) {
      availableTopics.forEach((t: Topic) => {
        if (t.topic.toLowerCase() === topic.toLowerCase()) dup = true;
      });
    }
    return dup;
  }

  function topicDupCheckWithId(name: string, id: string) {
    let dup = false;
    if (availableTopics) {
      availableTopics.forEach((t: Topic) => {
        if (t.id !== id && t.topic.toLowerCase() === name.toLowerCase())
          dup = true;
      });
    }
    return dup;
  }

  async function addTopic() {
    // UI Error checks
    const errors: Maybe<UiError>[] = [];
    if (topicToMonitor.trim() === "") errors.push(UiError.TOPIC_EMPTY);
    if (topicNameOnlyDupCheck(topicToMonitor))
      errors.push(UiError.DUPLICATE_TOPIC);
    if (relationship.relType === RelationType.DEFAULT)
      errors.push(UiError.RELATIONSHIP);
    if (errors.length > 0) {
      setUiErrors(errors);
      return;
    }
    setUiErrors([]);

    // Run mutation
    await addTopicMutation({
      inputOpts: {
        // if using default relation type OTHER (user did not select any relation), then use relation text for OTHER
        relationshipText: (relationship.relText === RelationText.SELECT_HOW_THIS_TOPIC_RELATES_TO_YOU && relationship.relType === RelationType.OTHER)
          ? RelationText.OTHER
          : relationship.relText,
        relationshipType: relationship.relType,
        topic: topicToMonitor,
        // TODO: Temp removing dynamic countries and hard coding it (removing
        // option for user to even select a country for now.)
        // country: countryArray,
        country: [
          { countryName: "United States - English", marketCode: "en-US" },
        ],
        excludedKeywords: excludedTopicsArray,
      },
    });
  }

  async function editTopic() {
    // UI Error checks
    const errors: Maybe<UiError>[] = [];
    if (topicToMonitor.trim() === "") errors.push(UiError.TOPIC_EMPTY);
    if (topicDupCheckWithId(topicToMonitor, topicId))
      errors.push(UiError.DUPLICATE_TOPIC);
    if (relationship.relType === RelationType.DEFAULT)
      errors.push(UiError.RELATIONSHIP);
    if (errors.length > 0) {
      setUiErrors(errors);
      return;
    }
    setUiErrors([]);

    // Run mutation
    await editTopicMutation({
      inputOpts: {
        id: topicId,
        relationshipText: relationship.relText,
        relationshipType: relationship.relType,
        topic: topicToMonitor,
        country: countryArray,
        excludedKeywords: excludedTopicsArray,
      },
    });
  }

  // COUNTRY HELPERS
  // const deleteClickedCountry = (name: string) => {
  //   const update = countryArray.filter(
  //     (c: CountryData) => c.countryName !== name
  //   );
  //   userProfileDispatcher({
  //     type: UserProfileActionChoices.SET_COUNTRY_ARRAY,
  //     countryArray: update,
  //   });
  // };

  interface Props1 {
    excludedTopicsArray: any;
  }
  const ExcludedTopicsList: FC<Props1> = ({ excludedTopicsArray }) => {
    return excludedTopicsArray
      ? excludedTopicsArray.map((word: string, idx: number) => {
          return (
            <div className={styles.wordAndSpanWrapper} key={idx}>
              <p
                onClick={() =>
                  userProfileDispatcher({
                    type: UserProfileActionChoices.REMOVE_EXCLUDED_TOPIC_FROM_ARRAY,
                    payload: word,
                  })
                }
              >
                {word}
                <span>X</span>
              </p>
            </div>
          );
        })
      : null;
  };

  return (
    <div className={styles.inputMenuWrapper}>
      {tipsModal && <TipsModal close={() => setTipsModal(false)} />}
      {topicInputError && (
        <ComponentMessagingOverlay
          close={() => {
            addTopicReset();
            editTopicReset();
          }}
          message={customErrorHandler(topicInputError)}
          showX={true}
        />
      )}
      {showPreviewModal && (
        <TopicNewsPreview
          showPreviewModal={showPreviewModal}
          close={() => {
            qClient.removeQueries("Fetch_Topics_From_Python");
            setShowPreviewModal(false);
          }}
        />
      )}
      {uiErrors.includes(UiError.TOPIC_EMPTY) && (
        <p className={styles.inputError}>
          <AiOutlineArrowDown className={styles.downArrow} />
          This is required
        </p>
      )}
      {uiErrors.includes(UiError.DUPLICATE_TOPIC) && (
        <p className={styles.inputError}>
          <AiOutlineArrowDown className={styles.downArrow} />
          Duplicate, please enter a different topic.
        </p>
      )}
      <label className={`${styles.inputLabel}`}>
        Enter a topic to monitor:
      </label>
      <div className={styles.firstInputWrapper}>
        <input
          type="text"
          placeholder="Enter a topic to monitor"
          // TODO
          value={topicToMonitor}
          // onChange={(e) => handleChange(e)}
          onChange={(e) => {
            setUiErrors(
              uiErrors.filter(
                (err) =>
                  err !== UiError.TOPIC_EMPTY && err !== UiError.DUPLICATE_TOPIC
              )
            );
            userProfileDispatcher({
              type: UserProfileActionChoices.SET_TOPIC_TO_MONITOR,
              topicToMonitor: e.target.value,
            });
          }}
        />
        <button onClick={() => setTipsModal(true)} className={styles.tipButton}>
          Tips
        </button>
      </div>
      <label className={styles.inputLabel}>
        Words to be excluded (optional):
      </label>
      <div className={styles.secondInputWrapper}>
        <MdSubdirectoryArrowRight className={styles.arrIcon} />
        <div className={styles.excludedTopicsListWrapper}>
          <ExcludedTopicsList excludedTopicsArray={excludedTopicsArray} />
          <input
            type="text"
            placeholder="Add exclude topics, use comma to separate each word (Optional)"
            value={excludedTopicInput}
            onChange={(e) =>
              userProfileDispatcher({
                type: UserProfileActionChoices.SET_EXCLUDED_TOPIC_INPUT,
                excludedTopicInput: e.target.value,
              })
            }
            onKeyUp={(e) => {
              if (
                e.key === "Enter" ||
                (e.key === "," && excludedTopicInput.trim() !== "")
              ) {
                userProfileDispatcher({
                  type: UserProfileActionChoices.ADD_EXCLUDED_TOPIC_TO_ARRAY,
                  payload: excludedTopicInput.replace(",", ""),
                });
              }
            }}
            onBlur={(e) => {
              if (excludedTopicInput !== "") {
                userProfileDispatcher({
                  type: UserProfileActionChoices.ADD_EXCLUDED_TOPIC_TO_ARRAY,
                  payload: e.target.value,
                });
              }
            }}
          />
        </div>
      </div>
      {uiErrors.includes(UiError.RELATIONSHIP) && (
        <p className={styles.inputError}>
          <AiOutlineArrowDown className={styles.downArrow} />
          This is required
        </p>
      )}
      <label className={styles.inputLabel}>
        Select how this topic relates to you:
      </label>
      <div
        className={styles.dropDownInputWrapper}
        onMouseLeave={() =>
          userProfileDispatcher({
            type: UserProfileActionChoices.TOGGLE_RELATION_DROPDOWN,
            boolean: false,
          })
        }
        style={{ zIndex: isRelationDropVisible ? 1 : 0 }}
      >
        <MultiLevelDropdown
          display={
            relationship?.relText
              ? `${relationship?.relText?.[0].toUpperCase()}${relationship?.relText?.slice(
                  1
                )}`
              : `Other`
          }
          isOpen={isRelationDropVisible}
          itemsArr={RelTextAndTypeCombinations}
          runDispatch={(type, text) => {
            if (type === RelationType.SELF || type === RelationType.OTHER) {
              userProfileDispatcher({
                type: UserProfileActionChoices.SET_RELATIONSHIP,
                rel: {
                  relText:
                    type === RelationType.SELF
                      ? RelationText.SELF
                      : RelationText.OTHER,
                  relType: type,
                },
              });
            } else {
              userProfileDispatcher({
                type: UserProfileActionChoices.SET_RELATIONSHIP,
                rel: {
                  relText: text as RelationText,
                  relType: type as RelationType,
                },
              });
            }
          }}
          toggleDropdownVis={() =>
            userProfileDispatcher({
              type: UserProfileActionChoices.TOGGLE_RELATION_DROPDOWN,
              boolean: !isRelationDropVisible,
            })
          }
        />
      </div>
      {/*<label className={styles.inputLabel}>Select countries of focus:</label>
      <div className={styles.dropDownInputWrapper}>
        <CountryListDropdown
          selectedCountries={countryArray}
          changeSelectedCountries={(selectedCountries) => {
            userProfileDispatcher({
              type: UserProfileActionChoices.SET_COUNTRY_ARRAY,
              countryArray: selectedCountries,
            });
          }}
        />
        </div>*/}
      {!topicId ? (
        <button
          className={styles.inputMenu__btn}
          onClick={() => {
            addTopic();
          }}
          disabled={UPIsFetching}
        >
          {/* {tagUserMessaging === TagUserMessaging.ADDED && (
            <AddedPopUp text="Added!" />
          )}
          {tagUserMessaging === TagUserMessaging.EDITED && (
            <AddedPopUp text="Edited!" />
          )} */}
          Add to Profile
        </button>
      ) : (
        <>
          <button
            className={styles.inputMenu__btn}
            onClick={editTopic}
            disabled={UPIsFetching}
          >
            Save
          </button>
          <button
            className={styles.cancel__btn}
            onClick={() => {
              setUiErrors([]);
              userProfileDispatcher({
                type: UserProfileActionChoices.RESET_TOPIC_FORM,
              });
            }}
          >
            Cancel
          </button>
        </>
      )}
      <button
        onClick={() => setShowPreviewModal(!showPreviewModal)}
        className={`${styles.inputMenu__btn} ${
          !topicToMonitor ||
          topicToMonitor === "Enter a topic to monitor" ||
          topicToMonitor.trim() === ""
            ? styles.disabled
            : null
        }`}
        disabled={
          !topicToMonitor ||
          topicToMonitor === "Enter a topic to monitor" ||
          topicToMonitor.trim() === ""
        }
      >
        Show Preview
      </button>
    </div>
  );
};

export default UPInputs;

function RelationDisplay({ text, type, width }: any) {
  let display = "";
  if (width > 570) {
    if (text !== "") {
      display = text;
    } else {
      if (type === "other") {
        display = "Other";
      } else {
        display = "Select how this topic relates to you";
      }
    }
  } else {
    if (text !== "Select how this topic relates to you") {
      display = text;
    } else {
      if (type === "other") {
        display = "Other";
      } else {
        display = "";
      }
    }
  }

  return <p className="displayElement">{display}</p>;
}
