import React from "react";

import styles from "./DisplayHarvestDate.module.scss";
import { convertDate } from '../../../../util/MMDDYYwithslashtimestampFormater';
import { useUserContext } from '../../../../context/allContexts/UserContext';

const DisplayHarvestDate = ({ harvestDate, uploadDate, userMetaData }) => {
  const { user } = useUserContext();
  let status = "";

  if (userMetaData) {
    userMetaData.forEach((u) => {
      if (u && u.userId === user._id) {
        if (!u.permanent) {
          status = "feed";
          if(u.sharedBy)
            status = "shared";  
        } else if (u.sharedBy) {
          status = "shared";
        } else {
          status = "private";
        }
      }
    });
  }
  // if (uploadDate) {
  //   return (
  //     <>
  //       <span className={styles.pipe}>|</span>
  //       <span className={styles.text}>{uploadDate}</span>
  //     </>
  //   );
  // }
  // console.log("date :", date);
  // let str = date;
  // console.log("date.length :", date.length);
  const getDate = (userMetaData) => {
    const filteredMeta = userMetaData.find((meta) => meta.userId === user._id)
    return filteredMeta ? convertDate(filteredMeta.date) : ""
  }
  return (
    <>
      <span className={styles.pipe}>|</span>
      {
        status === 'shared' ? 
        <span className={styles.text}>Shared On: {getDate(userMetaData)}</span>
        :
        <span className={styles.text}>{getDate(userMetaData)}</span>
      }
    </>
  );
};

export default DisplayHarvestDate;
