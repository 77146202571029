export function headersApiLevel(fileHeader) {
  fileHeader.append('Content-Type', 'application/json');
  fileHeader.append('Content-Type', 'application/json');
  fileHeader.append('Access-Control-Allow-Origin', '*');
  fileHeader.append('Access-Control-Allow-Credentials', 'true');
  fileHeader.append(
    'Content-Type',
    'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
  );
  fileHeader.append(
    'Access-Control-Allow-Methods',
    'GET,HEAD,OPTIONS,POST,PUT'
  );
  fileHeader.append(
    'Access-Control-Allow-Headers',
    'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
  );
  fileHeader.append(
    'Access-Control-Expose-Headers',
    'Access-Control-Allow-Origin, Access-Control-Allow-Credentials'
  );
  fileHeader.append('Access-Control-Max-Age', '3600');
}
