import { RichUtils } from "draft-js";

import styles from "./BtnControls.module.scss";
import { IoMdQuote } from "react-icons/io";
import { FaCode, FaListUl, FaListOl } from "react-icons/fa";

const BtnControls = ({ update, editorState, activeBlock, activeInline }) => {
  const changeStyle = (style) => {
    update(RichUtils.toggleInlineStyle(editorState, `${style}`));
  };

  const toggleBlockType = (blockType) => {
    update(RichUtils.toggleBlockType(editorState, blockType));
  };

  // const toggleLink = (something) => {
  //   update(RichUtils.toggleLink(editorState, something));
  // };

  const styleOptions = [
    {
      id: "1",
      label: "B",
      style: "BOLD",
      hover: "Bold",
    },
    {
      id: "2",
      label: "I",
      style: "ITALIC",
      hover: "Italic",
    },
    {
      id: "3",
      label: "U",
      style: "UNDERLINE",
      hover: "Underline",
    },
    {
      id: "4",
      label: "S",
      style: "STRIKETHROUGH",
      hover: "Strikethrough",
    },
  ];

  const blockTypeOptions = [
    {
      id: "5",
      label: "H1",
      style: "header-one",
      hover: "H1",
    },
    {
      id: "6",
      label: "H2",
      style: "header-two",
      hover: "H2",
    },
    {
      id: "7",
      label: "H3",
      style: "header-three",
      hover: "H3",
    },
    {
      id: "8",
      label: <IoMdQuote className={styles.btnControls__icon} />,
      style: "blockquote",
      hover: "Blockquote",
    },
    {
      id: "9",
      label: <FaListUl className={styles.btnControls__icon} />,
      style: "unordered-list-item",
      hover: "Unordered List",
    },
    {
      id: "10",
      label: <FaListOl className={styles.btnControls__icon} />,
      style: "ordered-list-item",
      hover: "Ordered List",
    },
    {
      id: "11",
      label: <FaCode className={styles.btnControls__icon} />,
      style: "code-block",
      hover: "Code Block",
    },
  ];

  const isActiveInlineStyle = (style) => {
    if (activeInline.length > 0) {
      let isActiveStyle = false;
      activeInline.forEach((item) => {
        if (item?.[0] === style) {
          isActiveStyle = true;
        }
      });
      return isActiveStyle;
    }
    return false;
  };

  return (
    <>
      {blockTypeOptions.map((option) => {
        return (
          <button
            type="button"
            key={option.id}
            title={`${option.hover}`}
            className={`${
              activeBlock === option.style ? styles.active : null
            } ${styles.btnControls__btn}`}
            onMouseDown={(e) => {
              e.preventDefault();
              toggleBlockType(option.style);
            }}>
            {option.label}
          </button>
        );
      })}
      {styleOptions.map((option) => {
        return (
          <button
            type="button"
            key={option.label}
            title={`${option.hover}`}
            className={`${
              isActiveInlineStyle(option.style) ? styles.active : null
            } ${styles.btnControls__btn} ${styles[option.style]}`}
            onMouseDown={(e) => {
              e.preventDefault();
              changeStyle(option.style);
            }}>
            {option.label}
          </button>
        );
      })}
    </>
  );
};

export default BtnControls;
