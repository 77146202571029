import React, { useState, useReducer, useEffect } from "react";

// Components
import { MdSearch } from "react-icons/md";
import MainNavigation from "../../Components/MainNavigation/MainNavigation";
import AnswerTitle from "./AnswerTitle/AnswerTitle";
import Answer from "./Answer/Answer";
import ActualAnswer from "./ActualAnswer/ActualAnswer";
import AMTopicStats from "./AMTopicStats/AMTopicStats";
import AMComparisonCards from "./AMComparisonCards/AMComparisonCards";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../Components/LoadingCube/LoadingCube";
import InfoButton from "../../../Icons/InfoButton";

// Modals
import AskMeInfoModal from "./AskMeInfoModal/AskMeInfoModal";
import AskMeInfoModal2 from "./AskMeInfoModal/AskMeInfoModal2";
// context
// import { useUserProfileContext } from "../../context/allContexts";
// Images
import askMeRobot from "../../../assets/img/Solomon.png";
import not_foundRobot from "../../../assets/img/not_found.png";
import not_found_updated from "../../../assets/img/not_found_image_updated.png";

import { useRewardsContext, useUserContext } from "../../context/allContexts";

import styles from "./AskMe.module.scss";
import { useAskMe } from "../../../rq-hooks/pythonRestApi/useAskMe";
import TopMenu from "../../Components/TopMenu/TopMenu";
import { Type, useRewardsDetails, useUserRewardLogsMutation, useUserRewardsMutation } from "../../../codeGenFE";

interface State {
  errors: string;
  question: string;
  topic: string;
  askMeInfoModal: boolean;
  askMeInfoModal2: boolean;
}

type Actions =
  | { type: "questionInput"; value: string; input: string }
  | { type: "toggleAskMeInfoModal"; value: boolean }
  | { type: "toggleAskMeInfoModal2"; value: boolean }
  | { type: "change active topic"; topic: string };

const askMeReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "questionInput":
      return {
        ...state,
        [action.input]: action.value,
      };
    case "toggleAskMeInfoModal":
      return {
        ...state,
        askMeInfoModal: action.value,
      };
    case "change active topic":
      return {
        ...state,
        topic: action.topic,
      };
    case "toggleAskMeInfoModal2":
      return {
        ...state,
        askMeInfoModal2: action.value,
      };
  }
};

const initState: State = {
  errors: "",
  question: "",
  topic: "",
  askMeInfoModal: false,
  askMeInfoModal2: false,
};

const AskMe = () => {
  const { user } = useUserContext();
  const { refetch } = useRewardsContext();
  const [askMeState, askMeDispatch] = useReducer(askMeReducer, initState);
  // const {status: rewardDetailsStatus, refetch} = useRewardsDetails({}, {
  //   onSuccess: (data) => {
  //     console.log(data)
  //   }
  // })
  
  // In this function system is adding reward points on the basis of URL upload action 
  // and on success system is refetching updated reward point details  by userRewardsDetails()
  const {mutate: rewardsMutate} = useUserRewardsMutation({
    onSuccess: () => {
      refetch()
    }
  })

  // In this function system is logging reward actions and on success we are mutating 
  // userRewardsMutation()
  const {mutate: rewardsLogMutate} = useUserRewardLogsMutation({
    onSuccess: () => {
      console.log("success")
      rewardsMutate({
        options:{
          type: Type.AskMe,
          meta: {
            question: question,
          }
        }
      })
    }
  })
  const { errors, question, topic, askMeInfoModal, askMeInfoModal2 } =
    askMeState;

  // In this function our system searches for the relevant answers for the question 
  // user ask from python’s api and show us the result either by sharing any matched 
  // article from our database or from web searches and on success system is mutating 
  // userRewardLogsMutation()
  const {
    mutate,
    status,
    isLoading: loading,
    error,
    data: askMeAnswer,
  } = useAskMe({
    onSuccess: () => {
      // rewardsLogMutate({
      //   options: {
      //     type: Type.AskMe
      //   }
      // })
    }
  });

  return (
    <div className={styles.askmeContainer}>
      <div className={styles.topMenu}>
        <TopMenu showSearch={false} />
      </div>
      <div className={styles.leftMenu}>
        <MainNavigation />
      </div>
      <div className={styles.askmeContent}>
        <img src={askMeRobot} className={styles.askMe__img} />
        <div className={styles.header__div}>
          <h1 className={styles.header__h1}>ASK ME</h1>
          <InfoButton
            className={styles.header__infoBtn}
            onClick={() =>
              askMeDispatch({
                type: "toggleAskMeInfoModal",
                value: !askMeInfoModal,
              })
            }
          />
          {/* VIDEO BUTTON GOES HERE */}
        </div>
        {/* <form className={styles.form} onSubmit={(e) => handleSubmit(e)}> */}
        <form
          className={styles.form}
          onSubmit={(e) => {
            e.preventDefault();
            /*handleSubmit(e)*/
          }}
        >
          <div className={styles.askmeInput}>
            <input
              type="text"
              placeholder="Throw me a question"
              name="Question"
              value={question}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  mutate({
                    user_id: user._id,
                    company_id: user.company,
                    question,
                  });
                }
              }}
              onChange={(e) => {
                askMeDispatch({
                  type: "questionInput",
                  input: "question",
                  value: e.target.value,
                });
              }}
              //onChange={e => setQuestion(e.target.value)}x
            />
            <div
              className={styles.iconBG}
              onClick={() => {
                /*handleSubmit*/
                mutate({
                  user_id: user._id,
                  company_id: user.company,
                  question,
                });
              }}
            >
              <MdSearch className={styles.icon} />
            </div>
          </div>
          {errors && <div>{errors}</div>}
        </form>
        {error && (
          <img src={not_found_updated} className={styles.noResultsRobot} />
        )}
        {askMeAnswer && (
          <>
            <div className={styles.wrapper}>
              <AnswerTitle webOrMemory="memAnswer">
                <ActualAnswer
                  // data={askMeAnswer.domainResult}
                  data={askMeAnswer?.internal_results?.main_document}
                  question={question}
                  sub="memAnswer"
                />
              </AnswerTitle>
              <AnswerTitle webOrMemory="memory">
                <Answer
                  webOrMem="memory"
                  data={askMeAnswer?.internal_results}
                />
              </AnswerTitle>
              <AnswerTitle webOrMemory="webAnswer">
                <ActualAnswer
                  // data={askMeAnswer.googleResult}
                  data={askMeAnswer?.external_results?.main_document}
                  question={question}
                  sub="web"
                />
              </AnswerTitle>
              <AnswerTitle webOrMemory="web">
                <Answer webOrMem="web" data={askMeAnswer?.external_results} />
              </AnswerTitle>
            </div>
            <div className={`${styles.header__div} ${styles.marginBtm}`}>
              <h2 className={styles.header__h1}>Related Topic Suggestions</h2>
              <InfoButton
                className={styles.header__infoBtn}
                onClick={() =>
                  askMeDispatch({
                    type: "toggleAskMeInfoModal2",
                    value: !askMeInfoModal2,
                  })
                }
              />
            </div>
            <AMComparisonCards
              // entityInfo={askMeAnswer.entityWikiInfo}
              entityInfo={askMeAnswer?.entity_results}
              chooseTopic={(topic: string) =>
                askMeDispatch({ type: "change active topic", topic: topic })
              }
            />
            {topic && (
              <div className={styles.internalMemory}>
                <AnswerTitle webOrMemory="trending">
                  <AMTopicStats
                    answer={
                      askMeAnswer?.internal_results?.main_document?.answer
                    }
                    topic={topic}
                  />
                </AnswerTitle>
              </div>
            )}
          </>
        )}
        {/* Goes at bottom so we don't have to fight with z index */}
        {loading && (
          <LoadingCube
            theme={LoadingCubeTheme.DARK}
            isFullScreen={true}
            height={150}
            width={150}
          />
        )}
      </div>
      <AskMeInfoModal
        close={() =>
          askMeDispatch({ type: "toggleAskMeInfoModal", value: false })
        }
        showAskMeInfoModal={askMeInfoModal}
      />
      <AskMeInfoModal2
        close={() =>
          askMeDispatch({ type: "toggleAskMeInfoModal2", value: false })
        }
        showAskMeInfoModal={askMeInfoModal2}
      />
    </div>
  );
};

export default AskMe;
