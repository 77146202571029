import React, { useState, FC } from "react";

import { AiOutlineDown, AiOutlineMail } from "react-icons/ai";
import FeedbackHack from "../../../../Components/FeedbackHack/FeedbackHack";

import { copyText } from "../../../../util/copyText";

import styles from "./InsightsNer.module.scss";

interface Props {
  ner: any[];
  label: string;
  category: string;
}

const InsightsNer: FC<Props> = ({ ner, label, category }) => {
  const [insightsDropdown, setInsightsDropdown] = useState(false);

  const Func = (Arr: any) => {
    let cursor;
    let displayed;
    if (!insightsDropdown) {
      cursor = 2;
    } else if (insightsDropdown) {
      displayed = Arr;
    }
    displayed = Arr.slice(0, cursor);
    return (
      <ul className={styles.insights__UL}>
        {displayed &&
          displayed.map((organizations: any, id: any) => (
            <SingleLI
              key={id}
              organizations={organizations}
              category={category}
            />
          ))}
      </ul>
    );
  };

  return (
    <>
      <div
        className={styles.titleArrowWrapper}
        onClick={() => setInsightsDropdown(!insightsDropdown)}
      >
        <p>{label}</p>
        {ner && ner.length > 2 && (
          <AiOutlineDown className={styles.arrowIcon} />
        )}
      </div>
      {Func(ner)}
    </>
  );
};

interface Props1 {
  organizations: string;
  category: string;
}

const SingleLI: FC<Props1> = ({ organizations, category }) => {
  const [copiedText, setCopiedText] = useState(false);
  const [openFBH, setOpenFBH] = useState(false);

  if (organizations === "Email Nowigence") {
    return (
      <li className={styles.insights__companys}>
        <a
          className={styles.nowigenceTeam__a}
          href="mailto:marcus.woodard@nowigence.com?subject=I%20need%20help%20searching%20for%20some%20of%20my%20data&body=Hello%20Marcus,%0d%0a%0d%0aPlease%20let%20us%20know%20what%20you%20did%20to%20try%20and%20find%20the%20article%20you%20were%20looking%20for%20and%20what%20you%20remember%20the%20article%20being%20about%20.%20%20We%20will%20get%20back%20to%20you%20as%20soon%20as%20we%20can!"
        >
          Nowigence Team{" "}
          <AiOutlineMail className={styles.nowigenceTeam__icon} />
        </a>
      </li>
    );
  }

  const copyFunc = (text: any) => {
    copyText(text);
    setCopiedText(true);

    setTimeout(() => {
      setCopiedText(false);
    }, 450);
  };

  return (
    <li
      className={`${styles.insights__companys} ${
        copiedText ? styles.copiedOverlay : null
      }`}
      onClick={() => copyFunc(organizations)}
    >
      {organizations}{" "}
      <span
        onClick={(e) => {
          e.stopPropagation();
          setOpenFBH(true);
        }}
      >
        X
      </span>
      <FeedbackHack
        isOpen={openFBH}
        itemClicked={organizations.toLowerCase()}
        itemCategory={category}
        close={() => setOpenFBH(false)}
      />
    </li>
  );
};

export default InsightsNer;
