import {
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
  Editor,
  Modifier,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  RichUtils,
  SelectionState
} from "draft-js";
import "draft-js/dist/Draft.css";
import React, { Dispatch, FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { Note, useUpdateNoteMutation } from "../../../codeGenFE";
import { customErrorHandler } from "../../../utils/customErrorHandler";
import {
  SingleNoteActionChoices,
  SingleNoteActions,
  SingleNoteState,
} from "../Notebook/SingleNote/SingleNote";
// Components
import BtnControls from "./BtnControls/BtnControls";
//
import {
  DraftLink,
  findLinkEntities,
  LinkControls,
} from "./decorators/linkDecorator";
import styles from "./draftEditor.module.scss";
import {stateToHTML} from 'draft-js-export-html';
import GPT3Tokenizer from 'gpt3-tokenizer';

interface Props {
  note: Note;
  setSaving: Dispatch<SingleNoteActions>;
  singleNoteState: SingleNoteState;
  currentStateData: (state: any) => void;
}

const DraftEditor: FC<Props> = ({ note, setSaving, currentStateData, singleNoteState }) => {
  const content = note?.richText.content;
  const [fetchedChatGpt, setFetchedChatGpt] = useState(false)

  // ========== Save content to DB ========== //
  function saveContent() {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    let html = stateToHTML(contentState, {
      defaultBlockTag: 'div',
    });
    currentStateData(html)
    mutate({
      updates: {
        noteId: note._id,
        title: note._source?.title ? note._source?.title : "",
        richText: {
          content: rawContentState,
          metaData: note.richText.metaData,
        },
      },
    });
  }

  const qClient = useQueryClient();
  const { mutate, error, isLoading } = useUpdateNoteMutation({
    onSuccess: (data) => {
      const updatedNote = data?.updateNote;
      if (updatedNote) {
        if (!showURLInput) {
          qClient.setQueryData(["getSingleNote", { noteId: note._id }], {
            getSingleNote: { ...updatedNote },
          });
        }
        setSaving({
          type: SingleNoteActionChoices.SET_NOTE_ERROR_MSG,
          msg: null,
          retry: () => {},
        });
      }
    },
    onError: (error) => {
      console.log(`Error logs from notebook error ${error}`)
      setSaving({
        type: SingleNoteActionChoices.SET_NOTE_ERROR_MSG,
        msg: customErrorHandler(error),
        retry: () => {
          const contentState = editorState.getCurrentContent();
          const rawContentState = convertToRaw(contentState);
          mutate({
            updates: {
              noteId: note._id,
              title: note._source?.title ? note._source?.title : "",
              richText: {
                content: rawContentState,
                metaData: note.richText.metaData,
              },
            },
          });
        },
      });
    },
  });

  useEffect(() => {
    if(fetchedChatGpt) {
      saveContent()
      setSaving({type: SingleNoteActionChoices.SET_CHAT_GPT_TYPING, payload: false})
    }
  }, [fetchedChatGpt])

  useEffect(() => {
    if(singleNoteState.chatGPTtext) {
      const prevContent = editorState.getCurrentContent()
      let text: string = singleNoteState.chatGPTtext
      const textArray: any = text.split(" ");
      var i = 0;
      let newEditorState: any = null;
      (function loop() {
          if (++i <= textArray.length) {
            let contentState = null
            if(!newEditorState){
              contentState = editorState.getCurrentContent();
            } else {
              contentState = newEditorState.getCurrentContent();
            }
            const blockMap = contentState.getBlockMap();
            const key = blockMap.last().getKey();
            const length = blockMap.last().getLength();
            const selection = new SelectionState({
                anchorKey: key,
                anchorOffset: length,
                focusKey: key,
                focusOffset: length,
            });
            //insert text at the selection created above 
            const textWithInsert = Modifier.insertText(contentState, selection, ` ${textArray[i-1]} ` || "");
            let editorWithInsert: any = null
            if(!newEditorState){
              editorWithInsert = EditorState.push(editorState, textWithInsert, 'insert-characters');
            } else {
              editorWithInsert = EditorState.push(newEditorState, textWithInsert, 'insert-characters');
            }
            //also focuses cursor at the end of the editor 
            newEditorState = EditorState.moveSelectionToEnd(editorWithInsert);
            setEditorState(newEditorState);
            setTimeout(loop, 100);
          } else {
            setFetchedChatGpt(true)
          }
      })();
    }
  }, [singleNoteState.chatGPTtext])

  // Turn on or off the loading message
  useEffect(() => {
    if (isLoading) {
      setSaving({ type: SingleNoteActionChoices.SET_SAVING, payload: true });
    } else {
      setSaving({ type: SingleNoteActionChoices.SET_SAVING, payload: false });
    }
  }, [isLoading]);

  // Pull data from storage to pre fill in the editor.
  useEffect(() => {
    if (content && content.entityMap && content.blocks) {
      setEditorState(
        EditorState.createWithContent(
          // @ts-ignore
          convertFromRaw(content),
          decorators
        )
      );
    }
  }, [note]);

  // ========== LOCAL EDITOR STATE ========== //
  const decorators = new CompositeDecorator([
    // FOR LINKS
    {
      strategy: findLinkEntities,
      component: DraftLink,
    },
  ]);

  // For managing editor focus
  const editorRef = useRef();

  // =============== FOR LINKS =============== //
  const [showURLInput, setShowURLInput] = useState(false);
  // =============== END FOR LINKS =============== //

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(decorators) // decorator is for links as well
  );

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    let html = stateToHTML(contentState, {
      defaultBlockTag: 'div',
    });
    const rawContentState = convertToRaw(contentState);
    if(rawContentState.blocks?.length > 0) {
      const tokenizer = new GPT3Tokenizer({ type: 'gpt3' }); // or 'codex'
      const blockArray = rawContentState.blocks?.map((block: any) => block.text)
      const encoded: { bpe: number[]; text: string[] } = tokenizer.encode(`show me more points like these: ${blockArray.join("")}`);
      if(encoded.text?.length < 97) {
        setSaving({ type: SingleNoteActionChoices.SET_EXPAND_MORE, payload: {status: true, rawData: rawContentState.blocks} });
      } else {
        setSaving({ type: SingleNoteActionChoices.SET_EXPAND_MORE, payload: {status: false, rawData: []} });  
      }
    } else {
      setSaving({ type: SingleNoteActionChoices.SET_EXPAND_MORE, payload: {status: false, rawData: []} });
    }
    currentStateData(html)
  }, [editorState])

  const showActiveInlineStyles = () => {
    const currentStyle = editorState.getCurrentInlineStyle();
    // @ts-ignore
    const styleList = currentStyle._map._list._tail;
    if (styleList) {
      return styleList.array;
    } else {
      return [];
    }
  };

  const handleKeyCommand = (command: any, editorState: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      update(newState);
      return "handled";
    }
    return "not-handled";
  };

  const update = (editorState: any) => {
    setEditorState(editorState)
  };

  const onTab = (e: any) => {
    const maxDepth = 4;
    update(RichUtils.onTab(e, editorState, maxDepth));
  };

  function myKeyBindingFn(e: any) {
    // FOR LINKS
    if (
      e.keyCode === 76 /* l key and  */ &&
      KeyBindingUtil.hasCommandModifier(e) // command (ctrl or command key) are both being pressed
    ) {
      e.preventDefault();
      setShowURLInput(true);
    }
    return getDefaultKeyBinding(e);
  }

  const pluarisBlockQuoteStyle = (contentBlock: any) => {
    const type = contentBlock.getType();
    switch (type) {
      case "blockquote":
        return `${styles.blockQuote}`;
      case "unordered-list-item":
        return `${styles.ulItem}`;
      case "ordered-list-item":
        return `${styles.olItem}`;
      case "code-block":
        return `${styles.codeBlock}`;
      case "header-one":
        return `${styles.headerOne}`;
      case "header-two":
        return `${styles.headerTwo}`;
      case "header-three":
        return `${styles.headerThree}`;
      default:
        return `${styles.default}`;
    }
  };

  function focusEditor() {
    if (editorRef?.current) {
      // @ts-ignore
      editorRef.current.focus();
    }
  }
  return (
    <div className={styles.editorWrapper} onBlur={saveContent}>
      <div className={styles.btnWrapper}>
        <BtnControls
          update={update}
          editorState={editorState}
          activeBlock={RichUtils.getCurrentBlockType(editorState)}
          activeInline={showActiveInlineStyles()}
        />
        <LinkControls
          editorState={editorState}
          update={update}
          showURLInput={showURLInput}
          setShowURLInput={setShowURLInput}
          focusEditor={focusEditor}
        />
      </div>
      <div className={styles.doubleLine} />
      <div
        id={"editorPdfTargetContent"}
        className={`${styles.content__container} ${styles.safari_only}`}
      >
        <Editor
          editorState={editorState}
          onTab={onTab}
          handleKeyCommand={handleKeyCommand}
          onChange={update}
          blockStyleFn={pluarisBlockQuoteStyle}
          spellCheck={true}
          // @ts-ignore
          decorators={decorators}
          keyBindingFn={myKeyBindingFn}
          placeholder={
            "You can add content \nsimply by clicking plus button(s)!"
          }
          onBlur={saveContent}
          // @ts-ignore
          ref={editorRef}
        />
      </div>
    </div>
  );
};

export default DraftEditor;
