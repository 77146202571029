import { Dispatch, FC, SetStateAction } from "react";
import { Maybe } from "../../../../../codeGenFE";

import styles from "./DisplayLinkOrModal.module.scss";

interface Props {
  orig_url: string;
  title: string;
  sharedBy: string | undefined;
  setIsShowingContentModal: Dispatch<SetStateAction<boolean>>;
}

const DisplayLinkOrModal: FC<Props> = ({
  orig_url,
  title,
  // sharedBy,
  // content,
  setIsShowingContentModal,
}) => {
  const linkOrModal = (orig_url: Maybe<string> | undefined) => {
    if (orig_url !== "No url for this file") {
      return (
        <h2>
          <a
            href={
              orig_url && orig_url !== "No url for this file"
                ? orig_url
                : undefined
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>
        </h2>
      );
    } else {
      return (
        <h2
          onClick={() => {
            if (title !== "Filter Tips From Nowigence")
              setIsShowingContentModal(true);
          }}
        >
          <button className={styles.btn}>{title}</button>
        </h2>
      );
    }
  };

  return linkOrModal(orig_url);
};

export default DisplayLinkOrModal;
