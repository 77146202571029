import React, { FC, useEffect } from "react";

import { useFetchBenchmarks } from "../../../../../rq-hooks";
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../../Components/LoadingCube/LoadingCube";

// context
import { useUserContext } from "../../../../context/allContexts";

// styles
import styles from "./Benchmarks.module.scss";

interface Props {
  sequenceId: string | null | undefined;
}

const Benchmarks: FC<Props> = ({ sequenceId }) => {
  const { user } = useUserContext();
  const { mutate, error, isLoading, data } = useFetchBenchmarks({
    enabled: !!sequenceId,
  });

  useEffect(() => {
    if (sequenceId) {
      mutate({ userId: user?._id, comp: user?.company, sequenceId });
    }
  }, [sequenceId]);

  if (error) {
    return (
      <ul>
        <li>{customErrorHandler(error)}</li>)
      </ul>
    );
  } else {
    return (
      <ul className={styles.benchmarksUL}>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            {
              <LoadingCube
                theme={LoadingCubeTheme.LIGHT}
                height="30px"
                width="30px"
                hasBackground={false}
              />
            }
          </div>
        ) : (
          data?.map((title: any, idx: number) => {
            return (
              <a
                key={idx}
                href={title?.[0]?._source?.orig_url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.relatedTitles__link}>
                <li>{title?.[0]?._source?.title}</li>
              </a>
            );
          })
        )}
      </ul>
    );
  }
};

export default Benchmarks;
