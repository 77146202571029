import React, { useEffect, useLayoutEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { AiFillLinkedin, AiFillTwitterCircle, AiFillTwitterSquare } from 'react-icons/ai';
import { Type, useRewardsDetails, useTwitterShareMutation, useUserLinkedInShareMutation, useUserRewardLogsMutation, useUserRewardsMutation } from '../../../../../../codeGenFE';
import { useTokenProcess } from '../../../../../../rq-hooks/useTokenProcess';
import { useTwitterPost } from '../../../../../../rq-hooks/useTwitterPost';
import { customErrorHandler } from '../../../../../../utils/customErrorHandler';
import CloseModalBtn from '../../../../../Components/CloseModalBtn/CloseModalBtn';
import LoadingCube, { LoadingCubeTheme } from '../../../../../Components/LoadingCube/LoadingCube';
import { useRewardsContext, useSocialLoginContext, useUserContext, useViewPortContext } from '../../../../../context/allContexts';
import styles from './TwitterShare.module.scss'

export enum CloseModalBtnTheme {
  LIGHT,
  DARK,
}
const TwitterShare = ({close, showTwitterPopup, article}: any) => {
  const [shouldRender, setShouldRender] = useState<any>(showTwitterPopup);
  const [twitterPostId, setTwitterPostId] = useState<any>(null);
  const [totalCharacters, setTotalCharacters] = useState(0)
  const [errors, setErrors] = useState<any>({
    empty: false,
    extended: false
  })
  const [text, setText] = useState<string>("")

  const {socialLoginState, socialLoginDispatch} = useSocialLoginContext()
  const {
    linkedInUserData, 
    linkedInAccessToken, 
    twitterAccessToken, 
    twitterAccessTokenSecret, twitterToken, twitterVerifier} = socialLoginState
  const {refetch} = useRewardsContext()

  // In this function system is adding reward points on the basis of URL upload 
  // action and on success system is refetching updated reward point details by 
  // userRewardsDetails()
  const {mutate: rewardsMutate} = useUserRewardsMutation({
    onSuccess: async () => {
      await refetch()
      close()
    }
  })

  // In this function system is logging reward actions and on success we are mutating 
  // userRewardsMutation()
  const {mutate: rewardsLogMutate} = useUserRewardLogsMutation({
    onSuccess: () => {
      console.log("success")
      rewardsMutate({
        options:{
          type: Type.Twitter,
          meta: {
            articleId: article._id,
            twitterPostId: twitterPostId,
          }
        }
      })
    }
  })

  // In this function our system uploads a post to the twitter account integrated for 
  // the user by the help of access token using useTwitterShareMutation() which uses 
  // graphql mutation userTwitterShare() and on success system is mutating 
  // userRewardLogsMutation()
  const {isLoading, mutate} = useTwitterShareMutation({
    onSuccess: (data:any) => {
        console.log(data.userTwitterShare.data.id)
        setTwitterPostId(data.userTwitterShare.data.id)
        // rewardsLogMutate({
        //     options: {
        //         type: Type.Twitter
        //     }
        // })
        toast.success("Article posted on twitter successfully!")
    },
    onError: (err: any) => {
      if(err === 'Unauthorized')
      {
        socialLoginDispatch({
          type: "logout twitter"
        })
      }
      toast.error(err)
    }
  })

  // @ts-ignore
  const { width } = useViewPortContext();

  useLayoutEffect(() => {
    let post = null
    if(article._source.source.name === "note") {
      post = (article._source.content)
    } else {
      post = `${article._source.title} ${article._source.orig_url}`
    }
    setTotalCharacters(post.length + 1)
    setText(post)
    if(post.length > 280)
        setErrors({...errors, extended: true})
    setTimeout(() => {
      localStorage.removeItem('articleShared')
      localStorage.removeItem('shareType')
    }, 5000) 
  }, [article])

  useEffect(() => {
    if (showTwitterPopup) setShouldRender(true);
  }, [showTwitterPopup]);

  const onAnimationEnd = () => {
    if (!showTwitterPopup) setShouldRender(false);
  };

  const handleClick = () => {
    if(!errors.empty && !errors.extended)
        mutate({
            options: {
                text,
                token: twitterAccessToken,
                secret: twitterAccessTokenSecret
            }
        })
  }
  const onTextChange = (e: any) => {
    const value = e.target.value
    const updatedLength = value.length
    setTotalCharacters(updatedLength)
    if(updatedLength > 280) {
        setErrors({...errors, extended: true})
        return 
    }
    if(updatedLength === 0 ) {
        setErrors({...errors, empty: true})
        setText(value)
        return 
    }
    setErrors({...errors, extended: false, empty: false})
    setText(value)
  }
  console.log(article)
  return (
    shouldRender && (
      <React.Fragment>
        <div
          onAnimationEnd={onAnimationEnd}
          className={`${styles.backdrop} ${
            showTwitterPopup ? styles.backdrop : styles.fadeOut
          }`}
          onClick={() => close()}
        />
        <div
          className={`${styles.myMemoryInfoModalWrapper} ${
            showTwitterPopup ? styles.myMemoryInfoModalWrapper : styles.slideUp
          }`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.header}>
            <div className={styles.headerText}><h2>Twitter</h2></div>
            <div><AiFillTwitterCircle className={styles.linkedin} size={35} /></div>
          </div>
          <div className={styles.closeBtn}>
            <CloseModalBtn theme={CloseModalBtnTheme.LIGHT} close={close} />
          </div>
          <div className={styles.card}>
            <div className={styles.cardBody}>
              <div className={styles.details}>
                <div className={styles.summary}>
                  <h2>Post</h2>
                  <textarea rows={5} className={styles.textArea} value={text || undefined} onChange={(e) => onTextChange(e)} /> 
                  <div className={styles.subTexts}>
                    <span className={styles.totalCharacters}>Contains {totalCharacters} characters, allowed limit is 280 characters.</span>
                    {
                        errors.extended ? <span className={styles.errors}>Post cannot be more then 280 characters</span> : ""
                    }
                    {
                        errors.empty ? <span className={styles.errors}>Post cannot be empty</span> : ""
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.cardFooter}>
                <button
                  onClick={handleClick}
                  className={styles.changePassBtn}>
                  Share
                </button>
            </div>
          </div>
          <div className={styles.isFetchingNextPageContainer}>
            {
              isLoading && <LoadingCube
                theme={LoadingCubeTheme.DARK}
                height={width < 1210 ? 100 : 30}
                width={width < 1210 ? 100 : 30}
                isFullScreen={width < 1210 ? true : false}
              />
            }
          </div>
        </div>
      </React.Fragment>
    )
  );
}

export default TwitterShare