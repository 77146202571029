import React, { useState } from "react";

import { copyText } from "../../../../util/copyText";

import { AiOutlineDown } from "react-icons/ai";

import styles from "./OtherNER.module.scss";

const OtherNER = ({ ner, label }) => {
	const [insightsDropdown, setInsightsDropdown] = useState(false);

	const Func = (ner) => {
		let otherCursor;
		let displayed;
		let otherArr = [];

		// const cardinal = ner?.CARDINAL;
		// const date = ner?.DATE;
		const fac = ner?.FAC;
		const norp = ner?.NORP;
		// const quantity = ner?.QUANTITY;

		// if (cardinal) {
		//   otherArr = cardinal;
		// }
		// if (date) {
		//   otherArr = [...otherArr, ...date];
		// }
		if (fac) {
			otherArr = [...otherArr, ...fac];
		}
		if (norp) {
			otherArr = [...otherArr, ...norp];
		}
		// if (quantity) {
		//   otherArr = [...otherArr, ...quantity];
		// }

		if (!insightsDropdown) {
			otherCursor = 2;
		} else if (insightsDropdown) {
			displayed = otherArr;
		}

		displayed = otherArr.slice(0, otherCursor);

		return (
			<>
				<div
					className={styles.titleArrowWrapper}
					onClick={() => setInsightsDropdown(!insightsDropdown)}
				>
					<small className="text-[12px]">{label}</small>
					{otherArr && otherArr.length > 2 && (
						<AiOutlineDown className={styles.arrowIcon} />
					)}
				</div>
				<ul className={styles.insights__UL}>
					{displayed.map((oth, id) => (
						<SingleLI key={id} otherData={oth} />
					))}
				</ul>
			</>
		);
	};

	return Func(ner);
};

const SingleLI = ({ otherData }) => {
	const [copiedText, setCopiedText] = useState(false);

	const copyFunc = (text) => {
		copyText(text);
		setCopiedText(true);

		setTimeout(() => {
			setCopiedText(false);
		}, 450);
	};

	return (
		<li
			className={`${styles.insights__companys} ${
				copiedText ? styles.copiedOverlay : null
			}`}
			onClick={() => copyFunc(otherData)}
		>
			{otherData}
		</li>
	);
};

export default OtherNER;
