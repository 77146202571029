import React, { useState, createContext, useContext } from "react";

//import ErrorText from '../../path-here'

export const ErrorContext = createContext();

export function ErrorProvider(props) {
  const [errorText, setErrorText] = useState(null);

  const ErrorTextActivate = () => {
    //return <ErrorText />;
  };

  const errorMessageFunction = (message) => {
    setErrorText(message);
  };

  return (
    <ErrorContext.Provider
      value={{ ErrorTextActivate, errorText, errorMessageFunction }}
    >
      {props.children}
    </ErrorContext.Provider>
  );
}

export const useErrorContext = () => useContext(ErrorContext);
