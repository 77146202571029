import AccountNeedsAttention from "../Components/AccountNeedsAttention/AccountNeedsAttention";
import BoardConsent from "../Components/BoardConsent/BoardConsent";
import Notebook from "../Components/Notebook/Notebook";
// import Walkthrough from "../Components/Walkthrough/Walkthrough";

// OTHER TOP LEVEL THINGS THAT MANAGE THERE ON STATE FOR SHOWING UP WILL BE
// ADDED HERE AND MANAGED INCASE CURRENT HOME OF TOPMENU CHANGES IN THE FUTURE
const Global = () => {
  return (
    <>
      <Notebook />
      {/* <Walkthrough /> */}
      <BoardConsent />
      <AccountNeedsAttention />
    </>
  );
};

export default Global;
