import { useReducer, FC } from "react";

// components
import TipsModal from "./TipsModal/TipsModal";
import TopicNewsPreview from "./TopicNewsPreview/TopicNewsPreview";
import ComponentMessagingOverlay from "../ComponentMessagingOverlay/ComponentMessagingOverlay";
// import Fuse from "fuse.js";

import LoadingCube, { LoadingCubeTheme } from "../LoadingCube/LoadingCube";
// country data
import { CountryData } from "../../util/countryData";

// context
import { useViewPortContext } from "../../context/allContexts";

// styles
import styles from "./AskMeTopicForm.module.scss";

// icons
import { MdSubdirectoryArrowRight } from "react-icons/md";
// import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { AiOutlineArrowDown } from "react-icons/ai";
import {
  RelationText,
  RelationTextAndType,
  RelationType,
  RelTextAndTypeCombinations,
} from "../QuickUploadBtn/QuickUpload/QuickUploadTypes";
// import QURelationDropdown from "../QuickUploadBtn/QuickUpload/QURelationDropdown/QURelationDropdown";
import { customErrorHandler } from "../../../utils/customErrorHandler";
import { useAddTopicToUserPreferencesMutation } from "../../../codeGenFE";
import { useQueryClient } from "react-query";
import MultiLevelDropdown from "../MultiLevelDropdown/MultiLevelDropdown";
// import CountryListDropdown from "../CountryListDropdown/CountryListDropdown";

interface State {
  formError: null | string;
  topic: string;
  countryArray: [] | CountryData[];
  tipsModal: boolean;
  showPreviewModal: boolean;
  excludedTopicsInput: string;
  excludedWordsArray: [] | string[];
  relationTextandType: RelationTextAndType;
  isRelationDropVisible: boolean;
  isCompanyDropDown: boolean;
  isIndustryDropDown: boolean;
  isProductDropDown: boolean;
  topicPreviews: [] | any[];
  selectedArticles: [] | string[];
}

type Actions =
  | { type: "form error"; message: string }
  | { type: "show/hide tips modal"; bool: boolean }
  | { type: "show/hide preview modal"; bool: boolean }
  | { type: "remove word"; payload: string }
  | { type: "add excluded topic"; topic: string }
  | { type: "change excluded topics input"; payload: string }
  | { type: "change countryArr"; payload: [] | CountryData[] }
  | { type: "toggle relationship dropdown"; bool: boolean }
  | { type: "close dropdowns" }
  | { type: "toggle sub dropdowns"; subdropdown: string; bool: boolean }
  | { type: "set relationship"; relText: RelationText; relType: RelationType }
  | { type: "reset" };

const topicFormReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "form error":
      return {
        ...state,
        formError: action.message,
      };
    case "show/hide tips modal":
      return {
        ...state,
        tipsModal: action.bool,
      };
    case "show/hide preview modal":
      return {
        ...state,
        showPreviewModal: action.bool,
      };
    case "remove word":
      const updatedWordsArr = state.excludedWordsArray.filter(
        (wrd) => wrd !== action.payload
      );
      return {
        ...state,
        excludedWordsArray: updatedWordsArr,
      };
    case "add excluded topic":
      return {
        ...state,
        excludedWordsArray: [...state.excludedWordsArray, action.topic],
        excludedTopicsInput: "",
      };
    case "change excluded topics input":
      return {
        ...state,
        excludedTopicsInput: action.payload,
      };
    case "change countryArr":
      return {
        ...state,
        countryArray: action.payload,
      };
    case "toggle relationship dropdown":
      return {
        ...state,
        isRelationDropVisible: action.bool,
      };
    case "close dropdowns":
      return {
        ...state,
        isRelationDropVisible: false,
        isCompanyDropDown: false,
        isIndustryDropDown: false,
        isProductDropDown: false,
      };
    case "toggle sub dropdowns":
      return {
        ...state,
        [action.subdropdown]: action.bool,
      };
    case "set relationship":
      return {
        ...state,
        isRelationDropVisible: false,
        formError: null,
        relationTextandType: {
          text: action.relText,
          type: action.relType,
        },
      };

    case "reset":
      return {
        ...initState,
      };
    default:
      break;
  }
  return state;
};

const initState = {
  formError: null,
  topic: "",
  countryArray: [],
  tipsModal: false,
  showPreviewModal: false,
  excludedTopicsInput: "",
  excludedWordsArray: [],
  relationTextandType: {
    text: RelationText.SELECT_HOW_THIS_TOPIC_RELATES_TO_YOU,
    type: RelationType.DEFAULT,
  },
  isRelationDropVisible: false,
  isCompanyDropDown: false,
  isIndustryDropDown: false,
  isProductDropDown: false,
  topicPreviews: [],
  selectedArticles: [],
};

interface Props {
  topic: string;
  disableTopic: boolean;
  isAskMeTopicModal: any;
  submitWithArticles: boolean;
  close: () => void;
}

export const AskMeTopicForm: FC<Props> = ({
  topic,
  disableTopic,
  isAskMeTopicModal,
  submitWithArticles = false,
  close,
}) => {
  const [topicFormState, topicFormDispatcher] = useReducer(
    topicFormReducer,
    initState
  );
  const {
    formError,
    countryArray,
    tipsModal,
    showPreviewModal,
    excludedTopicsInput,
    excludedWordsArray,
    relationTextandType,
    isRelationDropVisible,
  } = topicFormState;

  // @ts-ignore
  const { width } = useViewPortContext();

  const qClient = useQueryClient();

  const {
    data,
    status,
    error,
    mutate: subscribeToTopic,
  } = useAddTopicToUserPreferencesMutation({
    onSuccess: (data) => {
      qClient.invalidateQueries("fetchUserTopics");
    },
  });

  const ExcludedWordsList = ({ excludedWordsArray }: any) => {
    return excludedWordsArray
      ? excludedWordsArray.map((word: any, idx: number) => {
          return (
            <div className={styles.wordAndSpanWrapper} key={idx}>
              <p
                onClick={() =>
                  topicFormDispatcher({ type: "remove word", payload: word })
                }>
                {word}
                <span>X</span>
              </p>
            </div>
          );
        })
      : null;
  };

  // ================= MAIN COMPONENT FUNCTIONS ================== //

  const excludedTopicsFunc = (e: any) => {
    const singleWord = e.target.value.replace(",", "");
    if (e.key === "Enter" || e.key === ",") {
      if (
        singleWord.trim() === "" ||
        // @ts-ignore
        excludedWordsArray.includes(singleWord.trim())
      ) {
        return;
      }
      topicFormDispatcher({
        type: "add excluded topic",
        topic: singleWord.trim(),
      });
      // setExcludedTopicsArray([...excludedWordsArray, singleWord.trim()]);
      // setExcludedTopic("");
    } else {
      topicFormDispatcher({
        type: "change excluded topics input",
        payload: singleWord,
      });
    }
  };

  // ================= MAIN COMPONENT ================== //

  return (
    <>
      {tipsModal && (
        <TipsModal
          isAskMeTopicModal={isAskMeTopicModal}
          close={() =>
            topicFormDispatcher({ type: "show/hide tips modal", bool: false })
          }
        />
      )}
      <div className={styles.inputMenuWrapper}>
        {showPreviewModal && (
          <TopicNewsPreview
            isAskMeTopicModal={isAskMeTopicModal}
            showPreviewModal={showPreviewModal}
            close={() =>
              topicFormDispatcher({
                type: "show/hide preview modal",
                bool: false,
              })
            }
          />
        )}

        {status === "loading" && (
          <LoadingCube
            theme={LoadingCubeTheme.DARK}
            height={150}
            width={150}
            isFullScreen={true}
          />
        )}

        {formError === "Topic is required" && (
          <p className={styles.inputError}>
            <AiOutlineArrowDown className={styles.downArrow} />
            {formError}
          </p>
        )}
        <label
          className={`${styles.inputLabel} ${
            isAskMeTopicModal ? styles.lightText : null
          }`}>
          Enter a topic to monitor:
        </label>
        <div
          className={
            !isAskMeTopicModal
              ? styles.firstInputWrapper
              : styles.askMeTopicModalFirstInputWrapper
          }>
          <input
            type="text"
            placeholder="Enter a topic to monitor"
            value={topic}
            // topic from parent is either a string or null, this converts it to true or false
            disabled={true} // true if string.length > 0 else false
          />
          <button
            onClick={() =>
              topicFormDispatcher({ type: "show/hide tips modal", bool: true })
            }
            className={styles.tipButton}>
            Tips
          </button>
        </div>
        <label
          className={`${styles.inputLabel} ${
            isAskMeTopicModal ? styles.lightText : null
          }`}>
          Words to be excluded (optional):
        </label>
        <div
          className={
            !isAskMeTopicModal
              ? styles.secondInputWrapper
              : styles.askMeTopicModalSecondInputWrapper
          }>
          <MdSubdirectoryArrowRight className={styles.arrIcon} />
          <div className={styles.excludedTopicsListWrapper}>
            <ExcludedWordsList excludedWordsArray={excludedWordsArray} />
            <input
              type="text"
              placeholder="Add exclude topics, use comma to separate each word (Optional)"
              value={excludedTopicsInput}
              onChange={(e) => excludedTopicsFunc(e)}
              onKeyUp={(e) => excludedTopicsFunc(e)}
              onBlur={(e: any) => {
                e.key = "Enter";
                excludedTopicsFunc(e);
              }}
            />
          </div>
        </div>
        {formError === "Relationship error" && (
          <p className={styles.inputError}>
            <AiOutlineArrowDown className={styles.downArrow} />
            Relationship must be filled in.
          </p>
        )}

        <label
          className={`${styles.inputLabel} ${
            isAskMeTopicModal ? styles.lightText : null
          }`}>
          Select how this topic relates to you:
        </label>
        <div
          className={
            !isAskMeTopicModal
              ? styles.dropDownInputWrapper
              : styles.dropDownInputWrapperAskMeModal
          }
          style={{ zIndex: isRelationDropVisible ? 1 : 0 }}
          onMouseLeave={() => topicFormDispatcher({ type: "close dropdowns" })}>
          <MultiLevelDropdown
            display={`${relationTextandType.text[0].toUpperCase()}${relationTextandType.text.slice(
              1
            )}`}
            isOpen={isRelationDropVisible}
            itemsArr={RelTextAndTypeCombinations}
            runDispatch={(type, text) => {
              if (type === RelationType.SELF || type === RelationType.OTHER) {
                topicFormDispatcher({
                  type: "set relationship",
                  relText:
                    type === RelationType.SELF
                      ? RelationText.SELF
                      : RelationText.OTHER,
                  relType: type as RelationType,
                });
              } else {
                topicFormDispatcher({
                  type: "set relationship",
                  relText: text as RelationText,
                  relType: type as RelationType,
                });
              }
            }}
            toggleDropdownVis={() =>
              topicFormDispatcher({
                type: "toggle relationship dropdown",
                bool: !isRelationDropVisible,
              })
            }
          />
        </div>
        {/*<label
          className={`${styles.inputLabel} ${
            isAskMeTopicModal ? styles.lightText : null
          }`}
        >
          Select countries of focus:
        </label>
        <CountryListDropdown
          changeSelectedCountries={(countries) =>
            topicFormDispatcher({
              type: "change countryArr",
              payload: countries,
            })
          }
          selectedCountries={countryArray}
          />*/}
        <button
          disabled={status === "error"}
          className={styles.inputMenu__btn}
          onClick={() => {
            if (
              relationTextandType.text ===
              RelationText.SELECT_HOW_THIS_TOPIC_RELATES_TO_YOU
            ) {
              topicFormDispatcher({
                type: "form error",
                message: "Relationship error",
              });
            } else {
              subscribeToTopic({
                inputOpts: {
                  topic: topic,
                  relationshipText: relationTextandType.text,
                  relationshipType: relationTextandType.type,
                  // TODO: removing countries from user options and hardcoding
                  // to en-US for now.
                  // country: countryArray,
                  country: [
                    {
                      countryName: "United States - English",
                      marketCode: "en-US",
                    },
                  ],
                  excludedKeywords: excludedWordsArray,
                  prePopulateData: true,
                },
              });
            }
          }}>
          Add to Profile
        </button>
      </div>
      {/* MESSAGING COMPONENTS */}
      {error && (
        <ComponentMessagingOverlay
          close={() => {
            topicFormDispatcher({ type: "reset" });
            setTimeout(() => {
              close();
            }, 200);
          }}
          message={customErrorHandler(error)}
          showX={true}
        />
      )}
      {status === "success" && (
        <ComponentMessagingOverlay
          close={() => {
            topicFormDispatcher({ type: "reset" });
            setTimeout(() => {
              close();
            }, 200);
          }}
          message={`Successfully subscribed to ${topic} and we added a few articles for you!`}
          showCheck={true}
        />
      )}
    </>
  );
};

export default AskMeTopicForm;

function RelationDisplay({ text, type, width }: any) {
  let display = "";
  if (width > 570) {
    if (text !== "") {
      display = text;
    } else {
      if (type === "other") {
        display = "Other";
      } else {
        display = "Select how this topic relates to you";
      }
    }
  } else {
    if (text !== "Select how this topic relates to you") {
      display = text;
    } else {
      if (type === "other") {
        display = "Other";
      } else {
        display = "";
      }
    }
  }

  return <p className="displayElement">{display}</p>;
}
