import React, { FC, useEffect, useReducer } from "react";

// CONTEXT
import { useSearchContext, useUserContext } from "../../../context/allContexts";

// HELPER FUNCTION
import { getRiskOrOppFunc } from "../getRiskOrOppFunc/getRiskOrOppFunc";

// COMPONENTS
import NoRisksOrOppMessage from "../NoRisksOrOppMessage/NoRisksOrOppMessage";
import AddToNotebookBtn from "../../../Components/AddToNotebookBtn/AddToNotebookBtn";

import styles from "./AMRisks.module.scss";
import { v4 as uuidv4 } from "uuid";
import {
  IsRiskOrOpp,
  useArticlesTopFiveRisksAndOppsQuery,
} from "../../../../codeGenFE";
import ComponentMessagingOverlay from "../../../Components/ComponentMessagingOverlay/ComponentMessagingOverlay";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../Components/LoadingCube/LoadingCube";

interface Props {
  topic: string;
}

const AMRisk: FC<Props> = ({ topic }) => {
  const { data, status, error } = useArticlesTopFiveRisksAndOppsQuery({
    inputOpts: { isRiskOrOpp: IsRiskOrOpp.Risks, topic },
  });

  const articleData = data?.articlesTopFiveRisksAndOpps.risksAndOpps;

  const topRisks = articleData?.map((a) => a?.shortDesc);

  if (status === "loading") {
    return (
      <div
        className={styles.AMRiskWrapper}
        style={{
          position: "relative",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingCube
          height={100}
          width={100}
          hasBackground={false}
          theme={LoadingCubeTheme.DARK}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className={styles.AMRiskWrapper}
        style={{
          position: "relative",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NoRisksOrOppMessage error={`${error}`} theme="light" />
      </div>
    );
  }

  if (!topRisks || topRisks.length === 0) {
    return (
      <div
        className={styles.AMRiskWrapper}
        style={{
          position: "relative",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NoRisksOrOppMessage
          error={`No Risks found for ${topic}.`}
          theme="light"
        />
      </div>
    );
  }

  return (
    <div className={styles.AMRiskWrapper}>
      <div className={styles.shortDesc__div}>
        {topRisks &&
          topRisks.map((risk) => {
            return (
              <li key={uuidv4()} className={styles.shortDesc__li}>
                <div>
                  {risk && (
                    <AddToNotebookBtn content={risk} style={"unstyled"} />
                  )}
                </div>
                {risk}
              </li>
            );
          })}
      </div>
    </div>
  );
};

export default AMRisk;
