import React, { FC, useEffect, useState } from "react";

// components
import { MdArrowDropDown } from "react-icons/md";
import { useFetchUserDriversQuery } from "../../../../../codeGenFE";

// Data
import { useSearchContext } from "../../../../context/allContexts";

// TYPES
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";
import DropdownDisplayBelow from "../../../DropdownDisplayBelow/DropdownDisplayBelow";

// styles
import styles from "./filters.module.scss";

interface Props {
  openFilter: string;
  setOpenFilter: (arg: string) => void;
}

const DriverFilter: FC<Props> = ({ openFilter, setOpenFilter }) => {
  const { searchDispatcher, searchState } = useSearchContext();
  const { driverChoice } = searchState;

  const { data, status, error } = useFetchUserDriversQuery();
  const drivers = data?.fetchUserDrivers;

  return (
    <div
      className={styles.outcomeFilterWrapper}
      data-is-open={`${openFilter === "driver"}`}
    >
      <DropdownDisplayBelow
        selectableItems={!!drivers ? (drivers as string[]) : []}
        isDropdownOpen={openFilter === "driver"}
        toggleDropdown={() =>
          setOpenFilter(openFilter === "driver" ? "" : "driver")
        }
        dropdownDisplay={"Drivers"}
        selectionDisplay={searchState?.driverChoice}
        select={(driver) =>
          searchDispatcher({
            type: SearchContextActions.SET_DRIVER_CHOICE,
            driver: driver,
          })
        }
        unselect={() =>
          searchDispatcher({
            type: SearchContextActions.SET_DRIVER_CHOICE,
            driver: null,
          })
        }
        makeSearchable={true}
      />
    </div>
  );
};

export default DriverFilter;
