const padZero = (number) => {
  if (number < 10) {
    number = "0" + number;
  }

  return number;
};

export const convertDate = (unixtime) => {
  const milliseconds = unixtime * 1000,
    dateObject = new Date(milliseconds),
    temp = [];

    temp.push(dateObject.getUTCFullYear());
  temp.push(padZero(dateObject.getUTCMonth() + 1));
  temp.push(padZero(dateObject.getUTCDate()));

  return temp.join("-");
};

export const convertDateWithTime = (unixtime) => {
  const options = {
    // timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    // seconds: "numeric",
  };

  const time = new Date(unixtime * 1000);

  return time.toLocaleTimeString([], options);
};
