import { Maybe, Topic, UtCountry } from "../../../codeGenFE";
import {
  RelationText,
  RelationType,
} from "../../Components/QuickUploadBtn/QuickUpload/QuickUploadTypes";

export enum UPActiveMode {
  SEARCH_TOPICS,
  SEARCH_TAGS,
  ADD_TAGS,
  EDIT_TAGS,
  EDIT_TAGS_CONFIRM,
}

export enum UserProfileActionChoices {
  SET_TAGS,
  CHANGE_TAGS_SEARCH_STRING,
  CHANGE_TOPIC_SEARCH_STRING,
  CHANGE_MODE,
  CHANGE_TAG_USER_MESSAGING,
  CHANGE_TAG_TO_ADD,
  SHOW_MODAL_TYPE,
  SET_TAG_TO_EDIT,
  EDIT_TAG_INPUT,
  SET_ERROR_MESSAGE,
  SHOW_LOADING,
  SET_UI_TAG_ERROR,
  SET_TOPIC_TO_MONITOR,
  SET_EXCLUDED_TOPIC_INPUT,
  ADD_EXCLUDED_TOPIC_TO_ARRAY,
  REMOVE_EXCLUDED_TOPIC_FROM_ARRAY,
  TOGGLE_RELATION_DROPDOWN,
  SET_RELATIONSHIP,
  TOGGLE_COUNTRY_DROPDOWN,
  SET_COUNTRY_ARRAY,
  SET_SHOW_RECENT_TOPIC_NEWS_ARTICALS_MODAL,
  SET_TOPICS,
  RESET_TOPIC_FORM,
  SET_TOPIC_TO_EDIT,
  SET_TOPIC_TO_DELETE,
  RESET_CONTEXT,
}

export enum TagUserMessaging {
  INIT,
  ADDED,
  ERROR,
  EDITED,
  DELETED,
}

export enum ShowModalType {
  NONE,
  DELETE,
  EDIT,
}

export type UserProfileState = {
  tags: Maybe<string>[] | undefined;
  searchTagsString: string;
  activeMode: UPActiveMode;
  tagUserMessaging: TagUserMessaging;
  tagToAdd: string;
  showModalType: ShowModalType;
  tagToEdit: string;
  editTagInput: string;
  errorMsg: string;
  showLoading: boolean;
  uiTagError: string;
  topicToMonitor: string;
  excludedTopicsArray: Maybe<string>[];
  excludedTopicInput: string;
  isRelationDropVisible: boolean;
  relationship: {
    relText: RelationText;
    relType: RelationType;
  };
  showCountryDropdown: boolean;
  countryArray: Maybe<UtCountry>[];
  topicId: string;
  searchTopicString: string;
  topics: Topic[] | [];
  topicToDelete: string;
};

export type UserProfileActions =
  | {
      type: UserProfileActionChoices.SET_TAGS;
      tags: Maybe<string>[] | undefined;
    }
  | {
      type: UserProfileActionChoices.CHANGE_TAGS_SEARCH_STRING;
      searchTagsString: string;
    }
  | { type: UserProfileActionChoices.CHANGE_MODE; activeMode: UPActiveMode }
  | {
      type: UserProfileActionChoices.CHANGE_TAG_USER_MESSAGING;
      tagUserMessaging: TagUserMessaging;
    }
  | { type: UserProfileActionChoices.CHANGE_TAG_TO_ADD; tagToAdd: string }
  | {
      type: UserProfileActionChoices.SHOW_MODAL_TYPE;
      payload: ShowModalType;
    }
  | {
      type: UserProfileActionChoices.SET_TAG_TO_EDIT;
      tagToEdit: string;
      activeMode: UPActiveMode;
      editTagInput: string;
    }
  | {
      type: UserProfileActionChoices.EDIT_TAG_INPUT;
      editTagInput: string;
    }
  | { type: UserProfileActionChoices.SET_ERROR_MESSAGE; errorMsg: string }
  | { type: UserProfileActionChoices.SHOW_LOADING; boolean: boolean }
  | { type: UserProfileActionChoices.SET_UI_TAG_ERROR; uiTagError: string }
  | {
      type: UserProfileActionChoices.SET_TOPIC_TO_MONITOR;
      topicToMonitor: string;
    }
  | {
      type: UserProfileActionChoices.SET_EXCLUDED_TOPIC_INPUT;
      excludedTopicInput: string;
    }
  | {
      type: UserProfileActionChoices.ADD_EXCLUDED_TOPIC_TO_ARRAY;
      payload: string;
    }
  | {
      type: UserProfileActionChoices.REMOVE_EXCLUDED_TOPIC_FROM_ARRAY;
      payload: string;
    }
  | {
      type: UserProfileActionChoices.TOGGLE_RELATION_DROPDOWN;
      boolean: boolean;
    }
  | {
      type: UserProfileActionChoices.SET_RELATIONSHIP;
      rel: { relText: RelationText; relType: RelationType };
    }
  | {
      type: UserProfileActionChoices.TOGGLE_COUNTRY_DROPDOWN;
      showCountryDropdown: boolean;
    }
  | {
      type: UserProfileActionChoices.SET_COUNTRY_ARRAY;
      countryArray: string[];
    }
  | {
      type: UserProfileActionChoices.SET_SHOW_RECENT_TOPIC_NEWS_ARTICALS_MODAL;
      payload: boolean;
    }
  | {
      type: UserProfileActionChoices.CHANGE_TOPIC_SEARCH_STRING;
      payload: string;
    }
  | {
      type: UserProfileActionChoices.SET_TOPICS;
      payload: Topic[] | [];
    }
  | { type: UserProfileActionChoices.RESET_TOPIC_FORM }
  | { type: UserProfileActionChoices.SET_TOPIC_TO_EDIT; topic: Topic }
  | { type: UserProfileActionChoices.SET_TOPIC_TO_DELETE; topicId: string }
  | { type: UserProfileActionChoices.RESET_CONTEXT };

// ------------------------------  UPInput ---------------------- //
