import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import {
  removeTagFromMultiArticleFromContext,
  removeTagFromSingleArticleFromContext,
} from "../../util/removeTagFromContextUtil";
import { useUserContext } from "../allContexts";

export const NewsContext = createContext();

export function NewsProvider(props) {
  // const { userState } = useContext(UserContext);
  const { userState } = useUserContext();
  const [newsData, setNewsData] = useState([]);
  const [newsDaysOnState, setNewsDaysOnState] = useState(0);
  let requestCount = 0; // debounce get request variable

  // NOTE:  Debounce is written for initial dashboard load, which is called by the menu and the user context.  This only happens when a user reloads the webpage at the main dashboard.

  const fetchNewsData = async ({ days = 14, globalFilterDate }) => {
    if (
      globalFilterDate * 2 > newsDaysOnState ||
      (newsDaysOnState !== "all" && globalFilterDate === "all")
    ) {
      let oldestSortDateOnState;
      if (newsData.length === 0) {
        oldestSortDateOnState = Math.round(new Date().getTime() / 1000);
      } else {
        oldestSortDateOnState = newsData[newsData.length - 1]._source.sortDate;
      }

      try {
        requestCount++;

        let newsDataResponse = await axios.get("/api/newsData/newsData", {
          params: {
            days: days,
            oldestSortDateOnState: oldestSortDateOnState,
            requestCount: requestCount,
          },
        });

        newsDataResponse.data.newsDaysOnState = days;

        // Returned request count for debouncing requests
        const resRequestCount = Number(newsDataResponse.data.requestCount);

        // Bounces any request but the most recent
        if (
          resRequestCount === requestCount &&
          !!newsDataResponse.data.success
        ) {
          setNewsDaysOnState(days);
          setNewsData([...newsData, ...newsDataResponse.data.data]);
        }
      } catch (err) {
        console.log("err: ", err);
      }
    } else {
      // Not fetching because it is already all on state
    }
  };

  const clearNewsContext = () => {
    setNewsData([]);
    setNewsDaysOnState(0);
  };

  // To handle a user liking a article
  const userClickedLikeNews = (news) => {
    axios
      // .post("/api/newsData/like", news)
      .post("/api/newsData/like", news)
      .then(async (res) => {
        if (res.status === 200) {
          // console.log("res", res)
          let updatedNewsArray = await newsData.map((article) => {
            if (res.data.value._id === article._id) {
              return res.data.value;
            } else {
              return article;
            }
          });
          setNewsData(updatedNewsArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // To handle a user dislike an article
  const userClickedDislikeNews = (news) => {
    axios
      .post("/api/newsData/dislike", news)
      .then(async (res) => {
        if (res.status === 200) {
          let updatedNewsArray = await newsData.map((article) => {
            if (res.data.value._id === article._id) {
              return res.data.value;
            } else {
              return article;
            }
          });
          setNewsData(updatedNewsArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // delete news article or remove userId from userList array and favorites
  const deleteNewsItem = (article) => {
    let updatedNewsArray;

    const data = {
      WhichDb: "url",
      articleId: article._id,
      articleTitle: article._source.title,
      privateOrNot: "not",
    };

    axios
      .post("/api/articles", data)
      .then(async (res) => {
        if (res.status === 200) {
          updatedNewsArray = await newsData.filter(
            (newsArticle) => newsArticle._id !== article._id
          );
          setNewsData(updatedNewsArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   console.log("news :", newsData);
  // }, [newsData]);

  const removeNewsTags = (tag, articleId = null) => {
    const updatedNewsUploads = removeTagFromMultiArticleFromContext(
      tag,
      newsData,
      // user.data._id
      userState._id
    );
    setNewsData(updatedNewsUploads);
  };

  const removeSingleNewsTags = (tag, articleId, articles, userId) => {
    const updatedNewsUploads = removeTagFromSingleArticleFromContext(
      tag,
      articleId,
      articles,
      userId
    );
    setNewsData(updatedNewsUploads);
  };

  const addSingleNewsTag = (tagToAdd, articleId, userId) => {
    const updated = newsData.map((article) => {
      if (article._id === articleId) {
        if (!article.tags) {
          article.tags = {};
        }
        if (!article.tags[userId]) {
          article.tags[userId] = [];
        }
        article.tags[userId].push(tagToAdd);
      }
      return article;
    });
    setNewsData(updated);
  };

  const updateNewsArticle = (article) => {
    const updatedArticles = newsData.map((currentArt) => {
      if (currentArt._id === article._id) {
        return article;
      }
      return currentArt;
    });
    setNewsData(updatedArticles);
  };

  const addMultipleNewsArticles = (articles) => {
    setNewsData((prevState) => [...articles, ...prevState]);
  };

  const filterNewsArticle = (id) => {
    const updatedArticles = newsData.filter((article) => article._id !== id);
    setNewsData(updatedArticles);
  };

  return (
    <NewsContext.Provider
      value={{
        fetchNewsData,
        newsData,
        newsDaysOnState,
        clearNewsContext,
        userClickedLikeNews,
        userClickedDislikeNews,
        deleteNewsItem,
        removeNewsTags,
        removeSingleNewsTags,
        addSingleNewsTag,
        updateNewsArticle,
        filterNewsArticle,
        addMultipleNewsArticles,
      }}
    >
      {props.children}
    </NewsContext.Provider>
  );
}

export const useNewsContext = () => useContext(NewsContext);
