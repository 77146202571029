export default function countryFilterConverter(country: string): string {
  if (countryLookup[country]) {
    return countryLookup[country];
  }

  return country;
}

const countryLookup: any = {
  "United States of America": "United States",
  "Dem. Rep. Congo": "Congo, The Democratic Republic of the",
  "Falkland Is.": "Falkland Islands",
  // "North Korea": "North Korea",
};
