import { Fragment, useState } from "react";
import styles from "./landingPage.module.scss";
import logo from "../../../assets/img/logo.png";
import closeLight from "../../../assets/img/hamburger.png";
import closebtn from "../../../assets/img/close.png";
import homeicon from "../../../assets/img/homeImage.png";
import signin from "../../../assets/img/user-circle.png";
import create from "../../../assets/img/user.png";
import info from "../../../assets/img/Info.png";
import idea from "../../../assets/img/idea2.png";
import industry from "../../../assets/img/industry2.png";
import { set } from "lodash";

export default function Nav({ dataFetched }) {
	const [toggle, setToggle] = useState(false);

	const clickHandler = () => {
		setToggle(!toggle);
	};

	return (
		<>
			<Fragment>
				<div class={styles.section1}>
					<div class={styles.navbar}>
						<div>
							<img className={styles.logoWidth} src={logo} />
						</div>
						<div class={styles.nav + " align-items-center"}>
							<ul className={styles.ul}>
								<li className="mx-4 hidden">Solutions</li>
								<li className="mx-4 hidden">Industries</li>
								<li
									onClick={() => {
										window.open("https://www.nowigence.com/about-nowigence/");
									}}
									className="mx-4 cursor-pointer hidden"
								>
									About Pluaris
								</li>
							</ul>
							<ul className={styles.ul}>
								<a href="/loginlight" style={{ textDecoration: "none" }}>
									{" "}
									<li className={styles.developers}>Login</li>
								</a>
								<li className={styles.signUp} id="signUp">
									<button
										onClick={() => {
											if (!dataFetched)
												localStorage.removeItem("summaryKeywords");
											window.location.href = "/signuplight";
										}}
										style={{ cursor: "pointer" }}
										// className={styles.signUp}
									>
										Create&nbsp;free&nbsp;account
									</button>
								</li>
							</ul>
						</div>

						{/* <div class={styles.toggler}>
              <button onClick={clickHandler}>
                {toggle && <img width="40px" src={closebtn} />}
                {!toggle && <img width="25px" src={closeLight} />}
              </button>
            </div> */}
					</div>{" "}
					{/* {toggle && (
            <div className="d-flex justify-content-end">
              <div
                className={
                  styles.opacityDiv + " my-3 w-75 px-3  py-3 text-start fs-6 "
                }
              >
                <ul>
                  <li
                    onClick={() => {
                      window.open("https://www.nowigence.com");
                    }}
                    className="my-3 d-flex align-items-center cursor-pointer"
                  >
                    <img src={homeicon} />
                    <span className="mx-3">Home</span>
                  </li>
                  <li
                    onClick={() => {
                      window.open("https://www.nowigence.com/about-nowigence/");
                    }}
                    className="my-3 d-flex align-items-center cursor-pointer"
                  >
                    <img src={info} />
                    <span className="mx-3">About</span>
                  </li>
                  <li className="my-3 d-flex align-items-center">
                    <img src={idea} />
                    <span className="mx-3">Solution</span>
                  </li>
                  <li className="my-3 d-flex align-items-center">
                    <img src={industry} />
                    <span className="mx-3">Industry</span>
                  </li>
                  <hr />
                  <a
                    href="/signuplight"
                    style={{
                      textDecoration: "none",
                      color: "#0E0E2C",
                    }}
                  >
                    <li className="my-3 d-flex align-items-center">
                      <img src={create} />
                      <span className="mx-3">Create New Account</span>
                    </li>
                  </a>

                  <a
                    href="/loginlight"
                    style={{ textDecoration: "none", color: "#0E0E2C" }}
                  >
                    <li className="my-3 d-flex align-items-center">
                      <img src={signin} />
                      <span className="mx-3">Sign in</span>
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          )} */}
				</div>
			</Fragment>
		</>
	);
}
