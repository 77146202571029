import React from "react";
import styles from "./LoadingAnswer.module.scss";

// Components
import LoadingCube from "../../../../Components/LoadingCube/LoadingCube";

const LoadingAnswer = ({ message }) => {
  return (
    <div className={styles.loading__container}>
      <div className={styles.loading__wrapper}>
        <LoadingCube
          isFullScreen={false}
          height={150}
          width={150}
          theme="light"
          hasBackground={false}
        />
        <p className={styles.loading__message}>{message}</p>
      </div>
    </div>
  );
};

export default LoadingAnswer;
