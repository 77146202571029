import { FC } from "react";
// components
// Data
import { useFetchUserTopicsForSearchQuery } from "../../../../../codeGenFE";
import { useSearchContext } from "../../../../context/allContexts";
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";
import DropdownDisplayBelow from "../../../DropdownDisplayBelow/DropdownDisplayBelow";
// styles
import styles from "./filters.module.scss";

interface Props {
  openFilter: string;
  setOpenFilter: (arg: string) => void;
}

const TopicFilter: FC<Props> = ({ openFilter, setOpenFilter }) => {
  const { searchDispatcher, searchState } = useSearchContext();
  const { searchTopicChoice } = searchState;

  const { data } = useFetchUserTopicsForSearchQuery();
  const topics = data?.fetchUserTopicsForSearch;

  return (
    <div
      className={styles.outcomeFilterWrapper}
      data-is-open={`${openFilter === "topic"}`}>
      <DropdownDisplayBelow
        selectableItems={topics ? (topics as string[]) : []}
        isDropdownOpen={openFilter === "topic"}
        toggleDropdown={() =>
          setOpenFilter(openFilter === "topic" ? "" : "topic")
        }
        dropdownDisplay={"Topics"}
        selectionDisplay={searchTopicChoice}
        select={(topic) =>
          searchDispatcher({
            type: SearchContextActions.SET_TOPIC,
            payload: topic,
          })
        }
        unselect={() =>
          searchDispatcher({
            type: SearchContextActions.SET_TOPIC,
            payload: null,
          })
        }
        makeSearchable={true}
      />
    </div>
  );
};

export default TopicFilter;
