import { FC, useReducer } from "react";
import { MdArrowDropDown } from "react-icons/md";
// import { useUploadContentMutation } from "../../../../../codeGenFE";
import { useTextUpload } from "../../../../../rq-hooks/pythonRestApi/useTextUpload";
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
import { useUserContext, useNavContext, useRewardsContext } from "../../../../context/allContexts";
// COMPONENTS
import QURelationDropdown from "../QURelationDropdown/QURelationDropdown";
import QUShare from "../QUShare/QUShare";
import AddTagsDropdown from "../../../AddTagsDropdown/AddTagsDropdown";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../LoadingCube/LoadingCube";
import QuickUploadMessagingModal from "../../QuickUploadMessagingModal/QuickUploadMessagingModal";
// STYLES
import styles from "../QuickUpload.module.scss";
// TYPES
import {
  ActiveUploadType,
  QuickUploadActionChoices,
  RelationText,
  RelationType,
} from "../QuickUploadTypes";
import {
  ActionChoices,
  Actions,
  InputErrors,
  Props,
  State,
} from "./TextUploadTypes";
import { Type, useUserRewardLogsMutation, useUserRewardsMutation } from "../../../../../codeGenFE";
const textUploadReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionChoices.CHANGE_TITLE_STRING:
      return {
        ...state,
        title: action.title,
        inputError: null,
      };
    case ActionChoices.CHANGE_CONTENT_STRING:
      return {
        ...state,
        content: action.content,
        inputError: null,
      };

    case ActionChoices.CHANGE_RELATIONSHIP:
      return {
        ...state,
        relationship: {
          text: action.relText,
          type: action.relType,
        },
        isRelationshipDropdownOpen: false,
        inputError: null,
      };
    case ActionChoices.TOGGLE_RELATIONSHIP_DROPDOWN:
      return {
        ...state,
        isRelationshipDropdownOpen: !state.isRelationshipDropdownOpen,
      };
    case ActionChoices.RESET_RELATIONSHIP:
      return {
        ...state,
        isRelationshipDropdownOpen: false,
        relationship: {
          text: initState.relationship.text,
          type: initState.relationship.type,
        },
        inputError: null,
      };
    case ActionChoices.SET_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    case ActionChoices.TOGGLE_IS_PRIVATE:
      return {
        ...state,
        isPrivate: action.boolean,
      };
    case ActionChoices.TOGGLE_SHARE_ALL:
      return {
        ...state,
        shareAll: action.boolean,
      };
    case ActionChoices.SET_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: action.selectedUsers,
      };
    case ActionChoices.RESET_SHARE:
      return {
        ...state,
        selectedUsers: [],
        shareAll: false,
        isPrivate: true,
      };
    case ActionChoices.SET_INPUT_ERROR:
      return {
        ...state,
        inputError: action.error,
      };
    case ActionChoices.CLEAR_INPUT_ERRORS:
      return {
        ...state,
        inputError: null,
      };
    case ActionChoices.CLOSE_WARNING:
      return {
        ...state,
        closeWarning: action.boolean,
      };
    default:
      return state;
  }
};

const initState: State = {
  title: "",
  content: "",
  relationship: {
    text: RelationText.SELECT_HOW_THIS_TOPIC_RELATES_TO_YOU,
    type: RelationType.DEFAULT,
  },
  isRelationshipDropdownOpen: false,
  tags: [],
  isPrivate: true,
  selectedUsers: [],
  shareAll: false,
  inputError: null,
  closeWarning: false,
};

const TextUpload: FC<Props> = ({ quickUploadDispatcher }) => {
  const { user, disableShare } = useUserContext();
  const { navDispatch } = useNavContext();
  const [textUploadState, textUploadDispatcher] = useReducer(
    textUploadReducer,
    initState
  );
  const {
    title,
    content,
    isRelationshipDropdownOpen,
    relationship,
    tags,
    isPrivate,
    shareAll,
    selectedUsers,
    inputError,
    closeWarning,
  } = textUploadState;

  const {refetch} = useRewardsContext()


  // In this function system send article’s data including title content and 
  // relationship to useTextUpload() hook that is calling our python api and on 
  // success system mutating userRewardLogsMutation()
  const { mutate, status, error, reset: resetMutation } = useTextUpload();

  // shows text count in the textarea
  const charCount = (textContent: any) => {
    if (textContent.length >= 15000) {
      return "15,000 Limit Reached!";
    }
    return textContent.length;
  };

  function submitText(event: any) {
    event.preventDefault();
    if (title.length <= 0 || content.length <= 0) {
      textUploadDispatcher({
        type: ActionChoices.SET_INPUT_ERROR,
        error: InputErrors.TITLE_AND_CONTENT_REQUIRED,
      });
      return;
    } else if (
      !isPrivate &&
      Number(selectedUsers.length) === 0 &&
      shareAll === false
    ) {
      textUploadDispatcher({
        type: ActionChoices.SET_INPUT_ERROR,
        error: InputErrors.NO_USERS_TO_SHARE,
      });
      return;
    } else {
      const data1 = {
        // this is dumb -> on python side nothing we can do accept request them
        // to fix it.
        user_id: user._id,
        company_id: user.company,
        title,
        content,
        share_all: shareAll ? true : false,
        user_relationship_type: relationship.type && relationship.type,
        user_relationship_value: relationship.text && relationship.text,
      };
      // @ts-ignore
      if (tags) data1.tags = tags;

      // @ts-ignore
      if (selectedUsers) data1.share_to = selectedUsers;

      // @ts-ignore
      data1.share_all = shareAll ? true : false;

      console.log("data1", data1);
      mutate(data1);
    }
  }

  return (
    <div className={styles.quickUploadModal__wrapper}>
      {/* ========== HANDLE SUCCESS | WARNING | ERROR STATES FROM BACKEND ========== */}
      <QuickUploadMessagingModal
        success={status === "success"}
        warning={closeWarning}
        error={error && customErrorHandler(error)}
        close={() => {
          textUploadDispatcher({
            type: ActionChoices.CLOSE_WARNING,
            boolean: false,
          });
          resetMutation();
        }}
      />

      {/* ========== Loading ========== */}
      {status === "loading" && (
        <div className={styles.loadingWrapper}>
          <LoadingCube theme={LoadingCubeTheme.LIGHT} />
        </div>
      )}

      {/* ========== Top Button Row ========== */}
      <div className={styles.quickUploadModal__urlTextWrapper}>
        <button
          className={`${styles.urlTextWrapper_btn}`}
          onClick={() =>
            quickUploadDispatcher({
              type: QuickUploadActionChoices.CHANGE_UPLOAD_TYPE,
              newUploadType: ActiveUploadType.URL,
            })
          }>
          URL
        </button>
        <button className={`${styles.urlTextWrapper_btn} ${styles.active}`}>
          TEXT
        </button>
        <button
          className={styles.urlTextWrapper_btn}
          onClick={() =>
            quickUploadDispatcher({
              type: QuickUploadActionChoices.CHANGE_UPLOAD_TYPE,
              newUploadType: ActiveUploadType.FILE,
            })
          }>
          FILE
        </button>
      </div>
      {/* ========== Text Input ========== */}
      <div className={styles.quickUploadModal__URLWrapper}>
        {(inputError === InputErrors.TITLE_AND_CONTENT_REQUIRED ||
          inputError === InputErrors.CONTENT_TOO_LONG) && (
          <label className={styles.inputError}>{inputError}</label>
        )}
        <form className={styles.quickUploadModal__form}>
          <input
            type="text"
            placeholder="Enter a Title"
            value={title}
            onChange={(e) =>
              textUploadDispatcher({
                type: ActionChoices.CHANGE_TITLE_STRING,
                title: e.target.value,
              })
            }
            className={styles.quickUploadModal__input}
          />
          {/* ========== Text Area Input ========== */}

          <div className={styles.textAreaWrapper}>
            <textarea
              placeholder="Enter Your Content Here"
              value={content}
              onChange={(e: any) =>
                textUploadDispatcher({
                  type: ActionChoices.CHANGE_CONTENT_STRING,
                  content: e.target.value,
                })
              }
            />
            {content.length > 0 && (
              <div className={styles.charCount}>{`${charCount(
                content
              )} - 15,000`}</div>
            )}
          </div>
        </form>
        {/* ========== Relationship Dropdown ========== */}

        <div
          className={`${styles.dropDown} ${
            isRelationshipDropdownOpen ? styles.activeDropdown : null
          }`}>
          <p
            onClick={() =>
              textUploadDispatcher({
                type: ActionChoices.TOGGLE_RELATIONSHIP_DROPDOWN,
              })
            }>
            {relationship.text ===
            RelationText.SELECT_HOW_THIS_TOPIC_RELATES_TO_YOU
              ? "Relationship"
              : relationship.text}
          </p>
          <span
            onClick={() =>
              textUploadDispatcher({
                type: ActionChoices.TOGGLE_RELATIONSHIP_DROPDOWN,
              })
            }>
            <MdArrowDropDown />
          </span>
          {isRelationshipDropdownOpen && (
            // <QURelationDropdown close={() => setRelationDropDown(false)} />
            <QURelationDropdown
              close={() =>
                textUploadDispatcher({
                  type: ActionChoices.TOGGLE_RELATIONSHIP_DROPDOWN,
                })
              }
              reset={() =>
                textUploadDispatcher({
                  type: ActionChoices.RESET_RELATIONSHIP,
                })
              }
              changeRelationTextandType={({ relText, relType }) =>
                textUploadDispatcher({
                  type: ActionChoices.CHANGE_RELATIONSHIP,
                  relText: relText,
                  relType: relType,
                })
              }
            />
          )}
        </div>
        {/* ========== Relationship Dropdown ========== */}
        <AddTagsDropdown
          selectedTags={tags}
          setSelectedTags={(tags) =>
            textUploadDispatcher({ type: ActionChoices.SET_TAGS, tags: tags })
          }
        />
      </div>
      {inputError === InputErrors.NO_USERS_TO_SHARE && (
        <QuickUploadMessagingModal
          error={inputError}
          close={() =>
            textUploadDispatcher({ type: ActionChoices.CLEAR_INPUT_ERRORS })
          }
        />
      )}
      <div className={styles.quickUploadModal__privateShareUploadsWrapper}>
        <div className={styles.privNShare}>
          <button
            className={`${styles.privNShare_btn} ${
              isPrivate ? styles.active : null
            }`}
            onClick={(e) => {
              e.preventDefault();
              textUploadDispatcher({
                type: ActionChoices.TOGGLE_IS_PRIVATE,
                boolean: !isPrivate,
              });
            }}>
            PRIVATE
          </button>
          <button
            className={`${styles.privNShare_btn} ${
              !isPrivate ? styles.active : null
            } ${disableShare ? styles.disableShare : ""}`}
            onClick={(e) => {
              e.preventDefault();
              if (!disableShare) {
                textUploadDispatcher({
                  type: ActionChoices.TOGGLE_IS_PRIVATE,
                  boolean: !isPrivate,
                });
              } else {
                return null;
              }
            }}>
            SHARE
          </button>
        </div>
        <button className={styles.uploadBtn} onClick={(e) => submitText(e)}>
          UPLOAD
        </button>
      </div>
      <QUShare
        shareAll={shareAll}
        setShareAll={(boolean: boolean) =>
          textUploadDispatcher({
            type: ActionChoices.TOGGLE_SHARE_ALL,
            boolean: boolean,
          })
        }
        selectedUsers={selectedUsers}
        setSelectedUsers={(selectedUsers: [] | string[]) =>
          textUploadDispatcher({
            type: ActionChoices.SET_SELECTED_USERS,
            selectedUsers: selectedUsers,
          })
        }
        cancel={() => textUploadDispatcher({ type: ActionChoices.RESET_SHARE })}
        hidden={isPrivate || status !== "idle"}
      />
      <button
        className={styles.nevermind}
        onClick={() => navDispatch({ type: "change which menu", menu: "" })}
        // onClick={(e) => {
        //   e.preventDefault();
        //   textUploadDispatcher({
        //     type: ActionChoices.CLOSE_WARNING,
        //     boolean: true,
        //   });
        //   }}
      >
        Nevermind
      </button>
    </div>
  );
};

export default TextUpload;
