import axios from "axios";
import { useMutation } from "react-query";
import { PYTHON_REST_BASE_ENDPOINT } from "../../constants";
// axiosConfig axios is for protected routes to node backend. python has none of
// this implemented so can't use custom axios have to use default axios
// import { axios } from "../utils/axiosConfig";

interface FetchBenchmarksInput {
  userId: string;
  comp: string;
  sequenceId: string;
}

export function useFetchBenchmarks(options: any) {
  return useMutation(async (fetchBenchmarksInput: FetchBenchmarksInput) => {
    try {
      const pyData: FetchBenchmarksInput = {
        userId: fetchBenchmarksInput.userId,
        comp: fetchBenchmarksInput.comp,
        sequenceId: fetchBenchmarksInput.sequenceId,
      };
      const { data } = await axios.post(
        `${PYTHON_REST_BASE_ENDPOINT}/related_titles`,
        pyData
      );
      // console.log("data from useFetchBenchmarks", data);
      if (data) {
        return data;
      }
    } catch (err) {
      console.log("err from useFetchBenchmarks", err.response.data);
      throw new Error(err.response.data.message);
    }
  }, options);
}
