import LoadingCube from "../LoadingCube/LoadingCube";
import styles from "./ContainerCard.module.scss";

const ContainerCard = ({ children, isLoading = true, title, actionComponent, className }) => (
    <div className={`${styles.card} ${className}`}>
        {isLoading ?
            <div className={styles.loadingContainer}>
                <LoadingCube hasBackground={false} height="30px" width="30px" />
            </div>
            : <>
                <div className={styles.cardHeader}>
                    <h4 className={styles.cardTitle}>{title}</h4>
                    {actionComponent}
                </div>
                {children}
            </>
        }
    </div>
);

export default ContainerCard;
