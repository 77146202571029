import Fuse from "fuse.js";
import React, { FC, useEffect, useState } from "react";
// components
import { MdArrowDropDown } from "react-icons/md";
import { useUserTagsQuery } from "../../../../../codeGenFE";
// Data
import { useSearchContext } from "../../../../context/allContexts";
import { SearchContextActions } from "../../../../context/allContexts/SearchContext/SearchContextTypes";
import DropdownDisplayBelow from "../../../DropdownDisplayBelow/DropdownDisplayBelow";
// styles
import styles from "./filters.module.scss";

interface Props {
  openFilter: string;
  setOpenFilter: (arg: string) => void;
}

const TagsFilter: FC<Props> = ({ openFilter, setOpenFilter }) => {
  // const { user } = useUserContext();
  // const { tagsState } = useTagsContext();

  const { data, error, isFetching } = useUserTagsQuery();
  const tags = data?.userTags;

  const { searchState, searchDispatcher } = useSearchContext();
  const tagFilterChoice = searchState?.tagFilterChoice;

  // const [tagInput, setTagInput] = useState("");
  // const [filteredTags, setFilteredTags] = useState([]);
  // const [tagDisplayed, setTagDisplayed] = useState("Tags");

  return (
    <div
      className={styles.outcomeFilterWrapper}
      data-is-open={`${openFilter === "tags"}`}
    >
      <DropdownDisplayBelow
        selectableItems={!!tags ? (tags as string[]) : []}
        isDropdownOpen={openFilter === "tags"}
        toggleDropdown={() =>
          setOpenFilter(openFilter === "tags" ? "" : "tags")
        }
        dropdownDisplay={"Tags"}
        selectionDisplay={tagFilterChoice}
        select={(tag) =>
          searchDispatcher({
            type: SearchContextActions.SET_TAG_SEARCH_PARAMETER,
            payload: tag,
          })
        }
        unselect={() =>
          searchDispatcher({
            type: SearchContextActions.SET_TAG_SEARCH_PARAMETER,
            payload: null,
          })
        }
        makeSearchable={true}
      />
    </div>
  );

  // const [tags, setTags] = useState([]);

  // useEffect(() => {
  //   if (user && user.data && user.data.tags) setTags(user.data.tags);
  // }, [user]);
  // useEffect(() => {
  //   if (tagsState && tagsState.tags && tagsState.tags.length > 0) {
  //     setTags(tagsState.tags);
  //   }
  // }, [tagsState]);

  // main dropdown
  // function toggleOpen() {
  //   if (openFilter === "tags") {
  //     setOpenFilter("");
  //   } else {
  //     setOpenFilter("tags");
  //   }
  // }

  // useEffect(() => {
  //   if (tagFilterChoice && tagFilterChoice !== "") {
  //     // capitalizes the Tag's name
  //     setTagDisplayed(tagFilterChoice);
  //   } else {
  //     setTagDisplayed("Tags");
  //   }
  // }, [tagFilterChoice]);

  // useEffect(() => {
  //   if (tagInput === "") {
  //     setFilteredTags(tags);
  //   } else {
  //     // Fuzzy search here
  //     const options = {
  //       shouldSort: true,
  //       threshold: 0.2,
  //       location: 0,
  //       distance: 500,
  //       maxPatternLength: 32,
  //       minMatchCharLength: 1,
  //       keys: [],
  //     };
  //     let fuse = new Fuse(tags, options);
  //     let result = fuse.search(tagInput).map((i) => i.item);
  //     setFilteredTags(result);
  //   }
  // }, [tagInput, tags]);

  // function changeTag(tag) {
  //   if (tag === "") {
  //     searchDispatcher({
  //       type: SearchContextActions.SET_TAG_SEARCH_PARAMETER,
  //       payload: null,
  //     });
  //     toggleOpen();
  //   } else {
  //     searchDispatcher({
  //       type: SearchContextActions.SET_TAG_SEARCH_PARAMETER,
  //       payload: tag,
  //     });
  //     toggleOpen();
  //   }
  // }

  // if (isFetching) {
  //   return (
  //     <div className={styles.dropdown__container}>
  //       <div
  //         className={`${styles.filterDisplay} ${styles.filterDisplay__close}`}
  //       >
  //         <p className={styles.filterName}>Loading Tags...</p>
  //         <span className={styles.downArrow}>
  //           <MdArrowDropDown />
  //         </span>
  //       </div>
  //     </div>
  //   );
  // }

  // if (!data?.userTags) {
  //   return (
  //     <div className={styles.dropdown__container}>
  //       <div
  //         className={`${styles.filterDisplay} ${styles.filterDisplay__close}`}
  //       >
  //         <p className={styles.filterName}>No Tags Found...</p>
  //         <span className={styles.downArrow}>
  //           <MdArrowDropDown />
  //         </span>
  //       </div>
  //     </div>
  //   );
  // }

  // return (
  //   <div className={styles.dropdown__container}>
  //     <div
  //       className={`${styles.filterDisplay} ${
  //         openFilter === "tags"
  //           ? styles.filterDisplay__open
  //           : styles.filterDisplay__close
  //       }`}
  //       onClick={() => toggleOpen()}
  //     >
  //       <p className={styles.filterName}>{tagDisplayed}</p>
  //       <span className={styles.downArrow}>
  //         <MdArrowDropDown
  //           style={{
  //             transform: `rotate(${openFilter === "tags" ? "180" : "0"}deg)`,
  //           }}
  //         />
  //       </span>
  //     </div>

  //     <div
  //       className={`${styles.dropdown__selectionList} ${
  //         styles.dropdown__selectionList__limitOverflow
  //       } ${openFilter === "tags" ? null : styles.dropdown__closed} ${
  //         styles.darkTheme__light
  //       }`}
  //     >
  //       {/* Search box */}
  //       <div className={`${styles.dropdown__item} ${styles.searchInput__div}`}>
  //         <input
  //           type="text"
  //           placeholder="Search"
  //           value={tagInput}
  //           onChange={(e) => setTagInput(e.target.value)}
  //           className={`
  //           ${styles.searchInput__input}
  //           ${styles.darkTheme__light}
  //         `}
  //         />
  //       </div>
  //       {/* Reset Button */}
  //       <div className={`${styles.dropdown__item} `}>
  //         <button
  //           onClick={() => {
  //             changeTag("");
  //             setTagInput("");
  //           }}
  //           className={`${styles.dropdown__btn} ${styles.resetSingleFilter}
  //             ${styles.darkTheme__light}
  //           `}
  //         >
  //           Reset
  //         </button>
  //       </div>
  //       {/* <CategoriesDropdown categories={categories} /> */}
  //       {filteredTags &&
  //         filteredTags
  //           .sort((a, b) => a.toLowerCase() > b.toLowerCase())
  //           .map((tag) => {
  //             return (
  //               <div key={tag} className={`${styles.dropdown__item} `}>
  //                 <button
  //                   onClick={() => changeTag(tag)}
  //                   className={`${styles.dropdown__btn}
  //                 ${styles.darkTheme__light}
  //                 `}
  //                 >
  //                   {tag}
  //                 </button>
  //               </div>
  //             );
  //           })}

  //       {/* display loading below */}
  //       {isFetching ? (
  //         <div className={`${styles.dropdown__item} `}>
  //           <button
  //             className={`${styles.dropdown__btn}
  //             ${styles.darkTheme__light}
  //           `}
  //           >
  //             Loading ...
  //           </button>
  //         </div>
  //       ) : null}
  //     </div>
  //   </div>
  // );
};

export default TagsFilter;
