import { FC } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { SortBy } from "../SelectANote";
import styles from "./SANSortByDropDown.module.scss";

interface Props {
  showSortDropdown: boolean;
  SANDispatch: any;
  sortBy: SortBy;
}

const SANSortByDropDown: FC<Props> = ({
  showSortDropdown,
  SANDispatch,
  sortBy,
}) => {
  return (
    <div
      className={styles.sortDropDown__wrapper}
      style={{ marginBottom: `${showSortDropdown ? "100px" : "20px"}` }}
    >
      <div className={styles.sortAndArrowContainer}>
        <p
          className={styles.sortP}
          onClick={() => SANDispatch({ type: "showSortDropdown" })}
        >
          Sort By: {sortBy}
        </p>
        <IoMdArrowDropdown
          className={styles.sortDropDownArrow}
          onClick={() => SANDispatch({ type: "showSortDropdown" })}
        />
      </div>
      {showSortDropdown && (
        <div className={styles.sortSelectionWrapper}>
          <p
            onClick={() => {
              SANDispatch({ type: "reset" });
            }}
            className={styles.sortSelectionText}
          >
            Reset
          </p>
          <p
            onClick={() =>
              SANDispatch({ type: "selectSort", sortBy: SortBy.DATE })
            }
            className={styles.sortSelectionText}
          >
            Date
          </p>
          <p
            onClick={() =>
              SANDispatch({ type: "selectSort", sortBy: SortBy.NAME })
            }
            className={styles.sortSelectionText}
          >
            Name
          </p>
          <p
            onClick={() =>
              SANDispatch({ type: "selectSort", sortBy: SortBy.SHARED_BY })
            }
            className={styles.sortSelectionText}
          >
            Shared By
          </p>
        </div>
      )}
    </div>
  );
};

export default SANSortByDropDown;
