import * as React from "react";
import styles from "./ArrowNotebook30.module.scss";

function SvgArrowNotebook30(props) {
  return (
    <svg
      id="arrow-notebook-30_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 50 50"
      {...props}
    >
      <g id="arrow-notebook-30_svg__Group_2475" data-name="Group 2475">
        <path
          id="arrow-notebook-30_svg__Line_216"
          data-name="Line 216"
          className={styles.arrowPath}
          d="M32.56 8.5L16.47 25"
        />
        <path
          id="arrow-notebook-30_svg__Line_217"
          data-name="Line 217"
          className={styles.arrowPath}
          d="M32.56 41.5L16.47 25"
        />
      </g>
    </svg>
  );
}

export default SvgArrowNotebook30;
