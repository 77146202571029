import axios from "axios";
import { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { toast } from "react-toastify";

import ContainerCard from "../../../Components/ContainerCard";

import { API_BASE_URL, TECH_M_ENDPOINTS } from "../../../Constants";
import {convertAPIResponseToBarChartData, convertData, getRandomColor} from "../graphUtils";



const FinancialTab = ({ currentCompany }) => {
  const [loading, setLoading] = useState(true);

  const [finData, setFinData] = useState({});

  const getFinancialData = async (company) => {
    console.log("company", company);
    setLoading(true);
    await axios
      .get(API_BASE_URL + TECH_M_ENDPOINTS.FINANCIAL(company))
      .then((res) => {
        setFinData(res.data.data);
        console.log(res.data.data.peerAnalysis.data["Dec-21"]);
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

const revenueCompChartConfig = {
   borderRadius: 30,
  // width: 11,
 plugins: {
    legend: {
    height: '40px',
    labels: { 
      color : "black",
      font: {
        size: 12,      
      },  
      borderRadius: 30,
      height: '110px',
    }
  },
 },
  scales: {
    x: {
      title: {
        display: true,
        text: "Years",
      },
    },
    y: {
      title: {
        display: true,
        text: "Revenue in (%)",
      },
    },
  },
};

const acctRevChartConfig = {
 plugins: {
    legend: {
    labels: { 
      color : "black",
      font: {
        size: 12,      
      },  
      borderRadius: 30,
      height: '40px',
    }
  },
 },
  scales: {
    x: {
      title: {
        display: true,
        text: "Years",
      },
    },
    y: {
      title: {
        display: true,
        text: currentCompany === "airbus" ? "Revenue in EUR Millions" : "Revenue (US$ Millions)",
      },
    },
  },
};


const peerChartConfig = {
 plugins: {
    legend: {
    labels: { 
      color : "black",
      font: {
        size: 12,      
      },  
      borderRadius: 30,
      height: '40px',
    }
  },
 },
  scales: {
    x: {
      title: {
        display: true,
        text: "Years",
      },
    },
    y: {
      title: {
        display: true,
        text: "Percentage Profitability",
      },
    },
  },
};

const itExpChartConfig = {
 plugins: {
    legend: {
    labels: { 
      color : "black",
      font: {
        size: 12,      
      },  
      borderRadius: 30,
      height: '40px',
    }
  },
 },
  scales: {
    y: {
      title: {
        display: true,
         text: currentCompany === "airbus" ? "Value in Mil-EUR" : "Value in US$ Millions",
      },
    },
  },
};

  useEffect(() => {
    if (currentCompany) getFinancialData(currentCompany);
  }, [currentCompany]);

  return (
    <div className="grid grid-cols-2 gap-3 mx-2 mb-3">
      <ContainerCard title={"Revenue Comparison"} isLoading={loading}>
        {finData.revenue && (
          <Bar
            data={convertAPIResponseToBarChartData(finData.revenue)}
            options={revenueCompChartConfig}
          />
        )}
      </ContainerCard>
      <ContainerCard title={"Account Revenue"} isLoading={loading}>
        {finData.account && (
          <Line
            data={convertAPIResponseToBarChartData(finData.account)}
            options={acctRevChartConfig}
          />
        )}
      </ContainerCard>
      <ContainerCard
        title={"IT expenditure"}
        className="col-span-2"
        isLoading={loading}
      >
        {finData.itExpenditure && (
          <Line
            data={convertAPIResponseToBarChartData(finData.itExpenditure)}
            options={itExpChartConfig}
          />
        )}
      </ContainerCard>
      <ContainerCard
        title={"Profitability Peer Comparison"}
        isLoading={loading}
      >
        {finData?.peerAnalysis && (
          <Line
            data={convertData(
              finData?.peerAnalysis?.data
            )}
            options={peerChartConfig}
          />
        )}
      </ContainerCard>
    </div>
  );
};

export default FinancialTab;
