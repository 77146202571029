import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  removeTagFromMultiArticleFromContext,
  removeTagFromSingleArticleFromContext,
} from "../../util/removeTagFromContextUtil";
import { useUserContext } from "../allContexts";

export const PrivateUploadsContext = createContext();

export function PrivateUploadsProvider(props) {
  // const { userState } = useContext(UserContext);
  const { userState } = useUserContext();
  const [privateUploads, setPrivateUploads] = useState([]);
  const [privateUploadsDaysOnState, setPrivateUploadsDaysOnState] = useState(0);
  let requestCount = 0;

  const fetchPrivateUploads = async ({ days = 14, globalFilterDate }) => {
    if (
      globalFilterDate * 2 > privateUploadsDaysOnState ||
      (privateUploadsDaysOnState !== "all" && globalFilterDate === "all")
    ) {
      let oldestSortDateOnState;
      if (privateUploads.length === 0) {
        oldestSortDateOnState = Math.round(new Date().getTime() / 1000);
      } else {
        oldestSortDateOnState =
          privateUploads[privateUploads.length - 1]._source.sortDate;
      }

      try {
        requestCount++;

        // TODO: PERFORMANCE UPGRADE ON PROMISES BELOW

        // private text uploads
        let textUploadsResponse = await axios.get("/api/newsData/notes", {
          params: {
            days: days,
            oldestSortDateOnState: oldestSortDateOnState,
            requestCount: requestCount,
          },
        });

        // TODO: add debounce request count here when you come back for performance upgrade on promises
        // private uploaded articles
        let urlUploadsResponse = await axios.get(
          // "https://app.nowigence.ai/api/newsData/private",
          // TODO: Change back before pushing to v2
          "/api/newsData/private",
          {
            params: {
              days: days,
              oldestSortDateOnState: oldestSortDateOnState,
            },
          }
        );
        // checking request count so we only take in the most recent request and not multiple requests (creating multiple articles in the context)

        const resRequestCount = Number(textUploadsResponse.data.requestCount);

        if (
          urlUploadsResponse.status === 200 &&
          textUploadsResponse.status === 200 &&
          resRequestCount === requestCount
        ) {
          setPrivateUploadsDaysOnState(days);
          setPrivateUploads([
            ...privateUploads,
            ...urlUploadsResponse.data.data,
            ...textUploadsResponse.data.data,
          ]);
        }
      } catch (err) {
        console.log("err: ", err);
      }
    } else {
      // Private uploads isn't fetching because it already has it all on state
    }
  };

  const fetchPrivateUploadsInMyMemory = async ({
    days = 14,
    globalFilterDate,
  }) => {
    if (
      globalFilterDate * 2 > privateUploadsDaysOnState ||
      (privateUploadsDaysOnState !== "all" && globalFilterDate === "all")
    ) {
      let res = await fetchPrivateContentInMyMemory(days);
      return res;
    } else {
      return false;
    }
  };

  const fetchPrivateContentInMyMemory = async (days) => {
    let requestCount = 0;
    let oldestSortDateOnState;
    if (privateUploads.length === 0) {
      oldestSortDateOnState = Math.round(new Date().getTime() / 1000);
    } else {
      oldestSortDateOnState =
        privateUploads[privateUploads.length - 1]._source.sortDate;
    }
    try {
      requestCount++;

      // TODO: PERFORMANCE UPGRADE ON PROMISES BELOW

      // private text uploads
      let textUploadsResponse = await axios.get("/api/newsData/notes", {
        params: {
          days: days,
          oldestSortDateOnState: oldestSortDateOnState,
          requestCount: requestCount,
        },
      });

      // TODO: add debounce request count here when you come back for performance upgrade on promises
      // private uploaded articles
      let urlUploadsResponse = await axios.get(
        // "https://app.nowigence.ai/api/newsData/private",
        // TODO: Change back before pushing to v2
        "/api/newsData/private",
        {
          params: {
            days: days,
            oldestSortDateOnState: oldestSortDateOnState,
          },
        }
      );
      // checking request count so we only take in the most recent request and not multiple requests (creating multiple articles in the context)

      const resRequestCount = Number(textUploadsResponse.data.requestCount);

      if (
        urlUploadsResponse.status === 200 &&
        textUploadsResponse.status === 200 &&
        resRequestCount === requestCount
      ) {
        setPrivateUploadsDaysOnState(days);
        setPrivateUploads([
          ...privateUploads,
          ...urlUploadsResponse.data.data,
          ...textUploadsResponse.data.data,
        ]);
      }
      return true;
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const clearPrivateUploadsContext = () => {
    setPrivateUploads([]);
    setPrivateUploadsDaysOnState(0);
  };

  // delete private news article
  const deletePrivateArticle = (article) => {
    let updatedNewsArray;

    const WhichDb =
      article._source.orig_url === "No url for this file" ? "text" : "url";
    const data = {
      WhichDb: WhichDb,
      articleId: article._id,
      articleTitle: article._source.title,
      privateOrNot: WhichDb === "url" ? "private" : "not",
    };

    axios
      .post("/api/articles", data)
      .then(async (res) => {
        if (res.status === 200) {
          updatedNewsArray = privateUploads.filter(
            (privateArticle) => privateArticle._id !== article._id
          );
          setPrivateUploads(updatedNewsArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addOneUploadedArticle = (article) => {
    const updatedUploads = [article, ...privateUploads];
    setPrivateUploads(updatedUploads);
  };
  const addMultipleUploadedArticles = (articles) => {
    const updatedUploads = [...articles, ...privateUploads];
    setPrivateUploads(updatedUploads);
  };

  useEffect(() => {}, [privateUploads]);

  const removePrivateTags = (tag, articleId = null) => {
    const updatedPrivUploads = removeTagFromMultiArticleFromContext(
      tag,
      privateUploads,
      userState._id
    );
    setPrivateUploads(updatedPrivUploads);
  };

  const removeSinglePrivateTags = (tag, articleId, articles, userId) => {
    const updatedPrivateUploads = removeTagFromSingleArticleFromContext(
      tag,
      articleId,
      articles,
      userId
    );
    setPrivateUploads(updatedPrivateUploads);
  };

  const addSinglePrivateTag = (tagToAdd, articleId, userId) => {
    const updated = privateUploads.map((article) => {
      if (article._id === articleId) {
        if (!article.tags) {
          article.tags = {};
        }
        if (!article.tags[userId]) {
          article.tags[userId] = [];
        }
        article.tags[userId].push(tagToAdd);
      }
      return article;
    });
    setPrivateUploads(updated);
  };

  const updatePrivateArticle = (article) => {
    const updatedPrivateArticles = privateUploads.map((currentArt) => {
      if (currentArt._id === article._id) {
        return article;
      }
      return currentArt;
    });
    setPrivateUploads(updatedPrivateArticles);
  };

  const filterPrivateArticle = (id) => {
    const updatedArticles = privateUploads.filter(
      (article) => article._id !== id
    );
    setPrivateUploads(updatedArticles);
  };

  return (
    <PrivateUploadsContext.Provider
      value={{
        fetchPrivateUploads,
        privateUploads,
        privateUploadsDaysOnState,
        clearPrivateUploadsContext,
        deletePrivateArticle,
        addOneUploadedArticle,
        addMultipleUploadedArticles,
        removePrivateTags,
        removeSinglePrivateTags,
        addSinglePrivateTag,
        updatePrivateArticle,
        filterPrivateArticle,
        setPrivateUploadsDaysOnState,
        setPrivateUploads,
        fetchPrivateContentInMyMemory,
        fetchPrivateUploadsInMyMemory,
      }}
    >
      {props.children}
    </PrivateUploadsContext.Provider>
  );
}

export const usePrivateUploadsContext = () => useContext(PrivateUploadsContext);

// NOTES:
// axios url to get private text uploads "https://app.nowigence.ai/api/newsData/notes";
