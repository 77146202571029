import React, { FC, ReactElement } from "react";

import styles from "./DashErrorMessage.module.scss";

interface Inputs {
  error: string | null | undefined;
  isFetching: boolean;
  refetch: () => void;
}

const DashErrorMessage: FC<Inputs> = ({
  error,
  isFetching,
  refetch,
}): ReactElement => {
  return (
    <div className={styles.container}>
      <p>{error}</p>
      <button onClick={() => refetch()}>
        {isFetching ? "Recalculating..." : "Retry"}
      </button>
    </div>
  );
};

export default DashErrorMessage;
