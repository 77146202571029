import { PYTHON_REST_BASE_ENDPOINT } from "../../constants";
import { useMutation, useQuery } from "react-query";
import axios from "axios";

interface FetchTopicsInput {
  topic: string;
  excludedTopicKeywords: string | string[];
  topicType: string;
  subscriptionReason: string;
  marketCode: string[];
  comp: string;
  userId: string;
}

// const fetchTopicsFromPython = async (vars: FetchTopicsInput) => {
const fetchTopicsFromPython = async (data: FetchTopicsInput) => {
  // console.log("data", data);

  try {
    const { data: resData } = await axios.post(
      `${PYTHON_REST_BASE_ENDPOINT}/topics_check`,
      data
    );
    // console.log("res from useFetchTopicsFromPython", resData);
    if (!resData) throw "@No more data";
    return resData;
  } catch (err) {
    console.log("err from useFetchTopicsFromPython", err.response.data);
    throw new Error(err.response.data.message);
  }
};

export const useFetchTopicsFromPython = (
  vars: FetchTopicsInput,
  options?: any
) => {
  return useQuery(
    "Fetch_Topics_From_Python",
    () => fetchTopicsFromPython(vars),
    options
  );
};

// export function useFetchTopicsFromPython(options: any) {
//   return useMutation(async (vars: FetchTopicsInput) => {
//     console.log("vars", vars);
//     try {
//       // const pyData: FetchTopicsInput = {
//       //   userId: ,
//       //   comp: fetchBenchmarksInput.comp,
//       //   sequenceId: fetchBenchmarksInput.sequenceId,
//       // };
//       const {
//         data,
//       } = await axios.post(`${PYTHON_REST_BASE_ENDPOINT}/topics_check`, {
//         data: vars,
//       });
//       console.log("data from useFetchTopicsFromPython", data);
//       if (data) {
//         return data;
//       }
//     } catch (err) {
//       console.log("err from useFetchTopicsFromPython", err.response.data);
//       throw new Error(err.response.data.message);
//     }
//   }, options);
// }
