import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { PYTHON_REST_BASE_ENDPOINT } from "../../constants";
// axiosConfig axios is for protected routes to node backend. python has none of
// this implemented so can't use custom axios have to use default axios
// import { axios } from "../utils/axiosConfig";

interface PersistTopicsCheckInput {
  userId: string;
  comp: string;
  sequenceIds: string[];
}

export function usePersistTopicsCheck(options?: any) {
  // console.log("running usePersistTopicsCheck");

  return useMutation(
    async (persistTopicsCheckInput: PersistTopicsCheckInput) => {
      try {
        const data = persistTopicsCheckInput;
        const { data: resData } = await axios.post(
          `${PYTHON_REST_BASE_ENDPOINT}/persist_topics_check`,
          data
        );
        if (resData) {
          return resData;
        }
      } catch (err) {
        throw new Error(err.response.data.message);
      }
    },
    options
  );
}
