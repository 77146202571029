import styles from "./SwotAnalysis.module.scss";
import strength from "../../../../assets/img/strength.png";
import opportunities from "../../../../assets/img/opportunities.png";
import weakness from "../../../../assets/img/weakness.png";
import threats from "../../../../assets/img/threats.png";

export default function SwotAnalysis({ analysis }) {
  console.log(analysis.swot);

  const data = [
    {
      image: `${strength}`,
      heading: "Strength",
      headingColor: "#48991E",
      para: analysis.swot[0].content.split("\n"),
    },
    {
      image: `${weakness}`,
      heading: "Weakness",
      headingColor: "#4F77CC",
      para: analysis.swot[1].content.split("\n"),
    },
    {
      image: `${opportunities}`,

      heading: "Opportunities",
      headingColor: "#B8690C",
      para: analysis.swot[2].content.split("\n"),
    },
    {
      image: `${threats}`,
      heading: "Threats",
      headingColor: "#FC0404",
      para: analysis.swot[3].content.split("\n"),
    },
  ];
  return (
    <>
      <div className={styles.fontSize + " my-5"}>
        <h2 className="text-center  fs-2 fw-bold my-5">SWOT Analysis</h2>
        <div className={styles.image + " d-flex justify-content-between"}>
          {data.map(({ image, heading, para, headingColor }) => (
            <div className="w-25 mx-3">
              <div className={" rounded-2 p-3 "}>
                <div>
                  <div
                    style={{ width: "110px", height: "110px" }}
                    className="mx-auto p-4 bg-white shadow-sm rounded-circle"
                  >
                    <img
                      width="100px"
                      className="mx-auto"
                      src={image}
                      alt={image}
                    />
                  </div>
                </div>

                <h4 className="text-center fw-bold my-3 fs-3">
                  <div style={{ color: `${headingColor}` }}>{heading}</div>
                </h4>
                <ul className={styles.ul}>
                  {para.map((item) => (
                    <li className="text-start text-secondary mx-auto my-3 fs-6 lh-sm">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
