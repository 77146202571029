import React, { Suspense, useState, useEffect } from "react";
import { Route } from "react-router-dom";
import styles from "../App.scss";
import { LOCAL_STORAGE_KEYS, REST_BASE_ENDPOINT } from "../constants";
import ErrorBoundary from "../v2/Components/ErrorBoundary/ErrorBoundary";
import LoadingCube from "../v2/Components/LoadingCube/LoadingCube";
import HomeLight from "../v2/Pages/LandingPage/HomeLight";
import { ToastContainer } from "react-toastify";
import { axios } from "../utils/axiosConfig";
import ForgotPasswordScreen from "../v2/Pages/Login/NewLogin/ForgotPasswordScreen";
const LoginComponent = React.lazy(() => import("../v2/Pages/Login/Login"));
const LandingPage = React.lazy(() => import("../v2/Pages/LandingPage/Home2"));
const Footer = React.lazy(() => import("../v2/Pages/LandingPage/Footer"));
const LightTheme = React.lazy(() =>
  import("../v2/Pages/LandingPage/HomeLight")
);
const TermsAndConditions = React.lazy(() =>
  import("../v2/Pages/TermsAndConditions/TermsAndConditions")
);
const LoginIn = React.lazy(() => import("../v2/Pages/Login/NewLogin/LoginIn"));
const SignUp = React.lazy(() => import("../v2/Pages/SignUp/SignUp/SignUp"));
const SignUpLight = React.lazy(() =>
  import("../v2/Pages/SignUp/SignUp/SignUpLight")
);
const LoginLight = React.lazy(() =>
  import("../v2/Pages/Login/NewLogin/LoginLight")
);
const Marketing = React.lazy(() => import("../v2/Pages/Marketing/Marketing"));
const NewUser = React.lazy(() => import("../v2/Components/NewUser/NewUser"));
const ResetPass = React.lazy(() => import("../v2/Pages/ResetPass/ResetPass"));

// const RegisterComponent = React.lazy(() => import("../Register/Register"));

const UnauthenticatedApp = () => {
  const [theme, setTheme] = useState("");

  useEffect(() => {
    var myHeaders = new Headers();
    if (localStorage.getItem("userId") === null) {
      axios
        .get(`${REST_BASE_ENDPOINT}/auth/landing/tempId`)
        .then((result) => {
          storeUserinLocal(result.data);
          window.location.reload();
          console.log(result);
        })
        .catch((error) => console.log("error", error));

      function storeUserinLocal(result) {
        localStorage.setItem("companyId", result.data.companyId);
        localStorage.setItem("userId", result.data.userId);
        return console.log(result);
      }
    }

    const userId = localStorage.getItem("userId");
    console.log(userId);

    console.log("got here");
    setTheme(lastDigitThemeHandler(userId));
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.THEME,
      lastDigitThemeHandler(userId)
    );
  }, []);

  const lastDigitThemeHandler = (item) => {
    var b = true;

    console.log(b);
    if (b) {
      return "light_theme";
    }
  
    // if (b.toLowerCase() === b.toUpperCase()) {
    //   console.log("number");
    //   return "light_theme";
    // } else {
    //   console.log("stringed");
    //   return "light_theme";
    // }
  };

  return (
    <>
      <>
        <ToastContainer />
        <Suspense
          fallback={
            <div>
              <LoadingCube isFullScreen={true} width={100} height={100} />
            </div>
          }
        >
          {theme === "dark_theme" && (
            <>
              <Route
                exact
                path="/"
                component={LandingPage}
                className={styles.custom_style}
              />
              <Route
                exact
                path="/signupdark"
                component={SignUp}
                className={styles.custom_style}
              />
              <Route
                exact
                path="/loginlight"
                component={LoginIn}
                className={styles.custom_style}
              />
            </>
          )}
          {theme === "light_theme" && (
            <>
              <Route
                exact
                path="/"
                component={() => <HomeLight myProp="custom_font" />}
              />{" "}
              <Route exact path="/signuplight" component={SignUpLight} />
              <Route exact path="/loginlight" component={LoginLight} />
            </>
          )}
          <Route
            exact
            path="/termsandconditions"
            component={TermsAndConditions}
          />{" "}
          <Route exact path="/marketing" component={Marketing} />
          <Route exact path="/new-user" component={NewUser} />
          <Route path="/resetPass" component={ResetPass} />
          <Route
            exact
            path="/forgot-password"
            component={ForgotPasswordScreen}
          />
        </Suspense>
      </>
    </>
  );
};

export default UnauthenticatedApp;
