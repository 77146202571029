import React, { useState, useEffect, FC } from "react";
import { AiOutlineCheck } from "react-icons/ai";

import AskMeTopicForm from "../../../Components/TopicForm/AskMeTopicForm";
import CloseModalBtn, {
  CloseModalBtnTheme,
} from "../../../Components/CloseModalBtn/CloseModalBtn";

import { useUserProfileContext } from "../../../context/allContexts";

import styles from "./askMeModal.module.scss";

interface Props {
  close: () => void;
  topic: string;
}

const AskMeModal: FC<Props> = ({ close, topic }) => {
  // const [shouldRender, setShouldRender] = useState(false);

  const handleCloseEvent = () => {
    close();
  };

  // useEffect(() => {
  //   if (showAskMeTopicModal) setShouldRender(true);
  // }, [showAskMeTopicModal]);

  // const onAnimationEnd = () => {
  //   if (!showAskMeTopicModal) setShouldRender(false);
  // };

  // return shouldRender ? (
  return (
    <React.Fragment>
      <div
        // onAnimationEnd={onAnimationEnd}
        // className={`${
        //   showAskMeTopicModal ? styles.backdrop : styles.noBackdrop
        // }`}
        className={`${styles.backdrop}`}
        onClick={() => close()}
      />
      <div
        className={`${styles.askMeModalWrapper} ${
          // showAskMeTopicModal ? styles.askMeModalWrapper : styles.slideUp
          styles.askMeModalWrapper
        }`}
        // onAnimationEnd={onAnimationEnd}
      >
        <div className={styles.closeBtn}>
          <CloseModalBtn theme={CloseModalBtnTheme.LIGHT} close={close} />
        </div>
        <div className={styles.topicFormWrapper}>
          <AskMeTopicForm
            close={handleCloseEvent}
            disableTopic={true}
            isAskMeTopicModal={true}
            topic={topic}
            submitWithArticles={true}
          />
        </div>
      </div>
    </React.Fragment>
    // ) : null;
  );
};

// <AskMeModalInputs handleClose={handleCloseEvent}></AskMeModalInputs>

export default AskMeModal;
