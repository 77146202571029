import React, { useState } from "react";

// components
import InfoButton from "../../../Icons/InfoButton";
// import ThirdPartyIntegration from "./ThirdPartyIntegration/ThirdPartyIntegration";
import MainNavigation from "../../Components/MainNavigation/MainNavigation";
import TopMenu from "../../Components/TopMenu/TopMenu";
import ChangePassword from "./ChangePassword/ChangePassword";
import AccountFooter from "./AccountFooter/AccountFooter";

// data
import { useUserContext, useViewPortContext } from "../../context/allContexts";

// images
import securityBot from "../../../assets/img/securityBot.png";

// styles
import styles from "./Account.module.scss";
import { useRewardsContext } from "../../context/allContexts/RewardsContext";
import { Link } from "react-router-dom";
import {
  useOTPSend,
  useOTPVerify,
} from "../../../rq-hooks/useEmailVerification";
import toast from "react-hot-toast";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../Components/LoadingCube/LoadingCube";
import { BsCheckCircle } from "react-icons/bs";
import { isNumber } from "lodash";
import { useNFTBadge } from "../../../rq-hooks/useNFTBadge";
import { AiOutlineCopy } from "react-icons/ai";
import { useSDKCredGenerate } from "../../../rq-hooks/useSDK";
import MyAccountInfoModal from "./MyAccountInfoModal";

const Account = () => {
  const {
    isVerified,
    setIsVerified,
    user,
    nftBadge,
    setNftBadge,
    setNftErrorCounter,
    clientId,
    clientSecret,
    setClientId,
    setClientSecret,
  } = useUserContext();
  const { rewardsState } = useRewardsContext();
  const [showChangePass, setShowChangePass] = useState(false);
  const [showValidateOTPForm, setShowValidateOTPForm] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [badge, setBadge] = useState(nftBadge);
  const [otp, setOTP] = useState({
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n5: "",
    n6: "",
  });
  const { width } = useViewPortContext();
  const [error, setError] = useState({
    n1: false,
    n2: false,
    n3: false,
    n4: false,
    n5: false,
    n6: false,
  });
  const { mutate, isLoading } = useOTPSend({
    onSuccess: (message) => {
      // refetchMe()
      toast.success(message);
      setResendOTP(false);
      setShowValidateOTPForm(true);
    },
  });

  const { mutate: generateSDKCred, isLoading: generatingSDKCred } =
    useSDKCredGenerate({
      onSuccess: (data) => {
        setClientId(data?.clientId);
        setClientSecret(data?.clientSecret);
      },
      onError: () => {},
    });

  /** Commented this code for this release */
  // const { mutate: nftVerify, isLoading: nftLoading } = useNFTBadge({
  //   onSuccess: (data) => {
  //     console.log(data);
  //     toast.success("Email Verified!");
  //     setIsVerified(true);
  //     setShowValidateOTPForm(false);
  //     setBadge(data?.nft_url);
  //     setNftBadge(data?.nft_url);
  //   },
  //   onError: (data) => {
  //     console.log(data);
  //     setIsVerified(true);
  //     setShowValidateOTPForm(false);
  //     setNftErrorCounter((prevCounter) => {
  //       prevCounter++;
  //       return prevCounter++;
  //     });
  //     toast.error(data.message);
  //   },
  // });
  const { mutate: otpVerifyMutate, isLoading: otpVerifyLoading } = useOTPVerify(
    {
      onSuccess: (message) => {
        // nftVerify();
        setIsVerified(true);
        toast.success("Email Verified!");
      },
      onError: () => {
        setError({
          n1: true,
          n2: true,
          n3: true,
          n4: true,
          n5: true,
          n6: true,
        });
        toast.error("Invalid OTP");
      },
    }
  );

  const validate = () => {
    let errorStatus = false;
    Object.keys(otp).forEach((key) => {
      if (!otp[key] || (otp[key] && otp[key].length === 0)) {
        errorStatus = true;
        setError((prevError) => ({ ...prevError, [key]: true }));
      }
      return errorStatus;
    });
  };
  const handleOTP = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name && value) {
      const splittedName = name.split("");
      const counter = parseInt(splittedName[1]);
      if (counter <= 6) {
        const nextSibling = document.querySelector(
          `input[name=n${counter + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
      setOTP((prevOTP) => ({ ...prevOTP, [name]: value }));
      // setFocusInput()
      setError((prevOTP) => ({
        ...prevOTP,
        n1: false,
        n2: false,
        n3: false,
        n4: false,
        n5: false,
        n6: false,
      }));
    }
  };
  const sendOTP = (type = false) => {
    if (type) {
      setResendOTP(true);
    }
    mutate();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const invalid = validate();
    if (invalid) {
      return;
    }
    let mergedOTP = Object.values(otp).join("");
    otpVerifyMutate({
      otp: mergedOTP,
    });
  };
  const errorMessage = () => {
    const errorFound = Object.keys(error).find((key) => error[key] === true);
    return errorFound && <span>Invalid OTP!</span>;
  };
  const handleBackSpace = (e) => {
    const name = e.target.name;
    if (e.key === "Backspace") {
      setOTP((prevOTP) => ({ ...prevOTP, [e.target.name]: "" }));
      const splittedName = name.split("");
      const counter = parseInt(splittedName[1]);
      if (counter >= 1) {
        const nextSibling = document.querySelector(
          `input[name=n${counter - 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };
  const handlePaste = (e) => {
    const initialOTP = otp;
    const otpPasted = e.clipboardData.getData("Text");
    if (isNumber(parseInt(otpPasted)) && otpPasted.length === 6) {
      const splittedOTPPasted = otpPasted.split("");
      setOTP((prevOTP) => {
        let newOtp = {};
        splittedOTPPasted.forEach(
          (item, index) => (newOtp[`n${index + 1}`] = item)
        );
        return { ...prevOTP, ...newOtp };
      });
    }
  };
  const handleClick = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Text Copied!");
  };
  const hiddenText = (text) => {
    const splittedText = text.split("-");
    let convertedText = "";
    splittedText.forEach((textPart) => {
      const xChars = new Array(textPart.length + 1).join("x");
      convertedText += `${xChars}-`;
    });
    return convertedText.substring(0, convertedText.length - 1);
  };
  // console.log(clientId, clientSecret)
  return (
    <div className={styles.accountContainer}>
      <div className={styles.topMenu}>
        <TopMenu showSearch={false} showRewards={true} />
      </div>
      <main className={styles.accountMain}>
        <div className={styles.leftMenu}>
          <MainNavigation />
        </div>
        <div className={styles.accountPageWrap}>
          <div className={styles.accountContent}>
            <div className={styles.imgNTitleWrapper}>
              {/* <div className={styles.imgWrapper}>
                <img
                  src={securityBot}
                  alt="Robot/Computer logo, that has a safe's keypad on screen"
                />
              </div> */}
              <div className={styles.header__div}>
                <h3 className={styles.header__h3}>My Account</h3>
                <InfoButton 
                  onClick={() => setShowInfo(true)}
                  style={{cursor: "pointer"}}
                />
                {/* <PlayButton /> */}
              </div>
            </div>
            <div className={styles.accountInfo}>
              <div className={styles.accountInfo__div}>
                <p className={styles.accountInfo__p}>
                  First Name:
                  <span className={styles.accountInfo__span}>{user.name}</span>
                </p>
              </div>
              <div className={styles.accountInfo__div}>
                <p className={styles.accountInfo__p}>
                  Last Name:
                  <span className={styles.accountInfo__span}>
                    {user.lastName}
                  </span>
                </p>
              </div>
              <div className={styles.emailWrapper}>
                <div className={styles.emailInputButton}>
                  <div className={styles.accountInfo__div}>
                    <p className={styles.accountInfo__p}>
                      Email:
                      <span className={styles.accountInfo__span}>
                        {user.email}
                      </span>
                    </p>
                  </div>
                  <div className={styles.verifyEmail}>
                    {!isVerified && !showValidateOTPForm && (
                      <a
                        href="javascript:void(0);"
                        onClick={() => sendOTP()}
                        className={isLoading ? styles.button__loading : ""}
                      >
                        <span class="button__text">
                          {!isLoading ? "Verify" : ""}
                        </span>
                      </a>
                    )}
                    {isVerified && (
                      <BsCheckCircle
                        className={styles.verified}
                        title={"Email-id verified"}
                      />
                    )}
                  </div>
                </div>
                {showValidateOTPForm && (
                  <div className={styles.container}>
                    <div className={`${styles.row}`}>
                      <div className={`${styles.colMd4} text-center`}>
                        <div className={`${styles.row}`}>
                          <div
                            className={`${styles.colSm12} ${styles.mt5} ${styles.bgWhite}`}
                          >
                            <div className={styles.subHeader}>
                              <p>
                                Please enter the one time password to verify
                                your email
                              </p>
                              <p>
                                OTP has been sent to your registered email
                                address
                              </p>
                            </div>
                            <div className={styles.mt5}>
                              <form onSubmit={handleSubmit}>
                                <div>
                                  <input
                                    className={`${styles.otp} ${
                                      error.n1 ? styles.error : ""
                                    }`}
                                    name={"n1"}
                                    value={otp.n1}
                                    onChange={(e) => handleOTP(e)}
                                    autoComplete={"off"}
                                    type="text"
                                    onFocus={(e) => e.target.select()}
                                    maxLength={1}
                                    onKeyDown={(e) => handleBackSpace(e)}
                                    onPaste={(e) => handlePaste(e)}
                                  />
                                  <input
                                    className={`${styles.otp} ${
                                      error.n2 ? styles.error : ""
                                    }`}
                                    name={"n2"}
                                    value={otp.n2}
                                    onChange={(e) => handleOTP(e)}
                                    autoComplete={"off"}
                                    type="text"
                                    onFocus={(e) => e.target.select()}
                                    maxLength={1}
                                    onKeyDown={(e) => handleBackSpace(e)}
                                  />
                                  <input
                                    className={`${styles.otp} ${
                                      error.n3 ? styles.error : ""
                                    }`}
                                    name={"n3"}
                                    value={otp.n3}
                                    onChange={(e) => handleOTP(e)}
                                    autoComplete={"off"}
                                    type="text"
                                    onFocus={(e) => e.target.select()}
                                    maxLength={1}
                                    onKeyDown={(e) => handleBackSpace(e)}
                                  />
                                  <input
                                    className={`${styles.otp} ${
                                      error.n4 ? styles.error : ""
                                    }`}
                                    name={"n4"}
                                    value={otp.n4}
                                    onChange={(e) => handleOTP(e)}
                                    autoComplete={"off"}
                                    type="text"
                                    onFocus={(e) => e.target.select()}
                                    maxLength={1}
                                    onKeyDown={(e) => handleBackSpace(e)}
                                  />
                                  <input
                                    className={`${styles.otp} ${
                                      error.n5 ? styles.error : ""
                                    }`}
                                    name={"n5"}
                                    value={otp.n5}
                                    onChange={(e) => handleOTP(e)}
                                    autoComplete={"off"}
                                    type="text"
                                    onFocus={(e) => e.target.select()}
                                    maxLength={1}
                                    onKeyDown={(e) => handleBackSpace(e)}
                                  />
                                  <input
                                    className={`${styles.otp} ${
                                      error.n6 ? styles.error : ""
                                    }`}
                                    name={"n6"}
                                    value={otp.n6}
                                    onChange={(e) => handleOTP(e)}
                                    autoComplete={"off"}
                                    type="text"
                                    onFocus={(e) => e.target.select()}
                                    maxLength={1}
                                    onKeyDown={(e) => handleBackSpace(e)}
                                  />
                                </div>
                                <div className={styles.errorMessage}>
                                  {errorMessage()}
                                </div>
                                {(otpVerifyLoading ||
                                  (isLoading && resendOTP) 
                                  // || nftLoading
                                  ) && (
                                  <div className={styles.loader}>
                                    {
                                      <LoadingCube
                                        theme={LoadingCubeTheme.DARK}
                                        height={width < 1210 ? 100 : 30}
                                        width={width < 1210 ? 100 : 30}
                                        isFullScreen={
                                          width < 1210 ? true : false
                                        }
                                      />
                                    }
                                  </div>
                                )}
                                <button
                                  style={{backgroundColor: '#0d6efd'}}
                                  className={`btn btn-primary btn-block ${styles.mb4} ${styles.mt5} ${styles.customBtn}`}
                                  type="submit"
                                >
                                  Submit OTP
                                </button>
                              </form>
                              {/* <hr className={styles.mt4} /> */}
                            </div>
                          </div>
                          <div className={styles.resend}>
                            <p>
                              Didn't get the code{" "}
                              <a
                                className=""
                                href="javascript:void(0)"
                                onClick={() => sendOTP("resend")}
                              >
                                Resend OTP
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.accountInfo__div}>
                <p className={styles.accountInfo__p}>
                  Company Name:
                  <span className={styles.accountInfo__span}>
                    {user.companyName}
                  </span>
                </p>
              </div>
              {/* <div
                className={`${styles.accountInfo__div} ${styles.accountInfo__div_rewards}`}
              >
                <p className={`${styles.accountInfo__p} ${styles.tokens}`}>
                  <span className={styles.rewardtokens}>
                    Total Token Awarded:
                  </span>
                  <span className={styles.accountInfo__span}>
                    {rewardsState.totalToken}
                  </span>
                </p>
              </div> */}
              {/* {clientId && clientSecret ? (
                <>
                  <div className={`${styles.accountInfo__div}`}>
                    <p className={`${styles.accountInfo__p}`}>
                      <span className={styles.rewardtokens}>Client ID:</span>
                      <span className={styles.accountInfo__span}>
                        {clientId}
                      </span>
                      <AiOutlineCopy
                        title="Copy"
                        className={styles.copy}
                        onClick={() => handleClick(clientId)}
                        style={{ cursor: "pointer" }}
                      />
                    </p>
                  </div>
                  <div className={`${styles.accountInfo__div}`}>
                    <p className={`${styles.accountInfo__p} ${styles.sdk}`}>
                      <span className={styles.rewardtokens}>
                        Client Secret:
                      </span>
                      <span className={styles.accountInfo__span}>
                        {hiddenText(clientSecret)}
                      </span>
                      <AiOutlineCopy
                        title="Copy"
                        className={styles.copy}
                        onClick={() => handleClick(clientSecret)}
                        style={{ cursor: "pointer" }}
                      />
                    </p>
                  </div>
                </>
              ) : (
                // <button
                //   onClick={() => generateSDKCred()}
                //   className={`${styles.changePassBtn} ${styles.sdkButton}`}>
                //   Generate SDK Cred
                // </button>
                <a
                  href="javascript:void(0);"
                  onClick={() => generateSDKCred()}
                  className={`${styles.sdkButton} ${
                    generatingSDKCred ? styles.button__loading : ""
                  }`}
                >
                  <span class="button__text">
                    {!generatingSDKCred ? "Generate SDK Cred" : ""}
                  </span>
                </a>
              )} */}
              <button
                onClick={() => setShowChangePass(true)}
                className={styles.changePassBtn}
              >
                Change Password
              </button>
              <ChangePassword
                showChangePass={showChangePass}
                setShowChangePass={setShowChangePass}
              />
            </div>
            {/*<ThirdPartyIntegration />*/}
          </div>
          <AccountFooter />
        </div>
      </main>
      {/*<AccountFooter />*/}
      <MyAccountInfoModal 
        accountInfoModal={showInfo}
        close={() => setShowInfo(false)}
      />
    </div>
  );
};

export default Account;
