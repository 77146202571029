import React, { useEffect } from "react";

import styles from "./ActualAnswer.module.scss";

// HOOKS
import { useStateLabel } from "../../../hooks/stateLabeler";

// COMPONENTS
import ActualAnswerOverlay from "./ActualAnswerOverlay/ActualAnswerOverlay";
import { MainDocument } from "../../../../rq-hooks/pythonRestApi/useAskMe";

interface Props {
  sub: string;
  question: string;
  data: MainDocument;
}

const ActualAnswer = ({ data, question, sub }: any) => {
  const [isOpen, setIsOpen] = useStateLabel(false, "isOpen");
  // const { answer, sentence, harvestDate, pubPlatform, sequenceID, url } = data;
  const { answer, answer_sentence, harvest_date, pub_platform, url, id } = data;

  useEffect(() => {
    // Note : This is only required to handle the data parsed from python backend
    const manuallyFixStyles = document.querySelectorAll("b");

    if (manuallyFixStyles.length > 0) {
      manuallyFixStyles.forEach((el) => {
        el.classList.add(`${styles.changeColor}`);
      });
    }
  }, [data]);

  return (
    <div className={styles.actualAnswerWrapper}>
      {isOpen && (
        <ActualAnswerOverlay
          pubPlatform={pub_platform}
          sequenceID={id}
          answer={answer}
          sentence={answer_sentence}
          question={question}
          close={() => setIsOpen(false)}
        />
      )}
      <p className={styles.answer__p}>
        <span className={styles.label}>{`${
          sub === "memAnswer"
            ? "Suggestion from your memory:"
            : "Suggestion from the web:"
        }`}</span>
        <span className={styles.aaAnswer}>{answer}</span>
      </p>
      <div className={styles.sentenceWrapper}>
        <span className={`${styles.label} ${styles.label__span}`}>
          Sentence:
        </span>
        <p
          className={styles.answer__p}
          dangerouslySetInnerHTML={{ __html: answer_sentence }}
        />
      </div>
      <p className={styles.answer__p}>
        <span className={styles.label}>Date:</span>{" "}
        {harvest_date !== "No publication date for this file" &&
        harvest_date !== ""
          ? harvest_date
          : "Click View More for Date with full content"}
      </p>
      <p className={styles.answer__p}>
        <span className={styles.label}>Published by:</span> {pub_platform}
      </p>
      {url !== "No url for this file" ? (
        <a
          className={styles.viewMoreBtn}
          href={url}
          target="_blank"
          rel="noopener noreferrer">
          View More
        </a>
      ) : (
        <button
          className={styles.viewMoreBtn}
          onClick={() => {
            // console.log("I THINK I CLICKED THIS ONE");
            setIsOpen(!isOpen);
          }}>
          View More
        </button>
      )}
    </div>
  );
};

export default ActualAnswer;
