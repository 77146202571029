import { REST_BASE_ENDPOINT } from "../constants";
import { useMutation } from "react-query";
import { axios } from "../utils/axiosConfig";


export function useNFTBadge(opts?: any) {
  return useMutation(async () => {
    try {
      const { data } = await axios.get(
        `${REST_BASE_ENDPOINT}/auth/nft-badge`
      );
      if (data.data) {
        return data.data;
      }
    } catch (err) {
        if(err.response && err.response.data && err.response.data.data && err.response.data.data.msg)  
            throw new Error(err.response.data.data.msg);
        else  
            throw new Error(err.response.data.message);
    }
  }, opts);
}
