import { useEffect, useReducer, useState } from "react";
import { MdSearch } from "react-icons/md";
// Data
import { useSearchContext } from "../../context/allContexts";
import { SearchContextActions } from "../../context/allContexts/SearchContext/SearchContextTypes";
// styles
import styles from "./V2Search.module.scss";

enum ActionType {
  SEARCH_STRING = "change search string",
}

type State = {
  searchString: string;
};

type Action = { type: ActionType.SEARCH_STRING; payload: string };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "change search string":
      return {
        ...state,
        searchString: action.payload,
      };
    default:
      return state;
  }
};

const initState: State = {
  searchString: "",
};

// const V2Search = ({ darkTheme = false }: any) => {
const V2Search = ({}: any) => {
  const { searchDispatcher, disableSearch, searchState }: any =
    useSearchContext();

  // used by all search bars
  const [state, dispatcher] = useReducer(reducer, initState);
  const { searchString } = state;

  useEffect(() => {
    dispatcher({
      type: ActionType.SEARCH_STRING,
      payload: searchState.searchString,
    });
  }, []);

  useEffect(() => {
    dispatcher({
      type: ActionType.SEARCH_STRING,
      payload: searchState.searchString,
    });
  }, [searchState.searchString])

  const changeSearchString = (e: any) => {
    e.preventDefault();
    dispatcher({ type: ActionType.SEARCH_STRING, payload: e.target.value });
  };

  // const resetSimpleSearchInput = () => {
  //   dispatcher({ type: ActionType.SEARCH_STRING, payload: "" });
  // };

  function submitSearch(e: any) {
    e.preventDefault();
    if (!disableSearch) {
      searchDispatcher({
        type: SearchContextActions.CHANGE_SEARCH_STRING,
        payload: searchString,
      });
    }
    setShowMobileSearch(false);
  }

  const [showMobileSearch, setShowMobileSearch] = useState(false);
  return (
    <>
      <form
        className={`${styles.form} ${styles.desktopOnly}`}
        onSubmit={submitSearch}
      >
        <input
          type="text"
          placeholder="Keyword Filter"
          name="searchString"
          onChange={(e) => changeSearchString(e)}
          value={searchString === null ? "" : searchString}
          onBlur={(e) => {
            if (e.target.value === "") {
              submitSearch(e);
            }
          }}
        />
        <div title="Search" className={styles.searchIcon_container}>
          <MdSearch onClick={submitSearch} className={styles.icon} />
        </div>
      </form>

      <div
        className={`${styles.background} ${styles.mobileOnly}`}
        data-mobile-search-state={`${showMobileSearch}`}
      >
        <form className={`${styles.form}`} onSubmit={submitSearch}>
          <input
            type="text"
            placeholder="Keyword Filter"
            name="searchString"
            onChange={(e) => changeSearchString(e)}
            value={searchString}
            onBlur={(e) => {
              if (e.target.value === "") {
                submitSearch(e);
              }
            }}
          />
          <div title="Search" className={styles.searchIcon_container}>
            <MdSearch onClick={submitSearch} className={styles.icon} />
          </div>
        </form>
      </div>
      <MdSearch
        onClick={() => setShowMobileSearch(true)}
        data-mobile-search-state={`${showMobileSearch}`}
        className={`${styles.searchMobileToggleBtn} ${styles.mobileOnly}`}
      />
    </>
  );
};

export default V2Search;
