import { useState, useDebugValue } from "react";

// use for debugging
function useStateLabel(initialValue, name) {
  const [value, setValue] = useState(initialValue);
  useDebugValue(`${name}: ${value}`);
  return [value, setValue];
}

export { useStateLabel };
