import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";

// COMPONENTS
import CloseModalBtn, {
  CloseModalBtnTheme,
} from "../../../../Components/CloseModalBtn/CloseModalBtn";
import AddToNotebookBtn from "../../../../Components/AddToNotebookBtn/AddToNotebookBtn";

// STYLES
import styles from "./AnswerKPND.module.scss";
import LoadingCube, {
  LoadingCubeTheme,
} from "../../../../Components/LoadingCube/LoadingCube";
import ComponentMessagingOverlay, {
  CmoTheme,
} from "../../../../Components/ComponentMessagingOverlay/ComponentMessagingOverlay";
import { useArticleQuery, WhichDb } from "../../../../../codeGenFE";
import { customErrorHandler } from "../../../../../utils/customErrorHandler";
import { OtherDocument } from "../../../../../rq-hooks/pythonRestApi/useAskMe";

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "keypoints loading":
//       return {
//         ...state,
//         error: false,
//         loading: true,
//         success: false,
//       };
//     case "keypoints loaded":
//       return {
//         ...state,
//         keyPoints: action.payload,
//         loading: false,
//         error: false,
//         success: true,
//       };
//     case "keypoints failed to load":
//       return {
//         ...state,
//         keyPoints: [],
//         error:
//           "Sorry we had trouble loading the keypoints. Please try again later.",
//         loading: false,
//         success: false,
//       };
//   }
//   return state;
// };

// const initState = {
//   keyPoints: [],
//   loading: false,
//   error: false,
//   success: false,
// };

interface AnswerKPNDProps {
  close: () => void;
  article: OtherDocument;
}

const AnswerKPND = ({ close, article }: AnswerKPNDProps) => {
  // const [localState, localDispatch] = useReducer(reducer, initState);
  // const { keyPoints, loading, error, success } = localState;

  const { data, status, error } = useArticleQuery(
    {
      articleId: article.id,
      whichDB: article.db_origin as WhichDb,
    },
    {
      enabled: !!article,
    }
  );

  const keypoints = data?.article?._source?.summary;

  if (status === "loading") {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingCube
          theme={LoadingCubeTheme.DARK}
          height="100px"
          width="100px"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.loadingWrapper}>
        <ComponentMessagingOverlay
          close={close}
          message={customErrorHandler(error)}
          showX={true}
          theme={CmoTheme.LIGHT}
        />
      </div>
    );
  }

  return (
    <div className={styles.overlayWrapper}>
      <div className={styles.closeBtn}>
        <CloseModalBtn theme={CloseModalBtnTheme.DARK} close={close} />
      </div>
      <div className={styles.keyPointsContainer}>
        <h3 className={styles.keyPointsLabel}>Key Points</h3>
        {keypoints &&
          keypoints.map((s, idx) => {
            if (!s) return <></>;
            return (
              <div key={idx} className={styles.keyPointsNBtnWrapper}>
                {/* <AddToNotebookBtn content={s} style={"unordered-list-item"} /> */}
                <AddToNotebookBtn content={s} style={"unstyled"} />
                <p className={styles.sentence}>{s}</p>
              </div>
            );
          })}
      </div>
      {/* <p>Key Points N Drivers</p> */}
    </div>
  );
};

export default AnswerKPND;
