const nowigenceUploadDate = () => {
  let today = new Date();
  let date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  return date;
};

// // CUSTOM NO RESULTS CARD DATA
// export const noResults = {
//   _id: "noReults",
//   _source: {
//     PlanType: "Search Tips",
//     content:
//       "May want to change your filters or look further back in your history.",
//     driver: [
//       "You can try our Advanced Search and selecting",
//       "Date",
//       "Relationship",
//       "Topic",
//       "Category",
//       "Drivers",
//       "Outcome",
//       "Country",
//       "Data Source",
//       "Tags",
//     ],
//     driver_type: [],
//     harvestDate: "",
//     images: "No image for this file",
//     orig_url: "No url for this file",
//     title: "Filter Tips From Nowigence",
//     source: {
//       name: "Helpful Reminder from Nowigence",
//     },
//     uploadDate: nowigenceUploadDate(),
//     summary: [
//       "Unfortunately your search did not yield any results. Please adjust your search parameters and try again.",
//     ],
//     // shortDesc:
//     // "Unfortunately your search did not yield any results. Please adjust your search parameters and try again.",
//   },
//   proImageLink: "No processed image link for this file",
//   pubStatus: "help",
//   sharedBy: "Nowigence Inc.",
//   ner: {
//     ORG: ["Nowigence Inc"],
//     PRODUCT: ["Pluaris"],
//     PERSON: ["Email Nowigence"],
//     GPE: ["New York", "India", "North Carolina", "New Jersey"],
//   },
//   sentiment_score: [],
// };

// CUSTOM ERROR CARD DATA
export const errorCard = {
  _id: "noReults",
  _source: {
    PlanType: "Search Tips",
    content:
      "Systems are down. Please give us a few to correct the issues.  If problems persist please contact us.",
    driver: [
      "We are having system problems currently",
      "Date",
      "Relationship",
      "Topic",
      "Category",
      "Drivers",
      "Outcome",
      "Country",
      "Data Source",
      "Tags",
    ],
    driver_type: [],
    harvestDate: "",
    images: "No image for this file",
    orig_url: "No url for this file",
    title:
      "We are experiencing technical problems at this time.  Please try again.",
    source: {
      name: "Error notification from Nowigence",
    },
    uploadDate: nowigenceUploadDate(),
    summary: [
      "Systems are down. Please give us a few to correct the issues.  If problems persist please contact us.",
    ],
    // shortDesc:
    //   "Systems are down. Please give us a few to correct the issues.  If problems persist please contact us.",
  },
  proImageLink: "No processed image link for this file",
  pubStatus: "error",
  sharedBy: "Nowigence Inc.",
  ner: {
    ORG: ["Nowigence Inc"],
    PRODUCT: ["Pluaris"],
    PERSON: ["Email Nowigence"],
    GPE: ["New York", "India", "North Carolina", "New Jersey"],
  },
  sentiment_score: [],
  userMetaData: [],
};
