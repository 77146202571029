import * as React from "react";

function SvgInfoButton(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
      <path
        d="M17.071 2.929a10 10 0 100 14.142 10 10 0 000-14.142zM11.3 14.348a1.304 1.304 0 11-2.609 0V9.13a1.304 1.304 0 112.609 0zM9.976 6.9A1.163 1.163 0 018.74 5.712a1.163 1.163 0 011.252-1.2A1.2 1.2 0 119.976 6.9z"
        fill="#959aa2"
      />
    </svg>
  );
}

export default SvgInfoButton;
