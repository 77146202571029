import { REST_BASE_ENDPOINT, API_ENDPOINTS, POST_HEADERS, LOCAL_STORAGE_KEYS } from "../../../constants";
import { toast } from "react-toastify";
import { createCompany, createCompanyId } from '../Login/NewLogin/createCompany'
import { socialLogin } from "../../../services/signUpUser";
import { axios } from "../../../utils/axiosConfig";


function storeLinkedinDetails(result, setLoading, loading) {
    // Now we have the user details
    // Create a user in our database
    localStorage.setItem("linkedinDetails", JSON.stringify(result.data));
    const companyName =
        result?.data.localizedFirstName +
        " " +
        result?.data.localizedLastName +
        " " +
        Date.now();
    const userEmail = result?.data.email;
    if (!loading) setLoading(true);

    createCompanyId(companyName, userEmail)
        .then((res) => {
            if(res?.data) createLinkedinUser(res.data, result, userEmail)
        })
        .catch((err) => {
            if (err.response.data.message === "This email already exists.") {
                // socialLogin();
                return;
            }
        });
}

export function linkedinAccessToken(result) {
    // ME API Linkedin
    if (
        result.data.access_token !== undefined ||
        result.data.access_token !== null
    ) {
        localStorage.setItem("linkedInAccessToken", result.data.access_token);
    }

    const linkedInAccessToken = localStorage.getItem("linkedInAccessToken");

    const passToken = linkedInAccessToken
        ? linkedInAccessToken
        : result.data.access_token;
    axios.post(`${REST_BASE_ENDPOINT}${API_ENDPOINTS.LI_PROFILE}`, {
            accessToken: passToken,
    }, {
        headers: POST_HEADERS,
    })
        .then((result) => {
            if(result?.data) storeLinkedinDetails(result.data);
        });
}

function createLinkedinUser(res, result, userEmail, keywords, notify, setAllData, setProgress, setError, socialLogin, setLoading, pushLogin, canLogin, setshowLinkedinModal, interval, setSectionData) {
    localStorage.setItem("companyIdNew", res.data);

    if (result.error) {
        notify();
        setAllData(null);
        setProgress(0);
        setError(true);
        localStorage.removeItem("allData");
    } else {
        // Now Create User
        axios.post(`${REST_BASE_ENDPOINT}${API_ENDPOINTS.CREATE_USER}`, {
                firstName: result.data.localizedFirstName,
                lastName: result.data.localizedLastName,
                email: userEmail,
                tempUserId: localStorage.getItem("userId"),
                company: res.data,
                password: "fromLinkedin" + result.data.localizedFirstName,
                keys: localStorage.getItem("summaryKeywords") ?? keywords,
        }, {
            headers: POST_HEADERS,
        })
            .then((result) => {
                if (result?.status !== 200) {
                    if (result?.status === 400) {
                        toast.info("You already have an account", {
                            position: "top-center",
                            autoClose: 3000,
                            theme: "colored",
                        });
                        socialLogin(userEmail, setLoading);
                        if (pushLogin) {
                            window.location.assign("/my-memory/reader");
                        }
                    } else {
                        console.log(result);
                    }
                    setAllData(null);
                    setProgress(0);
                    setError(true);
                } else {
                    canLogin(true);
                }
                setLoading(false);
                setshowLinkedinModal(true);
            })
            .catch((error) => console.log("error", error));
        setSectionData(null);

        clearInterval(interval);

        setProgress(100);
        clearInterval(interval);
        setError(false);
    }
}


export function afterLogin(result, canLogin, setLoading) {
    canLogin(result.data.success);
    // localStorage.setItem(
    //     LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
    //     result.data.accessToken
    // );
    window.location.assign("/my-memory/reader");
    setLoading(false);
    localStorage.removeItem("integrated");
    return console.log(result);
}

export function createLinkedinPostThenLogin(response, linkedinDetails, pushLogin, canLogin, setLoading, setshowLinkedinModal, createUser) {
    if (response === 200) {
        toast.success("Posted Successfully", {
            position: "top-center",
            autoClose: 5000,
            theme: "colored",
        });
        if (
            pushLogin ||
            linkedinDetails !== undefined ||
            linkedinDetails !== null
        ) {
            const getLinkedinData = localStorage.getItem("linkedinDetails");
            const parseLinkedinData = JSON.parse(getLinkedinData);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Access-Control-Allow-Origin", "*");
            myHeaders.append("Access-Control-Allow-Credentials", "true");
            myHeaders.append(
                "Access-Control-Allow-Methods",
                "GET,HEAD,OPTIONS,POST,PUT"
            );
            myHeaders.append(
                "Access-Control-Allow-Headers",
                "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
            );
            myHeaders.append(
                "Access-Control-Expose-Headers",
                "Access-Control-Allow-Origin, Access-Control-Allow-Credentials"
            );
            myHeaders.append("Access-Control-Max-Age", "3600");

            setTimeout(async () => {
                await axios.post(
                    `${REST_BASE_ENDPOINT}/auth/landing/user/social/login`,
                    {
                        email: parseLinkedinData.email,
                        password: "",
                    }
                ).then((res) => {
                    console.log(res)
                    if (res.data?.data.success) {
                        afterLogin(res.data, canLogin, setLoading)
                    }
                    else {
                        createCompany(
                            parseLinkedinData.localizedFirstName,
                            parseLinkedinData.localizedLastName,
                            createUser,
                            {
                                firstName: parseLinkedinData.localizedFirstName,
                                lastName: parseLinkedinData.localizedLastName,
                                email: parseLinkedinData.email,
                            },
                            setLoading,
                            parseLinkedinData.email
                        );
                    }
                })
            }, 500);
        }
    } else if (response !== 200) {
        toast.error("Content is a duplicate post", {
            position: "top-center",
            autoClose: 5000,
            theme: "colored",
        });
        setLoading(false);
    }
    localStorage.removeItem("allData");
    setshowLinkedinModal(false);
}


export async function submitLinkedinData(e, setLoading, pushLogin, canLogin, setshowLinkedinModal, createUser) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const checkaccess = localStorage.getItem("linkedInAccessToken");
    const checklinkedinDetails = JSON.parse(
        localStorage.getItem("linkedinDetails")
    );

    const linkedinDetails = checklinkedinDetails;

    const authordata = "urn:li:person:" + linkedinDetails.id;
    if (data.linkedinTextField.length > 2999) {
        toast.error("LinkedIn Accepts less than 2999 Characters", {
            position: "top-center",
            autoClose: 5000,
            theme: "colored",
        });
    }
    setLoading(false);
    if (data.linkedinTextField.length <= 2999) {
        await axios.post(`${REST_BASE_ENDPOINT}${API_ENDPOINTS.LI_POST}`, {
                token: checkaccess,
                data: data.linkedinTextField.replace(
                    /[\(*\)\[\]\{\}<>@|~_]/gm,
                    (x) => "\\" + x
                ),
                author: authordata,
        }, {
            headers: POST_HEADERS,
        })
            .then((response) =>{
                    if(response?.data){
                        createLinkedinPostThenLogin(response.status, linkedinDetails, pushLogin, canLogin, setLoading, setshowLinkedinModal, createUser)
                    }
                }
            )
            .catch((error) => {
                setLoading(false);
                console.log("error", error);
            });


    }
}