import PainPointCard from "./painPointCard";
import bg1 from "../../../../../assets/img/bg1.png";
import bg2 from "../../../../../assets/img/bg2.png";
import bg3 from "../../../../../assets/img/bg3.png";

const RecentBoard = ({ data }) => {
  return (
    <>
      <div className="text-dark my-4">
        <div className="text-start mx-3 px-2">
          <h2 className="text-dark fw-bold fs-3 my-5">
            Problems, Pain Points & Key Challenges
          </h2>
        </div>
        <div className="my-5 grid grid-cols-3 gap-0">
          {data.problemsPainsChallenges.map((item, index) => (
            <div className="mx-2.5">
              <div>
                <PainPointCard
                  type={item.type}
                  contents={item.contents}
                  cssCard={index}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default RecentBoard;
