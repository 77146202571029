import React, { FC, useEffect, useState } from 'react';
import { useUserContext } from './v2/context/allContexts';
import CookieConsent from "react-cookie-consent";
// COMPONENTS
import AuthenticatedApp from './components/Authenticated';
import UnauthenticatedApp from './components/Unauthenticated';

// GLOBAL STYLES
import './reset.scss';
import './v2/SCSS/index.scss';
import './cssVariables.scss';
import './App.scss';
import styles from './FontFix.module.scss';
import LoadingCube from './v2/Components/LoadingCube/LoadingCube';

const AuthGate: FC = () => {
    const { user } = useUserContext();

    if (
        window.location.pathname === '/' ||
        window.location.pathname === '/signuplight' ||
        window.location.pathname === '/loginlight' ||
        window.location.pathname === '/loginlight' ||
        window.location.pathname === '/signupdark'
    ) {
        document.body.classList.add(styles.custom_font);
    } else {
        document.body.classList.add(styles.custom_auth);
    }

    const [loading, setLoading] = useState(true);

    const loaderTimeout = setTimeout(() => {
        setLoading(false);
    }, 1000);

    useEffect(() => {
        return clearTimeout(loaderTimeout);
    }, []);

    return (
        <>

            {loading && (
                <LoadingCube isFullScreen={true} width={100} height={100} />
            )}
            <CookieConsent
                location="bottom"
                buttonText="Sure"
                declineButtonStyle={{ color: "black", fontSize: 17, background: 'white', borderRadius: 10 }}
                declineButtonText="No"
                cookieName="myAwesomeCookieName2"
                style={{ background: "linear-gradient(to right, #3671e4 , #0534bd)" }}
                buttonStyle={{ color: "black", fontSize: 17, background: 'white', borderRadius: 10 }}
                expires={150}
            >
                Pluaris uses cookies to enhance the user experience. We use them to study user profile.{" "}

            </CookieConsent>
            {!user || !user.email ? (
                <UnauthenticatedApp />
            ) : (
                <AuthenticatedApp />
            )}
        </>
    );
};

export default AuthGate;
