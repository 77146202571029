import { FC, useEffect, useState } from "react";
// import SingleSelectDropdown from "../../../../SingleSelectDropdown/SingleSelectDropdown";
import { useQueryClient } from "react-query";
import { useSearchContext } from "../../../../../context/allContexts";
import { useSaveFiltersContext } from "../../SaveFiltersContext";
import {
  useDeleteFilterMutation,
  useGetUserSavedFiltersQuery,
} from "../../../../../../codeGenFE";

import styles from "./SavedFiltersDropdown.module.scss";
import { SearchContextActions } from "../../../../../context/allContexts/SearchContext/SearchContextTypes";
import { MdArrowDropDown } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { FiTrash2, FiEdit2 } from "react-icons/fi";
import OverwriteModal from "../OverwriteModal/OverwriteModal";
import SaveFilterErrorModal from "../SaveFilterErrorModal/SaveFilterErrorModal";
// import { isTemplateExpression } from "typescript";
// import { ContextProvider } from "../../../../../context/providerComposer";

interface Props {
  isOpen: boolean;
  changeOpenFilter: () => void;
}

const SavedFiltersDropdown: FC<Props> = ({ isOpen, changeOpenFilter }) => {
  const qClient = useQueryClient();

  const { saveFilterState, SaveFiltersDispatch } = useSaveFiltersContext();
  const { filterToEdit, saveErrorMsg } = saveFilterState;
  const { searchDispatcher, searchState } = useSearchContext();
  const { currentActiveFilter } = searchState;

  const { data } = useGetUserSavedFiltersQuery();
  const savedFilters = data?.getUserSavedFilters
    ? data?.getUserSavedFilters
    : [];

  const [showConfirmDelete, setShowConfirmDelete] = useState<null | number>(
    null
  );

  const {
    mutate: deleteFilter,
    // error: deleteFilterError,
    // status: deleteFilterStatus,
  } = useDeleteFilterMutation({
    onSuccess: () => {
      qClient.invalidateQueries("getUserSavedFilters");
    },
  });

  useEffect(() => {
    setShowConfirmDelete(null);
  }, [isOpen]);

  return (
    <div className={styles.container}>
      <div className={styles.dropdownWrapper}>
        <button
          onClick={changeOpenFilter}
          className={`${styles.dropdownDisplay} ${
            currentActiveFilter ? styles.activeColor : ""
          }`}
          data-isopen={`${isOpen && savedFilters?.length > 0}`}
        >
          Saved Filters{" "}
          <MdArrowDropDown
            className={`${isOpen ? styles.arrowReverse : styles.arrow}`}
          />
        </button>
        {isOpen && (
          <div className={styles.dropdown}>
            {savedFilters &&
              savedFilters.map((item, index) => {
                return (
                  <button
                    className={styles.dropdownItems}
                    onClick={() => {
                      searchDispatcher({
                        type: SearchContextActions.LOAD_SAVED_FILTER_SET,
                        // @ts-ignore
                        filters: item,
                      });
                      changeOpenFilter();
                    }}
                    key={item!._id}
                  >
                    {item?.name}
                    <div>
                      {showConfirmDelete !== index && (
                        <FiEdit2
                          className={styles.editIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            SaveFiltersDispatch({
                              type: "change filter to edit",
                              payload: item,
                            });
                          }}
                        />
                      )}
                      {showConfirmDelete !== index && (
                        <FiTrash2
                          className={styles.delIcons}
                          onClick={(e) => {
                            e.stopPropagation();
                            // deleteItem(item.submitData);
                            setShowConfirmDelete(index);
                          }}
                        />
                      )}
                    </div>
                    {showConfirmDelete === index && (
                      <span
                        className={styles.delIcons}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowConfirmDelete(null);
                          deleteFilter({ filterId: item!._id });
                          if (item!._id === currentActiveFilter) {
                            searchDispatcher({
                              type: SearchContextActions.RESET_FILTERS,
                            });
                          }
                        }}
                      >
                        Confirm?
                      </span>
                    )}
                  </button>
                );
              })}
          </div>
        )}
      </div>
      {filterToEdit && <OverwriteModal />}
      {saveErrorMsg && <SaveFilterErrorModal />}
      {currentActiveFilter &&
        savedFilters?.filter(
          (item) => item?._id === currentActiveFilter
        )?.[0] && (
          <p className={styles.selectedItem}>
            <button
              className={styles.selectedItem__btn}
              onClick={() => {
                searchDispatcher({ type: SearchContextActions.RESET_FILTERS });
              }}
            >
              <AiOutlineClose className={styles.icon} />
            </button>{" "}
            {
              savedFilters?.filter(
                (item, index) => item?._id === currentActiveFilter
              )?.[0]?.name
            }
          </p>
        )}
    </div>
  );
};

export default SavedFiltersDropdown;
