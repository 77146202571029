import React, { FC, useEffect } from "react";
import axios from "axios";

import { AiOutlineDown } from "react-icons/ai";

import { copyText } from "../../../../util/copyText";
import { useStateLabel } from "../../../../hooks/stateLabeler";

import styles from "./ACDisplaySharedBy.module.scss";
import {
  ListUsersBy,
  SharedBy,
  UserMetaData,
  useUserLookupTableQuery,
} from "../../../../../codeGenFE";
import { stringify } from "uuid";

interface Props {
  label: string;
  userMetaData: UserMetaData[] | undefined | null;
  uId: string;
}

const ACDisplaySharedBy: FC<Props> = ({ label, userMetaData, uId }) => {
  // const [availableSharers, setAvailableSharers] = useStateLabel(
  //   {},
  //   "availableSharers"
  // );
  const [sharedByDropdown, setSharedByDropdown] = useStateLabel(
    false,
    "sharedByDropdown"
  );
  const { data, error, status } = useUserLookupTableQuery({
    listBy: ListUsersBy.Id,
  });

  const availableSharers = data?.userLookupTable;

  let sharedByArr: string[] = [];
  if (userMetaData && userMetaData.length > 0) {
    userMetaData.forEach((u: any) => {
      if (u && u.userId === uId) {
        sharedByArr = u.sharedBy;
      }
    });

    interface Props2 {
      sharer: string;
    }

    // ---------- START SingleLI ---------- //
    const SingleLI: FC<Props2> = ({ sharer }) => {
      const [copiedText, setCopiedText] = useStateLabel(false, "copiedText");

      const copyFunc = (text: string) => {
        copyText(text);
        setCopiedText(true);

        setTimeout(() => {
          setCopiedText(false);
        }, 450);
      };

      return (
        <div className={styles.displayWrapper}>
          <li
            className={styles.insights__sharedBy}
            onClick={() => copyFunc(sharer)}
          >
            {copiedText ? "Copied" : sharer}
          </li>
        </div>
      );
    };
    // ---------- END SingleLI ---------- //

    return (
      <>
        <div
          className={styles.titleArrowWrapper}
          onClick={() => setSharedByDropdown(!sharedByDropdown)}
        >
          <p>{label}</p>
          {sharedByArr && sharedByArr.length > 2 && (
            <AiOutlineDown className={styles.arrowIcon} />
          )}
        </div>
        {sharedByArr &&
          sharedByArr.length > 0 &&
          // @ts-ignore
          sharedByArr.map((sender: SharedBy, idx) => {
            if (sharedByDropdown) {
              return <SingleLI key={idx} sharer={sender.name as string} />;
            } else if (idx < 2) {
              return <SingleLI key={idx} sharer={sender.name as string} />;
            } else {
              return null;
            }
          })}
      </>
    );
  } else return null;
};

export default ACDisplaySharedBy;
